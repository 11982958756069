import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import EditButton from 'components/maintenance/atoms/buttons/EditButton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import { getClassificationName } from 'utils/common.helper';
import UrlHelper from 'utils/url.helper';
import { formatDatetime } from 'utils/datetime.helper';
import { useAppSelector } from 'redux/hooks';
import { UserInfoType } from '../../../../types/maintenance/UserInfoType';
import CLS from '../../../../constants/classification';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  tdBtn: {
    textAlign: 'center',
  },
  tdString: {
    textAlign: 'left',
  },
  tdTimestamp: {
    textAlign: 'center',
  },
  tdClsfcn: {
    textAlign: 'center',
  },
}));

/* ************ Type ************ */
type Props = {
  row: UserInfoType;
};

/* ************ Component ************ */
const EditColumn: React.FC<{
  userId: string;
  role: string | null;
}> = (props) => {
  const history = useHistory();
  const { userId, role } = props;
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const loginUserRole = userInfo?.role;
  const userIdLogin = userInfo?.userId;

  /* ************ Event ************ */
  const handleClickEditUserInfo = (prmUserId: string) => {
    // ユーザ登録画面へ遷移
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MAINT_USER_DETAIL_EDIT, { userId: prmUserId }));
  };

  let btnLabel = '編集';
  if (role === loginUserRole && userId !== userIdLogin) {
    // ログインユーザと被参照者の権限が同じ場合
    btnLabel = '詳細';
  }
  return <EditButton onClick={() => handleClickEditUserInfo(userId)}>{btnLabel}</EditButton>;
};

const UserListTableRow: React.FC<Props> = ({ row }) => {
  const classes = useStyles();
  const lastloginTime = row.lastLoginTimestamp && formatDatetime(new Date(row.lastLoginTimestamp), '/', true);

  return (
    <>
      <TableRow key={row.userId}>
        <TableCell className={classes.tdBtn}>
          <EditColumn userId={row.userId} role={row.role} />
        </TableCell>
        <TableCell className={classes.tdString}>
          <div className="cellStyle">{row.accountId}</div>
        </TableCell>
        <TableCell className={classes.tdClsfcn}>
          <div className="cellStyle">{getClassificationName(CLS.ROLE.CATEGORY_CD, row.role)}</div>
        </TableCell>
        <TableCell className={classes.tdClsfcn}>
          <div className="cellStyle">
            {row.validDiv && getClassificationName(CLS.USER_VALID_DIV.CATEGORY_CD, row.validDiv)}
          </div>
        </TableCell>
        <TableCell className={classes.tdClsfcn}>
          <div className="cellStyle">{row.rockTimestamp && 'ロック中'}</div>
        </TableCell>
        <TableCell className={classes.tdTimestamp}>
          <div className="cellStyle">{lastloginTime}</div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserListTableRow;
