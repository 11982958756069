import React, { memo } from 'react';
import { InputBase, InputBaseProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * TextBox Component
 * テキストボックス
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  root: {
    border: '2px solid',
    borderColor: '#8E8E8E',
    borderRadius: '4px',
  },
  deafutStyle: {
    height: '60px',
    width: '560px',
  },
}));

// コンポーネント定義
const TextBox: React.FC<InputBaseProps> = (props) => {
  // makeStyleで定義したstyleを取得
  const styles = useStyles();
  const {
    id,
    name,
    value,
    classes,
    className,
    disabled,
    fullWidth,
    placeholder,
    type,
    onChange,
    onBlur,
    autoFocus,
  } = props;

  const styleClass = `${styles.root} ${className || styles.deafutStyle}`;

  // TextFieldを利用するか別途検討
  return (
    <InputBase
      id={id}
      name={name}
      value={value}
      classes={classes}
      className={styleClass}
      disabled={disabled}
      fullWidth={fullWidth}
      placeholder={placeholder}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
    />
  );
};

// propsの初期値。props未指定時に使用する。
TextBox.defaultProps = {
  value: '',
  disabled: false,
  placeholder: '',
  type: 'text',
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(TextBox);
