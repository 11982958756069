import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import StatusButton from 'components/home/atoms/buttons/StatusButton';
import React, { useRef, useState } from 'react';
import { formatDatetimeForList } from 'utils/datetime.helper';
import { formatCurrency } from 'utils/number.helper';
import CorSelectBox from 'components/home/atoms/selectBoxs/CorSelectBox';
import { useAppDispatch } from 'redux/hooks';
import MESSAGES from 'constants/messages';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { MachineStatus } from '../../../../types/home/machineStatus';
import CLS from '../../../../constants/classification';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '476px',
    width: '1129px',
    '& table': {
      width: '1112px',
    },
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '16px',
      lineHeight: '20px',
    },
    '& th': {
      textAlign: 'center',
      height: '36px',
      padding: 0,
    },
    '& tbody tr': {
      height: '44px',
    },
    '& td': {
      height: '44px',
      padding: '0 8px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdOccurensDate: {
    textAlign: 'center',
  },
  tdMachineName: {
    textAlign: 'left',
  },
  tdStationName: {
    textAlign: 'left',
  },
  tdGiftName: {
    textAlign: 'left',
  },
  tdSalesTotal: {
    textAlign: 'right',
  },
  tdPayoutTotal: {
    textAlign: 'right',
  },
  tdCostRate: {
    textAlign: 'right',
  },
  tdStatus: { textAlign: 'center' },
  tdCorrespondenceStatus: { textAlign: 'center', padding: '0px 6px 0px 6px !important' },
  tdCorrespondenceDate: {
    textAlign: 'center',
  },
  outStanding: { backgroundColor: '#FFDBDB' },
  holding: { backgroundColor: '#F6F6F6' },
  closed: { backgroundColor: '#B7D5FF' },
  // オーバーペイ即対応ダイアログ
  contentBox: { margin: '0 0 8px 8px' },
  contentText: { fontSize: '28px' },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<MachineStatus>;
  sort: string;
  callGetAlertInfoApi: (sort: string) => void;
  className?: {
    root?: string;
  };
  isTop?: boolean;
};

/* ************ Constants ************ */
const topAlertCount = 5; // TOP画面のアラート表示数
const corStatusSelectList = [CLS.CORRESPOND_STATUS.NEW, CLS.CORRESPOND_STATUS.PEND, CLS.CORRESPOND_STATUS.DONE];
const corStatusPayoutSelectList = [
  CLS.CORRESPOND_STATUS.NEW,
  CLS.CORRESPOND_STATUS.PEND,
  CLS.CORRESPOND_STATUS.DONE,
  CLS.CORRESPOND_STATUS.ALERT_RESET,
];

/* ************ Component ************ */
const MachineStatusTable: React.FC<Props> = ({ rows, sort, className, callGetAlertInfoApi, isTop = false }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  /* ************ state/redux ************ */
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openAlertResetDialog, setOpenAlertResetDialog] = useState(false);
  const [openPendingDialog, setOpenPendingDialog] = useState(false);
  const [targetAlertInfo, setTargetAlertInfo] = useState<{
    alertId: number | null;
    stationId: number | null;
    versionNo: number | null;
  }>({
    alertId: null,
    stationId: null,
    versionNo: null,
  });
  const targetAlertStatus = useRef<{
    alertId: number | null;
    stationId: number | null;
    cpStatus: number | null;
    versionNo: number | null;
  }>({
    alertId: null,
    stationId: null,
    cpStatus: null,
    versionNo: null,
  });

  /* ************ Function ************ */
  // 機器停止
  const stopMachine = async (alertId: number, stationId: number, versionNo: number) => {
    const http = new HttpConnection({ dispatch });
    await http.post(REST_API.HOME.CORRESPOND_OVERPAY, { alertId, stationId, versionNo });
  };
  // アラートステータス更新
  const updateAlertStatus = async (alertId: number, cpStatus: number, versionNo: number, stationId: number) => {
    const http = new HttpConnection({ dispatch });
    await http.post(REST_API.HOME.UPDATE_ALERT_STATUS, { alertId, cpStatus, versionNo, stationId });
  };
  // 同ステーションに通信不能のアラートがある場合、ハードエラーのアラートを除外
  const generateFilteredRow = (args: Array<MachineStatus>) => {
    // ステーションIDの重複しているレコードを抽出
    const duplicatedRow = args.filter(
      (item, i, self) => self.filter((subItem) => subItem.stationId === item.stationId).length >= 2,
    );
    // 重複レコードが無ければ処理を終了
    if (duplicatedRow.length === 0) return args;

    // 重複したレコードからハードエラーのものを抽出 → A
    const hardErrorRecords = duplicatedRow.filter((item) => item.status === Number(CLS.ALERT_STATUS.HARD_ERROR));
    // 重複したレコードから通信不能のものを抽出 → B
    const NetworkErrorRecords = duplicatedRow.filter((item) => item.status === Number(CLS.ALERT_STATUS.NETWORK_ERROR));
    // 抽出レコードが無ければ処理を終了
    if (hardErrorRecords.length === 0 || NetworkErrorRecords.length === 0) return args;

    // Bの中から、Aに同じステーションIDがあるものを抽出
    const removeRecord = hardErrorRecords.filter((hardError) => {
      const record = NetworkErrorRecords.filter((networkError) => networkError.stationId === hardError.stationId);
      return record;
    });
    // 渡された値(rows)から余分なレコードを取り除く
    const returnRows = args.filter((item) => {
      const record = removeRecord.filter((remove) => remove.alertId === item.alertId);
      return !record.length;
    });

    return newDataSort(returnRows);
  };

  const generatorId = (index: number) => {
    const math = Math.floor((1 + Math.random()) * 0x10000000000)
      .toString(16)
      .substring(1);
    return `${math}${index}`;
  };

  const newDataSort = (list: Array<MachineStatus>): Array<MachineStatus> => {
    if (list.length === 0) return list;

    // Add id for list
    const addIdForList = list.map((rowList: MachineStatus, index: number) => {
      const newRow = { ...rowList, id: generatorId(index) };

      return newRow;
    });

    // filter by status or correspondenceStatus
    // get value filter have status is 3 or correspondenceStatus is 5
    const dataByStatus = addIdForList.filter(
      (row: MachineStatus) => row.status === 3 || row.correspondenceStatus === 5,
    );

    // get Id from dataByStatus
    const orderById: string[] = [];
    dataByStatus.filter((row: { id: string }) => orderById.push(row.id));

    // remove all rows have orderById
    // get all rows diffrent id on the orderById
    const filterListData = addIdForList.filter((row: { id: string }) => !orderById.includes(row.id));

    // remove all rows have salesTotal is null
    const fiterListDataBySalesTotal = filterListData.filter((row: MachineStatus) => row.salesTotal !== null);

    // merge fiterListDataBySalesTotal and  dataByStatus
    const mergeData = fiterListDataBySalesTotal.concat(dataByStatus);

    // Create order id for newData
    const orderNewDataById: string[] = [];
    mergeData.filter((row: { id: string }) => orderNewDataById.push(row.id));

    // remove all rows have orderNewDataById
    const removeRowsByid = addIdForList.filter((row: { id: string }) => !orderNewDataById.includes(row.id));

    const newData = mergeData.concat(removeRowsByid);

    return newData;
  };

  /* ************ Event ************ */

  // オーバーペイ即対応
  const handleClickOk = async (): Promise<void> => {
    try {
      if (
        targetAlertInfo.alertId === null ||
        targetAlertInfo.stationId === null ||
        targetAlertInfo.versionNo === null
      ) {
        return;
      }
      await stopMachine(targetAlertInfo.alertId, targetAlertInfo.stationId, targetAlertInfo.versionNo);
      setTargetAlertInfo({ alertId: null, stationId: null, versionNo: null }); // reset
      setOpenConfirmDialog(false);
      setOpenInfoDialog(true);
      callGetAlertInfoApi(sort);
    } catch (error) {
      setOpenConfirmDialog(false);
      throw error;
    }
  };

  // 対応列
  const handleChangeCorStatus =
    (alertId: number, versionNo: number, stationId: number) => async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const cpStatus = Number(event.target.value);
      targetAlertStatus.current = { alertId, cpStatus, stationId, versionNo };
      // 保留をクリックした場合、ダイアログを表示（TOP画面のみ）
      if (cpStatus === Number(CLS.CORRESPOND_STATUS.PEND) && isTop) {
        setOpenPendingDialog(true);
        return;
      }
      // アラートリセットをクリックした場合、ダイアログを表示
      if (cpStatus === Number(CLS.CORRESPOND_STATUS.ALERT_RESET)) {
        setOpenAlertResetDialog(true);
        return;
      }

      await updateAlertStatus(alertId, cpStatus, versionNo, stationId);
      callGetAlertInfoApi(sort);
    };

  // ダイアログ汎用
  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
  };

  // 状態ボタン
  // BNAL-287 オーバーペイ即対応ボタンを「オーバーペイ」表記に変更
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOpenConfirmDialog = (alertId: number, stationId: number, versionNo: number) => {
    setTargetAlertInfo({ alertId, stationId, versionNo });
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = (): void => {
    setOpenConfirmDialog(false);
    setOpenPendingDialog(false);
    setOpenAlertResetDialog(false);
  };

  // 保留・アラートリセット ダイアログ 共通処理
  const handleClickAlertUpdateOk = async () => {
    if (
      targetAlertStatus.current.alertId &&
      targetAlertStatus.current.cpStatus &&
      targetAlertStatus.current.stationId &&
      targetAlertStatus.current.versionNo !== null
    ) {
      try {
        await updateAlertStatus(
          targetAlertStatus.current.alertId,
          targetAlertStatus.current.cpStatus,
          targetAlertStatus.current.versionNo,
          targetAlertStatus.current.stationId,
        );
        handleCloseConfirmDialog();
        callGetAlertInfoApi(sort);
      } catch (error) {
        handleCloseConfirmDialog();
      }
    }
  };

  /* ******** Item Control  ******** */
  /* ******** Table Column  ******** */
  // status/状態列
  const SatusColumn: React.FC<{
    alertId: number | null;
    stationId: number | null;
    status: number | null;
    corStatus: number | null;
    payoutTotal: number | null;
    versionNo: number | null;
  }> = (props) => {
    const { alertId, stationId, status, corStatus, payoutTotal, versionNo } = props;
    const CLS_STATUS = CLS.ALERT_STATUS;

    if (
      alertId == null ||
      stationId == null ||
      versionNo == null ||
      corStatus == null ||
      status == null ||
      ((status <= 0 || status >= 4) && !payoutTotal) ||
      corStatus === Number(CLS.CORRESPOND_STATUS.PROCESSED)
    ) {
      return <>---</>; // アラート情報なし、ペイアウトが0、対応が「対応済み」
    }

    const strStatus = status.toString();
    const statusName = getClassificationName(CLS_STATUS.CATEGORY_CD, strStatus);
    // BNAL-287 オーバーペイ即対応ボタンを「オーバーペイ」表記に変更
    // if (!(strStatus === CLS_STATUS.ORVERPAY || strStatus === CLS_STATUS.ORVERPAY_DONE)) {
    //   return <>{statusName}</>; // オーバーペイ即対応、オーバーペイ対応済み以外の場合
    // }
    // if (strStatus === CLS_STATUS.ORVERPAY && corStatus.toString() === CLS.CORRESPOND_STATUS.NEW) {
    //   return (
    //     <StatusButton onClick={() => handleOpenConfirmDialog(alertId, stationId, versionNo)}>
    //       オーバーペイ
    //       <br />
    //       即対応
    //     </StatusButton>
    //   );
    // }
    if (strStatus === CLS_STATUS.ORVERPAY_DONE) {
      return (
        <StatusButton disabled>
          オーバーペイ
          <br />
          対応済み
        </StatusButton>
      );
    }
    return <>{statusName}</>;
  };

  // correspondenceStatus/対応列
  const CorSatusColumn: React.FC<{
    alertId: number | null;
    versionNo: number | null;
    stationId: number | null;
    status: number | null;
    corStatus: number | null;
  }> = (props) => {
    const { alertId, versionNo, stationId, status, corStatus } = props;

    if (alertId === null || versionNo === null || stationId === null || !corStatus) {
      return <></>; // アラート情報がない場合
    }
    if (status === Number(CLS.ALERT_STATUS.NETWORK_ERROR)) {
      return <></>; // 通信不能の場合
    }
    if (corStatus === Number(CLS.CORRESPOND_STATUS.NATURAL_SOLVED)) {
      return <>自然解消</>;
    }
    if (corStatus === Number(CLS.CORRESPOND_STATUS.PROCESSED)) {
      return <>対応済み</>;
    }

    let dataSouceStatus;
    // ペイアウト系エラー（オーバーペイ、アンダーペイ）の選択肢
    if (status === Number(CLS.ALERT_STATUS.ORVERPAY) || status === Number(CLS.ALERT_STATUS.UNDERPAY)) {
      dataSouceStatus = getClsfcnByCategory(CLS.CORRESPOND_STATUS.CATEGORY_CD).filter((cls) =>
        corStatusPayoutSelectList.includes(cls.value),
      );
    } else {
      // 上記以外
      dataSouceStatus = getClsfcnByCategory(CLS.CORRESPOND_STATUS.CATEGORY_CD).filter((cls) =>
        corStatusSelectList.includes(cls.value),
      );
    }

    return (
      <CorSelectBox
        name="correspondenceStatus"
        dataSource={dataSouceStatus}
        value={corStatus}
        onChange={handleChangeCorStatus(alertId, versionNo, stationId)}
      />
    );
  };

  // テーブル列定義
  const headerColumns = [
    { id: 'occurrenceDate', label: '発生日時', width: 79 },
    { id: 'machineName', label: '機種名', width: 87 },
    { id: 'stationName', label: 'ｽﾃｰｼｮﾝ名', width: 115 },
    { id: 'giftName', label: '景品名', width: 240 },
    { id: 'salesTotal', label: '売上', width: 87 },
    { id: 'payoutTotal', label: 'PO数', width: 79 },
    { id: 'costRate', label: '原価率', width: 79 },
    { id: 'status', label: '状態', width: 145 },
    { id: 'correspondenceStatus', label: '対応', width: 119 },
    { id: 'correspondenceDate', label: '対応時間', width: 79 },
  ];

  // 行の色
  const rowClass = (cpStatus: number | null): string => {
    if (
      !cpStatus ||
      cpStatus.toString() === CLS.CORRESPOND_STATUS.DONE ||
      cpStatus.toString() === CLS.CORRESPOND_STATUS.NATURAL_SOLVED ||
      cpStatus.toString() === CLS.CORRESPOND_STATUS.PROCESSED
    ) {
      // アラートなし、完了、自然解消、対応済み
      return classes.closed;
    }
    if (cpStatus.toString() === CLS.CORRESPOND_STATUS.PEND) {
      // 保留
      return classes.holding;
    }
    // 未対応
    return classes.outStanding;
  };

  return (
    <>
      <TableContainer id="scrollContainer" className={className?.root || classes.default}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell key={column.id} style={{ width: column.width }} className={classes.head}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {generateFilteredRow(rows)
              .slice(0, isTop ? topAlertCount : rows.length) // TOP画面と機器状況一覧で表示件数を分ける
              .map((row) => {
                const key = `${row.stationId}_${row.alertId}`;
                const occurrenceDate = row.occurrenceDate
                  ? formatDatetimeForList(new Date(row.occurrenceDate))
                  : ['', ''];
                const correspondenceDate = row.correspondenceDate
                  ? formatDatetimeForList(new Date(row.correspondenceDate))
                  : ['', ''];
                return (
                  <TableRow key={key} className={rowClass(row.correspondenceStatus)}>
                    <TableCell className={classes.tdOccurensDate}>
                      {occurrenceDate[0]}
                      <br />
                      {occurrenceDate[1]}
                    </TableCell>
                    <TableCell className={classes.tdMachineName}>
                      <div className="cellStyle">{row.machineName}</div>
                    </TableCell>
                    <TableCell className={classes.tdStationName}>
                      <div className="cellStyle">{row.stationName}</div>
                    </TableCell>
                    <TableCell className={classes.tdGiftName}>
                      <TooltipComponent tooltipText={row.giftName} />
                    </TableCell>
                    <TableCell className={classes.tdSalesTotal}>
                      <div className="cellStyle">
                        {/* 対応が「対応済み」以外は売り上げを表示 */}
                        {row.status !== Number(CLS.ALERT_STATUS.NETWORK_ERROR) &&
                          row.correspondenceStatus !== Number(CLS.CORRESPOND_STATUS.PROCESSED) &&
                          row.salesTotal &&
                          formatCurrency(row.salesTotal)}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tdPayoutTotal}>
                      <div className="cellStyle">
                        {/* 対応が「対応済み」以外はPO数を表示 */}
                        {row.status !== Number(CLS.ALERT_STATUS.NETWORK_ERROR) &&
                          row.correspondenceStatus !== Number(CLS.CORRESPOND_STATUS.PROCESSED) &&
                          row.payoutTotal}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tdCostRate}>
                      <div className="cellStyle">
                        {/* 対応が「対応済み」以外は原価率を表示 */}
                        {row.status !== Number(CLS.ALERT_STATUS.NETWORK_ERROR) &&
                          row.correspondenceStatus !== Number(CLS.CORRESPOND_STATUS.PROCESSED) &&
                          row.costRate &&
                          `${String(row.costRate)}%`}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tdStatus}>
                      <SatusColumn
                        alertId={row.alertId}
                        stationId={row.stationId}
                        status={row.status}
                        corStatus={row.correspondenceStatus}
                        payoutTotal={row.payoutTotal}
                        versionNo={row.versionNo}
                      />
                    </TableCell>
                    <TableCell className={classes.tdCorrespondenceStatus}>
                      <CorSatusColumn
                        alertId={row.alertId}
                        versionNo={row.versionNo}
                        stationId={row.stationId}
                        status={row.status}
                        corStatus={row.correspondenceStatus}
                      />
                    </TableCell>
                    <TableCell className={classes.tdCorrespondenceDate}>
                      {correspondenceDate[0]}
                      <br />
                      {correspondenceDate[1]}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/** オーバーぺー即対応 確認ダイアログ */}
      <ConfirmDialog
        open={openConfirmDialog}
        msg={MESSAGES.INF211}
        detailMsg={MESSAGES.INF212}
        onOk={handleClickOk}
        onCancel={handleCloseConfirmDialog}
        addClass={{
          contentBox: classes.contentBox,
          contentText: classes.contentText,
        }}
      />
      <InfoDialog
        open={openInfoDialog}
        msg={MESSAGES.INF213}
        detailMsg={MESSAGES.INF214}
        closeFunc={handleCloseInfoDialog}
      />
      {/* アラートリセット押下時 ダイアログ */}
      <ConfirmDialog
        open={openAlertResetDialog}
        msg={MESSAGES.INF219}
        onOk={handleClickAlertUpdateOk}
        onCancel={handleCloseConfirmDialog}
      />
      {/* 保留押下時 ダイアログ（TOP画面のみ） */}
      <InfoDialog open={openPendingDialog} msg={MESSAGES.INF233} closeFunc={handleClickAlertUpdateOk} />
    </>
  );
};

export default MachineStatusTable;
