import React, { memo } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { makeStyles } from '@material-ui/core/styles';
import iconBear from 'assets/images/IconBear.svg';
import iconPrizeMachine from 'assets/images/IconPrizeMachine.svg';
import iconPerson from 'assets/images/IconPerson.svg';
import { Box } from '@material-ui/core';

/**
 * MaintenanceTopButton Component
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  buttonItem: {
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    fontSize: '22px',
    lineHeight: '28px',
    margin: '50px 0px 50px 32px',
    padding: '10px 0px 10px 0px',
    width: '336px',
    height: '536px',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
    '& .caption': {
      position: 'absolute',
      top: '48px',
      width: '272px',
    },
    '& .title': {
      height: '104px',
      marginBottom: '36px',
      fontSize: '40px',
      lineHeight: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .description': {
      height: '140px',
    },
    '& .descriptionStation': {
      textAlign: 'left',
    },
    '& img': {
      display: 'initial',
    },
  },
  buttonSpace: {
    marginLeft: '36px',
  },
  iconBear: {
    width: '112px',
    height: '112px',
  },
}));

type ButtonProps = {
  onClick?: () => void;
  iconName: string;
  disabled?: boolean;
};

const MaintenanceTopButton: React.FC<ButtonProps> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { onClick, iconName, disabled } = props;

  if (iconName === 'iconPrizeMachine') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonItem}`}>
        <Box className="caption">
          <p className="title">
            機器の
            <br />
            登録、削除
          </p>
          <p className="description descriptionStation">
            ・機器の新規登録、削除
            <br />
            ・店舗内の機器の一覧、
            <br />
            &nbsp;&nbsp;&nbsp;情報確認
          </p>
          <img src={iconPrizeMachine} alt={iconName} />
        </Box>
      </NormalButton>
    );
  }
  if (iconName === 'iconPerson') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonItem} ${classes.buttonSpace}`}>
        <Box className="caption">
          <p className="title">
            IDの
            <br />
            発行、停止
          </p>
          <p className="description">
            利用者のID
            <br />
            管理、発行、停止
          </p>
          <img src={iconPerson} alt={iconName} />
        </Box>
      </NormalButton>
    );
  }
  if (iconName === 'iconBear') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonItem} ${classes.buttonSpace}`}>
        <Box className="caption">
          <p className="title">景品登録</p>
          <p className="description">景品情報の登録</p>
          <img className={`${classes.iconBear}`} src={iconBear} alt={iconName} />
        </Box>
      </NormalButton>
    );
  }
  return <></>;
};

export default memo(MaintenanceTopButton);
