import React, { memo, useContext } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import homePositionButton from 'assets/images/homePositionButton.svg';
import customHomePositionButton from 'assets/images/customHomePositionButton.svg';
import endPositionButton from 'assets/images/endPositionButton.svg';
import defaultPositionButton from 'assets/images/defaultPositionButton.svg';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { TextBox } from 'components/system/atoms';
import { PositionControllerType } from 'types/machineConf/controllerType';
import COMMON from 'constants/common';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';

const RANGE_VALUE_JACK = {
  MAX_X: 100,
  MAX_Y: 80,
  MIN: 0,
};

/**
 * PositionController Component
 * ポジションコントローラー
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  xRoot: {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    width: '156px',
    '& .inputDirection': {
      color: theme.palette.common.black,
      margin: '0px 4px',
    },
    '& .buttonPre': { zIndex: 10 },
    '& .buttonPost': {
      transform: 'rotateY(180deg)',
      zIndex: 10,
    },
  },
  yRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '156px',
    width: '52px',
    '& .inputDirection': {
      color: theme.palette.common.black,
      margin: '4px 0px',
    },
    '& .buttonPre': {
      transform: 'rotate(90deg)',
    },
    '& .buttonPost': {
      transform: 'rotate(-90deg)',
      '& span': {
        transform: 'rotate(90deg)',
      },
    },
  },
  buttonRoot: {
    paddingLeft: '24px',
    minWidth: '48px',
  },
  buttonLabel: {
    maxWidth: '48px',
    color: '#FFFFFF',
  },
  buttonContained: {
    color: theme.palette.common.black,
    height: '48px',
    width: '48px',
    fontSize: '24px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  homeButtonContained: {
    background: `url(${homePositionButton})`,
    '&:active': {
      background: `url(${homePositionButton})`,
    },
  },
  endButtonContained: {
    background: `url(${endPositionButton})`,
    '&:active': {
      background: `url(${endPositionButton})`,
    },
  },
  customHomeButtonContained: {
    background: `url(${customHomePositionButton})`,
    '&:active': {
      background: `url(${customHomePositionButton})`,
    },
  },
  defaultButtonContained: {
    background: `url(${defaultPositionButton})`,
    '&:active': {
      background: `url(${defaultPositionButton})`,
    },
  },
  textBox: {
    backgroundColor: theme.palette.common.white,
    height: '52px',
    width: '52px',
  },
  input: {
    textAlign: 'center',
    fontSize: '28px',
  },
  HStyle: {
    borderColor: '#FF3E3E',
  },
  EStyle: {
    borderColor: '#3086FF',
  },
  HPStyle: {
    borderColor: '#FFB54E',
  },
  DefaultStyle: {
    borderColor: theme.rightStation.itemBorder,
  },
  activeBox: {
    position: 'relative',
    zIndex: 1400,
    borderColor: '#ff0000',
  },
}));

// コンポーネント定義
const PositionControllerJack: React.FC<PositionControllerType> = ({
  name,
  type,
  axis,
  value,
  className,
  onClickPositionController,
  focusController,
}) => {
  // makeStyleで定義したstyleを取得
  const { leftRight } = useContext(MachineConfContext); //
  const classes = useStyles({ leftRight });

  // タイプに応じた配色を定義
  let typeStyle = '';
  let buttonStyle = '';
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  switch (type) {
    case COMMON.POINTER_TYPE.HOME:
      typeStyle = classes.HStyle;
      buttonStyle = classes.homeButtonContained;
      break;
    case COMMON.POINTER_TYPE.END:
      typeStyle = classes.EStyle;
      buttonStyle = classes.endButtonContained;
      break;
    case COMMON.POINTER_TYPE.CUSTOM_HOME:
      typeStyle = classes.HPStyle;
      buttonStyle = classes.customHomeButtonContained;
      break;
    default:
      typeStyle = classes.DefaultStyle;
      buttonStyle = classes.defaultButtonContained;
      break;
  }

  const isNormal = axis === 'X' && leftRight === COMMON.LEFT_RIGHT.LEFT;

  const handleClick = (inclementValue: number) => () => {
    const resultValue = Number(value) + inclementValue;
    // 0～100の範囲を超えた場合は無効とする。
    if (axis === 'X' && RANGE_VALUE_JACK.MIN <= resultValue && resultValue <= RANGE_VALUE_JACK.MAX_X) {
      onClickPositionController(String(resultValue), name);
    }

    if (axis === 'Y' && RANGE_VALUE_JACK.MIN <= resultValue && resultValue <= RANGE_VALUE_JACK.MAX_Y) {
      onClickPositionController(String(resultValue), name);
    }
  };

  return (
    <Box className={axis === 'X' ? classes.xRoot : classes.yRoot}>
      <NormalButton
        variant="contained"
        classes={{
          root: `${classes.buttonRoot} buttonPre`,
          label: `${classes.buttonLabel} ${className}`,
          contained: `${classes.buttonContained} ${buttonStyle}`,
        }}
        onClick={handleClick(isNormal ? -1 : 1)}>
        {isNormal ? '－' : '＋'}
      </NormalButton>
      <TextBox
        classes={{
          root: `${classes.textBox} ${typeStyle} inputDirection ${focusController ? classes.activeBox : ''}`,
          input: classes.input,
        }}
        disabled
        value={value}
      />
      <NormalButton
        variant="contained"
        classes={{
          root: `${classes.buttonRoot} buttonPost`,
          label: `${classes.buttonLabel} ${className}`,
          contained: `${classes.buttonContained} ${buttonStyle}`,
        }}
        onClick={handleClick(isNormal ? 1 : -1)}>
        {isNormal ? '＋' : '－'}
      </NormalButton>
    </Box>
  );
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(PositionControllerJack);
