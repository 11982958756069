import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import redirectLinks from 'constants/redirectLinks';
import { useLocation } from 'react-router-dom';
import { getUserAuthThunk } from '../../redux/slices/commonSlice';
import { useAppDispatch } from '../../redux/hooks';
import HttpConnection from '../../utils/httpConnection';
import { REST_API } from '../../constants/apiUrls';

// PDF.js worker設定
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// CSS定義
const useStyles = makeStyles(() => ({
  pdfDocument: {
    color: '#FFFFFF',
  },
  pdfPage: {
    margin: '10px 0 10px 0',
  },
}));

/* ************ Type ************ */

/* ************ Component ************ */
const OperationExamplePdf: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const classes = useStyles();
  const [file, setFile] = React.useState<string>('');
  const [numPages, setNumPages] = React.useState<number>();
  const [noData, setNoData] = React.useState<boolean>(false);
  const [render, setRender] = React.useState<string>('svg');

  useEffect(() => {
    dispatch(getUserAuthThunk());
    getPdfUrl();
  }, []);

  // PDFファイル取得
  const getPdfUrl = async () => {
    const path = location.pathname.replace(redirectLinks.OPERATION_EXAMPLE_PDF, '').replace(/\//g, '');
    if (!path) {
      setNoData(true);
      return;
    }
    const http = new HttpConnection({ dispatch });
    let data = null;
    try {
      data = await http.getFile(`${REST_API.MANUAL_NOTICE.DOWNLOAD_MANUAL}/${path}`);
    } catch (error) {
      setNoData(true);
      return;
    }
    const pdfUrl = window.URL.createObjectURL(new Blob([data]));
    setFile(pdfUrl);
  };

  function onDocumentLoadSuccess({ numPages: numPageAll }: PDFDocumentProxy): void {
    setNumPages(numPageAll);
  }
  function onRenderError(): void {
    setRender('canvas');
  }

  return (
    <Box className="detail">
      <div>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className={classes.pdfDocument}
          noData=""
          renderMode="svg">
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              className={classes.pdfPage}
              width={1280}
              renderAnnotationLayer={false}
              renderMode={render}
              onRenderError={onRenderError}
            />
          ))}
        </Document>
      </div>
      <Box className={classes.pdfDocument} style={{ visibility: noData ? 'visible' : 'hidden' }}>
        No PDF file.
      </Box>
    </Box>
  );
};

export default OperationExamplePdf;
