import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import { makeStyles, Box, Typography, IconButton, Grid } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import arrowIcon from 'assets/images/arrowIcon.svg';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import arrowUp from 'assets/images/arrowUp.svg';
import grayCatcherIcon from 'assets/images/grayCatcher.svg';
import redCatcherIcon from 'assets/images/redCatcher.svg';
import iconPlusWhite from 'assets/images/iconPlusWhite.svg';
import timingArrow from 'assets/images/timingArrow.svg';
import { NormalSwitch, SelectButton } from 'components/system/atoms';
import { SelectListType, TenkeyOpenEventType, TenkeyType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import MESSAGES from 'constants/messages';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import {
  setAreaConf,
  setLedServiceOtherConf,
  setOperationConf,
  setOperationConfModeOff,
} from 'redux/slices/machineConfSlice';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';
import NewTenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/NewTenkey';
import {
  AreaConfType,
  LedServiceOtherConfType,
  OperationConfType,
  LocationType,
} from 'types/machineConf/machineConfType';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import { resetDescentLimitValue } from 'utils/resetDescentLimit.helper';
import { InfoDialog } from 'components/system/organisms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import AreaDetail from 'components/machineConf/organisms/Area/AreaDetail';
import AreaSimplePit from 'components/machineConf/organisms/Area/AreaSimplePit';
import { countAreaTotal, hasAreas, toArrayAreaLine } from 'utils/machineConf.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'プレイヤーサポート設定',
  screenId: 'SCR333',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  MOD_SETTING: 'modeSetting',
  SUPPORT: {
    GIFT_COST: 'giftCost',
    TARGET_COST_RATE: 'targetCostRate',
  },
  GRAB: {
    ARM_OPEN: 'armOpen',
    L_POWER: 'grabLPower',
    R_POWER: 'grabRPower',
    L_POWER_2: 'grabLPower2',
    R_POWER_2: 'grabRPower2',
    L_POWER_3: 'grabLPower3',
    R_POWER_3: 'grabRPower3',
    L_POWER_4: 'grabLPower4',
    R_POWER_4: 'grabRPower4',
    L_POWER_5: 'grabLPower5',
    R_POWER_5: 'grabRPower5',
    L_POWER_6: 'grabLPower6',
    R_POWER_6: 'grabRPower6',
  },
  BASIC: {
    L_POWER_1: 'lPower1',
    R_POWER_1: 'rPower1',
    L_POWER_2: 'lPower2',
    R_POWER_2: 'rPower2',
    L_POWER_3: 'lPower3',
    R_POWER_3: 'rPower3',
    L_POWER_4: 'lPower4',
    R_POWER_4: 'rPower4',
    L_POWER_5: 'lPower5',
    R_POWER_5: 'rPower5',
    L_POWER_6: 'lPower6',
    R_POWER_6: 'rPower6',
  },
  MOVING: {
    L_POWER: 'movingLPower',
    R_POWER: 'movingRPower',
    L_POWER_2: 'movingLPower2',
    R_POWER_2: 'movingRPower2',
    L_POWER_3: 'movingLPower3',
    R_POWER_3: 'movingRPower3',
    L_POWER_4: 'movingLPower4',
    R_POWER_4: 'movingRPower4',
    L_POWER_5: 'movingLPower5',
    R_POWER_5: 'movingRPower5',
    L_POWER_6: 'movingLPower6',
    R_POWER_6: 'movingRPower6',
  },
  TIMING: {
    TIMING_1: 'timing1',
    TIMING_2: 'timing2',
    TIMING_3: 'timing3',
    TIMING_4: 'timing4',
    TIMING_5: 'timing5',
    TIMING_6: 'timing6',
  },
};
const TENKEY_POWER = {
  MIN: 0.1,
  MAX: 200,
};
const TENKEY_GIFT_RATE = {
  MIN: 0,
  MAX: 1100,
};
const TENKEY_OPTION = {
  ARM_POWER: {
    mode: COMMON.TENKEY_MODE.DECIMAL,
    integerSize: 3,
    scaleSize: 1,
    maxValue: TENKEY_POWER.MAX,
    minValue: TENKEY_POWER.MIN,
    decimalLimit: 50,
    decimalLimit2: 100,
  },
  GIFT_COST: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 4,
    scaleSize: 0,
    maxValue: TENKEY_GIFT_RATE.MAX,
    minValue: TENKEY_GIFT_RATE.MIN,
  },
};
const INITIAL_LINE = '000000';
const TIMING_LABEL = [
  { index: 3, label: '20cm' },
  { index: 6, label: 'MAX' },
  { index: 2, label: '15cm' },
  { index: 5, label: '30cm' },
  { index: 1, label: '10cm' },
  { index: 4, label: '25cm' },
  { index: 0, label: '5cm' },
];

/* ********** create data ********** */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '88px',
    '& .modeArea': {
      position: 'absolute',
      top: '0px',
      left: '232px',
      height: '72px',
      width: '272px',
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0px 0px 18px',
      fontSize: '20px',
      zIndex: 200,
    },
    '& .description': {
      position: 'absolute',
      top: '0px',
      left: '528px',
      fontSize: '20px',
      padding: '8px 0 0',
    },
  },
  mainArea: {
    display: 'flex',
    '& .basicArmArea': {
      height: '180px',
      width: '288px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 8px 16px 8px',
      },
    },
    '& .grabToBasicArrow': {
      position: 'absolute',
      width: '54px',
      height: '105px',
      top: '231px',
      left: '117px',
      zIndex: 1,
    },
    '& .basicToMovingArrow': {
      position: 'absolute',
      width: '54px',
      height: '105px',
      top: '100px',
      left: '264px',
      zIndex: 1,
      transform: 'rotate(90deg)',
    },
    '& .grabArmArea': {
      position: 'absolute',
      top: '344px',
      left: '0px',
      height: '220px',
      width: '288px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px',
      },
      '& .main': {
        margin: '44px 0px 0px 0px',
      },
      '& .armOpen': {
        margin: '0 auto',
      },
      '& .armOpenLabel': {
        position: 'absolute',
        bottom: '8px',
        left: '8px',
      },
    },
    '& .movingArmArea': {
      position: 'absolute',
      top: '88px',
      left: '296px',
      width: '288px',
      height: '180px',
      '& .itemArea': {
        height: '100%',
        padding: '8px 8px 16px 8px',
        justifyContent: 'space-between',
      },
    },
    '& .areaConfArea': {
      position: 'absolute',
      top: '88px',
      left: '592px',
      height: '474px',
      width: '235px',
      '& .top': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '4px 12px 0px',
      },
      '& .description': {
        margin: '2px auto 0px 10px',
        fontSize: '16px',
        lineHeight: '24px',
        height: '48px',
      },
      '& .main': {
        position: 'relative',
        display: 'block',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        padding: '0px 10px 0px 10px',
        '& .areaConf': {
          display: 'flex',
        },
        '& .areaConfTable': {
          marginLeft: '15px',
          width: '180px',
          '& .title': {
            borderWidth: '1px 1px 0px 1px',
            borderColor: theme.base.tableBorder,
            borderStyle: 'solid',
            fontSize: '21px',
            textAlign: 'center',
            backgroundColor: '#FFFF77',
            '& span': {
              color: '#FF0000',
            },
          },
          '& .area': {
            position: 'relative',
          },
        },
        '& .addButton': {
          margin: '-12px 6px 6px 6px',
          height: '60px',
          width: '200px',
          color: theme.palette.common.white,
          boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
          justifyContent: 'flex-start',
          padding: 0,
          '& img': {
            marginLeft: '16px',
          },
          '& .caption': {
            marginLeft: '16px',
            fontSize: '19px',
          },
        },
      },
    },
    '& .timing': {
      position: 'absolute',
      bottom: '0px',
      left: '296px',
      width: '288px',
      height: '288px',
      padding: '8px',
      zIndex: 1,
      '& .timingIcon': {
        width: '44px',
        padding: '0px',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '15px',
        right: '100%',
        borderWidth: '20px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightWidth: '136px',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '17px',
        right: '98%',
        borderWidth: '18px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightWidth: '132px',
      },
      '& .container': {
        height: '100%',
        paddingTop: '8px',
      },
      '& .item': {
        display: 'flex',
        alignItems: 'center',
        '&:nth-child(even)': {
          paddingLeft: '20px',
        },
      },
      '& .label': {
        fontSize: '19px',
        marginLeft: '16px',
      },
      '& > img': {
        position: 'absolute',
        height: '16px',
        // キャッチャーアイコンと被るのを防ぐため、z-indexを調整
        zIndex: -1,
      },
      '& .arrow1': {
        top: '210px',
        left: '26px',
      },
      '& .arrow2': {
        top: '150px',
        left: '26px',
      },
      '& .arrow3': {
        top: '92px',
        left: '26px',
      },
      '& .arrow4': {
        top: '69px',
        left: '124px',
        height: '156px',
      },
      '& .arrow5': {
        top: '150px',
        left: '180px',
      },
      '& .arrow6': {
        top: '92px',
        left: '180px',
      },
    },
    '& .getSupportArea': {
      position: 'absolute',
      top: '88px',
      left: '836px',
      height: '360px',
      width: '244px',
      alignItems: 'center',
      '& .top': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '4px 12px 0px',
      },
      '& .main': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '100%',
      },
      '& .description': {
        margin: '16px 0px 0px 0px',
        fontSize: '16px',
        lineHeight: '24px',
        height: '98px',
      },
      '& .getSupportInnerArea': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '20px',
        justifyContent: 'flex-start',
        height: '76px',
        paddingLeft: '16px',
        width: '220px',
        '& .label': {
          margin: '0px 20px 0px 0px',
        },
        '& .postLabel': {
          alignItems: 'flex-end',
          display: 'flex',
          margin: '0px 0px 24px 4px',
          height: '100%',
        },
      },
    },
    '& .doneArea': {
      position: 'absolute',
      top: '462px',
      left: '900px',
    },
    '& .area0': {
      backgroundColor: theme.base.area0,
      '&:after': {
        borderRightColor: theme.base.area0,
      },
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
      '&:after': {
        borderRightColor: theme.base.area1,
      },
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
      '&:after': {
        borderRightColor: theme.base.area2,
      },
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
      '&:after': {
        borderRightColor: theme.base.area3,
      },
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
      '&:after': {
        borderRightColor: theme.base.area4,
      },
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
      '&:after': {
        borderRightColor: theme.base.area5,
      },
    },
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  disableAreaConfArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '0 0 8px 8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  disableGetSupportArea: {
    position: 'absolute',
    height: '87%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '0 0 8px 8px',
    zIndex: 100,
    top: '48px',
    left: '0px',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  titleBaseArea: {
    minWidth: 140,
  },
  baseAreaFlex: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  baseAreaArm: {
    '& .main': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .bottom': {
      margin: 'auto 0px 4px 0px',
    },
    '& .icon': {
      position: 'absolute',
      top: '32px',
      left: '0px',
      right: '0px',
      margin: '0 auto',
      width: '72px',
    },
  },
  returnButton: {
    fontSize: '20px',
    lineHeight: '28px',
    zIndex: 200,
  },
  payoutSupport: {
    margin: '0px 0px 0px 12px',
    width: '80px',
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  caption: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  selectButton: {
    width: '100px',
    height: '56px',
    fontSize: '24px',
  },
  giftCostArea: {
    margin: '16px 0px 0px 0px',
  },
  targetCostArea: {
    margin: '8px 0px 0px 0px',
  },
  leftStyle: {
    backgroundColor: '#fff',
    borderColor: theme.leftStation.itemBorder,
    '&:before': {
      borderRightColor: theme.leftStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  rightStyle: {
    backgroundColor: '#fff',
    borderColor: theme.rightStation.itemBorder,
    '&:before': {
      borderRightColor: theme.rightStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  leftStyleButton: {
    backgroundColor: theme.leftStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.leftStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.leftStation.contentsBorder,
    },
  },
  rightStyleButton: {
    backgroundColor: theme.rightStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.rightStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.rightStation.contentsBorder,
    },
  },
  areaSimplePit: {
    position: 'absolute',
  },
  baseAreaSelect: {
    height: '94px',
    '& .iconButton': {
      padding: 0,
    },
    '& .area': {
      height: '40px',
      width: '40px',
      border: '2px solid #707070',
      margin: '0 15px 7px',
    },
    '& .selectedArea': {
      border: '2px solid #FF0000',
    },
  },
}));

/* ******** Main Component ******** */
/**
 * NewPayoutSupportConf Screen プレイヤーサポート画面
 *
 * @author atsushi.teruya
 * @returns {React.FC} - プレイヤーサポート画面
 *
 */
const NewPayoutSupportConf: React.FC = () => {
  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();

  /* ************ state ************ */
  // global (Redux)
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineConf.ledServiceOtherConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);
  // local
  const [tempOperationConf, setTempOperationConf] = useState<OperationConfType | undefined>(operationConf);
  const [tempLedServiceOtherConf, setTempLedServiceOtherConf] = useState<LedServiceOtherConfType | undefined>(
    ledServiceOtherConf,
  );
  const [tempAreaConf, setTempAreaConf] = useState<AreaConfType | undefined>(areaConf);
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tenkey, setTenkey] = useState(INITIAL_TEN_KEY.tenkey);
  const [openConfirmDialogModeSetting, setOpenConfirmDialogModeSetting] = useState(false);
  const [openConfirmDialogModeSettingOff, setOpenConfirmDialogModeSettingOff] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  // エリア設定トグル
  const [toggleAreaConf, setToggleAreaConf] = useState(areaConf?.areaConf === COMMON.SWITCH.ON);
  // エリアボックス有無['ベースエリア', 'エリア緑', 'エリア紫', 'エリア黄', 'エリア赤', 'エリア青'];
  const SETTING_AREA =
    tempAreaConf?.areaConf === COMMON.SWITCH.ON ? hasAreas(tempAreaConf) : [true, false, false, false, false, false];
  // 設定エリア抽出
  const concatLine = toArrayAreaLine(tempAreaConf).join('');
  const uniqueNumberLine = Array.from(new Set(concatLine));
  // エリアボックスの配置先頭(CSSクラス名)
  const firstAreaBox =
    tempAreaConf?.areaConf === COMMON.SWITCH.ON ? `area${Math.min(...uniqueNumberLine.map(Number))}` : 'area0';
  // エリア設定 エリアボックス選択状態
  const [areaSelected, setAreaSelected] = useState(firstAreaBox);
  const [areaSelectedSave, setAreaSelectedSave] = useState('');
  // 画面アームパワーおよびタイミング
  const [dispPowerTiming, setDispPowerTiming] = useState({
    lPower: '',
    rPower: '',
    grabLPower: '',
    grabRPower: '',
    movingLPower: '',
    movingRPower: '',
    timing: '',
  });

  // dataSource
  const dataSouceGrabArmOpenList = useRef(getClsfcnByCategory(CLSFCN.ARM_OPEN.CATEGORY_CD));
  const dataSouceTargetCostRate = useRef(getClsfcnByCategory(CLSFCN.NEW_PAYOUT_TARGET_COST_RATE.CATEGORY_CD));

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;
  const stationStyleButton = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyleButton : classes.rightStyleButton;
  const isNewPayoutSupportDisable = tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT;
  const isGetSupportDisable = tempOperationConf?.getSupport === COMMON.SWITCH.OFF;

  /* ************ Event ************ */
  // 初期表示処理
  useEffect(() => {
    if (!operationConf || !areaConf || !pitConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
    // エリア設定画面から遷移した場合
    if (location.state && (location.state as LocationType).fromScreen === redirectLinks.MCONF_STATION_CONF_AREA_CONF) {
      // モード設定ON
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              operationConf: '1',
            }
          : prevState,
      );
      // エリア設定有効化
      enableAreaConf();
    }
  }, []);

  // エリア設定変更時（初期表示時も通過）
  useEffect(() => {
    if (!toggleAreaConf) {
      // エリア設定トグルOFF
      setAreaSelected('area0'); // area0を設定
      return;
    }
    if (areaSelectedSave) {
      // ON→OFFで保持したエリア選択が存在時
      setAreaSelected(areaSelectedSave); // 保持したエリア選択を設定
      setAreaSelectedSave(''); // 保持したエリア選択をクリア
      return;
    }
    // エリア選択に先頭エリア設定
    setAreaSelected(firstAreaBox);
  }, [toggleAreaConf]);

  // 選択エリアボックス、各パワー設定値、タイミング変更時（初期表示時も通過）
  useEffect(() => {
    if (!tempAreaConf || !tempOperationConf) return;
    // 画面表示アームパワーおよびタイミングを切替
    if (areaSelected === 'area0') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower1),
        rPower: String(tempAreaConf.rPower1),
        grabLPower: String(tempOperationConf.grabLPower),
        grabRPower: String(tempOperationConf.grabRPower),
        movingLPower: String(tempOperationConf.movingLPower),
        movingRPower: String(tempOperationConf.movingRPower),
        timing: String(tempOperationConf.timing1),
      });
    } else if (areaSelected === 'area1') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower2),
        rPower: String(tempAreaConf.rPower2),
        grabLPower: String(tempOperationConf.grabLPower2),
        grabRPower: String(tempOperationConf.grabRPower2),
        movingLPower: String(tempOperationConf.movingLPower2),
        movingRPower: String(tempOperationConf.movingRPower2),
        timing: String(tempOperationConf.timing2),
      });
    } else if (areaSelected === 'area2') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower3),
        rPower: String(tempAreaConf.rPower3),
        grabLPower: String(tempOperationConf.grabLPower3),
        grabRPower: String(tempOperationConf.grabRPower3),
        movingLPower: String(tempOperationConf.movingLPower3),
        movingRPower: String(tempOperationConf.movingRPower3),
        timing: String(tempOperationConf.timing3),
      });
    } else if (areaSelected === 'area3') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower4),
        rPower: String(tempAreaConf.rPower4),
        grabLPower: String(tempOperationConf.grabLPower4),
        grabRPower: String(tempOperationConf.grabRPower4),
        movingLPower: String(tempOperationConf.movingLPower4),
        movingRPower: String(tempOperationConf.movingRPower4),
        timing: String(tempOperationConf.timing4),
      });
    } else if (areaSelected === 'area4') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower5),
        rPower: String(tempAreaConf.rPower5),
        grabLPower: String(tempOperationConf.grabLPower5),
        grabRPower: String(tempOperationConf.grabRPower5),
        movingLPower: String(tempOperationConf.movingLPower5),
        movingRPower: String(tempOperationConf.movingRPower5),
        timing: String(tempOperationConf.timing5),
      });
    } else if (areaSelected === 'area5') {
      setDispPowerTiming({
        lPower: String(tempAreaConf.lPower6),
        rPower: String(tempAreaConf.rPower6),
        grabLPower: String(tempOperationConf.grabLPower6),
        grabRPower: String(tempOperationConf.grabRPower6),
        movingLPower: String(tempOperationConf.movingLPower6),
        movingRPower: String(tempOperationConf.movingRPower6),
        timing: String(tempOperationConf.timing6),
      });
    }
  }, [areaSelected, tempAreaConf, tempOperationConf]);

  const handleCloseTenkey = useCallback(() => setTenkey(INITIAL_TEN_KEY.tenkey), []);

  // Tenkey 決定（operationConf）
  const handleDecision = useCallback(
    (value: string, name: string) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempOperationConf],
  );

  const handleDecisionLed = useCallback(
    (value: string, name: string) => {
      setTempLedServiceOtherConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempLedServiceOtherConf],
  );

  // Tenkey 決定（areaConf）
  const handleDecisionArea = useCallback(
    (value: string, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempAreaConf],
  );

  const initialTenkeyArmPower: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecision,
    onClose: handleCloseTenkey,
  };

  // Tenkey - BasicLPower1
  const handleOpenBasicLPower: TenkeyOpenEventType = useCallback(() => {
    let nameLPower = '';
    if (areaSelected === 'area0') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_1;
    } else if (areaSelected === 'area1') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_2;
    } else if (areaSelected === 'area2') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_3;
    } else if (areaSelected === 'area3') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_4;
    } else if (areaSelected === 'area4') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_5;
    } else if (areaSelected === 'area5') {
      nameLPower = INPUT_NAMES.BASIC.L_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 120 },
      name: nameLPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: dispPowerTiming.lPower,
      onDecision: handleDecisionArea,
    });
  }, [
    tempAreaConf,
    tempAreaConf?.lPower2,
    tempAreaConf?.lPower3,
    tempAreaConf?.lPower4,
    tempAreaConf?.lPower5,
    tempAreaConf?.lPower6,
    areaSelected,
  ]);

  // Tenkey - BasicRPower1
  const handleOpenBasicRPower: TenkeyOpenEventType = useCallback(() => {
    let nameRPower = '';
    if (areaSelected === 'area0') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_1;
    } else if (areaSelected === 'area1') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_2;
    } else if (areaSelected === 'area2') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_3;
    } else if (areaSelected === 'area3') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_4;
    } else if (areaSelected === 'area4') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_5;
    } else if (areaSelected === 'area5') {
      nameRPower = INPUT_NAMES.BASIC.R_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 350 },
      name: nameRPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: dispPowerTiming.rPower,
      onDecision: handleDecisionArea,
    });
  }, [
    tempAreaConf?.rPower1,
    tempAreaConf?.rPower2,
    tempAreaConf?.rPower3,
    tempAreaConf?.rPower4,
    tempAreaConf?.rPower5,
    tempAreaConf?.rPower6,
    areaSelected,
  ]);

  // Tenkey - GrabLPower
  const handleOpenGrabLPower: TenkeyOpenEventType = useCallback(() => {
    let nameLPower = '';
    if (areaSelected === 'area0') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER;
    } else if (areaSelected === 'area1') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER_2;
    } else if (areaSelected === 'area2') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER_3;
    } else if (areaSelected === 'area3') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER_4;
    } else if (areaSelected === 'area4') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER_5;
    } else if (areaSelected === 'area5') {
      nameLPower = INPUT_NAMES.GRAB.L_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 120 },
      name: nameLPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: dispPowerTiming.grabLPower,
      onDecision: handleDecision,
    });
  }, [
    tempOperationConf?.grabLPower,
    tempOperationConf?.grabLPower2,
    tempOperationConf?.grabLPower3,
    tempOperationConf?.grabLPower4,
    tempOperationConf?.grabLPower5,
    tempOperationConf?.grabLPower6,
    areaSelected,
  ]);

  // Tenkey - GrabRPower

  const handleOpenGrabRPower: TenkeyOpenEventType = useCallback(() => {
    let nameRPower = '';
    if (areaSelected === 'area0') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER;
    } else if (areaSelected === 'area1') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER_2;
    } else if (areaSelected === 'area2') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER_3;
    } else if (areaSelected === 'area3') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER_4;
    } else if (areaSelected === 'area4') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER_5;
    } else if (areaSelected === 'area5') {
      nameRPower = INPUT_NAMES.GRAB.R_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 350 },
      name: nameRPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.grabRPower) || '0',
      onDecision: handleDecision,
    });
  }, [
    tempOperationConf?.grabRPower,
    tempOperationConf?.grabRPower2,
    tempOperationConf?.grabRPower3,
    tempOperationConf?.grabRPower4,
    tempOperationConf?.grabRPower5,
    tempOperationConf?.grabRPower6,
    areaSelected,
  ]);

  // Tenkey - MovingLPower
  const handleOpenMovingLPower: TenkeyOpenEventType = useCallback(() => {
    let nameLPower = '';
    if (areaSelected === 'area0') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER;
    } else if (areaSelected === 'area1') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER_2;
    } else if (areaSelected === 'area2') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER_3;
    } else if (areaSelected === 'area3') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER_4;
    } else if (areaSelected === 'area4') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER_5;
    } else if (areaSelected === 'area5') {
      nameLPower = INPUT_NAMES.MOVING.L_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 400 },
      name: nameLPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: dispPowerTiming.movingLPower,
      onDecision: handleDecision,
    });
  }, [
    tempOperationConf?.movingLPower,
    tempOperationConf?.movingLPower2,
    tempOperationConf?.movingLPower3,
    tempOperationConf?.movingLPower4,
    tempOperationConf?.movingLPower5,
    tempOperationConf?.movingLPower6,
    areaSelected,
  ]);

  // Tenkey - MovingRPower
  const handleOpenMovingRPower: TenkeyOpenEventType = useCallback(() => {
    let nameRPower = '';
    if (areaSelected === 'area0') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER;
    } else if (areaSelected === 'area1') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER_2;
    } else if (areaSelected === 'area2') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER_3;
    } else if (areaSelected === 'area3') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER_4;
    } else if (areaSelected === 'area4') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER_5;
    } else if (areaSelected === 'area5') {
      nameRPower = INPUT_NAMES.MOVING.R_POWER_6;
    }
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 570 },
      name: nameRPower,
      option: TENKEY_OPTION.ARM_POWER,
      value: dispPowerTiming.movingRPower,
      onDecision: handleDecision,
    });
  }, [
    tempOperationConf?.movingRPower,
    tempOperationConf?.movingRPower2,
    tempOperationConf?.movingRPower3,
    tempOperationConf?.movingRPower4,
    tempOperationConf?.movingRPower5,
    tempOperationConf?.movingRPower6,
    areaSelected,
  ]);

  // Tenkey - GiftCost
  const handleOpenGiftCost: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 485 },
      name: INPUT_NAMES.SUPPORT.GIFT_COST,
      option: TENKEY_OPTION.GIFT_COST,
      value: String(tempLedServiceOtherConf?.giftCost || '0'),
      onDecision: handleDecisionLed,
    });
  }, [tempLedServiceOtherConf?.giftCost]);

  // return button change
  const clickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // No3-1. モード設定 変更 ダイアログ表示
  const handleChangeSwitchOperationConf = useCallback(
    (event) => {
      if (!tempOperationConf) return;
      if (event.target.checked) {
        // OFF→ONの場合
        if (
          tempOperationConf.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE ||
          tempOperationConf.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION
        ) {
          // ダイアログを表示する。
          setOpenConfirmDialogModeSetting(true);
        } else {
          onClickOkModeSetting();
        }
      } else {
        // ON→OFFの場合
        setOpenConfirmDialogModeSettingOff(true);
      }
    },
    [tempOperationConf?.operationConf],
  );

  // No3-2. モード設定 変更 Dialog -> Yes
  const onClickOkModeSetting = useCallback(() => {
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            operationConf: '1',
          }
        : prevState,
    );
    handleCloseConfirmDialogModeSetting();
  }, [tempOperationConf?.operationConf]);

  // No3-2. モード設定 変更 Dialog -> No
  const handleCloseConfirmDialogModeSetting = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSetting(false);
  };

  // モード設定 off Dialog -> Yes
  const onClickOkModeSettingOff = useCallback(async () => {
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    // 下降限界チェック
    const newTempAreaConf = resetDescentLimitValueByAreaConf();
    // 運営設定「設定なし」をグローバルステートに保存
    dispatch(setOperationConfModeOff(COMMON.OPERATION_CONF.NO_CONF));

    if (!newTempAreaConf?.hasReset) {
      // 下降限界チェックOKの場合
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF).then(() => {
        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
    } else {
      // 下降限界チェックNGの場合
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });
      setOpenConfirm(true);
      // reset DescentLimit
      dispatch(setAreaConf(areaConfReset));
    }
  }, []);

  // 下降限界チェック＆リセット処理（エリア設定ON/OFFによって処理を分岐）
  const resetDescentLimitValueByAreaConf = () => {
    if (!tempAreaConf) return tempAreaConf;
    if (toggleAreaConf) {
      return resetDescentLimitValue(tempAreaConf);
    }
    // エリア設定OFFの場合、ベースカラーのみ対象に下降限界チェック
    const newLine: { [key: string]: string } = {
      line1: INITIAL_LINE,
      line2: INITIAL_LINE,
      line3: INITIAL_LINE,
      line4: INITIAL_LINE,
      line5: INITIAL_LINE,
      line6: INITIAL_LINE,
    };

    const newTempAreaConf: AreaConfType = {
      ...tempAreaConf,
      ...newLine,
    };
    const resetInfo = resetDescentLimitValue(newTempAreaConf);

    Object.keys(newLine).forEach((key) => {
      if (resetInfo?.areaConf) {
        resetInfo.areaConf[key] = String(tempAreaConf[key]);
      }
    });

    return resetInfo;
  };

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // モード設定 off Dialog -> No
  const handleCloseConfirmDialogModeSettingOff = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSettingOff(false);
  };

  // No3-2. モード設定 変更 ダイアログOK
  const handleOnChangeSwitchGetSupport = useCallback(
    (event) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              getSupport: event.target.checked ? '1' : '0',
            }
          : prevState,
      );
    },
    [tempOperationConf?.getSupport],
  );

  // select list (operationConf)
  const handleClickSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempOperationConf],
  );

  const handleClickSelectListTargetCostRate = useCallback(
    (data: { value: string }, name: string) => {
      setTempLedServiceOtherConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempLedServiceOtherConf],
  );

  // select list (areaConf)
  const handleClickAreaSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempAreaConf],
  );

  const handleCloseSelectList = useCallback(() => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  const initialSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickSelectList,
    onClose: handleCloseSelectList,
  };

  const handleClickGrabArmOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSouceGrabArmOpenList.current,
        name: INPUT_NAMES.GRAB.ARM_OPEN,
        placement: 'right-end',
        title: 'アーム開度を選択してください。',
        value: tempAreaConf?.armOpen,
        onClick: handleClickAreaSelectList,
      });
    },
    [tempAreaConf?.armOpen],
  );

  const handleClickTargetCostRate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        onClick: handleClickSelectListTargetCostRate,
        anchorEL: event.currentTarget,
        dataSource: dataSouceTargetCostRate.current,
        name: INPUT_NAMES.SUPPORT.TARGET_COST_RATE,
        placement: 'left-end',
        title: '目標原価率を選択してください。',
        value: tempLedServiceOtherConf?.targetCostRate,
      });
    },
    [tempLedServiceOtherConf?.targetCostRate],
  );

  // No16. 確定ボタンクリック
  const onClickOk = async () => {
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.NEWPAYOUTSUPPORT_CONF_VALIDATION, {
        areaConf: tempAreaConf?.areaConf,
        armOpen: tempAreaConf?.armOpen,
        lPower1: tempAreaConf?.lPower1,
        rPower1: tempAreaConf?.rPower1,
        lPower2: tempAreaConf?.lPower2,
        rPower2: tempAreaConf?.rPower2,
        lPower3: tempAreaConf?.lPower3,
        rPower3: tempAreaConf?.rPower3,
        lPower4: tempAreaConf?.lPower4,
        rPower4: tempAreaConf?.rPower4,
        lPower5: tempAreaConf?.lPower5,
        rPower5: tempAreaConf?.rPower5,
        lPower6: tempAreaConf?.lPower6,
        rPower6: tempAreaConf?.rPower6,
        operationConf: tempOperationConf?.operationConf,
        grabLPower: tempOperationConf?.grabLPower,
        grabRPower: tempOperationConf?.grabRPower,
        timing1: tempOperationConf?.timing1,
        grabLPower2: tempOperationConf?.grabLPower2,
        grabRPower2: tempOperationConf?.grabRPower2,
        timing2: tempOperationConf?.timing2,
        grabLPower3: tempOperationConf?.grabLPower3,
        grabRPower3: tempOperationConf?.grabRPower3,
        timing3: tempOperationConf?.timing3,
        grabLPower4: tempOperationConf?.grabLPower4,
        grabRPower4: tempOperationConf?.grabRPower4,
        timing4: tempOperationConf?.timing4,
        grabLPower5: tempOperationConf?.grabLPower5,
        grabRPower5: tempOperationConf?.grabRPower5,
        timing5: tempOperationConf?.timing5,
        grabLPower6: tempOperationConf?.grabLPower6,
        grabRPower6: tempOperationConf?.grabRPower6,
        timing6: tempOperationConf?.timing6,
        movingLPower: tempOperationConf?.movingLPower,
        movingRPower: tempOperationConf?.movingRPower,
        movingLPower2: tempOperationConf?.movingLPower2,
        movingRPower2: tempOperationConf?.movingRPower2,
        movingLPower3: tempOperationConf?.movingLPower3,
        movingRPower3: tempOperationConf?.movingRPower3,
        movingLPower4: tempOperationConf?.movingLPower4,
        movingRPower4: tempOperationConf?.movingRPower4,
        movingLPower5: tempOperationConf?.movingLPower5,
        movingRPower5: tempOperationConf?.movingRPower5,
        movingLPower6: tempOperationConf?.movingLPower6,
        movingRPower6: tempOperationConf?.movingRPower6,
        getSupport: tempOperationConf?.getSupport,
        giftCost: tempLedServiceOtherConf?.giftCost,
        targetCostRate: tempLedServiceOtherConf?.targetCostRate,
      })
      .then(() => {
        // グローバルステートに保存
        dispatch(setOperationConf(tempOperationConf));
        dispatch(setLedServiceOtherConf(tempLedServiceOtherConf));
        dispatch(setAreaConf(tempAreaConf));
        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
  };

  // 運営設定－プレイヤーサポート モード ON OFF表示
  const displayOperationConfChecked = () =>
    tempOperationConf?.operationConf === COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT ?? false;

  const displayGetSupportChecked = () => tempOperationConf?.getSupport === COMMON.SWITCH.ON ?? false;

  // エリア設定有効化
  const enableAreaConf = () => {
    // エリア設定トグルをON
    setToggleAreaConf(true);
    // エリア設定をReduxの値に戻す
    setTempAreaConf((prevState) =>
      prevState
        ? {
            ...prevState,
            areaConf: COMMON.SWITCH.ON,
          }
        : prevState,
    );
  };

  // エリア設定トグル変更
  const handleSwitchAreaConf = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      enableAreaConf();
    } else {
      // トグルOFF
      setToggleAreaConf(false);
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              areaConf: COMMON.SWITCH.OFF,
            }
          : prevState,
      );
      // エリア選択を保持
      setAreaSelectedSave(areaSelected);
    }
  };

  // エリア追加ボタン Redirect to SCR326
  const handleClickAddArea = () => {
    history.push({
      pathname: redirectLinks.MCONF_STATION_CONF_AREA_CONF,
      state: { fromScreen: redirectLinks.MCONF_STATION_CONF_NEW_PAYOUT_SUPPORT },
    });
  };

  // キャッチャーアイコンクリック
  const onClickCatcherIcon = (num: number) => {
    let name = '';
    const timingValue = dispPowerTiming.timing
      .split('')
      .map((value, index) => {
        if (index === num) {
          return value === COMMON.SWITCH.ON ? COMMON.SWITCH.OFF : COMMON.SWITCH.ON;
        }
        return value;
      })
      .join('');

    // 操作後、表示しているタイミングがすべてOFFになる場合は処理を終了
    const noActiveTiming = timingValue.split('').every((value) => value === COMMON.SWITCH.OFF);
    if (noActiveTiming) return;

    if (areaSelected === 'area0') {
      name = INPUT_NAMES.TIMING.TIMING_1;
    } else if (areaSelected === 'area1') {
      name = INPUT_NAMES.TIMING.TIMING_2;
    } else if (areaSelected === 'area2') {
      name = INPUT_NAMES.TIMING.TIMING_3;
    } else if (areaSelected === 'area3') {
      name = INPUT_NAMES.TIMING.TIMING_4;
    } else if (areaSelected === 'area4') {
      name = INPUT_NAMES.TIMING.TIMING_5;
    } else if (areaSelected === 'area5') {
      name = INPUT_NAMES.TIMING.TIMING_6;
    }
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: timingValue,
          }
        : prevState,
    );
  };

  // エリア選択ボックスクリック
  const handleClickAreaBox = (index: number) => {
    setAreaSelected(`area${index}`);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              <ReturnButton className={classes.returnButton} onClick={clickReturn}>
                ゲーム設定
                <br />
                TOP
              </ReturnButton>
              <Box className={`${classes.baseArea} modeArea ${stationStyle}`}>
                <p className={classes.titleBaseArea}>
                  プレイヤー
                  <br />
                  サポート
                </p>
                <NormalSwitch
                  className={classes.payoutSupport}
                  leftRight={leftRight}
                  value=""
                  checked={displayOperationConfChecked()}
                  onChange={handleChangeSwitchOperationConf}
                />
              </Box>
              <p className="description">
                景品の獲得をサポートします <br />
                各種設定をし、<Typography className={classes.decisionImage}>確 定</Typography> してください
              </p>
            </Box>
            <Box className={classes.mainArea}>
              {/* 基本のアームパワー */}
              <Box
                className={`${classes.baseArea} ${classes.baseAreaArm} basicArmArea ${stationStyle} ${areaSelected}`}>
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>基本のアームパワー</p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.BASIC.L_POWER_1,
                        INPUT_NAMES.BASIC.L_POWER_2,
                        INPUT_NAMES.BASIC.L_POWER_3,
                        INPUT_NAMES.BASIC.L_POWER_4,
                        INPUT_NAMES.BASIC.L_POWER_5,
                        INPUT_NAMES.BASIC.L_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.lPower}
                      onOpen={handleOpenBasicLPower}
                    />
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.BASIC.R_POWER_1,
                        INPUT_NAMES.BASIC.R_POWER_2,
                        INPUT_NAMES.BASIC.R_POWER_3,
                        INPUT_NAMES.BASIC.R_POWER_4,
                        INPUT_NAMES.BASIC.R_POWER_5,
                        INPUT_NAMES.BASIC.R_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.rPower}
                      onOpen={handleOpenBasicRPower}
                    />
                  </Box>
                  <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                </Box>
              </Box>
              <img src={arrowIcon} alt="arrowIcon" className="grabToBasicArrow" />
              {/* つかみ時のアームパワー */}
              <Box className={`${classes.baseArea} ${classes.baseAreaArm} grabArmArea ${stationStyle} ${areaSelected}`}>
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>つかみ時のアームパワー</p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.GRAB.L_POWER,
                        INPUT_NAMES.GRAB.L_POWER_2,
                        INPUT_NAMES.GRAB.L_POWER_3,
                        INPUT_NAMES.GRAB.L_POWER_4,
                        INPUT_NAMES.GRAB.L_POWER_5,
                        INPUT_NAMES.GRAB.L_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.grabLPower}
                      onOpen={handleOpenGrabLPower}
                    />
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.GRAB.R_POWER,
                        INPUT_NAMES.GRAB.R_POWER_2,
                        INPUT_NAMES.GRAB.R_POWER_3,
                        INPUT_NAMES.GRAB.R_POWER_4,
                        INPUT_NAMES.GRAB.R_POWER_5,
                        INPUT_NAMES.GRAB.R_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.grabRPower}
                      onOpen={handleOpenGrabRPower}
                    />
                    <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                  </Box>
                  <Box className="armOpen">
                    <p className={`${classes.caption} armOpenLabel`}>
                      アーム
                      <br />
                      開度
                    </p>
                    <SelectButton
                      disabled={isNewPayoutSupportDisable}
                      leftRight={leftRight}
                      className={`${classes.selectButton}`}
                      onClick={handleClickGrabArmOpen}
                      isSelected={selectList.name === INPUT_NAMES.GRAB.ARM_OPEN}>
                      {tempAreaConf?.armOpen &&
                        getClassificationName(CLSFCN.ARM_OPEN.CATEGORY_CD, tempAreaConf.armOpen)}
                    </SelectButton>
                  </Box>
                </Box>
              </Box>
              {/* 移動時のアームパワー */}
              <Box
                className={`${classes.baseArea} ${classes.baseAreaArm} movingArmArea ${stationStyle} ${areaSelected}`}>
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>移動時のアームパワー</p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.MOVING.L_POWER,
                        INPUT_NAMES.MOVING.L_POWER_2,
                        INPUT_NAMES.MOVING.L_POWER_3,
                        INPUT_NAMES.MOVING.L_POWER_4,
                        INPUT_NAMES.MOVING.L_POWER_5,
                        INPUT_NAMES.MOVING.L_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.movingLPower}
                      onOpen={handleOpenMovingLPower}
                    />
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={[
                        INPUT_NAMES.MOVING.R_POWER,
                        INPUT_NAMES.MOVING.R_POWER_2,
                        INPUT_NAMES.MOVING.R_POWER_3,
                        INPUT_NAMES.MOVING.R_POWER_4,
                        INPUT_NAMES.MOVING.R_POWER_5,
                        INPUT_NAMES.MOVING.R_POWER_6,
                      ].includes(tenkey.name)}
                      leftRight={leftRight}
                      value={dispPowerTiming.movingRPower}
                      onOpen={handleOpenMovingRPower}
                    />
                  </Box>
                  <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                </Box>
              </Box>
              <img src={arrowIcon} alt="arrowIcon" className="basicToMovingArrow" />
              {/* タイミングエリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaFlex} ${stationStyle} timing ${areaSelected}`}>
                <p className={classes.caption}>タイミング</p>
                <Grid container className="container">
                  {TIMING_LABEL.map((timing) => (
                    <Grid item xs={6} className="item">
                      <IconButton
                        disabled={isNewPayoutSupportDisable}
                        onClick={() => onClickCatcherIcon(timing.index)}
                        className="timingIcon">
                        <img
                          src={
                            dispPowerTiming.timing[timing.index] === COMMON.SWITCH.ON ? redCatcherIcon : grayCatcherIcon
                          }
                          alt={`タイミング${timing.label}`}
                        />
                      </IconButton>
                      <p className="label">{timing.label}</p>
                    </Grid>
                  ))}
                </Grid>
                <img src={arrowUp} className="arrow1" alt="" />
                <img src={arrowUp} className="arrow2" alt="" />
                <img src={arrowUp} className="arrow3" alt="" />
                <img src={timingArrow} className="arrow4" alt="" />
                <img src={arrowUp} className="arrow5" alt="" />
                <img src={arrowUp} className="arrow6" alt="" />
              </Box>
              {/* エリア設定エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaArm} areaConfArea ${stationStyle}`}>
                {/* アームパワーアイコン 移動時 */}
                <Box className="top">
                  <p className={classes.caption}>エリア設定</p>
                  <NormalSwitch
                    disabled={isNewPayoutSupportDisable}
                    leftRight={leftRight}
                    value=""
                    checked={toggleAreaConf}
                    onChange={handleSwitchAreaConf}
                  />
                </Box>
                <Box className="main">
                  <Box className="description">
                    設定したいエリアの色を
                    <br />
                    タッチで選択
                  </Box>
                  <Box className={classes.baseAreaSelect}>
                    {SETTING_AREA.map((value, index) => {
                      if (value) {
                        return (
                          <IconButton
                            className="iconButton"
                            onClick={() => handleClickAreaBox(index)}
                            disabled={isNewPayoutSupportDisable || !toggleAreaConf}>
                            <Box
                              className={`area area${index} ${areaSelected === `area${index}` ? `selectedArea` : ''}`}
                            />
                          </IconButton>
                        );
                      }
                      return false;
                    })}
                  </Box>
                  <Box className="areaConf">
                    <Box className="areaConfTable">
                      <Box className="title">
                        エリア数<span>「{countAreaTotal(tempAreaConf)}」</span>
                      </Box>
                      <Box className="area">
                        <AreaDetail
                          displayCellSeparate={pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                          displayTableBorder
                          defaultBorderColor
                          isDefault={!toggleAreaConf}
                          leftRight={leftRight}
                          size="S"
                          areaConf={tempAreaConf}
                          pitConf={pitConf}
                        />
                        {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE && (
                          <AreaSimplePit className={classes.areaSimplePit} pitConf={pitConf} size="S" />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <NormalButton
                    onClick={handleClickAddArea}
                    className={`${stationStyleButton} addButton`}
                    disabled={isNewPayoutSupportDisable || !toggleAreaConf}>
                    <img src={iconPlusWhite} alt="iconPlusWhite" />
                    <span className="caption">エリアを追加</span>
                  </NormalButton>
                  {!toggleAreaConf && <Box className={classes.disableAreaConfArea} />}
                </Box>
              </Box>
              {/* 獲得サポート */}
              <Box className={`${classes.baseArea} ${classes.baseAreaFlex} getSupportArea ${stationStyle}`}>
                <Box className="top">
                  <p className={classes.caption}>獲得サポート</p>
                  <NormalSwitch
                    disabled={isNewPayoutSupportDisable}
                    leftRight={leftRight}
                    value="1"
                    checked={displayGetSupportChecked()}
                    onChange={handleOnChangeSwitchGetSupport}
                  />
                </Box>
                <Box className="description">
                  目標原価率を上回っても <br />
                  景品獲得できない場合 <br />
                  「つかみ時のアームパワー」 <br /> のまま獲得をサポートします
                </Box>
                <Box className={`${classes.baseArea} getSupportInnerArea ${classes.giftCostArea} ${stationStyle}`}>
                  <Box className="label">
                    景品
                    <br />
                    原価
                  </Box>
                  <TenkeyInput
                    disabled={isNewPayoutSupportDisable || isGetSupportDisable}
                    isSelected={tenkey.name === INPUT_NAMES.SUPPORT.GIFT_COST}
                    leftRight={leftRight}
                    value={tempLedServiceOtherConf?.giftCost}
                    onOpen={handleOpenGiftCost}
                  />
                  <Box className="postLabel">円</Box>
                </Box>
                <Box className={`${classes.baseArea} getSupportInnerArea ${classes.targetCostArea} ${stationStyle}`}>
                  <Box className="label">
                    目標
                    <br />
                    原価率
                  </Box>
                  <SelectButton
                    disabled={isNewPayoutSupportDisable || isGetSupportDisable}
                    leftRight={leftRight}
                    onClick={handleClickTargetCostRate}
                    isSelected={selectList.name === INPUT_NAMES.SUPPORT.TARGET_COST_RATE}>
                    {tempLedServiceOtherConf?.targetCostRate &&
                      getClassificationName(
                        CLSFCN.NEW_PAYOUT_TARGET_COST_RATE.CATEGORY_CD,
                        tempLedServiceOtherConf.targetCostRate,
                      )}
                  </SelectButton>
                </Box>
                {isGetSupportDisable && <Box className={classes.disableGetSupportArea} />}
              </Box>
              <Box className="doneArea">
                <DoneButton disabled={isNewPayoutSupportDisable} onClick={onClickOk}>
                  確 定
                </DoneButton>
              </Box>
            </Box>
            {tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT && (
              <Box className={classes.disableInputArea} />
            )}
          </Box>
          <SelectList selectList={selectList} />
          <NewTenkey tenkey={tenkey} />
          <ConfirmDialog
            open={openConfirmDialogModeSetting}
            msg={MESSAGES.INF217}
            onOk={onClickOkModeSetting}
            onCancel={handleCloseConfirmDialogModeSetting}
          />
          <ConfirmDialog
            open={openConfirmDialogModeSettingOff}
            msg={MESSAGES.INF248}
            onOk={onClickOkModeSettingOff}
            onCancel={handleCloseConfirmDialogModeSettingOff}
          />
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default NewPayoutSupportConf;
