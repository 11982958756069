import React, { useCallback } from 'react';
import { makeStyles, TableContainer, Table, TableBody, TableRow, TableCell, Box } from '@material-ui/core';
import { AreaDetailRowType, AreaDetailType } from 'types/machineConf/areaType';
import COMMON from 'constants/common';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import { toArrayAreaLine, toArrayPitLine, toLineData } from 'utils/machineConf.helper';
import CLSFCN from 'constants/classification';

/* ************* constant ************* */
const tableArrayRender = [0, 1, 2, 3, 4, 5];
const XY_AREA_SIZE = {
  LARGE: 390,
  MEDIUM: 360,
  SMALL: 180,
};
const PRIZE_AREA_MAX = 100;

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  tableRoot: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    '& .area0': {
      backgroundColor: theme.base.area0,
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
    },
    '& td': {
      padding: 0,
      '& img': {
        transform: 'scale(0.8)',
      },
    },
  },
  tableRootBorder: {
    border: '3px solid',
  },
  tableContainer: {
    position: 'relative',
  },
  mediumLineSeparate: {
    borderBottom: '2px solid',
  },
  mediumColumnSeparate: {
    borderRight: '2px solid',
  },
  lineSeparateNone: {
    borderBottomWidth: '0px',
  },
  lineSeparateDot: {
    borderBottom: '2px dashed',
  },
  columnSeparateNone: {
    borderRightWidth: '0px',
  },
  columnSeparateDot: {
    borderRight: '2px dashed',
  },
  pinpointPit: {
    position: 'absolute',
    borderRadius: '100%',
    border: '2px solid',
    zIndex: 1,
    backgroundColor: '#FF0035',
    borderColor: theme.palette.common.white,
  },
  smallPit: {
    width: '16px',
    height: '16px',
  },
  mediumPit: {
    width: '20px',
    height: '20px',
  },
  largePit: {
    width: '24px',
    height: '24px',
  },
  smallContainer: {
    top: '-8px',
    left: '-8px',
    width: '196px',
    height: '196px',
    padding: '8px 0px 0px 8px',
  },
  mediumContainer: {
    top: '-10px',
    left: '-10px',
    width: '380px',
    height: '380px',
    padding: '10px',
  },
  largeContainer: {
    top: '-12px',
    left: '-12px',
    width: '414px',
    height: '414px',
    padding: '12px',
  },
  smallStyle: {
    height: '180px',
    width: '180px',
    '& tr': {
      height: '29px',
    },
    '& td': {
      width: '29px',
    },
  },
  mediumStyle: {
    height: '360px',
    width: '360px',
    '& tr': {
      height: '59px',
    },
    '& td': {
      width: '59px',
    },
  },
  largeStyle: {
    height: '390px',
    width: '390px',
    '& tr': {
      height: '64px',
    },
    '& td': {
      width: '64px',
    },
  },
  defaultStyle: {
    borderColor: theme.base.tableBorder,
  },
  leftStyle: {
    borderColor: theme.leftStation.itemBorder,
  },
  rightStyle: {
    borderColor: theme.rightStation.itemBorder,
  },
}));

/* ********* Local Function ********** */

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * AreaDetailRow エリア明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const AreaDetailRow: React.FC<AreaDetailRowType> = ({
  isDefault,
  className,
  displayCellSeparate,
  pitLine,
  areaLine,
  settingPattern,
}) => {
  const classes = useStyles();

  // 通常のセルのスタイル
  const cellClass = `${className} ${displayCellSeparate ? classes.columnSeparateDot : classes.columnSeparateNone}`;
  // 2列、4列目のセルのスタイル 右側に線を引く
  const cellClassEven = `${className} ${classes.mediumColumnSeparate}`;
  return (
    <TableRow>
      {tableArrayRender.map((value) => (
        <TableCell
          key={value}
          className={`${value === 1 || value === 3 ? cellClassEven : cellClass} ${
            isDefault === true ? 'area0' : `area${areaLine.length >= 6 ? areaLine[value] : '0'}`
          }`}>
          {pitLine && pitLine[value] === '1' && settingPattern === CLSFCN.PIT_PATTERN.DETAIL && (
            <img src={pitMarkIcon} alt="○" />
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

/**
 * PinpointPit ピンポイント落とし穴
 *
 * @author atsushi.teruya
 * @returns {React.FC} - ピンポイント用の落とし穴表示
 */
const PinpointPit: React.FC<{
  isLeft: boolean;
  status: string;
  x: number | null;
  y: number | null;
  size: 'S' | 'M' | 'L';
}> = ({ isLeft, status, x, y, size }) => {
  // status,x,yが有効でない場合は空を返す。
  if (status !== COMMON.STATUS.NORMAL || x === null || y === null) {
    return <></>;
  }

  const classes = useStyles();
  // 落とし穴サイズを定義
  let pitStyle = '';
  let areaSize = 0;
  switch (size) {
    case COMMON.SIZE.SMALL:
      pitStyle = classes.smallPit;
      areaSize = XY_AREA_SIZE.SMALL;
      break;
    case COMMON.SIZE.MEDIUM:
      pitStyle = classes.mediumPit;
      areaSize = XY_AREA_SIZE.MEDIUM;
      break;
    default:
      pitStyle = classes.largePit;
      areaSize = XY_AREA_SIZE.LARGE;
  }

  const toScreenPosY = useCallback((pitY: number) => areaSize - Math.round((pitY * areaSize) / PRIZE_AREA_MAX), []);
  const leftToScreenPos = useCallback(
    (pitX: number, pitY: number) => ({
      x: Math.round((pitX * areaSize) / PRIZE_AREA_MAX),
      y: toScreenPosY(pitY),
    }),
    [],
  );
  const rightToScreenPos = useCallback(
    (pitX: number, pitY: number) => ({
      x: areaSize - Math.round((pitX * areaSize) / PRIZE_AREA_MAX),
      y: toScreenPosY(pitY),
    }),
    [],
  );
  const toScreenPos = isLeft ? leftToScreenPos : rightToScreenPos;
  const axes = toScreenPos(x || 0, y || 0);

  const style = {
    top: axes.y,
    left: axes.x,
  };
  return (
    <Box style={style} className={`${classes.pinpointPit} ${pitStyle}`}>
      <span />
    </Box>
  );
};

/* ************ Main Component ************ */
const AreaDetail: React.FC<AreaDetailType> = ({
  className,
  displayCellSeparate,
  displayTableBorder,
  defaultBorderColor,
  leftRight,
  size,
  areaConf,
  pitConf,
  isDefault,
}) => {
  const classes = useStyles();
  // テーブルサイズを定義
  let tableSizeStyle = '';
  let contanerSizeStyle = '';
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  switch (size) {
    case COMMON.SIZE.SMALL:
      tableSizeStyle = classes.smallStyle;
      contanerSizeStyle = classes.smallContainer;
      break;
    case COMMON.SIZE.MEDIUM:
      tableSizeStyle = classes.mediumStyle;
      contanerSizeStyle = classes.mediumContainer;
      break;
    default:
      tableSizeStyle = classes.largeStyle;
      contanerSizeStyle = classes.largeContainer;
  }
  // ステーションの向きに応じた配色を定義
  let stationStyle = classes.defaultStyle;
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  if (!defaultBorderColor) {
    stationStyle = isLeft ? classes.leftStyle : classes.rightStyle;
  }
  const contanerStyle = `${classes.tableContainer} ${className} ${contanerSizeStyle}`;
  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const tableStyle = `${classes.tableRoot} ${
    displayTableBorder && classes.tableRootBorder
  } ${tableSizeStyle} ${stationStyle}`;
  // 基本は1行ごとに破線のボーダーを表示。XY範囲など一部の画面は非表示。
  const rowClass = `${displayCellSeparate ? classes.lineSeparateDot : classes.lineSeparateNone} ${stationStyle}`;
  // 2行、4行の行(実際はセル)のスタイル 右側に線を引く
  const mediumRowClass = `${classes.mediumLineSeparate} ${stationStyle}`;
  // 行ごとにセルごとの表示制御するためline(実際は列)の配列を作成
  const pitLineArray =
    pitConf?.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT ? toArrayPitLine(undefined) : toArrayPitLine(pitConf);
  const areaLineArray = toArrayAreaLine(areaConf);

  return (
    <>
      <TableContainer className={contanerStyle}>
        <Table className={tableStyle}>
          <TableBody>
            {tableArrayRender.map((value) => (
              <AreaDetailRow
                isDefault={isDefault}
                key={value}
                className={value === 1 || value === 3 ? mediumRowClass : rowClass}
                displayCellSeparate={displayCellSeparate}
                areaLine={toLineData(areaLineArray, value)}
                pitLine={toLineData(pitLineArray, value)}
                settingPattern={pitConf?.settingPattern}
              />
            ))}
          </TableBody>
        </Table>
        {/** ピンポイント表示 */}
        {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT && (
          <>
            <PinpointPit isLeft={isLeft} status={pitConf.pit1Status} x={pitConf.pit1x} y={pitConf.pit1y} size={size} />
            <PinpointPit isLeft={isLeft} status={pitConf.pit2Status} x={pitConf.pit2x} y={pitConf.pit2y} size={size} />
            <PinpointPit isLeft={isLeft} status={pitConf.pit3Status} x={pitConf.pit3x} y={pitConf.pit3y} size={size} />
            <PinpointPit isLeft={isLeft} status={pitConf.pit4Status} x={pitConf.pit4x} y={pitConf.pit4y} size={size} />
          </>
        )}
      </TableContainer>
    </>
  );
};

export default AreaDetail;
