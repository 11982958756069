import { combineReducers } from '@reduxjs/toolkit';
import commonReducer from 'redux/slices/commonSlice';
import dataReducer from 'redux/slices/dataSlice';
import machineConfReducer from 'redux/slices/machineConfSlice';
import maintenanceReducer from 'redux/slices/maintenanceSlice';
import groupReducer from 'redux/slices/groupSlice';
import userReducer from 'redux/slices/userSlice';
import historyActionTabmenu from './slices/historyActionTabmenu';
import activeCrateGroupSlice from './slices/activeCrateGroupSlice';
import manualInformationSlice from './slices/manualInformationSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  data: dataReducer,
  machineConf: machineConfReducer,
  group: groupReducer,
  maintenance: maintenanceReducer,
  user: userReducer,
  tabmenu: historyActionTabmenu,
  crateGroupSlice: activeCrateGroupSlice,
  manualInformation: manualInformationSlice,
});

export default rootReducer;
