import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButtonType } from 'types/system/buttonType';

/**
 * Delete Button Component
 * ボタン使用例) 削除、初期化など
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#E8FF4A',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#E8FF4A',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    '&:disabled': {
      color: theme.palette.common.black,
      background: '#E8FF4A',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  defaultStyle: {
    fontSize: '22px',
    lineHeight: '24px',
    height: '68px',
    width: '144px',
  },
  caption: {
    width: '100%',
  },
}));
const DeleteButton: React.FC<BaseButtonType> = memo((props) => {
  const classes = useStyles();
  const { className, disabled = false, fullWidth = false, onClick, children } = props;

  return (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      className={`${classes.button} ${className || classes.defaultStyle}`}
      onClick={onClick}
      disabled={disabled}>
      <p className={classes.caption}>{children}</p>
    </Button>
  );
});

export default DeleteButton;
