import React from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { makeStyles, Box } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import GroupConfTopButton from 'components/group/atoms/buttons/GroupConfTopButton';
import { useAppDispatch } from 'redux/hooks';
import {
  setNextFromLedOrderSetScr,
  setNextFromOrderSetScr,
} from 'redux/slices/groupSlice';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: 'グループカテゴリー選択',
  screenId: 'SCR412',
  currentMenu: COMMON.MENU.GROUP,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '24px 0px 0px 4px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      padding: '4px 0px 0px 24px',
      width: '224px',
    },
  },
  mainArea: {
    paddingLeft: '235px',
    flexDirection: 'row',
  },
}));

const GroupSelectCategory: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.GROUP_CONF_TOP);
  };
  const handleClickGroupLEDConf = () => {
    dispatch(setNextFromLedOrderSetScr(COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY));
    history.push(redirectLinks.GROUP_LED_GROUP_CREATE, { fromScrId: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY });
  };
  const handleClickPremiumConf = () => {
    dispatch(setNextFromOrderSetScr(COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY));
    history.push(redirectLinks.GROUP_GET_GIFT_GROUP_CREATE, { fromScrId: COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickGroupConfTop}>
                グループ
                <br />
                設定TOP
              </ReturnButton>
            </Box>
          </Box>
          <Box className={classes.mainArea}>
            <GroupConfTopButton onClick={handleClickGroupLEDConf} iconName="iconLED" />
            <GroupConfTopButton onClick={handleClickPremiumConf} iconName="iconInCommsSetting" />
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GroupSelectCategory;
