import { Box, FormControlLabel, makeStyles, Radio, RadioProps } from '@material-ui/core';
import React, { FC } from 'react';

type Props = RadioProps;

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.base.tableItemBorder,
    borderRadius: '8px',
  },
  checked: {
    backgroundColor: theme.palette.common.white,
  },
}));

/* ホバー時の効果を無効にしたい場合は以下の内容を設定。
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
  //const classes = useStyles();

  // checkboxコンポーネントに設定
  //  className={classes.root}
  //  disableRipple
*/

/* ************ Component ************ */
/**
 * ListRadio Component
 * radio button in table
 * テーブル表示用のラジオボタン。
 *
 * @author atsushi.teruya
 * @param {boolean} checked - 選択
 * @param {boolean} disabled - 活性制御 true => disabled, false => enabled
 * @param {string} name - ラジオボタンの名称。実装上は利用しないが定義する。
 * @param {string} value - ラジオボタンの値。ラジオボタンを一意に識別するための値を指定する。
 * @param {string} onChane - onChangイベント
 * @returns {React.FC} - 一覧表示用のラジオボタン
 *
 */
const ListRadio: FC<Props> = ({ checked, className, disabled, name, value, onChange }) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${className}`}>
      <FormControlLabel
        value="基準の設定にする"
        control={
          <Radio
            checked={checked}
            color="default"
            defaultChecked={false}
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
          />
        }
        label="基準の設定にする"
      />
    </Box>
  );
};

export default ListRadio;
