import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { DataStateType, StationDetailType } from 'types/data/dataType';

/* ************ 機器設定用Redux定義 ************ */
/* ************ State ************ */
// Reduxで管理するグローバルステートの設定
const initialState: DataStateType = {
  dataStationListSort: CLSFCN.DATA_STATION_SORT.STATION_NAME_ASC,
  dataStationList: [],
  stationList: [],
  returnFromDataViewScr: COMMON.SCREEN_ID.DATA_STATION_LIST,
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setStationList(state, action: PayloadAction<Array<number>>) {
      state.stationList = action.payload; // 選択されたｽﾃｰｼｮﾝIdを保持
    },
    setDataStationList(state, action: PayloadAction<Array<StationDetailType>>) {
      // ｽﾃｰｼｮﾝ一覧用(パフォーマンス向上を狙ってReduxで保持する)
      state.dataStationList = action.payload;
    },
    setDataStationListSort(state, action: PayloadAction<string>) {
      // ｽﾃｰｼｮﾝ一覧用のソート
      state.dataStationListSort = action.payload;
    },
    setDataStationListSelected(state, action: PayloadAction<number>) {
      // ｽﾃｰｼｮﾝ一覧のチェックボックス
      const row = state.dataStationList.find((st) => st.stationId === action.payload);
      if (!row) return;
      row.selected = !row?.selected;
    },
    initialize(state) {
      state.stationList = initialState.stationList;
    },
    initializeDataSlice: () => initialState,
    setReturnFromDataViewScr(state, action: PayloadAction<string>) {
      state.returnFromDataViewScr = action.payload;
    },
  },
});

export const {
  setStationList,
  setDataStationList,
  setDataStationListSort,
  setDataStationListSelected,
  initialize,
  initializeDataSlice,
  setReturnFromDataViewScr,
} = slice.actions;
export default slice.reducer;
