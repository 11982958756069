import React, { memo } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from 'redux/hooks';
import AreaDetail from 'components/machineConf/organisms/Area/AreaDetail';
import AreaSimplePit from 'components/machineConf/organisms/Area/AreaSimplePit';
import CLSFCN from 'constants/classification';
import COMMON from '../../../../constants/common';

/**
 * PitConfInfoButton Component
 * 落とし穴設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    position: 'relative',
    transform: 'translateY(-50%)',
    fontSize: 22,
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  pitArea: {
    position: 'relative',
    marginLeft: '16px',
  },
  areaSimplePit: {
    position: 'absolute',
  },
}));

type ButtonProps = {
  title: string;
  onClick?: () => void;
};

// コンポーネント定義
const PitConfInfoButton: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { title, onClick } = props;

  return (
    <Button variant="contained" className={classes.button} onClick={onClick}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          <b>{title}</b>
        </Box>
        <Box className={classes.pitArea}>
          <AreaDetail
            displayCellSeparate={pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
            displayTableBorder
            defaultBorderColor
            leftRight={leftRight}
            size="S"
            areaConf={areaConf}
            pitConf={pitConf}
            isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
          />

          {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE && (
            <AreaSimplePit className={classes.areaSimplePit} pitConf={pitConf} size="S" />
          )}
        </Box>
      </Box>
    </Button>
  );

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(PitConfInfoButton);
