import React from 'react';
import { useLocation } from 'react-router-dom';
import COMMON from 'constants/common';
import NewGift from 'components/maintenance/gift/NewGift';
import UpdateGift from 'components/maintenance/gift/UpdateGift';
import ScreenContext from 'Contexts/Common/ScreenContext';
import Layout from 'components/system/layouts/Layout';

const ScreenContextValue = {
  title: '景品詳細　新規登録',
  screenId: COMMON.SCREEN_ID.GIFT_DETAIL,
  currentMenu: COMMON.MENU.MAINTENANCE,
};

type GiftDetailType = {
  id?: string;
};

const GiftDetail: React.FC = () => {
  const location = useLocation();
  const giftId = (location.state as GiftDetailType)?.id;

  let content = <NewGift />;
  if (giftId !== undefined) {
    ScreenContextValue.title = '景品詳細　編集';
    content = <UpdateGift />;
  } else {
    ScreenContextValue.title = '景品詳細　新規登録';
  }
  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>{content}</Layout>
    </ScreenContext.Provider>
  );
};

export default GiftDetail;
