import React from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import { Box, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import PitConfInfoButton from 'components/machineConf/atoms/buttons/PitConfInfoButton';
import AreaConfInfoButton from 'components/machineConf/atoms/buttons/AreaConfInfoButton';
import SpeedConfInfoButton from 'components/machineConf/atoms/buttons/SpeedConfInfoButton';
import LeverConfInfoButton from 'components/machineConf/atoms/buttons/LeverConfInfoButton';
import XyConfInfoButton from 'components/machineConf/atoms/buttons/XyConfInfoButton';
import UrlHelper from 'utils/url.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '移動エリアパワー設定TOP',
  screenId: 'SCR321',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
    position: 'relative',
  },
  mainArea: {
    position: 'relative',
    display: 'flex',
    height: '493px',
    '& .pitConfButton': {
      position: 'absolute',
      top: '0px',
      left: '224px',
    },
    '& .areaConfButton': {
      position: 'absolute',
      top: '0px',
      left: '512px',
    },
    '& .xyConfButton': {
      position: 'absolute',
      top: '288px',
      left: '224px',
    },
    '& .speedConfButton': {
      position: 'absolute',
      top: '288px',
      left: '512px',
    },
    '& .leverConfButton': {
      position: 'absolute',
      top: '288px',
      left: '800px',
    },
  },
}));

/* ******** Main Component ******** */
/**
 *
 * @author takuya.tomisato
 * @returns {React.FC} - 移動エリアパワー設定TOP
 *
 */
const AreaPowerConf: React.FC = () => {
  /* ************ state/redux ************ */

  // Local

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();

  // const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;

  /* ************ Event ************ */
  // ステーション設定TOP画面へ遷移
  const handleclickReturn = () => {
    // history.push(redirectLinks.MCONF_STATION_CONF_TOP);
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
        selectConfId: 'none',
      }),
    );
  };
  // 落とし穴設定画面へ遷移
  const handleClickPitConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_PIT_CONF);
  };
  // エリアパワー設定画面へ遷移
  const handleClickAreaConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_AREA_POWER_CONF);
  };
  // XY移動範囲設定画面へ遷移
  const handleClickXyConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_XY_RANGE_CONF);
  };
  // キャッチャー速度設定画面へ遷移
  const handleClickSpeedConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_CATCHER_SPEED_CONF);
  };
  // レバー操作設定画面へ遷移
  const handleClickLeverConf = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_LEVER_OPERATION);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.mainArea}>
              <ReturnButton onClick={handleclickReturn}>設定TOP</ReturnButton>
              <Box className="pitConfButton">
                <PitConfInfoButton title="落とし穴設定" onClick={handleClickPitConf} />
              </Box>
              <Box className="areaConfButton">
                <AreaConfInfoButton title="エリア別設定" onClick={handleClickAreaConf} />
              </Box>
              <Box className="xyConfButton">
                <XyConfInfoButton title="XY移動範囲・HP" onClick={handleClickXyConf} />
              </Box>
              <Box className="speedConfButton">
                <SpeedConfInfoButton title="キャッチャー速度" onClick={handleClickSpeedConf} />
              </Box>
              <Box className="leverConfButton">
                <LeverConfInfoButton title="レバー操作" onClick={handleClickLeverConf} />
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};
export default AreaPowerConf;
