import React, { useState, useCallback, MouseEventHandler } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import { makeStyles, Box } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { setAreaConf } from 'redux/slices/machineConfSlice';
import arrowIcon from 'assets/images/arrowIcon.svg';
import { AreaConfType, AreaSelectType } from 'types/machineConf/machineConfType';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { resetDescentLimitValueJack } from 'utils/resetDescentLimitJack.helper';
import MESSAGES from 'constants/messages';
import { InfoDialog } from 'components/system/organisms';
import AreaConfPanelJack from 'components/machineConf/organisms/Area/AreaConfPanelJack';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'エリア設定',
  screenId: COMMON.SCREEN_ID.AREA_CONF_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const AREA_SELECT_LIST = ['ベースエリア', 'エリア緑', 'エリア紫', 'エリア黄', 'エリア赤', 'エリア青'];

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  baseArea: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    borderColor: theme.base.tableBorder,
  },
  baseAreaSelect: {
    '& .area0': {
      backgroundColor: theme.base.area0,
      fontSize: 18,
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
      fontSize: 18,
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
      fontSize: 18,
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
      fontSize: 18,
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
      fontSize: 18,
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
      fontSize: 18,
    },
  },
  topArea: {
    display: 'flex',
    height: '104px',
    '& .returnButton': {},
  },
  mainArea: {
    display: 'flex',
    height: '460px',
    width: '1080px',
    '& .areaSelectArea': {
      position: 'absolute',
      top: '142px',
      left: '0px',
      height: '424px',
      width: '344px',
      '& .description': {
        fontSize: '22px',
        height: '32px',
      },
      '& .areaSelectList': {
        height: '390px',
        width: '320px',
        padding: '24px 0px 0px 16px',
        '& .areaSelectButton': {
          height: '48px',
          width: '178px',
          boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
          },
        },
        '& .selected0': {
          height: '48px',
          width: '178px',
          border: '4px solid #707070',
        },
        '& .selected1': {
          height: '48px',
          width: '178px',
          border: '4px solid #08C57E',
        },
        '& .selected2': {
          height: '48px',
          width: '178px',
          border: '4px solid #991399',
        },
        '& .selected3': {
          height: '48px',
          width: '178px',
          border: '4px solid #FF960D',
        },
        '& .selected4': {
          height: '48px',
          width: '178px',
          border: '4px solid #FF566C',
        },
        '& .selected5': {
          height: '48px',
          width: '178px',
          border: '4px solid rgba(6,87,255,0.7)',
        },
        '& .clearButton': {
          height: '40px',
          width: '86px',
          margin: '4px 0px 0px 22px',
          boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    '& .arrowIconRight': {
      position: 'absolute',
      top: '224px',
      left: '364px',
      height: '136px',
      width: '72px',
      transform: 'rotate(90deg)',
    },
    '& .areaConfArea': {
      position: 'absolute',
      top: '112px',
      left: '484px',
      height: '446px',
      width: '532px',
      '& .caption': {
        fontSize: '18px',
        height: '54px',
      },
      '& .areaConf': {
        marginTop: '-48px',
        marginLeft: '-52px',
        height: '470px',
        width: '470px',
      },
    },
    '& .doneArea': {
      position: 'absolute',
      top: '464px',
      left: '900px',
    },
  },
  areaSelect: {
    height: '48px',
    width: '290px',
    marginBottom: '12px',
    '& .area': {
      width: '178px',
    },
    '& .clear': {
      width: '86px',
    },
  },
}));

/* ********** local function ********** */
// エリア設定の行xに対し、設定済みの行情報を初期化する。
const clearAreaLine = (line = '', areaValue: string) => line.replaceAll(areaValue, COMMON.AREA.BASE);

/* ********** Sub Component ********** */
/**
 * AreaSelect エリア選択
 *
 * @author atsushi.teruya
 * @param
 * @returns {React.FC} - エリア選択
 *
 */
const AreaSelect: React.FC<AreaSelectType> = ({ index, caption, selected, onClickArea, onClickClear }) => {
  const classes = useStyles();

  const indexStr = String(index);
  return (
    <Box className={classes.areaSelect}>
      <NormalButton
        className={`${selected ? `selected${index}` : 'areaSelectButton'} area${indexStr} ${
          index <= 0 && classes.baseArea
        }`}
        onClick={onClickArea(indexStr)}>
        {caption}
      </NormalButton>
      {/* ベースエリアの場合はクリアボタンを表示しない */}
      {index > 0 && (
        <NormalButton baseColor="black" className="clearButton" onClick={onClickClear(indexStr)}>
          クリア
        </NormalButton>
      )}
    </Box>
  );
};

/* ******** Main Component ******** */
/**
 * エリア設定
 *
 * @author atsushi.teruya
 * @returns {React.FC} - エリア設定
 *
 */
const AreaConfJack: React.FC = () => {
  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();
  const areaConf: AreaConfType | undefined = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);

  // Local
  const [inputValues, setInputValues] = useState({ selectedArea: COMMON.AREA.BASE });
  const [tempAreaConf, setTempAreaConf] = useState(areaConf);
  const [openConfirm, setOpenConfirm] = useState(false);

  // 初期処理 or 並べ替え変更時の処理

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();

  /* ************ Event ************ */

  // return button change
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_AREA_POWER_CONF);
  };

  // エリア
  const handleClickArea = (areaValue: string): MouseEventHandler<HTMLButtonElement> => () => {
    // 選択中エリアを更新
    setInputValues((prevState) => ({ ...prevState, selectedArea: areaValue }));
  };

  // クリア
  const handleClickClear = (areaValue: string): MouseEventHandler<HTMLButtonElement> => () => {
    setTempAreaConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          line1: clearAreaLine(prevState.line1, areaValue),
          line2: clearAreaLine(prevState.line2, areaValue),
          line3: clearAreaLine(prevState.line3, areaValue),
          line4: clearAreaLine(prevState.line4, areaValue),
          line5: clearAreaLine(prevState.line5, areaValue),
          line6: clearAreaLine(prevState.line6, areaValue),
        };
      }
      return prevState;
    });
  };

  // 確定
  const handleClickDone = async () => {
    if (!tempAreaConf) return;
    const newTempAreaConf = resetDescentLimitValueJack(tempAreaConf);
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    let formatTempAreaConf: AreaConfType = { ...tempAreaConf };
    if (tempAreaConf) {
      formatTempAreaConf = {
        ...formatTempAreaConf,
        line1: `0${tempAreaConf.line1}`,
        line2: `0${tempAreaConf.line2}`,
        line3: `0${tempAreaConf.line3}`,
        line4: `0${tempAreaConf.line4}`,
        line5: `0${tempAreaConf.line5}`,
        line6: `0${tempAreaConf.line6}`,
      };
    }
    await http.post<AreaConfType>(REST_API.MACHINE_CONF.AREA_CONF_VALIDATION_JACK, formatTempAreaConf);

    if (!newTempAreaConf?.hasReset) {
      dispatch(setAreaConf(formatTempAreaConf));
      history.push(redirectLinks.MCONF_JACK_CONF_AREA_POWER_CONF);
    } else {
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });
      setOpenConfirm(true);
      formatTempAreaConf = {
        ...areaConfReset,
        line1: `0${areaConfReset.line1}`,
        line2: `0${areaConfReset.line2}`,
        line3: `0${areaConfReset.line3}`,
        line4: `0${areaConfReset.line4}`,
        line5: `0${areaConfReset.line5}`,
        line6: `0${areaConfReset.line6}`,
      };
      // reset DescentLimit
      dispatch(setAreaConf(formatTempAreaConf));
    }
  };

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_JACK_CONF_AREA_POWER_CONF);
  };

  // 落とし穴変更時
  const handleChangeArea = useCallback((newAreaConf: AreaConfType) => {
    setTempAreaConf(newAreaConf);
  }, []);
  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              {/* 戻るボタン */}
              <Box className="returnButton">
                <ReturnButton onClick={handleClickReturn}>
                  設定を反映 <br />
                  させずに戻る
                </ReturnButton>
              </Box>
            </Box>
            <Box className={classes.mainArea}>
              {/* エリア選択 */}
              <Box className="areaSelectArea">
                <p className="description">※最大6エリアまで指定できます。</p>
                <Box className={`${classes.baseArea} ${classes.baseAreaSelect} areaSelectList`}>
                  {AREA_SELECT_LIST.map((value, index) => (
                    <AreaSelect
                      key={String(index)}
                      index={index}
                      caption={value}
                      selected={inputValues.selectedArea === String(index)}
                      onClickArea={handleClickArea}
                      onClickClear={handleClickClear}
                    />
                  ))}
                </Box>
              </Box>
              <img src={arrowIcon} alt="arrowIcon" className="arrowIconRight" />
              {/* エリア設定 */}
              <Box className="areaConfArea">
                <p className="caption">
                  ①左のパネルから指定したいエリアの色を選択します。 <br />
                  ②指定したいエリアをタッチまたはスライドして選択します。
                </p>
                <Box className="areaConf">
                  <AreaConfPanelJack
                    areaValue={inputValues.selectedArea}
                    onChange={handleChangeArea}
                    areaConf={tempAreaConf}
                    pitConf={pitConf}
                  />
                </Box>
              </Box>
              {/* 確定ボタン */}
              <Box className="doneArea">
                <DoneButton onClick={handleClickDone}>確 定</DoneButton>
              </Box>
            </Box>
          </Box>
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default AreaConfJack;
