import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import redirectLinks from 'constants/redirectLinks';
import UserTable from 'components/maintenance/organisms/tables/UserTable';
import NormalSelectBox from 'components/system/organisms/inputItems/NormalSelectBox';
import { makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setUserInfoListFilter } from 'redux/slices/userSlice';
import { getClsfcnByCategory } from 'utils/common.helper';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { UserInfoType } from 'types/maintenance/UserInfoType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ユーザー一覧',
  screenId: 'SCR531',
  currentMenu: COMMON.MENU.MAINTENANCE,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    padding: '10px 16px',
  },
  contoleArea: {
    display: 'flex',
  },
  rightArea: {
    display: 'block',
    margin: '0 0 0 auto',
    textAlign: 'right',
  },
  returnBtn: {
    display: 'flex',
  },
  sortArea: {},
  filter: {
    padding: '12px 0',
  },
  addUserBtn: {
    width: '180px',
    height: '64px',
    background: '#EBEBEB',
    margin: '0 0 0 auto',
    fontSize: '24px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
}));

/* ************ Const ************ */
export const IS_FILTERED = {
  FALSE: '0', // Reduxに保存された値を参照せず「利用」の初期値を使用する。
  TRUE: '1', // Reduxの「利用」の値を使用する
};

/* ************ Component ************ */
const UserList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  /* ************ state/redux ************ */
  const { userInfo } = useAppSelector((state) => state.user);
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const { isFiltered } = useParams<{ isFiltered: string }>();
  const [disabledAddUserBtn, setDisabledAddBtn] = useState<boolean>(true);
  const { ROLE } = CLSFCN;

  const [filter, setFilter] = useState<string>(
    // isFiltered=trueならば、Reduxに保存されたフィルタの値をセットする。
    isFiltered === IS_FILTERED.TRUE ? userInfo.userInfoListFilter : CLSFCN.USER_LIST_FILTER.ALL,
  );
  const [userInfoList, setUserInfoList] = useState<Array<UserInfoType>>([]);
  const isShowReturnBtn = loginUserInfo?.role !== CLSFCN.ROLE.SYSTEM_ADMIN;

  /* ************ Function ************ */
  useEffect(() => {
    // 店舗が選択または変更された場合、または「利用」が変更された場合
    // ユーザ情報リストの(再)取得と登録ボタンの活性化する。
    if (!loginUserInfo?.currentHeadQId || !loginUserInfo?.currentStoreId) {
      // 選択中の店舗がない場合、ユーザ一覧を表示しない。登録ボタンの非活性化
      setDisabledAddBtn(true);
      return;
    }
    setDisabledAddBtn(false);
    getUserInfoList(loginUserInfo.currentHeadQId, loginUserInfo.currentStoreId, filter);
  }, [loginUserInfo, filter]);

  // ユーザ一覧取得
  const getUserInfoList = async (hqId: string, storeId: string, prmFilter: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<UserInfoType>>(REST_API.MAINTENANCE.GET_USER_INFO_LIST, {
      hqId,
      storeId,
      filter: prmFilter,
    });
    setUserInfoList(data);
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeFilter = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    // 「利用」プルダウンの値が変更されたら、利用プルダウンの値をセットする。
    setFilter(ev.target.value);
    dispatch(setUserInfoListFilter(ev.target.value));
  };

  // return button
  const handleClickReturn = () => {
    history.push(redirectLinks.MAINT_TOP);
  };
  // 新規登録ボタン
  const handleClickAddUser = () => {
    // ユーザの新規登録画面へ遷移
    history.push(redirectLinks.MAINT_USER_DETAIL_NEW);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.contens}>
          <div className={classes.contoleArea}>
            {isShowReturnBtn && (
              <ReturnButton onClick={handleClickReturn}>
                管理登録
                <br />
                TOP
              </ReturnButton>
            )}
            <div className={classes.rightArea}>
              {![ROLE.HEAD_VIEWER, ROLE.STORE_VIEWER].includes(loginUserInfo?.role ?? '') ? (
                <NormalButton className={classes.addUserBtn} onClick={handleClickAddUser} disabled={disabledAddUserBtn}>
                  + 新規登録
                </NormalButton>
              ) : (
                <div style={{ height: '64px' }} />
              )}
              <div className={classes.filter}>
                <NormalSelectBox
                  name="filter"
                  dataSource={getClsfcnByCategory(CLSFCN.USER_LIST_FILTER.CATEGORY_CD)}
                  value={filter}
                  onChange={handleChangeFilter}
                  label="利用"
                />
              </div>
            </div>
          </div>
          <UserTable rows={userInfoList} filter={filter} />
        </div>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default UserList;
