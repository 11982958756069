import React, { memo, MouseEventHandler } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import arrowIcon from 'assets/images/downArrowIcon_3.svg';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import { shiftPanelType } from 'types/machineConf/shiftPanelType';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  table: {
    height: '100%',
  },
  tableCell: {
    position: 'relative',
    backgroundColor: '#FFF',
    border: '2px solid #707070',
    padding: '0px',
    '& .arrowBox': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      padding: '4px',
      // 斜めの矢印だと当たり判定が変わるので、
      // 画像をクリックしてもイベントが発火しないようにする
      pointerEvents: 'none',
      // ダブルクリックすると選択中表示（画像が青くなる）になるので、範囲選択不可にする
      userSelect: 'none',
    },
  },
  // 矢印Style
  backArrow: {
    transform: 'rotate(180deg)',
  },
  backRightArrow: {
    transform: 'rotate(225deg)',
  },
  rightArrow: {
    transform: 'rotate(270deg)',
  },
  frontRightArrow: {
    transform: 'rotate(315deg)',
  },
  frontLeftArrow: {
    transform: 'rotate(45deg)',
  },
  leftArrow: {
    transform: 'rotate(90deg)',
  },
  backLeftArrow: {
    transform: 'rotate(135deg)',
  },
}));

/* ************ helpers ************ */
// 文字列を1文字ずつ切り出し、配列にする
const generateArray = (str: string) => {
  const list: string[] = [];
  for (let i = 0; i < str.length; i += 1) {
    const result = str.charAt(i);
    list.push(result);
  }
  return list;
};

// 2次元配列の行・列を転置する
// 例
// [                    [
//   [1, 2, 3],            [1, 4, 7],
//   [4, 5, 6],    =>      [2, 5, 8],
//   [7, 8, 9]             [3, 6, 9]
// ]                    ]
const transpose2DArray = (arg: string[][]) => arg[0].map((_, c) => arg.map((r) => r[c]));

// line1, lin2, line3（列）からずらし運営設定画面用の2次元配列を生成
const generateShifts = (line1: string, line2: string, line3: string) => {
  const linesArray = [generateArray(line1), generateArray(line2), generateArray(line3)];
  return transpose2DArray(linesArray);
};

/* ******** Component ******** */

const ShiftPanel: React.FC<shiftPanelType> = memo((props) => {
  /* ************ State ************ */
  const classes = useStyles();
  const { shiftConf, line1, line2, line3, className, onChange } = props;

  /* ************ Event ************ */
  // 矢印画像のレンダー分岐
  const renderArrowImage = (shiftArrow: string) => {
    switch (shiftArrow) {
      case COMMON.SHIFT_ARROW.BACK:
        return <img src={arrowIcon} alt="back" className={classes.backArrow} />;
      case COMMON.SHIFT_ARROW.BACK_RIGHT:
        return <img src={arrowIcon} alt="backRight" className={classes.backRightArrow} />;
      case COMMON.SHIFT_ARROW.RIGHT:
        return <img src={arrowIcon} alt="right" className={classes.rightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT_RIGHT:
        return <img src={arrowIcon} alt="frontRight" className={classes.frontRightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT:
        return <img src={arrowIcon} alt="front" />;
      case COMMON.SHIFT_ARROW.FRONT_LEFT:
        return <img src={arrowIcon} alt="frontLeft" className={classes.frontLeftArrow} />;
      case COMMON.SHIFT_ARROW.LEFT:
        return <img src={arrowIcon} alt="left" className={classes.leftArrow} />;
      case COMMON.SHIFT_ARROW.BACK_LEFT:
        return <img src={arrowIcon} alt="backLeft" className={classes.backLeftArrow} />;
      case COMMON.SHIFT_ARROW.PIT:
        return <img src={pitMarkIcon} alt="pit" />;
      default:
        return <></>;
    }
  };

  // ずらし設定「カスタム」時のパネル
  const handleClickShiftPanel: MouseEventHandler<HTMLDivElement> = (event) => {
    const element = event.currentTarget.dataset;
    if (element) {
      // クリックした要素のテーブル位置を取得
      const TargetRow = Number(element.row);
      const TargetCol = Number(element.col);
      // 現在のテーブルの状態
      const shifts = generateShifts(line1, line2, line3);
      // 現在のテーブルの状態から対象要素を取得
      let targetValue = shifts[TargetRow][TargetCol];
      // 矢印が↖なら↑に再セットし、それ以外なら次の矢印に進める
      if (targetValue === COMMON.SHIFT_ARROW.BACK_LEFT) {
        targetValue = COMMON.SHIFT_ARROW.BACK;
      } else {
        targetValue = String(Number(targetValue) + 1);
      }
      shifts[TargetRow][TargetCol] = targetValue;
      // 親に返す用のlineを生成
      const newShifts = transpose2DArray(shifts);
      const newLine1 = newShifts[0].join('');
      const newLine2 = newShifts[1].join('');
      const newLine3 = newShifts[2].join('');
      // 親のStateに格納
      onChange(newLine1, newLine2, newLine3);
    }
  };

  return (
    <Box className={classes.root}>
      <TableContainer className={`${classes.container} ${className}`}>
        <Table className={classes.table}>
          <TableBody>
            {generateShifts(line1, line2, line3).map((row, i) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={i}>
                {row.map((value, j) =>
                  // ずらし設定がカスタムのときのみ、onClickイベントを発火させる
                  shiftConf === CLSFCN.SHIFT_PATTERN.CUSTOM_SHIFT ? (
                    <TableCell className={classes.tableCell}>
                      <Box className="arrowBox" data-row={i} data-col={j} onClick={handleClickShiftPanel}>
                        {renderArrowImage(value)}
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.tableCell}>{renderArrowImage(value)}</TableCell>
                  ),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default ShiftPanel;
