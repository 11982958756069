import React, { memo, useEffect, useState } from 'react';
import { createStyles, makeStyles, Box, GridList } from '@material-ui/core';
import { TextBox } from 'components/system/atoms';
import HttpConnection from 'utils/httpConnection';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { REST_API } from 'constants/apiUrls';
import { isSameDay } from 'utils/datetime.helper';
import { formatCurrency, formatPercent } from 'utils/number.helper';
import { DailySalesInfoType, StoreExtendType } from 'types/home/TopTypes';

const yellow = '#F4FF8A';
const red = '#FF3E3E';
const paleGrey = '#EBEBEB';
const lightGrey = '#CFCFCF';
const Grey = '#707070';
const border = `1px solid ${Grey}`;
const cellStyle = {
  padding: '0 8px',
  border,
  overflow: 'hidden',
};
const headerCellStyle = {
  display: 'grid',
  placeItems: 'center',
  textAlign: 'center' as const,
  ...cellStyle,
};
const dataCellStyle = {
  display: 'grid',
  placeItems: 'end',
  height: '30px',
  fontSize: '17px',
  ...cellStyle,
};

/* ************ Style ************ */
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    headers: {
      display: 'flex',
      margin: '0 0 3px 0',
    },
    dataList: {
      display: 'flex',
    },
    gridList: {
      width: '100%',
    },
    targetCostRateLabel: {
      fontSize: '16px',
      width: '72px',
      height: '41px',
      whiteSpace: 'pre-wrap',
      backgroundColor: yellow,
      ...headerCellStyle,
    },
    targetCostRate: {
      fontSize: '21px',
      width: '72px',
      height: '90px',
      color: red,
      backgroundColor: yellow,
      ...headerCellStyle,
    },
    tCRTextBox: {
      fontSize: '21px',
      width: '100%',
      height: '100%',
      color: red,
      backgroundColor: yellow,
      border: 'none',
    },
    salesInfoLabel: {
      height: '41px',
      fontSize: '16px',
      backgroundColor: lightGrey,
      ...headerCellStyle,
    },
    todaySalesInfo: {
      backgroundColor: `${yellow} !important`,
    },
    dataGroup: {},
    dataCell: {
      ...dataCellStyle,
    },
    dataCellWithColor: {
      backgroundColor: paleGrey,
      ...dataCellStyle,
    },
    headerCell: {
      height: '30px',
      fontSize: '17px',
      ...headerCellStyle,
    },
    headerCellWithColor: {
      height: '30px',
      fontSize: '17px',
      backgroundColor: paleGrey,
      ...headerCellStyle,
    },
  }),
);

/* ************ Const ************ */
const list = ['weekBeforeLast', 'lastWeek', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

/* ************ Component ************ */
const DailySalesInfoList: React.FC<{ dailySalesInfo?: DailySalesInfoType }> = memo(({ dailySalesInfo }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const [storeExtend, setStoreExtend] = useState<StoreExtendType | undefined>(dailySalesInfo?.targetCostRate);
  const [isTextBoxTRC, setIsTextBoxTRC] = useState<boolean>(false);

  useEffect(() => {
    setStoreExtend(dailySalesInfo?.targetCostRate);
  }, [dailySalesInfo?.targetCostRate]);

  /* ************ API ************ */
  const updateTargetCostRateApi = async (): Promise<StoreExtendType> => {
    const http = new HttpConnection({ dispatch });
    // バージョンNoは利用していないが、今後排他制御で利用する可能性があるためリクエストに含める。
    const data = await http.post<StoreExtendType>(REST_API.HOME.UPDATE_TARGET_COST_RATE, {
      headquarters_id: loginUserInfo?.currentHeadQId,
      store_id: loginUserInfo?.currentStoreId,
      target_cost_rate: storeExtend?.targetCostRate != null ? storeExtend?.targetCostRate : 0,
      version_no: storeExtend?.versionNo,
    });
    return data;
  };

  /* ************ Event ************ */
  // 目標原価率 変更
  const handleChangeTargetCostRate = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    // 入力値が存在しない場合
    if (!ev.target.value) {
      setStoreExtend({
        ...storeExtend,
        targetCostRate: undefined,
      });
    }
    // 入力値が数値に変換できる場合
    if (Number.isFinite(parseFloat(ev.target.value))) {
      setStoreExtend({
        ...storeExtend,
        targetCostRate: parseFloat(ev.target.value),
      });
    }
  };

  // 目標原価率 フォーカスアウト
  // フォーカスアウトで目標原価率を更新
  const handleBlurTargetCostRate = async () => {
    const data = await updateTargetCostRateApi();
    setIsTextBoxTRC(false);
    setStoreExtend(data);
  };

  // 目標原価率 クリック
  // クリックでtextBoxを表示
  const handleClickTargetCostRate = () => {
    if (loginUserInfo?.currentStoreId) {
      setIsTextBoxTRC(true);
      return;
    }
    setIsTextBoxTRC(false);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.headers}>
        <Box className={classes.targetCostRateLabel}>{`目標\n原価率`}</Box>
        <GridList className={classes.gridList} cols={10} spacing={0} cellHeight="auto">
          <Box className={`${classes.salesInfoLabel}`}>項目</Box>
          {list.map((v) => {
            const salesInfo = dailySalesInfo?.dailySalesInfoList[v];
            return (
              <Box
                key={v}
                className={`${classes.salesInfoLabel} ${
                  isSameDay(dailySalesInfo?.today, salesInfo?.summaryUnit) && classes.todaySalesInfo
                }`}>
                {salesInfo && salesInfo.label}
              </Box>
            );
          })}
        </GridList>
      </Box>
      <Box className={classes.dataList}>
        {/** 値 － 目標原価率 */}
        <Box className={classes.targetCostRate} onClick={handleClickTargetCostRate}>
          {isTextBoxTRC && (
            <TextBox
              type="number"
              value={`${storeExtend?.targetCostRate}`}
              onChange={handleChangeTargetCostRate}
              onBlur={handleBlurTargetCostRate}
              className={classes.tCRTextBox}
              fullWidth={false}
              autoFocus
            />
          )}
          {!isTextBoxTRC && `${storeExtend?.targetCostRate ? storeExtend.targetCostRate : 5}%`}
        </Box>
        <GridList className={classes.gridList} cols={10} spacing={0} cellHeight="auto">
          <Box className={classes.dataGroup}>
            <Box className={classes.headerCell}>原価率</Box>
            <Box className={classes.headerCellWithColor}>ｽﾃｰｼｮﾝ平均</Box>
            <Box className={classes.headerCell}>売上合計</Box>
          </Box>
          {list.map((v) => {
            const salesInfo = dailySalesInfo?.dailySalesInfoList[v];
            return (
              <Box key={v} className={classes.dataGroup}>
                <Box
                  className={`${classes.dataCell} ${
                    isSameDay(dailySalesInfo?.today, salesInfo?.summaryUnit) && classes.todaySalesInfo
                  }`}>
                  {salesInfo?.costRate != null && formatPercent(salesInfo.costRate)}
                </Box>
                <Box
                  className={`${classes.dataCellWithColor} ${
                    isSameDay(dailySalesInfo?.today, salesInfo?.summaryUnit) && classes.todaySalesInfo
                  }`}>
                  {salesInfo?.avgSales != null && formatCurrency(salesInfo.avgSales)}
                </Box>
                <Box
                  className={`${classes.dataCell} ${
                    isSameDay(dailySalesInfo?.today, salesInfo?.summaryUnit) && classes.todaySalesInfo
                  }`}>
                  {salesInfo?.totalSales != null && formatCurrency(salesInfo.totalSales)}
                </Box>
              </Box>
            );
          })}
        </GridList>
      </Box>
    </Box>
  );
});

export default DailySalesInfoList;
