import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { makeStyles, Box, Grid } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import CLSFCN from 'constants/classification';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import { useAppDispatch } from 'redux/hooks';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll/modules';
import { NormalSelectBox } from 'components/system/organisms';
import { getClsfcnByCategory } from 'utils/common.helper';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { StationResponseType, StationType } from 'types/maintenance/StationType';
import StationListTable from 'components/maintenance/machine/StationListTable';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import { NewGiftValueType } from 'components/maintenance/gift/NewGift';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '景品登録先一覧',
  screenId: COMMON.SCREEN_ID.REGISTER_GIFT_DESTINATION_LIST,
  currentMenu: COMMON.MENU.MAINTENANCE,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
  },
  decision: {
    textAlign: 'right',
  },
  buttonSize: {
    fontSize: '28px',
  },
  boxTextRight: {
    display: 'inline-flex',
    margin: '20px 0px 0px 15px',
  },
  giftNameType: {
    width: '718px',
    marginRight: '12px',
  },
  giftPriceType: {
    width: '138px',
  },
  mainTable: {
    marginTop: '24px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '29px',
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  boxTextRightContent: {
    display: 'inline-flex',
    alignItems: 'flex-end',
  },
  textUnderline: {
    fontSize: 24,
    borderBottom: '2px solid #707070',
    overflow: 'hidden',
    '& .noWrap': {
      whiteSpace: 'nowrap',
    },
  },
  selectedStationTable: {
    height: '381px',
    '& #dataStationTable': {
      height: '381px',
    },
  },
  boxTextRightSort: {
    position: 'absolute',
    left: '928px',
    top: '157px',
  },
  contentText: { fontSize: '24px' },
}));

type SubmitEntryGiftResponseType = {
  result: boolean;
};

// messages
const messageConfirm: string = MsgHelper.messageFormat(MSG.INF232);
const messageSuccess: string = MsgHelper.messageFormat(MSG.INF308, '景品', '登録景品一覧');

const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 7;

const RegisterGiftDestinationList: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [stationList, setStationList] = React.useState<Array<StationType>>([]);
  const [giftInfo, setGiftInfo] = React.useState<NewGiftValueType>({ giftName: '', giftPrice: '' });
  const sortDataSource = getClsfcnByCategory(CLSFCN.SORT_STATION_LIST.CATEGORY_CD);
  const [sort, setSort] = React.useState<string>(CLSFCN.SORT_STATION_LIST.STATION_NAME_ASC);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [openSaveConfirmDlg, setOpenSaveConfirmDlg] = React.useState(false);

  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.GIFT_DETAIL, { ...giftInfo });
  };

  /* ************ API ************ */
  const getStationDestList = async (sortType: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<StationResponseType>>(REST_API.MAINTENANCE.GET_STATION_LIST, { sort: sortType });
    let stationTypeListFormat: Array<StationType> = [];
    if (data.length > 0) {
      stationTypeListFormat = data.map((station, index) => ({
        stationId: station.stationId,
        order: index + 1,
        checked: false,
        boardSerial: station.boardSerial,
        boardStation: station.boardStation,
        stationName: `${station.stationName}：${station.giftName ?? COMMON.BLANK}`,
        machineType: station.machineType,
        leftRight: station.leftRight,
        giftName: station.giftName ?? null,
      }));
      setStationList([...stationTypeListFormat]);
    }
  };

  const submitEntryGift = async () => {
    const http = new HttpConnection({ dispatch });
    const arrStations: StationType[] = stationList.reduce((arrayStations: StationType[], station) => {
      if (station.checked) {
        arrayStations.push(station);
      }
      return arrayStations;
    }, []);
    const data = await http.post<SubmitEntryGiftResponseType>(REST_API.MAINTENANCE.POST_ENTRY_GIFT, {
      giftName: giftInfo.giftName,
      giftCost: giftInfo.giftPrice,
      stationList: arrStations,
    });
    if (data.result) {
      setOpenInfoDialog(true);
    }
  };

  useEffect(() => {
    const giftInfoParam: NewGiftValueType =
      location.state !== undefined ? (location.state as NewGiftValueType) : giftInfo;
    setGiftInfo({ ...giftInfoParam });
    getStationDestList(sort);
  }, []);

  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const sortType = ev.target.value;
    setSort(sortType);
    getStationDestList(sortType);
  };

  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleOkSaveConfirmDlg = () => {
    setOpenSaveConfirmDlg(false);
    submitEntryGift();
  };

  const handleSelectStationRow = (row: StationType) => {
    const newRows = stationList.map((item) => {
      if (row !== null && item.stationId === row.stationId) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });
    setStationList([...newRows]);
  };

  const handleClickDecisionButton = () => {
    setOpenSaveConfirmDlg(true);
  };

  const handleSuccessButton = () => {
    history.push(redirectLinks.MAINT_GIFT_LIST);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickGroupConfTop}>景品詳細</ReturnButton>
            </Box>
            <Box className={classes.boxTextRight}>
              <Box className={classes.giftNameType}>
                <div className={classes.textUnderline}>
                  <TooltipComponent tooltipText={giftInfo.giftName} displayWord={`景品名称 : ${giftInfo.giftName}`} />
                </div>
              </Box>
              <Box className={classes.giftPriceType}>
                <div className={classes.textUnderline}>
                  <div className="noWrap">{`原価 : ${giftInfo.giftPrice}`}</div>
                </div>
              </Box>
            </Box>
          </Box>
          <div className={classes.mainTable}>
            <Grid className={classes.previousBtn}>
              {stationList.length > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
            </Grid>
            <Box className={classes.boxTextRightSort}>
              <Box className={classes.boxTextRightContent}>
                <NormalSelectBox
                  name="sort"
                  dataSource={sortDataSource}
                  value={sort}
                  onChange={handleChangeSort}
                  label="並べ替え"
                />
              </Box>
            </Box>
            <div className={classes.selectedStationTable}>
              <StationListTable rows={stationList} handleSelectedItemChange={handleSelectStationRow} id={TABLE_ID} />
            </div>
            <Grid className={classes.nextBtn}>
              {MAX_DISPLAY_BAR < stationList.length && (
                <PrevNextButton className="nextScroll" onClick={handleClickNext} />
              )}
            </Grid>
          </div>
          <Grid className={classes.decision}>
            <DecisionButton onClick={handleClickDecisionButton}>
              <span className={classes.buttonSize}>登録</span>
            </DecisionButton>
          </Grid>
        </Box>
        <ConfirmDialog
          open={openSaveConfirmDlg}
          msg={messageConfirm}
          onOk={handleOkSaveConfirmDlg}
          onCancel={() => setOpenSaveConfirmDlg(false)}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <InfoDialog open={openInfoDialog} msg={messageSuccess} closeFunc={handleSuccessButton} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default RegisterGiftDestinationList;
