import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseMenuTabType } from 'types/data/dataType';
import { Box } from '@material-ui/core';

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #F2F2F2',
    height: '68px',
    lineHeight: '64px',
    textAlign: 'center',
    fontSize: '28px',
  },
  notSelected: {
    backgroundColor: '#8C93B0',
    color: '#DBDBDB',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * ContentMenuTab コンテンツメニュータブ
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ContentMenuTab: React.FC<BaseMenuTabType> = (props) => {
  const classes = useStyles();
  const { className, isSelected, onClick, children } = props;

  const styleClass = `${className || classes.root} ${isSelected ? classes.selected : classes.notSelected}`;

  return (
    <Box className={styleClass} onClick={onClick}>
      {children}
    </Box>
  );
};

// propsの初期値。未指定時に使用する。
ContentMenuTab.defaultProps = {
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(ContentMenuTab);
