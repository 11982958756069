import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import otherConfIcon from 'assets/images/otherConfIcon.svg';

/**
 * OtherConfButton Component
 * ゲーム設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// 型定義
type Props = {
  fullWidth?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '180px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    marginLeft: '28px',
    marginTop: '20px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    '& img': {
      position: 'relative',
      width: '50px',
      height: '50px',
    },
  },
  title: {
    position: 'relative',
    // width: '100%',
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  detailS: {
    border: '1px solid',
    borderColor: '#6A6A6A',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '232px',
    height: '36px',
    fontSize: '19px',
    marginBottom: '4px',
  },
  detailM: {
    border: '1px solid',
    borderColor: '#6A6A6A',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '232px',
    height: '60px',
    fontSize: '19px',
    marginBottom: '4px',
  },
  detailL: {
    border: '1px solid',
    borderColor: '#6A6A6A',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '232px',
    height: '140px',
    fontSize: '19px',
    marginBottom: '4px',
  },
}));

// コンポーネント定義
const OtherConfButton: React.FC<Props> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { fullWidth, onClick } = props;

  return (
    <Button variant="contained" fullWidth={fullWidth} className={classes.button} onClick={onClick}>
      <div className={classes.frame}>
        <div className={classes.title}>
          <b>その他</b>
        </div>
        <img src={otherConfIcon} alt="その他" />
        <div className={classes.detailS}>ステーション停止</div>
        <div className={classes.detailS}>センサ</div>
      </div>
    </Button>
  );

  return <></>;
};

// propsの初期値。未指定時に使用する。
OtherConfButton.defaultProps = {
  fullWidth: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(OtherConfButton);
