import COMMON from 'constants/common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NormalSwitch, ReturnButton, SelectButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectListType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { setAreaConf, setOperationConf, setOperationConfModeOff } from 'redux/slices/machineConfSlice';
import { OperationConfType } from 'types/machineConf/machineConfType';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { themeColor } from 'constants/theme';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MESSAGES from 'constants/messages';
import ShiftPanel from 'components/machineConf/organisms/ShiftOperation/ShiftPanel';
import { resetDescentLimitValue } from 'utils/resetDescentLimit.helper';
import { InfoDialog } from 'components/system/organisms';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ずらし運営設定',
  screenId: COMMON.SCREEN_ID.SHIFT_OPERATION,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .modeArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '272px',
      height: '72px',
      fontSize: '20px',
      position: 'relative',
      zIndex: 200,
      padding: '0 16px',
      marginLeft: '32px',
    },
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '28px',
    '& .shiftConfSelect': {
      width: '386px',
      height: '80px',
      fontSize: '30px',
      borderRadius: '8px',
    },
    '& .shiftCmArea': {
      width: '386px',
      fontSize: '19px',
      padding: '22px',
      marginTop: '40px',
      '& .scaMiddle': {
        display: 'flex',
        alignItems: 'end',
        marginTop: '20px',
      },
      '& .shiftCmSelect': {
        width: '120px',
        height: '56px',
        fontSize: '28px',
        marginRight: '12px',
      },
      '& .scaBottom': {
        display: 'flex',
        alignItems: 'start',
        marginTop: '20px',
        '&:before': {
          content: '"※"',
          display: 'block',
        },
      },
    },
    '& .panelArea': {
      width: '390px',
      height: '390px',
      marginLeft: '44px',
    },
    '& .cautionBox': {
      width: '213px',
      position: 'absolute',
      top: '0',
      right: '0',
      fontSize: '19px',
      padding: '8px',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        borderWidth: '0px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderTopWidth: '18px',
        borderRightWidth: '46px',
        borderBottomWidth: '18px',
      },
      '&:before': {
        top: '16px',
        left: '-46px',
      },
      '&:after': {
        top: '16px',
        left: '-42px',
      },
      '& .cautionTitle': {
        fontSize: '24px',
        color: '#FF0000',
      },
      '& .cautionTextTop': {
        marginTop: '8px',
      },
      '& .cautionTextBottom': {
        marginTop: '20px',
      },
    },
  },
  doneButtonArea: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
  // ステーション別Style
  leftStyle: {
    borderColor: themeColor.leftDarkColor,
    '&:before': {
      borderRightColor: themeColor.leftDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
    '&:before': {
      borderRightColor: themeColor.rightDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  // Common Style
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  SHIFT_CONF: 'shiftConf',
  SHIFT_CM: 'shiftCm',
};

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - ずらし設定
 *
 */
const ShiftOperation: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);

  // Local
  const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tempOperationConf, setTempOperationConf] = useState<OperationConfType | undefined>(operationConf);
  const [tempLine1, setTempLine1] = useState<string | undefined>(operationConf?.line1);
  const [tempLine2, setTempLine2] = useState<string | undefined>(operationConf?.line2);
  const [tempLine3, setTempLine3] = useState<string | undefined>(operationConf?.line3);
  const customLine1 = useRef<string>('');
  const customLine2 = useRef<string>('');
  const customLine3 = useRef<string>('');
  const [openConfirmDialogModeSetting, setOpenConfirmDialogModeSetting] = useState(false);
  const [openConfirmDialogModeSettingOff, setOpenConfirmDialogModeSettingOff] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  // DataSource
  const shiftPatternDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.SHIFT_PATTERN.CATEGORY_CD), []);
  const shiftCmDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.SHIFT_CM.CATEGORY_CD), []);

  const isOperationDisable = tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.SHIFT_OPERATION;

  /* ************ Event ************ */
  useEffect(() => {
    if (!operationConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  useEffect(() => {
    if (tempOperationConf?.shiftConf) {
      setUpdatedLines(tempOperationConf?.shiftConf);
    }
  }, [tempOperationConf?.shiftConf]);

  const setUpdatedLines = (shiftConf: string) => {
    switch (shiftConf) {
      case CLSFCN.SHIFT_PATTERN.FRONT_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}`);
        break;
      case CLSFCN.SHIFT_PATTERN.RIGHT_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`);
        break;
      case CLSFCN.SHIFT_PATTERN.LEFT_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`);
        break;
      case CLSFCN.SHIFT_PATTERN.CENTER_HORIZONTAL_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.RIGHT}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.PIT}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.LEFT}`);
        break;
      case CLSFCN.SHIFT_PATTERN.CENTER_VERTICAL_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`);
        break;
      case CLSFCN.SHIFT_PATTERN.CENTER_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.FRONT_RIGHT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.BACK_RIGHT}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.FRONT}${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.FRONT_LEFT}${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.BACK_LEFT}`);
        break;
      case CLSFCN.SHIFT_PATTERN.BACK_SHIFT:
        setTempLine1(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`);
        setTempLine2(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`);
        setTempLine3(`${COMMON.SHIFT_ARROW.PIT}${COMMON.SHIFT_ARROW.BACK}${COMMON.SHIFT_ARROW.BACK}`);
        break;
      case CLSFCN.SHIFT_PATTERN.CUSTOM_SHIFT:
        // 画面内で操作したパネル状態があれば優先して反映する
        // ※ずらし設定を変更するとパネル状態がリセットされるため、操作中のパネル状態を残す
        if (customLine1.current && customLine2.current && customLine3.current) {
          setTempLine1(customLine1.current);
          setTempLine2(customLine2.current);
          setTempLine3(customLine3.current);
        } else if (
          // ずらし設定がカスタムで、画面内で操作したパネル状態がないときはグローバルステートの値を反映する
          // ※カスタム設定のときの初期表示を行うため
          operationConf?.shiftConf === CLSFCN.SHIFT_PATTERN.CUSTOM_SHIFT &&
          !(customLine1.current && customLine2.current && customLine3.current)
        ) {
          setTempLine1(tempLine1);
          setTempLine2(tempLine2);
          setTempLine3(tempLine3);
          // パネル状態を残しておく
          // ※カスタム設定で画面遷移し、未操作で他のずらし設定に変えるとパネル状態がリセットされるため
          customLine1.current = tempLine1 ?? '';
          customLine2.current = tempLine2 ?? '';
          customLine3.current = tempLine3 ?? '';
        } else {
          // 上記以外の場合はカスタム設定の初期値を設定する
          // ※カスタム設定以外は○があるので、矢印だけのパターンに書き換える
          setTempLine1(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.LEFT}`);
          setTempLine2(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.LEFT}`);
          setTempLine3(`${COMMON.SHIFT_ARROW.LEFT}${COMMON.SHIFT_ARROW.RIGHT}${COMMON.SHIFT_ARROW.LEFT}`);
        }
        break;
      default:
        break;
    }
  };

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // モード設定
  const handleChangeOperationConf = useCallback(
    // ダイアログを表示する。
    (event) => {
      if (event.target.checked) {
        setOpenConfirmDialogModeSetting(true);
      } else {
        setOpenConfirmDialogModeSettingOff(true);
      }
    },
    [],
  );

  // モード設定 OFF->ON Yes
  const handleClickOkModeSetting = useCallback(() => {
    // モード設定を「ずらし運営」に変更
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            operationConf: COMMON.OPERATION_CONF.SHIFT_OPERATION,
          }
        : prevState,
    );
    handleCloseConfirmDialogModeSetting();
  }, []);

  // モード設定 OFF->ON No
  const handleCloseConfirmDialogModeSetting = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSetting(false);
  };

  // モード設定 ON->OFF Yes
  const handleClickOkModeSettingOff = async () => {
    // セッション更新
    const http = new HttpConnection({ dispatch });
    const newTempAreaConf = resetDescentLimitValue(areaConf);
    // 運営設定「設定なし」をグローバルステートに保存
    dispatch(setOperationConfModeOff(COMMON.OPERATION_CONF.NO_CONF));

    if (!newTempAreaConf?.hasReset) {
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF).then(() => {
        // ダイアログを閉じる
        setOpenConfirmDialogModeSettingOff(false);

        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
    } else {
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });
      setOpenConfirm(true);
      // reset DescentLimit
      dispatch(setAreaConf(areaConfReset));
    }
  };

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // モード設定 ON->OFF No
  const handleCloseConfirmDialogModeSettingOff = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSettingOff(false);
  };

  // SelectList(共通) 一覧選択情報を初期化
  const handleCloseSelectList = useCallback(() => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // SelectList(areaConf) 各項目の設定値に反映
  const handleClickSelectList = useCallback((data: { value: string }, name: string) => {
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: data.value,
          }
        : prevState,
    );
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // SelectList(areaConf) 設定初期値
  const initialSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickSelectList,
    onClose: handleCloseSelectList,
  };

  // 設定パターン
  const handleClickShiftPattern = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: shiftPatternDataSource,
        name: INPUT_NAMES.SHIFT_CONF,
        placement: 'right',
        title: '設定パターンを選択してください。',
        value: tempOperationConf?.shiftConf,
      });
    },
    [tempOperationConf?.shiftConf],
  );

  // ずらしcm選択
  const handleClickShiftCm = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: shiftCmDataSource,
        name: INPUT_NAMES.SHIFT_CM,
        placement: 'right',
        title: 'ずらす距離(cm)を選択してください。',
        value: tempOperationConf?.shiftCm,
      });
    },
    [tempOperationConf?.shiftCm],
  );

  const handleChangeShiftPanel = (newLine1: string, newLine2: string, newLine3: string) => {
    setTempLine1(newLine1);
    setTempLine2(newLine2);
    setTempLine3(newLine3);
    // 設定したカスタムのパネル状態を残しておく
    customLine1.current = newLine1;
    customLine2.current = newLine2;
    customLine3.current = newLine3;
  };

  const handleClickDone = async () => {
    // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.SHIFT_OPERATION_CONF_VALIDATION, {
        operationConf: COMMON.OPERATION_CONF.SHIFT_OPERATION,
        shiftConf: tempOperationConf?.shiftConf,
        shiftCm: tempOperationConf?.shiftCm,
        line1: tempLine1,
        line2: tempLine2,
        line3: tempLine3,
      })
      .then(() => {
        // 運営設定をグローバルステートに保存
        if (tempOperationConf && tempLine1 && tempLine2 && tempLine3) {
          dispatch(
            setOperationConf({
              ...tempOperationConf,
              line1: tempLine1,
              line2: tempLine2,
              line3: tempLine3,
            }),
          );
        }
        if (areaConf) {
          dispatch(
            setAreaConf({
              ...areaConf,
              areaConf: COMMON.SWITCH.OFF,
            }),
          );
        }
        // ダイアログを閉じる
        setOpenConfirmDialogModeSettingOff(false);
        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                ゲーム設定TOP
              </ReturnButton>
              <Box className={`${classes.baseArea} ${stationStyle} modeArea`}>
                <Box>ずらし運営</Box>
                <NormalSwitch
                  leftRight={leftRight}
                  value=""
                  checked={tempOperationConf?.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION}
                  onChange={handleChangeOperationConf}
                />
              </Box>
              <Box className="discription">
                ずらし運営を設定します。
                <br />
                各種設定をし、<Typography className={classes.doneImage}>確 定</Typography>してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box>
                <SelectButton
                  disabled={isOperationDisable}
                  className="shiftConfSelect"
                  leftRight={leftRight}
                  onClick={handleClickShiftPattern}
                  arrowSize="32px"
                  isSelected={selectList.name === INPUT_NAMES.SHIFT_CONF}>
                  {tempOperationConf?.shiftConf &&
                    getClassificationName(CLSFCN.SHIFT_PATTERN.CATEGORY_CD, tempOperationConf.shiftConf)}
                </SelectButton>
                <Box className={`${classes.baseArea} ${stationStyle} shiftCmArea`}>
                  <Box>キャッチャー下降後、</Box>
                  <Box className="scaMiddle">
                    <SelectButton
                      disabled={isOperationDisable}
                      className="shiftCmSelect"
                      leftRight={leftRight}
                      onClick={handleClickShiftCm}
                      arrowSize="24px"
                      isSelected={selectList.name === INPUT_NAMES.SHIFT_CM}>
                      {tempOperationConf?.shiftCm &&
                        getClassificationName(CLSFCN.SHIFT_CM.CATEGORY_CD, tempOperationConf.shiftCm)}
                    </SelectButton>
                    <Box>矢印の方向にずらす。</Box>
                  </Box>
                  <Box className="scaBottom">
                    移動範囲を超える場合、
                    <br />
                    それ以上には動きません。
                  </Box>
                </Box>
              </Box>
              <Box className="panelArea">
                {tempOperationConf && (
                  <ShiftPanel
                    shiftConf={tempOperationConf.shiftConf}
                    line1={`${tempLine1}` || ''}
                    line2={`${tempLine2}` || ''}
                    line3={`${tempLine3}` || ''}
                    onChange={handleChangeShiftPanel}
                  />
                )}
              </Box>
              {tempOperationConf?.shiftConf === CLSFCN.SHIFT_PATTERN.CUSTOM_SHIFT && (
                <Box className={`${classes.baseArea} ${stationStyle} cautionBox`}>
                  <Box className="cautionTitle">設定してください</Box>
                  <Box className="cautionTextTop">タッチで、矢印を設定します。</Box>
                  <Box className="cautionTextBottom">
                    運営に支障がないか
                    <br />
                    必ず動作確認を
                    <br />
                    してください。
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.doneButtonArea}>
              <DoneButton disabled={isOperationDisable} onClick={handleClickDone}>
                確 定
              </DoneButton>
            </Box>
            {/* ずらし設定がONのときのみ、操作可能にする */}
            {tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.SHIFT_OPERATION && (
              <Box className={classes.disableInputArea} />
            )}
          </Box>
          <SelectList selectList={selectList} />
          <ConfirmDialog
            open={openConfirmDialogModeSetting}
            msg={MESSAGES.INF222}
            onOk={handleClickOkModeSetting}
            onCancel={handleCloseConfirmDialogModeSetting}
          />
          <ConfirmDialog
            open={openConfirmDialogModeSettingOff}
            msg={MESSAGES.INF247}
            onOk={handleClickOkModeSettingOff}
            onCancel={handleCloseConfirmDialogModeSettingOff}
          />
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default ShiftOperation;
