import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useAppSelector } from 'redux/hooks';

/* ************* constant ************* */
const PRIZE_AREA_MAX = 100;
const XY_AREA_SIZE_M = 360;
const XY_AREA_SIZE_L = 390;
const AREA_SIZE = 475;
const MAX_AREA_SIZE = 440;
const ICON_SIZE = 24;
const MAX_Y = 80;

type BoundAreaDetailType = {
  className?: string;
  isLeft: boolean;
  size: 'M' | 'L';
};

type StylesProps = {
  minX: number | string;
  maxX: number | string;
  minY: number | string;
  maxY: number | string;
  size: 'M' | 'L';
  opacity: number;
};

/* ************ Style ************ */
const useStyles = makeStyles<Theme, StylesProps>(() => ({
  boundArea: (props) => ({
    position: 'absolute',
    width: '414px',
    padding: `${props.size === 'M' ? '27px' : '12px'}`,
    top: `${props.size === 'M' ? '45px' : '0'}`,
  }),
  boundXStyle: (props) => ({
    height: `${props.size === 'M' ? '313px' : '341px'}`,
    borderLeft: `${props.minX}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderRight: `${props.maxX}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderTop: `${props.maxY}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderBottom: `${props.minY}px solid rgba(0, 0, 0, ${props.opacity})`,
  }),
}));

const convertPixel = (width: number, areaSize: number) => Math.round((width * areaSize) / PRIZE_AREA_MAX);

const BoundAreaDetailJack: React.FC<BoundAreaDetailType> = (props) => {
  const { isLeft, className, size } = props;
  const iconHalfSize = ICON_SIZE / 2;

  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  const areaSize = size === 'M' ? XY_AREA_SIZE_M : XY_AREA_SIZE_L;
  const minX = isLeft
    ? convertPixel(Number(nonAreaXMinSide), areaSize)
    : convertPixel(Number(nonAreaXMaxSide), areaSize);
  const maxX = isLeft
    ? convertPixel(Number(nonAreaXMaxSide), areaSize)
    : convertPixel(Number(nonAreaXMinSide), areaSize);

  const minNonAreaY =
    Number(nonAreaYMinSide) <= MAX_Y - Number(nonAreaYMaxSide)
      ? Number(nonAreaYMinSide)
      : MAX_Y - Number(nonAreaYMaxSide);

  const maxNonAreaY =
    Number(nonAreaYMaxSide) <= MAX_Y - Number(nonAreaYMinSide)
      ? Number(nonAreaYMaxSide)
      : MAX_Y - Number(nonAreaYMinSide);

  const maxSizeHeight = size === 'M' ? MAX_AREA_SIZE : AREA_SIZE;
  const minY = convertPixel(minNonAreaY, maxSizeHeight + iconHalfSize);
  const maxY = convertPixel(maxNonAreaY, maxSizeHeight + iconHalfSize);

  const opacity = 0.5;
  const styleProps: StylesProps = { minX, maxX, minY, maxY, size, opacity };
  const classes = useStyles(styleProps);

  return (
    <div className={`${className} ${classes.boundArea}`}>
      <div className={classes.boundXStyle} />
    </div>
  );
};

export default BoundAreaDetailJack;
