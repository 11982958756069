import COMMON from 'constants/common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectListType, TenkeyOpenEventType, TenkeyType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import UrlHelper from 'utils/url.helper';
import { setLedServiceOtherConf } from 'redux/slices/machineConfSlice';
import ConfSelectArea from 'components/machineConf/organisms/selectArea/ConfSelectArea';
import { LedServiceOtherConfType } from 'types/machineConf/machineConfType';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import Tenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/Tenkey';
import ConfTenkeyArea from 'components/machineConf/organisms/tenkeyArea/ConfTenkeyArea';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '料金サービス設定',
  screenId: 'SCR341',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    marginTop: '48px',
    '& .row1, .row2': {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '28px',
    },
  },
  bottomContent: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  settingBtn: {
    width: '176px',
    height: '65px',
    fontSize: '19px',
  },
  settingBtnLarge: {
    width: '232px',
    height: '65px',
    fontSize: '19px',
  },
  pricingSelectList: {
    width: '440px',
    '& button': {
      width: '100%',
    },
    '& #scrollSelectList': {
      width: '100%',
    },
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  PRICING: 'pricing',
  GIFT_COST: 'giftCost',
  TARGET_COST_RATE: 'targetCostRate',
  FREE_GET: 'freeGet',
  KEEP_TIME: 'keepTime',
  PLAYING_DEEMED_TIME: 'playingDeemedTime',
};

const TENKEY_OPTION = {
  GIFT_COST: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 4,
    scaleSize: 0,
    maxValue: 1100,
    minValue: 0,
  },
};

/* ******** Main Component ******** */
/**
 *
 * @author shoya.yoshikawa
 * @returns {React.FC} - 料金サービス設定
 *
 */
const CostServiceConf: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineConf.ledServiceOtherConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);

  // Local
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tenkey, setTenkey] = useState(INITIAL_TEN_KEY.tenkey);
  const [tempLedServiceOtherConf, setTempLedServiceOtherConf] = useState<LedServiceOtherConfType | undefined>(
    ledServiceOtherConf,
  );

  // DataSource
  const pricingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.PRICING.CATEGORY_CD), []);
  const targetCostRateDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.TARGET_COST_RATE.CATEGORY_CD), []);
  const freeGetDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.FREE_GET.CATEGORY_CD), []);
  const keepTimeDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.KEEP_TIME.CATEGORY_CD), []);
  const playingDeemedTimeDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.PLAYING_DEEMED_TIME.CATEGORY_CD), []);

  /* ************ Event ************ */
  useEffect(() => {
    if (!ledServiceOtherConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 選択リスト 各項目の設定値に反映
  const handleClickSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setSelectList(INITIAL_SELECT_LIST.selectList);
      setTempLedServiceOtherConf((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            [name]: data.value,
          };
        }
        return prevState;
      });
    },
    [tempLedServiceOtherConf],
  );

  // テンキー 閉じる
  const handleCloseTenkey = useCallback(() => setTenkey(INITIAL_TEN_KEY.tenkey), []);

  // テンキー 決定
  const handleDecision = useCallback(
    (value: string, name: string) => {
      setTempLedServiceOtherConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempLedServiceOtherConf],
  );

  const initialTenkeyGiftCost: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecision,
    onClose: handleCloseTenkey,
  };

  // 料金設定 押下
  const handleClickPricing = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        className: classes.pricingSelectList,
        dataSource: pricingDataSource,
        name: INPUT_NAMES.PRICING,
        placement: 'right',
        title: '選択してください',
        value: tempLedServiceOtherConf?.pricing,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.pricing, handleClickSelectList],
  );

  // 景品原価 押下
  const handleClickGiftCost: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyGiftCost,
      anchorPosition: { top: 100, left: 640 },
      name: INPUT_NAMES.GIFT_COST,
      option: TENKEY_OPTION.GIFT_COST,
      value: String(tempLedServiceOtherConf?.giftCost),
    });
  }, [tempLedServiceOtherConf?.giftCost]);

  // 目標景品原価率 押下
  const handleClickTargetCostRate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: targetCostRateDataSource,
        name: INPUT_NAMES.TARGET_COST_RATE,
        placement: 'left',
        title: '選択してください',
        value: tempLedServiceOtherConf?.targetCostRate,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.targetCostRate, handleClickSelectList],
  );

  // とり放題 押下
  const handleClickFreeGet = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: freeGetDataSource,
        name: INPUT_NAMES.FREE_GET,
        placement: 'right-end',
        title: '選択してください',
        value: tempLedServiceOtherConf?.freeGet,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.freeGet, handleClickSelectList],
  );

  // 台キープ時間 押下
  const handleClickKeepTime = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: keepTimeDataSource,
        name: INPUT_NAMES.KEEP_TIME,
        placement: 'right-end',
        title: '選択してください',
        value: tempLedServiceOtherConf?.keepTime,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.keepTime, handleClickSelectList],
  );

  // 連続プレイみなし時間 押下
  const handleClickPlayingDeemedTime = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: playingDeemedTimeDataSource,
        name: INPUT_NAMES.PLAYING_DEEMED_TIME,
        placement: 'left-end',
        title: '選択してください',
        value: tempLedServiceOtherConf?.playingDeemedTime,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.playingDeemedTime, handleClickSelectList],
  );

  // 戻るボタン
  const handleclickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
        selectConfId: 'none',
      }),
    );
  };

  // 確定ボタン
  const handleClickDone = async () => {
    // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post<LedServiceOtherConfType>(REST_API.MACHINE_CONF.COST_SERVICE_CONF_VALIDATION, {
        pricing: tempLedServiceOtherConf?.pricing,
        giftCost: tempLedServiceOtherConf?.giftCost,
        targetCostRate: tempLedServiceOtherConf?.targetCostRate,
        freeGet: tempLedServiceOtherConf?.freeGet,
        keepTime: tempLedServiceOtherConf?.keepTime,
        playingDeemedTime: tempLedServiceOtherConf?.playingDeemedTime,
      })
      .then(() => {
        // グローバルステートに保存
        dispatch(setLedServiceOtherConf(tempLedServiceOtherConf));
        // 画面遷移
        history.push(
          UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
            selectConfId: 'none',
          }),
        );
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleclickReturn}>設定TOP</ReturnButton>
              <Box className="discription">
                「料金」「原価」「時間」など運営に関わる項目を設定できます。
                <br />
                設定内容を選択し、<Typography className={classes.doneImage}>確 定</Typography>してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="row1">
                <ConfSelectArea
                  title="料金設定"
                  leftRight={leftRight}
                  className={classes.settingBtnLarge}
                  onClick={handleClickPricing}
                  isSelected={selectList.name === INPUT_NAMES.PRICING}
                  value={
                    tempLedServiceOtherConf?.pricing &&
                    getClassificationName(CLSFCN.PRICING.CATEGORY_CD, tempLedServiceOtherConf.pricing)
                  }
                />
                <ConfTenkeyArea
                  title="景品原価"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onOpen={handleClickGiftCost}
                  isSelected={tenkey.name === INPUT_NAMES.GIFT_COST}
                  value={`\xA5 ${String(tempLedServiceOtherConf?.giftCost)}`}
                />
                <ConfSelectArea
                  title="目標景品原価率"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickTargetCostRate}
                  isSelected={selectList.name === INPUT_NAMES.TARGET_COST_RATE}
                  value={
                    tempLedServiceOtherConf?.targetCostRate &&
                    getClassificationName(CLSFCN.TARGET_COST_RATE.CATEGORY_CD, tempLedServiceOtherConf.targetCostRate)
                  }
                />
              </Box>
              <Box className="row2">
                <ConfSelectArea
                  title="とり放題"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickFreeGet}
                  isSelected={selectList.name === INPUT_NAMES.FREE_GET}
                  value={
                    tempLedServiceOtherConf?.freeGet &&
                    getClassificationName(CLSFCN.FREE_GET.CATEGORY_CD, tempLedServiceOtherConf.freeGet)
                  }
                />
                <ConfSelectArea
                  title="両替時間"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickKeepTime}
                  isSelected={selectList.name === INPUT_NAMES.KEEP_TIME}
                  value={
                    tempLedServiceOtherConf?.keepTime &&
                    getClassificationName(CLSFCN.KEEP_TIME.CATEGORY_CD, tempLedServiceOtherConf.keepTime)
                  }
                />
                <ConfSelectArea
                  title="連続プレイみなし時間"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickPlayingDeemedTime}
                  isSelected={selectList.name === INPUT_NAMES.PLAYING_DEEMED_TIME}
                  value={
                    tempLedServiceOtherConf?.playingDeemedTime &&
                    getClassificationName(
                      CLSFCN.PLAYING_DEEMED_TIME.CATEGORY_CD,
                      tempLedServiceOtherConf.playingDeemedTime,
                    )
                  }
                />
              </Box>
            </Box>
            <Box className={classes.bottomContent}>
              <DoneButton onClick={handleClickDone}>確 定</DoneButton>
            </Box>
          </Box>
          <SelectList selectList={selectList} />
          <Tenkey tenkey={tenkey} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default CostServiceConf;
