/**
 * Function use for messages
 * @author: akinori.akamine
 */
export default class MessageHelper {
  /**
   * get constants message
   *
   * @param message from /constants/messages
   * @param ...args arguments message.
   * @returns String message.
   */
  static messageFormat = (message: string, ...args: Array<string>): string => {
    let msg = message;
    args.forEach((arg, i) => {
      msg = msg.replace(`{${i}}`, arg);
    });
    return msg;
  };
}
