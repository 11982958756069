import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TenkeyInputType, TenkeyOpenEventType } from 'types/support/supportType';
import COMMON from 'constants/common';
import { InputBase, Theme } from '@material-ui/core';
import { themeColor } from 'constants/theme';

type StyleProps = {
  disabledTextColor?: string;
  leftRight?: string;
};

// makeStylesでstyleを定義
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    border: '2px solid',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    '& input': {
      textAlign: 'center',
    },
    '&.disabledStyle': {
      color: ({ disabledTextColor }) => disabledTextColor ?? themeColor.mainText,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: ({ leftRight }) =>
        leftRight === COMMON.LEFT_RIGHT.LEFT ? theme.leftStation.itemBorder : theme.rightStation.itemBorder,
    },
  },
  defaultStyle: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: '36px',
    lineHeight: '36px',
    height: '64px',
    width: '100px',
  },
  readOnly: {
    color: '#000000 !important',
    backgroundColor: '#FFFFFF !important',
    borderColor: '#FF0000 !important',
    zIndex: 1350,
  },
  left: {
    borderColor: theme.leftStation.itemBorder,
  },
  right: {
    borderColor: theme.rightStation.itemBorder,
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * Tenkey 一覧選択ボタン
 * 一覧選択画面を表示する。
 *
 * @author atsushi.teruya
 * @Params {SelectButtonType} props - 一覧選択ボタン利用に必要なパラメータ
 * @returns {React.FC}
 *
 */
const TenkeyInput: React.FC<TenkeyInputType> = (props) => {
  const { className, disabled, fullWidth, isSelected, leftRight, value, onOpen, disabledTextColor } = props;
  const classes = useStyles({ leftRight, disabledTextColor });

  const styleClass = `${classes.root} ${className || classes.defaultStyle} ${
    (leftRight && (leftRight && leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.left : classes.right)) || ''
  } ${(isSelected && classes.readOnly) || ''} ${disabled ? 'disabledStyle' : ''}`;

  const handleOpen: TenkeyOpenEventType = () => {
    if (disabled) return;
    if (onOpen) onOpen();
  };
  return (
    <InputBase
      className={styleClass}
      fullWidth={fullWidth}
      value={value}
      onClick={handleOpen}
      onFocus={handleOpen}
      disabled={disabled || isSelected}
    />
  );
};

// propsの初期値。未指定時に使用する。
TenkeyInput.defaultProps = {
  fullWidth: false,
  disabled: false,
  isSelected: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(TenkeyInput);
