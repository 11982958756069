import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from 'assets/images/InfoIcon.svg';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '640px',
  },
  content: {
    alignItems: 'start',
    display: 'flex',
    padding: '40px 8px 0px 40px ',
  },
  defaultBox: {
    margin: '8px 0 0 24px',
  },
  defaultText: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '21px',
  },
  action: {
    padding: '0px 40px 16px 40px ',
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailmsg: {
    color: theme.palette.common.white,
    fontSize: 24,
    overflow: 'hidden',
  },
  buttonIcon: {
    height: 80,
    width: 200,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: '#FF7070',
    },
  },
  buttonCancel: {
    backgroundColor: '#BCBCBC',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#BCBCBC',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
}));

type DialogProps = {
  open: boolean;
  msg: string;
  detailMsg?: string;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
  onOk: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
  addClass?: {
    contentBox?: string;
    contentText?: string;
  };
};

const ConfirmDialog: React.FC<DialogProps> = ({
  open,
  msg,
  detailMsg,
  onOk,
  okBtnLabel = 'はい',
  onCancel,
  cancelBtnLabel = 'いいえ',
  children,
  addClass,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <img src={InfoIcon} alt="Info" />
        <Box className={`${classes.defaultBox} ${addClass?.contentBox}`}>
          <DialogContentText className={`${classes.defaultText} ${addClass?.contentText}`}>{msg}</DialogContentText>
          {detailMsg && (
            <DialogContentText
              classes={{
                root: classes.detailmsg,
              }}>
              {detailMsg}
            </DialogContentText>
          )}
          {children}
        </Box>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.action,
        }}>
        <NormalButton onClick={onOk} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {okBtnLabel}
        </NormalButton>
        <NormalButton onClick={onCancel} className={`${classes.buttonIcon} ${classes.buttonCancel}`}>
          {cancelBtnLabel}
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
