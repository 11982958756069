import * as React from 'react';
import { Box, Modal, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloneTenkeyType } from 'types/support/supportType';
import CloseButton from 'components/support/atoms/buttons/CloseButton';
import NumberButton from 'components/support/atoms/buttons/NumberButton';
import PlusMinusButton from 'components/support/atoms/buttons/PlusMinusButton';
import DeleteButton from 'components/support/atoms/buttons/DeleteButton';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import MESSAGES from 'constants/messages';
import { handleCallFrontError } from 'redux/slices/commonSlice';
import MessageHelper from 'utils/message.helper';
import COMMON from 'constants/common';
import { AreaConfType, OperationConfType } from 'types/machineConf/machineConfType';
import { InfoDialog } from '../../../system/organisms';

/* ************* constant ************* */
const DECIMAL_POINT = '.';
const DEFAULT_MAX_DECIMAL_VALUE = 50;
const DEFAULT_MAX_DECIMAL_VALUE2 = 100;
/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    backgroundColor: 'transparent',
    height: '576px',
  },
  baseArea: {
    display: 'flex',
  },
  inputArea: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  inputNumberArea: {
    width: '276px',
    paddingTop: '4px',
  },
  plusMinusArea: {
    padding: '28px 0px 0px 8px',
    width: '92px',
  },
  tenkey: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '12px',
    height: '572px',
    padding: '12px 16px',
    margin: 0,
    width: '400px',
  },
  inputNumber: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    fontSize: '70px',
    height: '84px',
    paddingRight: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '368px',
  },
  inputNumberError: {
    color: 'red',
  },
  inputNumberNormal: {
    color: 'black',
  },
  numberButton: {
    border: '1px solid #707070',
    fontSize: '70px',
    height: '84px',
    margin: '8px 8px 0px 0px',
    width: '84px',
  },
  deleteButton: {
    height: '84px',
    margin: '8px 8px 0px 0px',
    width: '84px',
  },
  plusMinusButton: {
    height: '154px',
    marginBottom: '24px',
    width: '84px',
  },
  decisionButton: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    fontSize: '56px',
    height: '80px',
    marginTop: '4px',
    width: '368px',
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: 'rgba(255, 62, 62, 0.8)',
    },
  },
}));

/* *************    constants   ************* */
export const INITIAL_TEN_KEY: { tenkey: CloneTenkeyType } = {
  tenkey: {
    anchorPosition: { top: 100, left: 0 },
    name: '',
    open: false,
    option: {
      mode: 0,
      integerSize: 3,
      scaleSize: 1,
      maxValue: 999,
      minValue: 0,
      lPower1: 0,
      rPower1: 0,
      limitLPower: 0,
      limitRPower: 0,
    },
    value: '',
    onDecision: () => undefined,
  },
};

/* ************* local function ************* */
const toNumberString = (numberValue: string) => (!numberValue || numberValue === DECIMAL_POINT ? '0' : numberValue);
const toValueString = (value: string) => (!value || value === 'null' ? '' : value);

const calcUpDown = (
  numberValue: string,
  mode: number,
  plusOrMinus: number,
  scaleSize: number,
  decimalLimit: number,
  decimalLimit2: number,
) => {
  let incrementValue = 1;
  const minValue = 0.1;
  const mediumValue = 0.5;
  const maxValue = 1;

  if (mode === COMMON.TENKEY_MODE.DECIMAL) {
    if (Number(numberValue) < decimalLimit || (plusOrMinus < 0 && Number(numberValue) === decimalLimit)) {
      incrementValue = plusOrMinus * minValue;
    }

    if (
      (Number(numberValue) > decimalLimit && Number(numberValue) < decimalLimit2) ||
      (plusOrMinus > 0 && Number(numberValue) === decimalLimit)
    ) {
      incrementValue = plusOrMinus * mediumValue;
    }

    if (
      (Number(numberValue) > decimalLimit && Number(numberValue) < decimalLimit2) ||
      (plusOrMinus < 0 && Number(numberValue) === decimalLimit2)
    ) {
      incrementValue = plusOrMinus * mediumValue;
    }

    if (Number(numberValue) > decimalLimit2 || (plusOrMinus > 0 && Number(numberValue) === decimalLimit2)) {
      incrementValue = plusOrMinus * maxValue;
    }
  } else {
    incrementValue = plusOrMinus * 1;
  }

  return String(
    parseFloat((Number(numberValue.replace(DECIMAL_POINT, '.')) + incrementValue).toFixed(scaleSize)),
  ).replace('.', DECIMAL_POINT);
};

// Handle process click down
/* eslint-disable  @typescript-eslint/no-explicit-any */
const processClickDown = (inputValue: string, options: any) => {
  const { mode, decimalLimit, scaleSize, decimalLimit2 } = options;
  // 空文字を含む数字以外の値や小数点だけの場合は0に変換。
  const numberValue = toNumberString(inputValue);
  // 1-1. 値算出
  // 計算関数で+1した値を取得。
  const resultValue = calcUpDown(
    numberValue,
    mode,
    -1,
    scaleSize,
    decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
    decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
  );
  return resultValue;
};

// Process click up
const processClickUp = (inputValue: string, options: any) => {
  const { mode, scaleSize, decimalLimit, decimalLimit2 } = options;
  // 空文字を含む数字以外の値や小数点だけの場合は0に変換。
  const numberValue = toNumberString(inputValue);
  // 1-1. 値算出
  // 計算関数で+1した値を取得。
  const resultValue = calcUpDown(
    numberValue,
    mode,
    1,
    scaleSize,
    decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
    decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
  );
  return resultValue;
};

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * Tenkey テンキー
 * 数値を入力するためのテンキー画面を表示する。
 *
 * @author atsushi.teruya
 * @Params {SelectListType} selectList - 一覧選択の情報
 * @returns {React.FC} - 一覧選択の表示
 *
 */
const CloneNewTenkey: React.FC<{
  tenkey: CloneTenkeyType;
  tempAreaConf: AreaConfType | undefined;
  tempOperationConf: OperationConfType | undefined;
}> = ({ tenkey, tempAreaConf, tempOperationConf }) => {
  const { anchorPosition, name, open, option, value, onDecision, onClose } = tenkey;

  const classes = useStyles();
  const [inputNumber, setInputNumber] = useState(toValueString(value));
  const [errorValue, setErrorValue] = useState(false);

  // State dialog
  const [showDialog, setShowDialog] = React.useState({ isShow: false, message: '' });
  /* *************      hooks     ************* */
  // 表示のトリガーに利用した一覧選択ボタンを前面に表示する。

  // valueの初期化 open,closeのタイミングで初期化
  React.useEffect(() => {
    setInputNumber(toValueString(''));
  }, [open]);

  /**
   * handle change value and show error
   */
  React.useEffect(() => {
    const { minValue, maxValue } = option;
    const valueNumber = Number(inputNumber || '0');
    if (valueNumber < minValue || valueNumber > maxValue) {
      setErrorValue(true);
      return;
    }
    if (Number(valueNumber) >= Number(tempOperationConf?.limitLPower) && ['lPower1'].includes(tenkey.name)) {
      setErrorValue(true);
      return;
    }
    if (Number(valueNumber) >= Number(tempOperationConf?.limitRPower) && ['rPower1'].includes(tenkey.name)) {
      setErrorValue(true);
      return;
    }
    if (Number(valueNumber) <= Number(tempAreaConf?.lPower1) && ['limitLPower'].includes(tenkey.name)) {
      setErrorValue(true);
      return;
    }
    if (Number(valueNumber) <= Number(tempAreaConf?.rPower1) && ['limitRPower'].includes(tenkey.name)) {
      setErrorValue(true);
      return;
    }
    setErrorValue(false);
  }, [inputNumber]);

  const dispatch = useAppDispatch();

  /* *************      event     ************* */
  // NoX. 画面の外クリック
  // ダイアログ閉じる
  const handleClose = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    // 表示のトリガーに利用した項目の表示位置をもとに戻す。
    // 親から渡された処理があれば実行する。
    if (onClose) onClose(event, reason);
  };

  // No0. ページ読み込み
  // コンポーネントの表示制御だけなのでjsx内で対応する。 入力数値の初期表示、小数点の表示制御

  // No1. 閉じるボタンクリック
  const handleClickClose: React.MouseEventHandler<HTMLButtonElement> = () => {
    // 1-1. 画面を閉じ、モーダル表示を解除する。
    handleClose({}, 'backdropClick');
  };

  // No2～4,6～11,14,15. 0～9,小数点クリック
  const handleNumberClick =
    (decimalValue: string): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      const { integerSize, scaleSize } = option;

      // 空文字を含む数字以外の値や小数点だけの場合は0に変換。
      const numberValue = toNumberString(inputNumber);
      // 計算結果の想定値を初期設定
      let resultValue = numberValue + decimalValue;
      // 1-1. 入力値チェック
      const numberSplit = numberValue.split(DECIMAL_POINT);

      // 整数部桁数チェック 小数点または小数部の入力の場合はチェックしない。
      if (
        decimalValue !== DECIMAL_POINT &&
        !inputNumber.includes(DECIMAL_POINT) &&
        integerSize <= numberSplit[0].length
      )
        return;
      // 小数部桁数チェック
      if (numberSplit.length > 1 && scaleSize <= numberSplit[1].length) return;
      // 小数点二重チェック
      if (decimalValue === DECIMAL_POINT && numberValue.includes(DECIMAL_POINT)) return;
      // 1-2. 入力値反映
      if (numberValue === '0') {
        // 入力数値が未入力か0の場合入力数値を置き換える。
        if (decimalValue === DECIMAL_POINT) {
          // 小数点だけ入力する場合は0を先頭につける。
          resultValue = `0${decimalValue}`.slice(-2);
        } else {
          resultValue = decimalValue;
        }
      }
      setInputNumber(resultValue);
    };

  // No5 UPクリック
  const handleClickUp: React.MouseEventHandler<HTMLButtonElement> = () => {
    const { integerSize } = option;
    const resultValue = processClickUp(inputNumber, option);
    // 1-2. 入力チェック
    // 整数部桁数チェック
    if (integerSize < resultValue.split(DECIMAL_POINT)[0].length) return;
    // 1-3. 画面に反映
    setInputNumber(resultValue);
  };

  // No12 Downクリック
  const handleClickDown: React.MouseEventHandler<HTMLButtonElement> = () => {
    const resultValue = processClickDown(inputNumber, option);
    if (Number(resultValue) < 0) return;
    setInputNumber(String(resultValue));
  };

  // No13 削除クリック
  const handleClickDelete: React.MouseEventHandler<HTMLButtonElement> = () => {
    // 1-1. 入力チェック
    if (inputNumber) setInputNumber(inputNumber.slice(0, -1));
  };

  // No17 決定クリック
  const handleClickDecision: React.MouseEventHandler<HTMLButtonElement> = () => {
    const { minValue, maxValue, decimalLimit, decimalLimit2 } = option;
    // 入力値が数字ではない場合は空文字を設定して画面を閉じる。
    // 入力値が数字ではない場合は0とみなす。

    const valueNumber = Number(inputNumber || '0');
    const getEcimal = valueNumber - Math.floor(valueNumber);
    const decimalLimitValue = decimalLimit || DEFAULT_MAX_DECIMAL_VALUE;
    const decimalLimitValue2 = decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2;

    if (getEcimal !== 0 && getEcimal !== 0.5 && valueNumber >= decimalLimitValue && valueNumber <= decimalLimitValue2) {
      const errors = {
        errors: { key: [MessageHelper.messageFormat(MESSAGES.INF115)] },
      };
      dispatch(handleCallFrontError(errors));
      return;
    }

    // check is tempAreaConf >= tempOperationConf display snackbar error
    if (
      (Number(valueNumber) >= Number(tempOperationConf?.limitLPower) || Number(valueNumber) < minValue) &&
      ['lPower1'].includes(tenkey.name)
    ) {
      const limitPowerValue = processClickDown(Number(tempOperationConf?.limitLPower).toString(), option);
      if (inputNumber === '') {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF108, String(minValue), String(limitPowerValue))] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
      setShowDialog({
        isShow: true,
        message: MessageHelper.messageFormat(MESSAGES.INF242, String(minValue), String(limitPowerValue)),
      });
      return;
    }

    if (
      (Number(valueNumber) >= Number(tempOperationConf?.limitRPower) || Number(valueNumber) < minValue) &&
      ['rPower1'].includes(tenkey.name)
    ) {
      const limitPowerValue = processClickDown(Number(tempOperationConf?.limitRPower).toString(), option);
      if (inputNumber === '') {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF108, String(minValue), String(limitPowerValue))] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
      setShowDialog({
        isShow: true,
        message: MessageHelper.messageFormat(MESSAGES.INF242, String(minValue), String(limitPowerValue)),
      });
      return;
    }
    if (
      (Number(valueNumber) <= Number(tempAreaConf?.lPower1) || Number(valueNumber) > maxValue) &&
      ['limitLPower'].includes(tenkey.name)
    ) {
      const limitMinValue = processClickUp(String(tempAreaConf?.lPower1), option);
      if (inputNumber === '') {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF108, String(limitMinValue), String(maxValue))] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
      setShowDialog({
        isShow: true,
        message: MessageHelper.messageFormat(MESSAGES.INF242, limitMinValue, String(maxValue)),
      });
      return;
    }

    if (
      (Number(valueNumber) <= Number(tempAreaConf?.rPower1) || Number(valueNumber) > maxValue) &&
      ['limitRPower'].includes(tenkey.name)
    ) {
      const limitMinValue = processClickUp(String(tempAreaConf?.rPower1), option);
      if (inputNumber === '') {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF108, String(limitMinValue), String(maxValue))] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
      setShowDialog({
        isShow: true,
        message: MessageHelper.messageFormat(MESSAGES.INF242, limitMinValue, String(maxValue)),
      });
      return;
    }
    // 小数点を許容する数値の上限
    // decimalLimit で設定された値以上が入力された場合はエラーを返す
    if (decimalLimitValue2) {
      if (valueNumber >= decimalLimitValue2 && !Number.isInteger(valueNumber)) {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF113, String(decimalLimitValue2))] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
    }

    // 2. 画面を閉じる。
    // 2-2. 入力数値の値を呼び出し元の画面に返却する。そのために親コンポーネントの関数を実行する。
    const returnCheck = inputNumber.slice(-1);
    onDecision(returnCheck === DECIMAL_POINT ? inputNumber.slice(0, -1) : inputNumber, name);
    handleClose({}, 'backdropClick');
  };

  /**
   * Handle error close
   */
  const handleErrorClose = useCallback(() => {
    setShowDialog({ isShow: false, message: '' });
    const { mode, minValue, maxValue, scaleSize, decimalLimit, decimalLimit2 } = option;

    const valueNumber = Number(inputNumber || '0');
    if (valueNumber > maxValue) {
      setInputNumber(toValueString(maxValue.toString()));
    }
    if (valueNumber < minValue) {
      setInputNumber(toValueString(minValue.toString()));
    }
    if (Number(valueNumber) >= Number(tempOperationConf?.limitLPower) && ['lPower1'].includes(tenkey.name)) {
      const resultValue = calcUpDown(
        Number(tempOperationConf?.limitLPower).toString(),
        mode,
        -1,
        scaleSize,
        decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
        decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
      );
      setInputNumber(toValueString(resultValue.toString()));
    }
    if (Number(valueNumber) >= Number(tempOperationConf?.limitRPower) && ['rPower1'].includes(tenkey.name)) {
      const resultValue = calcUpDown(
        Number(tempOperationConf?.limitRPower).toString(),
        mode,
        -1,
        scaleSize,
        decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
        decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
      );
      setInputNumber(toValueString(resultValue.toString()));
    }
    if (Number(valueNumber) <= Number(tempAreaConf?.lPower1) && ['limitLPower'].includes(tenkey.name)) {
      const resultValue = calcUpDown(
        Number(tempAreaConf?.lPower1).toString(),
        mode,
        1,
        scaleSize,
        decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
        decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
      );
      setInputNumber(toValueString(resultValue.toString()));
    }
    if (Number(valueNumber) <= Number(tempAreaConf?.rPower1) && ['limitRPower'].includes(tenkey.name)) {
      const resultValue = calcUpDown(
        Number(tempAreaConf?.rPower1).toString(),
        mode,
        1,
        scaleSize,
        decimalLimit || DEFAULT_MAX_DECIMAL_VALUE,
        decimalLimit2 || DEFAULT_MAX_DECIMAL_VALUE2,
      );
      setInputNumber(toValueString(resultValue.toString()));
    }
  }, [inputNumber]);

  return (
    <>
      <Modal className={classes.root} open={open} onClose={handleClose} disableRestoreFocus>
        <Popover
          open={open}
          anchorPosition={anchorPosition}
          anchorReference="anchorPosition"
          classes={{
            root: classes.root,
            paper: classes.paper,
          }}
          onClose={handleClose}
          elevation={0}>
          <Box className={classes.baseArea}>
            <CloseButton onClick={handleClickClose}>Ｘ 閉じる</CloseButton>
            <Box className={classes.tenkey}>
              <Box
                className={`${classes.inputNumber} ${
                  errorValue ? classes.inputNumberError : classes.inputNumberNormal
                }`}>
                {inputNumber}
              </Box>
              <Box className={classes.inputArea}>
                <Box className={classes.inputNumberArea}>
                  <Box>
                    <NumberButton className={classes.numberButton} value="7" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="8" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="9" onNumberClick={handleNumberClick} />
                  </Box>
                  <Box>
                    <NumberButton className={classes.numberButton} value="4" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="5" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="6" onNumberClick={handleNumberClick} />
                  </Box>
                  <Box>
                    <NumberButton className={classes.numberButton} value="1" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="2" onNumberClick={handleNumberClick} />
                    <NumberButton className={classes.numberButton} value="3" onNumberClick={handleNumberClick} />
                  </Box>
                  <Box>
                    <DeleteButton className={classes.deleteButton} onClick={handleClickDelete} />
                    <NumberButton className={classes.numberButton} value="0" onNumberClick={handleNumberClick} />
                    {/* Event No0.ページ読み込み 小数点の表示を制御 */}
                    {option.mode === COMMON.TENKEY_MODE.DECIMAL && (
                      <NumberButton
                        className={classes.numberButton}
                        value={DECIMAL_POINT}
                        onNumberClick={handleNumberClick}
                      />
                    )}
                  </Box>
                </Box>
                <Box className={classes.plusMinusArea}>
                  <PlusMinusButton isPlus className={classes.plusMinusButton} onClick={handleClickUp} />
                  <PlusMinusButton isPlus={false} className={classes.plusMinusButton} onClick={handleClickDown} />
                </Box>
              </Box>
              <NormalButton className={classes.decisionButton} onClick={handleClickDecision}>
                決 定
              </NormalButton>
            </Box>
          </Box>
        </Popover>
      </Modal>
      <InfoDialog open={showDialog.isShow} msg={showDialog.message} closeFunc={handleErrorClose} />
    </>
  );
};

/* ********** Initial value ********** */
CloneNewTenkey.defaultProps = INITIAL_TEN_KEY;
export default CloneNewTenkey;
