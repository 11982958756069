import { Box, makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import React, { useCallback, useMemo, useState } from 'react';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import prizeMachineIcon from 'assets/images/prizeMachineIcon.png';
import prizeMachineJackIcon from 'assets/images/prizeMachineIconJack.png';
import pointer1 from 'assets/images/pointer1.svg';
import pointer2 from 'assets/images/pointer2.svg';
import pointer3 from 'assets/images/pointer3.svg';
import pointer4 from 'assets/images/pointer4.svg';
import pointer2Jack from 'assets/images/pointer2Jack.svg';
import pointer4Jack from 'assets/images/pointer4Jack.svg';
import { ledSelectAreaType } from 'types/machineConf/ledSelectAreaType';

import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { ledSelectButtonType } from 'types/machineConf/ledSelectButtontype';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { SelectListType } from 'types/support/supportType';
import MESSAGES from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import ColorEffectSelectArea from './ColorEffectSelectArea';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    padding: '1px',
  },
  settingArea: {
    '& > *': {
      marginBottom: '8px',
    },
    '& > *:last-child': {
      marginBottom: '0px',
    },
    '&.grayOut': {
      position: 'relative',
    },
  },
  imgArea: {
    position: 'relative',
    textAlign: 'center',
    width: '205px',
    '& .prizeMachine': {
      margin: '0 3px',
      display: 'inline',
    },
    '& .leftPointer': {
      position: 'absolute',
      left: '0',
    },
    '& .rightPointer': {
      position: 'absolute',
      right: '0',
      transform: 'scaleX(-1)',
    },
    '& .pointer1': {
      top: '68px',
    },
    '& .pointer2': {
      top: '188px',
    },
    '& .pointer2Jack': {
      top: '178px',
    },
    '& .pointer3': {
      top: '232px',
    },
    '& .pointer4': {
      top: '274px',
    },
  },
  resetButton: {
    width: '116px',
    height: '48px',
    fontSize: '21px',
    marginBottom: '88px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  LEFT: {
    COLOR4: 'lLedColor4',
    EFFECT4: 'lLedEffect4',
    COLOR3: 'lLedColor3',
    EFFECT3: 'lLedEffect3',
    COLOR2: 'lLedColor2',
    EFFECT2: 'lLedEffect2',
    COLOR1: 'lLedColor1',
    EFFECT1: 'lLedEffect1',
  },
  RIGHT: {
    COLOR4: 'rLedColor4',
    EFFECT4: 'rLedEffect4',
    COLOR3: 'rLedColor3',
    EFFECT3: 'rLedEffect3',
    COLOR2: 'rLedColor2',
    EFFECT2: 'rLedEffect2',
    COLOR1: 'rLedColor1',
    EFFECT1: 'rLedEffect1',
  },
};

/* ******** Main Component ******** */
/**
 *
 * @Params leftRight, ledSelectAreaConf, onChange
 * @returns {React.FC} - LED 設定エリア
 *
 */
const LedSelectArea: React.FC<ledSelectAreaType> = React.memo((props) => {
  const { leftRight, ledSelectAreaConf, onChange, disabled = false, settingTab = COMMON.SETTING_TAB.CLENA3 } = props;
  const classes = useStyles();
  const isJack = settingTab === COMMON.SETTING_TAB.JACK;
  const areaColor = isJack ? 'grayOut' : '';

  // Local State
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);

  // DataSource
  const ledColorDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_COLOR.CATEGORY_CD), []);
  const ledEffectDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_EFFECT.CATEGORY_CD), []);

  /* ******** Events ******** */
  const changeEffect = (colorValue: string, effectValue: string): string => {
    // 色を「ベースカラー」に変更した場合、ペアの効果を「無し（点灯）」に変更
    if (colorValue === CLSFCN.LED_COLOR.BASE_COLOR) return CLSFCN.LED_EFFECT.NONE_LIGHTING;
    // 色を「ベースカラー」以外に変更した場合、ペアの効果はそのまま
    return effectValue;
  };

  // リセット
  const handleClickReset = (): void => {
    const newConf = {
      ...ledSelectAreaConf,
      lLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor4: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect4: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor2: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect2: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      rLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      rLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    };
    onChange(newConf);
  };

  // on click pulldown items
  const handleClickSelectList = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>, id: string, oldValue: string) => {
      let dataSource;
      let title;
      let value;
      let placement: 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end';
      let effectInputName: string | null = null;

      if (
        [
          INPUT_NAMES.LEFT.COLOR1,
          INPUT_NAMES.LEFT.COLOR2,
          INPUT_NAMES.LEFT.COLOR3,
          INPUT_NAMES.LEFT.COLOR4,
          INPUT_NAMES.RIGHT.COLOR1,
          INPUT_NAMES.RIGHT.COLOR2,
          INPUT_NAMES.RIGHT.COLOR3,
          INPUT_NAMES.RIGHT.COLOR4,
        ].includes(id)
      ) {
        dataSource = ledColorDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '色');
      } else {
        dataSource = ledEffectDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '効果');
      }
      switch (id) {
        case INPUT_NAMES.LEFT.COLOR1:
        case INPUT_NAMES.LEFT.COLOR2:
        case INPUT_NAMES.LEFT.EFFECT1:
        case INPUT_NAMES.LEFT.EFFECT2:
        case INPUT_NAMES.RIGHT.COLOR1:
        case INPUT_NAMES.RIGHT.COLOR2:
        case INPUT_NAMES.RIGHT.EFFECT1:
        case INPUT_NAMES.RIGHT.EFFECT2:
          placement = 'right-end';
          break;
        default:
          placement = 'right';
      }

      if (id === INPUT_NAMES.LEFT.COLOR1) {
        value = ledSelectAreaConf.lLedColor1;
        effectInputName = INPUT_NAMES.LEFT.EFFECT1;
      } else if (id === INPUT_NAMES.LEFT.COLOR2) {
        value = ledSelectAreaConf.lLedColor2;
        effectInputName = INPUT_NAMES.LEFT.EFFECT2;
      } else if (id === INPUT_NAMES.LEFT.COLOR3) {
        value = ledSelectAreaConf.lLedColor3;
        effectInputName = INPUT_NAMES.LEFT.EFFECT3;
      } else if (id === INPUT_NAMES.LEFT.COLOR4) {
        value = ledSelectAreaConf.lLedColor4;
        effectInputName = INPUT_NAMES.LEFT.EFFECT4;
      } else if (id === INPUT_NAMES.LEFT.EFFECT1) {
        value = ledSelectAreaConf.lLedEffect1;
      } else if (id === INPUT_NAMES.LEFT.EFFECT2) {
        value = ledSelectAreaConf.lLedEffect2;
      } else if (id === INPUT_NAMES.LEFT.EFFECT3) {
        value = ledSelectAreaConf.lLedEffect3;
      } else if (id === INPUT_NAMES.LEFT.EFFECT4) {
        value = ledSelectAreaConf.lLedEffect4;
      } else if (id === INPUT_NAMES.RIGHT.COLOR1) {
        value = ledSelectAreaConf.rLedColor1;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT1;
      } else if (id === INPUT_NAMES.RIGHT.COLOR2) {
        value = ledSelectAreaConf.rLedColor2;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT2;
      } else if (id === INPUT_NAMES.RIGHT.COLOR3) {
        value = ledSelectAreaConf.rLedColor3;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT3;
      } else if (id === INPUT_NAMES.RIGHT.COLOR4) {
        value = ledSelectAreaConf.rLedColor4;
        effectInputName = INPUT_NAMES.RIGHT.EFFECT4;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT1) {
        value = ledSelectAreaConf.rLedEffect1;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT2) {
        value = ledSelectAreaConf.rLedEffect2;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT3) {
        value = ledSelectAreaConf.rLedEffect3;
      } else if (id === INPUT_NAMES.RIGHT.EFFECT4) {
        value = ledSelectAreaConf.rLedEffect4;
      } else {
        return;
      }

      setSelectList({
        ...INITIAL_SELECT_LIST.selectList,
        open: true,
        onClick: (data: { value: string }, name: string) => {
          // 選択値が変化ない場合
          if (data.value === oldValue) return;

          if (effectInputName) {
            let ledEffect = '0';
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT1) ledEffect = ledSelectAreaConf.lLedEffect1;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT2) ledEffect = ledSelectAreaConf.lLedEffect2;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT3) ledEffect = ledSelectAreaConf.lLedEffect3;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT4) ledEffect = ledSelectAreaConf.lLedEffect4;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT1) ledEffect = ledSelectAreaConf.rLedEffect1;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT2) ledEffect = ledSelectAreaConf.rLedEffect2;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT3) ledEffect = ledSelectAreaConf.rLedEffect3;
            if (effectInputName === INPUT_NAMES.RIGHT.EFFECT4) ledEffect = ledSelectAreaConf.rLedEffect4;
            // 色を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
              [effectInputName]: changeEffect(data.value, ledEffect),
            });
          } else {
            // 効果を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
            });
          }
        },
        onClose: () => setSelectList(INITIAL_SELECT_LIST.selectList),
        anchorEL: ev.currentTarget,
        dataSource,
        name: id,
        placement,
        title,
        value,
      });
    },
    [ledSelectAreaConf],
  );
  /* ************ Params ************ */
  const getColorParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(CLSFCN.LED_COLOR.CATEGORY_CD, value),
  });

  const getEffectParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(CLSFCN.LED_EFFECT.CATEGORY_CD, value),
  });

  return (
    <Box className={classes.content}>
      <Box className={classes.settingArea}>
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR4, ledSelectAreaConf.lLedColor4)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT4, ledSelectAreaConf.lLedEffect4)}
          effectDisabled={ledSelectAreaConf.lLedColor4 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
          isAreaGrayOut={isJack}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR3, ledSelectAreaConf.lLedColor3)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT3, ledSelectAreaConf.lLedEffect3)}
          effectDisabled={ledSelectAreaConf.lLedColor3 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={disabled}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR2, ledSelectAreaConf.lLedColor2)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT2, ledSelectAreaConf.lLedEffect2)}
          effectDisabled={ledSelectAreaConf.lLedColor2 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
          isAreaGrayOut={isJack}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.LEFT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.LEFT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR1, ledSelectAreaConf.lLedColor1)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT1, ledSelectAreaConf.lLedEffect1)}
          effectDisabled={ledSelectAreaConf.lLedColor1 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={disabled}
        />
      </Box>
      {!isJack ? (
        <Box className={classes.imgArea}>
          <DeleteButton disabled={disabled} className={classes.resetButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={prizeMachineIcon} alt="prizeMachineIcon" className="prizeMachine" />
          {/* 左側 */}
          <img src={pointer1} alt="pointer1" className="leftPointer pointer1" />
          <img src={pointer2} alt="pointer2" className="leftPointer pointer2" />
          <img src={pointer3} alt="pointer3" className="leftPointer pointer3" />
          <img src={pointer4} alt="pointer4" className="leftPointer pointer4" />
          {/* 右側 */}
          <img src={pointer1} alt="pointer1" className="rightPointer pointer1" />
          <img src={pointer2} alt="pointer2" className="rightPointer pointer2" />
          <img src={pointer3} alt="pointer3" className="rightPointer pointer3" />
          <img src={pointer4} alt="pointer4" className="rightPointer pointer4" />
        </Box>
      ) : (
        <Box className={classes.imgArea}>
          <DeleteButton disabled={disabled} className={classes.resetButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={prizeMachineJackIcon} alt="prizeMachineJackIcon" className="prizeMachine" />
          {/* 左側 */}
          <img src={pointer2Jack} alt="pointer2Jack" className="leftPointer pointer2Jack" />
          <img src={pointer4Jack} alt="pointer4Jack" className="leftPointer pointer4" />
        </Box>
      )}
      <Box className={`${classes.settingArea}  ${areaColor}`}>
        {/* Jackの場合はグレーアウトする */}
        {isJack && <Box className={classes.disableInputArea} />}
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.RIGHT.COLOR4, ledSelectAreaConf.rLedColor4)}
          effectSelect={getEffectParam(INPUT_NAMES.RIGHT.EFFECT4, ledSelectAreaConf.rLedEffect4)}
          effectDisabled={ledSelectAreaConf.rLedColor4 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.RIGHT.COLOR3, ledSelectAreaConf.rLedColor3)}
          effectSelect={getEffectParam(INPUT_NAMES.RIGHT.EFFECT3, ledSelectAreaConf.rLedEffect3)}
          effectDisabled={ledSelectAreaConf.rLedColor3 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.RIGHT.COLOR2, ledSelectAreaConf.rLedColor2)}
          effectSelect={getEffectParam(INPUT_NAMES.RIGHT.EFFECT2, ledSelectAreaConf.rLedEffect2)}
          effectDisabled={ledSelectAreaConf.rLedColor2 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
        />
        <ColorEffectSelectArea
          leftRight={leftRight === COMMON.LEFT_RIGHT.LEFT_AND_RIGHT ? COMMON.LEFT_RIGHT.RIGHT : leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.RIGHT.COLOR1, ledSelectAreaConf.rLedColor1)}
          effectSelect={getEffectParam(INPUT_NAMES.RIGHT.EFFECT1, ledSelectAreaConf.rLedEffect1)}
          effectDisabled={ledSelectAreaConf.rLedColor1 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={isJack ? true : disabled}
        />
      </Box>
      <SelectList selectList={selectList} />
    </Box>
  );
});

export default LedSelectArea;
