import COMMON from 'constants/common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectListType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import UrlHelper from 'utils/url.helper';
import { setLedServiceOtherConf } from 'redux/slices/machineConfSlice';
import ConfSelectArea from 'components/machineConf/organisms/selectArea/ConfSelectArea';
import { LedServiceOtherConfType } from 'types/machineConf/machineConfType';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'その他設定',
  screenId: 'SCR361',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    marginTop: '48px',
    '& .row1, .row2': {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '28px',
    },
    '& .row1::after, .row2::after': {
      content: '""',
      display: 'block',
      width: '330px',
    },
  },
  bottomContent: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  selectArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '330px',
    height: '138px',
    textAlign: 'center',
    backgroundColor: '#FFFBDF',
    border: '1px solid #707070',
    borderRadius: '8px',
    padding: '16px 4px',
    '& .title': {
      fontSize: '21px',
    },
    '& .content': {
      marginTop: 'auto',
    },
    '& .settingBtn': {
      width: '176px',
      height: '65px',
      fontSize: '19px',
    },
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  settingBtn: {
    width: '176px',
    height: '65px',
    fontSize: '19px',
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  STATION_STOP: 'stationStop',
  PAYOUT_SENSOR: 'payoutSensor',
  ARM_SIZE_SENSOR: 'armSizeSensor',
  CATCHER_ROTATION_SENSOR: 'catcherRotationSensor',
};

/* ******** Main Component ******** */
/**
 *
 * @author shoya.yoshikawa
 * @returns {React.FC} - その他設定
 *
 */
const OtherConf: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineConf.ledServiceOtherConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);

  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tempLedServiceOtherConf, setTempLedServiceOtherConf] = useState<LedServiceOtherConfType | undefined>(
    ledServiceOtherConf,
  );

  const stationStopDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.STATION_STOP.CATEGORY_CD), []);
  const onOffDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.ON_OFF.CATEGORY_CD), []);

  /* ************ Event ************ */

  useEffect(() => {
    if (!ledServiceOtherConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 選択リスト 各項目の設定値に反映
  const handleClickSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setSelectList(INITIAL_SELECT_LIST.selectList);
      setTempLedServiceOtherConf((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            [name]: data.value,
          };
        }
        return prevState;
      });
    },
    [tempLedServiceOtherConf],
  );

  // ステーション停止 押下
  const handleClickStationStop = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: stationStopDataSource,
        name: INPUT_NAMES.STATION_STOP,
        placement: 'right',
        title: '選択してください',
        value: tempLedServiceOtherConf?.stationStop,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.stationStop, handleClickSelectList],
  );

  // ペイアウトセンサ 押下
  const handleClickPayoutSensor = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: onOffDataSource,
        name: INPUT_NAMES.PAYOUT_SENSOR,
        placement: 'right',
        title: '選択してください',
        value: tempLedServiceOtherConf?.payoutSensor,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.payoutSensor, handleClickSelectList],
  );

  // アームサイズセンサ 押下
  const handleClickArmSizeSensor = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: onOffDataSource,
        name: INPUT_NAMES.ARM_SIZE_SENSOR,
        placement: 'right',
        title: '選択してください',
        value: tempLedServiceOtherConf?.armSizeSensor,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.armSizeSensor, handleClickSelectList],
  );

  // キャッチャー回転センサ 押下
  const handleClickCatcherRotationSensor = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: onOffDataSource,
        name: INPUT_NAMES.CATCHER_ROTATION_SENSOR,
        placement: 'right',
        title: '選択してください',
        value: tempLedServiceOtherConf?.catcherRotationSensor,
        onClick: handleClickSelectList,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempLedServiceOtherConf?.catcherRotationSensor, handleClickSelectList],
  );

  // 戻るボタン
  const handleclickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
        selectConfId: 'none',
      }),
    );
  };

  // 確定ボタン
  const handleClickDone = async () => {
    // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post<LedServiceOtherConfType>(REST_API.MACHINE_CONF.OTHER_CONF_VALIDATION, {
        stationStop: tempLedServiceOtherConf?.stationStop,
        payoutSensor: tempLedServiceOtherConf?.payoutSensor,
        armSizeSensor: tempLedServiceOtherConf?.armSizeSensor,
        catcherRotationSensor: tempLedServiceOtherConf?.catcherRotationSensor,
      })
      .then(() => {
        // グローバルステートに保存
        dispatch(setLedServiceOtherConf(tempLedServiceOtherConf));
        // 画面遷移
        history.push(
          UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
            selectConfId: 'none',
          }),
        );
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleclickReturn}>設定TOP</ReturnButton>
              <Box className="discription">
                「運営停止」「各種センサ」など運営に関わる項目を設定できます。
                <br />
                設定内容を選択し、<Typography className={classes.doneImage}>確 定</Typography>してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="row1">
                <ConfSelectArea
                  title="ステーション停止"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickStationStop}
                  isSelected={selectList.name === INPUT_NAMES.STATION_STOP}
                  value={
                    tempLedServiceOtherConf?.stationStop &&
                    getClassificationName(CLSFCN.STATION_STOP.CATEGORY_CD, tempLedServiceOtherConf?.stationStop)
                  }
                />
                <ConfSelectArea
                  title="ペイアウトセンサ"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickPayoutSensor}
                  isSelected={selectList.name === INPUT_NAMES.PAYOUT_SENSOR}
                  value={
                    tempLedServiceOtherConf?.payoutSensor &&
                    getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, tempLedServiceOtherConf?.payoutSensor)
                  }
                />
              </Box>
              <Box className="row2">
                <ConfSelectArea
                  title="アームサイズセンサ"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickArmSizeSensor}
                  isSelected={selectList.name === INPUT_NAMES.ARM_SIZE_SENSOR}
                  value={
                    tempLedServiceOtherConf?.armSizeSensor &&
                    getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, tempLedServiceOtherConf?.armSizeSensor)
                  }
                />
                <ConfSelectArea
                  title="キャッチャー回転センサ"
                  leftRight={leftRight}
                  className={classes.settingBtn}
                  onClick={handleClickCatcherRotationSensor}
                  isSelected={selectList.name === INPUT_NAMES.CATCHER_ROTATION_SENSOR}
                  value={
                    tempLedServiceOtherConf?.catcherRotationSensor &&
                    getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, tempLedServiceOtherConf?.catcherRotationSensor)
                  }
                />
              </Box>
            </Box>
            <Box className={classes.bottomContent}>
              <DoneButton onClick={handleClickDone}>確 定</DoneButton>
            </Box>
          </Box>
          <SelectList selectList={selectList} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default OtherConf;
