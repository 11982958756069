import React, { memo } from 'react';
import { NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CLSFCN from 'constants/classification';

/**
 * CorSelectBox Component
 * セレクトボックス
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  selectBox: {
    color: theme.palette.common.white,
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    height: '36px',
    float: 'right',
    fontSize: '16px',
    width: '107px',
    '& .MuiNativeSelect-icon': {
      color: theme.palette.common.white,
    },
    '& option': {
      color: theme.palette.common.black,
    },
  },

  new: { backgroundColor: '#FF3E3E' },
  pend: { backgroundColor: '#707070' },
  done: { backgroundColor: '#3086FF' },
}));

/* ************ Type ************ */
type dataSourceProps = {
  name: string;
  value: string;
};

type Props = {
  disabled?: boolean;
  dataSource: Array<dataSourceProps>;
  name: string;
  fullWidth?: boolean;
  size?: string;
  value?: number | null;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
};

const CorSelectBox: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { dataSource, disabled, fullWidth, name, value, onChange } = props;

  let valueClass = '';
  switch (String(value)) {
    case CLSFCN.CORRESPOND_STATUS.NEW:
      valueClass = classes.new;
      break;
    case CLSFCN.CORRESPOND_STATUS.PEND:
      valueClass = classes.pend;
      break;
    case CLSFCN.CORRESPOND_STATUS.DONE:
      valueClass = classes.done;
      break;
    case CLSFCN.CORRESPOND_STATUS.NATURAL_SOLVED:
      valueClass = classes.done;
      break;
    default:
      valueClass = classes.new;
  }

  return (
    <NativeSelect
      className={`${classes.selectBox} ${valueClass}`}
      disabled={disabled}
      fullWidth={fullWidth}
      name={name}
      value={value}
      variant="outlined"
      onChange={onChange}>
      {dataSource.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
      ;
    </NativeSelect>
  );
};

CorSelectBox.defaultProps = {
  disabled: false,
  fullWidth: false,
  value: null,
};

export default memo(CorSelectBox);
