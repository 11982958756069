import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { memo } from 'react';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import { StationType } from 'types/maintenance/StationType';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '381px',
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '16px',
      minHeight: '74px',
    },
    '& th': {
      textAlign: 'center',
      height: '74px',
      padding: 0,
    },
    '& tbody tr': {
      height: '58px',
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        overflow: 'hidden',
        fontSize: '23px',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdMachineName: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& .tdEditButton': {
      height: '44px',
      width: '120px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdEquipmentNo: {
    display: 'flex',
    alignItems: 'center',
  },
}));

/* ************ Type ************ */
type Props = {
  id: string;
  rows: Array<StationType>;
  handleSelectedItemChange: (row: StationType) => void;
};

/* ************ Component ************ */
const StationListTable: React.FC<Props> = ({ rows, handleSelectedItemChange, id }) => {
  const classes = useStyles();

  const mappingStationName = (row: StationType): string => {
    if (row.machineType === COMMON.MACHINE_TYPE.JACK) return 'J: ';
    return row.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'L: ' : 'R: ';
  };

  return (
    <>
      <TableContainer id={id} className={classes.default}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <col key="mainColumn" style={{ width: '100%' }} />
          <TableBody>
            {rows.map((row) => {
              const key = `station_list_table_key_${row.stationId}`;
              const stationName = mappingStationName(row) + row.stationName;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={classes.tdStationName}>
                      <div className={classes.tdEquipmentNo}>
                        <ListCheckBox
                          checked={row.checked}
                          name="selectStation"
                          onChange={() => handleSelectedItemChange(row)}
                        />
                        <div className="cellStyle">
                          <TooltipComponent tooltipText={row.giftName} displayWord={stationName} />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(StationListTable);
