import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Edit Button Component
 * 編集ボタン
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

type Props = {
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
  id?: string;
};

const useStyles = makeStyles((theme) => ({
  opCrBtn: {
    background: '#A2FFBB',
    boxShadow: '3px 3px 3px 0px rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    fontSize: '24px',
    height: '44px',
    lineHeight: '16px',
    width: '120px',
    '&$disabled': {
      color: theme.palette.common.white,
      background: '#3086FF',
    },
    '&:hover': {
      background: '#A2FFBB',
      boxShadow: '3px 3px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  disabled: {},
}));

const EditButton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { children, disabled = false, fullWidth = false, onClick, id } = props;

  return (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      className={`${classes.opCrBtn} ${disabled ? classes.disabled : ''}`}
      disabled={disabled}
      onClick={onClick}
      id={id}>
      {children}
    </Button>
  );
};

export default memo(EditButton);
