import { REST_API } from 'constants/apiUrls';
import COMMON from 'constants/common';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { AreaConfType, ConfType, MachineConfType } from 'types/machineConf/machineConfType';
import HttpConnection from 'utils/httpConnection';

interface TypeShowAreaConf {
  showTable: boolean | null;
  showLine: {
    lineFlg1: boolean;
    AreaSpecificSettingsLine2: boolean;
    AreaSpecificSettingsLine3: boolean;
    AreaSpecificSettingsLine4: boolean;
    AreaSpecificSettingsLine5: boolean;
    AreaSpecificSettingsLine6: boolean;
  };
  areaConf?: AreaConfType;
  conf?: ConfType;
}

function useShowAreaConfJack({ selectConfId }: { selectConfId: string }): TypeShowAreaConf {
  const [areaConf, setAreaConf] = useState<AreaConfType>();
  const [conf, setConf] = useState<ConfType>();

  const dispatch = useAppDispatch();
  const [dataAreaConf, setDataAreaConf] = useState<TypeShowAreaConf>({
    showTable: null,
    showLine: {
      lineFlg1: false,
      AreaSpecificSettingsLine2: false,
      AreaSpecificSettingsLine3: false,
      AreaSpecificSettingsLine4: false,
      AreaSpecificSettingsLine5: false,
      AreaSpecificSettingsLine6: false,
    },
  });

  // 変数定義
  let lineFlg1 = false;
  let lineFlg2 = false;
  let lineFlg3 = false;
  let lineFlg4 = false;
  let lineFlg5 = false;
  let lineFlg6 = false;

  let areaLine2 = false;
  let areaLine3 = false;
  let areaLine4 = false;
  let areaLine5 = false;
  let areaLine6 = false;

  const getSettingDetail = async (confId: number) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<MachineConfType>(REST_API.MACHINE_CONF.GET_SETTING_DETAIL, { confId });
    if (!data) return;

    if (data.areaConf && data.operationConf && data.conf) {
      setAreaConf(data.areaConf);
      setConf(data.conf);
      let isShowTable = null;
      const flagOperationConf = data.operationConf?.operationConf === COMMON.OPERATION_CONF.NO_CONF;
      const line1 = String(data.areaConf?.line1);
      const line2 = String(data.areaConf?.line2);
      const line3 = String(data.areaConf?.line3);
      const line4 = String(data.areaConf?.line4);
      const line5 = String(data.areaConf?.line5);
      const line6 = String(data.areaConf?.line6);

      // 各エリア色の設定有無を確認
      if (
        line1.includes('0') ||
        line2.includes('0') ||
        line3.includes('0') ||
        line4.includes('0') ||
        line5.includes('0') ||
        line6.includes('0')
      ) {
        lineFlg1 = true;
      }
      if (
        line1.includes('1') ||
        line2.includes('1') ||
        line3.includes('1') ||
        line4.includes('1') ||
        line5.includes('1') ||
        line6.includes('1')
      ) {
        lineFlg2 = true;
      }
      if (
        line1.includes('2') ||
        line2.includes('2') ||
        line3.includes('2') ||
        line4.includes('2') ||
        line5.includes('2') ||
        line6.includes('2')
      ) {
        lineFlg3 = true;
      }
      if (
        line1.includes('3') ||
        line2.includes('3') ||
        line3.includes('3') ||
        line4.includes('3') ||
        line5.includes('3') ||
        line6.includes('3')
      ) {
        lineFlg4 = true;
      }
      if (
        line1.includes('4') ||
        line2.includes('4') ||
        line3.includes('4') ||
        line4.includes('4') ||
        line5.includes('4') ||
        line6.includes('4')
      ) {
        lineFlg5 = true;
      }
      if (
        line1.includes('5') ||
        line2.includes('5') ||
        line3.includes('5') ||
        line4.includes('5') ||
        line5.includes('5') ||
        line6.includes('5')
      ) {
        lineFlg6 = true;
      }

      areaLine2 = lineFlg2 && flagOperationConf;
      areaLine3 = lineFlg3 && flagOperationConf;
      areaLine4 = lineFlg4 && flagOperationConf;
      areaLine5 = lineFlg5 && flagOperationConf;
      areaLine6 = lineFlg6 && flagOperationConf;

      if (!areaLine2 && !areaLine3 && !areaLine4 && !areaLine5 && !areaLine6) {
        isShowTable = false;
      } else {
        isShowTable = true;
      }

      setDataAreaConf({
        showTable: isShowTable,
        showLine: {
          lineFlg1,
          AreaSpecificSettingsLine2: areaLine2,
          AreaSpecificSettingsLine3: areaLine3,
          AreaSpecificSettingsLine4: areaLine4,
          AreaSpecificSettingsLine5: areaLine5,
          AreaSpecificSettingsLine6: areaLine6,
        },
      });
    }
  };

  useEffect(() => {
    const confId = Number(selectConfId);
    getSettingDetail(confId);
  }, [selectConfId]);

  return {
    showTable: dataAreaConf.showTable,
    showLine: {
      lineFlg1: dataAreaConf.showLine.lineFlg1,
      AreaSpecificSettingsLine2: dataAreaConf.showLine.AreaSpecificSettingsLine2,
      AreaSpecificSettingsLine3: dataAreaConf.showLine.AreaSpecificSettingsLine3,
      AreaSpecificSettingsLine4: dataAreaConf.showLine.AreaSpecificSettingsLine4,
      AreaSpecificSettingsLine5: dataAreaConf.showLine.AreaSpecificSettingsLine5,
      AreaSpecificSettingsLine6: dataAreaConf.showLine.AreaSpecificSettingsLine6,
    },
    areaConf,
    conf,
  };
}

export default useShowAreaConfJack;
