import React, { memo, MouseEventHandler } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import prevArrowIcon from 'assets/images/prevArrowIcon.svg';
import { MenuTabType } from 'types/data/dataType';
import { Box } from '@material-ui/core';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 24px',
    height: '64px',
    lineHeight: '64px',
    width: '211px',
    textAlign: 'center',
    fontSize: '28px',
    '& span': {
      width: '17px',
    },
  },
  notSelected: {
    backgroundColor: '#8C93B0',
    color: '#DBDBDB',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  next: {
    transform: 'rotateY(180deg)',
  },
  buttonContained: {
    height: '45px',
    minWidth: '8px',
    width: '17px',
    padding: '0px',
    boxShadow: 'none',
    background: `url(${prevArrowIcon})`,
    '&:active': {
      background: `url(${prevArrowIcon})`,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
    },
  },
  buttonDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0) !important',
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * MenuTab メニュータブ
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const MenuTab: React.FC<MenuTabType> = (props) => {
  const classes = useStyles();
  const { className, disabledNext, disabledPrevious, isSelected, onClick, onPrevious, onNext, children } = props;

  const styleClass = `${className || classes.root} ${isSelected ? classes.selected : classes.notSelected}`;
  const prevStyleClass = `${classes.buttonContained}`;
  const nextStyleClass = `${prevStyleClass} ${classes.next}`;

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (isSelected) {
      return;
    }
    if (onClick) onClick(event);
  };

  return (
    <Box className={styleClass} onClick={handleClick}>
      {/* 前へ */}
      {isSelected && !disabledPrevious ? (
        <NormalButton
          classes={{
            contained: prevStyleClass,
          }}
          onClick={onPrevious}
        />
      ) : (
        <span />
      )}

      {/* 指定HTML */}
      {children}

      {/* 次へ */}
      {isSelected && !disabledNext ? (
        <NormalButton
          classes={{
            contained: nextStyleClass,
            disabled: classes.buttonDisabled,
          }}
          onClick={onNext}
        />
      ) : (
        <span />
      )}
    </Box>
  );
};

// propsの初期値。未指定時に使用する。
MenuTab.defaultProps = {
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(MenuTab);
