import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* ******* Event ****** */
const activeCrateGroupSlice = createSlice({
  name: 'activeCrateGroupSlice',
  initialState: {
    isCrateGroup: false,
  },
  reducers: {
    actIsCrateGroup(state, action: PayloadAction<boolean>) {
      state.isCrateGroup = action.payload;
    },
  },
});

export const { actIsCrateGroup } = activeCrateGroupSlice.actions;
export default activeCrateGroupSlice.reducer;
