import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    height: 96,
    border: '1px solid #707070',
    borderRadius: 4,
    marginTop: 4,
  },
  header: {
    padding: '5px 10px',
    width: '100%',
    height: 33,
    background: '#535B80',
    fontSize: 19,
    color: '#fff',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
  },
  informationText: {
    fontSize: 20,
    marginBottom: 5,
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5,
  },
  informationContainer: {
    margin: '14px 25px',
    height: 34,
    overflowY: 'auto',
  },
}));

/* ************ Component ************ */
const OperationExample: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  /* ************ use effect ************ */
  useEffect(() => {
    //
  }, []);

  const onClick = () => {
    history.push(redirectLinks.OPERATION_EXAMPLE_LIST);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>運営事例紹介</div>
      <div className={classes.informationContainer} onClick={onClick}>
        <div className={classes.informationText}>クレナ運営事例一覧ページを開く</div>
      </div>
    </div>
  );
};

export default OperationExample;
