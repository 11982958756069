import React, { memo } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import arrowIcon from 'assets/images/downArrowIcon_3.svg';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import COMMON from 'constants/common';
import { convertLineValue } from 'utils/machineConf.helper';

export type ShiftPanelReviewType = {
  shiftConf: string;
  line1: string;
  line2: string;
  line3: string;
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  table: {
    height: '100%',
  },
  tableCell: {
    position: 'relative',
    backgroundColor: '#FFF',
    border: '2px solid #707070',
    padding: '0px',
    '& .arrowBox': {
      width: '100%',
      height: '100%',
    },
    '& img': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      padding: '4px',
      pointerEvents: 'none',
    },
  },
  backArrow: {
    transform: 'rotate(180deg)',
  },
  backRightArrow: {
    transform: 'rotate(225deg)',
  },
  rightArrow: {
    transform: 'rotate(270deg)',
  },
  frontRightArrow: {
    transform: 'rotate(315deg)',
  },
  frontLeftArrow: {
    transform: 'rotate(45deg)',
  },
  leftArrow: {
    transform: 'rotate(90deg)',
  },
  backLeftArrow: {
    transform: 'rotate(135deg)',
  },
}));

const generateArray = (str: string) => {
  const list: string[] = [];
  for (let i = 0; i < str.length; i += 1) {
    const result = str.charAt(i);
    list.push(result);
  }
  return list;
};

const transpose2DArray = (arg: string[][]) => arg[0].map((_, c) => arg.map((r) => r[c]));

const generateShifts = (line1: string, line2: string, line3: string) => {
  const linesArray = [generateArray(line1), generateArray(line2), generateArray(line3)];
  return transpose2DArray(linesArray);
};

const ShiftPanelReview: React.FC<ShiftPanelReviewType> = memo((props) => {
  const classes = useStyles();
  const { shiftConf, line1, line2, line3 } = props;

  const newLines = convertLineValue(shiftConf, line1, line2, line3);
  const newLine1 = newLines[0];
  const newLine2 = newLines[1];
  const newLine3 = newLines[2];

  const renderArrowImage = (shiftArrow: string) => {
    switch (shiftArrow) {
      case COMMON.SHIFT_ARROW.BACK:
        return <img src={arrowIcon} alt="back" className={classes.backArrow} />;
      case COMMON.SHIFT_ARROW.BACK_RIGHT:
        return <img src={arrowIcon} alt="backRight" className={classes.backRightArrow} />;
      case COMMON.SHIFT_ARROW.RIGHT:
        return <img src={arrowIcon} alt="right" className={classes.rightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT_RIGHT:
        return <img src={arrowIcon} alt="frontRight" className={classes.frontRightArrow} />;
      case COMMON.SHIFT_ARROW.FRONT:
        return <img src={arrowIcon} alt="front" />;
      case COMMON.SHIFT_ARROW.FRONT_LEFT:
        return <img src={arrowIcon} alt="frontLeft" className={classes.frontLeftArrow} />;
      case COMMON.SHIFT_ARROW.LEFT:
        return <img src={arrowIcon} alt="left" className={classes.leftArrow} />;
      case COMMON.SHIFT_ARROW.BACK_LEFT:
        return <img src={arrowIcon} alt="backLeft" className={classes.backLeftArrow} />;
      case COMMON.SHIFT_ARROW.PIT:
        return <img src={pitMarkIcon} alt="pit" />;
      default:
        return <></>;
    }
  };

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.container}>
        <Table className={classes.table}>
          <TableBody>
            {generateShifts(newLine1, newLine2, newLine3).map((row, i) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={i}>
                {row.map((value) => (
                  <TableCell className={classes.tableCell}>{renderArrowImage(value)}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default ShiftPanelReview;
