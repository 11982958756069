import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

/**
 * TextButton Component
 * テキストボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

/* ************ Type ************ */
// Propsの型定義
type Props = {
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};

/* ******** Main Component ******** */
/**
 * TextButton Component
 * テキストだけ(枠なし)のボタンを表示する。
 *
 * @author atsushi.teruya
 * @returns {React.FC} - テキスト表示だけのボタン
 *
 */
const TextButton: React.FC<Props> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { children, className, disabled, onClick } = props;

  return (
    <Button className={`${classes.root} ${className}`} disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
TextButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(TextButton);
