import React, { memo } from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  alert: {
    width: '720px',
    whiteSpace: 'pre-wrap',
  },
}));

type Props = SnackbarProps;

const NomalSnackbar: React.FC<Props> = memo((props) => {
  const classes = useStyles();
  const {
    open,
    message,
    anchorOrigin = { vertical: 'top', horizontal: 'center' },
    autoHideDuration = 5000,
    onClose,
    action,
  } = props;

  const handleAlertClose: (event: React.SyntheticEvent) => void = (event) => {
    if (onClose) onClose(event, 'clickaway');
  };

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      message={message}
      key={anchorOrigin.vertical + anchorOrigin.horizontal}
      autoHideDuration={autoHideDuration}
      action={action}>
      <Alert elevation={6} className={classes.alert} variant="filled" severity="error" onClose={handleAlertClose}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default NomalSnackbar;
