import { Box, makeStyles } from '@material-ui/core';
import { SelectButton } from 'components/system/atoms';
import React from 'react';
import { confSelectAreaType } from 'types/machineConf/confSelectAreaType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  selectArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '330px',
    height: '138px',
    textAlign: 'center',
    backgroundColor: '#FFFBDF',
    border: '1px solid #707070',
    borderRadius: '8px',
    padding: '16px 4px',
    '& .title': {
      fontSize: '21px',
    },
    '& .content': {
      marginTop: 'auto',
    },
  },
}));

/**
 *
 * @Params {confSelectAreaType} - {title, leftRight, className, onClick, isSelected, value}
 * @returns {React.FC} - 設定エリア（選択リスト）
 *
 */
const ConfSelectArea: React.FC<confSelectAreaType> = React.memo(
  ({ title, leftRight, className, onClick, isSelected, value }) => {
    const classes = useStyles();

    return (
      <Box className={classes.selectArea}>
        <Box className="title">{title}</Box>
        <Box className="content">
          <SelectButton
            className={className}
            leftRight={leftRight}
            onClick={onClick}
            arrowSize="32px"
            isSelected={isSelected}>
            {value}
          </SelectButton>
        </Box>
      </Box>
    );
  },
);

export default ConfSelectArea;
