import { Box, makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import React from 'react';
import { PositionIconType } from 'types/machineConf/iconType';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    borderRadius: '50%',
    textAlign: 'center',
    border: '3px solid #FFFFFF',
  },
  HStyle: {
    background: '#FF3E3E',
  },
  EStyle: {
    background: '#3086FF',
  },
  HPStyle: {
    background: '#FFB54E',
  },
  smallStyle: {
    height: '28px',
    width: '28px',
    fontSize: '14px',
    lineHeight: '25px',
  },
  mediumStyle: {
    height: '52px',
    width: '52px',
    fontSize: '22px',
    lineHeight: '49px',
  },
  largeStyle: {
    height: '56px',
    width: '56px',
    fontSize: '22px',
    lineHeight: '53px',
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * PositionIcon
 *
 * @author atsushi.teruya
 * @param {PositionIconType} {className, type} -
 * @returns {React.FC} - アイコン
 *
 */
const PointerIcon: React.FC<PositionIconType> = ({ className, type, size }) => {
  const classes = useStyles();
  // アイコンサイズを定義
  let iconSizeStyle = '';
  switch (size) {
    case COMMON.SIZE.SMALL:
      iconSizeStyle = classes.smallStyle;
      break;
    case COMMON.SIZE.MEDIUM:
      iconSizeStyle = classes.mediumStyle;
      break;
    default:
      iconSizeStyle = classes.largeStyle;
  }
  // タイプに応じた配色を定義
  let typeStyle = '';
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  switch (type) {
    case 'H':
      typeStyle = classes.HStyle;
      break;
    case 'E':
      typeStyle = classes.EStyle;
      break;
    case 'HP':
      typeStyle = classes.HPStyle;
      break;
    default:
  }

  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const iconStyle = `${classes.root} ${iconSizeStyle} ${typeStyle} ${className}`;

  return <Box className={iconStyle}>{type}</Box>;
};

export default React.memo(PointerIcon);
