import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import Combobox, { ListBoxItemType } from 'components/system/atoms/selectBoxs/Combobox';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { EquipmentSelectDataTableRow } from 'types/group/EquipmentSelectType';
import { MachineListApiResponseType } from 'types/group/GroupDataStateType';
import { MachineGiftNameResponseType } from 'types/maintenance/MachineType';
import { themeColor } from 'constants/theme';
import COMMON from 'constants/common';
import { setGiftNameListDataSource } from '../../../redux/slices/maintenanceSlice';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
    '& .addNewButton': {
      height: '64px',
      marginLeft: '28px',
    },
  },
  mainArea: {
    height: '430px',
    flexDirection: 'row',
    marginTop: 24,
  },
  decision: {
    textAlign: 'right',
  },
  addNewButton: {
    height: '64px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  labelStyle: {
    fontSize: 28,
    color: '#000000',
    marginRight: 24,
    width: 217,
  },
  labelContent: {
    textAlign: 'right',
  },
  textInputLeftStationName: {
    '& .outlinedInput': {
      height: '48px',
      width: 645,
      borderColor: themeColor.leftDarkColor,
      border: '2px solid',
      borderRadius: '4px',
      '& input': {
        fontSize: 28,
      },
    },
  },
  textInputRightStationName: {
    '& .outlinedInput': {
      height: '48px',
      width: 645,
      borderColor: themeColor.rightDarkColor,
      border: '2px solid',
      borderRadius: '4px',
      '& input': {
        fontSize: 28,
      },
    },
  },
  leftGiftComboboxOutline: {
    height: 48,
    width: 645,
    borderColor: themeColor.leftDarkColor,
    border: '2px solid',
    borderRadius: '4px',
    margin: '4px 0 0 0',
  },
  rightGiftComboboxOutline: {
    height: 48,
    width: 645,
    borderColor: themeColor.rightDarkColor,
    border: '2px solid',
    borderRadius: '4px',
    margin: '4px 0 0 0',
  },
  comboboxTextInput: {
    '& #leftGiftName': {
      fontSize: 28,
      padding: '6px 4px 6px 8px',
    },
    '& #rightGiftName': {
      fontSize: 28,
      padding: '6px 4px 6px 8px',
    },
  },
  contentText: { fontSize: '24px' },
}));

type UpdateMachineValueType = {
  machineNo: string;
  machineType: string | null;
  leftStationName: string;
  leftGiftId?: number | string | null;
  rightStationName: string;
  rightGiftId?: number | string | null;
  leftGiftDefault?: ListBoxItemType | null;
  rightGiftDefault?: ListBoxItemType | null;
};

type SubmitResponseType = {
  result: boolean;
};

type ParametersType = {
  boardSerial: string;
};

// messages
const confirmMsgSave: string = MsgHelper.messageFormat(MSG.INF230);
const messageSaveSuccess: string = MsgHelper.messageFormat(MSG.INF309, '機器', '機器一覧');

const UpdateMachine: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const boardSerialParameter = location.state !== undefined ? (location.state as ParametersType).boardSerial : '';
  const [machineGiftNameDatasource, setMachineGiftNameDatasource] = React.useState<Array<ListBoxItemType>>([]);
  const [openSaveConfirmDlg, setOpenSaveConfirmDlg] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [apiCompleted, setApiCompleted] = React.useState(false);

  const [inputValue, setInputValue] = useState<UpdateMachineValueType>({
    machineNo: '',
    machineType: null,
    leftStationName: '',
    leftGiftId: undefined,
    rightStationName: '',
    rightGiftId: undefined,
    leftGiftDefault: undefined,
    rightGiftDefault: undefined,
  });

  const getGiftNameList = async (): Promise<Array<ListBoxItemType>> => {
    const http = new HttpConnection({ dispatch }, false);
    const data = await http.get<Array<MachineGiftNameResponseType>>(REST_API.MAINTENANCE.GET_MACHINE_GIFT_NAME_LIST);
    let machineGiftNameListFormat: Array<ListBoxItemType> = [];
    if (data.length > 0) {
      machineGiftNameListFormat = data.map((giftName) => ({
        id: giftName.giftId,
        title: giftName.giftName,
      }));
    }
    dispatch(setGiftNameListDataSource(data));
    return Promise.resolve<Array<ListBoxItemType>>(machineGiftNameListFormat);
  };

  const getMachineDetail = async (boardSerial: string): Promise<EquipmentSelectDataTableRow | null> => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<MachineListApiResponseType>(REST_API.MAINTENANCE.GET_MACHINE_DETAIL_BY_BOARD_SERIAL, {
      boardSerial,
    });
    let machineDetail: EquipmentSelectDataTableRow | null = null;
    if (data) {
      machineDetail = {
        key: data.boardSerial,
        checked: false,
        machineNo: data.boardSerial,
        machineType: data.machineType,
        machineName: data.machineName,
        leftStationKey: data.leftStationId,
        leftStationName: data.leftStationName,
        leftStationGiftName: data.leftGiftName,
        leftStationGiftKey: data.leftGiftId,
        rightStationKey: data.rightStationId,
        rightStationName: data.rightStationName,
        rightStationGiftName: data.rightGiftName,
        rightStationGiftKey: data.rightGiftId,
      };
    }
    return Promise.resolve<EquipmentSelectDataTableRow | null>(machineDetail);
  };

  const handleGetMachineDetail = (boardSerial: string) => {
    getMachineDetail(boardSerial).then((machineDetail: EquipmentSelectDataTableRow | null) => {
      if (machineDetail !== null) {
        getGiftNameList().then((giftNameList: Array<ListBoxItemType>) => {
          setMachineGiftNameDatasource(giftNameList);
          const leftGiftNameItem: ListBoxItemType =
            giftNameList.filter((machineGift) => machineGift.id === machineDetail.leftStationGiftKey)[0] ?? undefined;
          const rightGiftNameItem: ListBoxItemType =
            giftNameList.filter((machineGift) => machineGift.id === machineDetail.rightStationGiftKey)[0] ?? undefined;
          setInputValue({
            ...inputValue,
            machineNo: machineDetail.machineNo,
            machineType: machineDetail.machineType,
            leftStationName: machineDetail.leftStationName,
            leftGiftId: machineDetail.leftStationGiftKey,
            rightStationName: machineDetail.rightStationName,
            rightGiftId: machineDetail.rightStationGiftKey,
            leftGiftDefault: leftGiftNameItem,
            rightGiftDefault: rightGiftNameItem,
          });
          setApiCompleted(true);
        });
      }
    });
  };

  useEffect(() => {
    handleGetMachineDetail(boardSerialParameter);
  }, [boardSerialParameter]);

  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.MAINT_MACHINE_LIST);
  };

  const handleSaveButton = () => {
    setOpenSaveConfirmDlg(true);
  };
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleGiftNameChange = (value: ListBoxItemType | null, id: string) => {
    let newValue = null;
    if (value !== null && value !== undefined) {
      newValue = value.id;
    }
    if (id === 'leftGiftId') {
      setInputValue({ ...inputValue, [id]: newValue, leftGiftDefault: value });
    } else {
      setInputValue({ ...inputValue, [id]: newValue, rightGiftDefault: value });
    }
  };

  const apiSubmitUpdateMachine = async () => {
    if (!(loginUserInfo?.currentHeadQId && loginUserInfo?.currentStoreId)) return;
    const machineUpdateBody = {
      boardSerial: inputValue.machineNo,
      machineType: inputValue.machineType,
      leftStationName: inputValue.leftStationName,
      leftGiftId: inputValue.leftGiftId,
      rightStationName: inputValue.rightStationName,
      rightGiftId: inputValue.rightGiftId,
    };
    const http = new HttpConnection({ dispatch });
    const data = await http.post<SubmitResponseType>(REST_API.MAINTENANCE.GET_MACHINE_UPDATE_BY_BOARD_SERIAL, {
      ...machineUpdateBody,
    });
    if (data.result) {
      setOpenInfoDialog(true);
    }
  };

  const handleSubmitData = useCallback(() => {
    setOpenSaveConfirmDlg(false);
    apiSubmitUpdateMachine();
  }, [inputValue]);

  const handleCloseInfoDialog = useCallback(() => {
    setOpenInfoDialog(false);
    handleClickGroupConfTop();
  }, []);

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.topArea}>
          <Box className="returnButton">
            <ReturnButton onClick={handleClickGroupConfTop}>機器一覧</ReturnButton>
          </Box>
        </Box>
        <Box className={classes.mainArea} style={{ visibility: apiCompleted ? 'visible' : 'hidden' }}>
          <div className={classes.inputRow}>
            <div className={classes.labelStyle}>
              <div className={classes.labelContent}>登録番号</div>
            </div>
            <div className={classes.labelStyle}>
              <div>{inputValue.machineNo}</div>
            </div>
          </div>
          {inputValue.machineType !== COMMON.MACHINE_TYPE.JACK ? (
            <div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>ステーション名L</div>
                </div>
                <div>
                  <OutlineTextBox
                    id="leftStationName"
                    name="leftStationName"
                    value={inputValue.leftStationName}
                    onChange={handleChangeInput}
                    className={classes.textInputLeftStationName}
                  />
                </div>
              </div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>景品名称</div>
                </div>
                <div>
                  <Combobox
                    id="leftGiftName"
                    dataSource={machineGiftNameDatasource}
                    value={inputValue.leftGiftDefault}
                    onChange={(value) => handleGiftNameChange(value, 'leftGiftId')}
                    classOutline={classes.leftGiftComboboxOutline}
                    classInput={classes.comboboxTextInput}
                  />
                </div>
              </div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>ステーション名R</div>
                </div>
                <div>
                  <OutlineTextBox
                    id="rightStationName"
                    name="rightStationName"
                    value={inputValue.rightStationName}
                    onChange={handleChangeInput}
                    className={classes.textInputRightStationName}
                  />
                </div>
              </div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>景品名称</div>
                </div>
                <div>
                  <Combobox
                    id="rightGiftName"
                    dataSource={machineGiftNameDatasource}
                    value={inputValue.rightGiftDefault}
                    onChange={(value) => handleGiftNameChange(value, 'rightGiftId')}
                    classOutline={classes.rightGiftComboboxOutline}
                    classInput={classes.comboboxTextInput}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>ステーション名</div>
                </div>
                <div>
                  <OutlineTextBox
                    id="leftStationName"
                    name="leftStationName"
                    value={inputValue.leftStationName}
                    onChange={handleChangeInput}
                    className={classes.textInputRightStationName}
                  />
                </div>
              </div>
              <div className={classes.inputRow}>
                <div className={classes.labelStyle}>
                  <div className={classes.labelContent}>景品名称</div>
                </div>
                <div>
                  <Combobox
                    id="leftGiftName"
                    dataSource={machineGiftNameDatasource}
                    value={inputValue.leftGiftDefault}
                    onChange={(value) => handleGiftNameChange(value, 'leftGiftId')}
                    classOutline={classes.rightGiftComboboxOutline}
                    classInput={classes.comboboxTextInput}
                  />
                </div>
              </div>
            </div>
          )}
        </Box>
        <Grid className={classes.decision}>
          <DecisionButton onClick={handleSaveButton}>登録</DecisionButton>
        </Grid>
      </Box>
      <ConfirmDialog
        open={openSaveConfirmDlg}
        msg={confirmMsgSave}
        onOk={handleSubmitData}
        onCancel={() => setOpenSaveConfirmDlg(false)}
        addClass={{
          contentText: classes.contentText,
        }}
      />
      <InfoDialog open={openInfoDialog} msg={messageSaveSuccess} closeFunc={handleCloseInfoDialog} />
    </>
  );
};

export default UpdateMachine;
