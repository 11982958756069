import { Box, makeStyles } from '@material-ui/core';
import { SelectBox } from 'components/system/atoms';
import React from 'react';

// 型定義
// データソース
type dataSourceProps = {
  name: string;
  value: string;
};

// パラメータ
type Props = {
  disabled?: boolean;
  dataSource: Array<dataSourceProps>;
  name: string;
  fullWidth?: boolean;
  size?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  label?: string;
};

// ヘッダに適用するCSSを定義
const useStyles = makeStyles(() => ({
  label: {
    margin: '4px 12px 0px 0px',
    display: 'inline-block',
  },
  area: {
    height: '36px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sortArea: {
    textAlign: 'right',
    fontSize: '19px',
  },
}));

// ヘッダ定義
const NormalSelectBox: React.FC<Props> = ({ name, dataSource, value, onChange, label }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.area}>
        <Box className={classes.sortArea}>
          <span className={classes.label}>{label}</span>
          <SelectBox name={name} dataSource={dataSource} fullWidth value={value} onChange={onChange} />
        </Box>
      </Box>
    </>
  );
};

export default NormalSelectBox;
