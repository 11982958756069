import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButtonType } from 'types/system/buttonType';

/**
 * CloseButton Component
 * 閉じるボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.white,
    borderRadius: '12px',
    background: theme.palette.common.black,
    paddingLeft: '17px',
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
  defaultStyle: {
    fontSize: '28px',
    lineHeight: '28px',
    height: '70px',
    width: '156px',
  },
  caption: {
    width: '100%',
  },
  action: {
    width: 168,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    cursor: 'auto',
    '&:hover': {
      background: 'transparent',
    },
  },
}));

// コンポーネント定義
const CloseButton: React.FC<BaseButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { className, disabled, fullWidth, onClick, children } = props;

  return (
    <Button className={classes.action} onClick={onClick}>
      <Button
        variant="contained"
        className={`${classes.button} ${className || classes.defaultStyle}`}
        fullWidth={fullWidth}
        onClick={onClick}
        disabled={disabled}>
        <p className={classes.caption}>{children}</p>
      </Button>
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
CloseButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(CloseButton);
