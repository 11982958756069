import { Box, makeStyles, Table, TableRow } from '@material-ui/core';
import CLSFCN from 'constants/classification';
import useShowAreaConf from 'customHooks/useShowAreaConf';
import React from 'react';
import { useParams } from 'react-router-dom';
import store from 'redux/store';
import COMMON from 'constants/common';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  title: {
    position: 'relative',
    transform: 'translateY(30%)',
    fontSize: '24px',
    lineHeight: 0.7,
  },
  detailBlock: {
    borderCollapse: 'collapse',
    fontSize: '14px',
    marginTop: 17,
  },
  detailBlockBottom: {
    borderCollapse: 'collapse',
    fontSize: '14px',
    marginTop: '12px',
  },
  detailInline: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'inline',
  },
  areaTop: {
    backgroundColor: '#D2F9FF',
    height: '32px',
    border: '1px solid #707070',
    textAlign: 'center',
    fontSize: '19px',
  },
  areaTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '32px',
    textAlign: 'center',
    fontSize: '19px',
  },

  areaBottom: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    height: '60px',
    width: '60px',
    lineHeight: '22px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '19px',
  },
  areaBottomValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    height: '60px',
    width: '60px',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '19px',
  },
  bgWhite: {
    background: '#ffffff',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '44px',
    height: '44px',
    border: '1px solid #707070',
    margin: '0 auto',
  },
  table: {
    border: (props: { isLeft: boolean }) =>
      props.isLeft ? `2px solid rgb(1, 195, 213)` : '2px solid rgb(255, 67, 134)',
    borderRadius: 4,
    padding: '5px 10px',
    height: '564px',
    width: '320px',
  },
}));

export const getClassificationName = (category: string, value: string): string => {
  let name = '';
  const { classificationList } = store.getState().common;
  if (classificationList != null && classificationList[category] !== null) {
    name = classificationList[category][value]?.name ?? '';
  }
  return name;
};

const TableStationHomeDetail: React.FC<{ isLeft: boolean }> = ({ isLeft }) => {
  const { selectConfId } = useParams<{ selectConfId: string }>();
  const areaConfHook = useShowAreaConf({ selectConfId });
  const { areaConf, conf } = areaConfHook;
  const classes = useStyles({ isLeft });
  if (!areaConfHook.showTable || areaConfHook.showLine === undefined || !areaConf || !conf) return null;

  const {
    lineFlg1,
    AreaSpecificSettingsLine2,
    AreaSpecificSettingsLine3,
    AreaSpecificSettingsLine4,
    AreaSpecificSettingsLine5,
    AreaSpecificSettingsLine6,
  } = areaConfHook.showLine;

  let leftArmSizeFlag = false;
  let rightArmSizeFlag = false;
  if (
    conf.leftArmSize === COMMON.ARM_SIZE.NONE ||
    conf.leftArmSize === COMMON.ARM_SIZE.S ||
    conf.leftArmSize === COMMON.ARM_SIZE.M ||
    conf.leftArmSize === COMMON.ARM_SIZE.L ||
    conf.leftArmSize === COMMON.ARM_SIZE.SS
  ) {
    leftArmSizeFlag = true;
  }
  if (
    conf.rightArmSize === COMMON.ARM_SIZE.NONE ||
    conf.rightArmSize === COMMON.ARM_SIZE.S ||
    conf.rightArmSize === COMMON.ARM_SIZE.M ||
    conf.rightArmSize === COMMON.ARM_SIZE.L ||
    conf.rightArmSize === COMMON.ARM_SIZE.SS
  ) {
    rightArmSizeFlag = true;
  }

  return (
    <Box className={classes.table}>
      <Box className={classes.title}>
        <b>アーム詳細</b>
      </Box>
      <Table className={classes.detailBlock}>
        {conf.confDiv !== '2' && (
          <TableRow>
            <td className={classes.areaTop}>ｱｰﾑｻｲｽﾞ</td>
            <td className={classes.areaTopValue}>
              {leftArmSizeFlag ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, String(conf.leftArmSize)) : '－'}/
              {rightArmSizeFlag ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, String(conf.rightArmSize)) : '－'}
            </td>
            <td className={classes.areaTop}>向き</td>
            <td className={classes.areaTopValue}>
              {getClassificationName(CLSFCN.CATCHER_DIRECTION.CATEGORY_CD, String(conf.catcherDirection))}
            </td>
          </TableRow>
        )}

        <TableRow>
          <td className={classes.areaTop}>ｱｰﾑ開度</td>
          <td className={classes.areaTopValue}>{areaConf?.armOpen}%</td>
          <td className={classes.areaTop}>初期高さ</td>
          <td className={classes.areaTopValue}>{areaConf?.initialHeight} </td>
        </TableRow>
      </Table>
      <Table className={classes.detailBlockBottom}>
        <TableRow>
          <td className={classes.areaBottom}>ｴﾘｱ</td>
          <td className={classes.areaBottom}>
            左<br />
            ｱｰﾑ
          </td>
          <td className={classes.areaBottom}>
            右<br />
            ｱｰﾑ
          </td>
          <td className={classes.areaBottom}>
            下降
            <br />
            限界
          </td>
          <td className={classes.areaBottom}>
            押込
            <br />力
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgWhite}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{lineFlg1 ? areaConf?.lPower1 : '－'}</td>
          <td className={classes.areaBottomValue}>{lineFlg1 ? areaConf?.rPower1 : '－'}</td>
          <td className={classes.areaBottomValue}>{lineFlg1 ? areaConf?.descentLimit1 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {lineFlg1
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing1))
              : '－'}
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgGreen}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine2 ? areaConf?.lPower2 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine2 ? areaConf?.rPower2 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine2 ? areaConf?.descentLimit2 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {AreaSpecificSettingsLine2
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing2))
              : '－'}
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgPurple}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine3 ? areaConf?.lPower3 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine3 ? areaConf?.rPower3 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine3 ? areaConf?.descentLimit3 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {AreaSpecificSettingsLine3
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing3))
              : '－'}
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgYellow}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine4 ? areaConf?.lPower4 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine4 ? areaConf?.rPower4 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine4 ? areaConf?.descentLimit4 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {AreaSpecificSettingsLine4
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing4))
              : '－'}
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgRed}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine5 ? areaConf?.lPower5 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine5 ? areaConf?.rPower5 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine5 ? areaConf?.descentLimit5 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {AreaSpecificSettingsLine5
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing5))
              : '－'}
          </td>
        </TableRow>
        <TableRow>
          <td className={classes.areaBottomValue}>
            <Box className={classes.bgBlue}>&nbsp;</Box>
          </td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine6 ? areaConf?.lPower6 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine6 ? areaConf?.rPower6 : '－'}</td>
          <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine6 ? areaConf?.descentLimit6 : '－'}</td>
          <td className={classes.areaBottomValue}>
            {AreaSpecificSettingsLine6
              ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing6))
              : '－'}
          </td>
        </TableRow>
      </Table>
    </Box>
  );
};

export default TableStationHomeDetail;
