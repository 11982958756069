import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import confSaveIcon from 'assets/images/confSaveIcon.svg';
import confListIcon from 'assets/images/confListIcon.svg';
import confInitializationIcon from 'assets/images/manageConfIcon.svg';

/**
 * CommonButton Component
 * 共通ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// 型定義
type Props = {
  fullWidth?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
  iconName: string;
};

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '72px',
    width: '200px',
    fontSize: '21px',
    textAlign: 'left',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '8%',
      transform: 'translateY(-50%)',
      WebkitTransform: 'translateY(-50%)',
      width: '40px',
      height: '40px',
    },
  },
  caption: {
    marginLeft: '56px',
    width: '100%',
  },
}));

// コンポーネント定義
const CommonButton: React.FC<Props> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { children, fullWidth, onClick, disabled, iconName } = props;

  if (iconName === 'confSaveIcon') {
    return (
      <Button
        variant="contained"
        fullWidth={fullWidth}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}>
        <img src={confSaveIcon} alt="設定を保存" />
        <p className={classes.caption}>{children}</p>
      </Button>
    );
  }
  if (iconName === 'confListIcon') {
    return (
      <Button
        variant="contained"
        fullWidth={fullWidth}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}>
        <img src={confListIcon} alt="保存設定一覧" />
        <p className={classes.caption}>{children}</p>
      </Button>
    );
  }
  if (iconName === 'confInitializationIcon') {
    return (
      <Button
        variant="contained"
        fullWidth={fullWidth}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}>
        <img src={confInitializationIcon} alt="はじめから設定する" />
        <p className={classes.caption}>{children}</p>
      </Button>
    );
  }

  return <></>;
};

// propsの初期値。未指定時に使用する。
CommonButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(CommonButton);
