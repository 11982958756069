import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import COMMON from 'constants/common';
import UrlHelper from 'utils/url.helper';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import SavedConfTable from 'components/machineConf/organisms/tables/SavedConfTable';
import { getConfListThunk } from 'redux/slices/machineConfSlice';
import { ConfListType } from 'types/machineConf/machineConfType';
import { animateScroll as scroll } from 'react-scroll';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MsgHelper from 'utils/message.helper';
import MSG from 'constants/messages';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import { convertMachineVersionToNumber } from 'utils/common.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '保存設定一覧',
  screenId: COMMON.SCREEN_ID.SAVED_CONF_LIST,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  initializeState: true,
};
const TABLE_ID = 'dataSavedConfTable';
const MAX_DISPLAY_BAR = 6;

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '16px 32px 28px 27px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      width: '200px',
      height: '70px',
    },
  },
  previousBtn: {
    margin: '14px 0 12px 0',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '24px',
  },
  nextBtn: {
    margin: '12px 0 14px 0',
    textAlign: 'center',
    width: '100%',
    height: '24px',
  },
  deleteBtn: {
    textAlign: 'left',
    width: '144px',
    height: '68px',
  },
  contentText: { fontSize: '24px' },
}));

// messages
const confirmDeleteMsg: string = MsgHelper.messageFormat(MSG.INF216, 'チェックした設定');
const successDeleteMsg: string = MsgHelper.messageFormat(MSG.INF304, 'チェックした設定');
const errorDeleteMsg: string = MsgHelper.messageFormat(MSG.INF112, '設定');

const SavedConf: React.FC = () => {
  /* ************ hooks ************ */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [openConfirmMsg, setOpenConfirmMsg] = useState<boolean>(false);
  const [openSuccessMsg, setOpenSuccessMsg] = useState<boolean>(false);
  const [openErrorMsg, setOpenErrorMsg] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [openDialogINF243, setOpenDialogINF243] = useState<boolean>(false);
  const [openDialogINF244, setOpenDialogINF244] = useState<boolean>(false);
  const [openDialogINF246, setOpenDialogINF246] = useState<boolean>(false);
  const [selectedItemValue, setSelectedItemValue] = useState<string>('');
  /* ************ state/redux ************ */
  const savedConfList: Array<ConfListType> = useAppSelector((state) => state.machineConf.savedConfList);
  const { selectedStation, selectedStationList } = useAppSelector((state) => state.machineConf);

  useEffect(() => {
    handleInitialDisplay();
  }, []);

  /* ************ Function ************ */
  const redirectByReflection = (confId: string) => {
    if (selectedStation.machineType === COMMON.MACHINE_TYPE.JACK) {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_JACK_CONF_TOP, {
          selectConfId: confId,
        }),
        { execDiv: COMMON.EXEC_DIV.SAVED_CONF },
      );
    } else {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
          selectConfId: confId,
        }),
        { execDiv: COMMON.EXEC_DIV.SAVED_CONF },
      );
    }
  };

  /* ************ Event ************ */
  // No1. Initial display
  const handleInitialDisplay = async () => {
    setCheckedItems({});
    dispatch(getConfListThunk({ machineType: selectedStation.machineType }));
  };

  // No2. Back to the station setting TOP screen
  const handleClickReturn = useCallback(() => {
    if (selectedStation.machineType === COMMON.MACHINE_TYPE.JACK) {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_JACK_CONF_TOP, {
          selectConfId: 'none',
        }),
      );
    } else {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
          selectConfId: 'none',
        }),
      );
    }
  }, []);

  // No3. Scroll up
  const handleClickPrev = useCallback(() => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  }, []);

  // No4. Handle Reflect button
  const handleClickReflect = useCallback((selectedItem: ConfListType) => {
    const machineVersionSet = new Set();
    // machineVersionの種類数を取得
    selectedStationList.forEach((station) => machineVersionSet.add(station.machineVersion));
    // machineVersionの上下を比較するため、文字列から数値に変換する
    const stationMachineVersion = convertMachineVersionToNumber(selectedStation.machineVersion);
    const selectedConfMachineVersion = convertMachineVersionToNumber(selectedItem.machineVersion);

    setSelectedItemValue(selectedItem.value);
    if (stationMachineVersion === null || selectedConfMachineVersion === null) {
      // machineVersionが不正な場合はエラー
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    } else if (machineVersionSet.size > 1) {
      setOpenDialogINF244(true);
    } else if (stationMachineVersion > selectedConfMachineVersion) {
      setOpenDialogINF243(true);
    } else if (stationMachineVersion < selectedConfMachineVersion) {
      setOpenDialogINF246(true);
    } else {
      redirectByReflection(selectedItem.value);
    }
  }, []);

  // No5. Handle Setting Detail button
  const handleClickSettingDetail = useCallback((selectedItem: ConfListType) => {
    if (selectedItem.machineType === COMMON.MACHINE_TYPE.JACK) {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_JACK_HOME_DETAIL, {
          selectConfId: selectedItem.value,
        }),
        {
          from: location.pathname,
        },
      );
    } else {
      history.push(
        UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_HOME_DETAIL, {
          selectConfId: selectedItem.value,
        }),
        {
          from: location.pathname,
        },
      );
    }
  }, []);

  // No6. Scroll down
  const handleClickNext = useCallback(() => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  }, []);

  // No7. Delete
  const handleSelectedItemChange = useCallback((checkedItem: ConfListType) => {
    setCheckedItems((prevState: Record<string, boolean>) => ({
      ...prevState,
      [checkedItem.value]: !prevState[checkedItem.value],
    }));
  }, []);

  const handleOkDeleteConfirmDlg = async () => {
    const confIdList: Array<string> = [];
    Object.entries(checkedItems).forEach(([confId, isChecked]) => {
      if (isChecked) {
        confIdList.push(confId);
      }
    });

    setOpenConfirmMsg(false);
    await deleteSavedConf(confIdList);
    handleInitialDisplay();
    setOpenSuccessMsg(true);
  };

  const deleteSavedConf = async (confIdList: Array<string>) => {
    const http = new HttpConnection({ dispatch });
    await http.put(REST_API.MACHINE_CONF.DELETE_SAVED_CONF, { confIdList });
  };

  const handleClickDelete = () => {
    const confIdList: Array<string> = [];
    Object.entries(checkedItems).forEach(([confId, isChecked]) => {
      if (isChecked) {
        confIdList.push(confId);
      }
    });
    if (confIdList.length < 1) {
      setOpenErrorMsg(true);
    } else {
      setOpenConfirmMsg(true);
    }
  };

  const handleOkReflectConfirmDlg = async () => {
    setOpenDialogINF243(false);
    setOpenDialogINF244(false);
    setOpenDialogINF246(false);
    redirectByReflection(selectedItemValue);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickReturn}>設定TOP</ReturnButton>
            </Box>
          </Box>
          {savedConfList.length > 0 && (
            <>
              <Grid className={classes.previousBtn}>
                <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />
              </Grid>
              <SavedConfTable
                savedConfList={savedConfList}
                tableId={TABLE_ID}
                handleSelectedItemChange={handleSelectedItemChange}
                handleClickReflect={handleClickReflect}
                handleClickSettingDetail={handleClickSettingDetail}
              />
              <Grid className={classes.nextBtn}>
                {MAX_DISPLAY_BAR < savedConfList.length && (
                  <PrevNextButton className="nextScroll" onClick={handleClickNext} />
                )}
              </Grid>
              <Grid className={classes.deleteBtn}>
                <DeleteButton onClick={handleClickDelete}>
                  チェック
                  <br />
                  して削除
                </DeleteButton>
              </Grid>
            </>
          )}
        </Box>

        <ConfirmDialog
          open={openConfirmMsg}
          msg={confirmDeleteMsg}
          onOk={handleOkDeleteConfirmDlg}
          onCancel={() => setOpenConfirmMsg(false)}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <ConfirmDialog
          open={openDialogINF243}
          msg={MsgHelper.messageFormat(MSG.INF243)}
          onOk={handleOkReflectConfirmDlg}
          onCancel={() => setOpenDialogINF243(false)}
        />
        <ConfirmDialog
          open={openDialogINF244}
          msg={MsgHelper.messageFormat(MSG.INF244)}
          onOk={handleOkReflectConfirmDlg}
          onCancel={() => setOpenDialogINF244(false)}
        />
        <ConfirmDialog
          open={openDialogINF246}
          msg={MsgHelper.messageFormat(MSG.INF246)}
          onOk={handleOkReflectConfirmDlg}
          onCancel={() => setOpenDialogINF246(false)}
        />
        <InfoDialog
          open={openSuccessMsg}
          msg={successDeleteMsg}
          closeFunc={() => setOpenSuccessMsg(false)}
          closeBtnLabel="OK"
        />
        <InfoDialog open={openErrorMsg} msg={errorDeleteMsg} closeFunc={() => setOpenErrorMsg(false)} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default SavedConf;
