import { Theme } from 'react-autosuggest';

export const AutoSuggestTheme: Theme = {
  container: {
    position: 'relative',
  },

  input: {
    height: '32px',
    marginTop: '4px',
    width: '500px',
    padding: '4px 8px',
    fontSize: '24px',
    border: '1px solid #aaa',
    borderRadius: '4px',
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: '37px',
    left: '191px',
    width: '500px',
    maxHeight: '190px',
    padding: '4px 4px',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontSize: '20px',
    borderRadius: '0 0 4px 4px',
    zIndex: 100,
    overflow: 'auto',
  },
  suggestionsList: {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '4px 4px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
};
