import { Box, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import React from 'react';

// ヘッダに適用するCSSを定義
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.white,
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: '#535B80',
    height: '36px',
    fontSize: '21px',
    paddingLeft: '12px',
    verticalAlign: 'middle',
    '& p': {
      lineHeight: '36px',
    },
  },
}));

// ヘッダ定義
const Title: React.FC = () => {
  const classes = useStyles();

  return (
    <ScreenContext.Consumer>
      {({ title }) => (
        <Box className={classes.title}>
          <p>{title}</p>
        </Box>
      )}
    </ScreenContext.Consumer>
  );
};

export default Title;
