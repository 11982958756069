import * as React from 'react';
import { Box, Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& div > div': {
      backgroundColor: theme.palette.common.black,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '730px',
    height: '397px',
    '& p': {
      color: theme.palette.common.white,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
  },
  message: {
    fontSize: '28px',
    marginLeft: '30px',
  },
  loadingTitleStyle: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 20,
  },
  loadingTitleText: {
    color: '#ffffff',
    fontSize: 30,
    padding: '20px 0px',
  },
}));

export type DialogLoadingProps = {
  open: boolean;
  titleLoading: string;
  msg: string;
};

const DialogLoading: React.FC<DialogLoadingProps> = ({ open, msg, titleLoading, children }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} className={classes.dialog} disableBackdropClick maxWidth={false} disableEscapeKeyDown>
      <DialogContent className={classes.content}>
        <Box className={classes.loadingTitleStyle} style={{ display: 'flex' }}>
          <CircularProgress size={80} style={{ color: '#ffffff' }} />
          <span className={classes.loadingTitleText}>{titleLoading}</span>
        </Box>
        <Box>
          <DialogContentText className={classes.message}>{msg}</DialogContentText>
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogLoading;
