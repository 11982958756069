import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';

/* ******** Main Component ******** */
/**
 * フィルタ
 * 認証の有無をチェックし、ない場合は認証チェック画面を表示。
 * セッションから認証情報を復元。
 *
 * @author atsushi.teruya
 * @returns {React.FC} - page component
 *
 */
const Filter: React.FC = ({ children }) => {
  const history = useHistory();

  const { isUnauthorizedError, status } = useAppSelector((state) => state.common.errorInfo);

  // 認証チェック => セッションタイムアウトエラー ログイン、エラー画面、認証チェック画面は処理しない。
  if (isUnauthorizedError) {
    // 認証チェック画面へ遷移。
    if (status === 0) {
      history.push(redirectLinks.COMMON_AUTH_CHECK);
    } else {
      history.push(redirectLinks.COMMON_SESSION_TIMEOUT_ERROR);
    }
    return <></>;
  }
  // 配下に指定されたコンポーネントを描画
  return <>{children}</>;
};

export default Filter;
