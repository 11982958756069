/**
 * Function debounce
 * 関数の実行を制御する。
 * ユーザの入力が止まった時、delayに指定したミリ秒が経過するとコールバック関数を実行。
 * @author: atsushi.teruya
 *
 * @param {T} callback - コールバック関数
 * @param {number} delay - 遅延するミリ秒数
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => unknown>(
  callback: T,
  delay = 1000,
): ((...args: Parameters<T>) => void) => {
  let timeoutId: NodeJS.Timeout;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback(...args), delay);
  };
};

/**
 * Function debounce
 * 関数の実行を制御する。
 * ユーザの入力が止まった時、delayに指定したミリ秒が経過するとコールバック関数を実行。
 * @author: atsushi.teruya
 *
 * @param {T} callback - コールバック関数
 * @param {number} delay - 遅延するミリ秒数
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throttle = <T extends (...args: any[]) => unknown>(
  callback: T,
  delay = 1000,
): ((...args: Parameters<T>) => void) => {
  let timerId: NodeJS.Timeout;
  let lastExecTime = 0;
  return (...args) => {
    const elapsedTime = performance.now() - lastExecTime;
    const execute = () => {
      callback(...args);
      lastExecTime = performance.now();
    };
    // タイマー予約中の処理がないなら処理を実行する。
    if (!timerId) {
      execute();
    }
    // タイマー予約中の処理があるなら処理をキャンセル。
    if (timerId) {
      clearTimeout(timerId);
    }
    if (elapsedTime > delay) {
      // 前回実行時刻から指定時間(ミリ秒)を経過したなら処理を実行する。
      execute();
    } else {
      // タイマー予約する。
      timerId = setTimeout(execute, delay);
    }
  };
};
