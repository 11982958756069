import { Box, makeStyles } from '@material-ui/core';
import CLSFCN from 'constants/classification';
import React, { useCallback, useMemo, useState } from 'react';
import { SelectButton } from 'components/system/atoms';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { SelectListType } from 'types/support/supportType';

type Props = {
  title: string;
  isSelected: boolean;
  status: string;
  pitX: number | null;
  pitY: number | null;
  onChangeStatus: (newStatus: string) => void;
  onClick: (e: React.MouseEvent) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    width: '127px',
    height: '149px',
    background: '#FFFFFF',
  },
  header: {
    height: '37px',
    border: '1px solid',
    borderColor: (props: Props) => {
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#FF3E3E';
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.DISABLED) return '#0335FF';
      if (!props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#000000';
      return '#707070';
    },
    borderBottom: 'none',
    background: (props: Props) => {
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#FF3E3E';
      if (props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#000000';
      return '#E9E8E8';
    },
    color: (props: Props) => {
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#FFFFFF';
      if (props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#FFFFFF';
      return '#000000';
    },
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    width: '100%',
    height: '112px',
    border: '1px solid',
    borderColor: (props: Props) => {
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#FF3E3E';
      if (props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.DISABLED) return '#0335FF';
      if (!props.isSelected && props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) return '#000000';
      return '#707070';
    },
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  selectButton: {
    width: '105px',
    height: '30px',
    fontSize: '19px',
    background: (props: Props) => (props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED ? '#FF3E3E' : '#E9E8E8'),
    color: (props: Props) => (props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED ? '#FFFFFF' : '#000000'),
    borderStyle: 'none',
    '&:hover': {
      background: (props: Props) => (props.status === CLSFCN.PIT_CONFIG.STATUS.ENABLED ? '#FF3E3E' : '#E9E8E8'),
    },
  },
  Axes: {
    width: '56px',
    '& span': {
      display: 'inline-block',
      width: '32px',
      textAlign: 'end',
    },
  },
}));

// ヘッダ定義
const PitPinpointInfo: React.FC<Props> = (props) => {
  const { title, status, pitX, pitY, isSelected, onChangeStatus, onClick } = props;
  const classes = useStyles(props);
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const dataSource = useMemo(() => getClsfcnByCategory(CLSFCN.PIT_CONFIG.STATUS_CATEGORY_CD), []);

  const handleClickConfPattern = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource,
        name: '',
        placement: 'right-end',
        title: '落とし穴の有効無効を選択してください。',
        value: status,
        onClick: (data) => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
          onChangeStatus(data.value);
        },
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [status],
  );

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.header}>{title}</Box>
      <Box className={classes.main}>
        {(pitX === null || pitY === null) && <p>なし</p>}
        {pitX !== null && pitY !== null && (
          <>
            <p className={classes.Axes}>
              縦 <span>{pitY}</span>
            </p>
            <p className={classes.Axes}>
              横 <span>{pitX}</span>
            </p>
            <SelectButton
              className={classes.selectButton}
              variant="contained"
              arrowColor={status === CLSFCN.PIT_CONFIG.STATUS.ENABLED && !selectList.open ? '#FFFFFF' : '#000000'}
              onClick={(e) => {
                if (isSelected) {
                  handleClickConfPattern(e);
                }
              }}
              isSelected={selectList.open}
              arrowSize="22px">
              {getClassificationName(CLSFCN.PIT_CONFIG.STATUS_CATEGORY_CD, status)}
            </SelectButton>
          </>
        )}
      </Box>
      <SelectList selectList={selectList} />
    </Box>
  );
};

export default PitPinpointInfo;
