import React, { memo } from 'react';
import { makeStyles, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

/**
 * Radio Button Component
 * ラジオボタン
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  radios: {},
  radioGroupLabel: {
    color: '#0D0D0D',
    fontSize: '23px',
  },
  labelRoot: {
    color: '#0D0D0D',
    fontSize: '21px',
  },
}));

/* ************ Type ************ */
export type RadioType = {
  value: string;
  label?: string;
  disabled?: boolean;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  children?: React.ReactNode;
};
export type RadioButtonsType = {
  name?: string;
  radioList: Array<RadioType>;
  value?: string | null;
  defaultValue?: string;
  label?: string;
  row?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

/* ************ Component ************ */
const RadioButtons: React.FC<RadioButtonsType> = memo((props) => {
  const classes = useStyles();
  const {
    name,
    radioList,
    defaultValue,
    value,
    label,
    row = false,
    disabled = false,
    onChange,
    className = '',
  } = props;

  return (
    <FormControl component="fieldset" className={`${classes.radios} ${className}`}>
      {label && (
        <FormLabel className={classes.radioGroupLabel} component="legend" focused={false}>
          {label}
        </FormLabel>
      )}
      <RadioGroup
        row={row}
        aria-label={label}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}>
        {radioList.map((item) => (
          <>
            <FormControlLabel
              control={<Radio color="secondary" />}
              value={item.value}
              label={<span className={classes.labelRoot}>{item.label}</span>}
              labelPlacement={item.labelPlacement}
              disabled={disabled || item.disabled}
            />
            {item.children}
          </>
        ))}
      </RadioGroup>
    </FormControl>
  );
});

export default RadioButtons;
