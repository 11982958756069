import { Box, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import COMMON from 'constants/common';
import React, { memo } from 'react';
import { MachineSelectListType } from 'types/group/GroupType';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import ListCheckBox from '../../../system/atoms/checkBoxs/ListCheckBox';

const column1 = 220;
const column2 = 887;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '352px',
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    width: 1108,
    borderCollapse: 'separate', // collapseにするとスクロール時にヘッダーのborderが消えるので注意
    borderLeft: '1px solid #707070',
    '& th,td': {
      lineHeight: '30px',
    },
    '& th': {
      fontSize: '23px',
      textAlign: 'center',
      height: '48px',
      padding: 0,
      backgroundColor: '#CFCFCF',
      backgroundClip: 'padding-box',
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
    },
    '& tbody tr': {
      height: '76px',
    },
    '& td': {
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    '& .boardSerial': {
      textAlign: 'left',
    },
  },
  tdMachineName: {
    textAlign: 'left',
    alignItems: 'center',
    padding: '0px 0px',
  },
  tdEquipmentNo: {
    fontSize: '23px',
    display: 'flex',
    alignItems: 'center',
  },
  tdStationName: {
    fontSize: '21px',
    textAlign: 'left',
    alignItems: 'center',
    padding: '0 16px',
  },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<MachineSelectListType>;
  handleCheckAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleCheck: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: MachineSelectListType) => void;
};

/* ************ Component ************ */
const MachineSelectTable: React.FC<Props> = ({ rows, handleCheckAll, handleCheck }) => {
  const classes = useStyles();

  const headerColumns = [
    {
      id: 'boardSerial',
      label: '登録番号',
      width: column1,
      hasCheckBox: true,
      align: 'left',
      className: 'boardSerial',
    },
    { id: 'stationName', label: 'ステーション名', width: column2, hasCheckBox: false, className: 'stationName' },
  ];

  return (
    <TableContainer id="scrollContainer" className={classes.default}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerColumns.map((column) => (
              <TableCell key={column.id} style={{ width: column.width }} className={column.className}>
                {column.hasCheckBox && <ListCheckBox name="selectStation" onChange={handleCheckAll} />}
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const key = `equipment_select_table_key_${row.boardSerial}`;
            return (
              <TableRow key={key}>
                <TableCell className={classes.tdMachineName} style={{ width: headerColumns[0].width }}>
                  <Box className={classes.tdEquipmentNo}>
                    <ListCheckBox
                      checked={row.checked}
                      name="selectStation"
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleCheck(ev, checked, row)
                      }
                    />
                    <Box className="cellStyle">{row.boardSerial}</Box>
                  </Box>
                </TableCell>
                <TableCell className={classes.tdStationName} style={{ width: headerColumns[1].width }}>
                  {row.machineType === COMMON.MACHINE_TYPE.JACK ? (
                    <TooltipComponent
                      tooltipText={row.leftGiftName}
                      displayWord={`J:${row.leftStationName} ${row.leftGiftName ?? COMMON.BLANK}`}
                    />
                  ) : (
                    <>
                      <TooltipComponent
                        tooltipText={row.leftGiftName}
                        displayWord={`L:${row.leftStationName} ${row.leftGiftName ?? COMMON.BLANK}`}
                      />
                      <TooltipComponent
                        tooltipText={row.rightGiftName}
                        displayWord={`R:${row.rightStationName} ${row.rightGiftName ?? COMMON.BLANK}`}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(MachineSelectTable);
