import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedConfIcon from 'assets/images/SpeedConfIcon.svg';
import SpeedConfIconYellow from 'assets/images/SpeedConfIconYellow.svg';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { Box, Table, TableRow, Theme } from '@material-ui/core';
import COMMON from 'constants/common';

type SpeedConfInfoProps = {
  title: string;
  isLeft: boolean;
  levelStatus: string;
  speedXy: string | undefined;
  speedZ: string | undefined;
};

type StyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: 9,
    height: '251px',
    width: '191px',
  },
  title: {
    position: 'relative',
    fontSize: 24,
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: '0 0px 0px 0',
  },
  detailBlockSpeed: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'block',
    marginLeft: '20px',
  },
  boxImg: {
    position: 'relative',
    height: '116px',
  },
  img: {
    position: 'relative',
    height: '125px',
    margin: '0 auto',
    top: '-10px',
  },
  speedTop: {
    backgroundColor: '#EEEEEE',
    width: '72px',
    height: '40px',
    border: '1px solid #707070',
    textAlign: 'center',
  },
  speedTopZ: {
    color: '#00B050',
  },
  speedTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '100px',
    height: '40px',
    textAlign: 'center',
  },
  X: {
    display: 'inline',
    color: '#FF3E3E',
    marginRight: '10px',
  },
  Y: {
    display: 'inline',
    color: '#3086FF',
  },
  speedTopText: {
    textAlign: 'center',
    color: '#FFC000',
  },
}));

const SpeedConfInfo: React.FC<SpeedConfInfoProps> = (props) => {
  const { title, isLeft, levelStatus, speedXy, speedZ } = props;
  const classes = useStyles({ isLeft });
  let speedTopText = (
    <>
      <p className={classes.X}>X</p>
      <p className={classes.Y}>Y</p>
    </>
  );
  if (levelStatus === COMMON.LEVER_STATUS.TRUE) {
    speedTopText = <Box className={classes.speedTopText}>レバー</Box>;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.frame}>
        <Box className={`${classes.title}`}>
          <b>{title}</b>
        </Box>
        <Box className={classes.child}>
          <Box className={classes.boxImg}>
            <img
              src={levelStatus === COMMON.LEVER_STATUS.FALSE ? SpeedConfIcon : SpeedConfIconYellow}
              alt="キャッチャー速度"
              className={classes.img}
            />
          </Box>
          <Table className={classes.detailBlockSpeed}>
            <TableRow>
              <td className={classes.speedTop}>{speedTopText}</td>
              <td className={classes.speedTopValue}>{getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(speedXy))}</td>
            </TableRow>
            <TableRow>
              <td className={classes.speedTop}>
                <div className={classes.speedTopZ}>Z</div>
              </td>
              <td className={classes.speedTopValue}>{getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(speedZ))}</td>
            </TableRow>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(SpeedConfInfo);
