import React, { memo } from 'react';
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { convertFullToHalfCharacters } from 'utils/string.helper';

const useStyles = makeStyles(() => ({
  outline: {
    '&+div': {
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#ffffff',
      border: '1px solid rgba(0,0,0,.25)',
      '& ul': {
        maxHeight: 176,
        overflow: 'auto',
      },
      '& li': {
        fontSize: 28,
        padding: '0 9px',
        wordBreak: 'break-all',
      },
      '& li[data-focus="true"]': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
      },
    },
  },
  input: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& fieldset': {
      border: 'none',
    },
  },
}));

export type ListBoxItemType = {
  id: string | number;
  title: string;
};

type Props = {
  id: string;
  dataSource: Array<ListBoxItemType>;
  onChange: (value: ListBoxItemType | null) => void;
  value?: ListBoxItemType | null;
  placeholder?: string;
  classInput?: string;
  classOutline?: string;
  classListBox?: string;
};

const Combobox: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { id, onChange, classInput, classOutline, dataSource, value = { id: '', title: '' } } = props;

  return (
    <Autocomplete
      id={id}
      options={dataSource}
      value={value}
      disablePortal
      getOptionLabel={(option) => option.title}
      noOptionsText=""
      filterOptions={(options: ListBoxItemType[], state: FilterOptionsState<ListBoxItemType>) => {
        const newState = {
          ...state,
          inputValue: convertFullToHalfCharacters(state.inputValue),
        };
        return createFilterOptions({
          stringify: (option: ListBoxItemType) => convertFullToHalfCharacters(option.title), // 全て半角に変換
        })(options, newState);
      }}
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      onChange={(event: any, newValue: ListBoxItemType | null) => onChange(newValue)}
      className={`${classOutline} ${classes.outline}`}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          className={`${classes.input} ${classInput}`}
          variant="outlined"
        />
      )}
    />
  );
};

export default memo(Combobox);
