import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from 'assets/images/InfoIcon.svg';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '640px',
  },
  content: {
    alignItems: 'start',
    display: 'flex',
    padding: '40px 8px 0px 40px ',
  },
  contentText: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '8px 0px 0px 24px',
    fontSize: '21px',
  },
  action: {
    padding: '0px 40px 16px 40px ',
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailmsg: {
    fontSize: 24,
    padingLeft: 8,
  },
  buttonIcon: {
    height: 80,
    width: 200,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: '#FF7070',
    },
  },
  buttonCancel: {
    backgroundColor: '#BCBCBC',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#BCBCBC',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  input: {
    padding: '10px',
    outline: 'none',
    borderRadius: '3px',
    fontSize: '21px',
    width: '450px',
    marginLeft: '20px',
  },
}));

type DialogProps = {
  open: boolean;
  detailMsg?: string;
  onOk: (e: React.FormEvent) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  okBtnLabel?: string;
  onCancel: () => void;
  cancelBtnLabel?: string;
  children?: React.ReactNode;
};

const ConfSaveDialog: React.FC<DialogProps> = ({
  open,
  onOk,
  handleChange,
  okBtnLabel = '設定を保存',
  onCancel,
  cancelBtnLabel = 'しない',
}) => {
  const classes = useStyles();
  const msg = '現在の設定を保存します。\nタイトルを入力してください。';

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <img src={InfoIcon} alt="Info" />
        <div>
          <DialogContentText classes={{ root: classes.contentText }}>{msg}</DialogContentText>
          <input type="text" name="confTitle" onChange={handleChange} className={classes.input} />
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.action,
        }}>
        <NormalButton onClick={onOk} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {okBtnLabel}
        </NormalButton>
        <NormalButton onClick={onCancel} className={`${classes.buttonIcon} ${classes.buttonCancel}`}>
          {cancelBtnLabel}
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfSaveDialog;
