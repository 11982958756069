import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { NormalButtonType } from 'types/system/buttonType';

/**
 * NormalButton Component
 * 通常ボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  defaultStyle: {
    height: '72px',
    width: '200px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  gray: {
    color: theme.palette.common.black,
    background: theme.base.gray,
    '&:hover': {
      background: theme.base.gray,
    },
  },
  black: {
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    '&:hover': {
      background: theme.palette.common.black,
    },
  },
}));

// コンポーネント定義
const NormalButton: React.FC<NormalButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const styles = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { baseColor, children, classes, className, disabled, fullWidth, isSelected, selectedClass, onClick } = props;

  let colorStyle = (baseColor && styles.defaultStyle) || '';
  switch (baseColor) {
    case 'black':
      colorStyle = styles.black;
      break;
      colorStyle = styles.gray;
    default:
  }

  const styleClasses = {
    contained: `${className || styles.defaultStyle} ${colorStyle} ${(isSelected && selectedClass) || ''}`,
  };

  return (
    <Button
      variant="contained"
      classes={classes || styleClasses}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}>
      {children}
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
NormalButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(NormalButton);
