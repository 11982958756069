import { Box, makeStyles } from '@material-ui/core';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';
import React from 'react';
import { confTenkeyAreaType } from 'types/machineConf/confTenkeyAreaType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  selectArea: {
    display: 'flex',
    flexDirection: 'column',
    width: '330px',
    height: '138px',
    textAlign: 'center',
    backgroundColor: '#FFFBDF',
    border: '1px solid #707070',
    borderRadius: '8px',
    padding: '16px 4px',
    '& .title': {
      fontSize: '21px',
    },
    '& .content': {
      marginTop: 'auto',
    },
  },
}));

/**
 *
 * @Params {ConfTenkeyArea} - {title, leftRight, className, onOpen, isSelected, value}
 * @returns {React.FC} - 設定エリア（テンキー）
 *
 */
const ConfTenkeyArea: React.FC<confTenkeyAreaType> = React.memo(
  ({ title, leftRight, className, onOpen, isSelected, value }) => {
    const classes = useStyles();

    return (
      <Box className={classes.selectArea}>
        <Box className="title">{title}</Box>
        <Box className="content">
          <TenkeyInput
            className={className}
            leftRight={leftRight}
            onOpen={onOpen}
            isSelected={isSelected}
            value={value}
          />
        </Box>
      </Box>
    );
  },
);

export default ConfTenkeyArea;
