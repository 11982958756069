import React, { memo, useEffect, useState } from 'react';
import { Box, GridList, Theme, createStyles, makeStyles } from '@material-ui/core';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { formatDateWithDayOfWeek } from 'utils/datetime.helper';
import CLSFCN from 'constants/classification';
import { getClsfcnByCategory } from 'utils/common.helper';
import { formatCurrency, formatPercent } from 'utils/number.helper';
import { HourlySalesInfoType, SelectedHourListType } from 'types/home/TopTypes';
import HourlyBtn from '../atoms/buttons/HourlyButton';

const buttonStyle = {
  width: '80px',
  height: '84px',
  borderRadius: '8px',
  boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
  fontSize: '22px',
  border: '1px solid #707070',
  padding: '4px',
};
/* ************ Style ************ */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: ' 100%',
    },
    today: {
      display: 'grid',
      width: '72px',
      height: '126px',
      fontSize: '21px',
      placeItems: 'center',
      textAlign: 'center',
      padding: '0 8px',
      color: '#FF3E3E',
      whiteSpace: 'pre-wrap',
      backgroundColor: '#F4FF8A',
      border: '1px solid #707070',
      borderRadius: '0 0 0 4px',
    },
    hourlyBtnList: {
      display: 'flex',
      alignItems: 'center',
    },
    gridList: {
      display: 'flex',
      alignItems: 'center',
    },
    reloadBtn: {
      color: theme.palette.common.black,
      background: theme.base.gray,
      margin: '0 21px 0 6px',
      ...buttonStyle,
      '&:hover': {
        background: theme.base.gray,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
    },
    hourlyBtn: {
      color: theme.palette.common.black,
      background: theme.palette.common.white,
      letterSpacing: '-0.2px',
      ...buttonStyle,
      '&:hover': {
        background: theme.palette.common.white,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
      '&:disabled': {
        background: theme.palette.common.white,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
    },
    salesInfoTable: {
      display: 'flex',
      margin: '0 2px 0 auto',
      '& thead tr': {
        display: 'flex',
        flexDirection: 'column',
        '& th': {
          fontSize: '18px',
          width: '94px',
          display: 'grid',
          placeItems: 'center',
          textAlign: 'center',
        },
      },
      '& tbody tr': {
        display: 'flex',
        flexDirection: 'column',
        '& td': {
          fontSize: '21px',
          width: '124px',
          textAlign: 'end',
          overflow: 'hidden',
        },
      },
      '& :nth-of-type(even)': {
        backgroundColor: theme.base.gray,
      },
      '& th,td': {
        height: '38px',
        border: '1px solid #707070',
      },
    },
    tdCostRate: {
      color: '#FF3E3E',
    },
  }),
);

/* ************ Function ************ */
// 時間に応じてボタンを非活性にする
const disabledHourlyBtn = (key: string, today?: string) => {
  if (!today) return true;
  const hours = new Date(today).getHours();
  switch (key) {
    case CLSFCN.HOURLY_SALES_BTN.YESTERDAY:
    case CLSFCN.HOURLY_SALES_BTN[12]:
      return false;
    case CLSFCN.HOURLY_SALES_BTN[14]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[12]);
    case CLSFCN.HOURLY_SALES_BTN[16]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[14]);
    case CLSFCN.HOURLY_SALES_BTN[18]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[16]);
    case CLSFCN.HOURLY_SALES_BTN[20]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[18]);
    case CLSFCN.HOURLY_SALES_BTN[22]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[20]);
    case CLSFCN.HOURLY_SALES_BTN[24]:
      return hours < Number(CLSFCN.HOURLY_SALES_BTN[22]);
    default:
      return true;
  }
};

/* ************ Component ************ */
const HourlySalesInfo: React.FC<{
  hourlySalesInfo?: HourlySalesInfoType;
  callGetSalesInfoApi: (selectedBtnList: SelectedHourListType) => void;
}> = memo(({ hourlySalesInfo, callGetSalesInfoApi }) => {
  const classes = useStyles();
  const [selectedBtnList, setSelectedBtnList] = useState<SelectedHourListType>(hourlySalesInfo?.selectedHourList || []);
  const HOURLY_SALES_BTN = getClsfcnByCategory(CLSFCN.HOURLY_SALES_BTN.CATEGORY_CD);

  useEffect(() => {
    // 親コンポーネントの値が変われば上書き
    if (hourlySalesInfo?.selectedHourList) {
      setSelectedBtnList(hourlySalesInfo.selectedHourList);
    }
  }, [hourlySalesInfo?.selectedHourList]);

  /* ************ Event ************ */
  // 更新ボタン
  const handleClickUpdateBtn = () => {
    callGetSalesInfoApi(selectedBtnList);
  };
  // 各時間ボタン
  const handleClickHourlyBtn = (isSelected: boolean, value: string) => {
    let list: SelectedHourListType = [];
    if (isSelected) {
      list = [...selectedBtnList, value];
    } else if (selectedBtnList.includes(value)) {
      // isSelected=falseの場合、配列から選択中のボタンを削除
      list = selectedBtnList.filter((v) => v !== value);
    }
    callGetSalesInfoApi(list); // stateは非同期でセットされるため、一時的な変数listを定義して利用する
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.today}>
        {hourlySalesInfo?.today && formatDateWithDayOfWeek(new Date(hourlySalesInfo.today))}
      </Box>
      <Box className={classes.hourlyBtnList}>
        <NormalButton onClick={handleClickUpdateBtn} className={classes.reloadBtn}>
          更新
        </NormalButton>
        <GridList className={classes.gridList} cols={HOURLY_SALES_BTN.length} spacing={12} cellHeight="auto">
          {HOURLY_SALES_BTN.map((buttonInfo: { value: string; name: string }) => {
            const { value, name } = buttonInfo;
            return (
              <Box key={value}>
                <HourlyBtn
                  value={value}
                  isSelected={selectedBtnList.includes(value)}
                  disabled={disabledHourlyBtn(value, hourlySalesInfo?.today)}
                  exClassName={classes.hourlyBtn}
                  onClick={handleClickHourlyBtn}>
                  {name}
                </HourlyBtn>
              </Box>
            );
          })}
        </GridList>
      </Box>
      <table className={classes.salesInfoTable}>
        <thead>
          <tr>
            <th>原価率</th>
            <th>ｽﾃｰｼｮﾝ平均</th>
            <th>売上合計</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.tdCostRate}>
              {hourlySalesInfo?.costRate && formatPercent(hourlySalesInfo.costRate)}
            </td>
            <td>{hourlySalesInfo?.avgStationSales && formatCurrency(hourlySalesInfo.avgStationSales)}</td>
            <td>{hourlySalesInfo?.totalSales && formatCurrency(hourlySalesInfo.totalSales)}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
});

export default HourlySalesInfo;
