import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { memo } from 'react';
import { MachineGiftTypeTableRow } from 'types/maintenance/GiftType';

const column1 = 192;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '211px',
    overflowX: 'hidden',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '21px',
      lineHeight: '40px',
      minHeight: '42px',
    },
    '& th': {
      textAlign: 'left',
      paddingLeft: 17,
      height: '42px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '74px',
      '&:nth-of-type(odd)': {
        backgroundColor: '#EFEFEF',
      },
    },
    '& td': {
      padding: '0 17px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  tdMachineName: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& .tdEditButton': {
      height: '44px',
      width: '120px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdEquipmentNo: {
    display: 'flex',
    alignItems: 'center',
  },
  tdAlignCenter: {
    display: 'left',
    justifyContent: 'center',
  },
  stationRow: {
    display: 'flex',
  },
  stationRowName: {
    flex: 1,
  },
}));

/* ************ Type ************ */
type RegisteredStationTableProps = {
  rows: Array<MachineGiftTypeTableRow>;
};

/* ************ Component ************ */
const RegisteredStationTable: React.FC<RegisteredStationTableProps> = ({ rows }) => {
  const classes = useStyles();

  const headerColumns = [
    { id: 'machineNoColumn', label: '登録番号', width: column1 },
    { id: 'stationNameColumn', label: 'ステーション名', width: '100%' },
  ];

  return (
    <>
      <TableContainer id="scrollContainer" className={classes.default}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell
                  key={`machine_list_table_head_key_${column.id}`}
                  style={{ width: column.width }}
                  className={classes.head}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const key = `gift_list_table_key_${row.key}`;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={classes.tdStationName}>
                      <div className={classes.tdAlignCenter}>
                        <div className="cellStyle">{row.machineNo}</div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tdStationName}>
                      <div className={classes.tdAlignCenter}>
                        <div className="cellStyle">{row.stationName}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(RegisteredStationTable);
