import React from 'react';
import { useAppSelector } from 'redux/hooks';
import ErrorBoundary from './ErrorBoundary';
import Loading from '../organisms/Loading';
import ContentWithoutTitle from '../organisms/contents/ContentWithoutTitle';

/* ************ Component ************ */
const ErrorLayout: React.FC = ({ children }) => {
  const { loading } = useAppSelector((state) => state.common);

  return (
    <ErrorBoundary>
      <Loading open={loading} />
      <ContentWithoutTitle>{children}</ContentWithoutTitle>
    </ErrorBoundary>
  );
};

export default ErrorLayout;
