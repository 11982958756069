import React, { memo } from 'react';
import { makeStyles, Box } from '@material-ui/core';

/**
 * BorderBottom Box Component
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  borderBottomBox: {
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: '1px solid',
  },
  defaultStyle: {
    height: '57px',
    maxWidth: '420px',
    fontSize: '24px',
    lineHeight: '28px',
    '& .label': {
      width: '145px',
      margin: '0 40px 0 0 ',
    },
  },
}));

/* ************ Type ************ */
type Props = {
  label?: string;
  value?: string | null;
  className?: string;
};

/* ************ Component ************ */
const BorderBottomBox: React.FC<Props> = memo((props) => {
  const classes = useStyles();
  const { label, value, className } = props;

  return (
    <Box className={`${classes.borderBottomBox} ${className || classes.defaultStyle}`}>
      {label && (
        <Box whiteSpace="pre-wrap" className="label">
          {label}
        </Box>
      )}
      <Box className="value">{value}</Box>
    </Box>
  );
});

export default BorderBottomBox;
