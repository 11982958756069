import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { useAppDispatch } from 'redux/hooks';
import { handleClearSystemError, initializeCommonSlice } from 'redux/slices/commonSlice';
import redirectLinks from 'constants/redirectLinks';
import ErrorLayout from 'components/system/layouts/ErrorLayout';
import COMMON from 'constants/common';
import ScreenContext from 'Contexts/Common/ScreenContext';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'セッションタイムアウトエラー',
  screenId: 'SCR722',
  currentMenu: COMMON.MENU.ERROR,
};

/* ************ Style ************ */
const secondaryGray = '#B3B3B3';
const useStyles = makeStyles((theme) => ({
  sessionTimeoutError: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    width: '960px',
    '& .header': {
      fontSize: '40px',
      textAlign: 'center',
      color: theme.palette.common.white,
      backgroundColor: `${secondaryGray}`,
      border: `3px solid ${theme.base.tableBorder}`,
      borderRadius: '8px 8px 0 0',
    },
    '& .content': {
      fontSize: '24px',
      borderRight: `3px solid ${theme.base.tableBorder}`,
      borderBottom: `3px solid ${theme.base.tableBorder}`,
      borderLeft: `3px solid ${theme.base.tableBorder}`,
      borderRadius: '0 0 8px 8px',
      padding: '40px',
    },
  },
  btnArea: {
    textAlign: 'center',
    marginTop: '100px',
    '& button': {
      fontSize: '21px',
    },
  },
}));

const SessionTimeoutError: React.FC = () => {
  /* ************ state/redux ************ */
  const classes = useStyles();
  const dispatch = useAppDispatch();

  /* ************ function ************ */
  useEffect(() => {
    dispatch(handleClearSystemError());
    dispatch(initializeCommonSlice());
  }, []);

  // ログイン画面に遷移
  const handleReturnLogin = () => {
    window.location.href = redirectLinks.LOGIN;
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <ErrorLayout>
        <Box className={classes.sessionTimeoutError}>
          <Box className={classes.errorContainer}>
            <Box className="header">セッションタイムアウト</Box>
            <Box className="content">
              <p>最後の操作から長時間操作が行われなかったか、ログインしていません。</p>
              <p>お手数ですがログインをお願いいたします。</p>
              <Box className={classes.btnArea}>
                <NormalButton onClick={handleReturnLogin}>ログイン画面へ</NormalButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </ErrorLayout>
    </ScreenContext.Provider>
  );
};

export default SessionTimeoutError;
