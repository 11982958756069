import { Box, makeStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import React, { useCallback, useMemo, useState } from 'react';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import prizeMachineJackIcon from 'assets/images/prizeMachineIconJack.png';
import pointer2JackConf from 'assets/images/pointer2JackConf.svg';
import pointer4JackConf from 'assets/images/pointer4JackConf.svg';
import { ledSelectAreaType } from 'types/machineConf/ledSelectAreaType';

import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { ledSelectButtonType } from 'types/machineConf/ledSelectButtontype';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { SelectListType } from 'types/support/supportType';
import MESSAGES from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import ColorEffectSelectAreaJack from './ColorEffectSelectAreaJack';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    padding: '1px',
  },
  settingArea: {
    '& > *': {
      margin: '50px 0 116px 0',
    },
    '& > *:last-child': {
      marginBottom: '84px',
    },
  },
  imgArea: {
    display: 'block',
    position: 'relative',
    width: '276px',
    '& .prizeMachine': {
      margin: '0 ',
      display: 'inline',
    },
    '& .leftPointer': {
      position: 'absolute',
      left: '0',
    },
    '& .pointer2': {
      top: '104px',
    },
    '& .pointer4': {
      top: '188px',
    },
  },
  innerImgArea: {
    width: '156px',
    textAlign: 'center',
    alignItems: 'center',
    margin: '0 0 0 120px',
  },
  resetButton: {
    width: '116px',
    height: '48px',
    fontSize: '21px',
    marginBottom: '50px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  LEFT: {
    COLOR3: 'lLedColor3',
    EFFECT3: 'lLedEffect3',
    COLOR1: 'lLedColor1',
    EFFECT1: 'lLedEffect1',
  },
};

/* ******** Main Component ******** */
/**
 *
 * @Params leftRight, ledSelectAreaConf, onChange
 * @returns {React.FC} - LED 設定エリア
 *
 */
const LedSelectAreaJack: React.FC<ledSelectAreaType> = React.memo((props) => {
  const { leftRight, ledSelectAreaConf, onChange, disabled = false } = props;
  const classes = useStyles();

  // Local State
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);

  // DataSource
  const ledColorDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_COLOR.CATEGORY_CD), []);
  const ledEffectDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_EFFECT.CATEGORY_CD), []);

  /* ******** Events ******** */
  const changeEffect = (colorValue: string, effectValue: string): string => {
    // 色を「ベースカラー」に変更した場合、ペアの効果を「無し（点灯）」に変更
    if (colorValue === CLSFCN.LED_COLOR.BASE_COLOR) return CLSFCN.LED_EFFECT.NONE_LIGHTING;
    // 色を「ベースカラー」以外に変更した場合、ペアの効果はそのまま
    return effectValue;
  };

  // リセット
  const handleClickReset = (): void => {
    const newConf = {
      ...ledSelectAreaConf,
      lLedColor3: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect3: CLSFCN.LED_EFFECT.NONE_LIGHTING,
      lLedColor1: CLSFCN.LED_COLOR.BASE_COLOR,
      lLedEffect1: CLSFCN.LED_EFFECT.NONE_LIGHTING,
    };
    onChange(newConf);
  };

  // on click pulldown items
  const handleClickSelectList = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>, id: string, oldValue: string) => {
      let dataSource;
      let title;
      let value;
      let placement: 'left' | 'left-start' | 'left-end' | 'right' | 'right-start' | 'right-end';
      let effectInputName: string | null = null;

      if ([INPUT_NAMES.LEFT.COLOR1, INPUT_NAMES.LEFT.COLOR3].includes(id)) {
        dataSource = ledColorDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '色');
      } else {
        dataSource = ledEffectDataSource;
        title = MsgHelper.messageFormat(MESSAGES.INF234, '効果');
      }
      switch (id) {
        case INPUT_NAMES.LEFT.COLOR1:
        case INPUT_NAMES.LEFT.EFFECT1:
          placement = 'right-end';
          break;
        default:
          placement = 'right';
      }

      if (id === INPUT_NAMES.LEFT.COLOR1) {
        value = ledSelectAreaConf.lLedColor1;
        effectInputName = INPUT_NAMES.LEFT.EFFECT1;
      } else if (id === INPUT_NAMES.LEFT.COLOR3) {
        value = ledSelectAreaConf.lLedColor3;
        effectInputName = INPUT_NAMES.LEFT.EFFECT3;
      } else if (id === INPUT_NAMES.LEFT.EFFECT1) {
        value = ledSelectAreaConf.lLedEffect1;
      } else if (id === INPUT_NAMES.LEFT.EFFECT3) {
        value = ledSelectAreaConf.lLedEffect3;
      } else {
        return;
      }

      setSelectList({
        ...INITIAL_SELECT_LIST.selectList,
        open: true,
        onClick: (data: { value: string }, name: string) => {
          // 選択値が変化ない場合
          if (data.value === oldValue) return;

          if (effectInputName) {
            let ledEffect = '0';
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT1) ledEffect = ledSelectAreaConf.lLedEffect1;
            if (effectInputName === INPUT_NAMES.LEFT.EFFECT3) ledEffect = ledSelectAreaConf.lLedEffect3;
            // 色を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
              [effectInputName]: changeEffect(data.value, ledEffect),
            });
          } else {
            // 効果を変更した場合
            onChange({
              ...ledSelectAreaConf,
              [name]: data.value,
            });
          }
        },
        onClose: () => setSelectList(INITIAL_SELECT_LIST.selectList),
        anchorEL: ev.currentTarget,
        dataSource,
        name: id,
        placement,
        title,
        value,
      });
    },
    [ledSelectAreaConf],
  );
  /* ************ Params ************ */
  const getColorParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(CLSFCN.LED_COLOR.CATEGORY_CD, value),
  });

  const getEffectParam = (inputName: string, value: string): ledSelectButtonType => ({
    selectButton: {
      isSelected: selectList.name === inputName,
      onClick: (ev) => handleClickSelectList(ev, inputName, value),
    },
    value: getClassificationName(CLSFCN.LED_EFFECT.CATEGORY_CD, value),
  });

  return (
    <Box className={classes.content}>
      <Box className={classes.settingArea}>
        <ColorEffectSelectAreaJack
          leftRight={leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR3, ledSelectAreaConf.lLedColor3)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT3, ledSelectAreaConf.lLedEffect3)}
          effectDisabled={ledSelectAreaConf.lLedColor3 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={disabled}
        />
        <ColorEffectSelectAreaJack
          leftRight={leftRight}
          selectButtonLR={COMMON.LEFT_RIGHT.RIGHT}
          colorSelect={getColorParam(INPUT_NAMES.LEFT.COLOR1, ledSelectAreaConf.lLedColor1)}
          effectSelect={getEffectParam(INPUT_NAMES.LEFT.EFFECT1, ledSelectAreaConf.lLedEffect1)}
          effectDisabled={ledSelectAreaConf.lLedColor1 === CLSFCN.LED_COLOR.BASE_COLOR}
          disabled={disabled}
        />
      </Box>
      <Box className={classes.imgArea}>
        <Box className={classes.innerImgArea}>
          <DeleteButton disabled={disabled} className={classes.resetButton} onClick={handleClickReset}>
            リセット
          </DeleteButton>
          <img src={prizeMachineJackIcon} alt="prizeMachineJackIcon" className="prizeMachine" />
        </Box>
        {/* 左側 */}
        <img src={pointer2JackConf} alt="pointer2" className="leftPointer pointer2" />
        <img src={pointer4JackConf} alt="pointer4" className="leftPointer pointer4" />
      </Box>
      <SelectList selectList={selectList} />
    </Box>
  );
});

export default LedSelectAreaJack;
