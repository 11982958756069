/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useMemo } from 'react';
import { Stage, Layer, Circle, Group } from 'react-konva';
import { Vector2d } from 'konva/lib/types';
import { PitConfType } from 'types/machineConf/machineConfType';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import { throttle } from 'utils/controlFn.helper';
import { useAppSelector } from 'redux/hooks';

/* ************ constant ************ */
const ICON_SIZE = 24;
const AREA_SIZE = 426;
const PERCENT_AREA_SIZE = 339;

const AREA_WIDTH_SIZE = 390;
const PRIZE_AREA_MAX = 100;
const maxy = 86;

type Props = {
  pitConf: PitConfType;
  selectedPitNumber: number | null;
  onChange: (pos: Vector2d, pitNumber: number) => void;
  onChangeEnd: (pos: Vector2d, pitNumber: number) => void;
  className?: string;
};

const convertPixel = (width: number) => Math.round((width * AREA_WIDTH_SIZE) / PRIZE_AREA_MAX);
const convertPixelY = (width: number) => Math.round((width * AREA_SIZE) / PRIZE_AREA_MAX);

// ヘッダ定義
const PitPinpointPanelJack: React.FC<Props> = (props) => {
  const { pitConf, selectedPitNumber, onChange, onChangeEnd, className } = props;

  // アイコンとエリアのサイズからkonvaによるアイコンの操作範囲を定義
  const width = AREA_WIDTH_SIZE + ICON_SIZE;
  const height = AREA_SIZE + ICON_SIZE;
  const iconHalfSize = ICON_SIZE / 2;
  const maxAreaRangeX = AREA_WIDTH_SIZE + iconHalfSize; // エリア最大X座標
  const maxAreaRangeY = AREA_SIZE + iconHalfSize; // エリア最大Y座標
  const { leftRight } = useContext(MachineConfContext); //
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;

  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  const minX = (isLeft ? convertPixel(Number(nonAreaXMinSide)) : convertPixel(Number(nonAreaXMaxSide))) + iconHalfSize;
  const maxX = maxAreaRangeX - (isLeft ? convertPixel(Number(nonAreaXMaxSide)) : convertPixel(Number(nonAreaXMinSide)));

  const minY = maxAreaRangeY - convertPixelY(Number(nonAreaYMinSide));
  const maxY = AREA_SIZE - PERCENT_AREA_SIZE + convertPixelY(Number(nonAreaYMaxSide)) + iconHalfSize;

  // useCallbackで初回のみ関数オブジェクトを作る
  // プライズの座標単位(100x100)から画面の座標単位(360x360)に変換する
  const toScreenPos = useCallback((x: number | null, y: number | null) => {
    if (x === null || y === null) {
      return { x: null, y: null };
    }
    const maxPointY = 80;
    const pointY = y <= maxPointY ? y : maxPointY;

    return {
      x: isLeft
        ? Math.round((x * AREA_WIDTH_SIZE) / PRIZE_AREA_MAX)
        : AREA_WIDTH_SIZE - Math.round((x * AREA_WIDTH_SIZE) / PRIZE_AREA_MAX),
      y: AREA_SIZE - Math.round((pointY * AREA_SIZE) / PRIZE_AREA_MAX),
    };
  }, []);

  // 画面の座標単位(360x360)からプライズの座標単位(100x100)に変換する
  const toPrizePos = useCallback(
    (konvaPos: Vector2d) => ({
      x: isLeft
        ? Math.round((konvaPos.x / AREA_WIDTH_SIZE) * PRIZE_AREA_MAX)
        : PRIZE_AREA_MAX - Math.round((konvaPos.x / AREA_WIDTH_SIZE) * PRIZE_AREA_MAX),
      y: PRIZE_AREA_MAX - Math.round((konvaPos.y / AREA_SIZE) * PRIZE_AREA_MAX),
    }),
    [],
  );

  const pit1Pos = useMemo(() => toScreenPos(pitConf.pit1x, pitConf.pit1y), [pitConf.pit1x, pitConf.pit1y]);
  const pit2Pos = useMemo(() => toScreenPos(pitConf.pit2x, pitConf.pit2y), [pitConf.pit2x, pitConf.pit2y]);
  const pit3Pos = useMemo(() => toScreenPos(pitConf.pit3x, pitConf.pit3y), [pitConf.pit3x, pitConf.pit3y]);
  const pit4Pos = useMemo(() => toScreenPos(pitConf.pit4x, pitConf.pit4y), [pitConf.pit4x, pitConf.pit4y]);

  // ドラッグ中イベント
  const handleDrag = useCallback(
    throttle((pos: Vector2d) => {
      const prizePos = toPrizePos(pos);
      if (selectedPitNumber) {
        onChange(prizePos, selectedPitNumber);
      }
    }, 100),
    [selectedPitNumber, onChange],
  );

  const handleDragEnd = useCallback(
    (pos: Vector2d) => {
      const prizePos = toPrizePos(pos);
      if (selectedPitNumber) {
        onChangeEnd(prizePos, selectedPitNumber);
      }
    },
    [selectedPitNumber, onChangeEnd],
  );

  // アイコン ドラッグ中移動範囲制御用イベント
  const dragBoundFunc = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    if (pos.x < minX) {
      newPos.x = minX;
    } else if (maxX < pos.x) {
      newPos.x = maxX;
    }
    // Y座標
    if (pos.y > minY) {
      newPos.y = minY;
    } else if (pos.y < maxY) {
      newPos.y = maxY;
    }
    return newPos;
  };

  const getPitFillColor = useCallback((status: string | undefined, isSelected: boolean) => {
    if (status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
      if (isSelected) {
        return '#FF0000';
      }
      return '#000000';
    }
    return '#E9E8E8';
  }, []);
  const getPitStrokeColor = useCallback((status: string | undefined, isSelected: boolean) => {
    if (status === CLSFCN.PIT_CONFIG.STATUS.ENABLED) {
      return '#FFFFFF';
    }
    if (isSelected) {
      return '#0335FF';
    }
    return '#707070';
  }, []);
  return (
    <Stage width={width} height={height} className={className}>
      {/* Layer=エリアはアイコンのサイズ分配置する座標をずらす。 */}
      <Layer x={iconHalfSize} y={iconHalfSize} width={AREA_WIDTH_SIZE} height={AREA_SIZE}>
        {/* 罫線 */}
        {/* <Rect x={0} y={0} width={AREA_WIDTH_SIZE} height={AREA_SIZE} stroke="#707070" strokeWidth={2} /> */}

        {/* Layer内の要素はLayerの左上を(x,y)=(0,0)として座標を指定する */}
        {/* 落とし穴４ */}
        {pit4Pos.x !== null && pit4Pos.y !== null && (
          <Group
            x={pit4Pos.x}
            y={pit4Pos.y >= maxy ? pit4Pos.y : maxy}
            draggable={selectedPitNumber === 4}
            onDragStart={(e) => handleDrag(e.target.position())}
            onDragMove={(e) => handleDrag(e.target.position())}
            onDragEnd={(e) => handleDragEnd(e.target.position())}
            dragBoundFunc={dragBoundFunc}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit4Status, selectedPitNumber === 4)}
              stroke={getPitStrokeColor(pitConf?.pit4Status, selectedPitNumber === 4)}
            />
          </Group>
        )}
        {/* 落とし穴３ */}
        {pit3Pos.x !== null && pit3Pos.y !== null && (
          <Group
            x={pit3Pos.x}
            y={pit3Pos.y >= maxy ? pit3Pos.y : maxy}
            draggable={selectedPitNumber === 3}
            onDragStart={(e) => handleDrag(e.target.position())}
            onDragMove={(e) => handleDrag(e.target.position())}
            onDragEnd={(e) => handleDragEnd(e.target.position())}
            dragBoundFunc={dragBoundFunc}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit3Status, selectedPitNumber === 3)}
              stroke={getPitStrokeColor(pitConf?.pit3Status, selectedPitNumber === 3)}
            />
          </Group>
        )}
        {/* 落とし穴２ */}
        {pit2Pos.x !== null && pit2Pos.y !== null && (
          <Group
            x={pit2Pos.x}
            y={pit2Pos.y >= maxy ? pit2Pos.y : maxy}
            draggable={selectedPitNumber === 2}
            onDragStart={(e) => handleDrag(e.target.position())}
            onDragMove={(e) => handleDrag(e.target.position())}
            onDragEnd={(e) => handleDragEnd(e.target.position())}
            dragBoundFunc={dragBoundFunc}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit2Status, selectedPitNumber === 2)}
              stroke={getPitStrokeColor(pitConf?.pit2Status, selectedPitNumber === 2)}
            />
          </Group>
        )}
        {/* 落とし穴１ */}
        {pit1Pos.x !== null && pit1Pos.y !== null && (
          <Group
            x={pit1Pos.x}
            y={pit1Pos.y >= maxy ? pit1Pos.y : maxy}
            draggable={selectedPitNumber === 1}
            onDragStart={(e) => handleDrag(e.target.position())}
            onDragMove={(e) => handleDrag(e.target.position())}
            onDragEnd={(e) => handleDragEnd(e.target.position())}
            dragBoundFunc={dragBoundFunc}>
            <Circle
              radius={iconHalfSize}
              fill={getPitFillColor(pitConf?.pit1Status, selectedPitNumber === 1)}
              stroke={getPitStrokeColor(pitConf?.pit1Status, selectedPitNumber === 1)}
            />
          </Group>
        )}
      </Layer>
    </Stage>
  );
};

export default PitPinpointPanelJack;
