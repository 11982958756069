import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import TextArea from 'components/system/atoms/textBoxs/TextArea';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { TenkeyOpenEventType, TenkeyType } from 'types/support/supportType';
import Tenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/Tenkey';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 16px',
  },
  btnReturn: {
    width: '87px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 0px',
      width: '224px',
    },
    '& .addNewButton': {
      height: '64px',
      marginLeft: '28px',
    },
  },
  mainArea: {
    border: '1px solid #C8C8C8',
    borderRadius: '4px',
    height: '188px',
    flexDirection: 'row',
    marginTop: 12,
    padding: '0 15px',
  },
  decision: {
    textAlign: 'right',
    marginRight: 12,
    marginTop: '254px',
  },
  btnRegister: {
    fontSize: 21,
  },
  addNewButton: {
    height: '64px',
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  inputTenType: {
    height: '48px',
    width: '140px',
    border: '1px solid #000000',
    fontSize: 23,
    '& input': {
      textAlign: 'left',
      paddingLeft: 20,
    },
  },
  labelStyle: {
    fontSize: 24,
    color: '#000000',
    marginRight: 10,
    width: 99,
  },
  labelContent: {
    textAlign: 'left',
    width: '200px',
  },
  textInputGiftName: {
    '& .outlinedInput': {
      width: 973,
      border: '1px solid #000000',
      borderRadius: 5,
      '& textarea': {
        textAlign: 'left',
        paddingLeft: 20,
        fontSize: 23,
        lineHeight: 1.1,
        height: 101,
      },
    },
  },
}));

export type NewGiftValueType = {
  giftName: string;
  giftPrice: string;
};

/* *************    constants   ************* */
const TENKEY_OPTION = {
  GIFT_PRICE: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 4,
    scaleSize: 0,
    maxValue: 1100,
    minValue: 0,
  },
};

const NewGift: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [tenkey, setTenkey] = React.useState(INITIAL_TEN_KEY.tenkey);
  const [inputValue, setInputValue] = React.useState<NewGiftValueType>({
    giftName: '',
    giftPrice: '',
  });

  const handleDecisionTenkey = useCallback(
    (value: string) => {
      setInputValue({ ...inputValue, giftPrice: value });
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [inputValue],
  );

  const handleCloseTenkey = useCallback(() => {
    setTenkey(INITIAL_TEN_KEY.tenkey);
  }, []);

  const initialTenkey: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecisionTenkey,
    onClose: handleCloseTenkey,
  };

  // Tenkey - Initial height
  const handleOpenInitialGiftPrice: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkey,
      anchorPosition: { top: 100, left: 385 },
      name: 'giftPrice',
      option: TENKEY_OPTION.GIFT_PRICE,
      value: String(inputValue.giftPrice) || '0',
    });
  }, [inputValue]);

  useEffect(() => {
    const giftInfoParam: NewGiftValueType =
      location.state !== undefined ? (location.state as NewGiftValueType) : inputValue;
    setInputValue({ ...giftInfoParam });
  }, []);

  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.MAINT_GIFT_LIST);
  };

  const handleSaveButton = () => {
    history.push(redirectLinks.SELECT_STATION_LIST_FOR_GIFT_REGISTER, { ...inputValue });
  };
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const text = ev.target.value;
    // 入力から改行を削除
    const cleanedText = text.replace(/[\r\n]/g, '');
    setInputValue({
      ...inputValue,
      [ev.target.name]: cleanedText,
    });
  };

  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.topArea}>
          <Box className="returnButton">
            <ReturnButton onClick={handleClickGroupConfTop}>
              <p className={classes.btnReturn}>登録景品一覧</p>
            </ReturnButton>
          </Box>
        </Box>
        <Box className={classes.mainArea}>
          <div className={classes.inputRow}>
            <div className={classes.labelStyle}>
              <div className={classes.labelContent}>景品名称</div>
            </div>
            <div>
              <TextArea
                id="giftName"
                name="giftName"
                value={inputValue.giftName}
                onChange={handleChangeInput}
                className={classes.textInputGiftName}
              />
            </div>
          </div>
          <div className={classes.inputRow}>
            <div className={classes.labelStyle}>
              <div className={classes.labelContent}>原価</div>
            </div>
            <div>
              <TenkeyInput
                id="giftPrice"
                name="giftPrice"
                className={classes.inputTenType}
                isSelected={tenkey.name === 'giftPrice'}
                leftRight="2"
                value={String(inputValue.giftPrice)}
                onOpen={handleOpenInitialGiftPrice}
              />
            </div>
          </div>
        </Box>
        <Grid className={classes.decision}>
          <NormalButton onClick={handleSaveButton}>
            <span className={classes.btnRegister}>ステーション選択</span>
          </NormalButton>
        </Grid>
      </Box>
      <Tenkey tenkey={tenkey} />
    </>
  );
};

export default NewGift;
