import React, { memo } from 'react';
import { Theme, createStyles, makeStyles,Box } from '@material-ui/core';
import TopTitle from 'components/home/atoms/Titles/TopTitle';

/* ************ Style ************ */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      border: '.5px solid #707070',
      borderRadius: '4px',
    },
    contents: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }),
);

type RoundBoxType = {
  title?: string;
  exClassName?: {
    root?: string;
    contents?: string;
  };
};

/* ************ Component ************ */
const RoundBox: React.FC<RoundBoxType> = memo(({ title, exClassName, children }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${exClassName?.root}`}>
      {title && <TopTitle title={title} />}
      <Box className={`${classes.contents} ${exClassName?.contents}`}>{children}</Box>
    </Box>
  );
});

export default RoundBox;
