import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { makeStyles, Box, Button } from '@material-ui/core';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import downArrowIcon2 from 'assets/images/downArrowIcon_2.svg';
import { setAreaConf, setOperationConf } from 'redux/slices/machineConfSlice';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import UrlHelper from 'utils/url.helper';
import { resetDescentLimitValueJack } from 'utils/resetDescentLimitJack.helper';
import MESSAGES from 'constants/messages';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: 'ゲーム設定TOP',
  screenId: COMMON.SCREEN_ID.GAME_CONF_TOP_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '100px',
  },
  mainArea: {
    display: 'flex',
    '& .operationArea': {
      position: 'absolute',
      top: '92px',
      left: '0px',
      width: '512px',
      height: '472x',
    },
    '& .sequenceArea': {
      position: 'absolute',
      top: '92px',
      left: '568px',
      width: '512px',
      height: '472x',
    },
  },
  item: {
    border: '1px solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    height: '472px',
    width: '512px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemRight: {
    borderColor: theme.rightStation.contentsBorder,
  },
  title: {
    textAlign: 'center',
    fontSize: '30px',
    height: '39px',
    margin: '24px 0px 0px 0px',
    fontWeight: 'bold',
  },
  buttonItem: {
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    fontSize: '28px',
    justifyContent: 'left',
    margin: '24px 0px 0px 0px',
    padding: '10px 20px 10px 40px',
    width: '432px',
  },
  buttonPink: {
    color: '#ffffff !important',
    background: `${theme.rightStation.contentsBorder} !important`,
    boxShadow: '0px 0px 0px 0px',
  },
  sequenceOperation: {
    alignItems: 'flex-start',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    flexDirection: 'column',
    height: '360px',
    justifyContent: 'flex-start',
    margin: '20px 0px 0px 0px',
    padding: '20px 0px 0px 24px',
    width: '432px',
  },
  sequenceImage: {
    '& img': {
      display: 'inline',
    },
  },
  sequenceOperationText: {
    height: '305px',
    width: '301px',
    marginLeft: '28px',
    fontSize: '21px',
    lineHeight: '36px',
    textAlign: 'left',
  },
}));

const GameConfTopJack: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = useState(false);

  // Redux
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);

  // Display Color Button Disable
  const displayColorButtonDisable = () => `${classes.buttonPink}`;

  // return button change
  const clickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_JACK_CONF_TOP, {
        selectConfId: 'none',
      }),
    );
  };

  const onClickToNewPayoutSupport = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_NEW_PAYOUT_SUPPORT);
  };

  const onClickToNewServiceMode = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_NEW_SERVICE_MODE);
  };

  const onClickToShiftManagement = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_SHIFT_OPERATION);
  };

  const onClickToSequenceSetting = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_SEQUENCE);
  };

  // 状態ボタン
  const onClickOk = async () => {
    // validationチェック及びセッション更新
    const newTempAreaConf = resetDescentLimitValueJack(areaConf);
    const http = new HttpConnection({ dispatch });

    if (!newTempAreaConf?.hasReset) {
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF);
    } else {
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });
      dispatch(setAreaConf(areaConfReset));
      setOpenConfirm(true);
    }

    if (operationConf) {
      dispatch(
        setOperationConf({
          ...operationConf,
          operationConf: COMMON.OPERATION_CONF.NO_CONF,
        }),
      );
    }
  };

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
  };

  const itemClass = `${classes.item} ${classes.itemRight}`;

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              <Box>
                <ReturnButton onClick={clickReturn}>設定TOP</ReturnButton>
              </Box>
            </Box>
            <Box className={classes.mainArea}>
              <Box className={`${itemClass} operationArea`}>
                <Box className={classes.title}>運 営 設 定</Box>
                <NormalButton
                  className={`${classes.buttonItem}`}
                  isSelected={
                    !operationConf?.operationConf || operationConf?.operationConf === COMMON.OPERATION_CONF.NO_CONF
                  }
                  disabled={
                    !operationConf?.operationConf || operationConf?.operationConf === COMMON.OPERATION_CONF.NO_CONF
                  }
                  selectedClass={displayColorButtonDisable()}
                  onClick={onClickOk}>
                  設定なし
                </NormalButton>
                <NormalButton
                  className={`${classes.buttonItem}`}
                  isSelected={operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT}
                  selectedClass={displayColorButtonDisable()}
                  onClick={onClickToNewPayoutSupport}>
                  プレイヤーサポート
                </NormalButton>
                <NormalButton
                  className={`${classes.buttonItem}`}
                  isSelected={operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE}
                  selectedClass={displayColorButtonDisable()}
                  onClick={onClickToNewServiceMode}>
                  新サービスモード
                </NormalButton>
                <NormalButton
                  className={`${classes.buttonItem}`}
                  isSelected={operationConf?.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION}
                  selectedClass={displayColorButtonDisable()}
                  onClick={onClickToShiftManagement}>
                  ずらし運営
                </NormalButton>
              </Box>
              <Box className={`${itemClass} sequenceArea`}>
                <Box className={classes.title}>シ ー ケ ン ス 設 定</Box>
                <Button variant="contained" className={classes.sequenceOperation} onClick={onClickToSequenceSetting}>
                  <Box className={classes.sequenceImage}>
                    <img src={downArrowIcon2} alt="DownArrowIcon2" />
                  </Box>
                  <Box className={classes.sequenceOperationText}>
                    X移動 <br />
                    Y移動 <br />
                    アームを開く <br />
                    下降（戻り時、必ず上昇） <br />
                    ずらし移動 <br />
                    アームを閉じる <br />
                    落とし口へ移動 <br />
                    アームを開閉 <br />
                    HPに戻る <br />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GameConfTopJack;
