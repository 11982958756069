import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PointerIcon from 'components/machineConf/atoms/icons/PointerIcon';
import { useAppSelector } from 'redux/hooks';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import COMMON from 'constants/common';

/**
 * XyConfInfoButton Component
 * XY移動範囲・HP設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// 型定義

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: 22,
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: '0 0px 0px 0',
  },
  borderLeft: {
    border: '2px solid',
    borderColor: theme.leftStation.itemBorder,
  },
  borderRight: {
    border: '2px solid',
    borderColor: theme.rightStation.itemBorder,
  },
  specifyArea: {
    display: 'flex',
    width: '182px',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    margin: '10px 10px 10px 15px',
    '& .inner': {
      position: 'relative',
    },
    '& .table': {
      position: 'relative',
      width: '180px',
      height: '158px',
    },
    '& .tableCols': {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
    '& .tableCol': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      background: '#ffffff',
    },
    '& .tableRows': {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 11,
    },
    '& .tableRow': {
      width: '100%',
      height: '30px',
      borderTop: 'none',
    },
    '& .tableRow:nth-child(1), .tableRow:nth-child(2)': {
      height: '19px',
    },
    '& .tableColLeft-1, & .tableColLeft-3': {
      borderRight: '1px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableColLeft-5': {
      borderRight: '2px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableRowLeft-2, & .tableRowLeft-4': {
      borderTop: '1px solid',
      borderColor: theme.leftStation.itemBorder,
    },
    '& .tableColRight-1, & .tableColRight-3': {
      borderRight: '1px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableColRight-5': {
      borderRight: '2px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableRowRight-2, & .tableRowRight-4': {
      borderTop: '1px solid',
      borderColor: theme.rightStation.itemBorder,
    },
    '& .line': {
      width: '30px',
      height: '30px',
    },
  },
  panel: {
    width: '180px',
    height: '199px',
    flexDirection: 'column',
    position: 'absolute',
    textAlign: 'center',
    top: '30px',
    left: '44px',
    zIndex: 50,
  },
  abs: {
    position: 'absolute',
    width: 30,
    height: 30,
    fontSize: '19px',
  },
  homeHr: {
    position: 'absolute',
    borderTop: 'dashed 2px #FF3E3E',
    width: '180px',
  },
  homeVertical: {
    position: 'absolute',
    borderLeft: '2px dashed #FF3E3E',
    width: '2px',
    height: '180px',
  },
  endHr: {
    position: 'absolute',
    borderTop: 'dashed 2px #3086FF',
    width: '178px',
  },
  endVertical: {
    position: 'absolute',
    borderLeft: 'dashed 2px #3086FF',
    width: '2px',
    height: '180px',
  },
  homeXValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    top: '221px',
    left: '61px',
  },
  homeYValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    top: '175px',
    left: '206px',
  },
  homeBg: {
    background: '#EEEEEE',
    border: '1px solid #FF3E3E',
    borderRadius: '2px',
  },
  endXValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  endYValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
  },
  endBg: {
    background: '#EEEEEE',
    border: '1px solid #3086FF',
    borderRadius: '2px',
  },
  homeVerticalRight: {
    position: 'absolute',
    left: '184px',
    zIndex: 10,
    border: '1px dashed #FF3E3E',
    width: '2px',
    height: '180px',
    top: '56px',
  },
  homeXValueRight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    top: '221px',
    left: '162px',
  },
  homeYValueRight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    top: '175px',
    left: '15px',
  },
  endXValueRight: {
    position: 'absolute',
  },
  endYValueRight: {
    position: 'absolute',
  },
  p: {
    marginLeft: '-3px',
    letterSpacing: '-2px',
  },
  hiddenCell: {
    width: 182,
    height: 65,
    background: '#EBEBEB',
    position: 'absolute',
    zIndex: 30,
    borderBottom: '2px solid #FF4386',
    overflow: 'hidden',
    textIndent: '-500px',
    left: 43,
  },
}));

type ButtonProps = {
  title: string;
  subtitle?: string;
  onClick?: () => void;
};

const MAX_CONTAINER = 180;
const MAX_CONTAINER_HEIGHT = 158;
const MAX_WIDTH_LINE = 25;
const MAX_WIDTH_BOX = 15;
const WIDTH_BOX = 30;
const X_Y_LIMIT_H = 5;
const WIDTH_BORDER_BOX = 2;
const MIN_LIMIT_X = 21;

// コンポーネント定義
const XyConfInfoButtonJack: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  const MAX_Y = 80;
  const MAX_AXIS_HOME_Y = Number(xySpeedLeverConf?.homeY) <= MAX_Y ? Number(xySpeedLeverConf?.homeY) : MAX_Y;
  const MAX_AXIS_END_Y = Number(xySpeedLeverConf?.endY) <= MAX_Y ? Number(xySpeedLeverConf?.endY) : MAX_Y;

  // イベント定義
  // エリアパネル 「列」を作成
  const tableCols = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(<Box className={`tableCol tableColRight-${index}`}>&nbsp;</Box>);
    }
    return item;
  };

  // エリアパネル 「行」を作成
  const tableRows = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(<Box className={`tableRow tableRowRight-${index}`}>&nbsp;</Box>);
    }
    return item;
  };

  // Hアイコン表示
  const hIcon = () => {
    const item: Array<JSX.Element> = [];

    const iconPos = {
      bottom: `${MAX_AXIS_HOME_Y}%`,
      left: `${Number(xySpeedLeverConf?.homeX)}%`,
    };
    item.push(
      <Box className={classes.abs} style={iconPos}>
        <Box style={{ marginTop: 15, marginLeft: -15 }}>
          <PointerIcon size="S" type="H" />
        </Box>
      </Box>,
    );

    return item;
  };
  // H座標値表示
  const hValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -MAX_WIDTH_BOX;
    let marginTop = -MAX_WIDTH_BOX;
    let marginLimitX = 0;
    if (xySpeedLeverConf) {
      const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
      const valueOverloadY = Math.abs(MAX_AXIS_HOME_Y - MAX_AXIS_END_Y);
      if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
        marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * 1.8;
      }
      if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
        const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
        if (pixelBetween < MAX_WIDTH_BOX) {
          marginLeft -= MAX_WIDTH_BOX - pixelBetween;
        }
      }
      if (valueOverloadY <= MAX_WIDTH_LINE) {
        const pixelBetween = (valueOverloadY * MAX_CONTAINER_HEIGHT) / 100 / 2;
        if (pixelBetween < MAX_WIDTH_BOX) {
          marginTop += MAX_WIDTH_BOX - pixelBetween;
        }
      }
    }
    const xValPos = {
      transform: `translate(${Number(xySpeedLeverConf?.homeX) * 1.8}px,199px`,
      left: marginLeft + marginLimitX,
      top: 9,
    };
    const yValPos = {
      top: `${100 - Number(MAX_AXIS_HOME_Y)}%`,
      left: `-${WIDTH_BOX + 9}px`,
      marginTop,
    };
    item.push(
      <Box>
        <Box className={classes.abs} style={xValPos}>
          <Box className={classes.homeBg}>
            <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
          </Box>
        </Box>
        <Box className={classes.abs} style={yValPos}>
          <Box className={classes.homeBg}>
            <p className={classes.p}>{MAX_AXIS_HOME_Y}</p>
          </Box>
        </Box>
      </Box>,
    );

    return item;
  };
  // H縦線表示
  const hVerLine = () => {
    const item: Array<JSX.Element> = [];

    const VarPos = {
      left: `${Number(xySpeedLeverConf?.homeX)}%`,
      top: 40,
      height: 155,
    };
    item.push(
      <Box className={classes.homeVertical} style={VarPos}>
        &nbsp;
      </Box>,
    );

    return item;
  };
  // H横線表示
  const hHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      bottom: `${MAX_AXIS_HOME_Y}%`,
      margin: 0,
    };
    item.push(<hr className={classes.homeHr} style={hrPos} />);
    return item;
  };
  // Eアイコン表示
  const eIcon = () => {
    const item: Array<JSX.Element> = [];

    const iconPos = {
      bottom: `${MAX_AXIS_END_Y}%`,
      left: `${Number(xySpeedLeverConf?.endX)}%`,
    };
    item.push(
      <Box className={classes.abs} style={iconPos}>
        <Box style={{ marginTop: 14, marginLeft: -14 }}>
          <PointerIcon size="S" type="E" />
        </Box>
      </Box>,
    );

    return item;
  };
  // E座標値表示
  const eValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -MAX_WIDTH_BOX;
    let marginTop = -MAX_WIDTH_BOX;
    let marginLimitX = 0;

    if (xySpeedLeverConf) {
      const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
      const valueOverloadY = Math.abs(MAX_AXIS_END_Y - MAX_AXIS_HOME_Y);
      if (
        xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
        xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
        valueOverloadX < MIN_LIMIT_X
      ) {
        marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * 1.8 + WIDTH_BORDER_BOX;
      }
      if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
        const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
        if (pixelBetween < MAX_WIDTH_BOX) {
          marginLeft += MAX_WIDTH_BOX - pixelBetween;
        }
      }
      if (valueOverloadY <= MAX_WIDTH_LINE) {
        const pixelBetween = (valueOverloadY * MAX_CONTAINER) / 100 / 2;
        if (pixelBetween < MAX_WIDTH_BOX) {
          marginTop -= MAX_WIDTH_BOX - pixelBetween;
        }
      }
    }

    const xValPos = {
      transform: `translate(${Number(xySpeedLeverConf?.endX) * 1.8}px, 199px`,
      left: marginLeft + marginLimitX,
      top: 9,
    };
    const yValPos = {
      marginTop,
      left: `-${WIDTH_BOX + 9}px`,
      top: `${100 - Number(MAX_AXIS_END_Y)}%`,
    };
    item.push(
      <Box>
        <Box className={classes.abs} style={xValPos}>
          <Box className={classes.endBg}>
            <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
          </Box>
        </Box>
        <Box className={classes.abs} style={yValPos}>
          <Box className={classes.endBg}>
            <p className={classes.p}>{MAX_AXIS_END_Y}</p>
          </Box>
        </Box>
      </Box>,
    );

    return item;
  };
  // E縦線表示
  const eVerLine = () => {
    const item: Array<JSX.Element> = [];

    const VarPos = {
      top: 40,
      height: 155,
      left: `${Number(xySpeedLeverConf?.endX)}%`,
    };
    item.push(
      <Box className={classes.endVertical} style={VarPos}>
        &nbsp;
      </Box>,
    );

    return item;
  };

  // E横線表示
  const eHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px,${MAX_AXIS_END_Y}%)`,
      bottom: `${MAX_AXIS_END_Y}%`,
      margin: 0,
    };
    item.push(<hr className={classes.endHr} style={hrPos} />);
    return item;
  };
  // HPアイコン表示
  const customHpIcon = () => {
    const item: Array<JSX.Element> = [];
    const customY = Number(xySpeedLeverConf?.customY) <= 80 ? Number(xySpeedLeverConf?.customY) : 80;
    if (xySpeedLeverConf?.customConf === COMMON.CUSTOM_HOME_POSITION.CUSTOM) {
      const iconPos = {
        bottom: `${customY}%`,
        left: `${Number(xySpeedLeverConf?.customX)}%`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <Box style={{ marginTop: 14, marginLeft: -14 }}>
            <PointerIcon size="S" type="HP" />
          </Box>
        </Box>,
      );
    }
    return item;
  };

  // propsのプロパティ情報を取得
  const { title, subtitle, onClick } = props;

  return (
    <NormalButton variant="contained" className={classes.button} onClick={onClick}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`} top="-9px">
          {title}
          {subtitle ? <span>{subtitle}</span> : ''}
        </Box>

        <Box className={`${classes.child} customTable`}>
          <Box className={classes.specifyArea}>
            <Box className={classes.borderRight}>
              <Box className="inner">
                <Box className="table">
                  <Box className="tableCols">{tableCols(5)} </Box>
                  <Box className="tableRows">{tableRows(5)}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.panel}>
          {eHrLine()}
          {hHrLine()}
          {eVerLine()}
          {hVerLine()}
          {eValue()}
          {hValue()}
          {hIcon()}
          {customHpIcon()}
          {eIcon()}
        </Box>
      </Box>
    </NormalButton>
  );

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(XyConfInfoButtonJack);
