import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NumberButtonType } from 'types/support/supportType';

/**
 * NumberButton Component
 * 閉じるボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  contained: {
    color: theme.palette.common.black,
    borderRadius: '16px',
    background: '#D9D964',
    boxShadow: '2px 2px 1px 0px rgba(196, 196, 196, 0.5)',
    '&:hover': {
      background: '#D9D964',
      boxShadow: '2px 2px 1px 0px rgba(196, 196, 196, 0.5)',
    },
    '&:active': {
      background: '#FFFF80',
    },
  },
  defaultStyle: {
    fontSize: '70px',
    lineHeight: '84px',
    height: '84px',
    width: '84px',
  },
}));

// コンポーネント定義
const NumberButton: React.FC<NumberButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { className, value, onNumberClick } = props;

  const styleClass = `${className || classes.defaultStyle}`;

  return (
    <Button
      variant="contained"
      classes={{
        contained: classes.contained,
      }}
      className={styleClass}
      disableRipple
      onClick={onNumberClick(value)}>
      {value}
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
NumberButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(NumberButton);
