import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import backAllowIcon from 'assets/images/backAllowIcon.svg';
import { BaseButtonType } from 'types/system/buttonType';

/**
 * ReturnButton Component
 * 戻るボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '70px',
    paddingRight: 0,
    width: '200px',
    textAlign: 'left',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: theme.base.gray,
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  defaultButtonClass: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  caption: {
    marginLeft: '18px',
    width: '100%',
  },
}));

// コンポーネント定義
const ReturnButton: React.FC<BaseButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { className, disabled, fullWidth, onClick, children } = props;

  return (
    <Button
      variant="contained"
      className={`${classes.button} ${className || classes.defaultButtonClass}`}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}>
      <img src={backAllowIcon} alt="戻る" />
      <p className={classes.caption}>{children}</p>
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
ReturnButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(ReturnButton);
