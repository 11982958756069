const redirectLinks = {
  BASE: '/',

  // LOGIN
  LOGIN: '/login',
  PW_CONF: '/login/password-conf',

  // HOME
  HOME_TOP: '/home/top',
  HOME_MACHINE_STATUS_LIST: '/home/machinestatus/list',

  // DATA
  DATA_STATION_LIST: '/data/station/list/:prevScreen',
  DATA_SALES_VIEW: '/data/sales/view/:prevScreen', // SCR221 売上データ閲覧
  DATA_COST_RATE_VIEW: '/data/cost-rate/view/:prevScreen',
  DATA_STATION_GENERATE_DATA_SHEET: '/data/station/generate-data-sheet', // SCR224

  // MACHINE_CONF (clena3)
  MCONF_STATION_SELECT: '/machine-conf/station/list',
  MCONF_STATION_HOME: '/machine-conf/station/home/:stationId',
  MCONF_STATION_HOME_DETAIL: '/machine-conf/station/home/detail/:selectConfId',
  MCONF_STATION_CONF_TOP: '/machine-conf/station/conf/top:selectConfId',
  MCONF_STATION_CONF_LEVER_OPERATION: '/machine-conf/station/conf/lever-operation',
  MCONF_STATION_CONF_GAME_CONF_TOP: '/machine-conf/station/conf/game-conf-top',
  MCONF_STATION_CONF_XY_PIT_AREA_TOP: '/machine-conf/station/conf/xy-pit-area-top',
  MCONF_STATION_CONF_XY_RANGE_CONF: '/machine-conf/station/conf/xy-range-conf', // SCR323 xy範囲設定
  MCONF_STATION_CONF_CATCHER_SPEED_CONF: '/machine-conf/station/conf/catcher-speed',
  MCONF_STATION_CONF_SHIFT_OPERATION: '/machine-conf/station/conf/shift-operation',
  MCONF_STATION_CONF_NEW_PAYOUT_SUPPORT: '/machine-conf/station/conf/new-payout-support',
  MCONF_STATION_CONF_NEW_SERVICE_MODE: '/machine-conf/station/conf/new-service-mode',
  MCONF_STATION_CONF_SEQUENCE: '/machine-conf/station/conf/sequence',
  MCONF_STATION_CONF_AREA_POWER_CONF: '/machine-conf/station/conf/area-power-conf',
  MCONF_STATION_CONF_LED: '/machine-conf/station/conf/led',
  MCONF_STATION_CONF_COST_SERVICE: '/machine-conf/station/conf/cost-service',
  MCONF_STATION_CONF_OTHER: '/machine-conf/station/conf/other',
  MCONF_STATION_CONF_AREA_CONF: '/machine-conf/station/conf/area-conf',
  MCONF_STATION_CONF_PIT_CONF: '/machine-conf/station/conf/pit-conf',
  MCONF_STATION_CONF_LEVER_CONF: '/machine-conf/station/conf/xy-range-conf',
  MCONF_STATION_CONF_SAVED_CONF: '/machine-conf/station/conf/saved-conf',

  // MACHINE_CONF (clena3-jack)
  MCONF_JACK_CONF_TOP: '/machine-conf/jack/conf/top:selectConfId', // SCR312J
  MCONF_JACK_HOME_DETAIL: '/machine-conf/jack/home/detail/:selectConfId', // SCR311
  MCONF_JACK_CONF_XY_PIT_AREA_TOP: '/machine-conf/jack/conf/xy-pit-area-top', // SCR321J
  MCONF_JACK_CONF_PIT_CONF: '/machine-conf/jack/conf/pit-conf', // SCR322J
  MCONF_JACK_CONF_XY_RANGE_CONF: '/machine-conf/jack/conf/xy-range-conf', // SCR323J
  MCONF_JACK_CONF_CATCHER_SPEED_CONF: '/machine-conf/jack/conf/catcher-speed', // SCR324J
  MCONF_JACK_CONF_AREA_POWER_CONF: '/machine-conf/jack/conf/area-power-conf', // SCR325J
  MCONF_JACK_CONF_LEVER_OPERATION: '/machine-conf/jack/conf/lever-operation', // SCR327J
  MCONF_JACK_CONF_GAME_CONF_TOP: '/machine-conf/jack/conf/game-conf-top', // SCR331J
  MCONF_JACK_CONF_SHIFT_OPERATION: '/machine-conf/jack/conf/shift-operation', // SCR332J
  MCONF_JACK_CONF_NEW_PAYOUT_SUPPORT: '/machine-conf/jack/conf/new-payout-support', // SCR333J
  MCONF_JACK_CONF_NEW_SERVICE_MODE: '/machine-conf/jack/conf/new-service-mode', // SCR334J
  MCONF_JACK_CONF_SEQUENCE: '/machine-conf/jack/conf/sequence', // SCR335J
  MCONF_JACK_CONF_COST_SERVICE: '/machine-conf/jack/conf/cost-service', // SCR341J
  MCONF_JACK_CONF_LED: '/machine-conf/jack/conf/led', // SCR351J
  MCONF_JACK_CONF_OTHER: '/machine-conf/jack/conf/other', // SCR361J
  MCONF_JACK_CONF_AREA_CONF: '/machine-conf/jack/conf/area-conf', // SCR326J

  // GROUP
  GROUP_CONF_TOP: '/group/top',
  GROUP_LIST: '/group/list',
  GROUP_SELECT_CONF: '/group/category-select',
  GROUP_LIST_DATA: '/group/list/data',
  GROUP_LED_CONF: '/group/LED-select',
  GROUP_GET_GIFT_GROUP_CREATE: '/group/get-gift/group-create',
  // GROUP_LED_GROUP_CREATE: '/',
  GROUP_GET_GIFT_SELECT_REGISTER_MACHINE: '/group/get-gift/select-register-machine',
  GROUP_GET_GIFT_INTERLOCKING_ORDER_SETTING: '/group/get-gift/interlocking-order-setting',
  GROUP_LED_GROUP_CREATE: '/group/led/group-create',
  GROUP_LED_SELECT_REGISTER_MACHINE: '/group/led/select-register-machine',
  GROUP_LED_INTERLOCKING_ORDER_SETTING: '/group/led/interlocking-order-setting',

  // MAINTENANCE
  MAINT_TOP: '/maintenance/top',
  MAINT_MACHINE_LIST: '/maintenance/machine/list',
  MAINT_USER_LIST: '/maintenance/user/list/:isFiltered',
  MAINT_USER_DETAIL_NEW: '/maintenance/user/detail',
  MAINT_USER_DETAIL_EDIT: '/maintenance/user/detail/:userId',
  MAINT_GIFT_LIST: '/maintenance/gift/list',
  MAINT_MACHINE_DETAIL: '/maintenance/machine/detail',
  GIFT_DETAIL: '/maintenance/gift/detail',
  SELECT_STATION_LIST_FOR_GIFT_REGISTER: '/maintenance/gift/select-station-list-for-gift-register',

  // COMMON
  COMMON_SYSTEM_ERROR: '/error',
  COMMON_SESSION_TIMEOUT_ERROR: '/session-timeout',
  COMMON_AUTH_CHECK: '/auth-check',

  // MANUAL NOTICE
  MANUAL_NOTICE: '/manual-notice',
  OPERATION_EXAMPLE_LIST: '/operation-example-list',
  OPERATION_EXAMPLE_PDF: '/operation-example-pdf',
};

export default redirectLinks;
