import React, { memo, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

/* ************ Style ************ */
const useStyles = makeStyles(() =>
  createStyles({
    selectedClass: {
      backgroundColor: '#F4FF8A !important',
    },
  }),
);

/* ************ Component ************ */
const HourlyBtn: React.FC<{
  value: string;
  exClassName?: string;
  isSelected?: boolean;
  disabled?: boolean;
  onClick?: (isSelected: boolean, value: string) => void;
}> = memo(({ value, exClassName, isSelected = false, disabled = false, onClick, children }) => {
  const classes = useStyles();
  const [isSelected_, setIsSelected] = useState<boolean>(isSelected);
  useEffect(() => {
    setIsSelected(isSelected);
  }, [isSelected]);

  /* ************ Event ************ */
  const handleClickHourlyBtn = () => {
    if (onClick) {
      onClick(!isSelected_, value);
    }
    setIsSelected(!isSelected_);
  };

  return (
    <NormalButton
      onClick={handleClickHourlyBtn}
      className={exClassName}
      selectedClass={classes.selectedClass}
      isSelected={isSelected_}
      disabled={disabled}>
      {children}
    </NormalButton>
  );
});

export default HourlyBtn;
