import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { IManualType } from 'types/manualInformation/manualInformationType';
import HttpConnection from '../../../utils/httpConnection';
import { REST_API } from '../../../constants/apiUrls';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ConfirmDialog from '../../../components/system/organisms/dialogs/ConfirmDialog';
import MsgHelper from '../../../utils/message.helper';
import MSG from '../../../constants/messages';
import { basename } from '../../../utils/common.helper';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    height: 272,
    border: '1px solid #707070',
    borderRadius: 4,
    marginTop: 4,
  },
  header: {
    padding: '5px 10px',
    width: '100%',
    height: 33,
    background: '#535B80',
    fontSize: 19,
    color: '#fff',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
  },
  informationText: {
    fontSize: 20,
    marginBottom: 5,
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5,
  },
  informationTextDate: {
    fontSize: 20,
    marginRight: 20,
  },
  informationContainer: {
    margin: '14px 25px',
    height: 214,
    overflowY: 'auto',
  },
  textContain: {
    display: 'table',
    lineHeight: 2,
  },
  textItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentText: { fontSize: '20px' },
}));

/* ************ Component ************ */
const Manual: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { manuals } = useAppSelector((state) => state.manualInformation);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [manualSelected, setManualSelected] = React.useState<IManualType>();

  /* ************ use effect ************ */
  useEffect(() => {
    // Get manual public
  }, []);

  const handleShowDialogDownload = useCallback((item: IManualType) => {
    setManualSelected(item);
    setOpenInfoDialog(true);
  }, []);

  const renderManualItem = useCallback(() => {
    if (manuals.length === 0) return <></>;
    const manualInfo = manuals.map((item) => (
      <div key={item.id} className={classes.textContain} onClick={() => handleShowDialogDownload(item)}>
        <div className={classes.textItem}>
          <div>• </div>
          <div className={classes.informationText}>{item.title}</div>
        </div>
      </div>
    ));
    return <div className={classes.informationContainer}>{manualInfo}</div>;
  }, [manuals]);

  const handleDownloadFile = useCallback(async () => {
    if (manualSelected?.path === undefined) return;
    const http = new HttpConnection({ dispatch });
    const data = await http.getFile(`${REST_API.MANUAL_NOTICE.DOWNLOAD_MANUAL}/${manualSelected?.path}`);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', basename(manualSelected?.path) as string);
    document.body.appendChild(link);
    link.click();
    setOpenInfoDialog(false);
  }, [manualSelected]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>マニュアル一覧</div>
      {renderManualItem()}
      <ConfirmDialog
        open={openInfoDialog}
        msg={MsgHelper.messageFormat(MSG.INF538, manualSelected ? manualSelected?.title : '')}
        onOk={handleDownloadFile}
        onCancel={() => setOpenInfoDialog(false)}
        addClass={{
          contentText: classes.contentText,
        }}
      />
    </div>
  );
};

export default Manual;
