import React, { memo } from 'react';
import { NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import COMMON from 'constants/common';

/**
 * SelectBox Component
 * セレクトボックス
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  selectBox: {
    color: theme.palette.common.black,
    border: '1px solid #707070',
    borderRadius: '4px',
    background: '#FFFFFF',
    height: '36px',
    float: 'right',
  },
  szS: {
    width: '200px',
    fontSize: '16px',
    padding: '4px',
  },
  szM: {
    width: '220px',
    fontSize: '17px',
    padding: '8px',
  },
}));

/* ************ Type ************ */
// データソース
type dataSourceProps = {
  name: string;
  value: string;
};

// パラメータ
type Props = {
  disabled?: boolean;
  dataSource: Array<dataSourceProps>;
  name: string;
  fullWidth?: boolean;
  placeholder?: string;
  size?: string;
  value?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
};

// コンポーネント定義
const SelectBox: React.FC<Props> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // プロパティ情報を取得
  const { dataSource, disabled, fullWidth, name, size, value, onChange, onClick } = props;

  // スタイル制御
  let sizeClass = '';
  switch (size) {
    case COMMON.SIZE.SMALL:
      sizeClass = classes.szS;
      break;
    case COMMON.SIZE.MEDIUM:
      sizeClass = classes.szM;
      break;
    default:
      sizeClass = classes.szM;
  }

  // 描画
  return (
    <NativeSelect
      className={`${classes.selectBox} ${sizeClass}`}
      disabled={disabled}
      fullWidth={fullWidth}
      name={name}
      value={value}
      variant="outlined"
      onChange={onChange}
      onClick={onClick}>
      {dataSource.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
      ;
    </NativeSelect>
  );
};

// propsの初期値。props未指定時に使用する。
SelectBox.defaultProps = {
  disabled: false,
  fullWidth: false,
  size: 'szM',
  value: '',
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(SelectBox);
