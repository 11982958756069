import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButtonType } from 'types/system/buttonType';
import NormalButton from './NormalButton';

/**
 * DoneButton Component
 * 確定ボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '8px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
    '&:disabled': {
      color: '#fff',
      background: '#000',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  defaultStyle: {
    fontSize: '40px',
    lineHeight: '28px',
    height: '100px',
    width: '180px',
  },
  caption: {
    width: '100%',
  },
}));

// コンポーネント定義
const ReturnButton: React.FC<BaseButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { className, disabled, fullWidth, onClick, children } = props;

  return (
    <NormalButton
      variant="contained"
      baseColor="black"
      className={`${classes.button} ${className || classes.defaultStyle}`}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}>
      <p className={classes.caption}>{children}</p>
    </NormalButton>
  );
};

// propsの初期値。未指定時に使用する。
ReturnButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(ReturnButton);
