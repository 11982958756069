import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from 'assets/images/InfoIcon.svg';
import NormalButton from 'components/system/atoms/buttons/NormalButton';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '640px',
  },
  content: {
    alignItems: 'start',
    display: 'flex',
    padding: '40px 8px 0px 40px ',
  },
  contentText: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '8px 0px 0px 24px',
    fontSize: '21px',
  },
  contentTextsub: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '8px 0px 0px 24px',
    fontSize: '21px',
    color: '#F5EF43',
  },
  contentTextdetail: {
    color: theme.palette.common.white,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    margin: '8px 0px 0px 24px',
    fontSize: '21px',
    padingLeft: 8,
    marginTop: '20px',
    marginBottom: '20px',
  },
  action: {
    padding: '0px 40px 16px 40px ',
    display: 'flex',
    justifyContent: 'space-around',
  },
  detailmsg: {
    fontSize: 24,
    padingLeft: 8,
  },
  buttonIcon: {
    height: 80,
    width: 155,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
  },
  buttonCancel: {
    backgroundColor: '#BCBCBC',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#BCBCBC',
    },
  },
  buttonForceIcon: {
    height: 80,
    width: 145,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonForce: {
    backgroundColor: '#FFFF4A',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#FFFF4A',
    },
  },
}));

type DialogProps = {
  open: boolean;
  onForce: () => void;
  forceBtnLabel?: string;
  onOk: () => void;
  okBtnLabel?: string;
  onCancel: () => void;
  cancelBtnLabel?: string;
};

const ConfSendDialog: React.FC<DialogProps> = ({
  open,
  // BNAL-287対応 強制反映ボタンの非表示
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onForce,
  // BNAL-287対応 強制反映ボタンの非表示
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  forceBtnLabel = '強制反映',
  onOk,
  okBtnLabel = '送信',
  onCancel,
  cancelBtnLabel = '戻る',
}) => {
  const classes = useStyles();
  const msg = '設定を筐体に送信します。\n筐体での設定受け取りを行ってください。';
  const submsg = '受け取り操作を行うまでは、設定は反映されません。';
  // BNAL-287対応 強制反映ボタンの非表示
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const detailmsg =
    '※緊急で反映させたい場合は、\n「強制反映」を選択してください。\nクレジットが０の時点で設定が強制反映されます。';

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <img src={InfoIcon} alt="Info" />
        <div>
          <DialogContentText classes={{ root: classes.contentText }}>{msg}</DialogContentText>
          <DialogContentText classes={{ root: classes.contentTextsub }}>{submsg}</DialogContentText>
          {/* // BNAL-287対応 強制反映ボタンの非表示 */}
          {/* <DialogContentText classes={{ root: classes.contentTextdetail }}>{detailmsg}</DialogContentText> */}
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.action,
        }}>
        {/* // BNAL-287対応 強制反映ボタンの非表示 */}
        {/* <NormalButton onClick={onForce} className={`${classes.buttonForceIcon} ${classes.buttonForce}`}>
          {forceBtnLabel}
        </NormalButton> */}
        <NormalButton onClick={onOk} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {okBtnLabel}
        </NormalButton>
        <NormalButton onClick={onCancel} className={`${classes.buttonIcon} ${classes.buttonCancel}`}>
          {cancelBtnLabel}
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfSendDialog;
