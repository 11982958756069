import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contents: {
    backgroundColor: theme.palette.common.black,
    '& .detail': {
      height: '720px',
      width: '1280px',
      backgroundColor: theme.palette.common.white,
    },
  },
  contentsDetail: {
    height: '720px',
    width: '1280px',
  },
}));

const ContentWithoutTitle: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.contents}>
      <Box className={classes.contentsDetail}>
        <Box className="detail">{children}</Box>
      </Box>
    </Box>
  );
};

export default ContentWithoutTitle;
