// moment.js is deprecated.

export const formatDate = (date: Date, separator = '/', zeroFilled = true): string => {
  let zeroFill = '';
  if (zeroFilled) zeroFill = '0';
  const year = date.getFullYear();
  const month = `${zeroFill}${date.getMonth() + 1}`.slice(-2);
  const day = `${zeroFill}${date.getDate()}`.slice(-2);

  return `${year}${separator}${month}${separator}${day}`;
};

export const formatDateWithDayOfWeek = (date: Date, separator = '/', withYear = false, type = 0): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const DayOfWeek = getDayOfWeek(date, type);

  if (withYear) {
    return `${year}${separator}${month}${separator}${day} (${DayOfWeek})`;
  }
  return `${month}${separator}${day} (${DayOfWeek})`;
};

// 日付の文字変換
export const formatDatetime = (date: Date, separator = '/', withSeconds = false): string => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const hour = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);

  if (withSeconds) {
    return `${year}${separator}${month}${separator}${day} ${hour}:${minutes}:${seconds}`;
  }
  return `${year}${separator}${month}${separator}${day} ${hour}:${minutes}`;
};

// 日付の文字変換
export const formatDatetimeForList = (date: Date): string[] => {
  const separator = '/';
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);

  return [`${month}${separator}${day}`, `${hour}:${minutes}`];
};

// 時間の文字変換(日時→時間)
export const formatTime = (date: Date, separator = ':', withSeconds = false): string => {
  const hour = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);

  if (withSeconds) {
    return `${hour}${separator}${minutes}${separator}${seconds}`;
  }
  return `${hour}${separator}${minutes}`;
};

export const getDayOfWeek = (date: Date, type: number): string => {
  const jp = ['日', '月', '火', '水', '木', '金', '土'];
  const en = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dow = date.getDay();

  return type === 0 ? jp[dow] : en[dow];
};

// 月初めの日付を取得
export const getStartOfMonthDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);

// 週初めの日付を取得
export const getStartOfWeekDate = (date: Date): Date => {
  const dow = date.getDay();
  date.setDate(date.getDate() - dow);
  return date;
};

export const subDay = (date1: Date, date2: Date): number => (+date1 - +date2) / 86400000;

// {days}日前
export function subDays(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate;
}

// 同日判定
export const isSameDay = (date1?: Date | string, date2?: Date | string): boolean => {
  if (!(date1 && date2)) {
    return false;
  }
  let [d1, d2] = [date1, date2];
  if (typeof d1 === 'string') d1 = new Date(d1);
  if (typeof d2 === 'string') d2 = new Date(d2);
  if (d1.getFullYear() !== d2.getFullYear()) {
    return false;
  }
  if (d1.getMonth() !== d2.getMonth()) {
    return false;
  }
  if (d1.getDate() !== d2.getDate()) {
    return false;
  }
  return true;
};
