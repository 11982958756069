import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import MaintenanceTopButton from 'components/maintenance/atoms/buttons/MaintenanceTopButton';
import { getUserAuthThunk } from 'redux/slices/commonSlice';
import UrlHelper from 'utils/url.helper';
import { actIsCrateGroup } from 'redux/slices/activeCrateGroupSlice';
import { IS_FILTERED } from './UserList';
import CLSFCN from '../../constants/classification';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: '管理登録TOP',
  screenId: 'SCR511',
  currentMenu: COMMON.MENU.MAINTENANCE,
  initializeState: true,
};

const MaintenaceTop: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const disabledButtons = userInfo?.currentHeadQId == null || userInfo?.currentStoreId == null;
  const { ROLE } = CLSFCN;

  /* ************ Event ************ */
  // Nox. ページ読み込み
  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  const handleClickDeviceRegist = () => {
    dispatch(actIsCrateGroup(false));
    history.push(redirectLinks.MAINT_MACHINE_LIST);
  };
  const handleClickIssueId = () => {
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MAINT_USER_LIST, { isFiltered: IS_FILTERED.FALSE }));
  };
  const handleClickPremiumConf = () => {
    history.push(redirectLinks.MAINT_GIFT_LIST);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MaintenanceTopButton
          disabled={disabledButtons || [ROLE.HEAD_VIEWER, ROLE.STORE_VIEWER].includes(userInfo?.role ?? '')}
          onClick={handleClickDeviceRegist}
          iconName="iconPrizeMachine"
        />
        <MaintenanceTopButton disabled={disabledButtons} onClick={handleClickIssueId} iconName="iconPerson" />
        <MaintenanceTopButton
          disabled={disabledButtons || [ROLE.HEAD_VIEWER, ROLE.STORE_VIEWER].includes(userInfo?.role ?? '')}
          onClick={handleClickPremiumConf}
          iconName="iconBear"
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default MaintenaceTop;
