import React, { useCallback, useEffect, useRef, useState } from 'react';
import MsgHelper from 'utils/message.helper';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Grid, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import CommonButton from 'components/machineConf/atoms/buttons/CommonButton';
import AreaConfButton from 'components/machineConf/atoms/buttons/AreaConfButton';
import GameConfButton from 'components/machineConf/atoms/buttons/GameConfButton';
import LedConfButton from 'components/machineConf/atoms/buttons/LedConfButton';
import CostServiceConfButton from 'components/machineConf/atoms/buttons/CostServiceConfButton';
import OtherConfButton from 'components/machineConf/atoms/buttons/OtherConfButton';
import {
  getSelectStationConfDataThunk,
  saveConfThunk,
  sendConfThunk,
  setMachineConf,
} from 'redux/slices/machineConfSlice';
import MESSAGES from 'constants/messages';
import InfoDialog, { InfoDialogProps } from 'components/system/organisms/dialogs/InfoDialog';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import Layout from 'components/system/layouts/Layout';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { ConfIdtype, MachineConfType } from 'types/machineConf/machineConfType';
import { handleLeftRightInversion } from 'utils/machineConf.helper';
import ConfSendDialog from 'components/machineConf/atoms/dialog/ConfSendDialog';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import ConfSaveDialog from 'components/machineConf/atoms/dialog/ConfSaveDialog';
import { StationSerialType } from 'types/machineConf/stationSelectType';
import { unwrapResult } from '@reduxjs/toolkit';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: 'ステーション設定TOP',
  screenId: COMMON.SCREEN_ID.CONF_TOP_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  description: {
    padding: '0 0 36px 24px',
    fontSize: '23px',
  },
  decision: {
    textAlign: 'right',
    marginTop: '16px',
  },
  leftButtonArea: {
    display: 'block',
    float: 'left',
  },
  confArea: {
    float: 'left',
  },
  returnArea: {
    display: 'block',
    padding: '0px 0px 72px 24px',
    margin: '18px 0px 0px 0px',
  },
  otherArea: {
    padding: '0px 0px 32px 24px',
  },
}));

/* ************ Constant ************ */
// messages
const MSG301: string = MsgHelper.messageFormat(MESSAGES.INF301, '設定');
const CONF_NAME_MAX = 50;

/* ************ Type ************ */
type execDivType = {
  execDiv: string;
};

const StationConfTopJack: React.FC = () => {
  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  /* ************ state/redux ************ */
  // 選択ステーション情報
  // const { selectConfId } = useParams<{ selectConfId: string }>();
  const { selectConfId } = useParams<ConfIdtype>();
  let execDiv = (location.state as execDivType)?.execDiv;
  const { selectedStation, machineConf, selectedStationList } = useAppSelector((state) => state.machineConf);
  const [openConfSaveDialog, setOpenConfSaveDialog] = useState(false);
  const [openConfSendDialog, setOpenConfSendDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openSaveConfInfoDialog, setOpenSaveConfInfoDialog] = useState(false);
  const [openSendConfInfoDialog, setOpenSendConfInfoDialog] = useState(false);
  const [openSendConfForceInfoDialog, setOpenSendConfForceInfoDialog] = useState(false);
  const [infoDialogSaveConf, setInfoDialogSaveConf] = useState({
    open: false,
    msg: '',
  });

  const [infoDialog, setInfoDialog] = useState<InfoDialogProps>({
    open: false,
    msg: '',
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [saveConfName, setSaveConfName] = React.useState('');
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);

  // 初期処理
  useEffect(() => {
    if (selectConfId !== 'none') {
      if (execDiv === COMMON.EXEC_DIV.SAVED_CONF) {
        dispatch(
          getSelectStationConfDataThunk({
            selectConfId,
            execDiv: COMMON.EXEC_DIV.SAVED_CONF,
            leftRight: selectedStation.leftRight ?? '',
            boardStation: selectedStation.boardStation,
          }),
        );
        // 初期化
        execDiv = COMMON.EXEC_DIV.NORMAL;
      } else {
        dispatch(
          getSelectStationConfDataThunk({
            selectConfId,
            execDiv: COMMON.EXEC_DIV.NORMAL,
            leftRight: selectedStation.leftRight ?? '',
          }),
        );
      }
    }
  }, [selectConfId]);

  const processing = useRef(false);

  /* ************ Event ************ */
  const dispatch = useAppDispatch();

  const handleClickAreaConf = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };
  const handleClickGameConf = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
  };
  const handleClickLedConf = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_LED);
  };
  const handleClickCostServiceConf = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_COST_SERVICE);
  };
  const handleClickOtherConf = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_OTHER);
  };

  // ステーション一覧(戻る)ボタン
  const handleclickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_SELECT);
  };

  // 設定を保存ボタン
  const handleclickConfSave = () => {
    setOpenConfSaveDialog(true);
  };

  // convert to half using normalize
  function convertToHalf(value: string) {
    return value.normalize('NFKC');
  }

  // 設定保存タイトルの変更
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = convertToHalf(e.target.value);
    const checkSpace = value.trim();
    const spaceLength = value.length;

    if (checkSpace === '' && spaceLength !== 0) {
      setSaveConfName('invalid');
    } else {
      setSaveConfName(e.target.value);
    }
  };

  // 設定を保存押下
  const handleOnClickOk = async (e: React.FormEvent) => {
    e.preventDefault();

    // 二重登録防止
    if (processing.current) return;
    processing.current = true;

    // 入力チェック
    let leftRight: string | undefined = '';
    if (!saveConfName) {
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF103, 'タイトル'),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else if (saveConfName === 'invalid') {
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF105),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else if (CONF_NAME_MAX < saveConfName.length) {
      // 文字数チェック
      setInfoDialog({
        open: true,
        msg: MsgHelper.messageFormat(MESSAGES.INF111, CONF_NAME_MAX.toString()),
        closeFunc: () => setInfoDialog({ open: false, msg: '' }),
      });
    } else {
      leftRight = selectedStation.leftRight;
      const saveConf = await dispatch(
        saveConfThunk({
          saveConfName,
          machineConf,
          leftRight,
          boardStation: selectedStation.boardStation,
          machineType: selectedStation.machineType,
          machineVersion: selectedStation.machineVersion,
        }),
      );

      const resultSaveConf = unwrapResult(saveConf);
      if (resultSaveConf && typeof resultSaveConf !== 'number' && !resultSaveConf.success) {
        setInfoDialogSaveConf({
          open: true,
          msg: resultSaveConf.errors[0][0],
        });
      } else {
        setOpenConfSaveDialog(false);
        setOpenSaveConfInfoDialog(true);
        setSaveConfName('');
      }
    }
    // 二重登録防止
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 2000);
  };

  // 保存設定一覧ボタン
  const handleClickSaveConf = useCallback(() => {
    history.push(redirectLinks.MCONF_STATION_CONF_SAVED_CONF);
  }, []);

  const handleCloseConfSaveDialog = (): void => {
    setOpenConfSaveDialog(false);
  };

  // はじめから設定するボタン
  const InitialconfId = {
    confId: COMMON.MACHINE_CONF.INITIALIZE_CONF_ID_JACK,
    execDiv: COMMON.EXEC_DIV.NORMAL,
    leftRight: selectedStation.leftRight,
  };
  const handleClickinitializeConf = async () => {
    const http = new HttpConnection({ dispatch });
    const tmpConf = await http.get<MachineConfType>(REST_API.MACHINE_CONF.GET_CONF_DATA, InitialconfId);
    // 現在のstationId
    const prevStationId = machineConf.conf?.stationId;
    // 既にグローバルステートの中にstationIdがある場合は、stationIdを上書きしない
    if (prevStationId) {
      if (tmpConf.conf) {
        tmpConf.conf.stationId = prevStationId;
      }
    }
    // 左右ステーション対応
    if (selectedStation.leftRight !== tmpConf.conf?.leftRight) {
      const inverConf = handleLeftRightInversion(tmpConf);
      dispatch(setMachineConf(inverConf));
    } else {
      dispatch(setMachineConf(tmpConf));
    }
    setOpenInfoDialog(true);
  };

  // 設定を筐体に送信ボタン
  const handleOnClickDecision = async () => {
    const listLength: number = selectedStationList.length;
    const List = {
      List: Array<string>(),
      VersionNo: Array<string>(),
    };
    // versionNoでの突合用
    const versionList: Array<{ stationId: number; versionNo: number | null }> = [];

    // 受取待機中のステーション数を確認、あわせて排他制御も実施
    for (let i = 0; i < listLength; i += 1) {
      if (selectedStationList[i].selectStation === true) {
        versionList.push({
          stationId: selectedStationList[i].stationId,
          versionNo: selectedStationList[i].versionNo,
        });
        List.List.push(String(selectedStationList[i].stationId));
        List.VersionNo.push(String(selectedStationList[i].versionNo));
      }
    }

    const http = new HttpConnection({ dispatch });
    // 排他制御
    await http.get(REST_API.MACHINE_CONF.GET_CONF_VERSION, { versionList });
    // 受取待機中ステーション数の確認
    const ListCount = await http.get<MachineConfType>(REST_API.MACHINE_CONF.GET_CONF_RECEIVE, {
      List,
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
    });
    if (ListCount > 0) {
      setOpenConfirmDialog(true);
    } else {
      setOpenConfSendDialog(true);
    }
  };
  // 設定受取中のステーションが含まれていた場合
  const handleClickOk = async (): Promise<void> => {
    setOpenConfirmDialog(false);
    setOpenConfSendDialog(true);
  };
  // 強制反映
  const handleOnClickSendForce = async (): Promise<void> => {
    // 二重登録防止
    if (processing.current) return;
    processing.current = true;

    // 選択ステーションの機器番号取得
    const listLength: number = selectedStationList.length;
    const List = {
      List: Array<string>(),
    };
    for (let i = 0; i < listLength; i += 1) {
      if (selectedStationList[i].selectStation === true) {
        List.List.push(String(selectedStationList[i].stationId));
      }
    }
    const http = new HttpConnection({ dispatch });
    const serialList = await http.get<StationSerialType>(REST_API.MACHINE_CONF.GET_STATION_SERIAL, {
      List,
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
    });

    setOpenConfSendDialog(false);
    dispatch(
      sendConfThunk({
        force: COMMON.MACHINE_CONF.FORCE,
        machineConf,
        serialList,
        leftRight: selectedStation.leftRight,
        headStoreId: (loginUserInfo?.currentHeadQId || '') + (loginUserInfo?.currentStoreId || ''),
      }),
    );
    setOpenSendConfForceInfoDialog(true);
    // 二重登録防止
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 2000);
  };
  // 通常反映
  const handleOnClickSendOk = async (): Promise<void> => {
    // 二重登録防止
    if (processing.current) return;
    processing.current = true;

    // 選択ステーションの機器番号取得
    const listLength: number = selectedStationList.length;
    const List = {
      List: Array<string>(),
    };
    for (let i = 0; i < listLength; i += 1) {
      if (selectedStationList[i].selectStation === true) {
        List.List.push(String(selectedStationList[i].stationId));
      }
    }
    const http = new HttpConnection({ dispatch });
    const serialList = await http.get<StationSerialType>(REST_API.MACHINE_CONF.GET_STATION_SERIAL, {
      List,
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
    });

    setOpenConfSendDialog(false);
    dispatch(
      sendConfThunk({
        force: COMMON.MACHINE_CONF.NOT_FORCE,
        machineConf,
        serialList,
        leftRight: selectedStation.leftRight,
        headStoreId: (loginUserInfo?.currentHeadQId || '') + (loginUserInfo?.currentStoreId || ''),
      }),
    ).then(() => {
      setOpenSendConfInfoDialog(true);
      processing.current = false;
    });
  };

  const handleCloseSendDialog = (): void => {
    setOpenConfSendDialog(false);
  };

  // ダイアログを閉じる
  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
    setOpenSaveConfInfoDialog(false);
    setOpenSendConfInfoDialog(false);
    setOpenSendConfForceInfoDialog(false);
    setOpenConfirmDialog(false);
    setInfoDialogSaveConf({ open: false, msg: '' });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <div className={classes.leftButtonArea}>
            <Grid className={classes.returnArea}>
              <ReturnButton onClick={handleclickReturn}>ステーション一覧</ReturnButton>
            </Grid>
            <Grid className={classes.otherArea}>
              <CommonButton onClick={handleclickConfSave} iconName="confSaveIcon">
                設定を保存
              </CommonButton>
            </Grid>
            <Grid className={classes.otherArea}>
              <CommonButton onClick={handleClickSaveConf} iconName="confListIcon">
                保存設定
                <br />
                一覧
              </CommonButton>
            </Grid>
            <Grid className={classes.otherArea}>
              <CommonButton onClick={handleClickinitializeConf} iconName="confInitializationIcon">
                はじめから設定する
              </CommonButton>
            </Grid>
          </div>
          <div className={classes.confArea}>
            <AreaConfButton onClick={handleClickAreaConf} />
          </div>
          <div className={classes.confArea}>
            <GameConfButton onClick={handleClickGameConf} />
            <br />
            <LedConfButton onClick={handleClickLedConf} />
          </div>
          <div className={classes.confArea}>
            <CostServiceConfButton onClick={handleClickCostServiceConf} />
            <br />
            <OtherConfButton onClick={handleClickOtherConf} />
            <br />
            <Grid className={classes.decision}>
              <DecisionButton onClick={handleOnClickDecision}>
                設定を筐体
                <br />
                に送信
              </DecisionButton>
            </Grid>
          </div>

          <ConfSaveDialog
            open={openConfSaveDialog}
            onOk={handleOnClickOk}
            handleChange={handleChange}
            onCancel={handleCloseConfSaveDialog}
          />
          <ConfSendDialog
            open={openConfSendDialog}
            onForce={handleOnClickSendForce}
            onOk={handleOnClickSendOk}
            onCancel={handleCloseSendDialog}
          />
          <ConfirmDialog
            open={openConfirmDialog}
            msg={MESSAGES.INF210}
            onOk={handleClickOk}
            onCancel={handleCloseInfoDialog}
          />
          <InfoDialog open={openInfoDialog} msg={MESSAGES.INF206} closeFunc={handleCloseInfoDialog} />
          <InfoDialog open={openSaveConfInfoDialog} msg={MSG301} closeFunc={handleCloseInfoDialog} />
          <InfoDialog
            open={openSendConfInfoDialog}
            msg={MESSAGES.INF203}
            closeFunc={handleCloseInfoDialog}
            closeBtnLabel="閉じる"
          />
          <InfoDialog open={openSendConfForceInfoDialog} msg={MESSAGES.INF204} closeFunc={handleCloseInfoDialog} />
          <InfoDialog open={infoDialog.open} msg={infoDialog.msg} closeFunc={infoDialog.closeFunc} />
          <InfoDialog open={infoDialogSaveConf.open} msg={infoDialogSaveConf.msg} closeFunc={handleCloseInfoDialog} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default StationConfTopJack;
