import React, { useCallback, useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DailySalesInfoList from 'components/home/layouts/DailySalesInfo';
import HourlySalesInfo from 'components/home/layouts/HourlySalesInfo';
import RoundBox from 'components/home/organisms/boxes/RoundBox';
import TopContent from 'components/home/organisms/contents/TopContent';
import MachineStatusInfo from 'components/home/layouts/MachineStatusInfo';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';

import {
  DailySalesInfoType,
  HourlySalesInfoType,
  SelectedHourListType,
  StoreExtendType,
  DailySalesInfoListType,
} from 'types/home/TopTypes';
import { useHistory } from 'react-router-dom';
import {
  setBrowsingDate,
  setInformationList,
  setIsShowNewNotify,
  setManualsList,
} from '../../redux/slices/manualInformationSlice';
import redirectLinks from '../../constants/redirectLinks';
import { IBrowsingDate, IInformationType, IManualType } from '../../types/manualInformation/manualInformationType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'TOP',
  screenId: 'SCR111',
  currentMenu: COMMON.MENU.HOME,
  layoutDiv: 1,
  disableContents: true,
  initializeState: true,
};

const useStyles = makeStyles(() => ({
  salesInfoArea: {
    backgroundColor: '#EBEBEB',
    padding: '4px 8px 4px 8px',
    borderRadius: '4px',
  },
  salesInfoAreaContens: {
    padding: '0 2px 2px 2px',
  },
  hourlySalesInfoAreaRoot: {
    margin: '6px 0 0 0',
  },
  alertInfoArea: {
    backgroundColor: '#FF6E81',
    padding: '4px 8px 2px 8px',
    borderRadius: '4px',
  },
}));

/* ************ Component ************ */
const Top: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  /* ************ state/redux ************ */
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const [dailySalesInfo, setDailySalesInfo] = useState<DailySalesInfoType>();
  const [hourlySalesInfo, setHourlySalesInfo] = useState<HourlySalesInfoType>();
  const history = useHistory();
  const { manuals, browsingDate, information } = useAppSelector((state) => state.manualInformation);
  const [isLoadManualInformationAgain, setIsLoadManualInformationAgain] = React.useState(false);

  useEffect(() => {
    // 表示する店舗が変更されたらすべて取得
    getSalesInfoApi(true, []);
  }, [loginUserInfo]);

  /* ************ API ************ */
  const getSalesInfoApi = async (isInit: boolean, selectedHourList: SelectedHourListType) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<{
      today: string;
      storeExtend: StoreExtendType;
      dailySalesInfoList: DailySalesInfoListType;
      hourlySalesInfoList: HourlySalesInfoType;
      selectedHourList: SelectedHourListType;
    }>(REST_API.HOME.GET_SALES_INFO, {
      isInit,
      targetDate: dailySalesInfo?.today,
      headquartersId: loginUserInfo?.currentHeadQId,
      storeId: loginUserInfo?.currentStoreId,
      selectedHourList,
    });

    setHourlySalesInfo({
      today: data.today,
      costRate: data.hourlySalesInfoList.costRate,
      avgStationSales: data.hourlySalesInfoList.avgStationSales,
      totalSales: data.hourlySalesInfoList.totalSales,
      selectedHourList: data.selectedHourList,
    });
    setDailySalesInfo({
      today: data.today,
      targetCostRate: {
        targetCostRate: data.storeExtend.targetCostRate,
        versionNo: data.storeExtend.versionNo,
      },
      dailySalesInfoList: {
        ...dailySalesInfo?.dailySalesInfoList,
        ...data.dailySalesInfoList,
      },
    });
    return data;
  };

  // event ob buttons of HourlySalesInfo
  const handleClickHourlySalesBtns = (selectedBtnList: SelectedHourListType) => {
    getSalesInfoApi(false, selectedBtnList);
  };

  // Get information api
  const getInformationApi = () => {
    const http = new HttpConnection({ dispatch });
    return new Promise((resolve) => {
      http.get<IInformationType[]>(REST_API.MANUAL_NOTICE.GET_INFORMATION_PRIVATE).then((data) => {
        // Update redux
        dispatch(setInformationList(data));
        resolve(data);
      });
    });
  };
  // get manual api
  const getManualApi = () => {
    const http = new HttpConnection({ dispatch });
    return new Promise((resolve) => {
      http.get<IManualType[]>(REST_API.MANUAL_NOTICE.GET_MANUAL).then((data) => {
        // Update redux
        dispatch(setManualsList(data));
        resolve(data);
      });
    });
  };
  // get browsing date api
  const getBrowsingHistoryApi = () => {
    const http = new HttpConnection({ dispatch });
    return new Promise((resolve) => {
      http.get<IBrowsingDate>(REST_API.MANUAL_NOTICE.UPDATE_BROWSING_HISTORY_DATE).then((data) => {
        // set current browsing date
        dispatch(setBrowsingDate(data.browsingDate));
        resolve(data);
      });
    });
  };

  const getApiInformation = useCallback(() => {
    Promise.all([getInformationApi(), getManualApi(), getBrowsingHistoryApi()]).then(() => {
      // All Promises have resolved successfully
      sessionStorage.setItem('isFresh', 'false');
      setIsLoadManualInformationAgain(true);
    });
  }, []);

  useEffect(() => {
    const isFreshSession = sessionStorage.getItem('isFresh');
    if (isFreshSession === 'true') {
      getApiInformation();
    }
  }, []);

  // Handle show icon notify
  useEffect(() => {
    const currentPath = history.location.pathname;
    if (currentPath === redirectLinks.MANUAL_NOTICE) return;
    let latestDate: Date | string =
      // eslint-disable-next-line no-nested-ternary
      information.length > 0 ? information[0].createdAt : manuals.length > 0 ? manuals[0].createdAt : browsingDate;
    if (latestDate !== '') {
      latestDate = new Date(latestDate);
    } else {
      latestDate = new Date();
    }
    // get latest date in information
    information.forEach((informationItem) => {
      const newDate = new Date(informationItem.createdAt);
      if (newDate >= latestDate) {
        latestDate = newDate;
      }
    });
    // get latest date in manual
    manuals.forEach((manualItem) => {
      const newDate = new Date(manualItem.createdAt);
      if (newDate >= latestDate) {
        latestDate = newDate;
      }
    });
    if (isLoadManualInformationAgain) {
      if (browsingDate === '' || latestDate > new Date(browsingDate)) {
        dispatch(setIsShowNewNotify(true));
      } else {
        dispatch(setIsShowNewNotify(false));
      }
      sessionStorage.setItem('isFresh', '');
    }
  }, [manuals, browsingDate, information, isLoadManualInformationAgain]);

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <TopContent>
          <Box className={classes.salesInfoArea}>
            <RoundBox
              title="集計情報"
              exClassName={{
                contents: classes.salesInfoAreaContens,
              }}>
              <DailySalesInfoList dailySalesInfo={dailySalesInfo} />
            </RoundBox>
            <RoundBox
              title="時間別情報"
              exClassName={{
                root: classes.hourlySalesInfoAreaRoot,
              }}>
              <HourlySalesInfo hourlySalesInfo={hourlySalesInfo} callGetSalesInfoApi={handleClickHourlySalesBtns} />
            </RoundBox>
          </Box>
          <Box className={classes.alertInfoArea}>
            <RoundBox title="アラート情報">
              <MachineStatusInfo />
            </RoundBox>
          </Box>
        </TopContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default Top;
