export default class UrlHelper {
  /**
   * Function use for convert get url
   * @author: atsushi.teruya
   * get convert url
   * オブジェクト(連想配列)で渡した値をクエリパラメータに変換
   * ex) convertGetUrl('hoge', {apple : '1', orage: '2'}) => hoge?apple=1&orange=2
   * @returns {string}
   */
  static convertGetUrl(url: string, parameters: { [key: string]: string }): string {
    if (!parameters) return url;

    let newUrl = url;
    Object.keys(parameters).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(parameters, key)) {
        if (parameters[key] !== undefined) {
          newUrl = this.updateQueryStringParameter(newUrl, key, parameters[key]);
        }
      }
    });
    return newUrl;
  }

  /**
   * Function using for update query string parameter
   * @author: atsushi.teruya
   *
   * @param uri
   * @param key
   * @param value
   * @returns {*}
   */
  static updateQueryStringParameter(uri: string, key: string, value: string): string {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    }

    return `${uri + separator + key}=${value}`;
  }

  /**
   * Function use for convert query url
   * @author: atsushi.teruya
   * get convert query to call api
   * urlに含む置換文字列をオブジェクト(連想配列)で渡した値に変換
   * ex) convertGetUrl('hoge/{id}', { id: '1'}) => hoge/1
   *
   * @returns {string}
   */
  static convertQueryUrl(url: string, parameters: { [key: string]: string }): string {
    if (!parameters) return url;
    let newUrl = url;
    Object.keys(parameters).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(parameters, key)) {
        if (parameters[key] !== undefined) {
          newUrl = newUrl.replace(`{${key}}`, parameters[key]);
        }
      }
    });

    return newUrl;
  }

  /**
   * Function use for convert query url
   * @author: atsushi.teruya
   * get convert query to transition (= history.push)
   * urlに含む置換文字列をオブジェクト(連想配列)で渡した値に変換
   * ex) convertGetUrl('hoge/:id', { id: '1'}) => hoge/1
   * @returns {string}
   */
  static convertQueryUrlFrontEnd(url: string, parameters: { [key: string]: string }): string {
    if (!parameters) return url;
    let newUrl = url;
    Object.keys(parameters).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(parameters, key)) {
        if (parameters[key] !== undefined) {
          newUrl = newUrl.replace(`:${key}`, parameters[key]);
        }
      }
    });

    return newUrl;
  }
}
