import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AreaDetail from 'components/machineConf/organisms/Area/AreaDetail';
import AreaSimplePit from 'components/machineConf/organisms/Area/AreaSimplePit';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { AreaConfType, PitConfType } from 'types/machineConf/machineConfType';
import { TypeShowAreaConf } from './OperationConf';

type PitConfInfoProps = {
  title: string;
  isLeft: boolean;
  pitConf: PitConfType | undefined;
  areaConf: AreaConfType | undefined;
  operationConf: string;
  areaConfHook: TypeShowAreaConf;
};

type StyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: 4,
    height: '251px',
    width: '192px',
  },
  title: {
    position: 'relative',
    fontSize: '24px',
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  pitArea: {
    position: 'relative',
    top: '8px',
    left: '8px',
  },
  areaSimplePit: {
    position: 'absolute',
  },
}));

const PitConfInfo: React.FC<PitConfInfoProps> = (props) => {
  const { title, isLeft, pitConf, areaConf, operationConf, areaConfHook } = props;
  const classes = useStyles({ isLeft });
  const machineVersion = areaConfHook.conf?.machineVersion;
  const isDefault = () => {
    if (!areaConf) return false;
    if (machineVersion === null) {
      return operationConf !== COMMON.OPERATION_CONF.NO_CONF;
    }
    return areaConf.areaConf === COMMON.SWITCH.OFF;
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.frame}>
        <Box className={`${classes.title}`}>
          <b>{title}</b>
        </Box>
        <Box>
          <Box>
            <Box className={classes.pitArea}>
              <AreaDetail
                displayCellSeparate={pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                displayTableBorder
                defaultBorderColor
                leftRight={isLeft ? COMMON.LEFT_RIGHT.LEFT : COMMON.LEFT_RIGHT.RIGHT}
                size="S"
                areaConf={areaConf}
                pitConf={pitConf}
                isDefault={isDefault()}
              />

              {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE && (
                <AreaSimplePit className={classes.areaSimplePit} pitConf={pitConf} size="S" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PitConfInfo);
