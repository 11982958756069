import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import armPowerMainIcon from 'assets/images/armPowerMainIcon2.svg';
import inIcon from 'assets/images/inIcon.svg';
import outIcon from 'assets/images/outIcon.svg';
import inIconLst from 'assets/images/inIconLst.svg';
import outIconLst from 'assets/images/outIconLst.svg';
import bearIcon from 'assets/images/bearIcon.svg';
import COMMON from 'constants/common';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';

// Define type Props
export type ArmPowerInfoType = {
  initialHeight: string;
  leftArmSize: string;
  rightArmSize: string;
  lPower1: number;
  rPower1: number;
  armOpen: string;
  pushing1: string;
  descentLimit1: number;
  catcherDirection: string;
};

type ArmPowerInfoProps = {
  title: string;
  subtitle?: string;
  isLeft: boolean;
  isStationListScreen: boolean;
  armPowerInfo: ArmPowerInfoType;
};

type TextBoxItemProps = {
  value: string;
  icon?: boolean;
  posClass?: string;
  isLeft: boolean;
};

type StyleProps = {
  isLeft: boolean;
};

type ShowInOrOutIconProps = {
  isLeft: boolean;
  armPowerInfo: { catcherDirection: string };
  isStationListScreen: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: '5px 10px',
    height: '564px',
    width: '320px',
  },
  borderStyle: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
  },
  title: {
    position: 'relative',
    fontSize: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    letterSpacing: '-4px',
    '& span': {
      display: 'block',
      margin: '0',
    },
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '& .mainIcon': {
      margin: '0 auto',
    },
    '& .inner': {
      position: 'relative',
    },
    '& .inIcon': {
      position: 'absolute',
      top: '55px',
      left: '25px',
    },
    '& .outIcon': {
      position: 'absolute',
      top: '55px',
      right: '25px',
    },
  },
  textBox: {
    minWidth: '46px',
    height: '46px',
    lineHeight: '55px',
    background: '#FFF',
    position: 'absolute',
    borderRadius: '5px',
    '& .inner': {
      position: 'relative',
      fontSize: 24,
      textAlign: 'center',
      padding: '0 8px',
    },
    '& span': {
      display: 'block',
      margin: '-7px 0 0',
    },
    '& .icon': {
      position: 'absolute',
      left: '-15px',
      top: '50px',
    },
  },
  textBoxTop: {
    top: '5px',
    left: '200px',
  },
  textBoxCenterLeft: {
    top: '185px',
    right: '200px',
    width: '78px',
    height: '42px',
  },
  textBoxCenterRight: {
    top: '185px',
    left: '200px',
    width: '78px',
    height: '42px',
  },
  textBoxBottom: {
    left: '200px',
    bottom: '76px',
    width: '78px',
    height: '42px',
  },
  textBoxM: {
    top: '115px',
    right: '200px',
  },
  textBoxS: {
    top: '115px',
    left: '200px',
  },
  textBoxItem3: {
    top: '275px',
    left: '110px',
    width: '78px',
    height: '42px',
  },
  iconItem4: {
    top: '340px',
    right: '200px',
    width: '78px',
    height: '42px',
    '& img': {
      maxWidth: '100px !important',
    },
  },
}));

const TextBoxItem: React.FC<TextBoxItemProps> = (props) => {
  const { value, icon, posClass, isLeft } = props;
  const classes = useStyles({ isLeft });
  return (
    <Box className={`${classes.textBox} ${classes.borderStyle} ${posClass}`}>
      <Box className="inner">
        <span>{value}</span>
        {icon ? <img src={bearIcon} alt="keyIcon" className="icon" /> : ''}
      </Box>
    </Box>
  );
};

const ShowInOrOutIcon: React.FC<ShowInOrOutIconProps> = (props) => {
  const { isLeft, isStationListScreen, armPowerInfo } = props;
  return (
    <>
      {armPowerInfo.catcherDirection === COMMON.CATCHER_DIRECTION.IN && isStationListScreen && !isLeft ? (
        <img src={inIcon} alt="inIcon" className="inIcon" />
      ) : (
        <></>
      )}
      {armPowerInfo.catcherDirection === COMMON.CATCHER_DIRECTION.IN && isStationListScreen && isLeft ? (
        <img src={outIconLst} alt="outIcon" className="outIcon" />
      ) : (
        <></>
      )}
      {armPowerInfo.catcherDirection === COMMON.CATCHER_DIRECTION.OUT && isStationListScreen && !isLeft ? (
        <img src={outIcon} alt="outIcon" className="outIcon" />
      ) : (
        <></>
      )}
      {armPowerInfo.catcherDirection === COMMON.CATCHER_DIRECTION.OUT && isStationListScreen && isLeft ? (
        <img src={inIconLst} alt="inIcon" className="inIcon" />
      ) : (
        <></>
      )}
    </>
  );
};

const ArmPowerInfo: React.FC<ArmPowerInfoProps> = (props) => {
  const { title, subtitle, isLeft, armPowerInfo, isStationListScreen } = props;
  const classes = useStyles({ isLeft });
  let leftArmSizeFlag = false;
  let rightArmSizeFlag = false;
  if (
    armPowerInfo.leftArmSize === COMMON.ARM_SIZE.NONE ||
    armPowerInfo.leftArmSize === COMMON.ARM_SIZE.S ||
    armPowerInfo.leftArmSize === COMMON.ARM_SIZE.M ||
    armPowerInfo.leftArmSize === COMMON.ARM_SIZE.L ||
    armPowerInfo.leftArmSize === COMMON.ARM_SIZE.SS
  ) {
    leftArmSizeFlag = true;
  }
  if (
    armPowerInfo.rightArmSize === COMMON.ARM_SIZE.NONE ||
    armPowerInfo.rightArmSize === COMMON.ARM_SIZE.S ||
    armPowerInfo.rightArmSize === COMMON.ARM_SIZE.M ||
    armPowerInfo.rightArmSize === COMMON.ARM_SIZE.L ||
    armPowerInfo.rightArmSize === COMMON.ARM_SIZE.SS
  ) {
    rightArmSizeFlag = true;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          {title}
          {subtitle ? <span>{subtitle}</span> : ''}
        </Box>
        <Box>
          <Box>
            <Box className="inner">
              <img src={armPowerMainIcon} alt="armPowerMainIcon" className="mainIcon" />
              <ShowInOrOutIcon isLeft={isLeft} armPowerInfo={armPowerInfo} isStationListScreen={isStationListScreen} />
              <TextBoxItem isLeft={isLeft} value={armPowerInfo.initialHeight} posClass={classes.textBoxTop} />
              <TextBoxItem
                isLeft={isLeft}
                value={armPowerInfo.lPower1.toString()}
                posClass={classes.textBoxCenterLeft}
              />
              <TextBoxItem
                isLeft={isLeft}
                value={armPowerInfo.rPower1.toString()}
                posClass={classes.textBoxCenterRight}
              />
              <TextBoxItem
                isLeft={isLeft}
                value={armPowerInfo.descentLimit1.toString()}
                posClass={classes.textBoxBottom}
              />
              {isStationListScreen ? (
                <>
                  <TextBoxItem
                    isLeft={isLeft}
                    value={
                      leftArmSizeFlag
                        ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, armPowerInfo.leftArmSize)
                        : '－'
                    }
                    posClass={classes.textBoxM}
                  />
                  <TextBoxItem
                    isLeft={isLeft}
                    value={
                      rightArmSizeFlag
                        ? getClassificationName(CLSFCN.ARM_SIZE.CATEGORY_CD, armPowerInfo.rightArmSize)
                        : '－'
                    }
                    posClass={classes.textBoxS}
                  />
                </>
              ) : (
                <></>
              )}
              <TextBoxItem isLeft={isLeft} value={`${armPowerInfo.armOpen}%`} posClass={classes.textBoxItem3} />
              <TextBoxItem
                isLeft={isLeft}
                value={getClassificationName(CLSFCN.PUSHING.CATEGORY_CD, armPowerInfo.pushing1)}
                posClass={classes.iconItem4}
                icon
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(ArmPowerInfo);
