import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfoListType } from 'types/maintenance/UserInfoType';
import CLSFCN from 'constants/classification';

/* ************ USER用Redux定義 ************ */

/* ************ Type ************ */

/* ************ State ************ */
const initialState: { userInfo: UserInfoListType } = {
  userInfo: { userInfoListFilter: CLSFCN.USER_LIST_FILTER.ALL },
};

/* ******* Event ****** */

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUserSlice: () => initialState,
    setUserInfoListFilter(state, action: PayloadAction<string>) {
      if (state) {
        // 「利用」プルダウンのチェンジイベント時にプルダウンの値もストアに保存する。
        state.userInfo.userInfoListFilter = action.payload;
      }
    },
  },
});

export const { initializeUserSlice, setUserInfoListFilter } = slice.actions;
export default slice.reducer;
