import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { makeStyles, Box, Grid } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import BorderBottomBox from 'components/system/atoms/boxs/BorderBottomBox';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { initializeGroupSlice } from 'redux/slices/groupSlice';
import { ConfirmDialog, InfoDialog } from 'components/system/organisms';
import MSG from 'constants/messages';
import MsgHelper from 'utils/message.helper';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { MachineListType } from 'types/group/GroupType';
import OrderSettingTable from '../../components/group/organisms/tables/OrderSettingTable';
import { DataSourceType, SelectListType } from '../../types/support/supportType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '登録機器連動順設定',
  screenId: COMMON.SCREEN_ID.GET_GIFT_ORDER_SET,
  currentMenu: COMMON.MENU.GROUP,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '10px 28px 24px 16px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      padding: '4px 0px 0px 24px',
      width: '224px',
    },
  },
  dataTitle: {
    display: 'flex',
  },
  decision: {
    textAlign: 'right',
    margin: '24px 0',
  },
  boxTextRight: {
    paddingLeft: 40,
    paddingTop: 12,
    flex: 1,
  },
  machineCount: {
    paddingTop: 12,
    fontSize: 28,
    borderBottom: '2px solid #707070',
    '& .value': {
      marginLeft: 4,
    },
  },
  titleContent: {
    margin: '30px 0 16px 12px',
    fontSize: '23px',
    float: 'left',
  },
  anchorDialog: {
    marginLeft: '250px',
  },
  groupName: {
    fontSize: 28,
    borderBottom: '2px solid #707070',
    '& .value': {
      marginLeft: 4,
    },
  },
  contentText: { fontSize: '28px' },
  pulldownList: {
    '& #selectList-dialog-title': {
      justifyContent: 'center',
    },
    '& #scrollSelectList': {
      textAlign: 'center',
      '& div': {
        padding: 0,
      },
    },
  },
  buttonSize: {
    fontSize: '28px',
  },
}));

const INPUT_NAMES = {
  ORDER_NO: 'order_no',
};

const GetGiftOrderSetting: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  /* ************ Global State ************ */
  const groupInfo = useAppSelector((state) => state.group.getGiftGroup);

  /* ************ Local State ************ */
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [selectedRow, setSelectedRow] = useState<number | undefined>();
  const [openConfirmDlg, setOpenConfirmDlg] = useState<boolean>(false);
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
  const nextScrName = useRef<string>('グループカテゴリ選択');
  const nextScrUrl = useRef<string>(redirectLinks.GROUP_SELECT_CONF);

  const anchorDialogRef = useRef(null);

  /* ************ Local Function ************ */

  // 連動順の選択肢を作成
  const dataSource: DataSourceType[] = (() =>
    groupInfo.machineList.map((m) => ({
      name: m.orderNo.toString(),
      value: m.orderNo.toString(),
    })))();

  /* ************ API ************ */
  // 景品獲得演出グループ情報を登録/更新
  const registGroupInfoApi = async () => {
    const http = new HttpConnection({ dispatch });
    await http.post(REST_API.GROUP.GET_GIFT_GROUP, {
      groupId: groupInfo.groupId,
      versionNo: groupInfo.versionNo,
      groupName: groupInfo.groupName,
      se: groupInfo.se,
      voice: groupInfo.voice,
      machineList: groupInfo.machineList,
    });
  };
  /* ************ UseEffect ************ */
  useEffect(() => {
    // 遷移先画面によって独自処理を実施
    if (groupInfo.nextFromOrderSetScr === COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY) {
      nextScrName.current = 'グループカテゴリ選択';
      nextScrUrl.current = redirectLinks.GROUP_SELECT_CONF;
    } else if (groupInfo.nextFromOrderSetScr === COMMON.SCREEN_ID.GROUP_LIST) {
      nextScrName.current = '登録グループ一覧';
      nextScrUrl.current = redirectLinks.GROUP_LIST_DATA;
    } else if (groupInfo.nextFromOrderSetScr === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      nextScrName.current = '機器一覧';
      nextScrUrl.current = redirectLinks.MAINT_MACHINE_LIST;
    }
  }, []);

  /* ************ Events ************ */

  // return button
  const handleClickReturn = () => {
    history.push(redirectLinks.GROUP_GET_GIFT_SELECT_REGISTER_MACHINE);
  };

  const handleClickRow = useCallback(
    (
      ev: React.MouseEvent<HTMLTableRowElement>,
      orderNo: number,
      rows: MachineListType[],
      handleClickNewOrderNo: (newOrderNo: number, oldOrderNo: number, machineList: MachineListType[]) => void,
    ) => {
      setSelectedRow(orderNo);
      setSelectList({
        open: true,
        anchorEL: anchorDialogRef.current,
        className: classes.pulldownList,
        dataSource,
        name: INPUT_NAMES.ORDER_NO,
        placement: 'right-start',
        title: '連動順を選択してください',
        value: orderNo.toString(),
        onClick: (d) => {
          handleClickNewOrderNo(Number(d.value), orderNo, rows);
        },
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
          setSelectedRow(undefined);
        },
      });
    },
    [groupInfo.machineList],
  );

  // click on ok button ob the confirm dialog
  const handleOkConfirmDlg = () => {
    registGroupInfoApi().then(() => {
      setOpenConfirmDlg(false);
      setOpenInfoDialog(true);
    });
  };

  // click on ok button ob the infomation dialog
  const handleOkInfoDlg = () => {
    setOpenInfoDialog(false);
    dispatch(initializeGroupSlice());
    history.push(nextScrUrl.current);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          <Box className={classes.topArea}>
            <ReturnButton onClick={handleClickReturn}>登録機器選択</ReturnButton>
            <Box className={classes.boxTextRight}>
              <BorderBottomBox value={groupInfo.groupName} className={classes.groupName} />
              <BorderBottomBox
                value={`機器数  ${groupInfo.machineList.length.toString()}件`}
                className={classes.machineCount}
              />
            </Box>
          </Box>
          <Box className={classes.dataTitle}>
            <Box className={classes.titleContent}>機器を選択して連動順を設定</Box>
            <div className={classes.anchorDialog} ref={anchorDialogRef} />
          </Box>
          <OrderSettingTable
            groupCategory={COMMON.GROUP_CATEGORY.GET_GIFT}
            rows={groupInfo.machineList}
            handleClickRow={handleClickRow}
            selectedOrderNo={selectedRow}
          />
          <Grid className={classes.decision}>
            <DecisionButton onClick={() => setOpenConfirmDlg(true)}>
              <span className={classes.buttonSize}>登録</span>
            </DecisionButton>
          </Grid>
        </Box>
        <SelectList selectList={selectList} />
        <ConfirmDialog
          open={openConfirmDlg}
          msg={MsgHelper.messageFormat(MSG.INF224, '景品獲得演出設定')}
          onOk={handleOkConfirmDlg}
          onCancel={() => setOpenConfirmDlg(false)}
          addClass={{
            contentText: classes.contentText,
          }}
        />
        <InfoDialog
          open={openInfoDialog}
          msg={`${MsgHelper.messageFormat(MSG.INF302, '景品獲得演出設定')}\n${MsgHelper.messageFormat(
            MSG.INF225,
            nextScrName.current,
          )}`}
          closeFunc={() => {
            handleOkInfoDlg();
          }}
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GetGiftOrderSetting;
