import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { AxiosError } from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/reset.css';
import './assets/common.css';

const isAxiosError = (object: string | Error | null | undefined): object is AxiosError =>
  object !== null && typeof object === 'object' && 'isAxiosError' in object;

// Sentryにログ送信したい時は以下コメントアウトを外す
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  maxBreadcrumbs: 100,
  tracesSampleRate: 1.0, // 1.0=100%=毎回トレース送信。本番では調整すべき
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (isAxiosError(error)) {
      const status = error.response?.status;
      if (status && status >= 400 && status < 500) {
        // 業務エラーは無視する
        return null;
      }
    }
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
