import COMMON from 'constants/common';
import React, { useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { themeColor } from 'constants/theme';
import catcherIconXy from 'assets/images/catcherIconXy.svg';
import catcherIconLever from 'assets/images/catcherIconLever.svg';
import leverIcon from 'assets/images/leverIcon.svg';
import { XySpeedLeverConfType } from 'types/machineConf/machineConfType';
import { setXySpeedLeverConf } from 'redux/slices/machineConfSlice';
import RadioButtons, { RadioType } from 'components/system/atoms/radioButtons/RadioButton';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'キャッチャー速度設定',
  screenId: COMMON.SCREEN_ID.CATCHER_SPEED_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '40px',
    '& .catcherArea': {
      marginLeft: '24px',
    },
    '& .settingArea': {
      marginLeft: '46px',
    },
    '& .xyLeverSetting, & .zSetting': {
      width: '568px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderStyle: 'solid',
        borderRadius: '3px',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
      },
      '&:before': {
        left: '-44px',
        borderTopWidth: '23px',
        borderRightWidth: '44px',
        borderBottomWidth: '23px',
        borderLeftWidth: '0px',
      },
      '&:after': {
        left: '-41px',
        borderTopWidth: '23px',
        borderRightWidth: '44px',
        borderBottomWidth: '23px',
        borderLeftWidth: '0px',
      },
    },
    '& .zSetting': {
      marginTop: '20px',
    },
  },
  bottomContent: {
    '& .doneButton': {
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
  },
  // ステーションStyle
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
    '&:before': {
      borderRightColor: themeColor.rightDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  // Common
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '20px',
  },
  baseBoxSmall: {
    width: '110px',
    height: '68px',
    fontSize: '21px',
    color: '#FFF',
    textAlign: 'center',
    border: '2px solid #707070',
    '&.xBox': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#FF3E3E',
      '&:before': {
        content: '"①"',
      },
    },
    '&.yBox': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#3086FF',
      marginTop: '20px',
      '&:before': {
        content: '"②"',
      },
    },
    '&.zBox': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#00B050',
    },
  },
  baseBoxLarge: {
    width: '110px',
    height: '156px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: '21px',
    backgroundColor: '#FFC000',
    border: '2px solid #707070',
    padding: '8px',
  },
  speedRadios: {
    height: '100%',
    '& label': {
      width: '78px',
      flexDirection: 'column',
      margin: '0',
    },
    '& span': {
      fontSize: '19px',
    },
    '& svg': {
      width: '36px',
      height: '36px',
    },
  },
  // img
  catcherIcon: {
    width: '232px',
  },
  leverIcon: {
    width: '40px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  SPEED_XY: 'speedXy',
  SPEED_Z: 'speedZ',
};

/* ******** Functions ******** */

// 利用の選択肢
const catcherSpeedRadios = (): Array<RadioType> =>
  getClsfcnByCategory(CLSFCN.CATCHER_SPEED.CATEGORY_CD).map((cls) => ({
    value: cls.value,
    label: cls.name,
  }));

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - キャッチャー速度設定
 *
 */
const CatcherSpeedConfJack: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);

  // Local
  const stationStyle = classes.rightStyle;
  const [tempXySpeedLeverConf, setTempXySpeedLeverConf] = useState<XySpeedLeverConfType | undefined>(xySpeedLeverConf);

  /* ************ Event ************ */
  useEffect(() => {
    if (!xySpeedLeverConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleclickReturn = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };

  // ラジオボタン チェンジイベント
  const handleChangeSpeed = (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setTempXySpeedLeverConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [ev.target.name]: ev.target.value,
          }
        : prevState,
    );
  };

  // 確定ボタン
  const handleClickDone = async () => {
    // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.CATCHER_SPEED_CONF_VALIDATION, {
        speedXy: tempXySpeedLeverConf?.speedXy,
        speedZ: tempXySpeedLeverConf?.speedZ,
      })
      .then(() => {
        // グローバルステートに保存
        dispatch(setXySpeedLeverConf(tempXySpeedLeverConf));
        // 画面遷移
        history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleclickReturn}>
                移動設定
                <br />
                TOP
              </ReturnButton>
              <Box className="discription">
                キャッチャー移動速度を設定できます。
                <br />
                移動の種類ごとに、速度を設定し、<Typography className={classes.doneImage}>確 定</Typography>
                してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="catcherArea">
                {tempXySpeedLeverConf?.leverStatus === COMMON.FLAG.OFF ? (
                  <img src={catcherIconXy} alt="catcherIconXy" className={classes.catcherIcon} />
                ) : (
                  <img src={catcherIconLever} alt="catcherIconLever" className={classes.catcherIcon} />
                )}
              </Box>
              <Box className="settingArea">
                <Box className={`${classes.baseArea} ${stationStyle} xyLeverSetting`}>
                  {tempXySpeedLeverConf?.leverStatus === COMMON.FLAG.OFF ? (
                    <Box>
                      <Box className={`${classes.baseBoxSmall} xBox`}>
                        ボタン
                        <br />
                        X移動
                      </Box>
                      <Box className={`${classes.baseBoxSmall} yBox`}>
                        ボタン
                        <br />
                        Y移動
                      </Box>
                    </Box>
                  ) : (
                    <Box className={`${classes.baseBoxLarge}`}>
                      <Box>
                        レバー
                        <br />
                        運営中
                      </Box>
                      <img src={leverIcon} alt="leverIcon" className={classes.leverIcon} />
                    </Box>
                  )}
                  <RadioButtons
                    row
                    name={INPUT_NAMES.SPEED_XY}
                    radioList={catcherSpeedRadios()}
                    value={tempXySpeedLeverConf?.speedXy}
                    onChange={handleChangeSpeed}
                    className={classes.speedRadios}
                  />
                </Box>
                <Box className={`${classes.baseArea} ${stationStyle} zSetting`}>
                  <Box className={`${classes.baseBoxSmall} zBox`}>Z移動</Box>
                  <RadioButtons
                    row
                    name={INPUT_NAMES.SPEED_Z}
                    radioList={catcherSpeedRadios()}
                    value={tempXySpeedLeverConf?.speedZ}
                    onChange={handleChangeSpeed}
                    className={classes.speedRadios}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.bottomContent}>
              <Box className="doneButton">
                <DoneButton onClick={handleClickDone}>確 定</DoneButton>
              </Box>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default CatcherSpeedConfJack;
