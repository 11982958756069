import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { setDataStationListSelected } from 'redux/slices/dataSlice';
import { StationTableRowType, StationTableType } from 'types/data/dataType';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '407px',
    // 1080pxにしても実際のサイズがあわないので+20pxする。
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid',
    borderColor: theme.base.tableBorder,
    '& th,td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.base.tableBorder,
      fontSize: '16px',
      lineHeight: '20px',
    },
    '& tbody tr': {
      height: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: theme.base.tableRowSecondory,
    },
    '& td': {
      padding: '0px 8px 0px 8px',
    },
  },
  tdSelectStation: {
    paddingLeft: '0px 0px 0px 0px',
    textAlign: 'center',
  },
  tdStation: {
    textAlign: 'left',
  },
}));

const StationTableRow: React.FC<StationTableRowType> = React.memo(({ row }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleChangeSelectStation = useCallback(() => {
    dispatch(setDataStationListSelected(row.stationId));
  }, [row]);

  return (
    <TableRow>
      <TableCell className={classes.tdSelectStation}>
        <ListCheckBox
          checked={row?.selected === undefined ? false : row.selected}
          name="selectStation"
          onChange={handleChangeSelectStation}
        />
      </TableCell>
      <TableCell className={classes.tdStation}>
        <TooltipComponent tooltipText={row.giftName} displayWord={row.stationNameDisp ?? ''} />
      </TableCell>
    </TableRow>
  );
});

const StationTable: React.FC<StationTableType> = ({ tableId, stationList }) => {
  const classes = useStyles();
  const cols = [
    { id: 'stationSelect', width: 56 },
    { id: 'station', width: 1024 },
  ];

  return (
    <TableContainer id={tableId} className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        {cols.map((column) => (
          <col key={column.id} style={{ width: column.width }} />
        ))}
        <TableBody>
          {stationList && stationList.map((row) => <StationTableRow key={row.stationId} row={row} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StationTable;
