import React, { MouseEventHandler, TouchEventHandler, useCallback, useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Box } from '@material-ui/core';
import { AreaConfType, PitConfType } from 'types/machineConf/machineConfType';
import { to2DArrayAreaJack, to2DArrayPitJack, toAreaConfFrom2DArrayJack } from 'utils/machineConf.helper';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import { useDispatch } from 'react-redux';
import { handleCallSystemError } from '../../../../redux/slices/commonSlice';
import ERROR_CODE from '../../../../constants/errorCode';

/* ************ constant ************ */
const XY_AREA_SIZE = 390;
const HEIGHT_AREA_SIZE = 340;
const PRIZE_AREA_MAX_Y = 80;
const PRIZE_AREA_MAX_X = 100;

type Props = {
  areaConf?: AreaConfType;
  pitConf?: PitConfType;
  areaValue: string;
  onChange: (areaConf: AreaConfType) => void;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px',
    userSelect: 'none',
    touchAction: 'none',
    '& *': {
      touchAction: 'none',
    },
  },
  container: {
    position: 'relative',
    width: '414px',
    height: '365px',
    borderWidth: '0px',
    top: 16,
  },
  table: {
    width: '390px',
    height: '341px',
    borderCollapse: 'collapse',
    border: '1px solid',
    margin: 12,
    borderColor: theme.base.tableBorder,
    '& .area0': {
      backgroundColor: theme.base.area0,
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
    },
    '& td, th': {
      borderCollapse: 'collapse',
      borderWidth: '1px',
      borderColor: theme.base.tableBorder,
    },
  },
  tableRowOld: {
    height: '80px',
  },
  tableRow: {
    height: '65px',
  },
  tableCell: {
    position: 'relative',
    padding: '0',
    width: '65px',
    borderRightStyle: 'solid',
  },
  evenColumnCell: {
    borderRightStyle: 'dashed',
  },
  evenRowCell: {
    borderBottomStyle: 'dashed',
  },
  noneColumnCell: {
    borderBottom: 'none',
  },
  pitBlock: {
    width: '100%',
    height: '100%',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pitIcon: {
    pointerEvents: 'none',
  },
  pitIconSimple: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    height: '114px',
    width: '114px',
    zIndex: 1,
  },
  pitIconSimpleFirst: {
    position: 'absolute',
    top: '4px',
    left: '30px',
    height: '70px',
    width: '70px',
    zIndex: 1,
  },
  pitIconDetail: {
    position: 'absolute',
    top: '4px',
    left: '4px',
    height: '57px',
    width: '57px',
  },
  pitIconDetailFirst: {
    position: 'absolute',
    top: '12px',
    left: '4px',
    height: '57px',
    width: '57px',
  },
  pinpointPit: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    borderRadius: '100%',
    border: '2px solid',
    zIndex: 1,
    backgroundColor: theme.palette.common.black,
    borderColor: theme.palette.common.white,
  },
}));

const PinpointPit: React.FC<{ status: string; x: number | null; y: number | null }> = ({ status, x, y }) => {
  // status,x,yが有効でない場合は空を返す。
  if (status !== COMMON.STATUS.NORMAL || x === null || y === null) {
    return <></>;
  }

  const classes = useStyles();

  const toScreenPosY = useCallback(
    (pitY: number) => HEIGHT_AREA_SIZE - Math.round((pitY * HEIGHT_AREA_SIZE) / PRIZE_AREA_MAX_Y),
    [],
  );
  const leftToScreenPos = useCallback(
    (pitX: number, pitY: number) => ({
      x: Math.round((pitX * XY_AREA_SIZE) / PRIZE_AREA_MAX_X),
      y: toScreenPosY(pitY),
    }),
    [],
  );

  const axes = leftToScreenPos(x || 0, y || 0);

  const style = {
    top: axes.y,
    left: axes.x,
  };
  return (
    <Box style={style} className={classes.pinpointPit}>
      <span />
    </Box>
  );
};

// TouchMove中はこのコンポーネント内部のステートを切り替えるのみとする
// TouchEnd時にonChangeを介して渡された基のステートを更新する
const AreaConfPanelJack: React.FC<Props> = (props) => {
  const { areaValue, areaConf, pitConf, onChange, className } = props;
  const classes = useStyles(props);
  const [areas, setAreas] = useState(to2DArrayAreaJack(areaConf));
  const pits = to2DArrayPitJack(pitConf);
  const dispatch = useDispatch();
  if (pitConf?.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL) {
    pits.shift();
  }

  useEffect(() => {
    if (!areaConf || !pitConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  useEffect(() => {
    setAreas(to2DArrayAreaJack(areaConf));
  }, [areaConf]);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    updateArea(event.clientX, event.clientY);
  };

  const handleTouchStartAndMove: TouchEventHandler<HTMLDivElement> = (event) => {
    // ※TouchMoveイベントは指を離すまで一番最初にタッチしたコンポーネントに対して発生し続ける
    const touch = event.changedTouches[0];
    updateArea(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    // ※PCとモバイルでイベントの発生仕方が異なる
    //   PC：マウスダウンした要素に関係なく、単純にマウスアップした要素でイベントが発生する→MouseUpだけでは編集終了を捕捉できないのでMouseLeaveも追加
    //   モバイル：要素内でタッチ開始すれば要素外でタッチ終了してもタッチ開始した要素でTouchEndイベントが発生する
    if (areaConf) {
      const newAreaConf = toAreaConfFrom2DArrayJack(areaConf, areas);
      onChange(newAreaConf);
    }
  };

  useEffect(() => {
    handleTouchEnd();
  }, []);

  const updateArea = (clientX: number, clientY: number) => {
    // タッチしているエリアを取得
    const element = document.elementFromPoint(clientX, clientY) as HTMLTableCellElement;
    if (element) {
      // 最初にタッチした落とし穴の値を反転した値を入力モードとして設定

      // 現在の入力モードとタッチしている落とし穴の値が違ったら落とし穴の値を変更
      const x = Number(element.dataset.x);
      const y = Number(element.dataset.y);
      if (!element.dataset.x || !element.dataset.y) return;
      if (areaValue !== areas[y][x]) {
        setAreas((prevAreas) => {
          const newAreas = [...prevAreas];
          newAreas[y][x] = areaValue;
          return newAreas;
        });
      }
    }
  };

  const displayPit = useCallback((i: number, j: number) => {
    if (pitConf?.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE) {
      // かんたんの場合は偶数時のみ処理する。
      if (j === 0 && i % 2 === 0 && pits[0][i / 2] === COMMON.FLAG.ON) {
        return (
          <Box className={`${classes.pitIcon} ${classes.pitIconSimpleFirst}`}>
            <img src={pitMarkIcon} alt="○" />
          </Box>
        );
      }
      if (j !== 0 && j % 2 !== 0 && i % 2 === 0 && pits[(j + 1) / 2][i / 2] === COMMON.FLAG.ON) {
        return (
          <Box className={`${classes.pitIcon} ${classes.pitIconSimple}`}>
            <img src={pitMarkIcon} alt="○" />
          </Box>
        );
      }
      return <></>;
    }
    if (pits[j][i] === COMMON.FLAG.ON)
      return (
        <Box className={`${classes.pitIcon} ${j === 0 ? classes.pitIconDetailFirst : classes.pitIconDetail}`}>
          <img src={pitMarkIcon} alt="○" />
        </Box>
      );
    return <></>;
  }, []);

  const cellStyle = useCallback(
    (x: number, y: number) =>
      `${classes.tableCell} ${(x % 2 === 0 && classes.evenColumnCell) || ''} ${
        (y % 2 !== 0 && y !== 0 && classes.evenRowCell) || ''
      }`,
    [],
  );
  // ※Boxの型定義からrefが漏れておりdivを使用
  return (
    <Box
      className={`${classes.root} ${className}`}
      onMouseDown={handleClick}
      onMouseLeave={handleTouchEnd}
      onMouseUp={handleTouchEnd}
      onTouchStart={handleTouchStartAndMove}
      onTouchMove={handleTouchStartAndMove}
      onTouchEnd={handleTouchEnd}>
      <TableContainer className={classes.container}>
        <Table className={classes.table}>
          <TableBody>
            {areas.map((row, i) => (
              <TableRow className={i === 0 ? classes.tableRowOld : classes.tableRow} key={String(i)}>
                {row.map((value, j) => (
                  <TableCell data-x={j} data-y={i} className={`${cellStyle(j, i)} area${value}`} key={String(j)}>
                    {pitConf?.settingPattern !== CLSFCN.PIT_PATTERN.PINPOINT && displayPit(j, i)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {pitConf?.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT && (
          <>
            <PinpointPit status={pitConf.pit1Status} x={pitConf.pit1x} y={pitConf.pit1y} />
            <PinpointPit status={pitConf.pit2Status} x={pitConf.pit2x} y={pitConf.pit2y} />
            <PinpointPit status={pitConf.pit3Status} x={pitConf.pit3x} y={pitConf.pit3y} />
            <PinpointPit status={pitConf.pit4Status} x={pitConf.pit4x} y={pitConf.pit4y} />
          </>
        )}
      </TableContainer>
    </Box>
  );
};

export default AreaConfPanelJack;
