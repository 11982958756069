import React, { memo } from 'react';
import { makeStyles, Box, InputLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

/**
 * ON OFF Button Toggle Component
 * ボタントグルコンポーネント(2択のみ)
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    '& .toggleLabel': {
      color: '#0D0D0D',
      margin: '0 8px 0 0',
      fontSize: '23px',
    },
    '& .toggleInput': {
      position: 'relative',
      width: '136px',
      height: '68px',
      fontSize: '32px',
      '&.toggleInput:not(.Mui-selected)': {
        background: '#DBDBDB',
        border: '#707070',
        color: '#000000',
        boxShadow: '4px 4px 1px rgba(0, 0, 0, .7)',
      },
      '&.toggleInput.Mui-selected': {
        top: '4px',
        left: '4px',
        background: '#FFB74D',
        color: '#FFFFFF',
      },
    },
    '& .Mui-disabled': {},
  },
}));

/* ************ Type ************ */
type Props = {
  id: string;
  value?: boolean;
  label?: string;
  optionName?: {
    onLabel?: string;
    offLabel?: string;
  };
  onChange?: (e: React.MouseEvent<HTMLElement>, newValue: boolean | null) => void;
  disabled?: boolean;
  labelPlacement?: 'start' | 'top';
  className?: string;
};

/* ************ Function ************ */
const setLabelPlacement = (labelPlacement: string): string => {
  if (labelPlacement === 'start') {
    return 'row';
  }
  return 'column';
};

/* ************ Component ************ */
const OnOFFButtonToggle: React.FC<Props> = memo((props) => {
  const classes = useStyles();
  const {
    id,
    value,
    label,
    optionName = {
      onLabel: 'あり',
      offLabel: 'なし',
    },
    onChange,
    disabled = false,
    labelPlacement = 'start',
    className = '',
  } = props;

  return (
    <Box
      display="flex"
      flexDirection={setLabelPlacement(labelPlacement)}
      alignItems="baseline"
      className={`${classes.root} ${className}`}>
      {label && (
        <InputLabel htmlFor={id} className="toggleLabel">
          {label}
        </InputLabel>
      )}
      <ToggleButtonGroup
        id={id}
        value={value}
        exclusive
        onChange={onChange}
        orientation="horizontal"
        className="toggleInputGroup">
        <ToggleButton id={`${id}_on`} value disabled={disabled} aria-label="on" className="toggleInput">
          {optionName.onLabel}
        </ToggleButton>
        <ToggleButton id={`${id}_off`} value={false} disabled={disabled} aria-label="off" className="toggleInput">
          {optionName.offLabel}
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
});

export default OnOFFButtonToggle;
