import React, { memo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { PrevNextButtonType } from 'types/system/buttonType';
import prevArrowIconScroll from 'assets/images/prevArrowIconScroll.svg';

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  root: {
    background: `url(${prevArrowIconScroll})`,
    boxShadow: 'none',
    height: '24px',
    width: '160px',
  },
  contained: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  upIcon: {
    transform: 'rotateX(180deg)',
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * PrevNextButton 上下ボタン
 * 一覧のスクロールに利用。
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const PrevNextButton: React.FC<PrevNextButtonType> = (props) => {
  const classes = useStyles();
  const { className, disabled, fullWidth, isUp, onClick, onMouseDown, onMouseUp, onMouseLeave } = props;

  /* *************      hooks     ************* */
  // disabledになった場合にlong press eventを強制解除。
  // 長押しイベントの制御は難しいので編集時は要注意してテストすること。
  useEffect(() => {
    if (disabled && onMouseUp) onMouseUp();
  }, [disabled]);

  const styleClass = `${className || ''} ${isUp ? classes.upIcon : ''}`;

  return (
    <Button
      variant="contained"
      classes={{
        root: classes.root,
        contained: classes.contained,
      }}
      className={styleClass}
      disabled={disabled}
      disableRipple
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
    />
  );
};

// propsの初期値。未指定時に使用する。
PrevNextButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(PrevNextButton);
