import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { AreaConfType, PitConfType } from 'types/machineConf/machineConfType';
import AreaDetailJack from '../Area/AreaDetailJack';
import AreaSimplePitJack from '../Area/AreaSimplePitJack';

type PitConfInfoProps = {
  title: string;
  isLeft: boolean;
  pitConf: PitConfType | undefined;
  areaConf: AreaConfType | undefined;
  operationConf: string;
};

type StyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
    borderRadius: 4,
    padding: 4,
    height: '251px',
    width: '192px',
  },
  title: {
    position: 'relative',
    fontSize: '24px',
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  pitArea: {
    position: 'relative',
    top: '36px',
    left: '8px',
  },
  areaSimplePit: {
    position: 'absolute',
  },
}));

const PitConfInfoJack: React.FC<PitConfInfoProps> = (props) => {
  const { title, isLeft, pitConf, areaConf, operationConf } = props;
  const classes = useStyles({ isLeft });

  return (
    <Box className={classes.container}>
      <Box className={classes.frame}>
        <Box className={`${classes.title}`}>
          <b>{title}</b>
        </Box>
        <Box>
          <Box>
            <Box className={classes.pitArea}>
              <AreaDetailJack
                displayCellSeparate={pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                displayTableBorder
                defaultBorderColor
                leftRight={isLeft ? COMMON.LEFT_RIGHT.LEFT : COMMON.LEFT_RIGHT.RIGHT}
                size="S"
                areaConf={areaConf}
                pitConf={pitConf}
                isDefault={operationConf !== COMMON.OPERATION_CONF.NO_CONF}
              />

              {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE && (
                <AreaSimplePitJack className={classes.areaSimplePit} pitConf={pitConf} size="S" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(PitConfInfoJack);
