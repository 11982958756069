import Chart from 'chart.js/auto';

const indexDataLabel: { [key: string]: number } = {
  月: 0,
  火: 1,
  水: 2,
  木: 3,
  金: 4,
  土: 5,
  日: 6,
  '1週目': 0,
  '2週目': 1,
  '3週目': 2,
  '4週目': 3,
  '5週目': 4,
  '1月': 0,
  '2月': 1,
  '3月': 2,
  '4月': 3,
  '5月': 4,
  '6月': 5,
  '7月': 6,
  '8月': 7,
  '9月': 8,
  '10月': 9,
  '11月': 10,
  '12月': 11,
};

/**
 * ラベルインデックス取得
 * @author atsushi.teruya
 * @param {Array<string>} lineList -
 * @param {number} lineNumber -
 * @returns {string}
 */
export const getLabelIndex = (key: string): number => {
  const result = indexDataLabel[key];
  return result;
};

/**
 * Chart.js用プラグイン取得
 * @author atsushi.teruya
 * @returns {function}}
 */
export const getChartPlugins = (): Array<{ id: string; beforeTooltipDraw(chart: Chart.Chart): void }> => [
  {
    id: 'chartAreaBorder',
    beforeTooltipDraw(chart: Chart.Chart) {
      const {
        ctx,
        chartArea: { left, top, width, height },
      } = chart;
      ctx.save();
      ctx.strokeStyle = '#8C93B0';
      ctx.lineWidth = 4;
      ctx.beginPath();
      ctx.moveTo(left, top);
      ctx.lineTo(left, top + height);
      ctx.lineTo(left + width, top + height);
      ctx.stroke();
      ctx.restore();
    },
  },
];
