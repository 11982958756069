import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import { ConfListType } from 'types/machineConf/machineConfType';
import COMMON from 'constants/common';
import { getMachineVersion } from 'utils/common.helper';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '350px',
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid',
    borderColor: theme.base.tableBorder,
    '& th,td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.base.tableBorder,
      fontSize: '23px',
    },
    '& tbody tr': {
      height: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: theme.base.tableRowSecondory,
    },
    '& td': {
      padding: '0px 8px 0px 8px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  tdConfValue: {
    textAlign: 'center',
  },
  tdConfName: {
    textAlign: 'left',
  },
  tdReflect: {
    textAlign: 'center',
  },
  tdDetailSetting: {
    textAlign: 'center',
  },
  reflect_detail_setting: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    fontSize: '22px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
}));

/* ************ Component ************ */
type SavedConfTableType = {
  savedConfList: Array<ConfListType>;
  tableId?: string;
  handleSelectedItemChange: (row: ConfListType) => void;
  handleClickReflect: (row: ConfListType) => void;
  handleClickSettingDetail: (row: ConfListType) => void;
};

const SavedConfTable: React.FC<SavedConfTableType> = ({
  savedConfList,
  tableId,
  handleSelectedItemChange,
  handleClickReflect,
  handleClickSettingDetail,
}) => {
  const classes = useStyles();
  const cols = [
    { id: 'confValue', width: 56 },
    { id: 'confName', width: 648 },
    { id: 'machineVersion', width: 88 },
    { id: 'reflect', width: 136 },
    { id: 'detailSetting', width: 152 },
  ];

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          {cols.map((column) => (
            <col key={column.id} style={{ width: column.width }} />
          ))}
          <TableBody>
            {savedConfList.map((row) => {
              const key = `saved_conf_table_key_${row.value}`;
              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={classes.tdConfValue}>
                      <ListCheckBox name="confIdList" onChange={() => handleSelectedItemChange(row)} />
                    </TableCell>
                    <TableCell className={classes.tdConfName}>
                      <div className="cellStyle">{row.name}</div>
                    </TableCell>
                    <TableCell>
                      {/* クレナ3ジャックのステーションはmachineVersion非表示 */}
                      {/* バージョン情報が無い場合は、「Ver.D」を表示する */}
                      {row.machineType === COMMON.MACHINE_TYPE.CLENA3
                        ? `(Ver.${getMachineVersion(row.machineVersion, true)})`
                        : null}
                    </TableCell>
                    <TableCell className={classes.tdReflect}>
                      <NormalButton className={classes.reflect_detail_setting} onClick={() => handleClickReflect(row)}>
                        反映
                      </NormalButton>
                    </TableCell>
                    <TableCell className={classes.tdDetailSetting}>
                      <NormalButton
                        className={classes.reflect_detail_setting}
                        onClick={() => handleClickSettingDetail(row)}>
                        設定詳細
                      </NormalButton>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SavedConfTable;
