import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedConfIcon from 'assets/images/SpeedConfIcon.svg';
import SpeedConfIconYellow from 'assets/images/SpeedConfIconYellow.svg';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { useAppSelector } from 'redux/hooks';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { Box, Table, TableRow } from '@material-ui/core';
import COMMON from 'constants/common';

/**
 * SpeedConfInfoButton Component
 * 落とし穴設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    position: 'relative',
    fontSize: 22,
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    margin: '0 0px 0px 0',
  },
  detailBlockSpeed: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'block',
    marginLeft: '20px',
  },
  img: {
    position: 'relative',
    width: '100px',
    height: '100px',
    marginLeft: '60px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  speedTop: {
    backgroundColor: '#EEEEEE',
    width: '72px',
    height: '40px',
    border: '1px solid #707070',
  },
  speedTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '100px',
    height: '40px',
  },
  X: {
    display: 'inline',
    color: '#FF3E3E',
    marginRight: '10px',
  },
  Y: {
    display: 'inline',
    color: '#3086FF',
  },
  speedTopText: {
    textAlign: 'center',
    color: '#FFC000',
  },
  speedTopZ: {
    color: '#00B050',
  },
}));

type ButtonProps = {
  title: string;
  onClick?: () => void;
};

// コンポーネント定義
const SpeedConfInfoButton: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { title, onClick } = props;

  let speedTopText = (
    <>
      <p className={classes.X}>X</p>
      <p className={classes.Y}>Y</p>
    </>
  );
  if (xySpeedLeverConf && xySpeedLeverConf.leverStatus === COMMON.LEVER_STATUS.TRUE) {
    speedTopText = <Box className={classes.speedTopText}>レバー</Box>;
  }

  return (
    <NormalButton variant="contained" className={classes.button} onClick={onClick}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          <b>{title}</b>
        </Box>
        <Box className={classes.child}>
          <Box>
            <img
              src={
                xySpeedLeverConf && xySpeedLeverConf.leverStatus === COMMON.LEVER_STATUS.FALSE
                  ? SpeedConfIcon
                  : SpeedConfIconYellow
              }
              alt="キャッチャー速度"
              className={classes.img}
            />
          </Box>
          <Table className={classes.detailBlockSpeed}>
            <TableRow>
              <td className={classes.speedTop}>{speedTopText}</td>
              <td className={classes.speedTopValue}>
                {getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(xySpeedLeverConf?.speedXy))}
              </td>
            </TableRow>
            <TableRow>
              <td className={classes.speedTop}>
                <div className={classes.speedTopZ}>Z</div>
              </td>
              <td className={classes.speedTopValue}>
                {getClassificationName(CLSFCN.SPEED_CONF.XYZ, String(xySpeedLeverConf?.speedZ))}
              </td>
            </TableRow>
          </Table>
        </Box>
      </Box>
    </NormalButton>
  );
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(SpeedConfInfoButton);
