import { Box, Grid, makeStyles } from '@material-ui/core';
import TextButton from 'components/system/atoms/buttons/TextButton';
import CLSFCN from 'constants/classification';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { StoreInfoType } from 'types/common/commonType';
import Autosuggest, { InputProps } from 'react-autosuggest';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { getStoreListForSuggestThunk, initializeSuggestions, setStoreInfo } from 'redux/slices/commonSlice';
import { debounce } from 'utils/controlFn.helper';
import { AutoSuggestTheme } from 'utils/customTheme';
import COMMON from 'constants/common';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { ConfirmDialog } from 'components/system/organisms';
import MESSAGES from 'constants/messages';
import { Redirect } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';

/* ************* constant ************* */
const SCREEN_ROLE_STORE = 0;
const SCREEN_ROLE_HEAD_QUQRTERS = 1;
const SCREEN_ROLE_SYSTEM_ADMIN = 2;

const CONTROL_STORE_LABEL = 0;
const CONTROL_STORE_AUTO_SUGGEST = 1;

const MAX_LENGTH = {
  AUTO_SUGGEST_STORE_NAME: 50,
  AUTO_SUGGEST_STORE_ID: 10,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  headerArea: {
    backgroundColor: theme.palette.common.black,
    height: '40px',
    paddingLeft: '104px',
  },
  root: {
    height: '40px',
    width: '1176px',
    whiteSpace: 'nowrap',
    '& p': {
      fontSize: '24px',
      color: '#FFFFFF',
      lineHeight: '40px',
    },
  },
  leftArea: {
    float: 'left',
    paddingLeft: '12px',
  },
  noStore: {
    color: '#FF3E3E !important',
    textDecoration: 'underline',
  },
  storeSuggest: {
    height: '32px',
    marginTop: '4px',
  },
  account: {
    textAlign: 'right',
    paddingRight: '40px',
    float: 'right',
  },
  storeName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '700px',
    textAlign: 'left',
    textTransform: 'none',
  },
  logOut: {
    width: 100,
    marginRight: 32,
    '& p': {
      cursor: 'pointer',
    },
  },
}));

/* ************ Component ************ */
/* ********  Main Component  ********* */
/**
 * ヘッダ
 * 権限とparam.menuにより自身の配下に定義されたコンポーネントを出力する。
 *
 * @author atsushi.teruya
 * @param {string} menu - メニュー項目の識別コード constants/common COMMON.MENUに定義
 * @returns {React.FC} - メニュー項目またはnull
 *
 */
// ヘッダ定義
const Header: React.FC = () => {
  const classes = useStyles();
  const suggestRef = useRef<HTMLInputElement>(null);

  /* ************* dispatch ************* */
  const dispatch = useAppDispatch();
  /* ************* context ************* */
  // ScreenContext page単位で不変な値を設定。
  const { screenId } = useContext(ScreenContext); //

  /* ************** state ************** */
  // global(redux)
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const suggestions = useAppSelector((state) => state.common.suggestions);
  // local
  // 店舗表示制御 0:通常表示(Text Button) ex)店舗ID:XXXXX, 1:店舗入力(AutoSuggestion)
  const [showStoreItem, setShowStoreItem] = useState(CONTROL_STORE_LABEL);
  const [searchStoreString, setSearchStoreString] = useState(''); // 店舗検索用文字列
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const { ROLE } = CLSFCN;

  const alwaysRenderSuggestionsFlag = true;

  // ヘッダ用権限判定
  let role = SCREEN_ROLE_STORE;
  switch (userInfo?.role) {
    case ROLE.SYSTEM_ADMIN:
      role = SCREEN_ROLE_SYSTEM_ADMIN;
      break;
    case ROLE.HEAD_ADMIN:
    case ROLE.HEAD_VIEWER:
      role = SCREEN_ROLE_HEAD_QUQRTERS;
      break;
    default:
  }

  // 店舗未選択時の表示メッセージ 初めは変更不可画面におけるメッセージを設定
  let unselectedStoreMessage = 'HOME画面で店舗を選択してください。';
  // システム管理者の場合
  if (role === SCREEN_ROLE_SYSTEM_ADMIN) {
    unselectedStoreMessage = 'ユーザー一覧画面で店舗を選択してください。';
  }

  let enableChangeStore = false;
  if (
    (role === SCREEN_ROLE_HEAD_QUQRTERS && screenId === COMMON.ENABLE_STORE_CHANGE.HEAD_QUARTERS) ||
    (role === SCREEN_ROLE_SYSTEM_ADMIN && screenId === COMMON.ENABLE_STORE_CHANGE.SYSTEM_ADMIN)
  ) {
    // 本部系権限でTOP または システム管理者でユーザ一覧の場合、store指定が可能
    enableChangeStore = true;

    // 変更可能画面の場合は店舗選択を促すメッセージを表示
    unselectedStoreMessage = 'ここを選択し、店舗を指定してください。';
  }

  /* ************ Event ************ */
  // サジェスト表示直後はフォーカスを強制であてる。
  useEffect(() => {
    if (showStoreItem === CONTROL_STORE_AUTO_SUGGEST) {
      suggestRef.current?.focus();
    }
  }, [showStoreItem]);

  // 店舗指定クリック
  const handleStoreClick = () => {
    // 店舗系権限の場合は何もしない。
    if (!enableChangeStore) return;
    // autosuggestを表示
    setShowStoreItem(CONTROL_STORE_AUTO_SUGGEST);
    setSearchStoreString('');
  };

  // Event For suggest
  // サジェスト 取得 1秒遅延で処理する。
  // 毎回の描画で関数が新規に作成されると、debounceで利用する内部のtimeridが保持できないため、usecallbackする。
  const handleSuggestionsFetchClick = useCallback(
    debounce((request: { value: string }) => {
      const inputValue = request.value.trim();
      const inputLength = inputValue.length;

      // dispatchでreduxのstateを更新する。
      // 未入力時はサジェストを初期化
      if (inputLength === 0) {
        dispatch(getStoreListForSuggestThunk({ searchString: '' }));
      } else {
        dispatch(getStoreListForSuggestThunk({ searchString: inputValue }));
      }
    }, 1000),
    [],
  );

  // サジェスト 選択
  const handleSuggestionSelected = (event: React.FormEvent<HTMLElement>, data: { suggestion: StoreInfoType }) => {
    // 取得した店舗情報を設定

    // 現在の本部、店舗の変更をサーバーに送信
    const http = new HttpConnection({ dispatch });
    http
      .put(
        REST_API.COMMON.CHANGE_CURRENT,
        {},
        {
          headquarters_id: data.suggestion.currentHeadQId,
          headquarters_name: data.suggestion.currentHeadQName,
          store_id: data.suggestion.currentStoreId,
          store_name: data.suggestion.currentStoreName,
        },
      )
      .then(() => {
        dispatch(setStoreInfo(data.suggestion));
      })
      .finally(() => {
        suggestRef.current?.blur();
        setShowStoreItem(CONTROL_STORE_LABEL);
      });
  };

  // サジェスト 選択値反映
  const getSuggestionValue = (suggestion: StoreInfoType) => suggestion.currentStoreName;

  // サジェスト 描画
  const renderSuggestion = (suggestion: StoreInfoType) => (
    <span>
      {role === SCREEN_ROLE_HEAD_QUQRTERS ? (
        <>{suggestion.currentStoreName}</>
      ) : (
        <>
          {suggestion.currentStoreId}/{suggestion.currentStoreName}
        </>
      )}
    </span>
  );

  // サジェスト 入力値変更
  const handleStoreChange = (event: React.FormEvent<HTMLElement>, params: { newValue: string }) => {
    if (event) {
      setSearchStoreString(params.newValue);
    }
  };

  // サジェスト 入力値フォーカスアウト
  const handleStoreBlur = (event: React.FormEvent<HTMLElement>) => {
    if (event) {
      setShowStoreItem(CONTROL_STORE_LABEL);
      setSearchStoreString('');
      dispatch(initializeSuggestions());
    }
  };

  // AUTO SUGGESTのinput item に対する指定。
  // input itemにフォーカスあてるためrefを指定する。
  const inputProps: InputProps<StoreInfoType> = {
    placeholder: '',
    maxLength:
      role === SCREEN_ROLE_HEAD_QUQRTERS ? MAX_LENGTH.AUTO_SUGGEST_STORE_NAME : MAX_LENGTH.AUTO_SUGGEST_STORE_ID,
    value: searchStoreString,
    ref: suggestRef,
    onChange: handleStoreChange,
    onBlur: handleStoreBlur,
  };

  // Call api/logout if success set isLogout is true.
  const handleLogOut = async () => {
    setIsOpenConfirmDialog(false);
    const http = new HttpConnection({ dispatch });
    const response = await http.post(REST_API.LOGOUT);
    if (response === 'Logout successful') setIsLogout(true);
  };

  // If isLogout is true will redirec to login page.
  if (isLogout) return <Redirect to={redirectLinks.LOGIN} />;

  return (
    <header>
      <Box className={classes.headerArea}>
        <Grid container wrap="nowrap" className={classes.root}>
          <Grid item xs={9}>
            <p className={classes.leftArea}>【CLENA-NET】</p>
            {showStoreItem === CONTROL_STORE_LABEL ? (
              <TextButton onClick={handleStoreClick} disabled={!enableChangeStore}>
                {/* 店舗IDがない場合は指定可能とする。本部、システム管理者しか通らない想定 */}
                {userInfo?.currentStoreId ? (
                  <p className={classes.storeName}>{userInfo?.currentStoreName}</p>
                ) : (
                  <p className={classes.noStore}>{unselectedStoreMessage}</p>
                )}
              </TextButton>
            ) : (
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={handleSuggestionsFetchClick}
                onSuggestionSelected={handleSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={AutoSuggestTheme}
                alwaysRenderSuggestions={alwaysRenderSuggestionsFlag}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <p className={classes.account}>アカウント {userInfo?.accountId} </p>
          </Grid>

          <Grid container item className={classes.logOut} onClick={() => setIsOpenConfirmDialog(true)}>
            <p>ログアウト</p>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        open={isOpenConfirmDialog}
        msg={MESSAGES.INF100}
        onOk={handleLogOut}
        onCancel={() => setIsOpenConfirmDialog(false)}
      />
    </header>
  );
};

export default Header;
