import React, { useCallback, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {clearShowDialogCheckVersion, setAppVersion} from 'redux/slices/commonSlice';
import MESSAGES from 'constants/messages';
import HttpConnection from 'utils/httpConnection';
import { AppInfoType } from 'types/common/commonType';
import { REST_API } from 'constants/apiUrls';
import { InfoDialog } from './index';


const VersionChecking: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { isShowAlertVersion: isShow } = useAppSelector((state) => state.common.appInfo);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setOpen(isShow ?? false);
  }, [isShow]);

  const getVersionApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<AppInfoType>(REST_API.COMMON.GET_VERSION_NO);
    if (data) {
      dispatch(setAppVersion(data.versionNo));
    }
  };

  useEffect(() => {
    getVersionApi().then();
  }, [location.pathname]);

  const closeDialogCheckVersion = useCallback(() => {
    dispatch(clearShowDialogCheckVersion());
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, []);

  return <InfoDialog open={open} msg={MESSAGES.INF312} closeFunc={() => closeDialogCheckVersion()} />;
};

export default VersionChecking;
