import { NativeSelect, makeStyles, InputLabel, NativeSelectProps, Box } from '@material-ui/core';
import React, { memo } from 'react';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {},
  label: {
    color: '#0D0D0D',
    margin: '0 8px 0 0',
    fontSize: '23px',
  },
  selectBox: {
    color: '#0D0D0D',
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    margin: '4px 0 0 0',
    height: '48px',
    fontSize: '23px',
    '&.MuiInputBase-root.Mui-disabled': {
      color: '#0D0D0D',
      backgroundColor: '#EBEBEB',
    },
  },
}));

/* ************ Type ************ */
type dataSourceProps = {
  name: string;
  value: string;
};

type SelectBoxWithLabelProps = NativeSelectProps & {
  dataSource: Array<dataSourceProps>;
  label?: string;
  exClassName?: string;
  labelPlacement?: 'start' | 'top';
};
/* ************ Function ************ */
const setLabelPlacement = (labelPlacement: string): string => {
  if (labelPlacement === 'start') {
    return 'row';
  }
  return 'column';
};

/* ************ Component ************ */
const SelectBoxWithLabel: React.FC<SelectBoxWithLabelProps> = memo(
  ({ id, name, dataSource, value, onChange, label, exClassName, labelPlacement = 'start', disabled, fullWidth }) => {
    const classes = useStyles();

    return (
      <Box
        display="flex"
        flexDirection={setLabelPlacement(labelPlacement)}
        alignItems="baseline"
        className={`${classes.root} ${exClassName}`}>
        {label && (
          <InputLabel htmlFor={id} className={classes.label}>
            {label}
          </InputLabel>
        )}
        <NativeSelect
          id={id}
          className={classes.selectBox}
          disabled={disabled}
          fullWidth={fullWidth}
          name={name}
          value={value}
          variant="outlined"
          onChange={onChange}
          disableUnderline>
          {dataSource.map((option) => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </NativeSelect>
      </Box>
    );
  },
);

export default SelectBoxWithLabel;
