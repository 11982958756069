/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import Layout from 'components/system/layouts/Layout';
import { ReturnButton, SelectButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import PitPanelJack from 'components/machineConf/organisms/Pit/PitPanelJack';
import AreaDetailJack from 'components/machineConf/organisms/Area/AreaDetailJack';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import { PitConfType } from 'types/machineConf/machineConfType';
import { SelectListType } from 'types/support/supportType';
import { setPitConf } from 'redux/slices/machineConfSlice';
import { ConfirmDialog } from 'components/system/organisms';
import MESSAGES from 'constants/messages';
import produce from 'immer';
import PitPinpointPanelJack from 'components/machineConf/organisms/Pit/PitPinpointPanelJack';
import PitPinpointInfoJack from 'components/machineConf/organisms/Pit/PitPinpointInfoJack';
import MessageHelper from 'utils/message.helper';
import { handleCallFrontError, handleCallSystemError } from 'redux/slices/commonSlice';
import PositionControllerJack from 'components/machineConf/organisms/Controllers/PositionControllerJack';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import ERROR_CODE from 'constants/errorCode';
import BoundAreaDetailJack from 'components/machineConf/organisms/Area/BoundAreaDetailJack';

const PRIZE_AREA_MAX = 100;
const PRIZE_AREA_MAX_Y = 80;

const SIZE = {
  POSITION_CONTROLLER: '52px',
  PIT_ICON: '24px',
  PANEL: '390px',
  PANEL_HEIGHT: '341px',
};

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '100px',
    '& .description': {
      position: 'absolute',
      top: '12px',
      left: '220px',
      lineHeight: '28px',
      fontSize: '23px',
      '& .doneImage': {
        width: '56px',
        height: '28px',
        padding: '2px 0px',
        borderRadius: '4px',
        fontSize: '16px',
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'inline-block',
        background: theme.palette.common.black,
        color: theme.palette.common.white,
      },
    },
  },
  mainArea: {
    display: 'flex',
    '& .settingPattern': {
      position: 'absolute',
      top: '100px',
      left: '0px',
      width: '386px',
      height: '80px',
      fontSize: '30px',
      borderRadius: '12px',
    },
    '& .pinpointInfo': {
      position: 'absolute',
      top: '188px',
      left: '0px',
      width: '386px',
      '& .description': {
        height: '64px',
        lineHeight: '28px',
        fontSize: '23px',
      },
    },
    '& .pitConfArea': {
      position: 'absolute',
      top: '100px',
      left: '438px',
    },
    '& .doneArea': {
      position: 'absolute',
      top: '464px',
      left: '900px',
    },
  },
  mainLeftArea: {
    width: '386px',
  },
  pinpointArea: {
    position: 'relative',
    '& .pit1': {
      position: 'absolute',
      top: '0px',
      left: '32px',
    },
    '& .pit2': {
      position: 'absolute',
      top: '0px',
      left: '200px',
    },
    '& .pit3': {
      position: 'absolute',
      top: '156px',
      left: '32px',
    },
    '& .pit4': {
      position: 'absolute',
      top: '156px',
      left: '200px',
    },
  },
  pinpointInfoLine: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '8px',
  },
  mainPitArea: {
    position: 'relative',
    marginLeft: '-40px',
    fontSize: '18px',
    height: '470px',
    width: '470px',
    '& .areaDetail': {
      position: 'absolute',
      top: 28,
      left: '28px',
    },
    '& .pitPanel': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: `calc(${SIZE.PANEL} + 80px)`,
      height: `calc(${SIZE.PANEL_HEIGHT} + 80px)`,
      padding: '40px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      top: '386px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '-5px' : '438px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
    },
    '& .maxPositionX': {
      position: 'absolute',
      top: '386px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '438px' : '8px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'start' : 'end',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '15px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? '-5px' : '438px',
      width: '32px',
      textAlign: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT ? 'end' : 'start',
    },
    '& .pinpointPanel': {
      position: 'absolute',
      top: '-58px',
      left: '28px',
    },
    '& .xController': {
      position: 'absolute',
      top: '390px',
      left: '144px',
    },
    '& .yController': {
      position: 'absolute',
      top: '144px',
      left: (props: { leftRight: string | undefined }) =>
        props.leftRight === COMMON.LEFT_RIGHT.LEFT
          ? '-20px'
          : `calc(${SIZE.POSITION_CONTROLLER} + ${SIZE.PANEL} + ${SIZE.PIT_ICON})`,
    },
  },
  buttonLabel: {
    color: '#000000',
  },
}));

const ScreenContextValue = {
  title: '落とし穴設定',
  screenId: COMMON.SCREEN_ID.PIT_CONF_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  SETTING_PATTERN: 'settingPattern',
  PIT_1_STATUS: 'pit1Status',
  PIT_2_STATUS: 'pit2Status',
  PIT_3_STATUS: 'pit3Status',
  PIT_4_STATUS: 'pit4Status',
};

const PitConfJack: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);

  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tempPitConf, setTempPitConf] = useState<PitConfType>({
    settingPattern: pitConf?.settingPattern || '',
    line1: pitConf?.line1 || '',
    line2: pitConf?.line2 || '',
    line3: pitConf?.line3 || '',
    line4: pitConf?.line4 || '',
    line5: pitConf?.line5 || '',
    line6: pitConf?.line6 || '',
    pit1x: pitConf?.pit1x || 0,
    pit2x: pitConf?.pit2x || 0,
    pit3x: pitConf?.pit3x || 0,
    pit4x: pitConf?.pit4x || 0,
    pit1y: pitConf?.pit1y || 0,
    pit2y: pitConf?.pit2y || 0,
    pit3y: pitConf?.pit3y || 0,
    pit4y: pitConf?.pit4y || 0,
    pit1Status: pitConf?.pit1Status || '',
    pit2Status: pitConf?.pit2Status || '',
    pit3Status: pitConf?.pit3Status || '',
    pit4Status: pitConf?.pit4Status || '',
  });
  const classes = useStyles({ leftRight });
  const [pit1, setPit1] = useState({ x: tempPitConf.pit1x, y: tempPitConf.pit1y });
  const [pit2, setPit2] = useState({ x: tempPitConf.pit2x, y: tempPitConf.pit2y });
  const [pit3, setPit3] = useState({ x: tempPitConf.pit3x, y: tempPitConf.pit3y });
  const [pit4, setPit4] = useState({ x: tempPitConf.pit4x, y: tempPitConf.pit4y });
  const [selectedPinpoint, setSelectedPinpoint] = useState<number | null>(1);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const selectedListRef = useRef<string>('');

  const pitConfigPatternDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.PIT_CONFIG_JACK.PATTERN_CATEGORY_CD), []);
  const MachineConfContextValue = { leftRight };
  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  const minX = Number(nonAreaXMinSide);
  const maxX = PRIZE_AREA_MAX - Number(nonAreaXMaxSide);
  const minY = Number(nonAreaYMinSide);
  const maxY = PRIZE_AREA_MAX_Y - Number(nonAreaYMaxSide);

  useEffect(() => {
    if (!pitConf || !areaConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  /* ************ Event ************ */
  useEffect(() => {
    setPit1({ x: tempPitConf.pit1x, y: tempPitConf.pit1y });
    setPit2({ x: tempPitConf.pit2x, y: tempPitConf.pit2y });
    setPit3({ x: tempPitConf.pit3x, y: tempPitConf.pit3y });
    setPit4({ x: tempPitConf.pit4x, y: tempPitConf.pit4y });
  }, [tempPitConf]);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };

  function replaceAt(str: string, index: number, ch: string) {
    if (!str) return str;
    return str.replace(/./g, (c, i) => (i === index ? ch : c));
  }

  // 確定
  const handleClickDone = async () => {
    if (tempPitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.PINPOINT) {
      // ピンポイントの場合は入力チェックを実施する。 他と合わせてサーバー側で実行するか別途検討する。
      // 有効無効選択1~4がすべて無効の場合
      if (
        [tempPitConf.pit1Status, tempPitConf.pit2Status, tempPitConf.pit3Status, tempPitConf.pit4Status].every(
          (value) => value === CLSFCN.PIT_CONFIG.STATUS.DISABLED,
        )
      ) {
        dispatch(
          handleCallFrontError({
            errors: { key: [MessageHelper.messageFormat(MESSAGES.INF101)] },
          }),
        );
        return;
      }
      // 落とし穴1~落とし穴4の縦欄、横欄の値がすべてnull（なし）の場合
      if (
        [
          tempPitConf.pit1x,
          tempPitConf.pit1y,
          tempPitConf.pit2x,
          tempPitConf.pit2y,
          tempPitConf.pit3x,
          tempPitConf.pit3y,
          tempPitConf.pit4x,
          tempPitConf.pit4y,
        ].every((value) => value === null)
      ) {
        dispatch(
          handleCallFrontError({
            errors: { key: [MessageHelper.messageFormat(MESSAGES.INF102)] },
          }),
        );
        return;
      }
    } else {
      const replaceIndex = 0;
      const replaceAreaConfValue = '0';

      const tempPitConfJack = {
        ...tempPitConf,
        line1: replaceAt(tempPitConf.line1, replaceIndex, replaceAreaConfValue),
        line2: replaceAt(tempPitConf.line2, replaceIndex, replaceAreaConfValue),
        line3: replaceAt(tempPitConf.line3, replaceIndex, replaceAreaConfValue),
        line4: replaceAt(tempPitConf.line4, replaceIndex, replaceAreaConfValue),
        line5: replaceAt(tempPitConf.line5, replaceIndex, replaceAreaConfValue),
        line6: replaceAt(tempPitConf.line6, replaceIndex, replaceAreaConfValue),
      };

      let isError = true;
      let lineOk = -1;
      let indexOk = -1;
      ['line1', 'line2', 'line3', 'line4', 'line5', 'line6'].every((item, indexLine) => {
        const line = ((tempPitConfJack as never)[item] as string).split('');
        const settingIsSimple = tempPitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE;
        line.every((value, index) => {
          let isOne;
          if (!settingIsSimple) {
            isOne = value === '1';
          }
          if (settingIsSimple && index <= 4 && index % 2 === 0) {
            if (index === 0) {
              isOne = line[index + 1] === '1';
            } else {
              isOne = value === '1' && line[index + 1] === '1';
            }
            if (isOne && indexLine % 2 === 0) {
              lineOk = indexLine;
              indexOk = index;
              return true;
            }
            if (isOne && indexLine - lineOk === 1 && indexOk === index) {
              isError = false;
              return false;
            }
            return true;
          }
          if (isOne) {
            isError = false;
            return false;
          }
          return true;
        });
        return isError;
      });
      if (isError) {
        const errors = {
          errors: { key: [MessageHelper.messageFormat(MESSAGES.INF104)] },
        };
        dispatch(handleCallFrontError(errors));
        return;
      }
    }

    dispatch(setPitConf(tempPitConf));

    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http.post<PitConfType>(REST_API.MACHINE_CONF.PIT_CONF_VALIDATION, tempPitConf);

    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };
  // 設定パターン > 選択
  const handleSelectListClick = useCallback(
    (data: { value: string }) => {
      selectedListRef.current = data.value;
      setSelectList(INITIAL_SELECT_LIST.selectList);

      if (
        [CLSFCN.PIT_CONFIG.PATTERN.SIMPLE, CLSFCN.PIT_CONFIG.PATTERN.DETAIL, undefined].includes(
          tempPitConf.settingPattern,
        ) &&
        data.value === CLSFCN.PIT_CONFIG.PATTERN.PINPOINT
      ) {
        setOpenConfirmDialog(true);
        return;
      }

      if (CLSFCN.PIT_CONFIG.PATTERN.PINPOINT === tempPitConf.settingPattern) {
        setIsOpenConfirmDialog(true);
        return;
      }

      setTempPitConf((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            settingPattern: data.value,
          };
        }
        return prevState;
      });
    },
    [tempPitConf],
  );

  // 設定パターン押下
  const handleClickConfPattern = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        open: true,
        anchorEL: event.currentTarget,
        dataSource: pitConfigPatternDataSource,
        name: INPUT_NAMES.SETTING_PATTERN,
        placement: 'right-start',
        title: '選択してください',
        value: tempPitConf.settingPattern,
        onClick: handleSelectListClick,
        onClose: () => {
          setSelectList(INITIAL_SELECT_LIST.selectList);
        },
      });
    },
    [tempPitConf.settingPattern, handleSelectListClick],
  );

  // 設定パターン > ピンポイント選択 > 確認ダイアログOK
  const handleSelectListConfirmOk = useCallback(() => {
    setOpenConfirmDialog(false);

    setTempPitConf(
      produce(tempPitConf, (draft) => {
        draft.settingPattern = CLSFCN.PIT_CONFIG.PATTERN.PINPOINT;
      }),
    );
  }, [tempPitConf]);

  const handleSelectListConfirm = useCallback(() => {
    setIsOpenConfirmDialog(false);
    setTempPitConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          settingPattern: selectedListRef.current,
        };
      }
      return prevState;
    });
  }, [tempPitConf]);

  // 落とし穴変更時
  const handleChangePit = useCallback((changedPitConf: PitConfType) => {
    setTempPitConf(changedPitConf);
  }, []);

  // ピンポイント落とし穴移動中 ※毎回PitConf全体を更新すると重くなるので、移動中は軽量なステートの更新で済ませる
  const handleDragPinpointPit = useCallback((pos: { x: number; y: number }, pitNumber) => {
    if (pitNumber === 1) setPit1(pos);
    if (pitNumber === 2) setPit2(pos);
    if (pitNumber === 3) setPit3(pos);
    if (pitNumber === 4) setPit4(pos);
  }, []);

  // ピンポイント落とし穴移動完了
  const handleDragEndPinpointPit = useCallback(
    (pos: { x: number; y: number }, pitNumber) => {
      setTempPitConf((prevState) => ({
        ...prevState,
        [`pit${pitNumber}x`]: pos.x,
        [`pit${pitNumber}y`]: pos.y,
      }));
    },
    [tempPitConf],
  );

  // ピンポイント 落とし穴 選択
  const handlePinpointInfoClick = useCallback((pos: { x: number | null; y: number | null }, pitNumber: number) => {
    if (pos.x === null && pos.y === null) {
      setTempPitConf((prevState) => ({
        ...prevState,
        [`pit${pitNumber}x`]: 0,
        [`pit${pitNumber}y`]: 0,
      }));
    }
    setSelectedPinpoint(pitNumber);
  }, []);

  // ピンポイント 有効/無効 変更時
  const handlePinpointStatusChange = useCallback(
    (key: string, value: string) => {
      setTempPitConf((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    [tempPitConf],
  );

  // 位置調整
  const handleClickPositionController = (value: string, name: string) => {
    const key = `pit${selectedPinpoint}${name}` as keyof typeof tempPitConf;
    if (name === 'x') {
      if (Number(value) < minX && Number(tempPitConf[key]) >= minX) {
        return false;
      }
      if (Number(value) > maxX && Number(tempPitConf[key]) <= maxX) {
        return false;
      }
    } else {
      if (Number(value) < minY && Number(tempPitConf[key]) >= minY) {
        return false;
      }
      if (Number(value) > maxY && Number(tempPitConf[key]) <= maxY) {
        return false;
      }
    }
    setTempPitConf((prevState) => ({
      ...prevState,
      [`pit${selectedPinpoint}${name}`]: Number(value),
    }));
    return true;
  };

  const getX = useCallback(() => {
    if (selectedPinpoint === 1) return String(pit1.x);
    if (selectedPinpoint === 2) return String(pit2.x);
    if (selectedPinpoint === 3) return String(pit3.x);
    if (selectedPinpoint === 4) return String(pit4.x);
    return '';
  }, [selectedPinpoint, pit1.x, pit2.x, pit3.x, pit4.x]);

  const getY = useCallback(() => {
    const pit1Y = (pit1.y || 0) < PRIZE_AREA_MAX_Y ? pit1.y : PRIZE_AREA_MAX_Y;
    const pit2Y = (pit2.y || 0) < PRIZE_AREA_MAX_Y ? pit2.y : PRIZE_AREA_MAX_Y;
    const pit3Y = (pit3.y || 0) < PRIZE_AREA_MAX_Y ? pit3.y : PRIZE_AREA_MAX_Y;
    const pit4Y = (pit4.y || 0) < PRIZE_AREA_MAX_Y ? pit4.y : PRIZE_AREA_MAX_Y;

    if (selectedPinpoint === 1) return String(pit1Y);
    if (selectedPinpoint === 2) return String(pit2Y);
    if (selectedPinpoint === 3) return String(pit3Y);
    if (selectedPinpoint === 4) return String(pit4Y);
    return '';
  }, [selectedPinpoint, pit1.y, pit2.y, pit3.y, pit4.y]);
  const pinpointPitList = [
    {
      title: '落とし穴１',
      status: tempPitConf.pit1Status,
      pitX: pit1.x,
      pitY: pit1.y,
      inputName: INPUT_NAMES.PIT_1_STATUS,
    },
    {
      title: '落とし穴２',
      status: tempPitConf.pit2Status,
      pitX: pit2.x,
      pitY: pit2.y,
      inputName: INPUT_NAMES.PIT_2_STATUS,
    },
    {
      title: '落とし穴３',
      status: tempPitConf.pit3Status,
      pitX: pit3.x,
      pitY: pit3.y,
      inputName: INPUT_NAMES.PIT_3_STATUS,
    },
    {
      title: '落とし穴４',
      status: tempPitConf.pit4Status,
      pitX: pit4.x,
      pitY: pit4.y,
      inputName: INPUT_NAMES.PIT_4_STATUS,
    },
  ];

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                {/* 戻るボタン */}
                <Box>
                  <ReturnButton onClick={handleClickReturn}>
                    移動設定
                    <br />
                    TOP
                  </ReturnButton>
                </Box>
                {/* 操作説明 */}
                <Box className="description">
                  「落とし穴」を設定できます。設定方法を選択し、タッチまたはスライドして、
                  <br />
                  穴にしたい位置に「○」を配置し、<Typography className="doneImage">確 定</Typography>
                  してください。
                </Box>
              </Box>
              <Box className={classes.mainArea}>
                <Box className={classes.mainLeftArea}>
                  {/* 設定パターン */}
                  <SelectButton
                    className="settingPattern"
                    onClick={handleClickConfPattern}
                    isSelected={selectList.name === INPUT_NAMES.SETTING_PATTERN}
                    leftRight={COMMON.LEFT_RIGHT.RIGHT}
                    arrowSize="32px">
                    {getClassificationName(
                      CLSFCN.PIT_CONFIG_JACK.PATTERN_CATEGORY_CD,
                      tempPitConf.settingPattern || '0',
                    )}
                  </SelectButton>
                  {tempPitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.PINPOINT && (
                    <Box className="pinpointInfo">
                      {/* ピンポイント設定 */}
                      <Box className="description">ピンポイント落とし穴を最大４つまで登録できます。</Box>
                      <Box className={classes.pinpointArea}>
                        {pinpointPitList.map((pinpointInfo, index) => {
                          const pinpointIndex = index + 1;
                          const key = `pit${String(pinpointIndex)}`;
                          return (
                            <Box key={key} className={key}>
                              <PitPinpointInfoJack
                                title={pinpointInfo.title}
                                isSelected={selectedPinpoint === pinpointIndex}
                                status={pinpointInfo.status}
                                pitX={pinpointInfo.pitX}
                                pitY={
                                  Number(pinpointInfo.pitY) <= PRIZE_AREA_MAX_Y ? pinpointInfo.pitY : PRIZE_AREA_MAX_Y
                                }
                                onClick={() => {
                                  handlePinpointInfoClick(
                                    { x: pinpointInfo.pitX, y: pinpointInfo.pitY },
                                    pinpointIndex,
                                  );
                                }}
                                onChangeStatus={(newStatus) => {
                                  handlePinpointStatusChange(pinpointInfo.inputName, newStatus);
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* パネル 落とし穴設定 */}
                <Box className="pitConfArea">
                  <Box className={classes.mainPitArea}>
                    <AreaDetailJack
                      className="areaDetail"
                      leftRight="2"
                      size="L"
                      areaConf={areaConf}
                      defaultBorderColor
                      pitConf={
                        tempPitConf.settingPattern === CLSFCN.PIT_CONFIG_JACK.PATTERN.DETAIL ? tempPitConf : undefined
                      }
                      isDefault={operationConf?.operationConf !== COMMON.OPERATION_CONF.NO_CONF}
                    />
                    {/* 落とし穴 かんたん・詳細 */}
                    {tempPitConf.settingPattern !== CLSFCN.PIT_CONFIG.PATTERN.PINPOINT && (
                      <PitPanelJack className="pitPanel" pitConf={tempPitConf} onChange={handleChangePit} />
                    )}
                    {/* 落とし穴 ピンポイント */}
                    {tempPitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.PINPOINT && (
                      <>
                        <BoundAreaDetailJack
                          className="areaDetail"
                          isLeft={leftRight === COMMON.LEFT_RIGHT.LEFT}
                          size="L"
                        />
                        <Box className="minPositionXY">0</Box>
                        <Box className="maxPositionX">100</Box>
                        <Box className="maxPositionY">80</Box>

                        <PitPinpointPanelJack
                          className="pinpointPanel"
                          pitConf={tempPitConf}
                          selectedPitNumber={selectedPinpoint}
                          onChange={handleDragPinpointPit}
                          onChangeEnd={handleDragEndPinpointPit}
                        />
                        <Box className="xController">
                          <PositionControllerJack
                            name="x"
                            axis="X"
                            className={classes.buttonLabel}
                            value={getX()}
                            onClickPositionController={handleClickPositionController}
                          />
                        </Box>
                        <Box className="yController">
                          <PositionControllerJack
                            name="y"
                            axis="Y"
                            className={classes.buttonLabel}
                            value={getY()}
                            onClickPositionController={handleClickPositionController}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box className="doneArea">
                  <DoneButton onClick={handleClickDone}>確 定</DoneButton>
                </Box>
              </Box>
            </Box>
            <SelectList selectList={selectList} />
          </MachineConfContent>
          <ConfirmDialog
            open={openConfirmDialog}
            msg={MESSAGES.INF201}
            onOk={handleSelectListConfirmOk}
            onCancel={() => {
              setOpenConfirmDialog(false);
            }}
          />
          <ConfirmDialog
            open={isOpenConfirmDialog}
            msg={MESSAGES.INF240}
            onOk={handleSelectListConfirm}
            onCancel={() => {
              setIsOpenConfirmDialog(false);
            }}
          />
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default PitConfJack;
