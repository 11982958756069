import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectBox } from 'components/system/atoms';
import MenuTab from 'components/data/organisms/tabs/MenuTab';
import ContentMenuTab from 'components/data/organisms/tabs/ContentMenuTab';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import HttpConnection from 'utils/httpConnection';
import {
  CostRateDataType,
  CostRateDataViewType,
  getCostRateDataType,
  LineDataType,
  TypeEventClickMenu,
} from 'types/data/dataType';
import { REST_API } from 'constants/apiUrls';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { getChartPlugins } from 'utils/data.helper';
import UrlHelper from 'utils/url.helper';
import { formatPercent } from 'utils/number.helper';
import { substrMaxSize } from 'utils/string.helper';
import useCostRateInfoList from 'customHooks/useCostRateInfoList';
import {
  resetTabView,
  tabActive,
  tabViewIsDay,
  tabViewIsMonth,
  tabViewIsWeek,
  tabViewIsYear,
} from 'redux/slices/historyActionTabmenu';

import { USE_REDUX_VALUES } from './DataStationList';
import NormalButton from '../../components/system/atoms/buttons/NormalButton';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: '原価率データ閲覧',
  screenId: 'SCR222',
  currentMenu: COMMON.MENU.DATA,
};

/* ********** Constants ********** */
// 表示するデータ数
const MAX_DISPLAY_LINE = 10;
// 凡例ラベルの文字数
const LABEL_MAX_LEN = 10;
// 上部タブメニュー
const MENU_SETTING = [
  { label: '日', dataViewUnit: COMMON.DATA_VIEW_UNIT.DAILY },
  { label: '週', dataViewUnit: COMMON.DATA_VIEW_UNIT.WEEKLY },
  { label: '月', dataViewUnit: COMMON.DATA_VIEW_UNIT.MONTHLY },
  { label: '年', dataViewUnit: COMMON.DATA_VIEW_UNIT.YEARLY },
];
// X軸のラベル
const X_LABELS: {
  [key: string]: string;
} = {
  [COMMON.DATA_VIEW_UNIT.DAILY]: '(時間)',
  [COMMON.DATA_VIEW_UNIT.WEEKLY]: '(曜日)',
  [COMMON.DATA_VIEW_UNIT.MONTHLY]: '(週目)',
  [COMMON.DATA_VIEW_UNIT.YEARLY]: '(月)',
};

// X軸のラベル
const XAxisLabels: {
  [key: string]: Array<string>;
} = {
  [COMMON.DATA_VIEW_UNIT.DAILY]: ['午前', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
  [COMMON.DATA_VIEW_UNIT.WEEKLY]: ['月', '火', '水', '木', '金', '土', '日'],
  [COMMON.DATA_VIEW_UNIT.MONTHLY]: ['1週目', '2週目', '3週目', '4週目', '5週目', '6週目'],
  [COMMON.DATA_VIEW_UNIT.YEARLY]: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
};
// 折れ線グラフのカラー
const DATA_COLOR = [
  '#EF5350',
  '#7986CB',
  '#66BB6A',
  '#FFA726',
  '#F06292',
  '#42A5F5',
  '#AED581',
  '#BA68C8',
  '#4DD0E1',
  '#FFF176',
];

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '24px 0px 0px 4px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      padding: '4px 0px 0px 24px',
      width: '224px',
    },
    '& .menuArea': {
      backgroundColor: '#535B80',
      borderRadius: '4px 4px 0px 0px',
      marginLeft: '24px',
      height: '82px',
      width: '892px',
    },
  },
  mainArea: {
    backgroundColor: '#535B80',
    borderRadius: '4px 0px 4px 4px',
    display: 'flex',
    flexDirection: 'column',
    height: '516px',
    width: '1136px',
    '& .contentItem': {
      marginTop: '-60px',
    },
  },
  itemTopArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '8px',
  },
  itemMainArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '4px',
    '& .contentMenuArea': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '20px',
      width: '120px',
      '& .buttonArea': {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      },
    },
    '& .viewArea': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      height: '508px',
      width: '1008px',
    },
  },
  dataSheetbtn: {
    alignSelf: 'flex-end',
    width: '112px',
    height: '70px',
    fontSize: 21,
    lineHeight: '28px',
  },
  graphOptionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '6px',
    padding: '0px 20px 0px 0px',
    '& .salesLimit': {
      marginRight: 'auto',
      marginLeft: '12px',
      '& > div': {
        width: '168px',
      },
    },
    '& .viewPeriod': {
      border: '1px solid',
      borderColor: theme.palette.common.black,
      borderRadius: '4px',
      height: '32px',
      width: '428px',
      textAlign: 'center',
      fontSize: '19px',
      lineHeight: '32px',
      marginRight: '20px',
      cursor: 'pointer',
    },
    '& .sortLabel': {
      margin: '4px 8px 0px 0px',
      fontSize: '19px',
    },
  },
  graphArea: {
    position: 'relative',
    height: '467px',
    width: '100%',
    padding: '32px 12px 0px 0px',
    '& .yAxesUnit': {
      position: 'absolute',
      top: '0px',
      left: '10px',
      fontSize: '16px',
    },
    '& .prevScroll': {
      position: 'absolute',
      top: '0px',
      left: '420px',
    },
    '& .graph': {
      height: '424px',
      width: '100%',
    },
    '& .nextScroll': {
      position: 'absolute',
      top: '436px',
      left: '420px',
    },
    '& .xAxesUnit': {
      position: 'absolute',
      top: '395px',
      left: '890px',
      fontSize: '16px',
    },
  },
}));

/* ********** local function ********** */
// 表示範囲のデータを取得する
const getData = (
  index: number,
  costRateInfoList: Array<CostRateDataType>,
  dataViewUnit: string,
  detailNum: number,
): LineDataType => {
  const startIndex = index; // 1件目に表示するデータのindex
  const costRateLen = costRateInfoList.length; // データ数
  let labels = XAxisLabels[dataViewUnit]; // x軸のラベル
  // 月次の場合は週数(月～日を1週として最大6週)に応じて、ラベルを変更する。
  if (dataViewUnit === COMMON.DATA_VIEW_UNIT.MONTHLY) {
    labels = labels.slice(0, detailNum);
  }

  // chartに渡すデータを作成
  const data: LineDataType = {
    labels, // x軸のラベル
    datasets: [],
  };

  let idx = 0;
  // 最大表示件数またはデータの件数分までデータを作成する。
  for (let i = startIndex; i < startIndex + MAX_DISPLAY_LINE && i < costRateLen; i += 1) {
    // ステーション名が重複するとチャートの表示がおかしくなるため、末尾に連番を付与する。
    const labelPostIdx = `00${i}`;
    const costRateInfo = costRateInfoList[i];
    const color = DATA_COLOR[idx];
    data.datasets[idx] = {
      label: costRateInfo.stationName + labelPostIdx.slice(-2),
      data: costRateInfo.costRateData.map((d) => Number(d) * 100),
      borderColor: color,
      pointBackgroundColor: color,
    };
    idx += 1;
  }
  return data;
};

// グラフの設定
const options = {
  maintainAspectRatio: false,
  responsive: false,
  layout: {},
  plugins: {
    // 凡例
    legend: {
      position: 'right',
      align: 'start',
      maxHeight: 140,
      maxWidth: 120,
      labels: {
        padding: 12,
        boxWidth: 12,
        boxHeight: 12,
        color: 'black',
        font: { size: 15 },
        pointStyle: 'circle',
        usePointStyle: true,
        generateLabels(chart: Chart.chart) {
          return chart.data.datasets.map((dataset: Chart.dataset, i: number) => {
            const isVisible = chart.isDatasetVisible(i);
            return {
              // ステーション名の末尾が連番のため表示時に削除する。
              text: `:${substrMaxSize(dataset.label.slice(0, -2), LABEL_MAX_LEN)}`,
              fillStyle: dataset.pointBackgroundColor,
              strokeStyle: dataset.borderColor,
              hidden: !isVisible,
              datasetIndex: i,
            };
          });
        },
      },
    },
    tooltip: {
      position: 'average',
      yAlign: 'bottom',
      callbacks: {
        label(tooltipItem: Chart.tooltipItem) {
          // ステーション名の末尾が連番のため表示時に削除する。
          return `:${tooltipItem.dataset.label.slice(0, -2)} : ${formatPercent(
            Number(tooltipItem.formattedValue) / 100,
          )}`;
        },
      },
    },
  },
  // 右ラベル凡例表示設定
  datasets: {
    line: {
      borderWidth: 3,
      pointRadius: 10,
      pointBorderWidth: 1,
      pointBorderColor: '#FFFFFF',
    },
  },
  // 軸設定
  scales: {
    x: {
      offset: true,
      // 罫線
      grid: {
        display: false,
      },
      // 罫線、目盛り設定 stepSize:罫線表示単位 callback:目盛表示制御 color:目盛表示色 font:目盛フォントサイズ
      // maxRotation:表示領域が狭い場合に自動的に目盛りを回転して表示 autoSkip:表示領域に合わせて目盛りの表示を自動制御する。
      ticks: {
        color: 'black',
        font: { size: 22 },
        maxRotation: 0,
        autoSkip: false,
      },
    },
    y: {
      max: 50, // 最大値
      min: 0, // 最小値
      // 罫線
      grid: {
        display: true,
        color: '#BCBDCF',
        lineWidth: (context: Chart.ScriptableScaleContext) => (context.tick.value % 10 === 0 ? 2 : 1),
        drawTicks: false,
      },
      // 罫線、目盛り設定 aling: x寄せ callback:目盛表示制御 font:目盛フォントサイズ
      // maxRotation:表示領域が狭い場合に自動的に目盛りを回転して表示
      ticks: {
        stepSize: 2,
        callback: (value: number) => (value % 10 === 0 ? value : ''),
        align: 'center',
        autoSkip: false,
        color: 'black',
        font: { size: 22 },
        maxRotation: 0,
        padding: 10,
      },
    },
  },
};

/* ************ Component ************ */
/* ********** Sub Component ********** */
const CostRateDataGraph: React.FC<{
  index: number;
  costRateInfoList: Array<CostRateDataType>;
  dataViewUnit: string;
  detailNum: number;
  inputValues: CostRateDataViewType;
  chartOptions?: Chart.ChartOptions;
}> = React.memo(({ index, costRateInfoList, dataViewUnit, detailNum, inputValues, chartOptions }) => {
  const dataCostRateInfoList = useCostRateInfoList({ costRateInfoList, inputValues, dataViewUnit });

  return (
    <Line
      type="line"
      width={980}
      height={400}
      data={getData(index, dataCostRateInfoList, dataViewUnit, detailNum)}
      options={chartOptions}
      plugins={getChartPlugins()}
    />
  );
});

/* ******** Main Component ******** */
/**
 * 原価率データ閲覧
 *
 * @author atsushi.teruya
 * @returns {React.FC} - 原価率データ閲覧
 *
 */
const CostRateDataView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const { isPc } = useAppSelector((state) => state.common.deviceInfo);
  const { viewIsDay, viewIsWeek, viewIsMonth, viewIsYear, activeTab } = useAppSelector(
    (state) => state.tabmenu.historyActionTabmenu,
  );
  const { prevScreen } = useParams<{ prevScreen: string }>();

  const { stationList, returnFromDataViewScr } = useAppSelector((state) => state.data);

  const [inputValues, setInputValues] = useState<CostRateDataViewType>({
    dataViewUnit: activeTab.viewUnit,
    index: 0,
    sort: CLSFCN.DATA_VIEW_CR_SORT.STATION_NAME_ASC,
    startDate: '',
    viewPeriod: '',
    disabledNext: '1',
    costRateInfoList: [],
    detailNum: 0,
  });

  const [selectedValue, setSelectedValue] = useState<{ name: string; value: string }>({ name: '50', value: '50' });
  const [chartOptions, setChartOptions] = useState<Chart.ChartOptions>(options);

  // reset history action of tab menu
  // resetDataRef is True then reset default
  const resetDataRef = React.useRef(true);

  // データシート発行ボタンの権限チェック
  const canDownloadDataSheet: boolean = (() => {
    if (!isPc) return false;
    if (!userInfo?.role) return false;
    if (![CLSFCN.ROLE.HEAD_ADMIN, CLSFCN.ROLE.HEAD_VIEWER, CLSFCN.ROLE.STORE_ADMIN].includes(userInfo.role)) {
      return false;
    }
    return true;
  })();

  // 戻るボタンの情報取得(URL、ラベル)
  const prevScrInfo = ((): { name: string; url: string } => {
    if (returnFromDataViewScr === COMMON.SCREEN_ID.GROUP_LIST) {
      return {
        name: '登録グループ一覧',
        url: redirectLinks.GROUP_LIST_DATA,
      };
    }
    if (returnFromDataViewScr === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      return {
        name: '機器一覧',
        url: redirectLinks.MAINT_MACHINE_LIST,
      };
    }
    return {
      name: 'ステーション一覧',
      url: UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_STATION_LIST, {
        prevScreen: USE_REDUX_VALUES.TRUE,
      }),
    };
  })();

  /* ************ function ************ */
  // 原価率データを取得し、ローカルステートに反映。
  const getCostRateData = async (
    startDate: string,
    sort: string,
    changeDate: string,
    dataViewUnit: string,
  ): Promise<getCostRateDataType> => {
    if (!(userInfo?.currentHeadQId && userInfo?.currentStoreId)) {
      return {
        costRateInfoList: [],
        detailNum: 0,
        disabledNext: '',
        startDate: '',
        viewPeriod: '',
      };
    }
    const http = new HttpConnection({ dispatch });
    const data = await http.get<getCostRateDataType>(REST_API.DATA.GET_COST_RATE_VIEW_DATA, {
      stationList,
      headquartersId: userInfo?.currentHeadQId,
      storeId: userInfo?.currentStoreId,
      search_div: dataViewUnit,
      startDate,
      sort,
      changeDate,
    });
    setInputValues((prevState) => ({
      ...prevState,
      dataViewUnit,
      index: 0,
      sort,
      startDate: data.startDate,
      viewPeriod: data.viewPeriod,
      disabledNext: data.disabledNext,
      costRateInfoList: data.costRateInfoList,
      detailNum: data.detailNum,
    }));

    return data;
  };

  /* ************ Event ************ */
  // No1. ページ読み込み
  useEffect(() => {
    getCostRateData(activeTab.startDate, inputValues.sort, COMMON.CHANGE_DATE.CURRENT, activeTab.viewUnit);

    return () => {
      if (resetDataRef.current) {
        dispatch(resetTabView());
        resetDataRef.current = true;
      }
    };
  }, []);

  // No2. ステーション一覧(登録グループ一覧)クリック
  const handleClickReturn = useCallback(() => {
    history.push(prevScrInfo.url);
  }, [prevScrInfo]);

  // データシート発行ボタン
  const handleClickDataSheet = () => {
    history.push(redirectLinks.DATA_STATION_GENERATE_DATA_SHEET, { from: COMMON.SCREEN_ID.DATA_COST_RATE_DATA_VIEW });
  };

  // No3. 売上タブクリック
  const handleClickMenuSales: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    resetDataRef.current = false;
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_SALES_VIEW, { prevScreen }));
  }, []);

  // No5～8,12～19 メニュータブ、切り替え_(前、後)(日、週、年、月) 共通処理
  const handleClickMenuTab = (
    startDate: string,
    sort: string,
    dataViewUnit: string,
    changeDate: string,
    typeEvent: TypeEventClickMenu,
  ): MouseEventHandler<HTMLElement> => async (event) => {
    event.preventDefault();

    if (['PreviousMenuTab', 'NextMenuTab'].includes(typeEvent)) {
      const data = await getCostRateData(startDate, sort, changeDate, dataViewUnit);
      switch (dataViewUnit) {
        case MENU_SETTING[0].dataViewUnit:
          dispatch(tabViewIsDay({ startDate: data.startDate }));
          break;
        case MENU_SETTING[1].dataViewUnit:
          dispatch(tabViewIsWeek({ startDate: data.startDate }));
          break;
        case MENU_SETTING[2].dataViewUnit:
          dispatch(tabViewIsMonth({ startDate: data.startDate }));
          break;
        case MENU_SETTING[3].dataViewUnit:
          dispatch(tabViewIsYear({ startDate: data.startDate }));
          break;
        default:
      }
    }

    if (['ClickMenuTab'].includes(typeEvent)) {
      switch (dataViewUnit) {
        case MENU_SETTING[0].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[0].dataViewUnit, startDate: viewIsDay.startDate }));
          await getCostRateData(viewIsDay.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[1].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[1].dataViewUnit, startDate: viewIsWeek.startDate }));
          await getCostRateData(viewIsWeek.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[2].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[2].dataViewUnit, startDate: viewIsMonth.startDate }));
          await getCostRateData(viewIsMonth.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[3].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[3].dataViewUnit, startDate: viewIsYear.startDate }));
          await getCostRateData(viewIsYear.startDate, sort, changeDate, dataViewUnit);
          break;
        default:
      }
    }
  };

  // No5～8 メニュータブ(日、週、年、月)クリック
  const handleMainMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab('', inputValues.sort, dataViewUnit, COMMON.CHANGE_DATE.CURRENT, 'ClickMenuTab'),
    [inputValues.sort, viewIsDay.startDate, viewIsWeek.startDate, viewIsMonth.startDate, viewIsYear.startDate],
  );

  // No9. 並び替えタブクリック
  const handleChangeSort: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      getCostRateData(inputValues.startDate, event.target.value, COMMON.CHANGE_DATE.CURRENT, inputValues.dataViewUnit);
    },
    [inputValues.startDate, inputValues.dataViewUnit],
  );

  // No10. スクロール(前)クリック
  const handleClickPrev: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (inputValues.index <= 0) return;
    setInputValues((prevState) => ({
      ...prevState,
      index: prevState.index - 1,
    }));
  }, [inputValues.index]);

  // No10. スクロール(後)クリック
  const handleClickNext: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (inputValues.costRateInfoList.length <= inputValues.index + MAX_DISPLAY_LINE) return;
    setInputValues((prevState) => ({
      ...prevState,
      index: prevState.index + 1,
    }));
  }, [inputValues.costRateInfoList.length]);

  // No12,14,16,18 切り替え_(前)(日、週、年、月)クリック
  const handlePreviousMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab(
        inputValues.startDate,
        inputValues.sort,
        dataViewUnit,
        COMMON.CHANGE_DATE.PREVIOUS,
        'PreviousMenuTab',
      ),
    [inputValues.startDate, inputValues.sort],
  );
  // No13,15,17,19 切り替え_(後)(日、週、年、月)クリック
  const handleNextMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab(inputValues.startDate, inputValues.sort, dataViewUnit, COMMON.CHANGE_DATE.NEXT, 'NextMenuTab'),
    [inputValues.startDate, inputValues.sort],
  );
  // No20 対象期間クリック
  const handleClickViewPeriod: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    dispatch(tabActive({ viewUnit: inputValues.dataViewUnit, startDate: '' }));
    switch (inputValues.dataViewUnit) {
      case MENU_SETTING[0].dataViewUnit:
        dispatch(tabViewIsDay({ startDate: '' }));
        break;
      case MENU_SETTING[1].dataViewUnit:
        dispatch(tabViewIsWeek({ startDate: '' }));
        break;
      case MENU_SETTING[2].dataViewUnit:
        dispatch(tabViewIsMonth({ startDate: '' }));
        break;
      case MENU_SETTING[3].dataViewUnit:
        dispatch(tabViewIsYear({ startDate: '' }));
        break;
      default:
    }
    return getCostRateData('', inputValues.sort, COMMON.CHANGE_DATE.CURRENT, inputValues.dataViewUnit);
  }, [inputValues.sort, inputValues.dataViewUnit, inputValues.startDate]);

  const disabledNext = inputValues.disabledNext === COMMON.FLAG.ON;

  const handleChangeTab = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof selectedValue;
    const lineChartNumber = Number(event.target.value) === 50 ? 1 : 5;

    setSelectedValue({
      ...selectedValue,
      [name]: event.target.value,
      value: `${event.target.value}`,
    });

    const scalesY = {
      max: Number(event.target.value),
      min: 0,
      grid: {
        display: true,
        color: '#BCBDCF',
        lineWidth: (context: Chart.ScriptableScaleContext) =>
          context.tick.value % (10 * lineChartNumber) === 0 ? 2 : 1,
        drawTicks: false,
      },

      ticks: {
        stepSize: Number(event.target.value) === 500 ? 25 : 2 * lineChartNumber, // 原価率のY軸1目盛値の設定
        callback: (value: number) => (value % (10 * lineChartNumber) === 0 ? value : ''),
        align: 'center',
        autoSkip: false,
        color: 'black',
        font: { size: 22 },
        maxRotation: 0,
        padding: 10,
      },
    };

    setChartOptions({
      ...chartOptions,
      scales: {
        ...chartOptions.scales,
        y: scalesY,
      },
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          {/* 上部エリア (戻るボタン、メニュータブエリア(タブ本体はコンテンツエリアに配置)) */}
          <Box className={classes.topArea}>
            {/* 戻るボタン */}
            <Box className="returnButton">
              <ReturnButton onClick={handleClickReturn}>{prevScrInfo.name}</ReturnButton>
            </Box>
            {/* メニュータブエリア */}
            <Box className="menuArea" />
          </Box>
          {/* コンテンツエリア */}
          <Box className={classes.mainArea}>
            <Box className="contentItem">
              {/* コンテンツ上部(メニュータブ) */}
              <Box className={classes.itemTopArea}>
                {MENU_SETTING.map((menu) => (
                  <MenuTab
                    disabledNext={disabledNext}
                    isSelected={inputValues.dataViewUnit === menu.dataViewUnit}
                    onClick={handleMainMenuTab(menu.dataViewUnit)}
                    onPrevious={handlePreviousMenuTab(menu.dataViewUnit)}
                    onNext={handleNextMenuTab(menu.dataViewUnit)}>
                    {menu.label}
                  </MenuTab>
                ))}
              </Box>
              <Box className={classes.itemMainArea}>
                {/* コンテンツメニュー(売上、原価率など) */}
                <Box className="contentMenuArea">
                  <ContentMenuTab onClick={handleClickMenuSales}>売上</ContentMenuTab>
                  <ContentMenuTab isSelected>原価率</ContentMenuTab>
                  <Box className="buttonArea">
                    {canDownloadDataSheet && (
                      <NormalButton className={classes.dataSheetbtn} onClick={handleClickDataSheet}>
                        ﾃﾞｰﾀｼｰﾄ発行
                      </NormalButton>
                    )}
                  </Box>
                </Box>
                <Box className="viewArea">
                  <Box className={classes.graphOptionArea}>
                    <Box className="salesLimit">
                      <SelectBox
                        name="name"
                        size={COMMON.SIZE.SMALL}
                        dataSource={[
                          { name: '上限50％', value: '50' },
                          { name: '上限200％', value: '200' },
                          { name: '上限500％', value: '500' },
                        ]}
                        value={selectedValue.name}
                        onChange={handleChangeTab}
                      />
                    </Box>
                    <Box className="viewPeriod" onClick={handleClickViewPeriod}>
                      {inputValues.viewPeriod}
                    </Box>
                    <span className="sortLabel">並べ替え</span>
                    <SelectBox
                      name="sort"
                      size={COMMON.SIZE.SMALL}
                      dataSource={getClsfcnByCategory(CLSFCN.DATA_VIEW_CR_SORT.CATEGORY_CD)}
                      value={inputValues.sort}
                      onChange={handleChangeSort}
                    />
                  </Box>
                  <Box className={classes.graphArea}>
                    {/* indexが0より大きい場合表示 */}
                    {inputValues.index > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
                    <p className="yAxesUnit">原価率(%)</p>
                    {/* グラフ */}
                    <Box className="graph">
                      <CostRateDataGraph
                        index={inputValues.index}
                        costRateInfoList={inputValues.costRateInfoList}
                        dataViewUnit={inputValues.dataViewUnit}
                        detailNum={inputValues.detailNum}
                        inputValues={inputValues}
                        chartOptions={chartOptions}
                      />
                    </Box>
                    {/* index+MAX_DISPLAY_BARがsalesListの件数未満の場合表示。 */}
                    {inputValues.index + MAX_DISPLAY_LINE < inputValues.costRateInfoList.length && (
                      <PrevNextButton className="nextScroll" onClick={handleClickNext} />
                    )}

                    <p className="xAxesUnit">{X_LABELS[inputValues.dataViewUnit]}</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default CostRateDataView;
