import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import logoClenaNet from 'assets/images/logoClenaNet.png';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}));

/* ************ Component ************ */
const LoginLogo: React.FC = () => {
  const classes = useStyles();

  /* ************ state/redux ************ */

  /* ************ API ************ */

  /* ************ Event ************ */

  return (
    <Box className={classes.root}>
      <img src={logoClenaNet} alt="Logo Clena-Net" />
    </Box>
  );
};

export default LoginLogo;
