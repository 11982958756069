import { FormControlLabel, Checkbox, CheckboxProps } from '@material-ui/core';
import React, { FC, memo } from 'react';
import CheckedIcon from 'assets/icons/CheckedIcon';
import UnCheckedIcon from 'assets/icons/UnCheckedIcon';

/**
 * Check Box Component
 * チェックボックス
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */

/* ************ Type ************ */
type NomalCheckBoxProps = CheckboxProps & {
  label?: string;
  exClassName?: string;
  labelPlacement?: 'start' | 'top' | 'bottom' | 'end';
};

/* ************ Component ************ */
const NomalCheckBox: FC<NomalCheckBoxProps> = memo(
  ({ name, label, labelPlacement = 'end', checked = false, disabled = false, onChange, exClassName }) => (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={false}
          disabled={disabled}
          color="default"
          checkedIcon={<CheckedIcon />}
          icon={<UnCheckedIcon />}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      className={exClassName}
    />
  ),
);

export default NomalCheckBox;
