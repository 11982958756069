import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SelectButtonType } from 'types/system/buttonType';
import { Box, InputLabel } from '@material-ui/core';
import { SelectButton } from '..';

const useStyles = makeStyles(() => ({
  root: {
    '& .pullDownLabel': {
      fontSize: '19px',
      color: '#0D0D0D',
      margin: '0 0 0 0',
    },
  },
}));

/* ************ Function ************ */
const setLabelPlacement = (labelPlacement: string): string => {
  if (labelPlacement === 'start') {
    return 'row';
  }
  return 'column';
};

/**
 * SelectButton 一覧選択ボタン
 * 一覧選択画面を表示する。
 *
 * @Params {SelectButtonWithLabelType} props - 一覧選択ボタン利用に必要なパラメータ
 * @returns {React.FC}
 *
 */
const SelectButtonWithLabel: React.FC<
  SelectButtonType & {
    label?: string;
    labelPlacement?: 'start' | 'top';
  }
> = (props) => {
  const classes = useStyles();
  const {
    children,
    className,
    disabled = false,
    fullWidth = false,
    isSelected,
    leftRight,
    onClick,
    arrowSize,
    arrowColor,
    variant,
    label,
    labelPlacement = 'start',
  } = props;

  return (
    <Box
      display="flex"
      flexDirection={setLabelPlacement(labelPlacement)}
      alignItems="baseline"
      className={`${classes.root} ${className}`}>
      {label && <InputLabel className="pullDownLabel">{label}</InputLabel>}
      <SelectButton
        disabled={disabled}
        fullWidth={fullWidth}
        isSelected={isSelected}
        leftRight={leftRight}
        onClick={onClick}
        arrowSize={arrowSize}
        arrowColor={arrowColor}
        variant={variant}>
        {children}
      </SelectButton>
    </Box>
  );
};

export default memo(SelectButtonWithLabel);
