import * as React from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataSourceType, SelectListType } from 'types/support/supportType';
import Popper from '@material-ui/core/Popper';
import { animateScroll as scroll } from 'react-scroll';
import UpDownButton from 'components/support/atoms/buttons/UpDownButton';
import { throttle } from 'utils/controlFn.helper';
import useLongPressContinue from 'utils/customHook';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {},
  popper: {
    backgroundColor: theme.palette.common.black,
    border: '3px solid #FFFFFF',
    borderRadius: '4px',
    margin: '0px 16px 0px 16px',
    maxHeight: '500px',
    zIndex: 1350,
    width: '300px',
  },
  title: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    fontSize: '21px',
    height: '76px',
    padding: '0px 12px 0px 12px',
  },
  content: {
    backgroundColor: theme.palette.common.black,
    borderWidth: '0px',
    maxHeight: '300px',
    padding: '0px 1px 0px 1px',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    width: '294px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  contentItem: {
    border: '2px solid',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '22px',
    height: '59px',
    lineHeight: '59px',
    margin: '1px 0px 0px 0px',
    paddingLeft: '24px',
    width: '100%',
    overflow: 'hidden',
  },
  itemSelected: {
    backgroundColor: '#FFD8D8',
    borderColor: '#FFAFAF',
  },
  itemNotSelected: {
    backgroundColor: theme.palette.common.white,
    borderColor: '#E5E5E5',
  },
  upButton: {
    background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 1) 100%)',
    borderRadius: '4px',
    width: '292px',
    height: '59px',
    marginLeft: '1px',
  },
  downButton: {
    background: 'linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
    borderRadius: '4px',
    width: '292px',
    height: '59px',
    marginLeft: '1px',
  },
}));

/* *************    constants   ************* */
const SCROLL_SIZE = 60;
const MAX_SHOW_ITEM = 5;
const SCROLL_OPTION = {
  duration: 50,
  delay: 0,
  containerId: 'scrollSelectList',
};
const DIRECTION = {
  UP: 1,
  DOWN: 2,
};
// 長押しイベント設定
const LONG_PRESS_OPTION = {
  shouldPreventDefault: true,
  delay: 100,
};

export const INITIAL_SELECT_LIST: { selectList: SelectListType } = {
  selectList: {
    anchorEL: null,
    className: '',
    dataSource: [],
    name: '',
    open: false,
    placement: 'right-start',
    title: '',
    value: '',
  },
};

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * Select List 一覧選択
 * 一覧を表示する。
 *
 * @author atsushi.teruya
 * @Params {SelectListType} selectList - 一覧選択の情報
 * @Params {hideAnchorEL} 一覧選択に紐づけた要素の表示制御。 true : 前面に表示しない。 false or undefined: 前面に表示する。
 * @returns {React.FC} - 一覧選択の表示
 *
 */
const SelectList: React.FC<{ selectList: SelectListType; hideAnchorEL?: boolean }> = ({ selectList, hideAnchorEL }) => {
  const { anchorEL, className = '', dataSource, name, open, placement, title, value, onClick, onClose } = selectList;
  const classes = useStyles();

  /* *************      hooks     ************* */
  const initialScrollPos = {
    top: true,
    bottom: false,
  };
  const [scrollPos, setScrollPos] = React.useState(initialScrollPos);
  const contentRef = React.useRef<HTMLDivElement>(null);
  // 表示のトリガーに利用した一覧選択ボタンを前面に表示する。
  React.useEffect(() => {
    if (!hideAnchorEL && anchorEL) {
      anchorEL.style.zIndex = '1350';
    }
  }, [anchorEL]);

  const dataSize = dataSource.length;

  /* ************* local function ************* */
  // スクロール量の調整。初めと最後だけスクロール量を1px多くする。
  const calcScrollSize = (direction: number): number => {
    let result = SCROLL_SIZE;
    // 端の場合はスクロール量を1増やす。
    if (scrollPos.bottom || scrollPos.top) result += 1;

    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      if (direction === DIRECTION.UP) {
        // 上(前)に移動で最上部に移動する場合
        if (scrollTop <= SCROLL_SIZE + 1) {
          result += 1;
        }
      } else if (scrollHeight - scrollTop - clientHeight <= SCROLL_SIZE + 1.3) {
        // 下(後)に移動で最下部に移動する場合
        result += 1;
      }
    }

    return result;
  };

  /* *************      event     ************* */
  // No3. スクロールボタン(前) クリック,長押し時
  const handleClickOrLongPressUpButton = () => {
    scroll.scrollMore(-1 * calcScrollSize(DIRECTION.UP), SCROLL_OPTION);
  };
  // イベントを長押し用の処理に変換
  const longPressEventsUpButton = useLongPressContinue(
    handleClickOrLongPressUpButton,
    handleClickOrLongPressUpButton,
    LONG_PRESS_OPTION,
  );

  // No5. スクロールボタン(後) クリック,長押し時
  const handleClickOrLongPressDownButton = () => {
    scroll.scrollMore(calcScrollSize(DIRECTION.DOWN), SCROLL_OPTION);
  };
  // イベントを長押し用の処理に変換
  const longPressEventsDownButton = useLongPressContinue(
    handleClickOrLongPressDownButton,
    handleClickOrLongPressDownButton,
    LONG_PRESS_OPTION,
  );

  // 自動スクロール処理
  const handleScroll: React.UIEventHandler<HTMLDivElement> = React.useCallback(
    throttle(() => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setScrollPos({
          top: scrollTop === 0,
          // 誤差がでるので1.3の幅を持たせる。
          bottom: scrollHeight - scrollTop <= clientHeight + 1.3,
        });
      }
    }, 100),
    [],
  );

  // No2. 画面の外クリック
  // ダイアログ閉じる
  const handleClose = (event: Record<string, unknown>, reason: 'backdropClick' | 'escapeKeyDown') => {
    // スクロール位置を初期化
    setScrollPos(initialScrollPos);
    // 表示のトリガーに利用した一覧選択ボタンの表示位置をもとに戻す。
    if (anchorEL) anchorEL.style.zIndex = '';
    if (onClose) onClose(event, reason);
  };

  // No4. 選択肢名称クリック
  const handleClick = (data: DataSourceType) => {
    // 閉じる処理を実行
    handleClose({}, 'backdropClick');
    // 選択時の処理を実行。
    if (onClick) onClick(data, name);
  };

  return (
    <Dialog
      className={classes.root}
      open={open}
      maxWidth={false}
      onClose={handleClose}
      aria-labelledby="selectList-dialog-title">
      <Popper open={open} anchorEl={anchorEL} placement={placement} className={`${classes.popper} ${className}`}>
        <DialogTitle id="selectList-dialog-title" className={classes.title}>
          {title}
        </DialogTitle>
        {MAX_SHOW_ITEM < dataSize && (
          <UpDownButton
            isUp
            className={classes.upButton}
            disabled={scrollPos.top}
            onMouseDown={longPressEventsUpButton.onMouseDown}
            onMouseUp={longPressEventsUpButton.onMouseUp}
            onMouseLeave={longPressEventsUpButton.onMouseLeave}
            leftRight=""
          />
        )}
        <DialogContent
          dividers
          id={SCROLL_OPTION.containerId}
          className={classes.content}
          onScroll={handleScroll}
          ref={contentRef}>
          {dataSource.map((item) => (
            <Box
              className={`${classes.contentItem} ${
                value === item.value ? classes.itemSelected : classes.itemNotSelected
              }`}
              key={item.value}
              onClick={() => handleClick(item)}>
              {item.name}
            </Box>
          ))}
        </DialogContent>
        {MAX_SHOW_ITEM < dataSize && (
          <UpDownButton
            className={classes.downButton}
            onMouseDown={longPressEventsDownButton.onMouseDown}
            onMouseUp={longPressEventsDownButton.onMouseUp}
            onMouseLeave={longPressEventsDownButton.onMouseLeave}
            disabled={scrollPos.bottom}
          />
        )}
      </Popper>
    </Dialog>
  );
};

/* ********** Initial value ********** */
SelectList.defaultProps = INITIAL_SELECT_LIST;

export default SelectList;
