import React from 'react';
import { makeStyles, TableContainer, Table, TableBody, TableRow, TableCell, Box } from '@material-ui/core';
import { AreaDetailRowType, AreaDetailType } from 'types/machineConf/areaType';
import COMMON from 'constants/common';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import { toArrayAreaLine, toArrayPitLine, toLineData } from 'utils/machineConf.helper';
import CLSFCN from 'constants/classification';
import { useLocation } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';

/* ************* constant ************* */
const tableArrayRender = [0, 1, 2, 3, 4, 5];

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  tableRoot: {
    height: 158,
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    '& .area0': {
      backgroundColor: theme.base.area0,
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
    },
    '& td': {
      padding: 0,
      '& img': {
        transform: 'scale(0.8)',
      },
    },
  },
  tableRootBorder: {
    border: '3px solid',
  },
  tableContainer: {
    position: 'relative',
  },
  mediumLineSeparate: {
    borderBottom: '2px solid',
  },
  mediumColumnSeparate: {
    borderRight: '2px solid',
  },
  lineSeparateNone: {
    borderBottomWidth: '0px',
  },
  lineSeparateDot: {
    borderBottom: '2px dashed',
  },
  columnSeparateNone: {
    borderRightWidth: '0px',
  },
  columnSeparateDot: {
    borderRight: '2px dashed',
  },
  pinpointPit: {
    position: 'absolute',
    borderRadius: '100%',
    border: '2px solid',
    zIndex: 20,
    backgroundColor: '#FF0035',
    borderColor: theme.palette.common.white,
  },
  smallPit: {
    width: '16px',
    height: '16px',
  },
  mediumPit: {
    width: '20px',
    height: '20px',
  },
  largePit: {
    width: '24px',
    height: '24px',
  },
  smallContainer: {
    top: '-8px',
    left: '-8px',
    width: '196px',
    height: '196px',
    padding: '8px 0px 0px 8px',
  },
  mediumContainer: {
    top: '-10px',
    left: '-10px',
    width: '380px',
    padding: '10px',
  },
  largeContainer: {
    top: '-12px',
    left: '-12px',
    width: '414px',
    height: '414px',
    padding: '12px',
  },
  smallStyle: {
    height: '158px',
    width: '180px',
    '& tr': {
      height: '30px',
    },
    '& tr:first-child': {
      height: '35px',
    },
  },
  mediumStyle: {
    height: '313px',
    width: '360px',
    '& tr:nth-child(1)': { height: '73px' },
  },
  largeStyle: {
    height: '341px',
    width: '390px',
    '& tr': {
      height: '64px',
    },
    '& tr:nth-child(1)': {
      height: '79px',
    },
  },
  hiddenBorderTable: {
    '& td': { border: 0 },
  },
  borderWhite: {
    '& td': {
      borderColor: '#fff',
    },
  },
  defaultStyle: {
    borderColor: theme.base.tableBorder,
  },
  leftStyle: {
    borderColor: theme.leftStation.itemBorder,
  },
  rightStyle: {
    borderColor: theme.rightStation.itemBorder,
  },
  hiddenCellHomeDetail: {
    background: 'rgb(255, 249, 252)',
  },
  hiddenCellConFTopOne: {
    background: '#EBEBEB',
  },
  borderTable: {
    border: '2px solid',
    borderColor: '#707070',
  },
  wrapPinpointPit: {
    width: 177,
    height: 190,
    position: 'absolute',
    top: -20,
    left: 1,
  },
}));

/* ********* Local Function ********** */

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * AreaDetailRow エリア明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const AreaDetailRow: React.FC<AreaDetailRowType> = ({
  isDefault,
  className,
  displayCellSeparate,
  pitLine,
  areaLine,
  settingPattern,
}) => {
  const classes = useStyles();
  const location = useLocation();

  // 通常のセルのスタイル
  const cellClass = `${className} ${displayCellSeparate ? classes.columnSeparateDot : classes.columnSeparateNone}`;
  // 2列、4列目のセルのスタイル 右側に線を引く
  const cellClassEven = `${className} ${classes.mediumColumnSeparate}`;
  return (
    <TableRow>
      {tableArrayRender.map((value) => (
        <TableCell
          key={value}
          className={`${value === 1 || value === 3 ? cellClassEven : cellClass} ${
            isDefault === true ? 'area0' : `area${areaLine.length >= 6 ? areaLine[value] : '0'}`
          }`}>
          {pitLine &&
            pitLine[value] === '1' &&
            settingPattern === CLSFCN.PIT_PATTERN.DETAIL &&
            location.pathname !== redirectLinks.MCONF_JACK_CONF_PIT_CONF && <img src={pitMarkIcon} alt="○" />}
        </TableCell>
      ))}
    </TableRow>
  );
};

/**
 * PinpointPit ピンポイント落とし穴
 *
 * @author atsushi.teruya
 * @returns {React.FC} - ピンポイント用の落とし穴表示
 */
const PinpointPit: React.FC<{
  isLeft: boolean;
  status: string;
  x: number | null;
  y: number | null;
  size: 'S' | 'M' | 'L';
}> = ({ status, x, y, size }) => {
  // status,x,yが有効でない場合は空を返す。
  if (status !== COMMON.STATUS.NORMAL || x === null || y === null) {
    return <></>;
  }
  const classes = useStyles();
  // 落とし穴サイズを定義
  let pitStyle = '';
  switch (size) {
    case COMMON.SIZE.SMALL:
      pitStyle = classes.smallPit;
      break;
    case COMMON.SIZE.MEDIUM:
      pitStyle = classes.mediumPit;
      break;
    default:
      pitStyle = classes.largePit;
  }

  const style = {
    bottom: `${y}%`,
    left: `${x}%`,
  };
  return (
    <Box style={style} className={`${classes.pinpointPit} ${pitStyle}`}>
      <span />
    </Box>
  );
};

/* ************ Main Component ************ */
const AreaDetailJack: React.FC<AreaDetailType> = ({
  className,
  displayCellSeparate,
  displayTableBorder,
  defaultBorderColor,
  leftRight,
  size,
  areaConf,
  pitConf,
  isDefault,
}) => {
  const classes = useStyles();
  const location = useLocation();

  // テーブルサイズを定義
  let tableSizeStyle = '';
  let contanerSizeStyle = '';
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  const MAX_PIT_Y = 80;

  switch (size) {
    case COMMON.SIZE.SMALL:
      tableSizeStyle = classes.smallStyle;
      contanerSizeStyle = classes.smallContainer;
      break;
    case COMMON.SIZE.MEDIUM:
      tableSizeStyle = classes.mediumStyle;
      contanerSizeStyle = classes.mediumContainer;
      break;
    default:
      tableSizeStyle = classes.largeStyle;
      contanerSizeStyle = classes.largeContainer;
  }
  // ステーションの向きに応じた配色を定義
  let stationStyle = classes.defaultStyle;
  // デフォルトカラーじゃない場合は左右のスタイルを設定
  if (!defaultBorderColor) {
    stationStyle = classes.rightStyle;
  }
  const contanerStyle = `${classes.tableContainer} ${className} ${contanerSizeStyle}`;
  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const tableStyle = `${classes.tableRoot} ${
    displayTableBorder && classes.tableRootBorder
  } ${tableSizeStyle} ${stationStyle}`;
  // 基本は1行ごとに破線のボーダーを表示。XY範囲など一部の画面は非表示。
  const rowClass = `${displayCellSeparate ? classes.lineSeparateDot : classes.lineSeparateNone} ${stationStyle}`;
  // 2行、4行の行(実際はセル)のスタイル 右側に線を引く
  const mediumRowClass = `${classes.mediumLineSeparate} ${stationStyle}`;
  // 行ごとにセルごとの表示制御するためline(実際は列)の配列を作成
  const pitLineArray =
    pitConf?.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT ? toArrayPitLine(undefined) : toArrayPitLine(pitConf);
  const areaLineArray = toArrayAreaLine(areaConf);
  const hiddenBorderTable =
    location.pathname === redirectLinks.MCONF_JACK_CONF_PIT_CONF &&
    pitConf?.settingPattern === CLSFCN.PIT_PATTERN.DETAIL;

  return (
    <>
      <TableContainer className={contanerStyle}>
        <Table className={`${tableStyle} ${classes.borderTable} ${hiddenBorderTable ? classes.hiddenBorderTable : ''}`}>
          <TableBody>
            {tableArrayRender.map((value) => {
              if (value === 0) return null;
              return (
                <AreaDetailRow
                  isDefault={isDefault}
                  key={value}
                  className={value === 1 || value === 3 ? mediumRowClass : rowClass}
                  displayCellSeparate={displayCellSeparate}
                  areaLine={toLineData(areaLineArray, value)}
                  pitLine={toLineData(pitLineArray, value)}
                  settingPattern={pitConf?.settingPattern}
                />
              );
            })}
          </TableBody>
        </Table>

        {/** ピンポイント表示 */}
        {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT && (
          <div className={classes.wrapPinpointPit}>
            <PinpointPit
              isLeft={isLeft}
              status={pitConf.pit1Status}
              x={pitConf.pit1x}
              y={pitConf.pit1y && pitConf.pit1y >= MAX_PIT_Y ? MAX_PIT_Y : pitConf.pit1y}
              size={size}
            />
            <PinpointPit
              isLeft={isLeft}
              status={pitConf.pit2Status}
              x={pitConf.pit2x}
              y={pitConf.pit2y && pitConf.pit2y >= MAX_PIT_Y ? MAX_PIT_Y : pitConf.pit2y}
              size={size}
            />
            <PinpointPit
              isLeft={isLeft}
              status={pitConf.pit3Status}
              x={pitConf.pit3x}
              y={pitConf.pit3y && pitConf.pit3y >= MAX_PIT_Y ? MAX_PIT_Y : pitConf.pit3y}
              size={size}
            />
            <PinpointPit
              isLeft={isLeft}
              status={pitConf.pit4Status}
              x={pitConf.pit4x}
              y={pitConf.pit4y && pitConf.pit4y >= MAX_PIT_Y ? MAX_PIT_Y : pitConf.pit4y}
              size={size}
            />
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default AreaDetailJack;
