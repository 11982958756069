import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import * as Sentry from '@sentry/react';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ErrorSnackbar from 'components/system/atoms/snackbars/ErrorSnackbar';
import ERROR_CODE from 'constants/errorCode';

/**
 * Error Boundary
 * エラーによる動作を定義するコンポーネント
 * @author atsushi.teruya
 *
 * @param category  category of classification
 * @param value  value of classification
 * @returns string
 */
const ErrorBoundary: React.FC = ({ children }) => {
  const { isError = false, isUnauthorizedError = false, isSystemError = false, errors } = useAppSelector(
    (state) => state.common.errorInfo,
  );

  /* ************ other function ************ */
  const history = useHistory();
  const dispatch = useAppDispatch();

  // エラー発生時(isError=true)、システムエラー画面へ遷移する。
  useEffect(() => {
    // Front内のエラー、または想定外のエラー => システムエラー
    if (isSystemError) {
      history.push(redirectLinks.COMMON_SYSTEM_ERROR);
    }
  }, [isUnauthorizedError, isSystemError]);

  return (
    <Sentry.ErrorBoundary
      onError={() => {
        dispatch(handleCallSystemError({ errorNo: ERROR_CODE.UNKWON_ERROR }));
      }}>
      <ErrorSnackbar isError={isError} errors={errors} />
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
