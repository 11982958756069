import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CLSFCN from 'constants/classification';
import COMMON from 'constants/common';
import { getClassificationName } from 'utils/common.helper';

type StyleProps = {
  isLeft: boolean;
};

type LevelManagementProps = {
  isLeft: boolean;
  isleverOperation: boolean;
  leverTime: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  mainContainer: {
    marginBottom: '24px',
    borderRadius: 4,
    height: '55px',
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
  },
  container: {
    display: 'flex',
    height: '100%',
  },
  levelTextStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
    width: '150px',
    fontSize: 24,
  },
  contentStyle: {
    display: 'flex',
    background: '#ffffff',
    alignItems: 'center',
    width: '162px',
  },
  valueStyle: {
    marginLeft: '8px',
    fontSize: 20,
  },
}));

const LeverManagement: React.FC<LevelManagementProps> = (props) => {
  const { isLeft, isleverOperation, leverTime } = props;
  const classes = useStyles({ isLeft });

  const leverTimeString = isleverOperation
    ? getClassificationName(CLSFCN.LEVER_TIME.CATEGORY_CD, leverTime)
    : COMMON.BLANK3;
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <div className={classes.levelTextStyle}>
          <span>レバー運営</span>
        </div>
        <div className={classes.contentStyle}>
          <span className={classes.valueStyle}>{leverTimeString}</span>
        </div>
      </Box>
    </Box>
  );
};

export default memo(LeverManagement);
