import { makeStyles, Table, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { GroupListType } from 'types/group/GroupType';
import { formatDatetime } from 'utils/datetime.helper';
import COMMON from 'constants/common';
import { PinIcon, DataIcon } from 'components/system/atoms/icons';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '440px',
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate', // collapseにするとスクロール時にヘッダーのborderが消えるので注意
    borderLeft: '1px solid #707070',
    '& tr': {
      height: '80px',
    },
    '& th,td': {},
    '& th': {
      fontSize: '23px',
      lineHeight: '28px',
      backgroundColor: '#CFCFCF',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      padding: 0,
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
    },
    '& td': {
      fontSize: '21px',
      lineHeight: '28px',
      borderBottom: '1px solid #707070',
      borderRight: '1px solid #707070',
      '&.cellStyle': {
        overflow: 'hidden',
      },
      '&.valid-led': {
        backgroundColor: '#F4FF8A',
      },
      '&.invalid-led': {
        backgroundColor: '#EBEBEB',
      },
    },
  },
  head: {},
  tdPinned: {
    textAlign: 'center',
    padding: 0,
    '& .control-icon': {
      cursor: 'pointer',
    },
    '& .pinned': {
      fill: '#020ad9',
    },
  },
  tdLastUpdatedAt: {
    textAlign: 'center',
    whiteSpace: 'normal',
    padding: 0,
  },
  tdGroupCategory: {
    textAlign: 'center',
    padding: 0,
  },
  tdGroupName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 12,
  },
  tdEdit: {
    padding: '0px 8px 0px 8px',
  },
  list_setting: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '56px',
    width: '84px',
    fontSize: '22px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  tdDataBrowsing: {
    textAlign: 'center',
    padding: '6px 0px 0px 0px',
    '& .control-icon': {
      cursor: 'pointer',
    },
  },
}));

const GROUP_CATEGORY_LABLES: Record<number, string> = {
  0: 'LED演出',
  1: '獲得演出',
};

/* ************ Component ************ */
type GroupTableType = {
  groupList: Array<GroupListType>;
  tableId?: string;
  handlePinning: (row: GroupListType) => void;
  handleClickList: (row: GroupListType) => void;
  handleClickSetting: (row: GroupListType) => void;
  handleClickDataBrowsing: (row: GroupListType) => void;
};
const GroupTable: React.FC<GroupTableType> = ({
  groupList,
  tableId,
  handlePinning,
  handleClickList,
  handleClickSetting,
  handleClickDataBrowsing,
}) => {
  const classes = useStyles();
  const headerColumns = [
    { id: 'pinned', label: 'ピン\n留め', width: 76 },
    { id: 'lastUpdatedAt', label: '前回の\n更新日時', width: 140 },
    { id: 'groupCategory', label: 'グループ\nの種類', width: 120 },
    { id: 'groupName', label: 'グループ名', width: 436 },
    { id: 'edit', label: '編集', width: 200 },
    { id: 'dataBrowsing', label: 'データ\n閲覧', width: 100 },
  ];

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) => (
                <TableCell
                  colSpan={column.id === 'edit' ? 2 : 1}
                  key={`group_table_head_key_${column.id}`}
                  style={{ width: column.width }}
                  className={classes.head}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {groupList.map((row) => {
              const key = `group_table_key_${row.groupId}`;
              let cellColorByType = '';
              if (Number(row.groupCategory) === COMMON.GROUP_CATEGORY.LED) {
                cellColorByType = Number(row.status) === Number(COMMON.VALID_FLG.VALID) ? 'valid-led' : 'invalid-led';
              }

              return (
                <React.Fragment key={key}>
                  <TableRow key={key}>
                    <TableCell className={`cellStyle ${classes.tdPinned}`}>
                      <PinIcon
                        className={
                          Number(row.pinned) === Number(COMMON.FLAG.OFF) ? 'control-icon' : 'control-icon pinned'
                        }
                        onClick={() => handlePinning(row)}
                      />
                    </TableCell>
                    <TableCell className={`cellStyle ${classes.tdLastUpdatedAt}`}>
                      {formatDatetime(new Date(row.lastUpdatedAt))}
                    </TableCell>
                    <TableCell className={`cellStyle ${classes.tdGroupCategory} ${cellColorByType}`}>
                      {GROUP_CATEGORY_LABLES[row.groupCategory]}
                    </TableCell>
                    <TableCell className={`${classes.tdGroupName} cellStyle  ${cellColorByType}`}>
                      {row.groupName}
                    </TableCell>
                    <TableCell className={`cellStyle ${classes.tdEdit}`}>
                      <NormalButton className={classes.list_setting} onClick={() => handleClickList(row)}>
                        一覧
                      </NormalButton>
                    </TableCell>
                    <TableCell className={`cellStyle ${classes.tdEdit}`}>
                      <NormalButton className={classes.list_setting} onClick={() => handleClickSetting(row)}>
                        設定
                      </NormalButton>
                    </TableCell>
                    <TableCell className={`cellStyle ${classes.tdDataBrowsing}`}>
                      <DataIcon className="control-icon" onClick={() => handleClickDataBrowsing(row)} />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default GroupTable;
