import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core';
import COMMON from 'constants/common';
import { useAppSelector } from '../../../../redux/hooks';

/* ************ Style ************ */
type StyleProps = {
  lineClamp?: string;
};
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  cellStyle: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': ({ lineClamp }) => `${lineClamp ?? 1}`,
    '-webkit-box-orient': 'vertical',
    'word-break': 'break-all',
  },
  customWidth: {
    maxWidth: 620,
  },
  tooltipParent: {
    flex: 1,
  },
}));
type TooltipProps = {
  tooltipText: string | undefined | null;
  displayWord?: string;
  lineClamp?: string;
};
const TooltipComponent: React.FC<TooltipProps> = (props) => {
  const { tooltipText, displayWord, lineClamp } = props;
  const TOOLTIP_TEXT = tooltipText === COMMON.BLANK || tooltipText === COMMON.BLANK3 ? null : tooltipText;
  const DISPLAY_WORD = (displayWord ?? TOOLTIP_TEXT) === '' ? null : displayWord ?? TOOLTIP_TEXT;
  const classes = useStyles({ lineClamp });
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      color: 'theme.palette.common.white',
      boxShadow: theme.shadows[1],
      fontSize: '21px',
    },
  }))(Tooltip);
  const { isMobile } = useAppSelector((state) => state.common.deviceInfo);
  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className={classes.tooltipParent}>
          <LightTooltip
            PopperProps={{
              disablePortal: true,
            }}
            classes={{ tooltip: classes.customWidth }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={TOOLTIP_TEXT ?? ''}
            placement="bottom-start">
            <div className={classes.cellStyle} onClick={handleTooltipOpen}>
              <span>{DISPLAY_WORD ?? COMMON.BLANK}</span>
            </div>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <>
      <LightTooltip
        classes={{ tooltip: classes.customWidth }}
        disableFocusListener
        title={TOOLTIP_TEXT ?? ''}
        placement="bottom-start">
        <div className={classes.cellStyle}>
          <span>{DISPLAY_WORD ?? COMMON.BLANK}</span>
        </div>
      </LightTooltip>
    </>
  );
};
export default TooltipComponent;
