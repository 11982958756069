import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import {
  makeStyles,
  Box,
  Typography,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { SelectButton, NormalSwitch } from 'components/system/atoms';
import { SelectListType, TenkeyOpenEventType, TenkeyOptionType, TenkeyType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';
import NewTenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/NewTenkey';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import iconPlusWhite from 'assets/images/iconPlusWhite.svg';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { setAreaConf, setOperationConf } from 'redux/slices/machineConfSlice';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import arrowIcon from 'assets/images/arrowIcon.svg';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import { AreaConfType, EachConfRowType, LocationType } from 'types/machineConf/machineConfType';
import { countAreaTotal, hasAreas } from 'utils/machineConf.helper';
import AreaDetail from 'components/machineConf/organisms/Area/AreaDetail';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import AreaSimplePit from 'components/machineConf/organisms/Area/AreaSimplePit';
import { handleCallFrontError } from 'redux/slices/commonSlice';
import MessageHelper from 'utils/message.helper';
import MESSAGES from 'constants/messages';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'エリアパワー設定',
  screenId: 'SCR325',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const TABLE_ARRAY_RENDER = [
  ['ベース', 'エリア'],
  ['エリア', '緑'],
  ['エリア', '紫'],
  ['エリア', '黄'],
  ['エリア', '赤'],
  ['エリア', '青'],
];

const INPUT_NAMES = {
  INITAL_HEIGHT: 'initialHeight',
  ARM_OPEN: 'armOpen',
  L_POWER: 'lPower',
  R_POWER: 'rPower',
  DESCENT_LIMIT: 'descentLimit',
  PUSHING: 'pushing',
};
const TENKEY_OPTION = {
  HEIGHT: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 3,
    scaleSize: 0,
    maxValue: 99,
    minValue: 0,
  },
  ARM_POWER: {
    mode: COMMON.TENKEY_MODE.DECIMAL,
    integerSize: 3,
    scaleSize: 1,
    maxValue: 200,
    minValue: 0.1,
    decimalLimit: 50,
    decimalLimit_2: 100,
  },
  DESCENT_LIMIT: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 3,
    scaleSize: 0,
    maxValue: 100,
    minValue: 1,
  },
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  baseTable: {
    borderCollapse: 'collapse',
    '& .tableTitle': {
      backgroundColor: '#FFFF77',
      '& th': {
        fontSize: '23px',
      },
    },
    '& .headTitle': {
      backgroundColor: theme.base.gray,
      '& th': {
        fontSize: '18px',
      },
    },
    '& th, td': {
      padding: 0,
      border: '1px solid',
      borderColor: theme.base.tableBorder,
      textAlign: 'center',
    },
    '& th': {
      height: '30px',
    },
    '& .area0': {
      backgroundColor: theme.base.area0,
    },
    '& .area1': {
      backgroundColor: theme.base.area1,
    },
    '& .area2': {
      backgroundColor: theme.base.area2,
    },
    '& .area3': {
      backgroundColor: theme.base.area3,
    },
    '& .area4': {
      backgroundColor: theme.base.area4,
    },
    '& .area5': {
      backgroundColor: theme.base.area5,
    },
  },
  topArea: {
    display: 'flex',
    height: '100px',
    '& .returnButton': {},
    '& .description': {
      position: 'absolute',
      top: '2px',
      left: '220px',
      height: '82px',
      fontSize: '23px',
      lineHeight: '28px',
    },
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    margin: '-4px 4px 0px 0px',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  mainArea: {
    display: 'flex',
    height: '460px',
    width: '100px',
    '& .areaConfArea': {
      position: 'absolute',
      top: '100px',
      left: '0px',
      height: 'auto',
      width: '222px',
      '& .areaConfTop': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 10px 14px 20px',
        width: '100%',
        lineHeight: '42px',
        fontSize: '19px',
      },
      '& .areaConfMain': {
        padding: '8px 10px 18px 10px',
        position: 'relative',
      },
      '& .areaConf': {
        marginLeft: '12px',
        width: '180px',
        '& .title': {
          borderWidth: '1px 1px 0px 1px',
          borderColor: theme.base.tableBorder,
          borderStyle: 'solid',
          fontSize: '23px',
          textAlign: 'center',
          backgroundColor: '#FFFF77',
          '& span': {
            color: '#FF0000',
          },
        },
        '& .area': {
          position: 'relative',
        },
      },
      '& .addArea': {
        marginTop: '4px',
        height: '60px',
        width: '200px',
        color: theme.palette.common.white,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
        justifyContent: 'flex-start',
        padding: 0,
        '& img': {
          marginLeft: '16px',
        },
        '& .caption': {
          marginLeft: '16px',
          fontSize: '19px',
        },
      },
    },
    '& .arrowIconRight': {
      position: 'absolute',
      top: '180px',
      left: '238px',
      height: '42px',
      width: '21px',
      transform: 'rotate(90deg)',
    },
    '& .areaPowerConfAreaCommon': {
      position: 'absolute',
      top: '100px',
      left: '274px',
      width: '296px',
    },
    '& .eachConfAreaTop': {
      position: 'absolute',
      top: '260px',
      left: '274px',
      width: '604px',
      '& .eachConfContainer': {
        height: '308px',
      },
    },
    '& .eachConfArea': {
      position: 'absolute',
      top: '290px',
      left: '274px',
      width: '604px',
      '& .eachConfContainer': {
        height: '278px',
      },
    },
    '& .doneArea': {
      position: 'absolute',
      top: '464px',
      left: '900px',
    },
  },
  commonConfTable: {
    '& td': {
      height: '88px',
    },
  },
  eachConfTable: {
    '& td': {
      height: '82px',
    },
    '& .rightArmPowerCell': {
      position: 'relative',
    },
  },
  disableLayer: {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      background: 'rgb(128 128 128 / 50%)',
      position: 'absolute',
      top: '0',
      zIndex: 1,
    },
  },
  catcherIcon: {
    position: 'absolute',
    top: '20px',
    left: '-20px',
    height: '38px',
    width: '38px',
  },
  selectButton: {
    width: '100px',
    height: '64px',
    fontSize: '24px',
  },
  leftStyleButton: {
    backgroundColor: theme.leftStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.leftStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.leftStation.contentsBorder,
    },
  },
  rightStyleButton: {
    backgroundColor: theme.rightStation.contentsBorder,
    '&:hover': {
      backgroundColor: theme.rightStation.contentsBorder,
    },
    '&:disabled': {
      background: theme.rightStation.contentsBorder,
    },
  },
  areaSimplePit: {
    position: 'absolute',
  },
  defaultNoteText: {
    whiteSpace: 'pre-line',
    lineHeight: '10px',
    margin: '10px 0 0 0px',
  },
  leftStyle: {
    backgroundColor: '#fff',
    borderColor: theme.leftStation.itemBorder,
    '&:before': {
      borderRightColor: theme.leftStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  rightStyle: {
    backgroundColor: '#fff',
    borderColor: theme.rightStation.itemBorder,
    '&:before': {
      borderRightColor: theme.rightStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
  disableArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '0 0 8px 8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
}));

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * EachConfRow 各エリア設定明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const EachConfRow: React.FC<EachConfRowType> = ({
  leftRight,
  index,
  tenkey,
  selectList,
  tempAreaConf,
  onClick,
  onOpen,
}) => {
  const classes = useStyles();
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);

  const areaName = TABLE_ARRAY_RENDER[index];
  const indexStr = String(index + 1);

  const lPowerName = INPUT_NAMES.L_POWER + indexStr;
  const lPowerValue = tempAreaConf ? String(tempAreaConf[lPowerName]) : '';
  const handleOpenLeftArmPower = useCallback(onOpen(lPowerName, 455, lPowerValue, TENKEY_OPTION.ARM_POWER), [
    onOpen,
    lPowerValue,
  ]);

  const rPowerName = INPUT_NAMES.R_POWER + indexStr;
  const rPowerValue = tempAreaConf ? String(tempAreaConf[rPowerName]) : '';
  const handleOpenRightArmPower = useCallback(onOpen(rPowerName, 595, rPowerValue, TENKEY_OPTION.ARM_POWER), [
    onOpen,
    rPowerValue,
  ]);

  const descentLimitName = INPUT_NAMES.DESCENT_LIMIT + indexStr;
  const descentLimitValue = tempAreaConf ? String(tempAreaConf[descentLimitName]) : '';
  const handleOpenDescentLimit = useCallback(
    onOpen(descentLimitName, 215, descentLimitValue, TENKEY_OPTION.DESCENT_LIMIT),
    [onOpen, descentLimitValue],
  );

  const pushingName = INPUT_NAMES.PUSHING + indexStr;
  const pushingValue = tempAreaConf ? String(tempAreaConf[pushingName]) : CLSFCN.PUSHING.MIDDLE;
  const handleClickPushing = useCallback(onClick(pushingName, pushingValue), [pushingValue]);

  return (
    <TableRow className={`area${String(index)}`}>
      <TableCell>
        {areaName[0]}
        <br />
        {areaName[1]}
      </TableCell>
      <TableCell
        className={operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE ? classes.disableLayer : ''}>
        <TenkeyInput
          disabled={operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE}
          isSelected={tenkey.name === lPowerName}
          leftRight={leftRight}
          value={tempAreaConf?.[lPowerName]}
          onOpen={handleOpenLeftArmPower}
        />
      </TableCell>
      <TableCell
        className={`rightArmPowerCell ${
          operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE ? classes.disableLayer : ''
        }`}>
        <img src={armPowerIcon} alt="キャッチャー" className={classes.catcherIcon} />
        <TenkeyInput
          disabled={operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE}
          isSelected={tenkey.name === rPowerName}
          leftRight={leftRight}
          value={tempAreaConf?.[rPowerName]}
          onOpen={handleOpenRightArmPower}
        />
      </TableCell>
      <TableCell>
        <TenkeyInput
          isSelected={tenkey.name === descentLimitName}
          leftRight={leftRight}
          value={tempAreaConf?.[descentLimitName]}
          onOpen={handleOpenDescentLimit}
        />
      </TableCell>
      <TableCell>
        <SelectButton
          leftRight={leftRight}
          className={classes.selectButton}
          onClick={handleClickPushing}
          isSelected={selectList.name === pushingName}>
          {tempAreaConf?.[pushingName] &&
            getClassificationName(CLSFCN.PUSHING.CATEGORY_CD, String(tempAreaConf?.[pushingName]) || '')}
        </SelectButton>
      </TableCell>
    </TableRow>
  );
};

/* ******** Main Component ******** */
/**
 * ステーション選択
 * 所属する店舗(または指定した店舗)のステーションを一覧表示する。
 *
 * @author atsushi.teruya
 * @returns {React.FC} - ステーション選択画面
 *
 */
const AreaPowerConf: React.FC = () => {
  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tenkey, setTenkey] = useState(INITIAL_TEN_KEY.tenkey);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);

  const MachineConfContextValue = {
    leftRight,
  };

  // Local
  const [tempAreaConf, setTempAreaConf] = useState(areaConf);
  const [toggleAreaConf, setToggleAreaConf] = useState(areaConf?.areaConf === COMMON.SWITCH.ON);
  const [openConfirmDialogAreaConfOn, setOpenConfirmDialogAreaConfOn] = useState(false);

  // Datasoure
  const dataSouceArmOpenList = useRef(getClsfcnByCategory(CLSFCN.ARM_OPEN.CATEGORY_CD));
  const dataSoucePushingList = useRef(getClsfcnByCategory(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD));

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const stationStyleButton = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyleButton : classes.rightStyleButton;
  const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;

  const inputAreas = hasAreas(tempAreaConf);

  /* ************ Event ************ */
  // 初期表示処理
  useEffect(() => {
    // エリア設定画面から遷移した場合、モード設定ON
    if (location.state && (location.state as LocationType).fromScreen === redirectLinks.MCONF_STATION_CONF_AREA_CONF) {
      // エリア設定を有効にする
      enableAreaConf();
    }
  }, []);

  // Tenkey
  // テンキー－決定 各項目の設定値に反映
  const handleDecisionTenkey = useCallback(
    (value: string, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempAreaConf],
  );
  // テンキー－閉じる テンキー情報を初期化
  const handleCloseTenkey = useCallback(() => setTenkey(INITIAL_TEN_KEY.tenkey), []);

  // テンキー初期値
  const initialTenkey: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecisionTenkey,
    onClose: handleCloseTenkey,
  };

  // Tenkey - Initial height
  const handleOpenInitialHeight: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkey,
      anchorPosition: { top: 100, left: 385 },
      name: INPUT_NAMES.INITAL_HEIGHT,
      option: TENKEY_OPTION.HEIGHT,
      value: String(tempAreaConf?.initialHeight) || '0',
    });
  }, [tempAreaConf?.initialHeight]);

  // Tenkey - RowItem(左右アームパワー、下降限界)
  const handleOpenRowItems = useCallback(
    (name: string, left: number, value: string, option: TenkeyOptionType): TenkeyOpenEventType => () => {
      setTenkey({
        ...initialTenkey,
        anchorPosition: { top: 100, left },
        name,
        option,
        value: value || '0',
      });
    },
    [],
  );

  // select list
  // 一覧選択－選択 各項目の設定値に反映
  const handleClickSelectList = useCallback((data: { value: string }, name: string) => {
    setTempAreaConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: data.value,
          }
        : prevState,
    );
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // 一覧選択－閉じる 一覧選択情報を初期化
  const handleCloseSelectList = useCallback(() => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // 一覧選択－呼び出しボタン選択時初期値
  const initialSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickSelectList,
    onClose: handleCloseSelectList,
  };

  // 一覧選択 - アーム開度
  const handleClickArmOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSouceArmOpenList.current,
        name: INPUT_NAMES.ARM_OPEN,
        placement: 'right',
        title: 'アーム開度を選択してください。',
        value: tempAreaConf?.armOpen,
      });
    },
    [tempAreaConf?.armOpen],
  );

  // Select List Window - Pushing 1
  const handleClickRowItems = useCallback(
    (name: string, value: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSoucePushingList.current,
        name,
        placement: 'left-end',
        title: '押込力を選択してください。',
        value,
      });
    },
    [],
  );

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_XY_PIT_AREA_TOP);
  };

  // エリア追加ボタン Redirect to SCR326
  const handleClickAddArea = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_AREA_CONF);
  };

  // 確定
  const handleClickDone = async () => {
    if (!tempAreaConf) return;
    const descentLimitList = [
      tempAreaConf.descentLimit1,
      tempAreaConf.descentLimit2,
      tempAreaConf.descentLimit3,
      tempAreaConf.descentLimit4,
      tempAreaConf.descentLimit5,
      tempAreaConf.descentLimit6,
    ];

    // 選択済みエリアの下降限界を抽出する
    let validDescentLimitList: Array<number> = [];
    if (toggleAreaConf) {
      validDescentLimitList = descentLimitList.filter((_, index) => inputAreas[index] === true);
    } else {
      validDescentLimitList.push(descentLimitList[0]);
    }

    // 初期高さ以下の下降限界が1つでもある場合、エラーを返す
    if (validDescentLimitList.some((value) => value <= Number(tempAreaConf?.initialHeight))) {
      dispatch(
        handleCallFrontError({
          errors: { key: [MessageHelper.messageFormat(MESSAGES.ERR603)] },
        }),
      );
      return;
    }

    if (!operationConf || !areaConf) return;

    // 選択済みエリアの項目のみ画面の値で更新する
    let isAreaSelected: boolean[] = inputAreas;
    if (tempAreaConf.areaConf === COMMON.SWITCH.OFF) {
      isAreaSelected = [true, false, false, false, false, false];
    }
    const newTempAreaConf: AreaConfType = {
      ...tempAreaConf,
      lPower1: isAreaSelected[0] ? tempAreaConf.lPower1 : areaConf.lPower1,
      rPower1: isAreaSelected[0] ? tempAreaConf.rPower1 : areaConf.rPower1,
      descentLimit1: isAreaSelected[0] ? tempAreaConf.descentLimit1 : areaConf.descentLimit1,
      pushing1: isAreaSelected[0] ? tempAreaConf.pushing1 : areaConf.pushing1,
      lPower2: isAreaSelected[1] ? tempAreaConf.lPower2 : areaConf.lPower2,
      rPower2: isAreaSelected[1] ? tempAreaConf.rPower2 : areaConf.rPower2,
      descentLimit2: isAreaSelected[1] ? tempAreaConf.descentLimit2 : areaConf.descentLimit2,
      pushing2: isAreaSelected[1] ? tempAreaConf.pushing2 : areaConf.pushing2,
      lPower3: isAreaSelected[2] ? tempAreaConf.lPower3 : areaConf.lPower3,
      rPower3: isAreaSelected[2] ? tempAreaConf.rPower3 : areaConf.rPower3,
      descentLimit3: isAreaSelected[2] ? tempAreaConf.descentLimit3 : areaConf.descentLimit3,
      pushing3: isAreaSelected[2] ? tempAreaConf.pushing3 : areaConf.pushing3,
      lPower4: isAreaSelected[3] ? tempAreaConf.lPower4 : areaConf.lPower4,
      rPower4: isAreaSelected[3] ? tempAreaConf.rPower4 : areaConf.rPower4,
      descentLimit4: isAreaSelected[3] ? tempAreaConf.descentLimit4 : areaConf.descentLimit4,
      pushing4: isAreaSelected[3] ? tempAreaConf.pushing4 : areaConf.pushing4,
      lPower5: isAreaSelected[4] ? tempAreaConf.lPower5 : areaConf.lPower5,
      rPower5: isAreaSelected[4] ? tempAreaConf.rPower5 : areaConf.rPower5,
      descentLimit5: isAreaSelected[4] ? tempAreaConf.descentLimit5 : areaConf.descentLimit5,
      pushing5: isAreaSelected[4] ? tempAreaConf.pushing5 : areaConf.pushing5,
      lPower6: isAreaSelected[5] ? tempAreaConf.lPower6 : areaConf.lPower6,
      rPower6: isAreaSelected[5] ? tempAreaConf.rPower6 : areaConf.rPower6,
      descentLimit6: isAreaSelected[5] ? tempAreaConf.descentLimit6 : areaConf.descentLimit6,
      pushing6: isAreaSelected[5] ? tempAreaConf.pushing6 : areaConf.pushing6,
    };

    const newOperationConf = {
      ...operationConf,
      // ベースエリアのアームパワーが上限アームパワーより大きくなる場合、
      // 上限アームパワーにベースエリアパワーの値を設定する。
      limitLPower:
        operationConf.limitLPower > newTempAreaConf.lPower1 ? operationConf.limitLPower : newTempAreaConf.lPower1,
      limitRPower:
        operationConf.limitRPower > newTempAreaConf.rPower1 ? operationConf.limitRPower : newTempAreaConf.rPower1,
      // 運営設定が「新サービスモード」「ずらし運営」のいずれか、かつエリア設定ONの場合、運営設定を「設定なし」に変更
      operationConf:
        toggleAreaConf &&
        (operationConf.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE ||
          operationConf.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION)
          ? COMMON.OPERATION_CONF.NO_CONF
          : operationConf.operationConf,
    };

    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http.post<AreaConfType>(REST_API.MACHINE_CONF.AREA_CONF_VALIDATION, newTempAreaConf);
    dispatch(setAreaConf(newTempAreaConf));
    dispatch(setOperationConf(newOperationConf));
    history.push(redirectLinks.MCONF_STATION_CONF_XY_PIT_AREA_TOP);
  };

  // エリア設定トグル
  const handleToggleAreaConf = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // トグルOFF→ON
      if (
        // 運営設定が「新サービスモード」「ずらし運営」のいずれかの場合
        operationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE ||
        operationConf?.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION
      ) {
        // ポップアップ表示
        setOpenConfirmDialogAreaConfOn(true);
      } else {
        // エリア設定を有効にする
        enableAreaConf();
      }
    } else {
      // トグルON→OFF
      // エリア設定トグルOFF
      setToggleAreaConf(false);
      // エリア設定初期化
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              areaConf: COMMON.SWITCH.OFF,
            }
          : prevState,
      );
    }
  };
  // エリア設定 OFF->ON Yes
  const handleClickOkAreaConfOnDialog = () => {
    // エリア設定を有効にする
    enableAreaConf();
    // ダイアログ閉じる
    setOpenConfirmDialogAreaConfOn(false);
  };
  // エリア設定有効化
  const enableAreaConf = () => {
    // エリア設定トグルをON
    setToggleAreaConf(true);
    // エリア設定をReduxの値に戻す
    setTempAreaConf((prevState) =>
      prevState
        ? {
            ...prevState,
            areaConf: COMMON.SWITCH.ON,
          }
        : prevState,
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                {/* 戻るボタン */}
                <Box className="returnButton">
                  <ReturnButton onClick={handleClickReturn}>
                    移動設定
                    <br />
                    TOP
                  </ReturnButton>
                </Box>
                {/* 操作説明 */}
                <Box className="description">
                  任意の「エリア」毎に、「キャッチャーのパワー」 や 「下降限界」 などを <br />
                  設定できます。各種設定をし、<Typography className={classes.decisionImage}> 確 定</Typography>
                  してください。
                  <br />
                  （最大６エリアまで設定可能です）
                </Box>
              </Box>
              <Box className={classes.mainArea}>
                {/* エリア設定表示 */}
                <Box className={`${classes.baseArea} areaConfArea ${stationStyle}`}>
                  <Box className="areaConfTop">
                    エリア設定
                    <NormalSwitch
                      leftRight={leftRight}
                      value=""
                      checked={toggleAreaConf}
                      onChange={handleToggleAreaConf}
                    />
                  </Box>
                  <Box className="areaConfMain">
                    <Box className="areaConf">
                      <Box className="title">
                        エリア数<span>「{countAreaTotal(tempAreaConf)}」</span>
                      </Box>
                      <Box className="area">
                        <AreaDetail
                          displayCellSeparate={pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                          displayTableBorder
                          defaultBorderColor
                          isDefault={!toggleAreaConf}
                          leftRight={leftRight}
                          size="S"
                          areaConf={tempAreaConf}
                          pitConf={pitConf}
                        />
                        {pitConf && pitConf.settingPattern === CLSFCN.PIT_PATTERN.SIMPLE && (
                          <AreaSimplePit className={classes.areaSimplePit} pitConf={pitConf} size="S" />
                        )}
                      </Box>
                    </Box>
                    <NormalButton
                      onClick={handleClickAddArea}
                      className={`${stationStyleButton} addArea`}
                      disabled={!toggleAreaConf}>
                      <img src={iconPlusWhite} alt="iconPlusWhite" />
                      <span className="caption">エリアを追加</span>
                    </NormalButton>
                    {!toggleAreaConf && <Box className={classes.disableArea} />}
                  </Box>
                </Box>
                <img src={arrowIcon} alt="arrowIcon" className="arrowIconRight" />
                {/* 全共通エリア設定 */}
                <Box className="areaPowerConfAreaCommon">
                  <TableContainer>
                    <Table className={`${classes.baseTable} ${classes.commonConfTable}`}>
                      <TableHead>
                        <TableRow className="tableTitle">
                          <TableCell colSpan={2}>全エリア共通設定</TableCell>
                        </TableRow>
                        <TableRow className="headTitle">
                          <TableCell width="126px">初期高さ</TableCell>
                          <TableCell width="170px">アーム開度</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TenkeyInput
                              isSelected={tenkey.name === INPUT_NAMES.INITAL_HEIGHT}
                              name={INPUT_NAMES.INITAL_HEIGHT}
                              leftRight={leftRight}
                              value={String(tempAreaConf?.initialHeight)}
                              onOpen={handleOpenInitialHeight}
                            />
                          </TableCell>
                          <TableCell>
                            <SelectButton
                              leftRight={leftRight}
                              className={classes.selectButton}
                              onClick={handleClickArmOpen}
                              isSelected={selectList.name === INPUT_NAMES.ARM_OPEN}>
                              {tempAreaConf?.armOpen &&
                                getClassificationName(CLSFCN.ARM_OPEN.CATEGORY_CD, tempAreaConf.armOpen)}
                            </SelectButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* 各エリアの設定 */}
                <Box className="eachConfAreaTop">
                  <TableContainer className="eachConfContainer">
                    <Table className={`${classes.baseTable} ${classes.eachConfTable}`}>
                      <TableHead>
                        <TableRow className="tableTitle">
                          <TableCell colSpan={5}>各エリアの設定</TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Box>
                <Box className="eachConfArea">
                  <TableContainer className="eachConfContainer">
                    <Table className={`${classes.baseTable} ${classes.eachConfTable}`}>
                      <TableHead>
                        <TableRow className="headTitle">
                          <TableCell width="72px">エリア</TableCell>
                          <TableCell width="150px">左アームパワー</TableCell>
                          <TableCell width="150px">右アームパワー</TableCell>
                          <TableCell width="118px">下降限界</TableCell>
                          <TableCell width="118px">押込力</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="body">
                        {TABLE_ARRAY_RENDER.map(
                          (value, index) =>
                            ((!toggleAreaConf && index === 0) || (toggleAreaConf && inputAreas[index])) && (
                              <EachConfRow
                                key={String(index)}
                                index={index}
                                leftRight={leftRight}
                                tenkey={tenkey}
                                selectList={selectList}
                                tempAreaConf={tempAreaConf}
                                onClick={handleClickRowItems}
                                onOpen={handleOpenRowItems}
                              />
                            ),
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* 確定ボタン */}
                <Box className="doneArea">
                  <DoneButton onClick={handleClickDone}>確 定</DoneButton>
                </Box>
              </Box>
            </Box>
            <SelectList selectList={selectList} />
            <NewTenkey tenkey={tenkey} />
            <ConfirmDialog
              open={openConfirmDialogAreaConfOn}
              msg={MESSAGES.INF217}
              onOk={handleClickOkAreaConfOnDialog}
              onCancel={() => {
                setOpenConfirmDialogAreaConfOn(false);
              }}
            />
          </MachineConfContent>
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default AreaPowerConf;
