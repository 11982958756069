import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import plusButton from 'assets/images/plusButton.svg';
import plusButtonActive from 'assets/images/plusButtonActive.svg';
import minusButton from 'assets/images/minusButton.svg';
import minusButtonActive from 'assets/images/minusButtonActive.svg';
import { Button } from '@material-ui/core';
import { PlusMinusButtonType } from 'types/support/supportType';

/**
 * PlusMinusButton Component
 * 閉じるボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  contained: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  plusLabel: {
    fontSize: '32px',
    marginTop: '72px',
  },
  minusLabel: {
    fontSize: '26px',
    marginBottom: '72px',
  },
  defaultStyle: {
    height: '153px',
    width: '84px',
  },
  plusButton: {
    background: `url(${plusButton})`,
    '&:active': {
      background: `url(${plusButtonActive})`,
    },
  },
  minusButton: {
    background: `url(${minusButton})`,
    '&:active': {
      background: `url(${minusButtonActive})`,
    },
  },
}));

// コンポーネント定義
const PlusMinusButton: React.FC<PlusMinusButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { className, isPlus, onClick } = props;
  const styleClass = `${className || classes.defaultStyle} ${isPlus ? classes.plusButton : classes.minusButton}`;
  const styleLabel = `${isPlus ? classes.plusLabel : classes.minusLabel}`;

  return (
    <Button
      variant="contained"
      classes={{
        contained: classes.contained,
        label: styleLabel,
      }}
      className={styleClass}
      disableRipple
      onClick={onClick}>
      {isPlus ? 'UP' : 'DOWN'}
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
PlusMinusButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  isPlus: true,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(PlusMinusButton);
