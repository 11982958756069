import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 16px 12px 16px',
    '& .detail': {
      height: '632px',
      width: '1152px', // TOP画面のみ横幅が長い。他の画面は1144px
      borderRadius: '0 0 4px 4px',
      backgroundColor: theme.palette.common.white,
    },
  },
  contentsDetail: {
    height: '668px',
    width: '1152px', // TOP画面のみ横幅が長い。他の画面は1144px
  },
}));

const Content: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.contents}>
      <Box className={classes.contentsDetail}>
        <Box className="detail">{children}</Box>
      </Box>
    </Box>
  );
};

export default Content;
