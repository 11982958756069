import React, { memo } from 'react';
import { createStyles, makeStyles, Box } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { NormalSelectBox } from 'components/system/organisms';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import { getClsfcnByCategory } from 'utils/common.helper';
import { MachineSelectListType } from 'types/group/GroupType';
import { ScrModetype } from 'types/common/commonType';
import MachineSelectTable from '../organisms/tables/MachineSelectTable';
import { setSelectMachineSort, setSelectLedMachineSort } from '../../../redux/slices/groupSlice';

/* ************ Style ************ */
const useStyles = makeStyles(() =>
  createStyles({
    controller: {
      display: 'flex',
      height: 41,
      margin: '32px 0 12px 0',
    },
    sort: {
      flex: 1,
      marginTop: 4,
    },
    description: {
      marginLeft: 20,
      fontSize: 23,
    },
  }),
);

/* ************ Type ************ */
type Props = {
  scrMode: ScrModetype;
  groupCategory?: number;
  handleCheckAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleCheck: (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean, row: MachineSelectListType) => void;
  machineList: Array<MachineSelectListType>;
};

/* ************ Component ************ */
const MachineSelect: React.FC<Props> = ({ scrMode, groupCategory, handleCheckAll, handleCheck, machineList }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const sortDataSource = (() => {
    if (scrMode === 'new') {
      return getClsfcnByCategory(CLSFCN.MACHINE_SORT.CATEGORY_CD).filter(
        (cl) =>
          ![CLSFCN.MACHINE_SORT.UNREGISTERED_PRIORITY, CLSFCN.MACHINE_SORT.REGISTERED_PRIORITY].includes(cl.value),
      );
    }
    return getClsfcnByCategory(CLSFCN.MACHINE_SORT.CATEGORY_CD);
  })();

  /* ************ state/redux ************ */
  const { selectMachineSort } = useAppSelector((state) => {
    if (groupCategory === COMMON.GROUP_CATEGORY.LED) {
      return state.group.ledGroup;
    }
    return state.group.getGiftGroup;
  });

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    if (groupCategory === COMMON.GROUP_CATEGORY.LED) {
      dispatch(setSelectLedMachineSort(ev.target.value));
    } else {
      dispatch(setSelectMachineSort(ev.target.value));
    }
  };

  return (
    <>
      <Box className={classes.controller}>
        <Box className={classes.description}>グループに登録する機器を選択</Box>
        <Box className={classes.sort}>
          <NormalSelectBox
            name="sort"
            dataSource={sortDataSource}
            value={selectMachineSort}
            onChange={handleChangeSort}
            label="並べ替え"
          />
        </Box>
      </Box>
      <MachineSelectTable handleCheckAll={handleCheckAll} handleCheck={handleCheck} rows={machineList} />
    </>
  );
};

export default memo(MachineSelect);
