// システム通して色味の一貫性を持たせるため、共通で使われる色はこのファイルに定義する。
// ※Material UIのTheme Providerは使わない。独自デザインが多くMaterialUIのデザインルールから外れることが多くなると予想されるため。

const theme = {
  // common
  mainText: 'rgba(0, 0, 0, 0.87)',
  background: 'orange',
  disabled: '#707070',
  // left
  leftDarkColor: '#01C3D5',
  leftLightColor: '#EEFEFF',
  leftAlphaColor: 'rgba(1, 195, 213, 0.5)',
  // right
  rightDarkColor: '#FF4386',
  rightLightColor: '#FFF9FC',
  rightAlphaColor: 'rgba(255, 67, 134, 0.5)',
};

export { theme as themeColor };
