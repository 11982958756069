import React, { memo, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import NomalSnackbar from 'components/system/atoms/snackbars/NomalSnackbar';
import { handleClearApiError } from 'redux/slices/commonSlice';
import { useAppDispatch } from 'redux/hooks';

/**
 * Error Snackbar Component
 * エラー用スナックバー
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */

/* ************ Type ************ */
type ErrorSnackbarProps = {
  isError: boolean;
  errors?: { key: string[] };
};

/* ************ Function ************ */
// errorsからメッセージを取得し1つの変数にまとめる。
const getMassages = (errors?: { key: string[] }): string => {
  let messsages = '';
  if (errors) {
    Object.entries(errors).forEach(([, msgs]) => {
      msgs.forEach((msg) => {
        messsages += `・${msg}\n`;
      });
    });
  }
  return messsages;
};

/* ************ Component ************ */
const ErrorSnackbar: React.FC<ErrorSnackbarProps> = memo((props) => {
  const dispatch = useAppDispatch();
  const { isError, errors } = props;
  const [open, setOpen] = useState<boolean>(false);
  const messages = getMassages(errors);

  useEffect(() => {
    if (!messages) {
      setOpen(false);
      return;
    }
    setOpen(isError);
  }, [isError]);

  /* ************ Event ************ */
  const handleClose = () => {
    /*
    if (reason === 'clickaway') {
      return; // 画面をクリックしても閉じない。
    }
    */
    dispatch(handleClearApiError());
  };

  const action = (
    <Button size="small" onClick={handleClose}>
      閉じる
    </Button>
  );

  return (
    <NomalSnackbar
      open={open}
      message={messages}
      key={new Date().getTime()}
      autoHideDuration={10000}
      onClose={handleClose}
      action={action}
    />
  );
});

export default ErrorSnackbar;
