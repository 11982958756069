import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CLSFCN from 'constants/classification';
import { getClassificationName } from 'utils/common.helper';
import { useAppSelector } from 'redux/hooks';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { Box } from '@material-ui/core';

/**
 * LeverConfInfoButton Component
 * レバー操作設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    position: 'relative',
    fontSize: 22,
  },
  child: {
    margin: '0 0px 0px 0',
  },
  blockLever: {
    display: 'block',
    margin: '30px 0 90px 0',
    fontSize: '19px',
  },
  blockLeverNone: {
    display: 'block',
    margin: '30px 0 90px 0',
    fontSize: '19px',
    color: '#A3525F',
  },
}));

type ButtonProps = {
  title: string;
  onClick?: () => void;
};

// コンポーネント定義
const LeverConfInfoButton: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);

  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { title, onClick } = props;

  const disableLever = xySpeedLeverConf?.leverStatus === '0';

  const handleClick = () => {
    if (disableLever) return;
    if (onClick) onClick();
  };

  const leverConfInfo = () => {
    const item: Array<JSX.Element> = [];
    if (xySpeedLeverConf?.leverStatus === '0') {
      item.push(
        <Box className={classes.blockLeverNone}>
          <Box>
            レバーが
            <br />
            認識できません
          </Box>
          <Box>&nbsp;</Box>
        </Box>,
      );
    } else {
      item.push(
        <Box className={classes.blockLever}>
          <Box>
            {getClassificationName(CLSFCN.SETTING_LEVER_OPEATION.ENTER_BUTTON, String(xySpeedLeverConf?.enterButton))}
          </Box>
          <br />
          <Box>
            {getClassificationName(CLSFCN.SETTING_LEVER_OPEATION.LEVER_TIME, String(xySpeedLeverConf?.leverTime))}
          </Box>
        </Box>,
      );
    }
    return item;
  };

  return (
    <NormalButton variant="contained" className={classes.button} onClick={handleClick}>
      <Box className={classes.frame}>
        <Box className={classes.title}>
          <b>{title}</b>
        </Box>
        <Box className={classes.child}>{leverConfInfo()}</Box>
      </Box>
    </NormalButton>
  );

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(LeverConfInfoButton);
