import COMMON from 'constants/common';
import React, { useEffect, useMemo, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NormalSwitch, ReturnButton, SelectButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectListType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { setLedServiceOtherConf } from 'redux/slices/machineConfSlice';
import { LedServiceOtherConfType } from 'types/machineConf/machineConfType';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { themeColor } from 'constants/theme';
import UrlHelper from 'utils/url.helper';
import LedSelectArea from 'components/machineConf/organisms/LedConf/LedSelectArea';
import { ledSelectAreaConfType } from 'types/machineConf/ledSelectAreaConfType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '照明演出設定',
  screenId: COMMON.SCREEN_ID.LED_CONF,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '24px',
    '& .middleRightArea': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .ledSelectArea': {
      position: 'relative',
    },
    '& .settingButton': {
      width: '224px',
      height: '48px',
      fontSize: '19px',
      border: '2px solid #000',
      borderRadius: '8px',
      padding: '0 8px',
      '& div': {
        textAlign: 'left',
      },
    },
    '& .ledDirecting': {
      marginTop: '16px',
    },
    '& .ecoMode': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '114px',
    },
    '& .doneButton': {
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
  },
  // ステーション別Style
  leftStyle: {
    borderColor: themeColor.leftDarkColor,
    '&:before': {
      borderRightColor: themeColor.leftDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
    '&:before': {
      borderRightColor: themeColor.rightDarkColor,
    },
    '&:after': {
      borderRightColor: '#FFF',
    },
  },
  // Common Style
  baseFontSize: {
    fontSize: '19px',
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  LED_SETTING: 'ledSetting',
  LED_DIRECTING: 'ledDirecting',
  ECO_MODE: 'ecoMode',
};

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - 照明演出設定
 *
 */
const LedConf: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineConf.ledServiceOtherConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);

  // Local
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tempLedServiceOtherConf, setTempLedServiceOtherConf] = useState<LedServiceOtherConfType | undefined>(
    ledServiceOtherConf,
  );

  // DataSource
  const ledSettingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_SETTING.CATEGORY_CD), []);
  const ledDirectingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LED_DIRECTING.CATEGORY_CD), []);

  // Disable group
  const isLedSettingDisable = tempLedServiceOtherConf?.ledSetting === CLSFCN.LED_SETTING.SIMPLE;

  /* ************ Event ************ */
  // 画面表示時
  useEffect(() => {
    // グローバルステートが見つからない場合、システムエラー画面に遷移
    if (!ledServiceOtherConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
        selectConfId: 'none',
      }),
    );
  };

  // NormalSwitch 押下 共通処理
  const handleClickSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked }: { id: string; checked: boolean } = event.target;
    setTempLedServiceOtherConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [id]: checked ? COMMON.VALID_FLG.VALID : COMMON.VALID_FLG.INVALID,
          }
        : prevState,
    );
  };

  // SelectList 各項目の設定値に反映
  const handleClickSelectList = (data: { value: string }, name: string) => {
    setTempLedServiceOtherConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: data.value,
          }
        : prevState,
    );
    setSelectList(INITIAL_SELECT_LIST.selectList);
  };

  // SelectList 一覧選択情報を初期化
  const handleCloseSelectList = () => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  };

  // SelectList 設定初期値
  const initialSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickSelectList,
    onClose: handleCloseSelectList,
  };

  // 設定内容 選択
  const handleClickLedSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectList({
      ...initialSelectList,
      anchorEL: event.currentTarget,
      dataSource: ledSettingDataSource,
      name: INPUT_NAMES.LED_SETTING,
      placement: 'left',
      title: '設定内容を選択してください。',
      value: tempLedServiceOtherConf?.ledSetting,
    });
  };

  // 演出内容 選択
  const handleClickLedDirecting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectList({
      ...initialSelectList,
      anchorEL: event.currentTarget,
      dataSource: ledDirectingDataSource,
      name: INPUT_NAMES.LED_DIRECTING,
      placement: 'left',
      title: '演出内容を選択してください。',
      value: tempLedServiceOtherConf?.ledDirecting,
    });
  };

  const handleClickDone = async () => {
    // // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.LED_CONF_VALIDATION, {
        // 照明演出設定
        ledSetting: tempLedServiceOtherConf?.ledSetting,
        ledDirecting: tempLedServiceOtherConf?.ledDirecting,
        lLedColor4: tempLedServiceOtherConf?.lLedColor4,
        lLedEffect4: tempLedServiceOtherConf?.lLedEffect4,
        lLedColor3: tempLedServiceOtherConf?.lLedColor3,
        lLedEffect3: tempLedServiceOtherConf?.lLedEffect3,
        lLedColor2: tempLedServiceOtherConf?.lLedColor2,
        lLedEffect2: tempLedServiceOtherConf?.lLedEffect2,
        lLedColor1: tempLedServiceOtherConf?.lLedColor1,
        lLedEffect1: tempLedServiceOtherConf?.lLedEffect1,
        rLedColor4: tempLedServiceOtherConf?.rLedColor4,
        rLedEffect4: tempLedServiceOtherConf?.rLedEffect4,
        rLedColor3: tempLedServiceOtherConf?.rLedColor3,
        rLedEffect3: tempLedServiceOtherConf?.rLedEffect3,
        rLedColor2: tempLedServiceOtherConf?.rLedColor2,
        rLedEffect2: tempLedServiceOtherConf?.rLedEffect2,
        rLedColor1: tempLedServiceOtherConf?.rLedColor1,
        rLedEffect1: tempLedServiceOtherConf?.rLedEffect1,
        ecoMode: tempLedServiceOtherConf?.ecoMode,
        ecoType: tempLedServiceOtherConf?.ecoType,
      })
      .then(() => {
        // 照明設定をグローバルステートに保存
        dispatch(setLedServiceOtherConf(tempLedServiceOtherConf));
        // 画面遷移
        history.push(
          UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MCONF_STATION_CONF_TOP, {
            selectConfId: 'none',
          }),
        );
      });
  };

  // LedSelectAreaの変更内容を反映
  const handleChangeLedSelectArea = (ledSelectAreaConf: ledSelectAreaConfType): void => {
    setTempLedServiceOtherConf((prevState) =>
      prevState
        ? {
            ...prevState,
            // left
            lLedColor4: ledSelectAreaConf.lLedColor4,
            lLedEffect4: ledSelectAreaConf.lLedEffect4,
            lLedColor3: ledSelectAreaConf.lLedColor3,
            lLedEffect3: ledSelectAreaConf.lLedEffect3,
            lLedColor2: ledSelectAreaConf.lLedColor2,
            lLedEffect2: ledSelectAreaConf.lLedEffect2,
            lLedColor1: ledSelectAreaConf.lLedColor1,
            lLedEffect1: ledSelectAreaConf.lLedEffect1,
            // right
            rLedColor4: ledSelectAreaConf.rLedColor4,
            rLedEffect4: ledSelectAreaConf.rLedEffect4,
            rLedColor3: ledSelectAreaConf.rLedColor3,
            rLedEffect3: ledSelectAreaConf.rLedEffect3,
            rLedColor2: ledSelectAreaConf.rLedColor2,
            rLedEffect2: ledSelectAreaConf.rLedEffect2,
            rLedColor1: ledSelectAreaConf.rLedColor1,
            rLedEffect1: ledSelectAreaConf.rLedEffect1,
          }
        : prevState,
    );
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                設定TOP
              </ReturnButton>
              <Box className="discription">
                「照明の演出」を設定できます。 設定内容を選択し、
                <Typography className={classes.doneImage}>確 定</Typography>してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className="ledSelectArea">
                {tempLedServiceOtherConf && (
                  <LedSelectArea
                    leftRight={leftRight || ''}
                    ledSelectAreaConf={tempLedServiceOtherConf}
                    onChange={handleChangeLedSelectArea}
                    disabled={isLedSettingDisable}
                  />
                )}
                {/* かんたん設定のときは、色・効果を設定不可にする */}
                {tempLedServiceOtherConf?.ledSetting === CLSFCN.LED_SETTING.SIMPLE && (
                  <Box className={classes.disableInputArea} />
                )}
              </Box>
              <Box>
                <Box className="ledSetting">
                  <Box className={classes.baseFontSize}>設定内容</Box>
                  <SelectButton
                    className="settingButton"
                    onClick={handleClickLedSetting}
                    arrowSize="28px"
                    isSelected={selectList.name === INPUT_NAMES.LED_SETTING}>
                    {tempLedServiceOtherConf?.ledSetting &&
                      getClassificationName(CLSFCN.LED_SETTING.CATEGORY_CD, tempLedServiceOtherConf?.ledSetting)}
                  </SelectButton>
                </Box>
                <Box className="ledDirecting">
                  <Box className={classes.baseFontSize}>演出内容</Box>
                  <SelectButton
                    className="settingButton"
                    onClick={handleClickLedDirecting}
                    arrowSize="28px"
                    isSelected={selectList.name === INPUT_NAMES.LED_DIRECTING}>
                    {tempLedServiceOtherConf?.ledDirecting &&
                      getClassificationName(CLSFCN.LED_DIRECTING.CATEGORY_CD, tempLedServiceOtherConf?.ledDirecting)}
                  </SelectButton>
                </Box>
                <Box className="middleRightArea">
                  <Box className="ecoMode">
                    <Box className={classes.baseFontSize}>エコモード</Box>
                    <NormalSwitch
                      id={INPUT_NAMES.ECO_MODE}
                      leftRight={leftRight}
                      value=""
                      checked={tempLedServiceOtherConf?.ecoMode === COMMON.VALID_FLG.VALID}
                      onChange={handleClickSwitch}
                    />
                  </Box>
                  <Box className="doneButton">
                    <DoneButton onClick={handleClickDone}>確 定</DoneButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <SelectList selectList={selectList} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default LedConf;
