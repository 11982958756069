import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
