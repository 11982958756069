import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  cancelMachineConfThunk,
  getStationListThunk,
  initializeMachineConf,
  initializeStationSelect,
  setSelectedStationInfo,
  setSelectedStationList,
} from 'redux/slices/machineConfSlice';
import COMMON from 'constants/common';
import StationTable from 'components/machineConf/organisms/tables/StationTable';
import UrlHelper from 'utils/url.helper';
import MESSAGES from 'constants/messages';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import { getUserAuthThunk } from 'redux/slices/commonSlice';
import { ConfirmDialog } from 'components/system/organisms';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll';
import { SelectedStationType, StationDetailType } from 'types/machineConf/stationSelectType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ステーション選択',
  screenId: 'SCR311',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  initializeState: true,
};
const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 7;
export const USE_REDUX_VALUES = {
  FALSE: '0', // Reduxの値を私用しない
  TRUE: '1', // Reduxの値を使用する
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '24px 16px',
  },
  description: {
    padding: '0 0 0px 24px',
    height: '36px',
    fontSize: '23px',
  },
  sortArea: {
    paddingBottom: '24px',
  },
  decision: {
    textAlign: 'right',
    width: '1100px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '29px',
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
}));

/* ******** Main Component ******** */
/**
 * ステーション選択
 * 所属する店舗(または指定した店舗)のステーションを一覧表示する。
 *
 * @author atsushi.teruya
 * @returns {React.FC} - ステーション選択画面
 *
 */
const StationSelect: React.FC = () => {
  /* ************ hooks ************ */
  const classes = useStyles();
  const history = useHistory();

  /* ************ state/redux ************ */
  // selectStationListItem 選択中のステーション情報 設定開始時に値を登録
  const { stationList, selectedBaseConfRowIdx } = useAppSelector((state) => state.machineConf.stationSelect);
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const dispatch = useAppDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // 確認ダイアログ用
  const [openInfoDialog, setOpenInfoDialog] = useState(false); // メッセージダイアログ用
  const [openInfoDialogERR605, setOpenInfoDialogERR605] = useState(false); // メッセージダイアログ用(ERR605)
  const [openDialogINF244, setOpenDialogINF244] = useState(false); // 確認ダイアログ用(INF244)
  const [openDialogINF245, setOpenDialogINF245] = useState(false); // 確認ダイアログ用(INF245)
  const baseConfId = useRef<number | null>(null);
  const selectedStation = useRef<SelectedStationType>({
    stationName: '',
    giftName: '',
    leftRight: '',
    boardStation: '',
    machineType: null,
    machineVersion: null,
  });
  const selectedStationList = useRef<StationDetailType[]>([]);

  /* ************ Event ************ */
  // 初期表示
  useEffect(() => {
    // 認証情報(権限別画面設定、権限(role))再取得
    dispatch(getUserAuthThunk());
    // 念のために機器設定情報初期化
    dispatch(initializeMachineConf());
    // 現在店舗設定時のみ値を取得。現在店舗はこの画面で変更しないので初期表示のみ実行。
    if (loginUserInfo?.currentStoreId) {
      dispatch(
        getStationListThunk({ headquartersId: loginUserInfo?.currentHeadQId, storeId: loginUserInfo?.currentStoreId }),
      );
    }
  }, []);

  // 設定開始ボタン クリック
  const handleClickSettingStart = () => {
    const machineTypeSet = new Set();
    const machineVersionSet = new Set();

    // 選択中のステーションを抽出
    const selectedList = stationList.filter((value) => value.selectStation);
    selectedStationList.current = selectedList;
    for (let i = 0; i < selectedList.length; i += 1) {
      const station = selectedList[i];
      // チェック用に保持
      machineTypeSet.add(station.machineType);
      machineVersionSet.add(station.machineVersion);
      // 基準設定に指定したステーションの情報を取得
      if (station.baseConfSelect === station.stationId) {
        baseConfId.current = station.confId;
        selectedStation.current = {
          stationName: station.stationName || '',
          giftName: station.giftName || '',
          leftRight: station.leftRight || '',
          boardStation: station.boardStation,
          machineType: station.machineType,
          machineVersion: station.machineVersion,
        };
      }
    }

    // ステーションの選択有無を判定
    if (selectedList.length === 0 || !baseConfId) {
      // ステーションが1件も選択されていない または 基準設定の指定がない場合、エラーメッセージ表示
      setOpenInfoDialog(true);
    } else if (machineTypeSet.size > 1) {
      // 機種数チェック 1種類以外の場合、エラーメッセージ表示
      setOpenInfoDialogERR605(true);
    } else if (machineVersionSet.size > 1) {
      setOpenDialogINF244(true);
    } else if (
      selectedStation.current.machineType === COMMON.MACHINE_TYPE.CLENA3 &&
      selectedStation.current.machineVersion === null
    ) {
      setOpenDialogINF245(true);
    } else {
      redirectScreen();
    }
  };

  // 画面遷移時の共通処理
  const redirectScreen = () => {
    // reduxに情報を保存
    dispatch(initializeStationSelect()); // 一度ステーション情報を破棄。
    dispatch(setSelectedStationInfo(selectedStation.current)); // 基準選択したステーション
    dispatch(setSelectedStationList(selectedStationList.current)); // 選択中のステーション

    const redirect =
      selectedStation.current.machineType !== null
        ? redirectLinks.MCONF_JACK_CONF_TOP
        : redirectLinks.MCONF_STATION_CONF_TOP;

    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirect, {
        selectConfId: String(baseConfId.current),
      }),
    );
  };

  // メッセージダイアログ(ステーションを1件以上選択) 閉じる(ボタンクリック)
  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
    setOpenInfoDialogERR605(false);
    setOpenConfirmDialog(false);
    setOpenDialogINF244(false);
    setOpenDialogINF245(false);
  };

  // 確認ダイアログ(受取待機中キャンセル)) OKボタン クリック
  const handleClickOk = async (): Promise<void> => {
    setOpenConfirmDialog(false);
    const stationId = 1;
    dispatch(cancelMachineConfThunk({ stationId }));
  };

  // スクロールボタン(前) クリック
  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  // スクロールボタン(後) クリック
  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Grid container alignItems="flex-end">
            <Grid className={classes.description}>設定を開始するステーションを選択</Grid>
          </Grid>
          <Grid className={classes.previousBtn}>
            {stationList.length > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
          </Grid>
          {/** ステーション一覧 */}
          <StationTable stationList={stationList} selectedBaseConfRowIdx={selectedBaseConfRowIdx} tableId={TABLE_ID} />
          <Grid className={classes.nextBtn}>
            {MAX_DISPLAY_BAR < stationList.length && (
              <PrevNextButton className="nextScroll" onClick={handleClickNext} />
            )}
          </Grid>
          <Grid className={classes.decision}>
            <DecisionButton onClick={handleClickSettingStart}>設定開始</DecisionButton>
          </Grid>
        </Box>
        <InfoDialog open={openInfoDialog} msg={MESSAGES.INF110} closeFunc={handleCloseInfoDialog} />
        <InfoDialog open={openInfoDialogERR605} msg={MESSAGES.ERR605} closeFunc={handleCloseInfoDialog} />
        <ConfirmDialog
          open={openConfirmDialog}
          msg={MESSAGES.INF205}
          onOk={handleClickOk}
          onCancel={handleCloseInfoDialog}
        />
        <ConfirmDialog
          open={openDialogINF244}
          msg={MESSAGES.INF244}
          onOk={redirectScreen}
          onCancel={handleCloseInfoDialog}
        />
        <ConfirmDialog
          open={openDialogINF245}
          msg={MESSAGES.INF245}
          onOk={redirectScreen}
          onCancel={handleCloseInfoDialog}
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default StationSelect;
