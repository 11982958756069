import React, { FC, memo } from 'react';

type props = {
  size?: number;
  className?: string;
};

const DownArrowIcon: FC<props> = ({ size, className }) => (
  <svg
    id="DownArrowIcon"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    className={className}
    viewBox="0 0 36 36">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="長方形_282"
          data-name="長方形 282"
          width="36"
          height="36"
          transform="translate(95 46)"
          stroke="#707070"
          strokeWidth="1"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="長方形_284" data-name="長方形 284" width="36" height="36" fill="none" />
      </clipPath>
    </defs>
    <g id="DownArrowIcon-2" data-name="DownArrowIcon" transform="translate(-95 -46)" clipPath="url(#clip-path)">
      <g id="DownArrowIcon-3" data-name="DownArrowIcon" transform="translate(95 46)" clipPath="url(#clip-path-2)">
        <g id="グループ_311" data-name="グループ 311">
          <path
            id="パス_327"
            data-name="パス 327"
            d="M17.621,26.513.915,10.045a.54.54,0,0,1,.38-.925H34.706a.54.54,0,0,1,.379.925L18.38,26.513a.541.541,0,0,1-.759,0"
          />
        </g>
      </g>
    </g>
  </svg>
);

DownArrowIcon.defaultProps = {
  size: 16,
};

export default memo(DownArrowIcon);
