import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseButtonType } from 'types/system/buttonType';
import deleteButton from 'assets/images/deleteButton.svg';
import deleteButtonActive from 'assets/images/deleteButtonActive.svg';

/**
 * DeleteButton Component
 * 閉じるボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  contained: {
    background: `url(${deleteButton})`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&:active': {
      background: `url(${deleteButtonActive})`,
    },
  },
  label: {
    fontSize: '80px',
    color: '#828282',
    marginLeft: '20px',
  },
  defaultStyle: {
    fontSize: '70px',
    lineHeight: '84px',
    height: '84px',
    width: '84px',
  },
}));

// コンポーネント定義
const DeleteButton: React.FC<BaseButtonType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { className, onClick } = props;

  const styleClass = `${className || classes.defaultStyle}`;

  return (
    <Button
      variant="contained"
      classes={{
        contained: classes.contained,
        label: classes.label,
      }}
      className={styleClass}
      disableRipple
      onClick={onClick}
    />
  );
};

// propsの初期値。未指定時に使用する。
DeleteButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(DeleteButton);
