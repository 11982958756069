import React, { memo } from 'react';
import { Box, Table, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from 'redux/hooks';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import COMMON from 'constants/common';

/**
 * AreaConfInfoButton Component
 * エリア別設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  buttonM: {
    // position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '552px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    position: 'relative',
    transform: 'translateY(30%)',
    fontSize: 22,
  },
  child: {
    margin: '0 0px 0px 0',
  },
  detailBlock: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'block',
  },
  detailBlockBottom: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'block',
    marginTop: '2px',
  },
  detailInline: {
    borderCollapse: 'collapse',
    fontSize: '19px',
    display: 'inline',
  },
  areaTop: {
    backgroundColor: '#D2F9FF',
    width: '105px',
    height: '32px',
    border: '1px solid #707070',
  },
  areaTopValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '48px',
    height: '32px',
  },
  areaBottom: {
    backgroundColor: '#D2F9FF',
    border: '1px solid #707070',
    height: '50px',
    width: '48px',
    lineHeight: '22px',
    verticalAlign: 'middle',
  },
  areaBottomValue: {
    backgroundColor: '#ffffff',
    border: '1px solid #707070',
    width: '48px',
    height: '35px',
    verticalAlign: 'middle',
  },
  bgWhite: {
    background: '#ffffff',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
  bgGreen: {
    background: '#C9F2E2',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
  bgPurple: {
    background: '#EBCFEB',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
  bgYellow: {
    background: '#FFFDB5',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
  bgRed: {
    background: '#FFC8C8',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
  bgBlue: {
    background: '#B4DFFF',
    width: '38px',
    height: '24px',
    border: '1px solid #707070',
    marginLeft: '3px',
  },
}));

type ButtonProps = {
  title: string;
  onClick?: () => void;
};

// コンポーネント定義
const AreaConfInfoButtonJack: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);

  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // 変数定義
  let lineFlg1 = false;
  let lineFlg2 = false;
  let lineFlg3 = false;
  let lineFlg4 = false;
  let lineFlg5 = false;
  let lineFlg6 = false;
  const line1 = String(areaConf?.line1).slice(1);
  const line2 = String(areaConf?.line2).slice(1);
  const line3 = String(areaConf?.line3).slice(1);
  const line4 = String(areaConf?.line4).slice(1);
  const line5 = String(areaConf?.line5).slice(1);
  const line6 = String(areaConf?.line6).slice(1);
  const flagOperationConf = operationConf?.operationConf === COMMON.OPERATION_CONF.NO_CONF;
  // 各エリア色の設定有無を確認
  if (
    line1.includes('0') ||
    line2.includes('0') ||
    line3.includes('0') ||
    line4.includes('0') ||
    line5.includes('0') ||
    line6.includes('0')
  ) {
    lineFlg1 = true;
  }
  if (
    line1.includes('1') ||
    line2.includes('1') ||
    line3.includes('1') ||
    line4.includes('1') ||
    line5.includes('1') ||
    line6.includes('1')
  ) {
    lineFlg2 = true;
  }
  if (
    line1.includes('2') ||
    line2.includes('2') ||
    line3.includes('2') ||
    line4.includes('2') ||
    line5.includes('2') ||
    line6.includes('2')
  ) {
    lineFlg3 = true;
  }
  if (
    line1.includes('3') ||
    line2.includes('3') ||
    line3.includes('3') ||
    line4.includes('3') ||
    line5.includes('3') ||
    line6.includes('3')
  ) {
    lineFlg4 = true;
  }
  if (
    line1.includes('4') ||
    line2.includes('4') ||
    line3.includes('4') ||
    line4.includes('4') ||
    line5.includes('4') ||
    line6.includes('4')
  ) {
    lineFlg5 = true;
  }
  if (
    line1.includes('5') ||
    line2.includes('5') ||
    line3.includes('5') ||
    line4.includes('5') ||
    line5.includes('5') ||
    line6.includes('5')
  ) {
    lineFlg6 = true;
  }

  const AreaSpecificSettingsLine2 = lineFlg2 && flagOperationConf;
  const AreaSpecificSettingsLine3 = lineFlg3 && flagOperationConf;
  const AreaSpecificSettingsLine4 = lineFlg4 && flagOperationConf;
  const AreaSpecificSettingsLine5 = lineFlg5 && flagOperationConf;
  const AreaSpecificSettingsLine6 = lineFlg6 && flagOperationConf;

  // propsのプロパティ情報を取得
  const { title, onClick } = props;

  return (
    <NormalButton variant="contained" className={classes.buttonM} onClick={onClick}>
      <Box className={classes.frame}>
        <Box className={classes.title}>
          <b>{title}</b>
        </Box>
        <Box className={classes.child}>
          <Box>
            <Table className={classes.detailBlock}>
              <TableRow>
                <td className={classes.areaTop}>初期高さ</td>
                <td className={classes.areaTopValue}>{areaConf?.initialHeight}</td>
              </TableRow>
              <TableRow>
                <td className={classes.areaTop}>アーム開度</td>
                <td className={classes.areaTopValue}>{areaConf?.armOpen}%</td>
              </TableRow>
            </Table>
            <Table className={classes.detailBlockBottom}>
              <Box className={classes.detailInline}>
                <TableRow>
                  <td className={classes.areaBottom}>ｴﾘｱ</td>
                  <td className={classes.areaBottom}>
                    左<br />
                    ｱｰﾑ
                  </td>
                  <td className={classes.areaBottom}>
                    右<br />
                    ｱｰﾑ
                  </td>
                  <td className={classes.areaBottom}>
                    下降
                    <br />
                    限界
                  </td>
                  <td className={classes.areaBottom}>
                    押込
                    <br />力
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgWhite}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{lineFlg1 === true ? areaConf?.lPower1 : '－'}</td>
                  <td className={classes.areaBottomValue}>{lineFlg1 === true ? areaConf?.rPower1 : '－'}</td>
                  <td className={classes.areaBottomValue}>{lineFlg1 === true ? areaConf?.descentLimit1 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {lineFlg1 === true
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing1))
                      : '－'}
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgGreen}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine2 ? areaConf?.lPower2 : '－'}</td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine2 ? areaConf?.rPower2 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine2 ? areaConf?.descentLimit2 : '－'}
                  </td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine2
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing2))
                      : '－'}
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgPurple}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine3 ? areaConf?.lPower3 : '－'}</td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine3 ? areaConf?.rPower3 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine3 ? areaConf?.descentLimit3 : '－'}
                  </td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine3
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing3))
                      : '－'}
                  </td>
                </TableRow>
              </Box>
              &nbsp;&nbsp;&nbsp;
              <Box className={classes.detailInline}>
                <TableRow>
                  <td className={classes.areaBottom}>ｴﾘｱ</td>
                  <td className={classes.areaBottom}>
                    左<br />
                    ｱｰﾑ
                  </td>
                  <td className={classes.areaBottom}>
                    右<br />
                    ｱｰﾑ
                  </td>
                  <td className={classes.areaBottom}>
                    下降
                    <br />
                    限界
                  </td>
                  <td className={classes.areaBottom}>
                    押込
                    <br />力
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgYellow}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine4 ? areaConf?.lPower4 : '－'}</td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine4 ? areaConf?.rPower4 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine4 ? areaConf?.descentLimit4 : '－'}
                  </td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine4
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing4))
                      : '－'}
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgRed}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine5 ? areaConf?.lPower5 : '－'}</td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine5 ? areaConf?.rPower5 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine5 ? areaConf?.descentLimit5 : '－'}
                  </td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine5
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing5))
                      : '－'}
                  </td>
                </TableRow>
                <TableRow>
                  <td className={classes.areaBottomValue}>
                    <Box className={classes.bgBlue}>&nbsp;</Box>
                  </td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine6 ? areaConf?.lPower6 : '－'}</td>
                  <td className={classes.areaBottomValue}>{AreaSpecificSettingsLine6 ? areaConf?.rPower6 : '－'}</td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine6 ? areaConf?.descentLimit6 : '－'}
                  </td>
                  <td className={classes.areaBottomValue}>
                    {AreaSpecificSettingsLine6
                      ? getClassificationName(CLSFCN.AREA_CONFIG.PUSHING_CATEGORY_CD, String(areaConf?.pushing6))
                      : '－'}
                  </td>
                </TableRow>
              </Box>
            </Table>
          </Box>
        </Box>
      </Box>
    </NormalButton>
  );

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(AreaConfInfoButtonJack);
