import React, { useEffect, useState, useCallback } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import XyArea from 'components/machineConf/organisms/Area/XyArea';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AreaDetail from 'components/machineConf/organisms/Area/AreaDetail';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ReturnButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import PointerIcon from 'components/machineConf/atoms/icons/PointerIcon';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import PositionController from 'components/machineConf/organisms/Controllers/PositionController';
import { Vector2d } from 'konva/lib/types';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import { setXySpeedLeverConf } from 'redux/slices/machineConfSlice';
import CLSFCN from 'constants/classification';
import HttpConnection from 'utils/httpConnection';
import { XySpeedLeverConfType } from 'types/machineConf/machineConfType';
import { REST_API } from 'constants/apiUrls';
import BoundAreaDetail from 'components/machineConf/organisms/Area/BoundAreaDetail';
import { TenkeyTypeJack } from 'types/support/supportType';
import Tenkey from 'components/support/organisms/supports/TenkeyJack';
import MessageHelper from 'utils/message.helper';
import MESSAGES from 'constants/messages';
import { handleCallFrontError, handleClearApiError } from 'redux/slices/commonSlice';

const INITIAL_TEN_KEY: { tenkey: TenkeyTypeJack } = {
  tenkey: {
    anchorPosition: { top: 100, left: 0 },
    name: '',
    open: false,
    option: {
      mode: 1,
      integerSize: 3,
      scaleSize: 1,
      maxValueX: 100,
      minValueX: 0,
      maxValueY: 100,
      minValueY: 0,
    },
    value: '',
    onDecision: () => undefined,
  },
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  HOME_X: 'homeX',
  HOME_Y: 'homeY',
  END_X: 'endX',
  END_Y: 'endY',
  CUSTOM_X: 'customX',
  CUSTOM_Y: 'customY',
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
    position: 'relative',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  baseButton: {
    display: 'flex',
    height: '64px',
    width: '204px',
    fontSize: '20px',
    textAlign: 'left',
    borderRadius: '12px',
    boxShadow: '5px 5px 2px 0px rgba(0, 0, 0, 0.5)',
    padding: '0px 0px 0px 0px',
    '& p': {
      width: '124px',
      textAlign: 'center',
    },
    '& icon': {
      marginRight: '20px',
    },
    '&:hover': {
      boxShadow: '5px 5px 2px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  topArea: {
    display: 'flex',
    height: '70px',
    '& .returnButton': {},
    '& .description': {
      position: 'absolute',
      top: '4px',
      left: '216px',
      fontSize: '20px',
      lineHeight: '20px',
    },
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  mainArea: {
    position: 'relative',
    display: 'flex',
    height: '493px',
    '& .leverArea': {
      position: 'absolute',
      top: '50px',
      height: '372px',
      width: '244px',
      padding: '16px 0px 0px 20px',
      '& .description': {
        height: '178px',
        '& .detailDescription': {
          fontSize: '17px',
        },
      },
      '& .unselectedButton': {
        background: theme.base.gray,
        color: theme.palette.common.black,
      },
      '& .customButton': {
        marginTop: '20px',
      },
      '& .selectedCustom': {
        background: '#FFB54E',
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
      '& .defaultButton': {
        marginTop: '12px',
      },
      '& .selectedDefault': {
        background: '#FF3E3E',
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
    },
    '& .xyInputArea': {
      position: 'absolute',
      top: '-12px',
      left: '294px',
      height: '516px',
      width: '516px',
    },
    '& .doneArea': {
      position: 'absolute',
      top: '393px',
      left: '900px',
    },
  },
  customButtonImage: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFB54E',
    color: theme.palette.common.white,
    height: '44px',
    width: '128px',
    borderRadius: '12px',
    textAlign: 'left',
    padding: '0px 0px 0px 12px',
    margin: '4px 0px',
    fontSize: '16px',
    '& .icon': {
      marginLeft: '8px',
    },
  },
  leftXyAreaParent: {
    position: 'relative',
    height: '100%',
    width: '100%',
    fontSize: '20px',
    '& .xyArea': {
      position: 'absolute',
      top: '52px',
      left: '52px',
    },
    '& .areaDetail': {
      position: 'absolute',
      top: '16px',
      left: '16px',
    },
    '& .homeXController': {
      position: 'absolute',
      top: '464px',
      left: '96px',
    },
    '& .homeYController': {
      position: 'absolute',
      top: '264px',
      left: '0px',
    },
    '& .endXController': {
      position: 'absolute',
      top: '0px',
      left: '253px',
    },
    '& .endYController': {
      position: 'absolute',
      top: '98px',
      left: '464px',
    },
    '& .customHomeXController': {
      position: 'absolute',
      top: '464px',
      left: '264px',
    },
    '& .customHomeYController': {
      position: 'absolute',
      top: '96px',
      left: '0px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      top: '455px',
      left: '22px',
    },
    '& .maxPositionX': {
      position: 'absolute',
      top: '455px',
      left: '455px',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '39px',
      left: '22px',
    },
  },
  rightXyAreaParent: {
    position: 'relative',
    height: '100%',
    width: '100%',
    fontSize: '20px',
    '& .xyArea': {
      position: 'absolute',
      top: '52px',
      left: '52px',
    },
    '& .areaDetail': {
      position: 'absolute',
      top: '16px',
      left: '16px',
    },
    '& .homeXController': {
      position: 'absolute',
      top: '464px',
      left: '264px',
    },
    '& .homeYController': {
      position: 'absolute',
      top: '264px',
      left: '464px',
    },
    '& .endXController': {
      position: 'absolute',
      top: '0px',
      left: '98px',
    },
    '& .endYController': {
      position: 'absolute',
      top: '98px',
      left: '0px',
    },
    '& .customHomeXController': {
      position: 'absolute',
      top: '464px',
      left: '96px',
    },
    '& .customHomeYController': {
      position: 'absolute',
      top: '96px',
      left: '464px',
    },
    '& .minPositionXY': {
      position: 'absolute',
      top: '455px',
      left: '455px',
    },
    '& .maxPositionX': {
      position: 'absolute',
      top: '455px',
      left: '22px',
    },
    '& .maxPositionY': {
      position: 'absolute',
      top: '39px',
      left: '455px',
    },
  },
  leftStyle: {
    backgroundColor: theme.leftStation.contents,
    borderColor: theme.leftStation.itemBorder,
  },
  rightStyle: {
    backgroundColor: theme.rightStation.contents,
    borderColor: theme.rightStation.itemBorder,
  },
}));

const ScreenContextValue = {
  title: 'XY範囲設定',
  screenId: 'SCR323',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

const PRIZE_AREA_MAX = 100;

type axisXY = 'X' | 'Y';
interface TenKeyAxisXProps {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  axis: axisXY;
}

const useStylesActionTenKey = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    background: 'transparent',
    width: 52,
    height: 52,
    overflow: 'hidden',
    left: '50%',
    top: (props: { axis: axisXY }) => (props.axis === 'X' ? 0 : '50%'),
    transform: (props: { axis: axisXY }) => (props.axis === 'X' ? 'translateX(-26px)' : 'translate(-26px, -26px)'),
    opacity: 0,
    cursor: 'pointer',
  },
}));

const ActionTenKeyAxisXY: React.FC<TenKeyAxisXProps> = ({ onClick, axis }) => {
  const classes = useStylesActionTenKey({ axis });

  return (
    <Box onClick={onClick} className={classes.root}>
      action tenkey
    </Box>
  );
};

const XyRangeConf: React.FC = () => {
  /* ************ state/redux ************ */
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const pitConf = useAppSelector((state) => state.machineConf.machineConf.pitConf);
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);

  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;
  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  const minX = Number(nonAreaXMinSide);
  const maxX = PRIZE_AREA_MAX - Number(nonAreaXMaxSide);
  const minY = Number(nonAreaYMinSide);
  const maxY = PRIZE_AREA_MAX - Number(nonAreaYMaxSide);

  const [tempXySpeedLeverConf, setTempXySpeedLeverConf] = useState(xySpeedLeverConf);
  const [tenkey, setTenkey] = React.useState(INITIAL_TEN_KEY.tenkey);
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const MachineConfContextValue = {
    leftRight,
  };

  const areaStyle = `${classes.baseArea} ${
    leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle
  }`;

  const xyAreaParentStyle = `${
    leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftXyAreaParent : classes.rightXyAreaParent
  }`;

  const enableCustomHome = tempXySpeedLeverConf?.customConf === COMMON.FLAG.ON;

  useEffect(() => {
    const clearError = () => {
      dispatch(handleClearApiError());
    };

    return () => clearError();
  }, []);

  useEffect(() => {
    const customPos = {
      customX: tempXySpeedLeverConf?.customX || 0,
      customY: tempXySpeedLeverConf?.customY || 0,
    };

    if (tempXySpeedLeverConf) {
      // X軸計算
      // 左ステーションの計算
      if (customPos.customX < tempXySpeedLeverConf.homeX) {
        // カスタムがホームより左側
        customPos.customX = tempXySpeedLeverConf.homeX;
      }
      if (tempXySpeedLeverConf.endX < customPos.customX) {
        // カスタムがエンドより右側
        customPos.customX = tempXySpeedLeverConf.homeX;
      }
      // Y軸計算
      if (customPos.customY < tempXySpeedLeverConf.homeY) {
        // カスタムがホームより下
        customPos.customY = tempXySpeedLeverConf.homeY;
      }
      if (tempXySpeedLeverConf.endY < customPos.customY) {
        // カスタムがエンドより上
        customPos.customY = tempXySpeedLeverConf.homeY;
      }
    }

    setTempXySpeedLeverConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          ...customPos,
        };
      }
      return prevState;
    });
  }, [tempXySpeedLeverConf?.customConf]);

  /* ************ other component ************ */

  /* ************ Event ************ */
  // 戻るボタン
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_XY_PIT_AREA_TOP);
  };

  // カスタムボタン、デフォルトボタン
  const handleClickCustomConf = (customConf: string) => () => {
    if (tempXySpeedLeverConf)
      setTempXySpeedLeverConf({
        ...tempXySpeedLeverConf,
        customConf,
      });
  };

  // コントローラー(ホーム、カスタム、エンド)
  const handleClickPositionController = (value: string, name: string) => {
    if (tempXySpeedLeverConf) {
      const postionValue = Number(value);
      if (name === INPUT_NAMES.HOME_X) {
        // HOMEはCUSTOM,ENDより大きいとNG
        const movableRangeX =
          tempXySpeedLeverConf.customConf === COMMON.FLAG.ON ? tempXySpeedLeverConf.customX : tempXySpeedLeverConf.endX;
        if (tempXySpeedLeverConf?.homeX >= minX && tempXySpeedLeverConf?.homeX <= maxX) {
          if (movableRangeX < postionValue) return;
          // HOMEは移動不可範囲の外側ならNG
          if (postionValue < minX) return;
          if (postionValue > maxX) return;
        } else if (movableRangeX < postionValue) return; // 移動不可範囲にアイコンがある場合
      } else if (name === INPUT_NAMES.HOME_Y) {
        // HOMEはCUSTOM,ENDより大きいとNG
        const movableRangeY =
          tempXySpeedLeverConf.customConf === COMMON.FLAG.ON ? tempXySpeedLeverConf.customY : tempXySpeedLeverConf.endY;
        if (tempXySpeedLeverConf?.homeY >= minY && tempXySpeedLeverConf?.homeY <= maxY) {
          if (movableRangeY < postionValue) return;
          // HOMEは移動不可範囲の外側ならNG
          if (postionValue < minY) return;
          if (postionValue > maxY) return;
        } else if (movableRangeY < postionValue) return; // 移動不可範囲にアイコンがある場合
      } else if (name === INPUT_NAMES.CUSTOM_X) {
        // CUSTOMはHOMEより小さい,ENDより大きいとNG
        if (postionValue < tempXySpeedLeverConf.homeX || tempXySpeedLeverConf.endX < postionValue) return;
      } else if (name === INPUT_NAMES.CUSTOM_Y) {
        // CUSTOMはHOMEより小さい,ENDより大きいとNG
        if (postionValue < tempXySpeedLeverConf.homeY || tempXySpeedLeverConf.endY < postionValue) return;
      } else if (name === INPUT_NAMES.END_X) {
        // ENDはHOME,CUSTOMより小さいとNG
        const movableRangeX =
          tempXySpeedLeverConf.customConf === COMMON.FLAG.ON
            ? tempXySpeedLeverConf.customX
            : tempXySpeedLeverConf.homeX;
        if (tempXySpeedLeverConf?.endX >= minX && tempXySpeedLeverConf?.endX <= maxX) {
          if (postionValue < movableRangeX) return;
          // ENDは移動不可範囲の外側ならNG
          if (postionValue < minX) return;
          if (postionValue > maxX) return;
        } else if (postionValue < movableRangeX) return; // 移動不可範囲にアイコンがある場合
      } else if (name === INPUT_NAMES.END_Y) {
        // ENDはHOME,CUSTOMより小さいとNG
        const movableRangeY =
          tempXySpeedLeverConf.customConf === COMMON.FLAG.ON
            ? tempXySpeedLeverConf.customY
            : tempXySpeedLeverConf.homeY;
        if (tempXySpeedLeverConf?.endY >= minY && tempXySpeedLeverConf?.endY <= maxY) {
          if (postionValue < movableRangeY) return;
          // ENDはHOME,CUSTOMより小さいとNG
          if (postionValue < minY) return;
          if (postionValue > maxY) return;
        } else if (postionValue < movableRangeY) return; // 移動不可範囲にアイコンがある場合
      }
    }

    setTempXySpeedLeverConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [name]: value,
        };
      }
      return prevState;
    });
  };

  // 確定
  const handleClickDone = async () => {
    dispatch(setXySpeedLeverConf(tempXySpeedLeverConf));
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http.post<XySpeedLeverConfType>(REST_API.MACHINE_CONF.XY_CONF_VALIDATION, tempXySpeedLeverConf);
    history.push(redirectLinks.MCONF_STATION_CONF_XY_PIT_AREA_TOP);
  };

  // ポジションアイコン掴み動作
  const handleDrag = (pos: Vector2d, name: { x: string; y: string }) => {
    setTempXySpeedLeverConf((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [name.x]: pos.x,
          [name.y]: pos.y,
        };
      }
      return prevState;
    });
  };

  const showError = (from: string, to: string) => {
    const erros = {
      errors: {
        key: [MessageHelper.messageFormat(MESSAGES.INF108, String(from), String(to))],
      },
    };

    dispatch(handleCallFrontError(erros));
  };

  // Validate temp Xy speed lever conf
  const validateTempXY = (type: string, inputNumber: number): boolean => {
    let isError = false;
    if (!xySpeedLeverConf) return isError;

    const asixMinX = nonAreaXMinSide;
    const asixMaxX = 100 - nonAreaXMaxSide;
    const asixMinY = nonAreaYMinSide;
    const asixMaxY = 100 - nonAreaYMaxSide;

    switch (type) {
      case INPUT_NAMES.HOME_X:
      case INPUT_NAMES.END_X:
      case INPUT_NAMES.CUSTOM_X:
        if (inputNumber < asixMinX || inputNumber > asixMaxX) {
          showError(String(asixMinX), String(asixMaxX));
          isError = true;
        }
        break;

      case INPUT_NAMES.HOME_Y:
      case INPUT_NAMES.END_Y:
      case INPUT_NAMES.CUSTOM_Y:
        if (inputNumber < asixMinY || inputNumber > asixMaxY) {
          showError(String(asixMinY), String(asixMaxY));
          isError = true;
        }
        break;

      default:
        isError = false;
        break;
    }

    return isError;
  };

  // Tenkey - Close
  const handleCloseTenkey = useCallback(() => {
    setTenkey(INITIAL_TEN_KEY.tenkey);
  }, []);

  const handleDecisionTenkey = useCallback(
    (value: string, name: string) => {
      setTenkey(INITIAL_TEN_KEY.tenkey);
      if (value === undefined || !tempXySpeedLeverConf || !xySpeedLeverConf) return;

      const inputNumber = Number(value);

      // Check the input number is invalid
      // If invalid show message error
      const isInValid = validateTempXY(name, inputNumber);
      if (isInValid) return;
      const { homeX, homeY, endX, endY, customX, customY } = tempXySpeedLeverConf;

      // handle homeX by inputNumber
      if (name === INPUT_NAMES.HOME_X) {
        let valueHomeX = 0;
        if (!enableCustomHome) {
          if (inputNumber > endX) valueHomeX = endX;
          else valueHomeX = Number(value);
        }

        if (enableCustomHome) {
          if (inputNumber > customX) valueHomeX = customX;
          else valueHomeX = Number(value);
        }

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          homeX: valueHomeX,
        });
      }

      // handle homeY by inputNumber
      if (name === INPUT_NAMES.HOME_Y) {
        let valueHomeY = 0;
        if (!enableCustomHome) {
          if (inputNumber > endY) valueHomeY = endY;
          else valueHomeY = Number(value);
        }

        if (enableCustomHome) {
          if (inputNumber > customY) valueHomeY = customY;
          else valueHomeY = Number(value);
        }

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          homeY: valueHomeY,
        });
      }

      // handle endX by inputNumber
      if (name === INPUT_NAMES.END_X) {
        let valueEndX = 0;
        if (!enableCustomHome) {
          if (inputNumber < homeX) valueEndX = homeX;
          else valueEndX = Number(value);
        }

        if (enableCustomHome) {
          if (inputNumber < customX) valueEndX = customX;
          else valueEndX = Number(value);
        }

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          endX: valueEndX,
        });
      }

      // handle endY by inputNumber
      if (name === INPUT_NAMES.END_Y) {
        let valueEndY = 0;
        if (!enableCustomHome) {
          if (inputNumber < homeY) valueEndY = homeY;
          else valueEndY = Number(value);
        }

        if (enableCustomHome) {
          if (inputNumber < customY) valueEndY = customY;
          else valueEndY = Number(value);
        }

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          endY: valueEndY,
        });
      }

      // handle customX by inputNumber
      if (name === INPUT_NAMES.CUSTOM_X) {
        let valueCustomX = 0;

        if (inputNumber < homeX) valueCustomX = homeX;
        else if (inputNumber > endX) valueCustomX = endX;
        else valueCustomX = Number(value);

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          customX: valueCustomX,
        });
      }

      // handle customY by inputNumber
      if (name === INPUT_NAMES.CUSTOM_Y) {
        let valueCustomX = 0;

        if (inputNumber < homeY) valueCustomX = homeY;
        else if (inputNumber > endY) valueCustomX = endY;
        else valueCustomX = Number(value);

        setTempXySpeedLeverConf({
          ...tempXySpeedLeverConf,
          customY: valueCustomX,
        });
      }
    },
    [enableCustomHome, tempXySpeedLeverConf],
  );

  const initialTenkey: TenkeyTypeJack = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecisionTenkey,
    onClose: handleCloseTenkey,
  };

  // Tenkey - Open
  const handleOpenTenkey = (e: React.MouseEvent<HTMLElement, MouseEvent>, type: string, defaultValue?: string) => {
    if (defaultValue === undefined) return;
    const widthButtonCloseTenkey = 128;
    const clientXOfEndX = 458;
    const axisXEventClick = e.clientX > 900 ? clientXOfEndX : e.clientX;

    setTenkey({
      ...initialTenkey,
      anchorPosition: { top: 100, left: axisXEventClick - widthButtonCloseTenkey },
      open: true,
      name: type,
      value: defaultValue,
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContext.Provider value={MachineConfContextValue}>
          <MachineConfContent>
            <Box className={classes.content}>
              <Box className={classes.topArea}>
                <Box className="returnButton">
                  <ReturnButton onClick={handleClickReturn}>
                    移動設定
                    <br />
                    TOP
                  </ReturnButton>
                </Box>
                <Box className="description">
                  「ホームポジション」や「移動範囲」を設定できます <br />
                  タッチ または スライドして、位置決定し、
                  <Typography className={classes.decisionImage}>確 定</Typography>
                  してください
                </Box>
              </Box>
              <Box className={classes.mainArea}>
                {xySpeedLeverConf?.leverStatus === COMMON.FLAG.ON && (
                  <Box className={`${areaStyle} leverArea`}>
                    <Box className="description">
                      <p className="detailDescription">レバー運営時は、</p>
                      <Box className={classes.customButtonImage}>
                        <p>カスタム</p>
                        <PointerIcon className="icon" type="HP" size="S" />
                      </Box>
                      <p className="detailDescription">
                        に設定することで
                        <br />
                        ホームポジションを
                        <br />
                        任意で変更することが
                        <br />
                        できます
                      </p>
                    </Box>
                    <NormalButton
                      className={`${classes.baseButton} defaultButton ${
                        tempXySpeedLeverConf?.customConf === COMMON.FLAG.ON ? 'unselectedButton' : 'selectedDefault'
                      }`}
                      onClick={handleClickCustomConf(COMMON.FLAG.OFF)}>
                      <p>デフォルト</p>
                      <PointerIcon className="icon" type="H" size="L" />
                    </NormalButton>
                    <NormalButton
                      className={`${classes.baseButton} customButton ${
                        tempXySpeedLeverConf?.customConf === COMMON.FLAG.ON ? 'selectedCustom' : 'unselectedButton'
                      }`}
                      onClick={handleClickCustomConf(COMMON.FLAG.ON)}>
                      <p>カスタム</p>
                      <PointerIcon className="icon" type="HP" size="L" />
                    </NormalButton>
                  </Box>
                )}
                <Box className="xyInputArea">
                  <Box className={xyAreaParentStyle}>
                    <Box className="homeXController">
                      <ActionTenKeyAxisXY
                        onClick={(e) =>
                          handleOpenTenkey(e, INPUT_NAMES.HOME_X, String(tempXySpeedLeverConf?.homeX || '0'))
                        }
                        axis="X"
                      />

                      <PositionController
                        focusController={tenkey.name === INPUT_NAMES.HOME_X}
                        name={INPUT_NAMES.HOME_X}
                        type="H"
                        axis="X"
                        value={String(tempXySpeedLeverConf?.homeX || 0)}
                        onClickPositionController={handleClickPositionController}
                      />
                    </Box>
                    <Box className="homeYController">
                      <ActionTenKeyAxisXY
                        onClick={(e) =>
                          handleOpenTenkey(e, INPUT_NAMES.HOME_Y, String(tempXySpeedLeverConf?.homeY || 0))
                        }
                        axis="Y"
                      />

                      <PositionController
                        focusController={tenkey.name === INPUT_NAMES.HOME_Y}
                        name={INPUT_NAMES.HOME_Y}
                        type="H"
                        axis="Y"
                        value={String(tempXySpeedLeverConf?.homeY || 0)}
                        onClickPositionController={handleClickPositionController}
                      />
                    </Box>
                    <Box className="endXController">
                      <ActionTenKeyAxisXY
                        onClick={(e) => handleOpenTenkey(e, INPUT_NAMES.END_X, String(tempXySpeedLeverConf?.endX || 0))}
                        axis="X"
                      />

                      <PositionController
                        focusController={tenkey.name === INPUT_NAMES.END_X}
                        name={INPUT_NAMES.END_X}
                        type="E"
                        axis="X"
                        value={String(tempXySpeedLeverConf?.endX || 0)}
                        onClickPositionController={handleClickPositionController}
                      />
                    </Box>
                    <Box className="endYController">
                      <ActionTenKeyAxisXY
                        onClick={(e) => handleOpenTenkey(e, INPUT_NAMES.END_Y, String(tempXySpeedLeverConf?.endY || 0))}
                        axis="Y"
                      />

                      <PositionController
                        focusController={tenkey.name === INPUT_NAMES.END_Y}
                        name={INPUT_NAMES.END_Y}
                        type="E"
                        axis="Y"
                        value={String(tempXySpeedLeverConf?.endY || 0)}
                        onClickPositionController={handleClickPositionController}
                      />
                    </Box>
                    {enableCustomHome && (
                      <>
                        <Box className="customHomeXController">
                          <ActionTenKeyAxisXY
                            onClick={(e) =>
                              handleOpenTenkey(e, INPUT_NAMES.CUSTOM_X, String(tempXySpeedLeverConf?.customX || 0))
                            }
                            axis="X"
                          />

                          <PositionController
                            focusController={tenkey.name === INPUT_NAMES.CUSTOM_X}
                            name={INPUT_NAMES.CUSTOM_X}
                            type="HP"
                            axis="X"
                            value={String(tempXySpeedLeverConf?.customX || 0)}
                            onClickPositionController={handleClickPositionController}
                          />
                        </Box>
                        <Box className="customHomeYController">
                          <ActionTenKeyAxisXY
                            onClick={(e) =>
                              handleOpenTenkey(e, INPUT_NAMES.CUSTOM_Y, String(tempXySpeedLeverConf?.customY || 0))
                            }
                            axis="Y"
                          />
                          <PositionController
                            focusController={tenkey.name === INPUT_NAMES.CUSTOM_Y}
                            name={INPUT_NAMES.CUSTOM_Y}
                            type="HP"
                            axis="Y"
                            value={String(tempXySpeedLeverConf?.customY || 0)}
                            onClickPositionController={handleClickPositionController}
                          />
                        </Box>
                      </>
                    )}
                    <Box className="minPositionXY">0</Box>
                    <Box className="maxPositionX">100</Box>
                    <Box className="maxPositionY">100</Box>
                    <Box className="xyArea">
                      <AreaDetail
                        className="areaDetail"
                        displayCellSeparate={pitConf?.settingPattern === CLSFCN.PIT_PATTERN.DETAIL}
                        leftRight={leftRight}
                        size="M"
                        areaConf={areaConf}
                        isDefault={areaConf && areaConf.areaConf === COMMON.SWITCH.OFF}
                      />
                      {/* 移動不可範囲エリアの表示 */}
                      <BoundAreaDetail isLeft={isLeft} size="M" />
                      <XyArea
                        leftRight={leftRight}
                        enableCustomHome={enableCustomHome}
                        homePos={{ x: tempXySpeedLeverConf?.homeX || 0, y: tempXySpeedLeverConf?.homeY || 0 }}
                        customHomePos={{ x: tempXySpeedLeverConf?.customX || 0, y: tempXySpeedLeverConf?.customY || 0 }}
                        endPos={{ x: tempXySpeedLeverConf?.endX || 0, y: tempXySpeedLeverConf?.endY || 0 }}
                        onDrag={handleDrag}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="doneArea">
                  <DoneButton onClick={handleClickDone}>確 定</DoneButton>
                </Box>
              </Box>
            </Box>
            <Tenkey tenkey={tenkey} />
          </MachineConfContent>
        </MachineConfContext.Provider>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default XyRangeConf;
