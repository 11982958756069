import { useEffect, useState } from 'react';
import { CostRateDataType, CostRateDataViewType } from 'types/data/dataType';
import { addDays, getWeekInMonth, hadleDataByDate } from 'utils/date.helper';

interface UseCostRateInfoListProps {
  costRateInfoList: Array<CostRateDataType>;
  inputValues: CostRateDataViewType;
  dataViewUnit: string;
}

// Handle Show costRateInfoList when dataViewUnit is number one
const handleCostRateInfoList = (
  dataViewUnit: string,
  inputValues: CostRateDataViewType,
  costRateInfoList: Array<CostRateDataType>,
): Array<CostRateDataType> => {
  const result = costRateInfoList;
  const numberOfDays = 6;

  // Take a time in 24 hour.
  // Morning time is calculated from 1 o'clock to 12 o'clock, we have 11 hours
  const timeMorning = 11;

  // time form inputValues
  const start = new Date(inputValues.startDate);
  const startDate = start.getDate();
  const startMonth = start.getMonth() + 1;
  const startYear = start.getFullYear();
  const startTime = start.getTime();
  const endTime = addDays(inputValues.startDate, numberOfDays).getTime();

  // current date
  const date = new Date();
  const currentDate = date.getDate();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const CurrentHours = date.getHours();
  const CurrentDay = date.getDay() + 1;
  const currentTime = date.getTime();

  if (dataViewUnit === '1' && startDate === currentDate && startMonth === currentMonth && startYear === currentYear) {
    return costRateInfoList.map((val: CostRateDataType) => {
      // get value from the first position to the index position of costRateData
      // get time value every 1 hour
      const resultCostRateData = hadleDataByDate(val.costRateData, Number(CurrentHours) - timeMorning);

      // Take a time in 24 hour
      // If current hours less than or equal 12 hours. Then get costRateData with first value to display on the chart line
      if (CurrentHours <= 12) return { ...val, costRateData: [val.costRateData[0]] };

      return { ...val, costRateData: resultCostRateData };
    });
  }

  if (dataViewUnit === '2' && currentTime >= startTime && currentTime <= endTime) {
    return costRateInfoList.map((val: CostRateDataType) => {
      // get current day. Then get value hadleDataByDate function to display on the chart line
      const resultCostRateData = hadleDataByDate(val.costRateData, CurrentDay - 1);
      return {
        ...val,
        costRateData: resultCostRateData,
      };
    });
  }

  if (dataViewUnit === '3' && startMonth === currentMonth && startYear === currentYear) {
    return costRateInfoList.map((val: CostRateDataType) => {
      // get number wekk in month. Then get value hadleDataByDate function to display on the chart line
      const weekInMonth = getWeekInMonth(currentYear, currentMonth, currentDate);
      const resultCostRateData = hadleDataByDate(val.costRateData, weekInMonth);

      return {
        ...val,
        costRateData: resultCostRateData,
      };
    });
  }

  if (dataViewUnit === '4' && startYear === currentYear) {
    return costRateInfoList.map((val: CostRateDataType) => {
      // get  curent month. Then get value hadleDataByDate function to display on the chart line
      const resultCostRateData = hadleDataByDate(val.costRateData, currentMonth);

      return {
        ...val,
        costRateData: resultCostRateData,
      };
    });
  }

  return result;
};

function useCostRateInfoList({
  costRateInfoList,
  inputValues,
  dataViewUnit,
}: UseCostRateInfoListProps): CostRateDataType[] {
  const [dataCostRateInfoList, setDataCostRateInfoList] = useState<CostRateDataType[]>(costRateInfoList);

  // Time from inputValues for by to by table
  const startDate = new Date(inputValues.startDate);
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const startHours = startDate.getHours();

  useEffect(() => {
    // current time when just accept first time
    const currentTimeDate = new Date();
    let justVisitedPageHours = currentTimeDate.getHours();
    let justVisitedPageDate = currentTimeDate.getDate();
    let justVisitedPageMonth = currentTimeDate.getMonth();
    let justVisitedPageYear = currentTimeDate.getFullYear();

    const data = handleCostRateInfoList(dataViewUnit, inputValues, costRateInfoList);
    setDataCostRateInfoList(data);

    const dateNowinterval = setInterval(() => {
      // times change by second
      const now = new Date();
      const nowHours = now.getHours();
      const nowDate = now.getDate();
      const nowMonth = now.getMonth();
      const nowYear = now.getFullYear();

      // when time now date difference time currentTimeDate. then call back handleCostRateInfoList function to change data chart line
      if (
        justVisitedPageHours !== nowHours ||
        justVisitedPageDate !== nowDate ||
        justVisitedPageMonth !== nowMonth ||
        justVisitedPageYear !== nowYear
      ) {
        const getDatas = handleCostRateInfoList(dataViewUnit, inputValues, costRateInfoList);
        setDataCostRateInfoList(getDatas);
        justVisitedPageHours = nowHours;
        justVisitedPageDate = nowDate;
        justVisitedPageMonth = nowMonth;
        justVisitedPageYear = nowYear;
      }
    }, 1000);

    return () => {
      clearInterval(dateNowinterval);
    };
  }, [startHours, startDay, startYear, startMonth, dataViewUnit, inputValues.sort]);
  return dataCostRateInfoList;
}

export default useCostRateInfoList;
