/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse, AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { showLoading, hideLoading, handleCallApiError, handleCallApiSuccess } from 'redux/slices/commonSlice';
import store, { AppDispatch } from 'redux/store';
import COMMON from 'constants/common';
import MESSAGES from 'constants/messages';

type SuccessResponse<T> = {
  success: boolean;
  message: string;
  data: T;
};

type ErrorResponse = {
  success: boolean;
  logId: string;
  errorCode: string;
  message: string;
  errors: { [key: string]: string };
};

const addCheckAuth = (params = {}) => {
  const { userInfo } = store.getState().common;
  const newParams = {
    ...params,
    chkAuthId: userInfo?.userId,
    chkAuthHeadQId: userInfo?.currentHeadQId,
    chkAuthStoreId: userInfo?.currentStoreId,
  };
  return newParams;
};

export default class HttpConnection {
  dispatch: AppDispatch;

  client: AxiosInstance;

  constructor(thunkAPI: { dispatch: AppDispatch }, isShowLoadingScreen = true) {
    this.dispatch = thunkAPI.dispatch;
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: COMMON.BASE_URL,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }),
    );

    if (isShowLoadingScreen) {
      this.dispatch(showLoading());
    }
  }

  async get<T>(url: string, params = {}): Promise<T> {
    try {
      const newParams = addCheckAuth(params);
      const response = await this.client.get<SuccessResponse<T>>(url, { params: newParams });

      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  async getFile(url: string, params = {}): Promise<any> {
    try {
      const newParams = addCheckAuth(params);
      const config: AxiosRequestConfig = {
        params: newParams,
        responseType: 'blob',
      };
      const response = await this.client.get<SuccessResponse<any>>(url, config);
      return this.handleDownloadFileResponse(response);
    } catch (error) {
      return this.handleDownloadFileErrorResponse(error);
    }
  }

  async getWithoutHandle<T>(url: string, params = {}): Promise<T> {
    try {
      const response = await this.client.get<SuccessResponse<T>>(url, { params });
      return response.data.data;
    } finally {
      this.dispatch(hideLoading());
    }
  }

  async post<T>(url: string, data: any = {}): Promise<T> {
    try {
      const newData = addCheckAuth(data);
      const response = await this.client.post<SuccessResponse<T>>(url, newData);

      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  // only apply for api have status code 202
  async postSecond(url: string, data: any = {}): Promise<any> {
    try {
      const newData = addCheckAuth(data);
      const response = await this.client.post(url, newData);

      if (response.status === 202) {
        return this.handleMesagesErrorResponse(response);
      }

      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  async put<T>(url: string, params = {}, data: any = {}): Promise<T> {
    try {
      const newData = addCheckAuth(data);
      const response = await this.client.put<SuccessResponse<T>>(url, newData, { params });

      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  async delete<T>(url: string, params = {}): Promise<T> {
    try {
      const newParams = addCheckAuth(params);
      const response = await this.client.delete<SuccessResponse<T>>(url, { params: newParams });

      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }

  handleResponse<T>(response: AxiosResponse<SuccessResponse<T>>): T {
    this.dispatch(hideLoading());
    this.dispatch(handleCallApiSuccess());

    return response.data.data;
  }

  handleDownloadFileResponse<T>(response: AxiosResponse<SuccessResponse<T>>): any {
    this.dispatch(hideLoading());
    this.dispatch(handleCallApiSuccess());

    return response.data;
  }

  handleErrorResponse(error: AxiosError<ErrorResponse>): never {
    this.dispatch(hideLoading());
    this.dispatch(
      handleCallApiError({ message: error.message, status: error.response?.status, data: error.response?.data }),
    );
    throw error;
  }

  handleMesagesErrorResponse(response: AxiosResponse<ErrorResponse>): any {
    this.dispatch(hideLoading());
    return response.data;
  }

  handleDownloadFileErrorResponse(error: AxiosError<ErrorResponse>): never {
    this.dispatch(hideLoading());
    this.dispatch(
      handleCallApiError({
        message: error.message,
        status: error.response?.status,
        data: { errors: { 0: [MESSAGES.ERR602] } },
      }),
    );
    throw error;
  }

  async postFormData<T>(url: string, data: any = {}): Promise<T> {
    try {
      const newData = addCheckAuth(data);
      this.client = applyCaseMiddleware(
        axios.create({
          baseURL: COMMON.BASE_URL,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }),
      );
      const formData = new FormData();
      const objectEntries = Object.entries(newData);
      objectEntries.forEach(([key, value]) => {
        const newValue: any = value;
        formData.append(key, newValue);
      });
      const response = await this.client.post<SuccessResponse<T>>(url, formData);
      return this.handleResponse(response);
    } catch (error) {
      return this.handleErrorResponse(error);
    }
  }
}
