import React, { memo } from 'react';
import { makeStyles, Box, InputLabel } from '@material-ui/core';
import { TextBox } from '..';

/**
 * Outline Text Box Component
 * 外枠付きテキストボックス
 * @author akinori.akamine
 *
 * @param props
 * @returns {*}
 * @constructor
 */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  root: {
    '& .outlinedLabel': {
      color: '#0D0D0D',
      margin: '0 8px 0 0',
      fontSize: '23px',
    },
    '& .outlinedInput': {
      background: '#FFFFFF',
      height: '48px',
      margin: '4px 0 0 0',
      '& input': {
        padding: '0 8px',
        fontSize: '23px',
      },
    },
    '& .Mui-disabled': {
      background: '#EBEBEB',
      color: '#0D0D0D',
    },
  },
}));

/* ************ Type ************ */
type Props = {
  id: string;
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  type?: 'text' | 'password'; // number date
  disabled?: boolean;
  fullWidth?: boolean;
  labelPlacement?: 'start' | 'top';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

/* ************ Function ************ */
const setLabelPlacement = (labelPlacement: string): string => {
  if (labelPlacement === 'start') {
    return 'row';
  }
  return 'column';
};

/* ************ Component ************ */
const OutlineTextBox: React.FC<Props> = memo((props) => {
  const classes = useStyles();
  const {
    id,
    name,
    value,
    placeholder,
    label,
    onChange,
    disabled = false,
    fullWidth = false,
    labelPlacement = 'start',
    type = 'text',
    className = '',
  } = props;

  return (
    <Box
      display="flex"
      flexDirection={setLabelPlacement(labelPlacement)}
      alignItems="baseline"
      className={`${classes.root} ${className}`}>
      {label && (
        <InputLabel htmlFor={id} className="outlinedLabel">
          {label}
        </InputLabel>
      )}
      <TextBox
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        onChange={onChange}
        className="outlinedInput"
        fullWidth={fullWidth}
      />
    </Box>
  );
});

export default OutlineTextBox;
