import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import UserTableRow from 'components/maintenance/atoms/table/UserTableRow';
import { UserInfoType } from '../../../../types/maintenance/UserInfoType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    height: '480px',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '23px',
      lineHeight: '23px',
    },
    '& th': {
      textAlign: 'center',
      height: '60px',
      padding: 0,
    },
    '& tbody tr': {
      height: '60px',
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFDBDB',
      },
    },
    '& td': {
      padding: '0 8px',
      fontSize: '22px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<UserInfoType>;
  filter: string;
};

/* ************ Component ************ */
const UserListTable: React.FC<Props> = ({ rows }) => {
  const classes = useStyles();

  const headerColumns = [
    { id: 'editBtn', label: '編集', width: 152 },
    { id: 'accountId', label: 'ID', width: 184 },
    { id: 'role', label: '権限', width: 208 },
    { id: 'validDiv', label: '利用', width: 160 },
    { id: 'rock', label: 'ロック', width: 160 },
    { id: 'lastLoginTime', label: '最終ログイン日時', width: 248 },
  ];

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerColumns.map((column) => (
              <TableCell key={column.id} style={{ width: column.width }} className={classes.head}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <UserTableRow row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserListTable;
