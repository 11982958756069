import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { ReturnButton } from 'components/system/atoms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import OnOFFButtonToggle from 'components/system/atoms/toggles/OnOffButtonToggle';
import DeleteButton from 'components/system/atoms/buttons/DeleteButton';
import { initializeGroupSlice, setCreateGroup, setGetGiftGroup } from 'redux/slices/groupSlice';
import { GetGiftGroupType, GroupLocationType } from 'types/group/GroupType';
import { convertToBool4Flg, convertToString4Flg } from 'utils/common.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '景品獲得演出設定グループ作成',
  screenId: COMMON.SCREEN_ID.GET_GIFT_GROUP_CREATE,
  currentMenu: COMMON.MENU.GROUP,
  initializeState: true,
};

/* ********** Constants ********** */

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contens: {
    padding: '10px 16px',
  },
  topArea: {},
  textBox: {
    marginTop: '24px',
    padding: '0 20px',
    width: '1112px',
    height: '112px',
    backgroundColor: '#F6F6F6',
    border: '1px solid #C8C8C8',
    borderRadius: '4px',
    '& .outlinedLabel': {
      width: '120px',
      margin: 'auto 72px auto 0',
      whiteSpace: 'nowrap',
    },
    '& .outlinedInput': {
      margin: 'auto 0',
      height: '60px',
    },
  },
  toggle: {
    marginTop: '24px',
    padding: '16px 24px 12px 20px',
    width: '344px',
    height: '132px',
    backgroundColor: '#F6F6F6',
    border: '1px solid #C8C8C8',
    borderRadius: '4px',
    '& .toggleLabel': {},
    '& .toggleInputGroup': {
      margin: '8px 8px 0 24px',
    },
  },
  setting: {
    display: 'flex',
    gap: '16px 40px',
    height: '280px',
  },

  bottomArea: {
    display: 'flex',
    margin: '28px 0 0 0',
  },
  addUserBtn: {
    width: '200px',
    height: '70px',
    background: '#EBEBEB',
    margin: '0 0 0 auto',
    fontSize: '21px',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.2)',
  },
}));

/* ******** Components ******** */
const GetGiftGroupCreate: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation<GroupLocationType>();
  const fromScrId: string = location.state?.fromScrId ?? '';
  const groupId: number = location.state?.groupId ?? COMMON.DUMMY_GROUP_ID;

  /* ************ state/redux ************ */
  const { getGiftGroup } = useAppSelector((state) => state.group);
  const [inputValue, setInputValue] = useState<{
    groupName: string;
  }>({
    groupName: getGiftGroup.groupName,
  });
  const [se, setSe] = useState<boolean>(convertToBool4Flg(getGiftGroup.se));
  const [voice, setVoice] = useState<boolean>(convertToBool4Flg(getGiftGroup.voice));

  // 戻るボタンの情報取得(URL、ラベル)
  const prevScrInfo = ((): { name: string; url: string } => {
    if (getGiftGroup.returnFromCreateScr === COMMON.SCREEN_ID.GROUP_LIST) {
      return {
        name: '登録グループ一覧',
        url: redirectLinks.GROUP_LIST_DATA,
      };
    }
    if (getGiftGroup.returnFromCreateScr === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      return {
        name: '機器一覧',
        url: redirectLinks.MAINT_MACHINE_LIST,
      };
    }
    return {
      name: 'ｸﾞﾙｰﾌﾟｶﾃｺﾞﾘ\n選択',
      url: redirectLinks.GROUP_SELECT_CONF,
    };
  })();

  /* ************ UseEffect ************ */
  useEffect(() => {
    // 遷移元画面によって分岐
    if (fromScrId === COMMON.SCREEN_ID.GROUP_SELECT_CATEGORY) {
      dispatch(initializeGroupSlice());
    } else if (fromScrId === COMMON.SCREEN_ID.GROUP_LIST) {
      getGiftGroupApi();
    } else if (fromScrId === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      getGiftGroupApi();
    }
  }, []);

  /* ************ APIs ************ */
  const getGiftGroupApi = async () => {
    // 景品獲得演出設定グループ情報取得
    const http = new HttpConnection({ dispatch });
    await http
      .get<GetGiftGroupType>(
        UrlHelper.convertGetUrl(REST_API.GROUP.GET_GIFT_GROUP, {
          group_id: groupId.toString(),
        }),
      )
      .then((d: GetGiftGroupType) => {
        dispatch(setGetGiftGroup(d));
        setInputValue({
          ...inputValue,
          groupName: d.groupName,
        });
        setSe(convertToBool4Flg(d.se));
        setVoice(convertToBool4Flg(d.voice));
      });
  };

  const giftGroupValidateApi = async () => {
    // バリデーション
    const http = new HttpConnection({ dispatch });
    return http.post(REST_API.GROUP.GET_GIFT_GROUP_SETTING, {
      groupName: inputValue.groupName,
      se: convertToString4Flg(se),
      voice: convertToString4Flg(voice),
    });
  };
  /* ************ Event ************ */
  // return button
  const handleClickReturn = () => {
    dispatch(initializeGroupSlice());
    history.push(prevScrInfo.url);
  };

  // input items
  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setInputValue({
      ...inputValue,
      [ev.target.name]: ev.target.value,
    });
  };

  // SE
  const handleChangeSe = (ev: React.MouseEvent<HTMLElement>, newValue: boolean | null) => {
    if (newValue !== null) {
      setSe(newValue);
    }
  };

  // voice
  const handleChangeVoice = (ev: React.MouseEvent<HTMLElement>, newValue: boolean | null) => {
    if (newValue !== null) {
      setVoice(newValue);
    }
  };

  // reset setting button
  const handleClickReset = () => {
    // 初期化
    setSe(true);
    setVoice(true);
  };

  // next button
  const handleClicknext = () => {
    giftGroupValidateApi().then(() => {
      dispatch(
        setCreateGroup({
          groupName: inputValue.groupName,
          se: convertToString4Flg(se),
          voice: convertToString4Flg(voice),
        }),
      );
      // 登録機器選択画面へ遷移
      history.push(redirectLinks.GROUP_GET_GIFT_SELECT_REGISTER_MACHINE);
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contens}>
          <Box className={classes.topArea}>
            <ReturnButton onClick={handleClickReturn}>{prevScrInfo.name}</ReturnButton>
          </Box>
          <OutlineTextBox
            id="groupName"
            name="groupName"
            label="グループ名"
            value={inputValue.groupName}
            onChange={handleChangeInput}
            className={classes.textBox}
            fullWidth
          />
          <Box className={classes.setting}>
            <OnOFFButtonToggle
              id="se"
              label="SE"
              value={se}
              labelPlacement="top"
              onChange={handleChangeSe}
              className={classes.toggle}
            />
            <OnOFFButtonToggle
              id="voice"
              label="ボイス"
              value={voice}
              labelPlacement="top"
              onChange={handleChangeVoice}
              className={classes.toggle}
            />
          </Box>
          <Box className={classes.bottomArea}>
            <DeleteButton onClick={handleClickReset}>設定初期化</DeleteButton>
            <NormalButton className={classes.addUserBtn} onClick={handleClicknext}>
              登録機器選択へ
            </NormalButton>
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GetGiftGroupCreate;
