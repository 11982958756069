import React, { useEffect, useState } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleClearSystemError } from 'redux/slices/commonSlice';
import { makeStyles, Box, TableBody, TableRow, TableCell, Table, Typography, Grid, Button } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import { formatDatetime } from 'utils/datetime.helper';
import { formatString } from 'utils/string.helper';
import ERROR_CODE from 'constants/errorCode';
import ErrorLayout from 'components/system/layouts/ErrorLayout';
import COMMON from 'constants/common';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import ScreenContext from 'Contexts/Common/ScreenContext';
import CLSFCN from 'constants/classification';
import UrlHelper from 'utils/url.helper';
import { IS_FILTERED } from 'pages/maintenance/UserList';
import { checkScreenAuth } from 'utils/common.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'システムエラー',
  screenId: 'SCR721',
  currentMenu: COMMON.MENU.ERROR,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  errorContainer: {
    width: '960px',
    '& .header': {
      backgroundColor: '#E50000',
      fontSize: '40px',
      color: theme.palette.common.white,
      textAlign: 'center',
      borderRadius: '8px 8px 0 0',
    },
    '& .content': {
      backgroundColor: theme.palette.common.white,
      fontSize: '24px',
      padding: '20px',
      borderRadius: '0 0 8px 8px',
      border: `3px solid #E50000`,
    },
  },
  systemErrorInfoTable: {
    boxSizing: 'border-box',
    whiteSpace: 'pre-wrap',
    borderCollapse: 'separate',
    border: '1px solid #000000',
    borderRadius: '4px',
    width: 450,
    margin: '0 auto',
    '& tbody': {
      '& tr:last-child': {
        '& td': {
          borderTop: '1px solid #000000',
        },
      },
      '& tr': {
        '& td': {
          fontSize: '21px',
          padding: '4px 0 4px 16px',
          borderBottom: 'none',
        },
        '& td:not(:last-child)': {
          borderRight: '1px solid #000000',
        },
        '& .thSupportCenter': {
          width: '116px',
          height: '80px',
          borderRadius: '4px 0 0 4px',
        },
        '& .tdSupportCenter': {
          width: '236px',
        },
        '& .thErrorInfo': {
          height: '38px',
          width: '210px',
        },
        '& .tdErrorInfo': {
          width: '264px',
        },
      },
    },
  },
  contentDetail: {
    '& .highlightInfo': {
      color: '#FF0000',
      whiteSpace: 'pre-wrap',
    },
    '& p': {
      margin: '0 0 16px 0',
    },
  },
  returnButton: {
    textAlign: 'center',
    margin: '10px 0 0',
    '& button': {
      fontSize: '21px',
    },
  },
  infoContactGroup: {
    marginTop: 10,
  },
  titleContact: {
    color: '#3f51b5',
    fontSize: '24px',
    margin: '0 20px',
  },
  linkSupport: {
    fontSize: '24px',
    textTransform: 'none',
    margin: '-38px 20px 0',
  },
  numberPhone: {
    width: 626,
    marginTop: 10,
  },
  textTel: {
    fontSize: '16px',
    marginBottom: 10,
    marginLeft: -34,
  },
  listContact: {
    fontSize: '16px',
    margin: 0,
    listStyle: 'inherit !important',
  },
}));

const SystemError: React.FC = () => {
  /* ************ state/redux ************ */
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { userInfo, errorInfo } = useAppSelector((state) => state.common);
  const { errorDate: prmErrorDate, errorNo: prmErrorNo } = errorInfo;
  const [errorDate, setErrorDate] = useState<Date>(new Date());
  const [showErrorNo] = useState(prmErrorNo);

  // logIdが取得できない場合、システム未制御のエラーとして表示
  const errorNo = formatString(showErrorNo || ERROR_CODE.UNKWON_ERROR);
  const tel = '株式会社バンダイナムコテクニカ　0120-03-8765';

  useEffect(() => {
    // エラー発生日時を取得できない場合、クライアントの日時を表示
    if (prmErrorDate) {
      setErrorDate(new Date(prmErrorDate));
      return;
    }
    getErrorDate();
  }, [prmErrorDate]);

  /* ************ function ************ */
  const getErrorDate = async () => {
    const http = new HttpConnection({ dispatch });
    const date = await http.getWithoutHandle<{
      errorDate: string;
    }>(REST_API.COMMON.GET_SYSTEM_ERROR_INFO);
    if (date.errorDate) {
      setErrorDate(new Date(date.errorDate));
    }
  };

  // ホーム画面へボタン クリック
  const handleClickHomeTopBtn = () => {
    dispatch(handleClearSystemError());
    // TOP画面の利用権限がある場合はTOP、ない場合はログインに遷移
    if (checkScreenAuth('SCR111')) {
      window.location.href = redirectLinks.HOME_TOP;
    } else {
      window.location.href = redirectLinks.LOGIN;
    }
  };

  // ユーザ一覧画面へボタン クリック
  const handleClickUserList = () => {
    dispatch(handleClearSystemError());
    window.location.href = UrlHelper.convertQueryUrlFrontEnd(redirectLinks.MAINT_USER_LIST, {
      isFiltered: IS_FILTERED.FALSE,
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <ErrorLayout>
        <Box className={classes.content}>
          <Box className={classes.errorContainer}>
            <Box className="header">システムエラー</Box>
            <Box className="content">
              <Box className={classes.contentDetail}>
                <p>申し訳ございません。システムエラーが発生しました。</p>
                <p className="highlightInfo">
                  {`お手数ですが、以下のいずれかをお試しください。\n・HOME画面から操作をやり直してください。\n・時間をおいてから、再度ご利用ください。`}
                </p>
                <p>
                  操作をやり直しても同じエラーが発生する場合は、お手数ですがエラーの操作と以下のエラー発生日時、番号を弊社サポートまでご連絡ください。
                </p>
              </Box>
              <Table className={classes.systemErrorInfoTable}>
                <TableBody>
                  <TableRow>
                    <TableCell className="thErrorInfo">エラー発生日時</TableCell>
                    <TableCell className="tdErrorInfo">{formatDatetime(errorDate, '/', true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="thErrorInfo">エラー番号</TableCell>
                    <TableCell className="tdErrorInfo">{errorNo}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Grid container justify="space-around" className={classes.infoContactGroup}>
                <Grid container alignItems="center">
                  <Grid item alignItems="flex-start" justify="flex-start" direction="column">
                    <Typography className={classes.titleContact}>バンダイナムコテクニカサポート</Typography>
                    <Typography className={classes.titleContact}>お電話によるお問い合わせ</Typography>
                  </Grid>

                  <Grid item alignItems="flex-start" justify="flex-start" direction="column">
                    <Button
                      href="https://www.banasupport.net/"
                      color="primary"
                      target="_blank"
                      className={classes.linkSupport}>
                      https://www.banasupport.net/
                    </Button>
                  </Grid>
                </Grid>

                <Grid className={classes.numberPhone} item container justify="flex-start">
                  <Typography className={classes.textTel}>{tel}</Typography>

                  <ul className={classes.listContact}>
                    <li>受付時間9:30～12:00、13:00～18:00（土曜・日曜・祝日を除く）</li>
                    <li>ゲーム内容、運営方法、各種イベントに関するお問い合わせは受け付けておりません。</li>
                    <li>一般のお客様からのお問い合わせは受け付けておりません。</li>
                  </ul>
                </Grid>
              </Grid>

              <Box className={classes.returnButton}>
                {/** システム管理者の場合はユーザ一覧画面へ遷移 */}
                {userInfo?.role === CLSFCN.ROLE.SYSTEM_ADMIN ? (
                  <NormalButton onClick={handleClickUserList}>ユーザー一覧画面へ</NormalButton>
                ) : (
                  <NormalButton onClick={handleClickHomeTopBtn}>HOME画面へ</NormalButton>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </ErrorLayout>
    </ScreenContext.Provider>
  );
};

export default SystemError;
