import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import arrowUpIcon from 'assets/images/arrowUpIcon.svg';
import arrowRigthIcon from 'assets/images/arrowRigthIcon.svg';
import newServiceGraphImg from 'assets/images/newServiceGraphImg.svg';
import COMMON from 'constants/common';
import { getClassificationName } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import ShiftPanelReviewJack from './ShiftPanelReviewJack';

type StyleProps = {
  isLeft: boolean;
  powerIconLeft?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    height: '251px',
    padding: '3px 10px',
  },
  title: {
    position: 'relative',
    fontSize: '24px',
    textAlign: 'left',
    fontWeight: 'bold',
    '& span': {
      display: 'inline-block',
      margin: '0 0 0 15px',
      fontSize: '17px',
    },
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
    '& .inner': {
      display: 'flex',
    },
  },
  powerIcon: {
    position: 'relative',
    top: '-12px',
    width: '150px',
    '& .icon': {
      margin: '0 auto',
    },
    '& .square': {
      width: '28px',
      height: '28px',
      background: '#CCCCCC',
      display: 'inline-block',
      position: 'absolute',
      top: '70px',
      left: '61px',
      border: `1px solid ${theme.palette.common.black}`,
    },
    '& .value': {
      width: '66px',
      height: '46px',
      lineHeight: '44px',
      fontSize: '24px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      top: '124px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
    },
    '& .pos-right': {
      right: '10px',
    },
    '& .pos-left-r': {
      left: '10px',
    },
    '& .pos-right-r': {
      right: '0px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '47px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
    },
  },
  powerIconService: {
    position: 'relative',
    width: '150px',
    '& .icon': {
      margin: '0 auto',
    },
    '& .valueTop': {
      width: '66px',
      height: '46px',
      lineHeight: '44px',
      fontSize: '24px',
      background: '#FFF',
      borderRadius: '2px',
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .pos-left': {
      left: '0px',
    },
    '& .pos-right': {
      right: '10px',
    },
    '& .pos-left-r': {
      left: '10px',
    },
    '& .pos-right-r': {
      right: '0px',
    },
    '& .bottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '60px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .serviceBottom': {
      borderTop: `2px solid ${theme.palette.common.black}`,
      padding: '4px 0 0',
      margin: '50px 0 0',
      fontSize: 24,
      textAlign: 'center',
    },
    '& .top': {
      fontSize: 19,
      textAlign: 'center',
    },
  },
  border: {
    border: ({ isLeft }) =>
      `2px solid ${isLeft ? theme.leftStation.contentsBorder : theme.rightStation.contentsBorder}`,
  },
  borderRadius: {
    borderRadius: 4,
  },
  newServiceImgContent: {
    marginTop: '12px',
    '& .icon': {
      margin: '0 auto',
    },
  },
  resetTiming: {
    textAlign: 'center',
    fontSize: 24,
  },
  shiftContainer: {
    height: '180px',
    width: '180px',
  },
  shiftContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
  },
  shiftOperatorLeftContent: {
    width: '100px',
    alignSelf: 'center',
    '& .contentBox': {
      background: '#ffffff',
      width: '100px',
      height: '36px',
      fontSize: 24,
      border: '2px solid #000000',
      borderRadius: 2,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '& .mb-30': {
      marginBottom: '20px',
    },
  },
  shiftOperatorRightContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px',
  },
  arrowIcon: {
    position: 'absolute',
    top: 10,
    right: ({ powerIconLeft }) => (powerIconLeft ? 20 : 10),
  },
}));

// Define type Props
type PowerIconProps = {
  textTop: string;
  valueLeft: string;
  valueRight: string;
  textBottom: string;
  isLeft: boolean;
  powerIconLeft: boolean;
};

type PowerIconServiceGraphProps = {
  valueLeft: string;
  valueRight: string;
  textTop: string;
  isLeft: boolean;
  powerIconLeft: boolean;
};

export type NewPayoutInfoSupportType = {
  grabLPower: number;
  grabRPower: number;
  lPower1: number;
  rPower1: number;
  backTiming: string;
  getSupport: string;
  movingLPower: number;
  movingRPower: number;
};

export type NewServiceGraphType = {
  lPower1: number;
  rPower1: number;
  limitLPower: number;
  limitRPower: number;
  resetTiming: string;
};

export type ShiftOperationType = {
  line1: string;
  line2: string;
  line3: string;
  shiftConf: string;
  shiftCm: string;
};

type NewPayoutInfoSupportProps = {
  title: string;
  isLeft: boolean;
  operationConf: string;
  payoutInfoSupport: NewPayoutInfoSupportType;
  newServiceGraph: NewServiceGraphType;
  shiftOperation: ShiftOperationType;
};

const PowerIcon: React.FC<PowerIconProps> = (props) => {
  const { valueLeft, valueRight, textBottom, textTop, isLeft, powerIconLeft } = props;
  const classes = useStyles({ isLeft, powerIconLeft });
  const classPosLeft = powerIconLeft ? 'pos-left' : 'pos-left-r';
  const classPosRight = powerIconLeft ? 'pos-right' : 'pos-right-r';
  const arrowIcon = powerIconLeft ? arrowUpIcon : arrowRigthIcon;

  return (
    <Box className={classes.powerIcon}>
      <Box className={classes.arrowIcon}>
        <img src={arrowIcon} alt="arrowUpIcon" className="icon" />
      </Box>
      <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
      <span className="square">&nbsp;</span>
      <Box className="top">{textTop}</Box>
      <Box>
        <span className={`value ${classes.border} ${classPosLeft}`}>{valueLeft}</span>
        <span className={`value ${classes.border} ${classPosRight}`}>{valueRight}</span>
      </Box>
      <Box className="bottom">{textBottom}</Box>
    </Box>
  );
};

const PowerIconServiceGraph: React.FC<PowerIconServiceGraphProps> = (props) => {
  const { valueLeft, valueRight, textTop, isLeft, powerIconLeft } = props;
  const classes = useStyles({ isLeft });
  const classPosLeft = powerIconLeft ? 'pos-left' : 'pos-left-r';
  const classPosRight = powerIconLeft ? 'pos-right' : 'pos-right-r';
  return (
    <Box className={classes.powerIconService}>
      <Box className="top">{textTop}</Box>
      <span className={`valueTop ${classes.border} ${classPosLeft}`}>{valueLeft}</span>
      <span className={`valueTop ${classes.border} ${classPosRight}`}>{valueRight}</span>
      <Box className="serviceBottom" />
    </Box>
  );
};

const newPaymentInfoSupportContent = (
  isLeft: boolean,
  operationConf: string,
  payoutInfoSupport: NewPayoutInfoSupportType,
  newServiceGraph: NewServiceGraphType,
  shiftOperation: ShiftOperationType,
) => {
  const classes = useStyles({ isLeft });
  let contentPaymentInfo: JSX.Element;
  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentPaymentInfo = <></>;
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentPaymentInfo = (
        <Box>
          <Box>
            <Box className="inner">
              <PowerIcon
                isLeft={isLeft}
                textTop="つかみ時"
                valueLeft={payoutInfoSupport.grabLPower.toString()}
                valueRight={payoutInfoSupport.grabRPower.toString()}
                powerIconLeft
                textBottom={getClassificationName(CLSFCN.TIMING.CATEGORY_CD, payoutInfoSupport.backTiming)}
              />
              <PowerIcon
                isLeft={isLeft}
                textTop="移動時"
                valueLeft={payoutInfoSupport.movingLPower.toString()}
                valueRight={payoutInfoSupport.movingRPower.toString()}
                powerIconLeft={false}
                textBottom={getClassificationName(CLSFCN.ON_OFF.CATEGORY_CD, payoutInfoSupport.getSupport)}
              />
            </Box>
          </Box>
        </Box>
      );
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentPaymentInfo = (
        <Box>
          <Box className={classes.newServiceImgContent}>
            <img src={newServiceGraphImg} alt="newServiceGraph" className="icon" />
          </Box>
          <Box className="inner">
            <PowerIconServiceGraph
              valueLeft={newServiceGraph.lPower1.toString()}
              valueRight={newServiceGraph.rPower1.toString()}
              textTop="基本"
              powerIconLeft
              isLeft={isLeft}
            />
            <PowerIconServiceGraph
              valueLeft={newServiceGraph.limitLPower.toString()}
              valueRight={newServiceGraph.limitRPower.toString()}
              textTop="上限"
              powerIconLeft={false}
              isLeft={isLeft}
            />
          </Box>
          <Box className={classes.resetTiming}>
            {getClassificationName(CLSFCN.RESET_TIMING.CATEGORY_CD, newServiceGraph.resetTiming)}
          </Box>
        </Box>
      );
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentPaymentInfo = (
        <Box className={classes.shiftContent}>
          <Box className={classes.shiftOperatorLeftContent}>
            <Box className="contentBox mb-30">
              {getClassificationName(CLSFCN.SHIFT_PATTERN_NAME.CATEGORY_CD, shiftOperation.shiftConf)}
            </Box>
            <Box className="contentBox">
              {getClassificationName(CLSFCN.SHIFT_CM.CATEGORY_CD, shiftOperation.shiftCm)}
            </Box>
          </Box>
          <Box className={classes.shiftOperatorRightContent}>
            <Box className={classes.shiftContainer}>
              <ShiftPanelReviewJack
                shiftConf={shiftOperation.shiftConf}
                line1={shiftOperation.line1}
                line2={shiftOperation.line2}
                line3={shiftOperation.line3}
              />
            </Box>
          </Box>
        </Box>
      );
      break;
    default:
      contentPaymentInfo = <></>;
      break;
  }

  return contentPaymentInfo;
};

const OperationConfJack: React.FC<NewPayoutInfoSupportProps> = (props) => {
  const { title, isLeft, operationConf, payoutInfoSupport, newServiceGraph, shiftOperation } = props;
  const classes = useStyles({ isLeft });
  let contentSubtitle = '';
  switch (operationConf) {
    case COMMON.OPERATION_CONF.NO_CONF:
      contentSubtitle = COMMON.OPERATION_TEXT['0'];
      break;
    case COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT:
      contentSubtitle = COMMON.OPERATION_TEXT['1'];
      break;
    case COMMON.OPERATION_CONF.NEW_SERVICE_MODE:
      contentSubtitle = COMMON.OPERATION_TEXT['2'];
      break;
    case COMMON.OPERATION_CONF.SHIFT_OPERATION:
      contentSubtitle = COMMON.OPERATION_TEXT['3'];
      break;
    default:
      contentSubtitle = '';
      break;
  }

  return (
    <Box className={`${classes.container} ${classes.border} ${classes.borderRadius}`}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          {title}
          {contentSubtitle && <span>{contentSubtitle}</span>}
        </Box>
        {newPaymentInfoSupportContent(isLeft, operationConf, payoutInfoSupport, newServiceGraph, shiftOperation)}
      </Box>
    </Box>
  );
};

export default memo(OperationConfJack);
