import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import { makeStyles, Box, Grid } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll/modules';
import StationListTable from 'components/system/organisms/tables/StationListTable';
import { StationDetailType } from 'types/data/dataType';
import UrlHelper from 'utils/url.helper';
import { REST_API } from 'constants/apiUrls';
import HttpConnection from 'utils/httpConnection';
import { TextBox } from 'components/system/atoms';
import { formatDate, subDays } from 'utils/datetime.helper';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import MessageHelper from 'utils/message.helper';
import { handleCallFrontError } from 'redux/slices/commonSlice';
import MESSAGES from 'constants/messages';
import { InfoDialog } from 'components/system/organisms';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'データシート発行',
  screenId: COMMON.SCREEN_ID.DATA_DATA_SHEET,
  currentMenu: COMMON.MENU.DATA,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '0 31px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      whiteSpace: 'pre-wrap',
      padding: '20px 0px 0px 0px',
      width: '224px',
    },
  },
  bottomArea: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'space-between',
  },
  decision: {
    fontSize: '24px',
    width: '200px',
    height: '72px',
    margin: '0 0 0 auto',
  },
  mainTable: {
    marginTop: '48px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '100%',
    height: '29px',
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  tableTitle: {
    fontSize: 23,
    position: 'absolute',
    top: '197px',
  },
  textBox: {
    height: '36px',
    width: '150px',
    '& input': {
      fontSize: '19px',
      paddingLeft: 5,
    },
    '& input::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
  },
  boxDateType: {
    display: 'flex',
    alignItems: 'end',
    marginLeft: '364px',
  },
  boxDateInputType: {
    display: 'flex',
    alignItems: 'center',
  },
  boxToDateInputType: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 41,
  },
  dateLabel: {
    fontSize: 19,
    marginRight: 16,
  },
}));

const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 6;

type LocationType = {
  from: string;
};

type CurrentDateType = {
  now: string;
};

type FilterDate = {
  fromDate: string;
  toDate: string;
};

const GenerateDataSheet: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const currentStoreName = useAppSelector((state) => state.common.userInfo?.currentStoreName);
  const { dataStationList, stationList: selectedStIdList } = useAppSelector((state) => state.data);
  const [dataSourceStation, setDataSourceStation] = useState<Array<StationDetailType>>([]);
  const [filterDate, setFilterDate] = useState<FilterDate>({ fromDate: '', toDate: '' });
  const [openInfoDlg, setOpenInfoDlg] = useState<boolean>(false);
  const fromScreen =
    location.state !== undefined ? (location.state as LocationType).from : COMMON.SCREEN_ID.DATA_SALES_DATA_VIEW;

  /* ************ API ************ */
  // 開始日/終了日の初期値を取得
  const getCurrentDate = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<CurrentDateType>(REST_API.COMMON.CURRENT_DATE);
    const today = new Date(data.now);
    setFilterDate({
      fromDate: formatDate(subDays(today, 30), '-'),
      toDate: formatDate(today, '-'),
    });
  };

  // データシートの作成
  async function generateDatasheet(): Promise<string> {
    // 選択されたステーション
    const stIds: number[] = dataSourceStation.filter((item) => item.selected).map((item) => item.stationId);
    const http = new HttpConnection({ dispatch });
    const data = await http.get<string>(REST_API.DATA.GENERATE_DATASHEET, {
      fromDate: filterDate.fromDate,
      toDate: filterDate.toDate,
      stationIds: stIds,
    });
    return data;
  }

  // データシートの取得
  const getDatasheet = async (issueDateTime: string) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.getFile(REST_API.DATA.DATASHEET, {
      issueDateTime,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${currentStoreName}_${issueDateTime}.zip`);
    document.body.appendChild(link);
    link.click();
  };

  /* ************ Local Function ************ */
  let returnBtnLabel = '売上データ\n閲覧';
  let returnBtnUrl = redirectLinks.DATA_SALES_VIEW;
  switch (fromScreen) {
    case COMMON.SCREEN_ID.DATA_SALES_DATA_VIEW:
      returnBtnLabel = '売上データ\n閲覧';
      returnBtnUrl = redirectLinks.DATA_SALES_VIEW;
      break;
    case COMMON.SCREEN_ID.DATA_COST_RATE_DATA_VIEW:
      returnBtnLabel = '原価率データ\n閲覧';
      returnBtnUrl = redirectLinks.DATA_COST_RATE_VIEW;
      break;
    default:
      break;
  }
  /* ************ useEffect ************ */
  useEffect(() => {
    // 開始日/終了日の初期値を取得
    getCurrentDate();
    // ステートからステーション情報を取得
    const dataSource: Array<StationDetailType> = selectedStIdList.map((id) => {
      const stationInfo: StationDetailType | undefined = dataStationList.find((st) => st.stationId === id);
      if (!stationInfo) {
        const tm: StationDetailType = {
          stationId: 0,
          stationNameDisp: '',
          selected: false,
        };
        return tm;
      }
      return stationInfo;
    });

    setDataSourceStation(dataSource);
  }, []);

  // ▲ボタン
  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  // ▼ボタン
  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  // check box
  const handleSelectStationRow = (row: StationDetailType) => {
    const newRows = dataSourceStation.map((item) => {
      if (row !== null && item.stationId === row.stationId) {
        return { ...item, selected: !item.selected };
      }
      return item;
    });
    setDataSourceStation([...newRows]);
  };

  // CSV発行ボタン
  const handleClickDecisionButton = async () => {
    // （無効な日付はBEへ送付されないため）日付はFEでもバリデーション
    const messeges = [];
    if (!filterDate.fromDate) {
      messeges.push(MessageHelper.messageFormat(MESSAGES.INF114, '開始日'));
    }
    if (!filterDate.toDate) {
      messeges.push(MessageHelper.messageFormat(MESSAGES.INF114, '終了日'));
    }
    if (messeges.length > 0) {
      const erros = {
        errors: {
          key: messeges,
        },
      };
      dispatch(handleCallFrontError(erros));
      return;
    }

    // ファイル作成
    generateDatasheet().then((issueDateTime: string) => {
      // ファイルダウンロード
      getDatasheet(issueDateTime).then(() => {
        setOpenInfoDlg(true);
      });
    });
  };

  // 戻るボタン
  const handleClickButtonReturnTop = () => {
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(returnBtnUrl, {
        prevScreen: COMMON.DATA_PREV_SCREEN.DATA_DATA_SHEET,
      }),
    );
  };

  const handleChangeInput = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterDate({
      ...filterDate,
      [ev.target.name]: ev.target.value,
    });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          <Box className={classes.topArea}>
            <Box className="returnButton">
              <ReturnButton onClick={handleClickButtonReturnTop}>{returnBtnLabel}</ReturnButton>
            </Box>
            <Box className={classes.boxDateType}>
              <Box className={classes.boxDateInputType}>
                <Box className={classes.dateLabel}>開始日</Box>
                <TextBox
                  id="fromDate"
                  name="fromDate"
                  value={filterDate.fromDate}
                  onChange={handleChangeInput}
                  className={classes.textBox}
                  fullWidth
                  type="date"
                />
              </Box>
              <Box className={classes.boxToDateInputType}>
                <Box className={classes.dateLabel}>終了日</Box>
                <TextBox
                  id="toDate"
                  name="toDate"
                  value={filterDate.toDate}
                  onChange={handleChangeInput}
                  className={classes.textBox}
                  fullWidth
                  type="date"
                />
              </Box>
            </Box>
          </Box>
          <Box className={classes.mainTable}>
            <Grid className={classes.previousBtn}>
              {dataSourceStation.length > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
            </Grid>
            <Box className={classes.tableTitle}>対象となるステーションを選択</Box>
            <StationListTable
              rows={dataSourceStation}
              handleSelectedItemChange={handleSelectStationRow}
              id={TABLE_ID}
            />
            <Grid className={classes.nextBtn}>
              {MAX_DISPLAY_BAR < dataSourceStation.length && (
                <PrevNextButton className="nextScroll" onClick={handleClickNext} />
              )}
            </Grid>
          </Box>
          <Box className={classes.bottomArea}>
            <DoneButton onClick={handleClickDecisionButton} className={classes.decision}>
              CSV発行
            </DoneButton>
          </Box>
        </Box>
        <InfoDialog open={openInfoDlg} msg={MESSAGES.INF310} closeFunc={() => setOpenInfoDlg(false)} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GenerateDataSheet;
