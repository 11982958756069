import React, { useCallback, useEffect } from 'react';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import OutlineTextBox from 'components/system/atoms/textBoxs/OutlineTextBox';
import { getUserAuthThunk } from '../../redux/slices/commonSlice';
import { useAppDispatch } from '../../redux/hooks';
import Layout from '../../components/system/layouts/Layout';
import HttpConnection from '../../utils/httpConnection';
import { REST_API } from '../../constants/apiUrls';
import { IOperationExampleType } from '../../types/manualInformation/manualInformationType';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'マニュアル・お知らせ',
  screenId: 'SCR713',
  currentMenu: COMMON.MENU.MANUAL_NOTICE,
  disableContents: true,
};

// CSS定義
const useStyles = makeStyles((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 16px 12px 16px',
    '& .detail': {
      height: 668,
      width: '1144px',
      padding: '4px 5px',
      borderRadius: '0 0 4px 4px',
      backgroundColor: theme.palette.common.white,
    },
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      flex: 1,
      padding: '10px 0px 0px 22px',
      width: '224px',
    },
  },
  contentsDetail: {
    height: '668px',
    width: '1144px',
  },
  container: {
    height: 650,
    border: '1px solid #707070',
    borderRadius: 4,
    marginTop: 4,
  },
  header: {
    padding: '5px 10px',
    width: '100%',
    height: 33,
    background: '#535B80',
    fontSize: 19,
    color: '#fff',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
  },
  informationText: {
    fontSize: 20,
    marginBottom: 5,
    color: '#007bff',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: 5,
  },
  informationContainer: {
    margin: '16px 25px',
    height: 504,
    overflowY: 'auto',
  },
  textContain: {
    display: 'table',
    lineHeight: 2,
  },
  textItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  boxKeyword: {
    display: 'inline-flex',
    margin: '20px 10px 0 0',
    width: '286px',
  },
  keyword: {
    '& .outlinedInput': {
      width: '276px',
      '& input': {
        fontSize: '20px',
      },
    },
  },
  searchBtn: {
    margin: '1px 0px 0px -42px',
  },
  queryCount: {
    display: 'inline-flex',
    margin: '20px 300px 0 0',
    fontSize: '20px',
    lineHeight: '58px',
    width: '286px',
  },
}));

type LogOperationExampleResponseType = {
  result: boolean;
};

/* ************ Component ************ */
const OperationExampleList: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [operationExampleList, setOperationExampleList] = React.useState<Array<IOperationExampleType>>([]);
  const [operationExampleSelected, setOperationExampleSelected] = React.useState<IOperationExampleType>();
  const [inputWord, setInputWord] = React.useState<string>('');
  const [countQueried, setCountQueried] = React.useState<number>(0);

  // get OperationExample api
  const getOperationExampleApi = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.get<IOperationExampleType[]>(REST_API.MANUAL_NOTICE.GET_OPERATION_EXAMPLE, {
      search: inputWord,
    });
    setOperationExampleList(data);
    setCountQueried(data.length);
  };

  useEffect(() => {
    dispatch(getUserAuthThunk());
    getOperationExampleApi().then();
  }, []);
  useEffect(() => {
    handleOpenPdf();
  }, [operationExampleSelected]);

  const renderOperationExampleItem = useCallback(() => {
    if (operationExampleList.length === 0) return <></>;
    const operationExampleInfo = operationExampleList.map((item) => (
      <div key={item.id} className={classes.textContain} onClick={() => handleShowDialogDownload(item)}>
        <div className={classes.textItem}>
          <div>• </div>
          <div className={classes.informationText}>{item.title}</div>
        </div>
      </div>
    ));
    return <div className={classes.informationContainer}>{operationExampleInfo}</div>;
  }, [operationExampleList]);

  const handleShowDialogDownload = useCallback((item: IOperationExampleType) => {
    setOperationExampleSelected(item);
  }, []);

  const handleOpenPdf = useCallback(async () => {
    if (operationExampleSelected?.path === undefined) return;
    // PDF画面を別タブで開く
    window.open(`${redirectLinks.OPERATION_EXAMPLE_PDF}/${operationExampleSelected.path}`);
    // log出力
    const http = new HttpConnection({ dispatch });
    await http.post<LogOperationExampleResponseType>(REST_API.MANUAL_NOTICE.LOG_OPERATION_EXAMPLE, {
      id: operationExampleSelected?.id,
      path: operationExampleSelected?.path,
    });
    setOperationExampleSelected(undefined);
  }, [operationExampleSelected]);

  const handleClickGroupConfTop = () => {
    history.push(redirectLinks.MANUAL_NOTICE);
  };
  const handleChangeKeyword = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setInputWord(ev.target.value);
  };
  const handleClickSearch = () => {
    getOperationExampleApi().then();
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Box className={classes.contentsDetail}>
            <Box className="detail">
              <div className={classes.container}>
                <div className={classes.header}>運営事例一覧</div>
                <Box className={classes.topArea}>
                  <Box className="returnButton">
                    <ReturnButton onClick={handleClickGroupConfTop}>
                      お知らせ
                      <br />
                      運営事例
                    </ReturnButton>
                  </Box>
                  <Box className={classes.boxKeyword}>
                    <OutlineTextBox
                      id="keyWord"
                      name="keyWord"
                      placeholder="キーワード検索"
                      labelPlacement="top"
                      value={inputWord}
                      onChange={handleChangeKeyword}
                      className={classes.keyword}
                      fullWidth
                    />
                    <IconButton onClick={handleClickSearch} className={classes.searchBtn}>
                      🔍
                    </IconButton>
                  </Box>
                  <Box className={classes.queryCount}>{`該当件数 ${countQueried.toLocaleString()}件`}</Box>
                </Box>
                {renderOperationExampleItem()}
              </div>
            </Box>
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default OperationExampleList;
