import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { makeStyles, Box, Typography } from '@material-ui/core';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import redirectLinks from 'constants/redirectLinks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import CLSFCN from 'constants/classification';
import leverOperationTimeIcon from 'assets/images/leverOperationTimeIcon.svg';
import howToEnterButtonIcon from 'assets/images/howToEnterButtonIcon.svg';
import { setLeverOperation } from 'redux/slices/machineConfSlice';
import COMMON from 'constants/common';
import { SelectButton } from 'components/system/atoms';
import { SelectListType } from 'types/support/supportType';
import SelectList from 'components/support/organisms/supports/SelectList';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { ClassificationType } from 'types/common/commonType';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import HttpConnection from 'utils/httpConnection';
import { XySpeedLeverConfType } from 'types/machineConf/machineConfType';
import { REST_API } from 'constants/apiUrls';
import ERROR_CODE from 'constants/errorCode';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: 'レバー操作設定',
  screenId: COMMON.SCREEN_ID.LEVER_OPERATION_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  LEVER_TIME: 'leverTime',
  ENTER_BUTTON: 'enterButton',
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  content: {
    position: 'relative',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '104px',
    '& .description': {
      position: 'absolute',
      top: '0px',
      left: '216px',
      fontSize: '20px',
    },
  },
  mainArea: {
    display: 'flex',
    '& .leverOperationArea': {
      position: 'absolute',
      top: '96px',
      left: '0px',
      height: '352px',
      width: '528px',
    },
    '& .enterButtonArea': {
      position: 'absolute',
      top: '96px',
      left: '540px',
      height: '352px',
      width: '556px',
      fontSize: '19px',
      lineHeight: '23px',
    },
    '& .doneArea': {
      position: 'absolute',
      top: '464px',
      left: '900px',
    },
  },
  inputAreaBox: {
    backgroundColor: '#FFFBDF',
    borderRadius: '8px',
    border: '1px solid #707070',
    padding: '24px 0px 0px 12px',
  },
  leverOperationIcon: {
    display: 'flex',
    justifyContent: 'center',
    height: '200px',
    margin: '-6px 0px 0px 0px',
  },
  selectButtonArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '32px 15px 0px 0px',
  },
  leverTimeButton: {
    width: '125px',
    height: '65px',
    fontSize: '19px',
  },
  enterButton: {
    height: '65px',
    width: '192px',
    fontSize: '19px',
  },
  title: {
    textAlign: 'left',
    height: '24px',
    fontSize: '21px',
    color: '#FF0000',
    fontWeight: 'bold',
  },
  enterInnerTop: {
    height: '70px',
    margin: '16px 0px 0px 0px',
  },
  enterInnerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '193px',
    margin: '12px 0px 0px 0px',
  },
  enterInnerBottomDesc: {
    width: '273px',
  },
  enterInnerBottomSelect: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '0px 15px 0px 0px',
  },
  enterButtonIcon: {
    position: 'absolute',
    top: '54px',
    left: '241px',
  },
  textNote: {
    borderRadius: '3px',
    width: '60px',
    textAlign: 'center',
    display: 'inline-block',
    background: '#000000',
    color: '#ffffff',
  },
  decision: {
    marginTop: '15px',
    marginRight: '12px',
    '& button': {
      background: '#000000',
      color: '#ffffff',
      fontSize: '40px',
      width: '180px',
      lineHeight: '1.4',
      '&:hover': {
        background: '#000000',
        opacity: '0.5',
      },
    },
  },
}));

const LeverOperationConfJack: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  /* *************      hooks     ************* */
  const leftRight = COMMON.LEFT_RIGHT.RIGHT;
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);

  const dataSouceLeverTime = useRef(getClsfcnByCategory(CLSFCN.SETTING_LEVER_OPEATION.LEVER_TIME));
  const dataSouceEnterButton = useRef(getClsfcnByCategory(CLSFCN.ENTER_BUTTON.CATEGORY_CD));

  // ダイアログ
  const [tempXySpeedLeverConf, setTempXySpeedLeverConf] = useState({
    lever_time: xySpeedLeverConf ? xySpeedLeverConf.leverTime : '10',
    enter_button: xySpeedLeverConf ? xySpeedLeverConf.enterButton : '0',
  });

  // useState
  const [selectList, setSelectList] = useState<SelectListType>({
    anchorEL: null,
    dataSource: [],
    name: '',
    open: false,
    title: '',
    placement: 'right',
  });

  useEffect(() => {
    // Checking system error that the state cannot retrieve
    if (
      !xySpeedLeverConf ||
      checkingDataSoure(dataSouceLeverTime.current) ||
      checkingDataSoure(dataSouceEnterButton.current)
    ) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // Checking status of Data soure
  const checkingDataSoure = (obj: Array<ClassificationType>) => obj === undefined || obj.length <= 0;

  // on change select
  const handleClickLeverTime = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        anchorEL: event.currentTarget,
        dataSource: dataSouceLeverTime.current,
        name: INPUT_NAMES.LEVER_TIME,
        open: true,
        placement: 'right-end',
        title: 'レバー操作時間を選択してください',
        value: tempXySpeedLeverConf.lever_time,
        onClick: handleClickValidListLeverTime,
        onClose: handleCloseSelectList,
      });
    },
    [tempXySpeedLeverConf.lever_time],
  );

  const handleClickValidListLeverTime = useCallback((data: { value: string }) => {
    setTempXySpeedLeverConf((prevState) => ({
      ...prevState,
      lever_time: data.value,
    }));
    setSelectList({
      ...selectList,
      anchorEL: null,
      open: false,
      name: '',
      value: data.value,
    });
  }, []);

  const handleClickEnterButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        anchorEL: event.currentTarget,
        dataSource: dataSouceEnterButton.current,
        name: INPUT_NAMES.ENTER_BUTTON,
        open: !selectList.open,
        placement: 'left',
        title: '決定ボタンの使い方を選択してください',
        value: tempXySpeedLeverConf.enter_button,
        onClick: handleClickValidListEnterButton,
        onClose: handleCloseSelectList,
      });
    },
    [tempXySpeedLeverConf.enter_button],
  );

  const handleClickValidListEnterButton = useCallback((data: { value: string }) => {
    setTempXySpeedLeverConf((prevState) => ({
      ...prevState,
      enter_button: data.value,
    }));
    setSelectList({
      ...selectList,
      anchorEL: null,
      name: '',
      open: false,
      value: data.value,
    });
  }, []);

  const handleCloseSelectList = useCallback(() => {
    setSelectList({
      ...selectList,
      anchorEL: null,
      name: '',
      open: false,
    });
  }, []);

  // return button change
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };

  const handleClickDecision = async () => {
    dispatch(
      setLeverOperation({
        leverTime: tempXySpeedLeverConf.lever_time,
        enterButton: tempXySpeedLeverConf.enter_button,
      }),
    );
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http.post<XySpeedLeverConfType>(REST_API.MACHINE_CONF.LEVER_CONF_VALIDATION, tempXySpeedLeverConf);
    history.push(redirectLinks.MCONF_JACK_CONF_XY_PIT_AREA_TOP);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              <ReturnButton onClick={handleClickReturn}>
                移動設定
                <br />
                TOP
              </ReturnButton>
              <p className="description">
                「レバー操作時の動作」を設定できます。
                <br />
                決定ボタンの使い方と、レバー操作時間を設定し、
                <Typography className={classes.textNote}> 確 定</Typography> してください。
              </p>
            </Box>
            <Box className={classes.mainArea}>
              <Box className={`leverOperationArea ${classes.inputAreaBox}`}>
                <Box className={classes.title}>レバー操作時間</Box>
                <Box className={classes.leverOperationIcon}>
                  <img src={leverOperationTimeIcon} alt="レバー操作アイコン" />
                </Box>
                <Box className={classes.selectButtonArea}>
                  <SelectButton
                    className={classes.leverTimeButton}
                    leftRight={leftRight}
                    onClick={handleClickLeverTime}
                    isSelected={selectList.name === INPUT_NAMES.LEVER_TIME}>
                    {getClassificationName(CLSFCN.SETTING_LEVER_OPEATION.LEVER_TIME, tempXySpeedLeverConf.lever_time)}
                  </SelectButton>
                </Box>
              </Box>
              <Box className={`enterButtonArea ${classes.inputAreaBox}`}>
                <img src={howToEnterButtonIcon} alt="決定ボタンの使い方アイコン" className={classes.enterButtonIcon} />
                <Box className={classes.title}>決定ボタンの使い方</Box>
                <Box className={classes.enterInnerTop}>
                  ①下降停止なし <br />
                  &nbsp;&nbsp;&nbsp;決定ボタンを押すと、
                  <br />
                  &nbsp;&nbsp;&nbsp;キャッチャーが下降開始します。
                </Box>
                <Box className={classes.enterInnerBottom}>
                  <Box className={classes.enterInnerBottomDesc}>
                    ②下降停止あり <br />
                    &nbsp;&nbsp;&nbsp; 決定ボタンを押すと、 <br />
                    &nbsp;&nbsp;&nbsp; キャッチャーが下降開始 <br />
                    &nbsp;&nbsp;&nbsp; します。 <br />
                    &nbsp;&nbsp;&nbsp; 下降中にもう一度、 <br />
                    &nbsp;&nbsp;&nbsp; 決定ボタンを押すと、 <br />
                    &nbsp;&nbsp;&nbsp; 任意の高さでキャッチャーを <br />
                    &nbsp;&nbsp;&nbsp; 停止させることができます。
                  </Box>
                  <Box className={classes.enterInnerBottomSelect}>
                    <SelectButton
                      className={classes.enterButton}
                      leftRight={leftRight}
                      onClick={handleClickEnterButton}
                      isSelected={selectList.name === INPUT_NAMES.ENTER_BUTTON}>
                      {getClassificationName(CLSFCN.ENTER_BUTTON.CATEGORY_CD, tempXySpeedLeverConf.enter_button)}
                    </SelectButton>
                  </Box>
                </Box>
              </Box>
              <Box className="doneArea">
                <DoneButton onClick={handleClickDecision}>確 定</DoneButton>
              </Box>
            </Box>
          </Box>
          <SelectList selectList={selectList} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default LeverOperationConfJack;
