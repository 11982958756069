import React, { useContext, useEffect } from 'react';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { checkScreenAuth } from 'utils/common.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ERROR_CODE from 'constants/errorCode';
import ErrorBoundary from './ErrorBoundary';
import Loading from '../organisms/Loading';
import LoginContent from '../organisms/contents/ContentWithoutTitle';

/* ************ Component ************ */
const Layout: React.FC = ({ children }) => {
  const screenContext = useContext(ScreenContext);
  const { loading } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (screenContext.screenId !== 'SCR011' && !checkScreenAuth(screenContext.screenId)) {
      // システムエラーフラグを立て、Error Boundaryでエラー処理を引き起こす。
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.SCR_AUTH_ERROR }));
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Loading open={loading} />
        {/** disableCotentsを指定する場合はcontentの適用を解除 */}
        {!screenContext.disableContents ? <LoginContent>{children}</LoginContent> : <>{children}</>}
      </ErrorBoundary>
    </>
  );
};

export default Layout;
