import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PointerIcon from 'components/machineConf/atoms/icons/PointerIcon';
import { useAppSelector } from 'redux/hooks';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import COMMON from 'constants/common';

/**
 * XyConfInfoButton Component
 * XY移動範囲・HP設定画面遷移ボタン
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// 型定義
const WIDTH_BOX = 30;
const FONT_SIZE_BOX = 19;
const MAX_CONTAINER = 180;
const MAX_WIDTH_LINE = 25;
const MAX_WIDTH_BOX = 15;
const MAX_WIDTH_TAG = 28;
const MARGIN_VALUE = 6;
const X_Y_LIMIT_H = 5;
const WIDTH_BORDER_BOX = 2;
const MIN_LIMIT_X = 22;

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.black,
    borderRadius: '8px',
    background: '#EBEBEB',
    height: '268px',
    width: '264px',
    fontSize: '27px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#EBEBEB',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
  },
  frame: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -20,
  },
  child: {
    margin: '0 0px 0px 0',
  },
  borderLeft: {
    border: '2px solid',
    borderColor: theme.leftStation.itemBorder,
  },
  borderRight: {
    border: '2px solid',
    borderColor: theme.rightStation.itemBorder,
  },
  specifyArea: {
    display: 'flex',
    width: '182px',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    '& .inner': {
      position: 'relative',
    },
    '& .table': {
      position: 'relative',
      width: '180px',
      height: '180px',
    },
    '& .tableCols': {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
    '& .tableCol': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      background: '#ffffff',
    },
    '& .tableRows': {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 11,
    },
    '& .tableRow': {
      width: '100%',
      height: '30px',
      borderTop: 'none',
    },
    '& .tableColLeft-1, & .tableColLeft-3': {
      borderRight: '1px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableColLeft-5': {
      borderRight: '2px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableRowLeft-2, & .tableRowLeft-4': {
      borderTop: '1px solid',
      borderColor: theme.leftStation.itemBorder,
    },
    '& .tableColRight-1, & .tableColRight-3': {
      borderRight: '1px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableColRight-5': {
      borderRight: '2px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableRowRight-2, & .tableRowRight-4': {
      borderTop: '1px solid',
      borderColor: theme.rightStation.itemBorder,
    },
    '& .line': {
      width: '30px',
      height: '30px',
    },
  },
  panel: {
    width: '180px',
    height: '180px',
    flexDirection: 'column',
    position: 'absolute',
    textAlign: 'center',
    top: '51px',
    left: '42px',
    zIndex: 20,
  },
  abs: {
    position: 'absolute',
  },
  homeHr: {
    position: 'absolute',
    borderTop: 'dashed 2px #FF3E3E',
    width: '180px',
    fontSize: 0,
  },
  homeVertical: {
    position: 'absolute',
    borderLeft: '2px dashed #FF3E3E',
    width: '2px',
    height: '180px',
  },
  endHr: {
    position: 'absolute',
    borderTop: 'dashed 2px #3086FF',
    width: '180px',
    fontSize: 0,
  },
  endVertical: {
    position: 'absolute',
    borderLeft: 'dashed 2px #3086FF',
    width: '2px',
    height: '180px',
    fontSize: 0,
  },
  homeXValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    top: '221px',
    left: '61px',
  },
  homeYValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    top: '175px',
    left: '206px',
  },
  homeBg: {
    background: '#EEEEEE',
    border: '1px solid #FF3E3E',
    borderRadius: '2px',
    width: `${WIDTH_BOX}px`,
    height: `${WIDTH_BOX}px`,
    fontSize: `${FONT_SIZE_BOX}px`,
  },
  endXValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  endYValueLeft: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
  },
  endBg: {
    background: '#EEEEEE',
    border: '1px solid #3086FF',
    borderRadius: '2px',
    width: `${WIDTH_BOX}px`,
    height: `${WIDTH_BOX}px`,
    fontSize: `${FONT_SIZE_BOX}px`,
  },
  homeVerticalRight: {
    position: 'absolute',
    left: '184px',
    zIndex: 10,
    border: '1px dashed #FF3E3E',
    width: '2px',
    height: '180px',
    top: '56px',
  },
  homeXValueRight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
    top: '221px',
    left: '162px',
  },
  homeYValueRight: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 20,
    top: '175px',
    left: '15px',
  },
  endXValueRight: {
    position: 'absolute',
  },
  endYValueRight: {
    position: 'absolute',
  },
  p: {
    marginLeft: '-3px',
    letterSpacing: '-2px',
  },
}));

type ButtonProps = {
  title: string;
  subtitle?: string;
  onClick?: () => void;
};

// コンポーネント定義
const XyConfInfoButton: React.FC<ButtonProps> = (props) => {
  /* ************ state/redux ************ */
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const xySpeedLeverConf = useAppSelector((state) => state.machineConf.machineConf.xySpeedLeverConf);
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義
  // エリアパネル 「列」を作成
  const tableCols = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(
        <Box
          className={
            leftRight === COMMON.LEFT_RIGHT.LEFT ? `tableCol tableColLeft-${index}` : `tableCol tableColRight-${index}`
          }>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  // エリアパネル 「行」を作成
  const tableRows = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(
        <Box
          className={
            leftRight === COMMON.LEFT_RIGHT.LEFT ? `tableRow tableRowLeft-${index}` : `tableRow tableRowRight-${index}`
          }>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  // Hアイコン表示
  const hIcon = () => {
    const item: Array<JSX.Element> = [];
    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      const iconPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * 1.8 - MAX_WIDTH_TAG / 2
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.homeX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * 1.8 - MAX_WIDTH_TAG / 2
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    }
    return item;
  };
  // H座標値表示
  const hValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;
    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginLeft -= MAX_WIDTH_BOX - pixelBetween;
          }
        }
        if (valueOverloadY <= MAX_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginTop += MAX_WIDTH_BOX - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * 1.8}px, ${MAX_CONTAINER + MARGIN_VALUE}px`,
        left: `${marginLeft + marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(-${WIDTH_BOX + MARGIN_VALUE}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * 1.8
        }px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginLeft += MAX_WIDTH_BOX - pixelBetween;
          }
        }
        if (valueOverloadY <= MAX_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginTop += MAX_WIDTH_BOX - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.homeX) * 1.8}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(0px,${MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * 1.8}px`,
        left: MAX_CONTAINER + MARGIN_VALUE,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };
  // H縦線表示
  const hVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      const VarPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * 1.8}px,0px)`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.homeX) * 1.8}px,0px`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };
  // H横線表示
  const hHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px,${MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * 1.8}px)`,
    };
    item.push(<hr className={classes.homeHr} style={hrPos} />);
    return item;
  };
  // Eアイコン表示
  const eIcon = () => {
    const item: Array<JSX.Element> = [];
    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      const iconPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * 1.8 - MAX_WIDTH_TAG / 2
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.endX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * 1.8 - MAX_WIDTH_TAG / 2
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    }
    return item;
  };
  // E座標値表示
  const eValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;

    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginLeft += MAX_WIDTH_BOX - pixelBetween;
          }
        }
        if (valueOverloadY <= MAX_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginTop -= MAX_WIDTH_BOX - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * 1.8}px, ${MAX_CONTAINER + MARGIN_VALUE}px`,
        left: `${marginLeft + marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(-${WIDTH_BOX + MARGIN_VALUE}px,${MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * 1.8}px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MAX_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginLeft -= MAX_WIDTH_BOX - pixelBetween;
          }
        }
        if (valueOverloadY <= MAX_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * MAX_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WIDTH_BOX) {
            marginTop -= MAX_WIDTH_BOX - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.endX) * 1.8}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(0px,${MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * 1.8}px`,
        left: MAX_CONTAINER + MARGIN_VALUE,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };
  // E縦線表示
  const eVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      const VarPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * 1.8}px,0px)`,
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.endX) * 1.8}px,0px`,
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };
  // E横線表示
  const eHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px,${MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * 1.8}px)`,
    };
    item.push(<hr className={classes.endHr} style={hrPos} />);
    return item;
  };
  // HPアイコン表示
  const customHpIcon = () => {
    const item: Array<JSX.Element> = [];
    if (xySpeedLeverConf?.customConf === COMMON.CUSTOM_HOME_POSITION.CUSTOM) {
      if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
        const iconPos = {
          transform: `translate(${Number(xySpeedLeverConf?.customX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
            MAX_CONTAINER - Number(xySpeedLeverConf?.customY) * 1.8 - MAX_WIDTH_TAG / 2
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      } else {
        const iconPos = {
          transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.customX) * 1.8 - MAX_WIDTH_TAG / 2}px,${
            MAX_CONTAINER - Number(xySpeedLeverConf?.customY) * 1.8 - MAX_WIDTH_TAG / 2
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      }
    }
    return item;
  };

  // propsのプロパティ情報を取得
  const { title, subtitle, onClick } = props;

  return (
    <NormalButton variant="contained" className={classes.button} onClick={onClick}>
      <Box className={classes.frame}>
        <Box className={`${classes.title} customTitle`}>
          {title}
          {subtitle ? <span>{subtitle}</span> : ''}
        </Box>

        <Box className={`${classes.child} customTable`}>
          <Box className={classes.specifyArea}>
            <Box className={leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.borderLeft : classes.borderRight}>
              <Box className="inner">
                <Box className="table">
                  <Box className="tableCols">{tableCols(5)} </Box>
                  <Box className="tableRows">{tableRows(5)}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.panel}>
          {eHrLine()}
          {hHrLine()}
          {eVerLine()}
          {hVerLine()}
          {eValue()}
          {hValue()}
          {hIcon()}
          {customHpIcon()}
          {eIcon()}
        </Box>
      </Box>
    </NormalButton>
  );

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(XyConfInfoButton);
