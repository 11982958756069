/**
 * Function use for format number decial on FE
 * @author: atsushi.teruya
 *
 * @param number
 * @returns {string}
 */
export const formatCurrency = (number: number): string => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  });

  return formatter.format(number);
};

/**
 * Function use for format number decial on FE
 * @author: akinori.akamine
 *
 * @param number
 * @returns {string}
 */
export const formatPercent = (number: number, maximumSignificantDigits = 3): string =>
  new Intl.NumberFormat('ja-JP', {
    style: 'percent',
    maximumSignificantDigits,
  }).format(number);
