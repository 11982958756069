/*
 * フロント専用のエラーコード
 *  【命名ルール】
 *   ・必ずprefixはつける
 *   ・prefix込みで6桁
 */

const prefix = 'F';
const ERROR_CODE = {
  SCR_AUTH_ERROR: `${prefix}00000`, // 権限エラー
  PARAMETER_ERROR: `${prefix}00001`, // パラメータ不正
  NOT_FOUND: `${prefix}00002`, // データ不正など
  UNKWON_ERROR: `${prefix}99999`, // システム未制御のエラー
};

export default ERROR_CODE;
