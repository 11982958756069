import { AreaConfType } from 'types/machineConf/machineConfType';
import COMMON from 'constants/common';

/**
 * Function use reset descentLimit to 100 if initialHeight is greater than descentLimit
 * @author: nam.tran
 *
 * @param AreaConfType | undefined
 * @returns AreaConfType
 */
export const resetDescentLimitValue = (
  tempArea: AreaConfType | undefined,
): { areaConf: AreaConfType; hasReset: boolean } | undefined => {
  if (!tempArea) return tempArea;

  let hasReset = false;
  let newDescentLimit = {};
  const initialHeight = Number(tempArea?.initialHeight);

  const lines: Array<string> = [
    tempArea.line1,
    tempArea.line2,
    tempArea.line3,
    tempArea.line4,
    tempArea.line5,
    tempArea.line6,
  ];

  const defaultDescentLimit: Array<number> = [
    tempArea.descentLimit1,
    tempArea.descentLimit2,
    tempArea.descentLimit3,
    tempArea.descentLimit4,
    tempArea.descentLimit5,
    tempArea.descentLimit6,
  ];

  const uniqueLine = lines.filter((c: string, index: number) => lines.indexOf(c) === index).join('');
  const uniqueNumberLine = Array.from(new Set(uniqueLine));
  const firstCharAtLine = uniqueNumberLine.map((val) => Number(val.charAt(0)));

  firstCharAtLine.map((val: number) => {
    if (initialHeight >= Number(defaultDescentLimit[val])) {
      hasReset = true;
      defaultDescentLimit[val] = COMMON.RESET_VALUE_DESCENT_LIMIT;
    }
    return null;
  });

  defaultDescentLimit.map((val: number, index: number) => {
    newDescentLimit = {
      ...newDescentLimit,
      [`descentLimit${index + 1}`]: val,
    };
    return null;
  });

  const newTempAreaConf: AreaConfType | undefined = {
    ...tempArea,
    ...newDescentLimit,
  };

  return { areaConf: newTempAreaConf, hasReset };
};
