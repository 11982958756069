import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { makeStyles, Box } from '@material-ui/core';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import redirectLinks from 'constants/redirectLinks';
import COMMON from 'constants/common';
import MachineConfContentSettingDetail from 'components/machineConf/organisms/contents/MachineConfContentSettingDetail';
import SpeedConfInfo from 'components/machineConf/organisms/setting/SpeedConfInfo';
import XyConfInfo from 'components/machineConf/organisms/setting/XyConfInfo';
import PitConfInfo from 'components/machineConf/organisms/setting/PitConfInfo';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LeverManagement from 'components/machineConf/organisms/setting/LeverManagement';
import OperationConf, {
  NewPayoutInfoSupportType,
  NewServiceGraphType,
  ShiftOperationType,
} from 'components/machineConf/organisms/setting/OperationConf';
import ArmPowerInfo, { ArmPowerInfoType } from 'components/machineConf/organisms/setting/ArmPowerInfo';
import {
  AreaConfType,
  ConfListType,
  MachineConfType,
  PitConfType,
  XySpeedLeverConfType,
} from 'types/machineConf/machineConfType';
import useShowAreaConf from 'customHooks/useShowAreaConf';
import { getMachineVersion } from 'utils/common.helper';
import { SaveDetailInfoType } from 'types/machineConf/stationSelectType';
import TableStationHomeDetail from './TableStationHomeDetail';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '詳細画面HOME',
  screenId: COMMON.SCREEN_ID.STATION_HOME_DETAIL,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

type StationHomeProps = {
  stationName: string;
  giftName: string;
  machineType: string | null;
  machineVersion: string | null;
};

type LevelManagementType = {
  levelStatus: string;
  levelTime: string;
};

type PreviousPageType = {
  from: string;
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '& .leftArea': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .centerArea': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .bottom': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  operationNewPayoutButton: {
    width: '316px',
    '& button': {
      width: '100%',
      marginTop: '0',
      marginLeft: '0',
    },
  },
  xyConfButton: {
    width: '404px',
    '& button': {
      width: '100%',
    },
  },
  SpeedConfButton: {
    width: '192px',
    '& button': {
      width: '100%',
    },
    '& table': {
      marginLeft: '0',
    },
  },
  PitConfButton: {
    width: '192px',
    '& button': {
      width: '100%',
    },
  },
  ArmPowerButton: {
    width: '320px',
    '& button': {
      width: '100%',
      margin: '0',
    },
  },
  breakText: {
    whiteSpace: 'break-spaces',
  },
}));

const StationHomeDetail: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { selectConfId } = useParams<{ selectConfId: string }>();
  const areaConfHook = useShowAreaConf({ selectConfId });
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { stationList } = useAppSelector((state) => state.machineConf.stationSelect);
  const savedConfList: Array<ConfListType> = useAppSelector((state) => state.machineConf.savedConfList);
  const [xySpeedLeverConf, setXySpeedLeverConf] = React.useState<XySpeedLeverConfType>();
  const [pitConf, setPitConf] = React.useState<PitConfType>();
  const [areaConf, setAreaConf] = React.useState<AreaConfType>();
  const [operationConf, setOperationConf] = React.useState(COMMON.OPERATION_CONF.NO_CONF);
  const [previousPage, setPreviousPage] = React.useState('');
  const [isStationListScreen, setIsStationListScreen] = React.useState(false);
  const [isLeft, setIsLeft] = React.useState(false);
  const [textButtonBack, setTextButtonBack] = React.useState('ステーション\n一覧');

  const [levelManagement, setLevelManagement] = React.useState<LevelManagementType>({
    levelStatus: COMMON.LEVER_STATUS.FALSE,
    levelTime: '',
  });
  const [payoutInfoSupport, setPayoutInfoSupport] = React.useState<NewPayoutInfoSupportType>({
    grabLPower: 0,
    grabRPower: 0,
    grabLPower2: 0,
    grabRPower2: 0,
    grabLPower3: 0,
    grabRPower3: 0,
    grabLPower4: 0,
    grabRPower4: 0,
    grabLPower5: 0,
    grabRPower5: 0,
    grabLPower6: 0,
    grabRPower6: 0,
    lPower1: 0,
    rPower1: 0,
    backTiming: '',
    getSupport: '',
    movingLPower: 0,
    movingRPower: 0,
    timing1: '000000',
  });

  const [newServiceGraph, setNewServiceGraph] = React.useState<NewServiceGraphType>({
    lPower1: 0,
    rPower1: 0,
    limitLPower: 0,
    limitRPower: 0,
    resetTiming: '',
  });
  const [shiftOperation, setShiftOperation] = React.useState<ShiftOperationType>({
    line1: '',
    line2: '',
    line3: '',
    shiftConf: '',
    shiftCm: '',
  });

  const [stationHomeProps, setStationHomeProps] = React.useState<StationHomeProps>({
    stationName: '',
    giftName: '',
    machineType: null,
    machineVersion: null,
  });
  const [savedConfProps, setSavedConfProps] = React.useState<SaveDetailInfoType>({
    saveName: '',
    saveMachineVersion: null,
    saveMachineType: null,
  });
  const [armPowerInfoType, setArmPowerInfoType] = React.useState<ArmPowerInfoType>({
    initialHeight: '',
    leftArmSize: '',
    rightArmSize: '',
    lPower1: 0,
    rPower1: 0,
    armOpen: '',
    pushing1: '',
    descentLimit1: 0,
    catcherDirection: '',
  });

  // 選択したステーションの設定情報を取得
  const getSettingDetail = async (confId: number) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<MachineConfType>(REST_API.MACHINE_CONF.GET_SETTING_DETAIL, {
      confId,
    });
    if (!data) return;
    if (data.xySpeedLeverConf) {
      setXySpeedLeverConf({
        homeX: data.xySpeedLeverConf?.homeX,
        homeY: data.xySpeedLeverConf?.homeY,
        endX: data.xySpeedLeverConf?.endX,
        endY: data.xySpeedLeverConf?.endY,
        customConf: data.xySpeedLeverConf?.customConf,
        customX: data.xySpeedLeverConf?.customX,
        customY: data.xySpeedLeverConf?.customY,
        speedXy: data.xySpeedLeverConf?.speedXy,
        speedZ: data.xySpeedLeverConf?.speedZ,
        leverStatus: data.xySpeedLeverConf?.leverStatus,
        leverTime: data.xySpeedLeverConf?.leverTime,
        enterButton: data.xySpeedLeverConf?.enterButton,
      });

      setLevelManagement({
        levelStatus: data.xySpeedLeverConf.leverStatus,
        levelTime: data.xySpeedLeverConf.leverTime,
      });
    }

    if (data.operationConf && data.areaConf) {
      setOperationConf(data.operationConf.operationConf);
      setPayoutInfoSupport({
        grabLPower: data.operationConf.grabLPower,
        grabRPower: data.operationConf.grabRPower,
        grabLPower2: data.operationConf.grabLPower2,
        grabRPower2: data.operationConf.grabRPower2,
        grabLPower3: data.operationConf.grabLPower3,
        grabRPower3: data.operationConf.grabRPower3,
        grabLPower4: data.operationConf.grabLPower4,
        grabRPower4: data.operationConf.grabRPower4,
        grabLPower5: data.operationConf.grabLPower5,
        grabRPower5: data.operationConf.grabRPower5,
        grabLPower6: data.operationConf.grabLPower6,
        grabRPower6: data.operationConf.grabRPower6,
        lPower1: data.areaConf?.lPower1,
        rPower1: data.areaConf?.rPower1,
        backTiming: data.operationConf.backTiming,
        getSupport: data.operationConf.getSupport,
        movingLPower: data.operationConf.movingLPower,
        movingRPower: data.operationConf.movingRPower,
        timing1: data.operationConf.timing1,
      });
    }
    if (data.areaConf && data.operationConf) {
      setNewServiceGraph({
        lPower1: data.areaConf.lPower1,
        rPower1: data.areaConf.rPower1,
        limitLPower: data.operationConf.limitLPower,
        limitRPower: data.operationConf.limitRPower,
        resetTiming: data.operationConf.resetTiming,
      });
    }
    if (data.operationConf) {
      setShiftOperation({
        line1: data.operationConf.line1,
        line2: data.operationConf.line2,
        line3: data.operationConf.line3,
        shiftConf: data.operationConf.shiftConf,
        shiftCm: data.operationConf.shiftCm,
      });
    }
    if (data.areaConf && data.conf) {
      if (data.conf.confDiv === COMMON.CONF_DIV.SAVE_CONF) {
        setIsLeft(data.conf.leftRight === COMMON.LEFT_RIGHT.LEFT);
      }
      setArmPowerInfoType({
        initialHeight: data.areaConf.initialHeight,
        leftArmSize: data.conf.leftArmSize,
        rightArmSize: data.conf.rightArmSize,
        lPower1: data.areaConf.lPower1,
        rPower1: data.areaConf.rPower1,
        armOpen: data.areaConf.armOpen,
        pushing1: data.areaConf.pushing1,
        descentLimit1: data.areaConf.descentLimit1,
        catcherDirection: data.conf.catcherDirection,
      });
    }
    if (data.pitConf) {
      setPitConf(data.pitConf);
    }
    if (data.areaConf) {
      setAreaConf(data.areaConf);
    }
  };

  // 選択中のステーション情報取得
  const getStationSelected = (configId: number) => {
    const station = stationList.find((item) => item.confId === configId);
    if (station) {
      setIsLeft(station.leftRight === COMMON.LEFT_RIGHT.LEFT);
      setStationHomeProps({
        giftName: station.giftName ?? COMMON.BLANK,
        stationName: station.stationName ?? COMMON.BLANK,
        machineType: station.machineType,
        machineVersion: station.machineVersion,
      });
    }
  };

  // 選択中の保存設定情報取得
  const getSavedConfInfo = (configId: number) => {
    const savedConfInfo = savedConfList.find((item) => Number(item.value) === configId);
    const version =
      savedConfInfo?.machineType === COMMON.MACHINE_TYPE.CLENA3
        ? getMachineVersion(savedConfInfo.machineVersion, true)
        : null;
    if (savedConfInfo) {
      setSavedConfProps({
        saveName: savedConfInfo.name,
        saveMachineVersion: version,
        saveMachineType: savedConfInfo.machineType,
      });
    }
  };

  useEffect(() => {
    const from = location.state !== undefined ? (location.state as PreviousPageType).from : '';
    setPreviousPage(from);
    const checkIsStationListScreen = from === redirectLinks.MCONF_STATION_SELECT;
    setIsStationListScreen(checkIsStationListScreen);
    if (checkIsStationListScreen) {
      setTextButtonBack('ステーション\n一覧');
    } else {
      setTextButtonBack('保存設定\n一覧');
    }
  }, [location, selectConfId]);

  useEffect(() => {
    const confId = Number(selectConfId);
    getStationSelected(confId);
    getSettingDetail(confId);
    getSavedConfInfo(confId);
  }, [selectConfId, stationList]);

  const handleClickReturn = () => {
    history.push(previousPage);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContentSettingDetail
          isStationListScreen={isStationListScreen}
          isLeft={isLeft}
          stationName={stationHomeProps.stationName}
          giftName={stationHomeProps.giftName}
          machineType={stationHomeProps.machineType}
          machineVersion={stationHomeProps.machineVersion}
          savedConfProps={savedConfProps}>
          <Box className={classes.container}>
            <Box className="leftArea">
              <ReturnButton onClick={() => handleClickReturn()}>
                <p className={classes.breakText}>{`${textButtonBack}`}</p>
              </ReturnButton>
              <Box className={classes.operationNewPayoutButton}>
                {isStationListScreen && (
                  <LeverManagement
                    isLeft={isLeft}
                    isleverOperation={levelManagement.levelStatus === COMMON.LEVER_STATUS.TRUE}
                    leverTime={levelManagement.levelTime}
                  />
                )}
                <OperationConf
                  title="運営設定"
                  payoutInfoSupport={payoutInfoSupport}
                  newServiceGraph={newServiceGraph}
                  shiftOperation={shiftOperation}
                  operationConf={operationConf}
                  areaConfHook={areaConfHook}
                  isLeft={isLeft}
                />
              </Box>
            </Box>
            <Box className="centerArea">
              <Box className={classes.xyConfButton}>
                <XyConfInfo
                  isLeft={isLeft}
                  title="移動範囲"
                  subtitle={'ホーム\nポジション'}
                  xySpeedLeverConf={xySpeedLeverConf}
                />
              </Box>
              <Box className="bottom">
                <Box className={classes.SpeedConfButton}>
                  <SpeedConfInfo
                    isLeft={isLeft}
                    title="ｷｬｯﾁｬｰ速度"
                    levelStatus={levelManagement.levelStatus}
                    speedXy={xySpeedLeverConf?.speedXy}
                    speedZ={xySpeedLeverConf?.speedZ}
                  />
                </Box>
                <Box className={classes.PitConfButton}>
                  <PitConfInfo
                    isLeft={isLeft}
                    title="エリア落とし穴"
                    pitConf={pitConf}
                    areaConf={areaConf}
                    operationConf={operationConf}
                    areaConfHook={areaConfHook}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="rightArea">
              <Box className={classes.ArmPowerButton}>
                {!areaConfHook.showTable && areaConfHook.showTable !== null && (
                  <ArmPowerInfo
                    isStationListScreen={isStationListScreen}
                    armPowerInfo={armPowerInfoType}
                    isLeft={isLeft}
                    title="アームパワー"
                    subtitle="キャッチャー詳細"
                  />
                )}
                {areaConfHook.showTable && <TableStationHomeDetail isLeft={isLeft} />}
              </Box>
            </Box>
          </Box>
        </MachineConfContentSettingDetail>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default StationHomeDetail;
