import { Box, makeStyles } from '@material-ui/core';
import { SelectButton } from 'components/system/atoms';
import { themeColor } from 'constants/theme';
import React from 'react';
import { colorEffectSelectAreaType } from 'types/machineConf/colorEffectSelectAreaType';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  selectArea: {
    width: '296px',
    fontSize: '19px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '4px 8px',
    '&.grayOut': {
      position: 'relative',
    },
  },
  buttonStyle: {
    width: '100%',
    height: '48px',
    fontSize: '19px',
    borderRadius: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '& div': {
      textAlign: 'left',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  buttonBox: {
    width: '85%',
    '&.effect': {
      position: 'relative',
    },
  },
  buttonLabel: {
    width: '15%',
  },
  colorBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
  },
  effectBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '4px',
    '&.reverse': {
      flexDirection: 'row-reverse',
    },
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  leftStyle: {
    backgroundColor: themeColor.leftLightColor,
    borderColor: themeColor.leftDarkColor,
  },
  rightStyle: {
    backgroundColor: themeColor.rightLightColor,
    borderColor: themeColor.rightDarkColor,
  },
}));

/**
 *
 * @Params
 * @returns {React.FC} - 色・効果 設定エリア
 *
 */
const ColorEffectSelectAreaJack: React.FC<colorEffectSelectAreaType> = React.memo((props) => {
  const { selectButtonLR, colorSelect, effectSelect, effectDisabled, disabled = false } = props;
  const classes = useStyles();
  const stationStyle = classes.rightStyle;

  return (
    <Box className={`${classes.selectArea} ${stationStyle} `}>
      <Box className={`${classes.colorBox}`}>
        <Box className={`${classes.buttonBox}`}>
          <SelectButton
            className={classes.buttonStyle}
            leftRight={selectButtonLR}
            onClick={colorSelect.selectButton.onClick}
            arrowSize="28px"
            isSelected={colorSelect.selectButton.isSelected}
            disabled={disabled}>
            {colorSelect.value}
          </SelectButton>
        </Box>
        <Box className={classes.buttonLabel}>色</Box>
      </Box>
      <Box className={`${classes.effectBox}`}>
        <Box className={`${classes.buttonBox} effect`}>
          <SelectButton
            className={classes.buttonStyle}
            leftRight={selectButtonLR}
            onClick={effectSelect.selectButton.onClick}
            arrowSize="28px"
            isSelected={effectSelect.selectButton.isSelected}
            disabled={effectDisabled || disabled}>
            {effectSelect.value}
          </SelectButton>
          {/* effectDisabledがtrueのときは効果を設定できないようにする */}
          {effectDisabled && <Box className={classes.disableInputArea} />}
        </Box>
        <Box className={classes.buttonLabel}>効果</Box>
      </Box>
    </Box>
  );
});

export default ColorEffectSelectAreaJack;
