import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useAppSelector } from 'redux/hooks';
import COMMON from 'constants/common';
import SelectedStationInfo from 'components/machineConf/organisms/labels/SelectedStationInfo';

// CSS定義
const useStyles = makeStyles((theme) => ({
  contents: {
    marginLeft: '104px',
    backgroundColor: theme.palette.common.black,
    padding: '0px 12px 12px 12px',
    height: '680px',
  },
  detail: {
    height: '668px',
    borderWidth: '8px',
    borderRadius: '8px',
    borderStyle: 'solid',
    width: '1144px',
    padding: '16px 0px 0px 0px',
  },
  leftDetail: {
    backgroundColor: theme.leftStation.contents,
    borderColor: theme.leftStation.contentsBorder,
  },
  rightDetail: {
    backgroundColor: theme.rightStation.contents,
    borderColor: theme.rightStation.contentsBorder,
  },
  selectStation: {
    margin: '0px 24px 0px 0px',
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * MachineConfContent 機器設定用コンテンツ
 * stationの左右で配色を変更
 *
 * @author atsushi.teruya
 * @Params {Props}  - なし childrenのみ
 * @returns {React.FC} - 機器設定用コンテンツ表示
 *
 */
const MachineConfContent: React.FC = ({ children }) => {
  const classes = useStyles();

  /* *************      hooks     ************* */
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const { selectedStation } = useAppSelector((state) => state.machineConf);

  /* *************  Local Functions  ************* */
  const getColorStyle = () => {
    let colorStyle = null;
    // クレナ3ジャックの場合は右ステーションのスタイルを当てる
    // クレナ3ジャック以外の場合は、ステーションの左右に合わせたスタイルを当てる
    if (selectedStation.machineType === COMMON.MACHINE_TYPE.JACK) {
      colorStyle = classes.rightDetail;
    } else if (leftRight === COMMON.LEFT_RIGHT.LEFT) {
      colorStyle = classes.leftDetail;
    } else {
      colorStyle = classes.rightDetail;
    }
    return colorStyle;
  };

  return (
    <>
      <Box className={classes.contents}>
        <Box className={`${classes.detail} ${getColorStyle()}`}>
          <Box className={classes.selectStation}>
            <SelectedStationInfo
              stationName={selectedStation.stationName}
              giftName={selectedStation.giftName}
              leftRight=""
              boardStation=""
              machineType={selectedStation.machineType}
              machineVersion={selectedStation.machineVersion}
            />
          </Box>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default MachineConfContent;
