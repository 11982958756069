import * as React from 'react';
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { BaseSyntheticEvent, memo, useEffect, useRef } from 'react';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { useAppDispatch } from 'redux/hooks';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.common.black,
    minHeight: '276px',
    width: '640px',
  },
  content: {
    alignItems: 'start',
    display: 'flex',
    paddingTop: '0!important',
    padding: 0,
  },
  textTitle: {
    fontSize: 24,
    color: 'white',
    marginLeft: '113px',
    marginTop: '36px',
    whiteSpace: 'pre-wrap',
  },
  linkDownload: {
    marginTop: 15,
    color: '#6FBEF3',
    cursor: 'pointer',
  },
  inputFileType: {
    marginLeft: 36,
    padding: '30px 0',
    overflow: 'hidden',
  },
  inputTextBox: {
    width: '570px',
    background: 'white',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  buttonUploadCsvInput: {
    height: '38px',
    marginLeft: 6,
    fontSize: 19,
    color: '#000000',
    minWidth: '180px',
  },
  action: {
    padding: '0px 40px 16px 40px ',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIcon: {
    height: 80,
    width: 200,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: '#FF7070',
    },
  },
  buttonCancel: {
    backgroundColor: '#BCBCBC',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: '#BCBCBC',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
}));

type DialogProps = {
  open: boolean;
  okBtnLabel?: string;
  cancelBtnLabel?: string;
  onOk: (file: File | undefined) => void;
  onCancel: () => void;
};

/* ************ CONSTANT ************ */
const TEMPLATE_FILE_NAME = 'template_gift.csv';

const CsvUploadDialog: React.FC<DialogProps> = ({
  open,
  onOk,
  okBtnLabel = '登録',
  onCancel,
  cancelBtnLabel = 'キャンセル',
}) => {
  const classes = useStyles();
  const [fileSelected, setFileSelected] = React.useState<File>();
  const [fileName, setFileName] = React.useState<string>('');
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  /* ************ API ************ */

  // データシートの取得
  const getTemplate = async () => {
    const http = new HttpConnection({ dispatch });
    const data = await http.getFile(REST_API.MAINTENANCE.GET_TEMPLATE);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', TEMPLATE_FILE_NAME);
    document.body.appendChild(link);
    link.click();
  };

  /* ************ useEffect ************ */

  useEffect(() => {
    setFileName('');
    setFileSelected(undefined);
  }, [open]);

  const handleUploadFileInputClick = () => {
    inputFile?.current?.click();
  };

  const onFileChange = (event: BaseSyntheticEvent) => {
    setFileSelected(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleResponseFile = () => {
    onOk(fileSelected);
  };

  const handleClickTemplate = () => {
    getTemplate();
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onCancel}
      maxWidth={false}>
      <DialogContent
        classes={{
          root: classes.content,
        }}>
        <Box>
          <Box className={classes.textTitle}>
            <Box>{`CSVファイルを添付し\n登録ボタンを押してください。`}</Box>
            <Box className={classes.linkDownload} onClick={handleClickTemplate}>
              CSVファイルのテンプレートはこちら
            </Box>
          </Box>
          <Box>
            <Box className={classes.inputFileType}>
              <Box className={classes.inputTextBox}>
                <NormalButton className={classes.buttonUploadCsvInput} onClick={handleUploadFileInputClick}>
                  ファイルを添付
                </NormalButton>
                <span>{fileName}</span>
              </Box>
              <input id="inputCsvFile" type="file" hidden ref={inputFile} accept=".csv" onChange={onFileChange} />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.action,
        }}>
        <NormalButton onClick={handleResponseFile} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {okBtnLabel}
        </NormalButton>
        <NormalButton onClick={onCancel} className={`${classes.buttonIcon} ${classes.buttonCancel}`}>
          {cancelBtnLabel}
        </NormalButton>
      </DialogActions>
    </Dialog>
  );
};

export default memo(CsvUploadDialog);
