import React, { MouseEventHandler, TouchEventHandler, useEffect, useRef, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Box } from '@material-ui/core';
import { PitConfType } from 'types/machineConf/machineConfType';
import { to2DArrayPit, toPitConfFrom2DArray } from 'utils/machineConf.helper';
import CLSFCN from 'constants/classification';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import pitCrossIcon from 'assets/images/pitCrossIcon.svg';

type Props = {
  pitConf: PitConfType;
  onChange: (newPitConf: PitConfType) => void;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    touchAction: 'none',
    '& *': {
      touchAction: 'none',
    },
  },
  container: {
    width: '100%',
    height: '100%',
  },
  table: {
    width: '100%',
    height: '100%',
    borderCollapse: 'collapse',

    '& tr:nth-child(odd)': {
      borderTop: `2px solid ${theme.base.tableBorder}`,
      borderBottom: (props: Props) =>
        props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL
          ? `2px dashed ${theme.base.tableBorder}`
          : `2px solid ${theme.base.tableBorder}`,
    },
    '& tr:nth-child(even)': {
      borderTop: (props: Props) =>
        props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL
          ? `2px dashed ${theme.base.tableBorder}`
          : `2px solid ${theme.base.tableBorder}`,
      borderBottom: `2px solid ${theme.base.tableBorder}`,
    },
  },
  tableRow: {
    height: (props: Props) => (props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL ? '16.67%' : '33.34%'),

    '& td:nth-child(odd)': {
      borderLeft: `2px solid ${theme.base.tableBorder}`,
      borderRight: (props: Props) =>
        props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL
          ? `2px dashed ${theme.base.tableBorder}`
          : `2px solid ${theme.base.tableBorder}`,
    },
    '& td:nth-child(even)': {
      borderLeft: (props: Props) =>
        props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL
          ? `2px dashed ${theme.base.tableBorder}`
          : `2px solid ${theme.base.tableBorder}`,
      borderRight: `2px solid ${theme.base.tableBorder}`,
    },
  },
  tableCell: {
    padding: '0',
    width: (props: Props) => (props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL ? '16.67%' : '33.34%'),
  },
  pitBlock: {
    width: '100%',
    height: '100%',
    padding: (props: Props) => (props.pitConf.settingPattern === CLSFCN.PIT_CONFIG.PATTERN.DETAIL ? '8px' : '16px'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pitIcon: {
    pointerEvents: 'none',
  },
}));

// TouchMove中はこのコンポーネント内部のステートを切り替えるのみとする
// TouchEnd時にonChangeを介して渡された基のステートを更新する

const PitPanel: React.FC<Props> = (props) => {
  const { pitConf, onChange, className } = props;
  const classes = useStyles(props);
  const [pits, setPits] = useState(to2DArrayPit(pitConf));
  const inputMode = useRef<'0' | '1' | null>(null);
  const mouseEventSkip = useRef<boolean>(false);

  useEffect(() => {
    setPits(to2DArrayPit(pitConf));
  }, [pitConf.settingPattern]);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (mouseEventSkip.current) return;
    inputMode.current = null;
    updatePanel(event.clientX, event.clientY);
  };

  const handleTouchStart: TouchEventHandler<HTMLDivElement> = (event) => {
    mouseEventSkip.current = true;
    // ※TouchMoveイベントは指を離すまで一番最初にタッチしたコンポーネントに対して発生し続ける
    inputMode.current = null;
    handleTouchMove(event);
  };

  const handleTouchMove: TouchEventHandler<HTMLDivElement> = (event) => {
    // ※TouchMoveイベントは指を離すまで一番最初にタッチしたコンポーネントに対して発生し続ける
    const touch = event.changedTouches[0];
    updatePanel(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    // ※PCとモバイルでイベントの発生仕方が異なる
    //   PC：マウスダウンした要素に関係なく、単純にマウスアップした要素でイベントが発生する→MouseUpだけでは編集終了を捕捉できないのでMouseLeaveも追加
    //   モバイル：要素内でタッチ開始すれば要素外でタッチ終了してもタッチ開始した要素でTouchEndイベントが発生する
    inputMode.current = null;
    if (pitConf) {
      const temp = toPitConfFrom2DArray(pitConf, pits);
      onChange(temp);
    }
  };

  const updatePanel = (clientX: number, clientY: number) => {
    // タッチしている落とし穴を取得
    const element = document.elementFromPoint(clientX, clientY) as HTMLDivElement;
    if (element && element.dataset.value) {
      // 最初にタッチした落とし穴の値を反転した値を入力モードとして設定
      if (inputMode.current == null) {
        inputMode.current = element.dataset.value === '1' ? '0' : '1';
      }

      // 現在の入力モードとタッチしている落とし穴の値が違ったら落とし穴の値を変更
      const x = Number(element.dataset.x);
      const y = Number(element.dataset.y);
      if (inputMode.current !== pits[y][x]) {
        const newone = pits.map((row) => row.slice());
        newone[y][x] = inputMode.current;
        setPits(newone);
      }
    }
  };

  return (
    <Box
      className={`${classes.root} ${className}`}
      onMouseDown={handleClick}
      onMouseLeave={handleTouchEnd}
      onMouseUp={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <TableContainer className={classes.container}>
        <Table className={classes.table}>
          <TableBody>
            {pits.map((row, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow className={classes.tableRow} key={i}>
                {row.map((value, j) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell className={classes.tableCell} key={j}>
                    <Box data-x={j} data-y={i} data-value={value} className={classes.pitBlock}>
                      {value === '1' && <img src={pitMarkIcon} alt="○" className={classes.pitIcon} />}
                      {value !== '1' && <img src={pitCrossIcon} alt="×" className={classes.pitIcon} />}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PitPanel;
