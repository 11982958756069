import React, { useCallback, useContext, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Header, Menu } from 'components/common/organisms';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { checkScreenAuth } from 'utils/common.helper';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ERROR_CODE from 'constants/errorCode';
import ErrorBoundary from './ErrorBoundary';
import Loading from '../organisms/Loading';
import Content from '../organisms/contents/Content';

// CSS定義
const useStyles = makeStyles((theme) => ({
  menu: {
    float: 'left',
    backgroundColor: theme.palette.common.white,
    width: '104px',
    height: '100%',
    fontSize: '20px',
  },
}));

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const screenContext = useContext(ScreenContext);
  const { loading, errorInfo } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      screenContext.screenId !== 'SCR011' &&
      !errorInfo.isUnauthorizedError &&
      !checkScreenAuth(screenContext.screenId)
    ) {
      // システムエラーフラグを立て、Error Boundaryでエラー処理を引き起こす。
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.SCR_AUTH_ERROR }));
    }
  }, []);

  /* ************ use effect ************ */
  useEffect(() => {
    window.addEventListener('beforeunload', setIsFreshBrowser);
    return () => {
      window.removeEventListener('beforeunload', setIsFreshBrowser);
    };
  }, []);

  const setIsFreshBrowser = useCallback(() => {
    sessionStorage.setItem('isFresh', 'true');
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Loading open={loading} />
        <Box className={classes.menu}>
          <Menu />
        </Box>
        <Header />
        {/** disableCotentsを指定する場合はcontentの適用を解除 */}
        {!screenContext.disableContents ? <Content>{children}</Content> : <>{children}</>}
      </ErrorBoundary>
    </>
  );
};

export default Layout;
