import { makeStyles, SvgIcon } from '@material-ui/core';
import React, { FC, memo } from 'react';

type Props = {
  size?: number;
  color?: string;
  style?: {
    fontSize: string;
  };
};

const useStyles = makeStyles(() => ({
  root: {
    // アイコンサイズを指定, materiau-ui のrootを上書き
    fontSize: '32px',
  },
}));

const CheckedIcon: FC<Props> = (props) => {
  const { size } = props;
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.root }}>
      <svg id="CheckedIcon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
        <g id="グループ_390" data-name="グループ 390" transform="translate(-12 -13)">
          <g id="GroupSelectBox" transform="translate(12 13)" fill="#fff" stroke="#000" strokeWidth="1">
            <rect width="48" height="48" rx="8" stroke="none" />
            <rect x="0.5" y="0.5" width="47" height="47" rx="7.5" fill="none" />
          </g>
          <g id="surface42624651" transform="translate(12 12)">
            <path
              fillRule="nonzero"
              fill="#4EC754"
              fillOpacity="1"
              strokeWidth="8"
              strokeLinecap="butt"
              strokeLinejoin="round"
              stroke="#000000"
              strokeOpacity="1"
              strokeMiterlimit="10"
              d="M 156 56 L 60 148 L 16 96 L 36 76 L 60 108 L 136 36 Z M 156 56 "
              transform="matrix(0.263721,0,0,0.263721,1.32,1.32)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default memo(CheckedIcon);
