import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import { makeStyles, Box, Typography } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import arrowIcon from 'assets/images/arrowIcon.svg';
import arrowIconSplitRight from 'assets/images/arrowIconSplitRight.svg';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import { NormalSwitch, SelectButton } from 'components/system/atoms';
import { SelectListType, TenkeyOpenEventType, TenkeyType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import MESSAGES from 'constants/messages';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import {
  setAreaConf,
  setLedServiceOtherConf,
  setOperationConf,
  setOperationConfModeOff,
} from 'redux/slices/machineConfSlice';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';
import NewTenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/NewTenkey';
import { AreaConfType, LedServiceOtherConfType, OperationConfType } from 'types/machineConf/machineConfType';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import { resetDescentLimitValueJack } from 'utils/resetDescentLimitJack.helper';
import { InfoDialog } from 'components/system/organisms';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'プレイヤーサポート設定',
  screenId: COMMON.SCREEN_ID.NEW_PAYOUT_SUPPORT_JACK,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ********** Constants ********** */
const INPUT_NAMES = {
  MOD_SETTING: 'modeSetting',
  SUPPORT: {
    GIFT_COST: 'giftCost',
    TARGET_COST_RATE: 'targetCostRate',
  },
  GRAB: {
    ARM_OPEN: 'armOpen',
    L_POWER: 'grabLPower',
    R_POWER: 'grabRPower',
  },
  BACK: {
    HEIGHT: 'backHeight',
    TIMING: 'backTiming',
  },
  BASIC: {
    L_POWER_1: 'lPower1',
    R_POWER_1: 'rPower1',
  },
  MOVING: {
    L_POWER: 'movingLPower',
    R_POWER: 'movingRPower',
  },
};
const TENKEY_POWER = {
  MIN: 0.1,
  MAX: 150,
};
const TENKEY_GIFT_RATE = {
  MIN: 0,
  MAX: 1100,
};
const TENKEY_OPTION = {
  ARM_POWER: {
    mode: COMMON.TENKEY_MODE.DECIMAL,
    integerSize: 3,
    scaleSize: 1,
    maxValue: TENKEY_POWER.MAX,
    minValue: TENKEY_POWER.MIN,
    decimalLimit: 50,
    decimalLimit2: 100,
  },
  GIFT_COST: {
    mode: COMMON.TENKEY_MODE.INTEGER,
    integerSize: 4,
    scaleSize: 0,
    maxValue: TENKEY_GIFT_RATE.MAX,
    minValue: TENKEY_GIFT_RATE.MIN,
  },
};

/* ********** create data ********** */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    margin: '18px 24px',
    minHeight: '563px',
    minWidth: '1080px',
  },
  topArea: {
    display: 'flex',
    height: '88px',
    '& .modeArea': {
      position: 'absolute',
      top: '0px',
      left: '232px',
      height: '72px',
      width: '272px',
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0px 0px 18px',
      fontSize: '20px',
      zIndex: 200,
    },
    '& .description': {
      position: 'absolute',
      top: '0px',
      left: '528px',
      fontSize: '20px',
      padding: '8px 0 0',
    },
  },
  mainArea: {
    display: 'flex',
    '& .basicArmArea': {
      height: '180px',
      width: '352px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 12px 16px 12px',
      },
    },
    '& .glabToBasicArrow': {
      position: 'absolute',
      height: '140px',
      width: '72px',
      top: '232px',
      left: '140px',
      zIndex: 1,
    },
    '& .grabArmArea': {
      position: 'absolute',
      top: '344px',
      left: '0px',
      height: '220px',
      width: '352px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 12px 4px 12px',
      },
      '& .main': {
        margin: '26px 0px 0px 0px',
        '& .armOpen': {
          margin: '48px 0px 0px 0px',
        },
      },
    },
    '& .basicToMovingArrow': {
      position: 'absolute',
      top: '112px',
      left: '312px',
      height: '72px',
      width: '140px',
      zIndex: 1,
    },
    '& .movingArmArea': {
      position: 'absolute',
      top: '88px',
      left: '444px',
      justifyContent: 'space-between',
      height: '180px',
      width: '352px',
      '& .itemArea': {
        height: '100%',
        justifyContent: 'space-between',
        padding: '8px 12px 16px 12px',
      },
    },
    '& .backBasicArea': {
      position: 'absolute',
      top: '276px',
      left: '360px',
      justifyContent: 'space-between',
      height: '116px',
      margin: '8px 0px 0px 0px',
      padding: '12px 8px 12px 8px',
      width: '437px',
      '& .main': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '100%',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '16px',
        right: '100%',
        borderWidth: '22px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightWidth: '156px',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '18px',
        right: '100%',
        borderWidth: '20px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightWidth: '150px',
      },
      '& .height': {
        margin: '0px 32px 0px 12px',
      },
      '& .buttonTiming': {
        margin: '0px 0px 0px 12px',
        fontSize: '17px',
      },
    },
    '& .getSupportArea': {
      position: 'absolute',
      top: '88px',
      left: '836px',
      justifyContent: 'flex-start',
      height: '360px',
      padding: '16px 0px 0px 12px',
      width: '244px',
      '& .top': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      },
      '& .main': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        width: '100%',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '16px',
        right: '100%',
        borderWidth: '22px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderTopWidth: '0px',
        borderBottomWidth: '40px',
        borderRightWidth: '80px',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '18px',
        right: '100%',
        borderWidth: '20px',
        borderStyle: 'solid',
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        borderTopWidth: '0px',
        borderBottomWidth: '35px',
        borderRightWidth: '72px',
      },
      '& .description': {
        margin: '16px 0px 0px 0px',
        fontSize: '16px',
        lineHeight: '24px',
        height: '98px',
      },
      '& .getSupportInnerArea': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '20px',
        justifyContent: 'flex-start',
        height: '76px',
        paddingLeft: '16px',
        width: '220px',
        '& .label': {
          margin: '0px 20px 0px 0px',
        },
        '& .postLabel': {
          alignItems: 'flex-end',
          display: 'flex',
          margin: '0px 0px 24px 4px',
          height: '100%',
        },
      },
    },
    '& .doneArea': {
      position: 'absolute',
      top: '462px',
      left: '900px',
    },
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  baseArea: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  titleBaseArea: {
    minWidth: 140,
  },
  baseAreaFlex: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
  },
  baseAreaArm: {
    '& .main': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .bottom': {
      margin: 'auto 0px 4px 0px',
    },
    '& .icon': {
      position: 'absolute',
      top: '24px',
      left: '126px',
    },
  },
  returnButton: {
    fontSize: '20px',
    lineHeight: '28px',
    zIndex: 200,
  },
  payoutSupport: {
    margin: '0px 0px 0px 12px',
    width: '80px',
  },
  decisionImage: {
    borderRadius: '4px',
    fontSize: '19px',
    height: '32px',
    padding: '2px 0px',
    width: '60px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  caption: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  selectButton: {
    width: '100px',
    height: '56px',
    fontSize: '24px',
  },
  giftCostArea: {
    margin: '16px 0px 0px 0px',
  },
  targetCostArea: {
    margin: '8px 0px 0px 0px',
  },
  rightStyle: {
    backgroundColor: '#fff',
    borderColor: theme.rightStation.itemBorder,
    '&:before': {
      borderRightColor: theme.rightStation.itemBorder,
    },
    '&:after': {
      borderRightColor: '#fff',
    },
  },
}));

/* ******** Main Component ******** */
/**
 * NewPayoutSupportConf Screen プレイヤーサポート画面
 *
 * @author atsushi.teruya
 * @returns {React.FC} - プレイヤーサポート画面
 *
 */
const NewPayoutSupportConfJack: React.FC = () => {
  /* ************ state/redux ************ */
  const dispatch = useAppDispatch();

  /* ************ state ************ */
  // global (Redux)
  const leftRight = COMMON.LEFT_RIGHT.RIGHT;
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const ledServiceOtherConf = useAppSelector((state) => state.machineConf.machineConf.ledServiceOtherConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  // local
  const [tempOperationConf, setTempOperationConf] = useState<OperationConfType | undefined>(operationConf);
  const [tempLedServiceOtherConf, setTempLedServiceOtherConf] = useState<LedServiceOtherConfType | undefined>(
    ledServiceOtherConf,
  );
  const [tempAreaConf, setTempAreaConf] = useState<AreaConfType | undefined>(areaConf);
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tenkey, setTenkey] = useState(INITIAL_TEN_KEY.tenkey);
  const [openConfirmDialogModeSetting, setOpenConfirmDialogModeSetting] = useState(false);
  const [openConfirmDialogModeSettingOff, setOpenConfirmDialogModeSettingOff] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  // dataSource
  const dataSouceBackHeight = useRef(getClsfcnByCategory(CLSFCN.HEIGHT.CATEGORY_CD));
  const dataSouceBackTiming = useRef(getClsfcnByCategory(CLSFCN.TIMING.CATEGORY_CD));
  const dataSouceGrabArmOpenList = useRef(getClsfcnByCategory(CLSFCN.ARM_OPEN.CATEGORY_CD));
  const dataSouceTargetCostRate = useRef(getClsfcnByCategory(CLSFCN.NEW_PAYOUT_TARGET_COST_RATE.CATEGORY_CD));

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();
  const stationStyle = classes.rightStyle;

  const isNewPayoutSupportDisable = tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT;

  /* ************ Event ************ */
  useEffect(() => {
    if (!operationConf || !areaConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  const handleCloseTenkey = useCallback(() => setTenkey(INITIAL_TEN_KEY.tenkey), []);

  // Tenkey 決定（operationConf）
  const handleDecision = useCallback(
    (value: string, name: string) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempOperationConf],
  );

  const handleDecisionLed = useCallback(
    (value: string, name: string) => {
      setTempLedServiceOtherConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempLedServiceOtherConf],
  );

  // Tenkey 決定（areaConf）
  const handleDecisionArea = useCallback(
    (value: string, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempAreaConf],
  );

  const initialTenkeyArmPower: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleDecision,
    onClose: handleCloseTenkey,
  };

  // Tenkey - BasicLPower1
  const handleOpenBasicLPower1: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 120 },
      name: INPUT_NAMES.BASIC.L_POWER_1,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempAreaConf?.lPower1) || '0',
      onDecision: handleDecisionArea,
    });
  }, [tempAreaConf?.lPower1]);

  // Tenkey - BasicRPower1
  const handleOpenBasicRPower1: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 350 },
      name: INPUT_NAMES.BASIC.R_POWER_1,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempAreaConf?.rPower1) || '0',
      onDecision: handleDecisionArea,
    });
  }, [tempAreaConf?.rPower1]);

  // Tenkey - GrabLPower
  const handleOpenGrabLPower: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 120 },
      name: INPUT_NAMES.GRAB.L_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.grabLPower) || '0',
      onDecision: handleDecision,
    });
  }, [tempOperationConf?.grabLPower]);

  // Tenkey - GrabRPower

  const handleOpenGrabRPower: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 350 },
      name: INPUT_NAMES.GRAB.R_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.grabRPower) || '0',
      onDecision: handleDecision,
    });
  }, [tempOperationConf?.grabRPower]);

  // Tenkey - MovingLPower
  const handleOpenMovingLPower: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 565 },
      name: INPUT_NAMES.MOVING.L_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.movingLPower) || '0',
      onDecision: handleDecision,
    });
  }, [tempOperationConf?.movingLPower]);

  // Tenkey - MovingRPower

  const handleOpenMovingRPower: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 255 },
      name: INPUT_NAMES.MOVING.R_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.movingRPower) || '0',
      onDecision: handleDecision,
    });
  }, [tempOperationConf?.movingRPower]);

  // Tenkey - GiftCost

  const handleOpenGiftCost: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialTenkeyArmPower,
      anchorPosition: { top: 100, left: 485 },
      name: INPUT_NAMES.SUPPORT.GIFT_COST,
      option: TENKEY_OPTION.GIFT_COST,
      value: String(tempLedServiceOtherConf?.giftCost || '0'),
      onDecision: handleDecisionLed,
    });
  }, [tempLedServiceOtherConf?.giftCost]);

  // return button change
  const clickReturn = () => {
    history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
  };

  // No3-1. モード設定 変更 ダイアログ表示
  const handleChangeSwitchOperationConf = useCallback(
    // ダイアログを表示する。
    (event) => {
      if (event.target.checked) {
        setOpenConfirmDialogModeSetting(true);
      } else {
        setOpenConfirmDialogModeSettingOff(true);
      }
    },
    [tempOperationConf?.operationConf],
  );

  // No3-2. モード設定 変更 Dialog -> Yes
  const onClickOkModeSetting = useCallback(() => {
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            operationConf: '1',
          }
        : prevState,
    );
    handleCloseConfirmDialogModeSetting();
  }, [tempOperationConf?.operationConf]);

  // No3-2. モード設定 変更 Dialog -> No
  const handleCloseConfirmDialogModeSetting = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSetting(false);
  };

  // モード設定 off Dialog -> Yes
  const onClickOkModeSettingOff = useCallback(async () => {
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    const newTempAreaConf = resetDescentLimitValueJack(areaConf);
    // 運営設定「設定なし」をグローバルステートに保存
    dispatch(setOperationConfModeOff(COMMON.OPERATION_CONF.NO_CONF));

    if (!newTempAreaConf?.hasReset) {
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF).then(() => {
        // 画面遷移
        history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
      });
    } else {
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });
      setOpenConfirm(true);
      // reset DescentLimit
      dispatch(setAreaConf(areaConfReset));
    }
  }, []);

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
  };

  // モード設定 off Dialog -> No
  const handleCloseConfirmDialogModeSettingOff = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSettingOff(false);
  };

  // No3-2. モード設定 変更 ダイアログOK
  const handleOnChangeSwitchGetSupport = useCallback(
    (event) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              getSupport: event.target.checked ? '1' : '0',
            }
          : prevState,
      );
    },
    [tempOperationConf?.getSupport],
  );

  // select list (operationConf)
  const handleClickSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempOperationConf],
  );

  const handleClickSelectListTargetCostRate = useCallback(
    (data: { value: string }, name: string) => {
      setTempLedServiceOtherConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempLedServiceOtherConf],
  );

  // select list (areaConf)
  const handleClickAreaSelectList = useCallback(
    (data: { value: string }, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: data.value,
            }
          : prevState,
      );
      setSelectList(INITIAL_SELECT_LIST.selectList);
    },
    [tempAreaConf],
  );

  const handleCloseSelectList = useCallback(() => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  const initialSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickSelectList,
    onClose: handleCloseSelectList,
  };

  const handleClickBackHeight = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSouceBackHeight.current,
        name: INPUT_NAMES.BACK.HEIGHT,
        placement: 'right-end',
        title: '高さを選択してください。',
        value: tempOperationConf?.backHeight,
      });
    },
    [tempOperationConf?.backHeight],
  );

  const handleClickBackTiming = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSouceBackTiming.current,
        name: INPUT_NAMES.BACK.TIMING,
        placement: 'left',
        title: 'タイミングを選択してください。',
        value: tempOperationConf?.backTiming,
      });
    },
    [tempOperationConf?.backTiming],
  );

  const handleClickGrabArmOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        anchorEL: event.currentTarget,
        dataSource: dataSouceGrabArmOpenList.current,
        name: INPUT_NAMES.GRAB.ARM_OPEN,
        placement: 'right-end',
        title: 'アーム開度を選択してください。',
        value: tempAreaConf?.armOpen,
        onClick: handleClickAreaSelectList,
      });
    },
    [tempAreaConf?.armOpen],
  );

  const handleClickTargetCostRate = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialSelectList,
        onClick: handleClickSelectListTargetCostRate,
        anchorEL: event.currentTarget,
        dataSource: dataSouceTargetCostRate.current,
        name: INPUT_NAMES.SUPPORT.TARGET_COST_RATE,
        placement: 'left-end',
        title: '目標原価率を選択してください。',
        value: tempLedServiceOtherConf?.targetCostRate,
      });
    },
    [tempLedServiceOtherConf?.targetCostRate],
  );

  // No16. 確定ボタンクリック
  const onClickOk = async () => {
    // validationチェック及びセッション更新
    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.NEWPAYOUTSUPPORT_CONF_VALIDATION_JACK, {
        armOpen: tempAreaConf?.armOpen,
        lPower1: tempAreaConf?.lPower1,
        rPower1: tempAreaConf?.rPower1,
        operationConf: tempOperationConf?.operationConf,
        grabLPower: tempOperationConf?.grabLPower,
        grabRPower: tempOperationConf?.grabRPower,
        movingLPower: tempOperationConf?.movingLPower,
        movingRPower: tempOperationConf?.movingRPower,
        backHeight: tempOperationConf?.backHeight,
        backTiming: tempOperationConf?.backTiming,
        getSupport: tempOperationConf?.getSupport,
        giftCost: tempLedServiceOtherConf?.giftCost,
        targetCostRate: tempLedServiceOtherConf?.targetCostRate,
      })
      .then(() => {
        // エリア設定をグローバルステートに保存
        dispatch(setAreaConf(tempAreaConf));
        // 運営設定をグローバルステートに保存
        dispatch(setOperationConf(tempOperationConf));
        dispatch(setLedServiceOtherConf(tempLedServiceOtherConf));
        // 画面遷移
        history.push(redirectLinks.MCONF_JACK_CONF_GAME_CONF_TOP);
      });
  };

  // 運営設定－プレイヤーサポート モード ON OFF表示
  const displayOperationConfChecked = () =>
    tempOperationConf?.operationConf === COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT ?? false;

  const displayGetSupportChecked = () => tempOperationConf?.getSupport === COMMON.SWITCH.ON ?? false;

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.content}>
            <Box className={classes.topArea}>
              <ReturnButton className={classes.returnButton} onClick={clickReturn}>
                ゲーム設定
                <br />
                TOP
              </ReturnButton>
              <Box className={`${classes.baseArea} modeArea ${stationStyle}`}>
                <p className={classes.titleBaseArea}>
                  プレイヤー
                  <br />
                  サポート
                </p>
                <NormalSwitch
                  className={classes.payoutSupport}
                  leftRight={leftRight}
                  value=""
                  checked={displayOperationConfChecked()}
                  onChange={handleChangeSwitchOperationConf}
                />
              </Box>
              <p className="description">
                景品の獲得をサポートします <br />
                各種設定をし、<Typography className={classes.decisionImage}>確 定</Typography> してください
              </p>
            </Box>
            {/* アームパワーなどの入力エリア */}
            <Box className={classes.mainArea}>
              {/* 左側の入力エリア */}
              {/* 基本入力エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaArm} basicArmArea ${stationStyle}`}>
                {/* アームパワーアイコン 基本 */}
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>
                    基本の
                    <br />
                    アームパワー
                  </p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.BASIC.L_POWER_1}
                      leftRight={leftRight}
                      value={String(tempAreaConf?.lPower1 || '')}
                      onOpen={handleOpenBasicLPower1}
                    />
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.BASIC.R_POWER_1}
                      leftRight={leftRight}
                      value={String(tempAreaConf?.rPower1 || '')}
                      onOpen={handleOpenBasicRPower1}
                    />
                  </Box>
                  <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                </Box>
              </Box>
              {/* 矢印アイコン 基本<==つかみ */}
              <img src={arrowIcon} alt="arrowIcon" className="glabToBasicArrow" />
              {/* つかみ入力エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaArm} grabArmArea ${stationStyle}`}>
                {/* アームパワーアイコン つかみ */}
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>
                    つかみ時の
                    <br />
                    アームパワー
                  </p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.GRAB.L_POWER}
                      leftRight={leftRight}
                      value={String(tempOperationConf?.grabLPower || '')}
                      onOpen={handleOpenGrabLPower}
                    />
                    <SelectButton
                      disabled={isNewPayoutSupportDisable}
                      leftRight={leftRight}
                      className={`${classes.selectButton} armOpen`}
                      onClick={handleClickGrabArmOpen}
                      isSelected={selectList.name === INPUT_NAMES.GRAB.ARM_OPEN}>
                      {tempAreaConf?.armOpen &&
                        getClassificationName(CLSFCN.ARM_OPEN.CATEGORY_CD, tempAreaConf.armOpen)}
                    </SelectButton>
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.GRAB.R_POWER}
                      leftRight={leftRight}
                      value={String(tempOperationConf?.grabRPower || '')}
                      onOpen={handleOpenGrabRPower}
                    />
                    <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                  </Box>
                  <p className={`${classes.caption}`}>アーム開度</p>
                </Box>
              </Box>
              {/* 矢印アイコン 基本<==移動 */}
              <img src={arrowIconSplitRight} alt="arrowIconSplitRight" className="basicToMovingArrow" />
              {/* 真ん中の入力エリア */}
              {/* 移動入力エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaArm} movingArmArea ${stationStyle}`}>
                {/* アームパワーアイコン 移動時 */}
                <Box className={`${classes.baseAreaFlex} itemArea`}>
                  <p className={classes.caption}>
                    移動時の
                    <br />
                    アームパワー
                  </p>
                  <Box className="main">
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.MOVING.L_POWER}
                      leftRight={leftRight}
                      value={String(tempOperationConf?.movingLPower || '')}
                      onOpen={handleOpenMovingLPower}
                    />
                    <TenkeyInput
                      disabled={isNewPayoutSupportDisable}
                      isSelected={tenkey.name === INPUT_NAMES.MOVING.R_POWER}
                      leftRight={leftRight}
                      value={String(tempOperationConf?.movingRPower || '')}
                      onOpen={handleOpenMovingRPower}
                    />
                  </Box>
                  <img src={armPowerIcon} alt="armPowerIcon" className="icon" />
                </Box>
              </Box>
              {/* 基本戻る入力エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaFlex} ${stationStyle} backBasicArea`}>
                <p className={classes.caption}>基本のアームパワーに戻るまでの</p>
                <Box className="main">
                  <p className={classes.caption}>高さ</p>
                  <SelectButton
                    disabled={isNewPayoutSupportDisable}
                    className={`${classes.selectButton} height`}
                    leftRight={leftRight}
                    onClick={handleClickBackHeight}
                    isSelected={selectList.name === INPUT_NAMES.BACK.HEIGHT}>
                    {tempOperationConf?.backHeight != null &&
                      getClassificationName(CLSFCN.HEIGHT.CATEGORY_CD, tempOperationConf.backHeight)}
                  </SelectButton>
                  <p className={classes.caption}>タイミング</p>
                  <SelectButton
                    disabled={isNewPayoutSupportDisable}
                    className={`${classes.selectButton} buttonTiming`}
                    leftRight={leftRight}
                    onClick={handleClickBackTiming}
                    isSelected={selectList.name === INPUT_NAMES.BACK.TIMING}>
                    {tempOperationConf?.backTiming &&
                      getClassificationName(CLSFCN.TIMING.CATEGORY_CD, tempOperationConf?.backTiming || '0')}
                  </SelectButton>
                </Box>
              </Box>
              {/* 右側の入力エリア */}
              {/* 獲得サポート */}
              {/* 基本戻る入力エリア */}
              <Box className={`${classes.baseArea} ${classes.baseAreaFlex} getSupportArea ${stationStyle}`}>
                <Box className="top">
                  <p className={classes.caption}>獲得サポート</p>
                  <NormalSwitch
                    disabled={isNewPayoutSupportDisable}
                    leftRight={leftRight}
                    value="1"
                    checked={displayGetSupportChecked()}
                    onChange={handleOnChangeSwitchGetSupport}
                  />
                </Box>

                <Box className="description">
                  目標原価率を上回っても <br />
                  景品獲得できない場合 <br />
                  「つかみ時のアームパワー」 <br /> のまま獲得をサポートします
                </Box>
                <Box className={`${classes.baseArea} getSupportInnerArea ${classes.giftCostArea} ${stationStyle}`}>
                  <Box className="label">
                    景品
                    <br />
                    原価
                  </Box>
                  <TenkeyInput
                    disabled={isNewPayoutSupportDisable}
                    isSelected={tenkey.name === INPUT_NAMES.SUPPORT.GIFT_COST}
                    leftRight={leftRight}
                    value={tempLedServiceOtherConf?.giftCost}
                    onOpen={handleOpenGiftCost}
                  />
                  <Box className="postLabel">円</Box>
                </Box>
                <Box className={`${classes.baseArea} getSupportInnerArea ${classes.targetCostArea} ${stationStyle}`}>
                  <Box className="label">
                    目標
                    <br />
                    原価率
                  </Box>
                  <SelectButton
                    disabled={isNewPayoutSupportDisable}
                    leftRight={leftRight}
                    onClick={handleClickTargetCostRate}
                    isSelected={selectList.name === INPUT_NAMES.SUPPORT.TARGET_COST_RATE}>
                    {tempLedServiceOtherConf?.targetCostRate &&
                      getClassificationName(
                        CLSFCN.NEW_PAYOUT_TARGET_COST_RATE.CATEGORY_CD,
                        tempLedServiceOtherConf.targetCostRate,
                      )}
                  </SelectButton>
                </Box>
              </Box>
              <Box className="doneArea">
                <DoneButton disabled={isNewPayoutSupportDisable} onClick={onClickOk}>
                  確 定
                </DoneButton>
              </Box>
            </Box>
            {tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_PAYOUT_SUPPORT && (
              <Box className={classes.disableInputArea} />
            )}
          </Box>
          <SelectList selectList={selectList} />
          <NewTenkey tenkey={tenkey} />
          <ConfirmDialog
            open={openConfirmDialogModeSetting}
            msg={MESSAGES.INF217}
            onOk={onClickOkModeSetting}
            onCancel={handleCloseConfirmDialogModeSetting}
          />
          <ConfirmDialog
            open={openConfirmDialogModeSettingOff}
            msg={`${MESSAGES.INF218}`}
            onOk={onClickOkModeSettingOff}
            onCancel={handleCloseConfirmDialogModeSettingOff}
          />
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default NewPayoutSupportConfJack;
