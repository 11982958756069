import React from 'react';
import { makeStyles, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { AreaSimplePitRowType, AreaSimplePitType } from 'types/machineConf/areaType';
import COMMON from 'constants/common';
import pitMarkIcon from 'assets/images/pitMarkIcon.svg';
import { toArrayPitLine, toArraySimplePitLine, toSimplePitLineData } from 'utils/machineConf.helper';
import CLSFCN from 'constants/classification';

/* ************* constant ************* */
const tableArrayRender = [0, 1, 2];

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  tableRoot: {
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    border: '3px solid',
    borderColor: 'transparent',
    '& td': {
      padding: 0,
      position: 'relative',
      borderRight: '2px solid transparent',
      borderBottom: '2px solid transparent',
      '& img': {
        transform: 'scale(0.8)',
      },
    },
  },
  smallSimplePit: {},
  mediumSimplePit: {},
  largeSimplePit: {},
  smallContainer: {
    top: '-8px',
    left: '-8px',
    width: '196px',
    height: '196px',
    padding: '8px 0px 0px 8px',
  },
  mediumContainer: {
    top: '-10px',
    left: '-10px',
    width: '380px',
    height: '380px',
    padding: '10px',
  },
  largeContainer: {
    top: '-12px',
    left: '-12px',
    width: '414px',
    height: '414px',
    padding: '12px',
  },
  smallStyle: {
    height: '180px',
    width: '180px',
    '& tr': {
      height: '59px',
    },
    '& td': {
      width: '59px',
    },
  },
  mediumStyle: {
    height: '360px',
    width: '360px',
    '& tr': {
      height: '119px',
    },
    '& td': {
      width: '119px',
    },
  },
  largeStyle: {
    height: '390px',
    width: '390px',
    '& tr': {
      height: '129px',
    },
    '& td': {
      width: '129px',
    },
  },
}));

/* ********* Local Function ********** */

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * AreaSimplePitRow エリア明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const AreaSimplePitRow: React.FC<AreaSimplePitRowType> = ({ pitLine }) => (
  <TableRow>
    {tableArrayRender.map((value) => (
      <TableCell key={value}>
        {pitLine && pitLine[value] === COMMON.FLAG.ON && <img src={pitMarkIcon} alt="○" />}
      </TableCell>
    ))}
  </TableRow>
);

/* ************ Main Component ************ */
const AreaSimplePit: React.FC<AreaSimplePitType> = ({ className, size, pitConf }) => {
  const classes = useStyles();
  // テーブルサイズを定義
  let pitStyle = '';
  let tableSizeStyle = '';
  let contanerSizeStyle = '';
  switch (size) {
    case COMMON.SIZE.SMALL:
      pitStyle = classes.smallSimplePit;
      tableSizeStyle = classes.smallStyle;
      contanerSizeStyle = classes.smallContainer;
      break;
    case COMMON.SIZE.MEDIUM:
      pitStyle = classes.mediumSimplePit;
      tableSizeStyle = classes.mediumStyle;
      contanerSizeStyle = classes.mediumContainer;
      break;
    default:
      pitStyle = classes.largeSimplePit;
      tableSizeStyle = classes.largeStyle;
      contanerSizeStyle = classes.largeContainer;
  }

  const contanerStyle = `${className} ${contanerSizeStyle}`;
  // テーブルのスタイル定義 サイズ、左右の配色など最終的なスタイルを定義
  const tableStyle = `${classes.tableRoot} ${tableSizeStyle}`;

  // 行ごとにセルごとの表示制御するためline(実際は列)の配列を作成
  const pitLineArray =
    pitConf?.settingPattern === CLSFCN.PIT_PATTERN.PINPOINT ? toArrayPitLine(undefined) : toArrayPitLine(pitConf);
  const simplePitLineArray = toArraySimplePitLine(pitLineArray);

  return (
    <TableContainer className={contanerStyle}>
      <Table className={tableStyle}>
        <TableBody>
          {tableArrayRender.map((value) => (
            <AreaSimplePitRow
              key={value}
              className={pitStyle}
              pitLine={toSimplePitLineData(simplePitLineArray, value)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AreaSimplePit;
