import React, { memo, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DownAllowIcon from 'assets/icons/DownArrowIcon';
import { UpDownButtonType } from 'types/common/commonAtomsType';

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  upIcon: {
    transform: 'rotateX(180deg)',
  },
  enabled: {
    fill: theme.palette.common.black,
  },
  disabled: {
    fill: theme.base.gray,
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * UpDownButton 上下ボタン
 * 一覧のスクロールに利用。クリックでスクロールを動かす。
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const UpDownButton: React.FC<UpDownButtonType> = (props) => {
  const classes = useStyles();
  const { className, disabled, fullWidth, isUp, onClick, onMouseDown, onMouseUp, onMouseLeave } = props;

  /* *************      hooks     ************* */
  // disabledになった場合にlong press eventを強制解除。
  // 長押しイベントの制御は難しいので編集時は要注意してテストすること。
  useEffect(() => {
    if (disabled && onMouseUp) onMouseUp();
  }, [disabled]);

  return (
    <Button
      variant="outlined"
      className={`${className} ${classes.enabled}`}
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      disabled={disabled}>
      {!disabled && <DownAllowIcon size={32} className={isUp ? classes.upIcon : ''} />}
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
UpDownButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(UpDownButton);
