import COMMON from 'constants/common';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NormalSwitch, ReturnButton, SelectButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClassificationName, getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectListType, TenkeyOpenEventType, TenkeyType } from 'types/support/supportType';
import SelectList, { INITIAL_SELECT_LIST } from 'components/support/organisms/supports/SelectList';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { setAreaConf, setOperationConf, setOperationConfModeOff } from 'redux/slices/machineConfSlice';
import { AreaConfType, OperationConfType } from 'types/machineConf/machineConfType';
import { handleCallFrontError, handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import CloneNewTenkey, { INITIAL_TEN_KEY } from 'components/support/organisms/supports/CloneNewTenkey';
import { themeColor } from 'constants/theme';
import graph from 'assets/images/newServiceConfGraph.svg';
import armPowerIcon from 'assets/images/armPowerIcon.svg';
import TenkeyInput from 'components/support/atoms/inputs/TenkeyInput';
import ConfirmDialog from 'components/system/organisms/dialogs/ConfirmDialog';
import MESSAGES from 'constants/messages';
import { resetDescentLimitValue } from 'utils/resetDescentLimit.helper';
import { InfoDialog } from 'components/system/organisms';
import MessageHelper from 'utils/message.helper';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '新サービスモード設定',
  screenId: COMMON.SCREEN_ID.NEW_SERVICE_MODE_CONF,
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Contants ************ */
const COLORS = {
  BASIC_ARM: '#00B050',
  PLAY_COUNT: '#3086FF',
  LIMIT_ARM: '#FF3E3E',
  LIMIT_PLAY_COUNT: '#AB47BC',
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    position: 'relative',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .returnButton': {
      fontSize: '20px',
      lineHeight: '24px',
      position: 'relative',
      zIndex: 200,
    },
    '& .modeArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '282px',
      height: '72px',
      fontSize: '20px',
      position: 'relative',
      zIndex: 200,
      padding: '0 16px',
      marginLeft: '32px',
    },
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    '& .limitPCArea': {
      width: '188px',
      height: '226px',
      padding: '8px 4px 8px 8px',
      '& .limitPCRow': {
        display: 'flex',
        alignItems: 'end',
        marginTop: '32px',
      },
      '& .limitPCLabelTop': {
        marginLeft: '8px',
      },
      '& .limitPCLabelBottom': {
        marginTop: '8px',
      },
    },
  },
  bottomContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    '& .bottomLeft': {
      display: 'flex',
    },
    '& .graphArea': {
      width: '321px',
      height: '226px',
      position: 'relative',
      '& .graphImage': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      },
      '& .graphBasicAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '6px',
        left: '56px',
      },
      '& .graphLimitAPIcon': {
        width: '36px',
        position: 'absolute',
        top: '48px',
        left: '226px',
      },
      '& .graphBoxLarge, & .graphBoxMedium, & .graphBoxSmall': {
        position: 'absolute',
        fontSize: '19px',
        textAlign: 'center',
        backgroundColor: '#FFF',
        border: '1px solid #000',
        borderRadius: '4px',
      },
      '& .graphBoxLarge': {
        width: '58px',
        height: '29px',
      },
      '& .graphBoxMedium': {
        width: '39px',
        height: '29px',
      },
      '& .graphBoxSmall': {
        width: '29px',
        height: '29px',
      },
      '& .graphBasicLeft': {
        top: '48px',
        left: '8px',
      },
      '& .graphBasicRight': {
        top: '48px',
        left: '82px',
      },
      '& .graphBasicAO': {
        top: '84px',
        left: '45px',
      },
      '& .graphLimitLeft': {
        top: '84px',
        left: '178px',
      },
      '& .graphLimitRight': {
        top: '84px',
        left: '252px',
      },
      '& .graphLimitAO': {
        top: '120px',
        left: '215px',
      },
      '& .graphPC': {
        top: '188px',
        left: '122px',
      },
      '& .graphLimitPC': {
        top: '188px',
        left: '168px',
      },
    },
    '& .resetTimingArea': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '430px',
      height: '124px',
      padding: '8px',
      marginLeft: '10px',
    },
    '& .bottomRight': {
      marginTop: 'auto',
      marginLeft: 'auto',
    },
  },
  arrowAreaLarge: {
    width: '268px',
    height: '226px',
    backgroundColor: '#FFF',
    position: 'relative',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px 3px 3px 8px',
    padding: '8px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderStyle: 'solid',
      borderRadius: '3px',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&:before': {
      left: '265px',
      borderWidth: '113px',
      borderLeftWidth: '55px',
    },
    '&:after': {
      left: '263px',
      borderWidth: '112px',
      borderLeftWidth: '54px',
    },
    '& .powerRow': {
      display: 'flex',
      width: '276px',
      '& .powerSetting': {
        marginTop: '32px',
      },
    },
    '& .armOpenRow': {
      position: 'relative',
      width: '276px',
      textAlign: 'center',
      '& .limitArmOpenBox': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '4px',
        margin: '0 auto',
      },
      '& .armOpenLabel': {
        position: 'absolute',
        textAlign: 'left',
        right: '8px',
        bottom: '0',
      },
    },
  },
  arrowAreaSmall: {
    width: '166px',
    height: '226px',
    backgroundColor: '#FFF',
    position: 'relative',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px 3px 3px 8px',
    padding: '8px',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      borderStyle: 'solid',
      borderRadius: '3px',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
    '&:before': {
      left: '163px',
      borderWidth: '113px',
      borderLeftWidth: '55px',
    },
    '&:after': {
      left: '161px',
      borderWidth: '112px',
      borderLeftWidth: '54px',
    },
    '& .playCountRow': {
      display: 'flex',
      alignItems: 'end',
      width: '180px',
      marginTop: '32px',
      '& .playCountLabelTop': {
        marginLeft: '8px',
        zIndex: 1,
      },
    },
    '& .playCountLabelBottom': {
      width: '180px',
      position: 'relative',
      zIndex: 1,
      marginTop: '8px',
    },
  },
  // ステーション別Style
  leftStyle: {
    borderColor: themeColor.leftDarkColor,
    '&:before': {
      borderLeftColor: themeColor.leftDarkColor,
    },
    '&:after': {
      borderLeftColor: '#FFF',
    },
  },
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
    '&:before': {
      borderLeftColor: themeColor.rightDarkColor,
    },
    '&:after': {
      borderLeftColor: '#FFF',
    },
  },
  // Color
  basicArmColor: {
    color: COLORS.BASIC_ARM,
  },
  playCountColor: {
    color: COLORS.PLAY_COUNT,
  },
  limitArmColor: {
    color: COLORS.LIMIT_ARM,
  },
  limitPlayCountColor: {
    color: COLORS.LIMIT_PLAY_COUNT,
  },
  // Font
  fontLarge: {
    fontSize: '24px',
  },
  fontSmall: {
    fontSize: '19px',
  },
  // Common Style
  zIndex1: {
    zIndex: 1,
  },
  disableInputArea: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgb(128 128 128 / 50%)',
    borderRadius: '8px',
    zIndex: 100,
    top: '0px',
    left: '0px',
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
  },
  armPowerIcon: {
    width: '80px',
    height: '80px',
    margin: '0 8px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  inputBox: {
    width: '90px',
    height: '56px',
    fontSize: '28px',
    '&.fontMedium': {
      fontSize: '24px',
    },
    '&.resetTiming': {
      width: '182px',
      height: '65px',
    },
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  OPERATION_CONF: 'operationConf',
  BASIC: {
    ARM_OPEN: 'armOpen',
    L_POWER: 'lPower1',
    R_POWER: 'rPower1',
  },
  PLAY_COUNT: 'playCount',
  LIMIT: {
    L_POWER: 'limitLPower',
    R_POWER: 'limitRPower',
  },
  LIMIT_PLAY_COUNT: 'limitPlayCount',
  RESET_TIMING: 'resetTiming',
};

const TENKEY_OPTION = {
  ARM_POWER: {
    mode: COMMON.TENKEY_MODE.DECIMAL,
    integerSize: 3,
    scaleSize: 1,
    maxValue: 200,
    minValue: 0.1,
    decimalLimit: 50,
    decimalLimit2: 100,
    lPower1: 0,
    rPower1: 0,
    limitLPower: 0,
    limitRPower: 0,
  },
};

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - 新サービスモード設定
 *
 */
const NewServiceModeConf: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  /* ************ State ************ */
  // Global
  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const areaConf = useAppSelector((state) => state.machineConf.machineConf.areaConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);

  const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;
  const [selectList, setSelectList] = useState<SelectListType>(INITIAL_SELECT_LIST.selectList);
  const [tenkey, setTenkey] = useState(INITIAL_TEN_KEY.tenkey);
  const [tempOperationConf, setTempOperationConf] = useState<OperationConfType | undefined>(operationConf);
  const [tempAreaConf, setTempAreaConf] = useState<AreaConfType | undefined>(areaConf);
  const [openConfirmDialogModeSetting, setOpenConfirmDialogModeSetting] = useState(false);
  const [openConfirmDialogModeSettingOff, setOpenConfirmDialogModeSettingOff] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  // DataSource
  const armOpenDataSource = useRef(getClsfcnByCategory(CLSFCN.ARM_OPEN.CATEGORY_CD));
  const playCountDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.PLAY_COUNT.CATEGORY_CD), []);
  const limitPlayCountDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.LIMIT_PLAY_COUNT.CATEGORY_CD), []);
  const resetTimingDataSource = useMemo(() => getClsfcnByCategory(CLSFCN.RESET_TIMING.CATEGORY_CD), []);

  const isNewSeviceModeDisable = tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_SERVICE_MODE;

  /* ************ Event ************ */
  useEffect(() => {
    if (!operationConf || !areaConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleClickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // モード設定
  const handleChangeOperationConf = useCallback(
    // ダイアログを表示する。
    (event) => {
      if (event.target.checked) {
        setOpenConfirmDialogModeSetting(true);
      } else {
        setOpenConfirmDialogModeSettingOff(true);
      }
    },
    [tempOperationConf?.operationConf],
  );

  // モード設定 OFF->ON Yes
  const handleClickOkModeSetting = useCallback(() => {
    // モード設定を「新サービスモード」に変更
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            operationConf: COMMON.OPERATION_CONF.NEW_SERVICE_MODE,
          }
        : prevState,
    );
    handleCloseConfirmDialogModeSetting();
  }, [tempOperationConf?.operationConf]);

  // モード設定 OFF->ON No
  const handleCloseConfirmDialogModeSetting = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSetting(false);
  };

  // モード設定 ON->OFF Yes
  const handleClickOkModeSettingOff = async () => {
    // セッション更新
    const http = new HttpConnection({ dispatch });
    const newTempAreaConf = resetDescentLimitValue(areaConf);
    // 運営設定「設定なし」をグローバルステートに保存
    dispatch(setOperationConfModeOff(COMMON.OPERATION_CONF.NO_CONF));

    if (!newTempAreaConf?.hasReset) {
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF).then(() => {
        // ダイアログを閉じる
        setOpenConfirmDialogModeSettingOff(false);

        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
    } else {
      const areaConfReset = newTempAreaConf.areaConf;
      await http.post(REST_API.MACHINE_CONF.SET_NO_CONF_WITH_AREA_CONF, {
        descentLimit1: areaConfReset.descentLimit1,
        descentLimit2: areaConfReset.descentLimit2,
        descentLimit3: areaConfReset.descentLimit3,
        descentLimit4: areaConfReset.descentLimit4,
        descentLimit5: areaConfReset.descentLimit5,
        descentLimit6: areaConfReset.descentLimit6,
      });

      setOpenConfirm(true);
      // reset DescentLimit
      dispatch(setAreaConf(areaConfReset));
    }
  };

  const onClickOkModeConfirm = () => {
    setOpenConfirm(false);
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // モード設定 ON->OFF No
  const handleCloseConfirmDialogModeSettingOff = (): void => {
    // ダイアログを閉じる
    setOpenConfirmDialogModeSettingOff(false);
  };

  // SelectList(共通) 一覧選択情報を初期化
  const handleCloseSelectList = useCallback(() => {
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // SelectList(areaConf) 各項目の設定値に反映
  const handleClickAreaSelectList = useCallback((data: { value: string }, name: string) => {
    setTempAreaConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: data.value,
          }
        : prevState,
    );
    setSelectList(INITIAL_SELECT_LIST.selectList);
  }, []);

  // SelectList(areaConf) 設定初期値
  const initialAreaSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickAreaSelectList,
    onClose: handleCloseSelectList,
  };
  // Validate playCount and limitPlayCount
  const validateLimitPlayCount = (
    playCount: string | number | undefined,
    limitPlayCount: string | number | undefined,
  ): { isError: boolean } => {
    if (!playCount || !limitPlayCount) return { isError: false };

    const erros = {
      errors: { key: [MessageHelper.messageFormat(MESSAGES.INF118)] },
    };

    if (Number(playCount) >= Number(limitPlayCount)) {
      dispatch(handleCallFrontError(erros));
      return { isError: true };
    }
    return { isError: false };
  };

  // SelectList(operationConf) 各項目の設定値に反映
  const handleClickOperationSelectList = (data: { value: string }, name: string) => {
    setTempOperationConf((prevState) =>
      prevState
        ? {
            ...prevState,
            [name]: data.value,
          }
        : prevState,
    );
    setSelectList(INITIAL_SELECT_LIST.selectList);
  };

  // SelectList(areaConf) 設定初期値
  const initialOperationSelectList: SelectListType = {
    ...INITIAL_SELECT_LIST.selectList,
    open: true,
    onClick: handleClickOperationSelectList,
    onClose: handleCloseSelectList,
  };

  // Tenkey(共通) 閉じる
  const handleCloseTenkey = useCallback(() => setTenkey(INITIAL_TEN_KEY.tenkey), []);

  // Tenkey(areaConf) 決定
  const handleAreaDecision = useCallback(
    (value: string, name: string) => {
      setTempAreaConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempAreaConf],
  );

  // Tenkey(areaConf) 設定初期値
  const initialAreaTenkey: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleAreaDecision,
    onClose: handleCloseTenkey,
  };

  // Tenkey(operationConf) 決定
  const handleOperationDecision = useCallback(
    (value: string, name: string) => {
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [name]: Number(value),
            }
          : prevState,
      );
      setTenkey(INITIAL_TEN_KEY.tenkey);
    },
    [tempOperationConf],
  );

  // Tenkey(operationConf) 設定初期値
  const initialOperationTenkey: TenkeyType = {
    ...INITIAL_TEN_KEY.tenkey,
    open: true,
    onDecision: handleOperationDecision,
    onClose: handleCloseTenkey,
  };

  // 基本アームパワー左
  const handleClickBasicLeft: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialAreaTenkey,
      anchorPosition: { top: 100, left: 180 },
      name: INPUT_NAMES.BASIC.L_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempAreaConf?.lPower1),
    });
  }, [tempAreaConf?.lPower1]);

  // 基本アームパワー右
  const handleClickBasicRight: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialAreaTenkey,
      anchorPosition: { top: 100, left: 300 },
      name: INPUT_NAMES.BASIC.R_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempAreaConf?.rPower1),
    });
  }, [tempAreaConf?.rPower1]);

  // 基本アーム開度
  const handleClickArmOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialAreaSelectList,
        anchorEL: event.currentTarget,
        dataSource: armOpenDataSource.current,
        name: INPUT_NAMES.BASIC.ARM_OPEN,
        placement: 'right',
        title: 'アーム開度を選択してください。',
        value: tempAreaConf?.armOpen,
      });
    },
    [tempAreaConf?.armOpen],
  );

  // プレイ回数
  const handleClickPlayCount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectList({
      ...initialOperationSelectList,
      anchorEL: event.currentTarget,
      dataSource: playCountDataSource,
      name: INPUT_NAMES.PLAY_COUNT,
      placement: 'right',
      title: 'プレイ回数を選択してください。',
      value: tempOperationConf?.playCount,
    });
  };

  // 上限アームパワー左
  const handleClickLimitLeft: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialOperationTenkey,
      anchorPosition: { top: 100, left: 130 },
      name: INPUT_NAMES.LIMIT.L_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.limitLPower),
    });
  }, [tempOperationConf?.limitLPower]);

  // 上限アームパワー右
  const handleClickLimitRight: TenkeyOpenEventType = useCallback(() => {
    setTenkey({
      ...initialOperationTenkey,
      anchorPosition: { top: 100, left: 280 },
      name: INPUT_NAMES.LIMIT.R_POWER,
      option: TENKEY_OPTION.ARM_POWER,
      value: String(tempOperationConf?.limitRPower),
    });
  }, [tempOperationConf?.limitRPower]);

  // 上限プレイ回数
  const handleClickLimitPlayCount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectList({
      ...initialOperationSelectList,
      anchorEL: event.currentTarget,
      dataSource: limitPlayCountDataSource,
      name: INPUT_NAMES.LIMIT_PLAY_COUNT,
      placement: 'left',
      title: '上限プレイ回数を選択してください。',
      value: tempOperationConf?.limitPlayCount,
    });
  };

  // リセットタイミング
  const handleClickResetTiming = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setSelectList({
        ...initialOperationSelectList,
        anchorEL: event.currentTarget,
        dataSource: resetTimingDataSource,
        name: INPUT_NAMES.RESET_TIMING,
        placement: 'right',
        title: 'リセットタイミングを選択してください。',
        value: tempOperationConf?.resetTiming,
      });
    },
    [tempOperationConf?.resetTiming],
  );

  // 確定ボタン
  const handleClickDone = async () => {
    // Validationとセッション格納
    const isValidate = validateLimitPlayCount(tempOperationConf?.playCount, tempOperationConf?.limitPlayCount);
    if (isValidate.isError) return;

    const http = new HttpConnection({ dispatch });
    await http
      .post(REST_API.MACHINE_CONF.NEW_SERVICE_MODE_CONF_VALIDATION, {
        armOpen: tempAreaConf?.armOpen,
        lPower1: tempAreaConf?.lPower1,
        rPower1: tempAreaConf?.rPower1,
        operationConf: tempOperationConf?.operationConf,
        playCount: tempOperationConf?.playCount,
        limitLPower: tempOperationConf?.limitLPower,
        limitRPower: tempOperationConf?.limitRPower,
        limitPlayCount: tempOperationConf?.limitPlayCount,
        resetTiming: tempOperationConf?.resetTiming,
      })
      .then(() => {
        // エリア設定（基本アームパワー左右、基本アーム開度、エリア設定=OFF）をグローバルステートに保存
        if (tempAreaConf) {
          dispatch(
            setAreaConf({
              ...tempAreaConf,
              areaConf: COMMON.SWITCH.OFF,
            }),
          );
        }
        // 運営設定をグローバルステートに保存
        dispatch(setOperationConf(tempOperationConf));
        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton className="returnButton" onClick={handleClickReturn}>
                ゲーム設定TOP
              </ReturnButton>
              <Box className={`${classes.baseArea} ${stationStyle} modeArea`}>
                <Box>新サービスモード</Box>
                <NormalSwitch
                  leftRight={leftRight}
                  value=""
                  checked={tempOperationConf?.operationConf === COMMON.OPERATION_CONF.NEW_SERVICE_MODE}
                  onChange={handleChangeOperationConf}
                />
              </Box>
              <Box className="discription">
                景品の獲得をサポートします。
                <br />
                各種設定をし、<Typography className={classes.doneImage}>確 定</Typography>してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.arrowAreaLarge} ${stationStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.basicArmColor}`}>基本のアームパワー</Box>
                <Box className="powerRow">
                  {/* 基本アームパワー左 */}
                  <TenkeyInput
                    // テンキー表示したとき、zIndex1を外す
                    className={`${classes.basicArmColor} ${classes.inputBox}  powerSetting ${
                      tenkey.name === INPUT_NAMES.BASIC.L_POWER ?? classes.zIndex1
                    }`}
                    leftRight={leftRight}
                    onOpen={handleClickBasicLeft}
                    isSelected={tenkey.name === INPUT_NAMES.BASIC.L_POWER}
                    value={String(tempAreaConf?.lPower1)}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.BASIC_ARM}
                  />
                  <img src={armPowerIcon} alt="armPowerIcon" className={classes.armPowerIcon} />
                  {/* 基本アームパワー右 */}
                  <TenkeyInput
                    // テンキー表示したとき、zIndex1を外す
                    className={`${classes.basicArmColor} ${classes.inputBox} powerSetting ${
                      tenkey.name === INPUT_NAMES.BASIC.R_POWER ? '' : classes.zIndex1
                    }`}
                    leftRight={leftRight}
                    onOpen={handleClickBasicRight}
                    isSelected={tenkey.name === INPUT_NAMES.BASIC.R_POWER}
                    value={String(tempAreaConf?.rPower1)}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.BASIC_ARM}
                  />
                </Box>
                <Box className="armOpenRow">
                  <Box className={classes.fontSmall}>アーム開度</Box>
                  {/* 基本アーム開度 */}
                  <SelectButton
                    className={`${classes.basicArmColor} ${classes.inputBox} fontMedium`}
                    leftRight={leftRight}
                    onClick={handleClickArmOpen}
                    arrowSize="24px"
                    isSelected={selectList.name === INPUT_NAMES.BASIC.ARM_OPEN}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.BASIC_ARM}>
                    {tempAreaConf?.armOpen && getClassificationName(CLSFCN.ARM_OPEN.CATEGORY_CD, tempAreaConf.armOpen)}
                  </SelectButton>
                  <Box className={`${classes.fontSmall} armOpenLabel`}>
                    から
                    <br />
                    開始し、
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.arrowAreaSmall} ${stationStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.playCountColor}`}>プレイ回数</Box>
                <Box className="playCountRow">
                  <SelectButton
                    className={`${classes.playCountColor} ${classes.inputBox} fontMedium`}
                    leftRight={leftRight}
                    onClick={handleClickPlayCount}
                    arrowSize="24px"
                    isSelected={selectList.name === INPUT_NAMES.PLAY_COUNT}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.PLAY_COUNT}>
                    {tempOperationConf?.playCount &&
                      getClassificationName(CLSFCN.PLAY_COUNT.CATEGORY_CD, tempOperationConf.playCount)}
                  </SelectButton>
                  <Box className={`${classes.fontSmall} playCountLabelTop`}>回目以降</Box>
                </Box>
                <Box className={`${classes.fontSmall} playCountLabelBottom`}>パワーを上昇させ、</Box>
              </Box>
              <Box className={`${classes.arrowAreaLarge} ${stationStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.limitArmColor}`}>上限のアームパワー</Box>
                <Box className="powerRow">
                  {/* 上限アームパワー左 */}
                  <TenkeyInput
                    // テンキー表示したとき、zIndex1を外す
                    className={`${classes.limitArmColor} ${classes.inputBox} powerSetting ${
                      tenkey.name === INPUT_NAMES.LIMIT.L_POWER ?? classes.zIndex1
                    }`}
                    leftRight={leftRight}
                    onOpen={handleClickLimitLeft}
                    isSelected={tenkey.name === INPUT_NAMES.LIMIT.L_POWER}
                    value={String(tempOperationConf?.limitLPower)}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.LIMIT_ARM}
                  />
                  <img src={armPowerIcon} alt="armPowerIcon" className={classes.armPowerIcon} />
                  {/* 上限アームパワー右 */}
                  <TenkeyInput
                    // テンキー表示したとき、zIndex1を外す
                    className={`${classes.limitArmColor} ${classes.inputBox} powerSetting ${
                      tenkey.name === INPUT_NAMES.LIMIT.R_POWER ? '' : classes.zIndex1
                    }`}
                    leftRight={leftRight}
                    onOpen={handleClickLimitRight}
                    isSelected={tenkey.name === INPUT_NAMES.LIMIT.R_POWER}
                    value={String(tempOperationConf?.limitRPower)}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.LIMIT_ARM}
                  />
                </Box>
                <Box className="armOpenRow">
                  {/* 上限アーム開度 */}
                  <Box className={classes.fontSmall}>アーム開度</Box>
                  <Box
                    className={`${stationStyle} ${classes.inputBox} fontMedium limitArmOpenBox`}>{`${tempAreaConf?.armOpen}%`}</Box>
                  <Box className={`${classes.fontSmall} armOpenLabel`}>
                    を
                    <br />
                    目標に、
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.baseArea} limitPCArea ${stationStyle}`}>
                <Box className={`${classes.fontLarge} ${classes.limitPlayCountColor}`}>上限プレイ回数</Box>
                <Box className="limitPCRow">
                  <SelectButton
                    className={`${classes.limitPlayCountColor} ${classes.inputBox} fontMedium`}
                    leftRight={leftRight}
                    onClick={handleClickLimitPlayCount}
                    arrowSize="24px"
                    isSelected={selectList.name === INPUT_NAMES.LIMIT_PLAY_COUNT}
                    disabled={isNewSeviceModeDisable}
                    disabledTextColor={COLORS.LIMIT_PLAY_COUNT}>
                    {tempOperationConf?.limitPlayCount &&
                      getClassificationName(CLSFCN.LIMIT_PLAY_COUNT.CATEGORY_CD, tempOperationConf.limitPlayCount)}
                  </SelectButton>
                  <Box className={`${classes.fontSmall} limitPCLabelTop`}>回目</Box>
                </Box>
                <Box className={`${classes.fontSmall} limitPCLabelBottom`}>まで、上昇を続ける</Box>
              </Box>
            </Box>
            <Box className={classes.bottomContent}>
              <Box className="bottomLeft">
                <Box className={`${classes.baseArea} graphArea ${stationStyle}`}>
                  {/* グラフのラベルと画像 */}
                  <img src={graph} alt="graph" className="graphImage" />
                  <img src={armPowerIcon} alt="armPowerIcon" className="graphBasicAPIcon" />
                  <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicLeft`}>{tempAreaConf?.lPower1}</Box>
                  <Box className={`${classes.basicArmColor} graphBoxLarge graphBasicRight`}>
                    {tempAreaConf?.rPower1}
                  </Box>
                  <Box
                    className={`${classes.basicArmColor} graphBoxLarge graphBasicAO`}>{`${tempAreaConf?.armOpen}%`}</Box>
                  <img src={armPowerIcon} alt="armPowerIcon" className="graphLimitAPIcon" />
                  <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitLeft`}>
                    {tempOperationConf?.limitLPower}
                  </Box>
                  <Box className={`${classes.limitArmColor} graphBoxLarge graphLimitRight`}>
                    {tempOperationConf?.limitRPower}
                  </Box>
                  <Box className="graphBoxLarge graphLimitAO">{`${tempAreaConf?.armOpen}%`}</Box>
                  <Box className={`${classes.playCountColor} graphBoxSmall graphPC`}>
                    {tempOperationConf?.playCount}
                  </Box>
                  <Box className={`${classes.limitPlayCountColor} graphBoxMedium graphLimitPC`}>
                    {tempOperationConf?.limitPlayCount}
                  </Box>
                </Box>
                <Box className={`${classes.baseArea} resetTimingArea ${stationStyle}`}>
                  <Box>
                    <Box className={classes.fontLarge}>リセットタイミング</Box>
                    <Box className={classes.fontSmall}>
                      上昇したアームパワーを
                      <br />
                      リセットするタイミング
                      <br />
                      を設定します。
                    </Box>
                  </Box>
                  <SelectButton
                    disabled={isNewSeviceModeDisable}
                    className={`${classes.inputBox} resetTiming`}
                    leftRight={leftRight}
                    onClick={handleClickResetTiming}
                    arrowSize="24px"
                    isSelected={selectList.name === INPUT_NAMES.RESET_TIMING}>
                    {tempOperationConf?.resetTiming === CLSFCN.RESET_TIMING.GET_GIFT ? '獲得時' : '連続終了時'}
                  </SelectButton>
                </Box>
              </Box>
              <Box className="bottomRight">
                <DoneButton disabled={isNewSeviceModeDisable} onClick={handleClickDone}>
                  確 定
                </DoneButton>
              </Box>
            </Box>
            {tempOperationConf?.operationConf !== COMMON.OPERATION_CONF.NEW_SERVICE_MODE && (
              <Box className={classes.disableInputArea} />
            )}
          </Box>
          <SelectList selectList={selectList} />
          <CloneNewTenkey tenkey={tenkey} tempAreaConf={tempAreaConf} tempOperationConf={tempOperationConf} />
          <ConfirmDialog
            open={openConfirmDialogModeSetting}
            msg={MESSAGES.INF220}
            onOk={handleClickOkModeSetting}
            onCancel={handleCloseConfirmDialogModeSetting}
          />
          <ConfirmDialog
            open={openConfirmDialogModeSettingOff}
            msg={MESSAGES.INF248}
            onOk={handleClickOkModeSettingOff}
            onCancel={handleCloseConfirmDialogModeSettingOff}
          />
          <InfoDialog open={openConfirm} msg={MESSAGES.INF241} closeFunc={onClickOkModeConfirm} />
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default NewServiceModeConf;
