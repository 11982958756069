import { makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import React, { memo } from 'react';
import NomalRow from 'components/system/atoms/tables/NomalRow';
import { StationDetailType } from 'types/data/dataType';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  default: {
    height: '348px',
    overflowX: 'hidden',
  },
  table: {
    tableLayout: 'fixed',
    borderColor: theme.base.tableBorder,
    border: '1px solid',
    '& th,td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.base.tableBorder,
      fontSize: '19px',
      lineHeight: '20px',
      minHeight: '58px',
    },
    '& tbody tr': {
      minHeight: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: theme.base.tableRowSecondory,
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
}));

/* ************ Type ************ */
type Props = {
  id: string;
  rows: Array<StationDetailType>;
  handleSelectedItemChange: (row: StationDetailType) => void;
};

/* ************ Component ************ */
const StationListTable: React.FC<Props> = ({ rows, handleSelectedItemChange, id }) => {
  const classes = useStyles();
  const cols = [{ key: 'mainColumn', width: '100%' }];

  return (
    <TableContainer id={id} className={classes.default}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        {cols.map((column) => (
          <col key={column.key} style={{ width: column.width }} />
        ))}
        <TableBody>
          {rows.map((row) => {
            const key = `station_list_table_key_${row.stationId}`;
            return <NomalRow key={key} row={row} handleSelected={handleSelectedItemChange} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(StationListTable);
