import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { SelectedStationType } from 'types/machineConf/stationSelectType';
import SelectedStationInfoContext from 'Contexts/Common/SelectedStationInfoContext';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getMachineVersion } from 'utils/common.helper';
import COMMON from 'constants/common';

/**
 * SelectedStationInfo Component
 * SCR301 選択ステーション情報
 * @author takuya.tomisato
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  Label: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 auto',
    position: 'relative',
    border: '1px solid',
    borderRadius: '4px',
    height: '40px',
    width: '840px',
    fontSize: '21px',
    padding: '0px 8px',
    backgroundColor: '#FFFFFF',
  },
  BoxLeft: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BoxCenter: {
    width: '20px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BoxRight: {
    paddingLeft: '8px',
  },
}));

// コンポーネント定義
const SelectedStationInfo: React.FC<SelectedStationType> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();
  // propsのプロパティ情報を取得
  const { stationName, giftName, machineType, machineVersion } = props;

  // Context取得
  // 選択ステーション情報の表示制御
  const LabelFlg = useContext(SelectedStationInfoContext);
  // LabelFlg.value
  if (LabelFlg.SelectedStationInfoDisplay === true) {
    return (
      <div className={classes.Label}>
        <div className={classes.BoxLeft}>{stationName}</div>
        <div className={classes.BoxCenter}>：</div>
        {/* ステーションに紐づく景品名が取得できない場合、ブランクを表示 */}
        <TooltipComponent tooltipText={giftName} />
        {/* クレナ3ジャックはmachineVersion非表示 */}
        {machineType === COMMON.MACHINE_TYPE.CLENA3 ? (
          <div className={classes.BoxRight}>{`(Ver.${getMachineVersion(machineVersion)})`}</div>
        ) : null}
      </div>
    );
  }
  return <></>;
};
export default SelectedStationInfo;
