export function getWeekInMonth(year: number, month: number, day: number): number {
  // we start at week 1
  let weekNum = 1;
  // get the weekDay of day 1
  let weekDay = new Date(year, month - 1, 1).getDay();
  //  recalculate the weekDay (Mon:0, Tue:1, Wed:2, Thu:3, Fri:4, Sat:5, Sun:6)
  weekDay = weekDay === 0 ? 6 : weekDay - 1;
  //  get the first monday of the month
  let monday = 1 + (7 - weekDay);

  while (monday <= day) {
    weekNum += 1;
    monday += 7;
  }

  return weekNum;
}

export function addDays(date: string, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function hadleDataByDate(costRateData: number[], time: number): number[] {
  const cloneCostRateData = [...costRateData];
  cloneCostRateData.splice(time);
  return cloneCostRateData;
}
