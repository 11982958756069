import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import redirectLinks from 'constants/redirectLinks';
import MachineStatusTable from 'components/home/organisms/tables/MachineStatusTable';
import NormalSelectBox from 'components/system/organisms/inputItems/NormalSelectBox';
import { Grid, makeStyles } from '@material-ui/core';
import ScreenContext from 'Contexts/Common/ScreenContext';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getClsfcnByCategory } from 'utils/common.helper';
import COMMON from 'constants/common';
import CLSFCN from 'constants/classification';
import HttpConnection from 'utils/httpConnection';
import { MachineStatus } from 'types/home/machineStatus';
import { REST_API } from 'constants/apiUrls';

/* ************ Context ************ */
const ScreenContextValue = {
  title: '機器状況一覧',
  screenId: 'SCR112',
  currentMenu: COMMON.MENU.HOME,
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '32px 20px 32px 12px',
  },
  returnArea: {
    padding: '0 0 24px 16px',
  },
  sortArea: {
    paddingBottom: '24px',
  },
}));

/* ************ Type ************ */

/* ************ Component ************ */
const MachineStatusList: React.FC = () => {
  const dispatch = useAppDispatch();
  /* ************ state/redux ************ */
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const [sort, setSort] = useState<string>(CLSFCN.ALERT_SORT.ALL_NEW);
  const [rows, setRows] = useState<Array<MachineStatus>>([]);

  useEffect(() => {
    getAlertInfoApi(sort);
  }, []);

  /* ************ API ************ */
  const getAlertInfoApi = async (prmSort: string) => {
    if (!(loginUserInfo?.currentHeadQId && loginUserInfo?.currentStoreId)) return;
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<MachineStatus>>(REST_API.HOME.GET_MACHINE_STATUS_LIST, {
      headquarters_id: loginUserInfo.currentHeadQId,
      store_id: loginUserInfo.currentStoreId,
      sort: prmSort,
    });
    setSort(prmSort);
    setRows(data);
  };

  const dataSouceSort = getClsfcnByCategory(CLSFCN.ALERT_SORT.CATEGORY_CD);

  /* ************ other component ************ */
  const classes = useStyles();
  const history = useHistory();

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    getAlertInfoApi(ev.target.value);
  };

  // return button change
  const handleClickReturn = () => {
    history.push(redirectLinks.HOME_TOP);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <div className={classes.contents}>
          <Grid container alignItems="flex-end">
            <Grid item xs={3} className={classes.returnArea}>
              <ReturnButton onClick={handleClickReturn}>HOME</ReturnButton>
            </Grid>
            <Grid item xs={9} className={classes.sortArea}>
              <NormalSelectBox
                name="sort"
                dataSource={dataSouceSort}
                value={sort}
                onChange={handleChangeSort}
                label="並び替え"
              />
            </Grid>
          </Grid>
          <MachineStatusTable rows={rows} sort={sort} callGetAlertInfoApi={getAlertInfoApi} />
        </div>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default MachineStatusList;
