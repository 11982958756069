import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ReturnButton from 'components/system/atoms/buttons/ReturnButton';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, makeStyles } from '@material-ui/core';
import redirectLinks from 'constants/redirectLinks';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import { SelectBox } from 'components/system/atoms';
import MenuTab from 'components/data/organisms/tabs/MenuTab';
import ContentMenuTab from 'components/data/organisms/tabs/ContentMenuTab';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import HttpConnection from 'utils/httpConnection';
import { getSalesDataType, SalesDataType, SalesDataViewType, TabMenu, TypeEventClickMenu } from 'types/data/dataType';
import { REST_API } from 'constants/apiUrls';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { getChartPlugins } from 'utils/data.helper';
import UrlHelper from 'utils/url.helper';
import {
  resetTabView,
  tabActive,
  tabViewIsDay,
  tabViewIsMonth,
  tabViewIsWeek,
  tabViewIsYear,
} from 'redux/slices/historyActionTabmenu';

import { USE_REDUX_VALUES } from './DataStationList';
import NormalButton from '../../components/system/atoms/buttons/NormalButton';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: '売上データ閲覧',
  screenId: 'SCR221',
  currentMenu: COMMON.MENU.DATA,
};

/* ********** Constants ********** */
const MAX_DISPLAY_BAR = 10;
const LABEL_MAX_LEN = 7;

const DATA_SETS = [
  [{ label: '', data: [], backgroundColor: '#FFB74D', borderColor: '#FFB74D', borderWidth: 1 }],
  [
    { label: ':月', data: [], backgroundColor: '#F06292', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':火', data: [], backgroundColor: '#BA68C8', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':水', data: [], backgroundColor: '#7986CB', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':木', data: [], backgroundColor: '#4FC3F7', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':金', data: [], backgroundColor: '#AED581', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':土', data: [], backgroundColor: '#FFF176', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':日', data: [], backgroundColor: '#FFB74D', borderColor: '#FFFFFF', borderWidth: 1 },
  ],
  [
    { label: ':1週目', data: [], backgroundColor: '#F06292', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':2週目', data: [], backgroundColor: '#7986CB', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':3週目', data: [], backgroundColor: '#4FC3F7', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':4週目', data: [], backgroundColor: '#AED581', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':5週目', data: [], backgroundColor: '#FFB74D', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':6週目', data: [], backgroundColor: '#FF7043', borderColor: '#FFFFFF', borderWidth: 1 },
  ],
  [
    { label: ':1月', data: [], backgroundColor: '#EF5350', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':2月', data: [], backgroundColor: '#F06292', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':3月', data: [], backgroundColor: '#BA68C8', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':4月', data: [], backgroundColor: '#7986CB', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':5月', data: [], backgroundColor: '#42A5F5', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':6月', data: [], backgroundColor: '#4DD0E1', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':7月', data: [], backgroundColor: '#66BB6A', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':8月', data: [], backgroundColor: '#DCE775', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':9月', data: [], backgroundColor: '#FFF176', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':10月', data: [], backgroundColor: '#FFA726', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':11月', data: [], backgroundColor: '#FF7043', borderColor: '#FFFFFF', borderWidth: 1 },
    { label: ':12月', data: [], backgroundColor: '#A1887F', borderColor: '#FFFFFF', borderWidth: 1 },
  ],
];
const MENU_SETTING = [
  { label: '日', dataViewUnit: COMMON.DATA_VIEW_UNIT.DAILY },
  { label: '週', dataViewUnit: COMMON.DATA_VIEW_UNIT.WEEKLY },
  { label: '月', dataViewUnit: COMMON.DATA_VIEW_UNIT.MONTHLY },
  { label: '年', dataViewUnit: COMMON.DATA_VIEW_UNIT.YEARLY },
];

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  content: {
    height: '598px',
    width: '1140px',
    padding: '24px 0px 0px 4px',
  },
  topArea: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .returnButton': {
      padding: '4px 0px 0px 24px',
      width: '224px',
    },
    '& .menuArea': {
      backgroundColor: '#535B80',
      borderRadius: '4px 4px 0px 0px',
      marginLeft: '24px',
      height: '82px',
      width: '892px',
    },
  },
  mainArea: {
    backgroundColor: '#535B80',
    borderRadius: '4px 0px 4px 4px',
    display: 'flex',
    flexDirection: 'column',
    height: '516px',
    width: '1136px',
    '& .contentItem': {
      marginTop: '-60px',
    },
  },
  itemTopArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '8px',
  },
  itemMainArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '4px',
    '& .contentMenuArea': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '20px',
      width: '120px',
      '& .buttonArea': {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      },
    },
    '& .viewArea': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      height: '508px',
      width: '1008px',
    },
  },
  dataSheetbtn: {
    alignSelf: 'flex-end',
    width: '112px',
    height: '70px',
    fontSize: 21,
    lineHeight: '28px',
  },
  graphOptionArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '6px',
    padding: '0px 20px 0px 0px',
    '& .salesLimit': {
      marginRight: 'auto',
      marginLeft: '12px',
      '& > div': {
        width: '168px',
      },
    },
    '& .viewPeriod': {
      border: '1px solid',
      borderColor: theme.palette.common.black,
      borderRadius: '4px',
      height: '32px',
      width: '428px',
      textAlign: 'center',
      fontSize: '19px',
      lineHeight: '32px',
      marginRight: '20px',
      cursor: 'pointer',
    },
    '& .sortLabel': {
      margin: '4px 8px 0px 0px',
      fontSize: '19px',
    },
  },
  graphArea: {
    position: 'relative',
    height: '467px',
    width: '100%',
    padding: '32px 12px 0px 0px',
    '& .xAxesUnit': {
      position: 'absolute',
      top: '0px',
      left: '10px',
      fontSize: '16px',
    },
    '& .prevScroll': {
      position: 'absolute',
      top: '0px',
      left: '420px',
    },
    '& .graph': {
      height: '424px',
      width: '100%',
    },
    '& .nextScroll': {
      position: 'absolute',
      top: '436px',
      left: '420px',
    },
    '& .yAxesUnit': {
      position: 'absolute',
      top: '436px',
      left: '900px',
      fontSize: '16px',
    },
  },
}));

/* ********** local function ********** */
// 表示単位ごとのオプション設定を作成する。
const getOption = (dataViewUnit: string, selectedValue: { name: string; value: string }) => {
  const index = Number(dataViewUnit) - 1;
  const selected = Number(selectedValue.value) - 1;
  const tabMenu: Array<TabMenu> = ['DAY', 'WEEK', 'MONTH', 'YEAR'];
  const tabMenuIndex = tabMenu[index];

  const optionSetting = COMMON.OPTION_SETTING[tabMenuIndex][selected];

  return {
    indexAxis: 'y',
    responsive: false,
    maintainAspectRatio: false,
    layout: {},
    plugins: {
      // 右ラベル凡例表示設定
      legend: {
        display: optionSetting.displayLegend,
        position: 'right',
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          color: 'black',
          font: { size: 20 },
        },
      },
      tooltip: {
        xAlign: 'left',
        callbacks: {
          title: (context: Array<{ label: string }>) => {
            let lable = context[0].label;
            const characterAtIndex = lable.charAt(LABEL_MAX_LEN);
            const comma = ',';

            // if the character at position LABEL_MAX_LEN is comma. Then remove the comma
            if (characterAtIndex === comma) {
              lable = lable.replace(/./g, (character: string, i: number) => (i === LABEL_MAX_LEN ? '' : character));
            }
            return lable;
          },
          afterBody: (context: Array<{ raw: string }>) => {
            const valuesArray = context.map((e) => Number(e.raw));
            let result = '';
            if (valuesArray.length !== 1) {
              // 日タブ以外の場合
              const total = valuesArray.reduce((sum, num) => sum + num, 0);
              result = `合計：${total.toLocaleString()}`;
            }
            return result;
          },
        },
      },
    },
    // 右ラベル凡例表示設定
    datasets: {
      bar: {
        borderWidth: 0,
      },
    },
    // 軸設定
    scales: {
      x: {
        stacked: true, // 棒グラフを積み上げで表示する。両方設定しないと正しく動作しない。
        max: optionSetting.max, // 最大値
        min: 0, // 最小値
        // 罫線の設定 色、幅(10000円ごとに罫線を太くする。)、下線の表示位置制御
        grid: {
          color: '#BCBDCF',
          lineWidth: (context: Chart.ScriptableScaleContext) =>
            context.tick.value % optionSetting.mdGridUnit === 0 ? 2 : 1,
          drawTicks: false,
        },
        // 罫線、目盛り設定 stepSize:罫線表示単位 callback:目盛表示制御 color:目盛表示色 font:目盛フォントサイズ
        // maxRotation:表示領域が狭い場合に自動的に目盛りを回転して表示 autoSkip:表示領域に合わせて目盛りの表示を自動制御する。
        ticks: {
          stepSize: optionSetting.stepSize,
          callback: (value: number) => (value % optionSetting.mdGridUnit === 0 ? value / 10000 : ''),
          color: 'black',
          font: { size: 20 },
          maxRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        stacked: true, // 棒グラフを積み上げで表示する
        // 罫線の設定 y軸に対応する罫線は非表示
        grid: {
          display: false,
        },
        // 罫線、目盛り設定 aling: x寄せ callback:目盛表示制御 font:目盛フォントサイズ
        // maxRotation:表示領域が狭い場合に自動的に目盛りを回転して表示
        ticks: {
          align: 'center',
          autoSkip: false,
          color: 'black',
          font: { size: 14 },
          maxRotation: 30,
          padding: 4,
        },
      },
    },
  };
};

// 表示範囲のデータを取得する
const getData = (index: number, salesList: Array<SalesDataType>, dataViewUnit: string, detailNum: number) => {
  const startIndex = index;
  const dsIndex = Number(dataViewUnit) - 1;
  let datasets = DATA_SETS[dsIndex];

  // 月次の場合は週数(月～日を1週として最大6週)に応じて、ラベルを変更する。
  if (dataViewUnit === COMMON.DATA_VIEW_UNIT.MONTHLY) {
    datasets = datasets.slice(0, detailNum);
  }

  // chartに渡すデータを作成
  const data: {
    labels: Array<string | Array<string>>;
    datasets: Array<{ label: string; data: Array<number>; backgroundColor: string }>;
  } = {
    labels: [],
    datasets,
  };

  const salesLen = salesList.length;
  const dataSetLen = datasets.length;

  // 初期化
  for (let j = 0; j < dataSetLen; j += 1) {
    data.datasets[j].data = [];
  }

  // 最大表示件数またはデータの件数分までデータを作成する。
  for (let i = startIndex; i < startIndex + MAX_DISPLAY_BAR && i < salesLen; i += 1) {
    const sales = salesList[i];
    if (LABEL_MAX_LEN < sales.stationName.length) {
      data.labels.push([
        sales.stationName.slice(0, LABEL_MAX_LEN),
        sales.stationName.substr(LABEL_MAX_LEN, LABEL_MAX_LEN),
      ]);
    } else {
      data.labels.push(sales.stationName);
    }
    const { salesData } = sales;
    const salesDataLen = salesData.length;
    for (let j = 0; j < dataSetLen && j < salesDataLen; j += 1) {
      data.datasets[j].data.push(salesData[j]);
    }
  }
  return data;
};

/* ************ Component ************ */
/* ********** Sub Component ********** */
/**
 * EachConfRow 各エリア設定明細行
 *
 * @author atsushi.teruya
 * @Params {AreaDetailRowType} - {className, pitLine, areaLine}
 * @returns {React.FC} - エリア明細行の表示
 *
 */
const SalesDataGraph: React.FC<{
  index: number;
  salesList: Array<SalesDataType>;
  dataViewUnit: string;
  detailNum: number;
  selectedValue: { name: string; value: string };
}> = React.memo(({ index, salesList, dataViewUnit, detailNum, selectedValue }) => (
  <Bar
    type="bar"
    width={980}
    height={400}
    data={getData(index, salesList, dataViewUnit, detailNum)}
    options={getOption(dataViewUnit, selectedValue)}
    plugins={getChartPlugins()}
  />
));

/* ******** Main Component ******** */
/**
 * 売上データ閲覧
 *
 * @author atsushi.teruya
 * @returns {React.FC} - 売上データ閲覧
 *
 */
const SalesDataView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const { isPc } = useAppSelector((state) => state.common.deviceInfo);
  const { viewIsDay, viewIsWeek, viewIsMonth, viewIsYear, activeTab } = useAppSelector(
    (state) => state.tabmenu.historyActionTabmenu,
  );

  const { prevScreen } = useParams<{ prevScreen: string }>();
  const [option, setOption] = useState<Array<{ name: string; value: string }>>(COMMON.OPTIONS_TAB.DAY);
  const [selectedValue, setSelectedValue] = useState<{ name: string; value: string }>(COMMON.OPTIONS_TAB.DAY[1]);
  const { stationList, returnFromDataViewScr } = useAppSelector((state) => state.data);
  const [inputValues, setInputValues] = useState<SalesDataViewType>({
    dataViewUnit: activeTab.viewUnit,
    index: 0,
    sort: CLSFCN.DATA_VIEW_FILTER.STATION_ASC,
    startDate: '',
    viewPeriod: '',
    disabledNext: '1',
    salesList: [],
    detailNum: 0,
  });

  // reset history action of tab menu
  // resetDataRef is True then reset default
  const resetDataRef = React.useRef(true);

  // データシート発行ボタンの権限チェック
  const canDownloadDataSheet: boolean = (() => {
    if (!isPc) return false;
    if (!userInfo?.role) return false;
    if (![CLSFCN.ROLE.HEAD_ADMIN, CLSFCN.ROLE.HEAD_VIEWER, CLSFCN.ROLE.STORE_ADMIN].includes(userInfo.role)) {
      return false;
    }
    return true;
  })();

  // 戻るボタンの情報取得(URL、ラベル)
  const prevScrInfo = ((): { name: string; url: string } => {
    if (returnFromDataViewScr === COMMON.SCREEN_ID.GROUP_LIST) {
      return {
        name: '登録グループ一覧',
        url: redirectLinks.GROUP_LIST_DATA,
      };
    }
    if (returnFromDataViewScr === COMMON.SCREEN_ID.MACHINE_DETAIL) {
      return {
        name: '機器一覧',
        url: redirectLinks.MAINT_MACHINE_LIST,
      };
    }
    return {
      name: 'ステーション一覧',
      url: UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_STATION_LIST, {
        prevScreen: USE_REDUX_VALUES.TRUE,
      }),
    };
  })();

  /* ************ function ************ */
  // 売上データを取得し、ローカルステートに反映。
  const getSalesData = async (
    startDate: string,
    sort: string,
    changeDate: string,
    dataViewUnit: string,
  ): Promise<getSalesDataType> => {
    if (!(userInfo?.currentHeadQId && userInfo?.currentStoreId)) {
      return { detailNum: 0, disabledNext: '0', salesList: [], startDate: '', viewPeriod: '' };
    }

    const http = new HttpConnection({ dispatch });
    const data = await http.get<getSalesDataType>(REST_API.DATA.GET_SALES_VIEW_DATA, {
      stationList,
      headquartersId: userInfo?.currentHeadQId,
      storeId: userInfo?.currentStoreId,
      search_div: dataViewUnit,
      startDate,
      sort,
      changeDate,
    });
    setInputValues((prevState) => ({
      ...prevState,
      dataViewUnit,
      index: 0,
      sort,
      startDate: data.startDate,
      viewPeriod: data.viewPeriod,
      disabledNext: data.disabledNext,
      salesList: data.salesList,
      detailNum: data.detailNum,
    }));

    return data;
  };

  /* ************ Event ************ */
  // No1. ページ読み込み
  useEffect(() => {
    getSalesData(activeTab.startDate, inputValues.sort, COMMON.CHANGE_DATE.CURRENT, activeTab.viewUnit);
    const tabMenu: Array<TabMenu> = ['DAY', 'WEEK', 'MONTH', 'YEAR'];
    const optionTab: TabMenu = tabMenu[Number(activeTab.viewUnit) - 1];
    setOption(COMMON.OPTIONS_TAB[optionTab]);

    setSelectedValue({
      ...selectedValue,
      name: '1',
      value: '1',
    });

    return () => {
      if (resetDataRef.current) {
        dispatch(resetTabView());
        resetDataRef.current = true;
      }
    };
  }, []);

  // No2. ステーション一覧(登録グループ一覧)クリック
  const handleClickReturn = useCallback(() => {
    history.push(prevScrInfo.url);
  }, [prevScrInfo]);

  // データシート発行ボタン
  const handleClickDataSheet = () => {
    history.push(redirectLinks.DATA_STATION_GENERATE_DATA_SHEET, { from: COMMON.SCREEN_ID.DATA_SALES_DATA_VIEW });
  };

  // No3. 原価率タブクリック
  const handleClickMenuCostRate: MouseEventHandler<HTMLDivElement> = () => {
    resetDataRef.current = false;
    history.push(UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_COST_RATE_VIEW, { prevScreen }));
  };

  // handle when changing tab menu. Then reset selected value to zero.
  const handleChangeTabMenu = (dataViewUnit: string, typeEvent?: TypeEventClickMenu) => {
    const tabMenu: Array<TabMenu> = ['DAY', 'WEEK', 'MONTH', 'YEAR'];
    const optionTab: TabMenu = tabMenu[Number(dataViewUnit) - 1];
    setOption(COMMON.OPTIONS_TAB[optionTab]);
    if (typeEvent === 'ClickMenuTab') {
      setSelectedValue({
        ...selectedValue,
        name: '1',
        value: '1',
      });
    }
  };

  // No5～8,12～19 メニュータブ、切り替え_(前、後)(日、週、年、月) 共通処理
  const handleClickMenuTab = (
    startDate: string,
    sort: string,
    dataViewUnit: string,
    changeDate: string,
    typeEvent: TypeEventClickMenu,
  ): MouseEventHandler<HTMLElement> => async (event) => {
    event.preventDefault();
    handleChangeTabMenu(dataViewUnit, typeEvent);

    if (['PreviousMenuTab', 'NextMenuTab'].includes(typeEvent)) {
      const data = await getSalesData(startDate, sort, changeDate, dataViewUnit);
      switch (dataViewUnit) {
        case MENU_SETTING[0].dataViewUnit:
          dispatch(tabViewIsDay({ startDate: data.startDate }));
          break;
        case MENU_SETTING[1].dataViewUnit:
          dispatch(tabViewIsWeek({ startDate: data.startDate }));
          break;
        case MENU_SETTING[2].dataViewUnit:
          dispatch(tabViewIsMonth({ startDate: data.startDate }));
          break;
        case MENU_SETTING[3].dataViewUnit:
          dispatch(tabViewIsYear({ startDate: data.startDate }));
          break;
        default:
      }
    }

    if (['ClickMenuTab'].includes(typeEvent)) {
      switch (dataViewUnit) {
        case MENU_SETTING[0].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[0].dataViewUnit, startDate: viewIsDay.startDate }));
          await getSalesData(viewIsDay.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[1].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[1].dataViewUnit, startDate: viewIsWeek.startDate }));
          await getSalesData(viewIsWeek.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[2].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[2].dataViewUnit, startDate: viewIsMonth.startDate }));
          await getSalesData(viewIsMonth.startDate, sort, changeDate, dataViewUnit);
          break;
        case MENU_SETTING[3].dataViewUnit:
          dispatch(tabActive({ viewUnit: MENU_SETTING[3].dataViewUnit, startDate: viewIsYear.startDate }));
          await getSalesData(viewIsYear.startDate, sort, changeDate, dataViewUnit);
          break;
        default:
      }
    }
  };

  const handleChangeTab = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof selectedValue;
    setSelectedValue({
      ...selectedValue,
      [name]: event.target.value,
      value: `${event.target.value}`,
    });
  };

  // No5～8 メニュータブ(日、週、年、月)クリック
  const handleMainMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab('', inputValues.sort, dataViewUnit, COMMON.CHANGE_DATE.CURRENT, 'ClickMenuTab'),
    [inputValues.sort, viewIsDay.startDate, viewIsWeek.startDate, viewIsMonth.startDate, viewIsYear.startDate],
  );

  // No9. 並び替えタブクリック
  const handleChangeSort: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      getSalesData(inputValues.startDate, event.target.value, COMMON.CHANGE_DATE.CURRENT, inputValues.dataViewUnit);
    },
    [inputValues.startDate, inputValues.dataViewUnit],
  );

  // No10. スクロール(前)クリック
  const handleClickPrev: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (inputValues.index <= 0) return;
    setInputValues((prevState) => ({
      ...prevState,
      index: prevState.index - 1,
    }));
  }, [inputValues.index]);

  // No10. スクロール(後)クリック
  const handleClickNext: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (inputValues.salesList.length <= inputValues.index + MAX_DISPLAY_BAR) return;
    setInputValues((prevState) => ({
      ...prevState,
      index: prevState.index + 1,
    }));
  }, [inputValues.salesList.length]);

  // No12,14,16,18 切り替え_(前)(日、週、年、月)クリック
  const handlePreviousMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab(
        inputValues.startDate,
        inputValues.sort,
        dataViewUnit,
        COMMON.CHANGE_DATE.PREVIOUS,
        'PreviousMenuTab',
      ),
    [inputValues.startDate, inputValues.sort],
  );
  // No13,15,17,19 切り替え_(後)(日、週、年、月)クリック
  const handleNextMenuTab = useCallback(
    (dataViewUnit: string) =>
      handleClickMenuTab(inputValues.startDate, inputValues.sort, dataViewUnit, COMMON.CHANGE_DATE.NEXT, 'NextMenuTab'),
    [inputValues.startDate, inputValues.sort, inputValues.startDate],
  );
  // No20 対象期間クリック
  const handleClickViewPeriod: MouseEventHandler<HTMLDivElement> = useCallback(() => {
    dispatch(tabActive({ viewUnit: inputValues.dataViewUnit, startDate: '' }));
    switch (inputValues.dataViewUnit) {
      case MENU_SETTING[0].dataViewUnit:
        dispatch(tabViewIsDay({ startDate: '' }));
        break;
      case MENU_SETTING[1].dataViewUnit:
        dispatch(tabViewIsWeek({ startDate: '' }));
        break;
      case MENU_SETTING[2].dataViewUnit:
        dispatch(tabViewIsMonth({ startDate: '' }));
        break;
      case MENU_SETTING[3].dataViewUnit:
        dispatch(tabViewIsYear({ startDate: '' }));
        break;
      default:
    }

    return getSalesData('', inputValues.sort, COMMON.CHANGE_DATE.CURRENT, inputValues.dataViewUnit);
  }, [inputValues.sort, inputValues.dataViewUnit]);

  const disabledNext = inputValues.disabledNext === COMMON.FLAG.ON;

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.content}>
          {/* 上部エリア (戻るボタン、メニュータブエリア(タブ本体はコンテンツエリアに配置)) */}
          <Box className={classes.topArea}>
            {/* 戻るボタン */}
            <Box className="returnButton">
              <ReturnButton onClick={handleClickReturn}>{prevScrInfo.name}</ReturnButton>
            </Box>
            {/* メニュータブエリア */}
            <Box className="menuArea" />
          </Box>
          {/* コンテンツエリア */}
          <Box className={classes.mainArea}>
            <Box className="contentItem">
              {/* コンテンツ上部(メニュータブ) */}
              <Box className={classes.itemTopArea}>
                {MENU_SETTING.map((menu) => (
                  <MenuTab
                    disabledNext={disabledNext}
                    isSelected={inputValues.dataViewUnit === menu.dataViewUnit}
                    onClick={handleMainMenuTab(menu.dataViewUnit)}
                    onPrevious={handlePreviousMenuTab(menu.dataViewUnit)}
                    onNext={handleNextMenuTab(menu.dataViewUnit)}>
                    {menu.label}
                  </MenuTab>
                ))}
              </Box>
              <Box className={classes.itemMainArea}>
                {/* コンテンツメニュー(売上、原価率など) */}
                <Box className="contentMenuArea">
                  <ContentMenuTab isSelected>売上</ContentMenuTab>
                  <ContentMenuTab onClick={handleClickMenuCostRate}>原価率</ContentMenuTab>
                  <Box className="buttonArea">
                    {canDownloadDataSheet && (
                      <NormalButton className={classes.dataSheetbtn} onClick={handleClickDataSheet}>
                        ﾃﾞｰﾀｼｰﾄ発行
                      </NormalButton>
                    )}
                  </Box>
                </Box>
                <Box className="viewArea">
                  <Box className={classes.graphOptionArea}>
                    <Box className="salesLimit">
                      <SelectBox
                        name="name"
                        size={COMMON.SIZE.SMALL}
                        dataSource={option}
                        value={selectedValue.name}
                        onChange={handleChangeTab}
                      />
                    </Box>
                    <Box className="viewPeriod" onClick={handleClickViewPeriod}>
                      {inputValues.viewPeriod}
                    </Box>
                    <span className="sortLabel">並べ替え</span>
                    <SelectBox
                      name="sort"
                      size={COMMON.SIZE.SMALL}
                      dataSource={getClsfcnByCategory(CLSFCN.DATA_VIEW_FILTER.CATEGORY_CD)}
                      value={inputValues.sort}
                      onChange={handleChangeSort}
                    />
                  </Box>
                  <Box className={classes.graphArea}>
                    {/* indexが0より大きい場合表示 */}
                    {inputValues.index > 0 && <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />}
                    <p className="xAxesUnit">ステーション</p>
                    {/* グラフ */}
                    <Box className="graph">
                      <SalesDataGraph
                        index={inputValues.index}
                        salesList={inputValues.salesList}
                        dataViewUnit={inputValues.dataViewUnit}
                        detailNum={inputValues.detailNum}
                        selectedValue={selectedValue}
                      />
                    </Box>
                    {/* index+MAX_DISPLAY_BARがsalesListの件数未満の場合表示。 */}
                    {inputValues.index + MAX_DISPLAY_BAR < inputValues.salesList.length && (
                      <PrevNextButton className="nextScroll" onClick={handleClickNext} />
                    )}

                    <p className="yAxesUnit">売上（万円）</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default SalesDataView;
