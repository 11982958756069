import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useAppSelector } from '../../../redux/hooks';
import { formatDate } from '../../../utils/datetime.helper';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  container: {
    height: 284,
    border: '1px solid #707070',
    borderRadius: 4,
  },
  header: {
    padding: '5px 10px',
    width: '100%',
    height: 33,
    background: '#535B80',
    fontSize: 19,
    color: '#fff',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
  },
  informationText: {
    fontSize: 20,
  },
  informationTextDate: {
    fontSize: 20,
    marginRight: 20,
  },
  informationContainer: {
    margin: '14px 25px',
    height: 224,
    overflowY: 'auto',
  },
  textContain: {
    display: 'flex',
    lineHeight: 2,
  },
}));

/* ************ Component ************ */
const Information: React.FC = () => {
  const classes = useStyles();
  const { information } = useAppSelector((state) => state.manualInformation);

  /* ************ use effect ************ */
  useEffect(() => {
    // Get information public
  }, []);

  const renderInformationItem = useCallback(() => {
    if (information.length === 0) return <></>;
    const informationInfo = information.map((item) => {
      if (item.url !== null && item.url !== '') {
        return (
          <div key={item.id} className={classes.textContain}>
            <div className={classes.informationTextDate}>{formatDate(new Date(item.startDate))}</div>
            <a href={item.url} target="_blank" rel="noreferrer">
              <div className={classes.informationText}>{item.text}</div>
            </a>
          </div>
        );
      }
      return (
        <div key={item.id} className={classes.textContain}>
          <div className={classes.informationTextDate}>{formatDate(new Date(item.startDate))}</div>
          <div className={classes.informationText}>{item.text}</div>
        </div>
      );
    });
    return <div className={classes.informationContainer}>{informationInfo}</div>;
  }, [information]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>お知らせ</div>
      {renderInformationItem()}
    </div>
  );
};

export default Information;
