import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import prizeMachinesGroupIcon from 'assets/images/PrizeMachinesGroupIcon.svg';
import prizeMachinesListIcon from 'assets/images/PrizeMachinesListIcon.svg';
import iconBear from 'assets/images/IconBear.svg';
import iconInCommsSetting from 'assets/images/IconInCommsSetting.svg';
import iconLED from 'assets/images/IconLED.svg';

/**
 * GroupSelectConfButton Component
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// makeStylesでstyleを定義
const useStyles = makeStyles(() => ({
  buttonItem: {
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    fontSize: '22px',
    lineHeight: '28px',
    margin: '50px 0px 50px 180px',
    padding: '10px 0px 10px 0px',
    width: '336px',
    height: '536px',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  marginLeft: {
    marginLeft: '120px',
  },
  topText: {
    fontSize: '40px',
    lineHeight: '50px',
  },
  image: {
    display: 'initial',
  },
  buttonHorizontalItem: {
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    fontSize: '24px',
    margin: '30px 0px 50px 0px',
    padding: '10px 0px 10px 55px',
    width: '640px',
    height: '160px',
    justifyContent: 'left',
    '&:hover': {
      backgroundColor: '#E0E0E0',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  paddingLeft: {
    paddingLeft: '120px',
  },
  btnText: {
    fontSize: '36px',
    paddingLeft: '40px',
  },
  imageContent: {
    display: 'contents',
  },
  iconInCommsSetting: {
    position: 'absolute',
    left: '20px',
    top: '20px',
  },
  imageBear: {
    position: 'absolute',
    left: '56px',
    top: '56px',
  },
}));

type ButtonProps = {
  onClick?: () => void;
  iconName: string;
  disabled?: boolean;
};

// コンポーネント定義
const GroupSelectConfButton: React.FC<ButtonProps> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // propsのプロパティ情報を取得
  const { onClick, iconName, disabled } = props;
  if (iconName === 'prizeMachinesGroupIcon') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonItem}`}>
        <span>
          <p className={`${classes.topText}`}>
            グループ作成
            <br />
            機器登録
          </p>
          <br />
          <p>
            新規にグループを作成し、
            <br />
            任意の機器を登録できます
          </p>
          <br />
          <img className={`${classes.image}`} src={prizeMachinesGroupIcon} alt={iconName} />
        </span>
      </NormalButton>
    );
  }
  if (iconName === 'prizeMachinesListIcon') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonItem} ${classes.marginLeft}`}>
        <span>
          <p className={`${classes.topText}`}>
            登録グループ
            <br />
            一覧
          </p>
          <br />
          <p>
            登録したグループ毎に
            <br />
            各種設定や管理ができます
          </p>
          <br />
          <img className={`${classes.image}`} src={prizeMachinesListIcon} alt={iconName} />
        </span>
      </NormalButton>
    );
  }
  if (iconName === 'iconLED') {
    return (
      <NormalButton disabled={disabled} onClick={onClick} className={`${classes.buttonHorizontalItem}`}>
        <img src={iconLED} alt={iconName} />
        <p className={`${classes.btnText}`}>LED演出設定グループ</p>
      </NormalButton>
    );
  }
  if (iconName === 'iconInCommsSetting') {
    return (
      <NormalButton
        disabled={disabled}
        onClick={onClick}
        className={`${classes.buttonHorizontalItem} ${classes.paddingLeft}`}>
        <div className={`${classes.imageContent}`}>
          <img className={`${classes.iconInCommsSetting}`} src={iconInCommsSetting} alt={iconName} />
          <img className={`${classes.imageBear}`} src={iconBear} alt="iconBear" />
        </div>
        <p className={`${classes.btnText}`}>景品獲得演出設定グループ</p>
      </NormalButton>
    );
  }

  return <></>;
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(GroupSelectConfButton);
