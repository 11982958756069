import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { memo, useEffect, useRef } from 'react';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import { GiftNameTypeTableRow } from 'types/maintenance/GiftType';
import { useHistory } from 'react-router-dom';
import COMMON from 'constants/common';
import redirectLinks from 'constants/redirectLinks';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import EditButton from '../../atoms/buttons/EditButton';

const column1 = 136;
const column2 = 404;
const column3 = 133;
/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  default: {
    height: '408px',
    overflowX: 'hidden',
    marginTop: '14px',
    minWidth: '920px',
  },
  table: {
    tableLayout: 'fixed',
    '& th,td': {
      border: '1px solid #707070',
      fontSize: '23px',
      lineHeight: '20px',
      minHeight: '60px',
    },
    '& th': {
      textAlign: 'center',
      height: '48px',
      padding: 0,
    },
    '& tbody tr': {
      minHeight: '60px',
      '&:nth-of-type(odd)': {
        backgroundColor: '#EFEFEF',
      },
    },
    '& td': {
      padding: '0 8px',
      '& .cellStyle': {
        fontSize: '22px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  head: {
    backgroundColor: '#CFCFCF',
  },
  thGiftName: {
    marginLeft: '-50px',
    width: '100%',
  },
  tdMachineName: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '& .tdEditButton': {
      height: '44px',
      width: '120px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdStationName: {
    textAlign: 'left',
    alignItems: 'center',
    '& .tdDetailButton': {
      height: '44px',
      width: '128px',
      backgroundColor: '#A2FFBB',
      fontSize: '24px',
    },
  },
  tdEquipmentNo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '22px',
  },
  tdAlignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

/* ************ Type ************ */
type Props = {
  rows: Array<GiftNameTypeTableRow>;
  page: number;
  handleSelectedItemChange: (row: GiftNameTypeTableRow) => void;
  handleAllCheckboxChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  checkedAll: boolean;
  scrollTop: boolean;
  setScrollTop: (scrollTop: boolean) => void;
};

/* ************ Component ************ */
const GiftListTable: React.FC<Props> = ({
  rows,
  page,
  handleSelectedItemChange,
  handleAllCheckboxChange,
  checkedAll,
  scrollTop,
  setScrollTop,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // スクロールをTopに移動
    if (tableRef.current && scrollTop) {
      tableRef.current.scrollTo(0, 0);
      setScrollTop(false);
    }
  }, [scrollTop]);

  const headerColumns = [
    { id: 'editColumnId', label: '編集', width: column1 },
    { id: 'giftColumnId', label: '景品名称', width: column2 },
    { id: 'priceColumnId', label: '景品原価', width: column3 },
    { id: 'updatedColumnId', label: '更新日時', width: '100%' },
  ];

  const handleGoToUpdateGiftScreen = (row: GiftNameTypeTableRow) => {
    history.push(redirectLinks.GIFT_DETAIL, { id: row.key });
  };

  return (
    <>
      <TableContainer id="scrollContainer" className={classes.default} ref={tableRef}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headerColumns.map((column) =>
                column.id === 'giftColumnId' ? (
                  <TableCell
                    key={`machine_list_table_head_key_${column.id}`}
                    style={{ width: column.width }}
                    className={classes.head}>
                    <div className={classes.tdEquipmentNo} style={{ paddingLeft: '8px' }}>
                      <ListCheckBox
                        checked={checkedAll}
                        name="selectStation"
                        onChange={(e) => {
                          handleAllCheckboxChange(e);
                        }}
                      />
                      <div className={classes.thGiftName}>{column.label}</div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    key={`machine_list_table_head_key_${column.id}`}
                    style={{ width: column.width }}
                    className={classes.head}>
                    {column.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              if (index >= COMMON.GIFTS_DISPLAYED * (page - 1) && index < COMMON.GIFTS_DISPLAYED * page) {
                const key = `gift_list_table_key_${row.key}`;
                return (
                  <React.Fragment key={key}>
                    <TableRow key={key}>
                      <TableCell className={classes.tdMachineName}>
                        <div>
                          <EditButton onClick={() => handleGoToUpdateGiftScreen(row)}>編集</EditButton>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tdStationName}>
                        <div className={classes.tdEquipmentNo}>
                          <ListCheckBox
                            checked={row.checked}
                            name="selectStation"
                            onChange={() => handleSelectedItemChange(row)}
                          />
                          <TooltipComponent tooltipText={row.giftName} />
                        </div>
                      </TableCell>
                      <TableCell className={classes.tdStationName}>
                        <div className={classes.tdAlignCenter}>
                          <div className="cellStyle">{row.price}</div>
                        </div>
                      </TableCell>
                      <TableCell className={classes.tdStationName}>
                        <div className={classes.tdAlignCenter}>
                          <div className="cellStyle">{row.updatedAt}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              }
              return false;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(GiftListTable);
