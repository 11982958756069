import { REST_API } from 'constants/apiUrls';
import COMMON from 'constants/common';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { AreaConfType, ConfType, MachineConfType, OperationConfType } from 'types/machineConf/machineConfType';
import HttpConnection from 'utils/httpConnection';

interface TypeShowAreaConf {
  showTable: boolean | null;
  showLine: {
    lineFlg1: boolean;
    AreaSpecificSettingsLine2: boolean;
    AreaSpecificSettingsLine3: boolean;
    AreaSpecificSettingsLine4: boolean;
    AreaSpecificSettingsLine5: boolean;
    AreaSpecificSettingsLine6: boolean;
  };
  areaConf?: AreaConfType;
  conf?: ConfType;
}

/**
 * 各エリア設定値の表示可否を取得
 * @param {AreaConfType} areaConf エリア設定
 * @param {OperationConfType} operationConf 運営設定
 * @param {string|null} machineVersion 機器バージョン
 * @param {boolean} isDetail ステーション設定詳細画面かどうか
 * @returns {TypeShowAreaConf}
 */
export const showAreaDetail = (
  areaConf: AreaConfType,
  operationConf: OperationConfType,
  machineVersion: string | null,
  isDetail = false,
): TypeShowAreaConf => {
  let hasArea1 = false;
  let hasArea2 = false;
  let hasArea3 = false;
  let hasArea4 = false;
  let hasArea5 = false;
  let hasArea6 = false;

  let showTable = false;
  let showArea1 = false;
  let showArea2 = false;
  let showArea3 = false;
  let showArea4 = false;
  let showArea5 = false;
  let showArea6 = false;

  if (
    areaConf.line1.includes('0') ||
    areaConf.line2.includes('0') ||
    areaConf.line3.includes('0') ||
    areaConf.line4.includes('0') ||
    areaConf.line5.includes('0') ||
    areaConf.line6.includes('0')
  ) {
    hasArea1 = true;
  }
  if (
    areaConf.line1.includes('1') ||
    areaConf.line2.includes('1') ||
    areaConf.line3.includes('1') ||
    areaConf.line4.includes('1') ||
    areaConf.line5.includes('1') ||
    areaConf.line6.includes('1')
  ) {
    hasArea2 = true;
  }
  if (
    areaConf.line1.includes('2') ||
    areaConf.line2.includes('2') ||
    areaConf.line3.includes('2') ||
    areaConf.line4.includes('2') ||
    areaConf.line5.includes('2') ||
    areaConf.line6.includes('2')
  ) {
    hasArea3 = true;
  }
  if (
    areaConf.line1.includes('3') ||
    areaConf.line2.includes('3') ||
    areaConf.line3.includes('3') ||
    areaConf.line4.includes('3') ||
    areaConf.line5.includes('3') ||
    areaConf.line6.includes('3')
  ) {
    hasArea4 = true;
  }
  if (
    areaConf.line1.includes('4') ||
    areaConf.line2.includes('4') ||
    areaConf.line3.includes('4') ||
    areaConf.line4.includes('4') ||
    areaConf.line5.includes('4') ||
    areaConf.line6.includes('4')
  ) {
    hasArea5 = true;
  }
  if (
    areaConf.line1.includes('5') ||
    areaConf.line2.includes('5') ||
    areaConf.line3.includes('5') ||
    areaConf.line4.includes('5') ||
    areaConf.line5.includes('5') ||
    areaConf.line6.includes('5')
  ) {
    hasArea6 = true;
  }

  const selectedColors = [hasArea1, hasArea2, hasArea3, hasArea4, hasArea5, hasArea6].filter((value) => value === true);
  if (machineVersion === null && isDetail) {
    // バージョン情報が無い（Ver.D）の設定かつ、ステーション設定詳細画面の場合
    // ※AreaConfInfoButton等の表示は、バージョン情報の有無に関わらずバージョン情報ありのレイアウトで表示するため、isDetailで制御する
    const flagOperationConf = operationConf.operationConf === COMMON.OPERATION_CONF.NO_CONF;
    showTable = selectedColors.length >= 2 && flagOperationConf;
    showArea1 = hasArea1;
    showArea2 = hasArea2 && flagOperationConf;
    showArea3 = hasArea3 && flagOperationConf;
    showArea4 = hasArea4 && flagOperationConf;
    showArea5 = hasArea5 && flagOperationConf;
    showArea6 = hasArea6 && flagOperationConf;
  } else {
    const flagAreaConf = areaConf.areaConf === COMMON.SWITCH.ON;
    showTable = flagAreaConf;
    showArea1 = hasArea1 || !flagAreaConf;
    showArea2 = hasArea2 && flagAreaConf;
    showArea3 = hasArea3 && flagAreaConf;
    showArea4 = hasArea4 && flagAreaConf;
    showArea5 = hasArea5 && flagAreaConf;
    showArea6 = hasArea6 && flagAreaConf;
  }

  return {
    showTable,
    showLine: {
      lineFlg1: showArea1,
      AreaSpecificSettingsLine2: showArea2,
      AreaSpecificSettingsLine3: showArea3,
      AreaSpecificSettingsLine4: showArea4,
      AreaSpecificSettingsLine5: showArea5,
      AreaSpecificSettingsLine6: showArea6,
    },
  };
};

function useShowAreaConf({ selectConfId }: { selectConfId: string }): TypeShowAreaConf {
  const [areaConf, setAreaConf] = useState<AreaConfType>();
  const [conf, setConf] = useState<ConfType>();

  const dispatch = useAppDispatch();
  const [dataAreaConf, setDataAreaConf] = useState<TypeShowAreaConf>({
    showTable: null,
    showLine: {
      lineFlg1: false,
      AreaSpecificSettingsLine2: false,
      AreaSpecificSettingsLine3: false,
      AreaSpecificSettingsLine4: false,
      AreaSpecificSettingsLine5: false,
      AreaSpecificSettingsLine6: false,
    },
  });

  const getSettingDetail = async (confId: number) => {
    const http = new HttpConnection({ dispatch });
    const data = await http.post<MachineConfType>(REST_API.MACHINE_CONF.GET_SETTING_DETAIL, { confId });
    if (!data) return;

    if (data.areaConf && data.operationConf && data.conf) {
      setAreaConf(data.areaConf);
      setConf(data.conf);
      // 保存設定一覧→設定詳細の場合はselectedStationを参照しないため、conf.machineVersionの値を参照で統一する
      const areaDetail = showAreaDetail(data.areaConf, data.operationConf, data.conf.machineVersion, true);

      setDataAreaConf({
        showTable: areaDetail.showTable,
        showLine: {
          lineFlg1: areaDetail.showLine.lineFlg1,
          AreaSpecificSettingsLine2: areaDetail.showLine.AreaSpecificSettingsLine2,
          AreaSpecificSettingsLine3: areaDetail.showLine.AreaSpecificSettingsLine3,
          AreaSpecificSettingsLine4: areaDetail.showLine.AreaSpecificSettingsLine4,
          AreaSpecificSettingsLine5: areaDetail.showLine.AreaSpecificSettingsLine5,
          AreaSpecificSettingsLine6: areaDetail.showLine.AreaSpecificSettingsLine6,
        },
      });
    }
  };

  useEffect(() => {
    const confId = Number(selectConfId);
    getSettingDetail(confId);
  }, [selectConfId]);

  return {
    showTable: dataAreaConf.showTable,
    showLine: {
      lineFlg1: dataAreaConf.showLine.lineFlg1,
      AreaSpecificSettingsLine2: dataAreaConf.showLine.AreaSpecificSettingsLine2,
      AreaSpecificSettingsLine3: dataAreaConf.showLine.AreaSpecificSettingsLine3,
      AreaSpecificSettingsLine4: dataAreaConf.showLine.AreaSpecificSettingsLine4,
      AreaSpecificSettingsLine5: dataAreaConf.showLine.AreaSpecificSettingsLine5,
      AreaSpecificSettingsLine6: dataAreaConf.showLine.AreaSpecificSettingsLine6,
    },
    areaConf,
    conf,
  };
}

export default useShowAreaConf;
