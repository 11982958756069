import { useCallback, useRef, useState } from 'react';
import { LongPressType } from 'types/system/customHookType';

/**
 * useLongPress
 * 長押しのための関数
 * クリックと長押しを実現する。
 * @author: atsushi.teruya
 *
 * @param {function} onClick - クリック時の処理
 * @param {function} onLongPress - 長押し時の処理
 * @returns {object} option - shouldPreventDefault: boolean - touch時の他イベント停止, delay: number - 長押し処理の実行間隔
 */
const useLongPressContinue: LongPressType = (
  onClick,
  onLongPress,
  { shouldPreventDefault, delay } = { shouldPreventDefault: true, delay: 300 },
) => {
  // state for judge long press
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  // timer for judge long press
  const timer = useRef<NodeJS.Timeout>();
  // target for preeventDefault
  const target = useRef<EventTarget>();

  // start to long press or click
  const start = useCallback(
    (event: React.UIEvent<HTMLButtonElement>) => {
      // touchend時には他のイベントを引き起こさないようにする。
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        // イベントが発生したdomを保持。
        target.current = event.target;
      }
      // 長押し処理を予約し、タイマーidを保持。
      timer.current = setInterval(() => {
        onLongPress(event);
        // 長押し処理中を設定
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  // clear (or cancel) to long press, or execute click
  const clear = useCallback(
    (event?: React.UIEvent<HTMLButtonElement>, shouldTriggerClick = true) => {
      if (timer.current) clearInterval(timer.current);
      // タイマーを初期化
      timer.current = undefined;
      if (shouldTriggerClick && !longPressTriggered && event) onClick(event);
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

// touch Eventの判定。不要だが、念のために入れる。
const isTouchEvent = (event: Event): boolean => 'touches' in event;

// 他イベントのキャンセル処理
// Event型がlib.dom.d.tsとglobal.d.tsで競合しエラーとなるため、途中でキャストする。
const preventDefault: EventListenerOrEventListenerObject = (event): void => {
  // touch eventではない処理を中断
  if (!isTouchEvent(event)) return;

  // touchが1件(同時タッチが1つのみ)の場合かつキャンセル可能な場合はキャンセルする。
  if ((event as TouchEvent).touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPressContinue;
