import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHistory } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import redirectLinks from 'constants/redirectLinks';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import GroupConfTopButton from 'components/group/atoms/buttons/GroupConfTopButton';
import { getUserAuthThunk } from 'redux/slices/commonSlice';
import { actIsCrateGroup } from 'redux/slices/activeCrateGroupSlice';

/* ************ Context ************ */
// title componetに渡す値
const ScreenContextValue = {
  title: 'グループ設定TOP',
  screenId: 'SCR411',
  currentMenu: COMMON.MENU.GROUP,
  initializeState: true,
};

const GroupConfTop: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.common.userInfo);
  const disabledButtons = userInfo?.currentHeadQId == null || userInfo?.currentStoreId == null;

  /* ************ Event ************ */
  // Nox. ページ読み込み
  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  // No1. グループ作成機器登録クリック
  const handleClickGroupSelectConf = () => {
    dispatch(actIsCrateGroup(true));
    history.push(redirectLinks.GROUP_SELECT_CONF);
  };
  // No2. 登録グループ一覧クリック
  const handleClickGroupList = () => {
    dispatch(actIsCrateGroup(false));
    history.push(redirectLinks.GROUP_LIST_DATA);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <GroupConfTopButton
          disabled={disabledButtons}
          onClick={handleClickGroupSelectConf}
          iconName="prizeMachinesGroupIcon"
        />
        <GroupConfTopButton
          disabled={disabledButtons}
          onClick={handleClickGroupList}
          iconName="prizeMachinesListIcon"
        />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default GroupConfTop;
