import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IInformationType,
  IManualInformationStateType,
  IManualType,
} from 'types/manualInformation/manualInformationType';

const initialState: IManualInformationStateType = {
  manuals: [],
  information: [],
  browsingDate: '',
  isShowNewNotify: false,
};

const slice = createSlice({
  name: 'manualInformation',
  initialState,
  reducers: {
    initializeManualInformationSlice(state) {
      const tempInitialState = { ...initialState };
      return Object.assign(tempInitialState, {
        browsingDate: state.browsingDate,
        isShowNewNotify: state.isShowNewNotify,
      });
    },
    setInformationList(state, action: PayloadAction<Array<IInformationType>>) {
      state.information = action.payload;
    },
    setManualsList(state, action: PayloadAction<Array<IManualType>>) {
      state.manuals = action.payload;
    },
    setBrowsingDate(state, action: PayloadAction<string>) {
      state.browsingDate = action.payload;
    },
    setIsShowNewNotify(state, action: PayloadAction<boolean>) {
      state.isShowNewNotify = action.payload;
    },
    clearManualInformation(state) {
      state.manuals = [];
      state.information = [];
      state.browsingDate = '';
      state.isShowNewNotify = false;
    },
  },
});

export const {
  initializeManualInformationSlice,
  setInformationList,
  setManualsList,
  setBrowsingDate,
  setIsShowNewNotify,
  clearManualInformation,
} = slice.actions;
export default slice.reducer;
