import { makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import redirectLinks from 'constants/redirectLinks';
import React, { useCallback, useState } from 'react';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  setMachineStatusBaseConf,
  setMachineStatusSelectStation,
  cancelMachineConfThunk,
  getStationListThunk,
  setAutoSelectedStationList,
  setSelectedStationList,
  setSelectedStationInfo,
} from 'redux/slices/machineConfSlice';
import { StationTableRowType, StationTableType, StationDetailType } from 'types/machineConf/stationSelectType';
import UrlHelper from 'utils/url.helper';
import ListCheckBox from 'components/system/atoms/checkBoxs/ListCheckBox';
import ListRadio from 'components/system/atoms/radioButtons/ListRadio';
import { ConfirmDialog } from 'components/system/organisms';
import MESSAGES from 'constants/messages';
import COMMON from 'constants/common';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import { getMachineVersion } from 'utils/common.helper';

/**
 * StationTable Component
 * ステーション一覧
 * @author atsushi.teruya
 *
 */

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  container: {
    height: '428px',
    // 1080pxにしても実際のサイズがあわないので+20pxする。
    width: '1100px',
  },
  table: {
    tableLayout: 'fixed',
    border: '1px solid',
    borderColor: theme.base.tableBorder,
    '& th,td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.base.tableBorder,
      fontSize: '16px',
      lineHeight: '20px',
    },
    '& tbody tr': {
      height: '58px',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.base.tableRowPrimary,
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: theme.base.tableRowSecondory,
    },
    '& td': {
      padding: '0px 8px 0px 8px',
      '& .cellStyle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'fade',
      },
    },
  },
  tdSelectStation: {
    paddingLeft: '0px 0px 0px 0px',
    textAlign: 'center',
  },
  tdStation: {
    textAlign: 'left',
  },
  tdReceiveWaiting: {
    textAlign: 'center',
  },
  tdBaseConfSelect: {
    textAlign: 'center',
  },
  tdConfDetail: {
    textAlign: 'center',
  },
  tdStatus: { textAlign: 'center' },
  tdCorrespondenceStatus: { textAlign: 'center', padding: '0px 6px 0px 6px !important' },
  tdCorrespondenceDate: {
    textAlign: 'center',
  },
  selectedBaseConf: { backgroundColor: '#FFFF84' },
  confDetail: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  receiveWaiting: {
    backgroundColor: '#B8FFC9',
    borderColor: '#B8FFC9',
    color: theme.palette.common.black,
    height: '40px',
    width: '120px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: '#B8FFC9',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
    },
  },
  '&:hover': {
    backgroundColor: '#B8FFC9',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
  },
}));

/* ************ Component ************ */
/* ********    Table Item     ******** */
/**
 * 受取待機列
 * 設定送信後プライズ機から設定通知が来るまでの間、受取待機中を表示する。
 *
 * @author atsushi.teruya
 * @param {number} - receiveWaiting 仮置き
 * @returns {React.FC} - ステーション一覧の明細行
 *
 */
const ReceiveWaitingColumn: React.FC<{ receiveWaiting: number; confId: number; stationId: number }> = (props) => {
  const classes = useStyles();
  const { receiveWaiting } = props;
  const { stationId } = props;
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const dispatch = useAppDispatch();
  const handleReceiveWaitingClick = () => setOpenConfirmDialog(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleCloseInfoDialog = (): void => {
    setOpenConfirmDialog(false);
  };

  // 受取待機中 OKボタン クリック
  const handleClickOk = async (): Promise<void> => {
    setOpenConfirmDialog(false);
    await dispatch(
      cancelMachineConfThunk({
        stationId,
        headquartersId: loginUserInfo?.currentHeadQId,
        storeId: loginUserInfo?.currentStoreId,
      }),
    );
    dispatch(setAutoSelectedStationList()); // 選択中ステーションを更新
    // Re-load station list when Cancel Machine Config
    dispatch(
      getStationListThunk({ headquartersId: loginUserInfo?.currentHeadQId, storeId: loginUserInfo?.currentStoreId }),
    );
  };
  return receiveWaiting === 1 ? (
    <div>
      <NormalButton className={classes.receiveWaiting} onClick={handleReceiveWaitingClick}>
        受取待機中
      </NormalButton>
      <ConfirmDialog
        open={openConfirmDialog}
        msg={MESSAGES.INF205}
        onOk={handleClickOk}
        onCancel={handleCloseInfoDialog}
      />
    </div>
  ) : null;
};

/* ******** Control Component ******** */
/**
 * ステーション一覧 明細行
 * ステーション一覧の行を出力する。
 * memo：基準設定が変更された行のみ再描画する。
 *
 * @author atsushi.teruya
 * @param {StationTableType} -  {row:ステーション一覧, index：対象の行, selectedBaseConfRowIdx：選択中の基準設定がある行のindex}
 * @returns {React.FC} - ステーション一覧の明細行
 *
 */
const StationTableRow: React.FC<StationTableRowType> = React.memo(({ row, index, selectedList }) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  /* ************ Event ************ */
  // ステーション選択(チェックボックス)
  const handleChangeSelectStation = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setMachineStatusSelectStation({
          index,
          value: Number(event.target.value),
        }),
      );
    },
    [index],
  );

  // 基準設定ラジオボタン選択
  const handleChangeBaseConf = useCallback(
    // 選択結果をステートに反映
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setMachineStatusBaseConf({
          index,
          value: Number(event.target.value),
        }),
      );
    },
    [index],
  );

  // 設定詳細ボタンクリック
  const onClickConfDetail = useCallback(() => {
    // 初期化
    // 基準設定のステーションを抽出
    const baseConf = selectedList.find((s) => s.baseConfSelect === s.stationId);
    if (baseConf) {
      // 基準設定のステーションを登録
      dispatch(
        setSelectedStationInfo({
          stationName: baseConf.stationName ?? '',
          giftName: baseConf.giftName ?? '',
          leftRight: baseConf.leftRight,
          boardStation: baseConf.boardStation,
          machineType: baseConf.machineType,
          machineVersion: baseConf.machineVersion,
        }),
      );
    }
    // 選択中のステーションを登録
    dispatch(setSelectedStationList(selectedList));

    // 画面用のurl変換メソッドを利用し、遷移する
    const redirect =
      row.machineType === COMMON.MACHINE_TYPE.JACK
        ? redirectLinks.MCONF_JACK_HOME_DETAIL
        : redirectLinks.MCONF_STATION_HOME_DETAIL;

    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirect, {
        selectConfId: String(row.confId),
      }),
      { from: location.pathname },
    );
  }, [row.stationId, selectedList]);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tdSelectStation}>
          {/** 選択チェックボックス（受取待機中の場合は選択できない） */}
          <ListCheckBox
            checked={row.confReceive === COMMON.CONF_RECEIVE.WAITING ? false : !!row.selectStation}
            name="selectStation"
            onChange={handleChangeSelectStation}
            disabled={row.confReceive === COMMON.CONF_RECEIVE.WAITING}
          />
        </TableCell>
        <TableCell className={classes.tdStation}>
          {/* ステーションに紐づく景品名が取得できない場合、ブランクを表示 */}
          <TooltipComponent
            tooltipText={row.giftName}
            displayWord={`${row.stationName}：${row.giftName || COMMON.BLANK}`}
          />
        </TableCell>
        <TableCell className={classes.tdReceiveWaiting}>
          {row.confReceive === COMMON.CONF_RECEIVE.WAITING && (
            <ReceiveWaitingColumn confId={row.confId} stationId={row.stationId} receiveWaiting={1} />
          )}
        </TableCell>
        <TableCell className={classes.tdBaseConfSelect}>
          {/* 基準の設定にする。 ラジオボタンの表示制御 ステーション選択時のみ表示 */}
          {row.selectStation ? (
            <ListRadio
              checked={row.stationId === row.baseConfSelect}
              className={row.stationId === row.baseConfSelect ? classes.selectedBaseConf : ''}
              name="baseConf"
              value={row.stationId}
              onChange={handleChangeBaseConf}
            />
          ) : null}
        </TableCell>
        <TableCell>
          {/* クレナ3ジャックのステーションはmachineVersion非表示 */}
          {row.machineType === COMMON.MACHINE_TYPE.CLENA3 ? `(Ver.${getMachineVersion(row.machineVersion)})` : null}
        </TableCell>
        <TableCell className={classes.tdConfDetail}>
          <NormalButton className={classes.confDetail} onClick={onClickConfDetail}>
            設定詳細
          </NormalButton>
        </TableCell>
      </TableRow>
    </>
  );
});

/* ******** Main Component ******** */
/**
 * ステーション一覧
 * ステーションの一覧を表示する
 *
 * @author atsushi.teruya
 * @param {StationTableType} -  {stationList:ステーション一覧, selectedBaseConfRowIdx：選択中の基準設定がある行のindex}
 * @returns {React.FC} - ステーション一覧
 *
 */
const StationTable: React.FC<StationTableType> = ({ stationList, selectedBaseConfRowIdx, tableId }) => {
  const classes = useStyles();

  /* ************ state/redux ************ */

  /* ************ Event ************ */

  // テーブル列定義 idは利用しないが定義する。
  const cols = [
    { id: 'stationSelect', width: 56 },
    { id: 'station', width: 453 },
    { id: 'receiveWaiting', width: 136 },
    { id: 'baseConfSelect', width: 216 },
    { id: 'machineVersion', width: 76 },
    { id: 'confDetail', width: 143 },
  ];

  return (
    <>
      <TableContainer id={tableId} className={classes.container}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          {cols.map((column) => (
            <col key={column.id} style={{ width: column.width }} />
          ))}
          <TableBody>
            {stationList.map((row, index) => (
              <StationTableRow
                key={String(index)}
                row={row}
                index={index}
                selectedBaseConfRowIdx={selectedBaseConfRowIdx}
                selectedList={stationList.filter((value: StationDetailType) => value.selectStation)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StationTable;
