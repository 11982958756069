import React, { memo, useEffect, useState } from 'react';
import { createStyles, makeStyles, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { NormalSelectBox } from 'components/system/organisms';
import NormalButton from 'components/system/atoms/buttons/NormalButton';
import CLSFCN from 'constants/classification';
import { getClsfcnByCategory } from 'utils/common.helper';
import { MachineStatus } from 'types/home/machineStatus';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import MachineStatusTable from '../organisms/tables/MachineStatusTable';

/* ************ Style ************ */
const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      height: '256px',
    },
    controller: {
      display: 'flex',
      position: 'relative',
      margin: '0 12px 0 auto',
    },
    sort: {
      margin: '4px 0 10px 0',
    },
    MSListBtn: {
      position: 'relative',
      top: '-8px',
      width: '124px',
      height: '49px',
      margin: '0 0 0 56px',
      fontSize: '22px',
      borderRadius: '8px',
      color: theme.palette.common.black,
      background: theme.base.gray,
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      '&:hover': {
        background: theme.base.gray,
        boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.5)',
      },
    },
  }),
);

/* ************ Component ************ */
const MachineStatusInfo: React.FC = memo(() => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const classes = useStyles();
  // 選択肢から一部除外
  const dataSouceSort = getClsfcnByCategory(CLSFCN.ALERT_SORT.CATEGORY_CD).filter(
    (cls) =>
      ![CLSFCN.ALERT_SORT.STATUS_PEND, CLSFCN.ALERT_SORT.STATUS_NEW_OR_PEND, CLSFCN.ALERT_SORT.STATUS_DONE].includes(
        cls.value,
      ),
  );

  /* ************ state/redux ************ */
  const [sort, setSort] = useState<string>(CLSFCN.ALERT_SORT.ALL_NEW);
  const [rows, setRows] = useState<Array<MachineStatus>>([]);

  useEffect(() => {
    getAlertInfoApi(sort);
  }, [loginUserInfo]);

  /* ************ API ************ */
  const getAlertInfoApi = async (prmSort: string) => {
    if (!(loginUserInfo?.currentHeadQId && loginUserInfo?.currentStoreId)) return;
    const http = new HttpConnection({ dispatch });
    const data = await http.get<Array<MachineStatus>>(REST_API.HOME.GET_TOP_MACHINE_STATUS_LIST, {
      headquarters_id: loginUserInfo?.currentHeadQId,
      store_id: loginUserInfo?.currentStoreId,
      sort: prmSort,
    });
    setRows(data);
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(ev.target.value);
    getAlertInfoApi(ev.target.value);
  };

  const handleClickMSList = () => {
    history.push(redirectLinks.HOME_MACHINE_STATUS_LIST);
  };

  return (
    <>
      <Box className={classes.controller}>
        <Box className={classes.sort}>
          <NormalSelectBox
            name="sort"
            dataSource={dataSouceSort}
            value={sort}
            onChange={handleChangeSort}
            label="並び替え"
          />
        </Box>
        <NormalButton onClick={handleClickMSList} className={classes.MSListBtn}>
          一覧
        </NormalButton>
      </Box>
      <MachineStatusTable
        rows={rows}
        sort={sort}
        className={{ root: classes.table }}
        callGetAlertInfoApi={getAlertInfoApi}
        isTop
      />
    </>
  );
});

export default MachineStatusInfo;
