import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from 'assets/images/InfoIcon.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ZIndexProperty } from '@material-ui/core/styles/zIndex';

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '1700 !important' as string & ZIndexProperty,
    '& div > div': {
      backgroundColor: theme.palette.common.black,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'start',
    width: 640,
    padding: '12px 24px',
    '& div': {
      paddingLeft: 8,
    },
    '& p': {
      color: theme.palette.common.white,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
  },
  message: {
    fontSize: '28px',
    padingLeft: 8,
  },
  detailmsg: {
    fontSize: '24px',
    padingLeft: 8,
  },
  actions: {
    display: 'flex',
    width: 640,
    padding: '12px 24px',
  },
  buttonIcon: {
    height: 80,
    width: 200,
    fontSize: 28,
    borderRadius: 12,
  },
  buttonOk: {
    backgroundColor: '#FF3E3E',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FF3E3E',
    },
    '&:active': {
      backgroundColor: '#FF7070',
    },
  },
}));

export type InfoDialogProps = {
  open: boolean;
  msg: string;
  detailMsg?: string;
  closeBtnLabel?: string;
  closeFunc?: () => void;
};

const InfoDialog: React.FC<InfoDialogProps> = ({
  open,
  msg,
  detailMsg,
  closeBtnLabel = 'はい',
  closeFunc,
  children,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={closeFunc} className={classes.dialog} maxWidth={false}>
      <DialogContent className={classes.content}>
        <img src={InfoIcon} alt="Info" />
        <div>
          <DialogContentText className={classes.message}>{msg}</DialogContentText>
          {detailMsg && <DialogContentText className={classes.detailmsg}>{detailMsg}</DialogContentText>}
          {children}
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={closeFunc} className={`${classes.buttonIcon} ${classes.buttonOk}`}>
          {closeBtnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
