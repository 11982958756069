const MESSAGES = {
  INF100: 'ログアウトしますか？',
  INF101: '落とし穴を有効にしてください。',
  INF102: '落とし穴を設定してください。',
  INF103: '{0}を入力してください。',
  INF104: '落とし穴を最低１ヵ所設定してください。',
  INF105: '入力されたタイトルは無効です。',
  INF108: '数値は{0}以上{1}以下の値を入力してください。',
  INF109: 'アームパワーは整数3桁、小数1桁まで入力できます。',
  INF110: 'ステーションを1件以上選択してください。',
  INF111: '{0}文字以下で入力してください。',
  INF112: '{0}を1件以上選択してください。',
  INF113: '数値が{0}以上の場合は、整数で設定してください。',
  INF114: '{0}は無効な日付です。有効な日付を指定してください。',
  INF115: '数値が50以上100より小さい場合は、小数点を0.5刻みで設定してください。',
  INF116: '数値は0.1以上{0}より小さい値を入力してください。',
  INF117: '数値は{0}より大きく150以下の値を入力してください。',
  INF118: '上限プレイ回数はプレイ回数より大きい値に設定してください。',
  INF201:
    '「ピンポイント」の設定を行うと、\n現在の「落とし穴設定」は反映されません。\n「ピンポイント」の設定を行いますか？',
  INF202:
    '設定を筐体に送信します。筐体での「設定受け取り」を行ってください。\n受け取り操作を行うまでは、設定は反映されません。\n\n',
  INF203:
    '設定を筐体に送信しました。\n\n筐体での「設定受け取り」を行ってください。\n受け取り操作を行うまでは、設定は反映されません。',
  INF204: '設定を 「強制反映」 しました。\n\n運営に問題が無いか、ご確認ください',
  INF205: '送信した設定をキャンセルします。よろしいですか？',
  INF206:
    '設定を初期値から設定開始します。\n初期値のまま反映する場合は、\nそのまま「設定を筐体に送信」ボタンを押してください。',
  INF207:
    '※緊急で反映させたい場合は、\n「強制反映」を選択してください。\nクレジットが０の時点で設定が強制反映されます。',
  INF209:
    '「新ペイアウトモード」、「新サービスモード」、「ずらし運営」 いずれかを設定していた場合、これらの設定は「OFF」 または 「無効」 になります。',
  INF210: '設定受取中のステーションが含まれています。\n設定を送信してよろしいですか？',
  INF211: '緊急対応として、「エラー表示」を行い、\n運営を止めますか？',
  INF212: '※クレジットが0の状態で運営を停止します',
  INF213: '運営を止める信号を送信しました。',
  INF214: 'クレジットが０の時点で「エラー表示」が行われます。必ず、実機での確認を行ってください。',
  INF215: '{0}を更新しますか？',
  INF216: '{0}を削除しますか？',
  INF217:
    '「新サービスモード」、「ずらし運営」のいずれかを設定していた場合、これらの設定は強制的に 「OFF」 になります。',
  INF218:
    '設定を「OFF」にします。\n「エリア設定」が「有効」になります。\n（キャッチャーの設定は「基本のアームパワー」になります。）',
  INF219: 'アラートをリセットしますか？\n※アラート計算のための数値をリセットし、再計算します。',
  INF220:
    '「プレイヤーサポートモード」、「ずらし運営」、\n「エリア設定」 のいずれかを設定していた場合、\nこれらの設定は、\n強制的に 「OFF」 になります。',
  INF221: '設定を 「OFF」 にします。\nOFF後のアームパワーは、現在設定していた、\n「基本のアームパワー」 になります。',
  INF222:
    '「プレイヤーサポートモード」、\n「新サービスモード」、「エリア設定」 の\nいずれかを設定していた場合、これらの設定は、\n強制的に 「OFF」 になります。',
  INF223: '設定を「OFF」にします。\n「エリア設定」が「有効」になります。',
  INF224: '{0}を登録します。\nよろしいですか。',
  INF225: '{0}画面に遷移します。',
  INF226: '以下の機器を削除します。',
  INF227: 'CSVファイルを選択し、\n登録ボタンを押してください。',
  INF228: '以下の{0}を削除します。\n{1}',
  INF229: 'サーバー上に機器登録を行いますか？',
  INF230: '更新を行いますか？',
  INF231:
    '景品詳細を更新しますか？\n景品が紐づくステーションがある場合は下記が実施されます。\n　・ステーションの景品原価の更新\n　・アラートリセット',
  INF232: '景品を登録します。\nよろしいですか？',
  INF233: '「保留」対応となったステーションアラートは、リストから除外されます。',
  INF234: '{0}を選択してください。',
  INF235:
    '機器側にて受入操作を行ってください。\n' +
    'この画面を閉じたりログアウト状態になると、\n' +
    '機器登録が出来ない恐れがあります。\n' +
    'その場合は再度機器登録作業を行ってください。',
  INF236: '機器に登録情報を送信しましたが\n機器からの受入操作でキャンセルされました。',
  INF237:
    '機器に登録情報を送信しましたが\n' +
    '機器からの受入操作が一定時間なかった\n' +
    'ため、キャンセルされました。\n' +
    '再度「登録」ボタンを押したのち、\n' +
    '機器の設定画面より受入操作を行ってください。',
  INF239: '機器情報が送信できませんでした。\n登録番号が間違っていないか\n確認を行ってください。',
  INF240:
    '「かんたん」または「詳細」の設定を行うと、\n現在の「落とし穴設定」は反映されません。\n「かんたん」または「詳細」の設定を行いますか？',
  INF241:
    '初期高さより高い下降限界が設定されておりました。エリアパワー設定画面の下降限界を変更しましたので、確認してください',
  INF242: '設定できる範囲は({0}～{1})です。\n範囲外の場合は自動で修正を行います。',
  INF243:
    '設定中のステーションより古いバージョンの設定内容です。以下の設定内容は正しく反映されない場合がありますがよろしいですか？\n' +
    '・プレイヤーサポートモード内の項目\n' +
    ' └ つかみ時のパワー\n' +
    ' └ 移動時のパワー\n' +
    ' └ タイミング（基本のパワーに戻るまでの高さ）\n' +
    '・エリアごとの設定内容\n' +
    '・150より大きいパワーの設定値\n\n' +
    '（正しく反映されない場合、初期値が入ります）',
  INF244:
    '異なるバージョンのステーションが同時に選択されています。以下の設定内容は、古いバージョンの筐体に正しく反映されない場合がありますがよろしいですか？\n' +
    '・プレイヤーサポートモード内の項目\n' +
    ' └ つかみ時のパワー\n' +
    ' └ 移動時のパワー\n' +
    ' └ タイミング（基本のパワーに戻るまでの高さ）\n' +
    '・エリアごとの設定内容\n' +
    '・150より大きいパワーの設定値\n\n' +
    '（正しく反映されない場合、初期値が入ります）',
  INF245:
    '古いバージョンのステーションが選択されています。以下の設定内容は正しく反映されない場合がありますがよろしいですか？\n' +
    '・プレイヤーサポートモード内の項目\n' +
    ' └ つかみ時のパワー\n' +
    ' └ 移動時のパワー\n' +
    ' └ タイミング（基本のパワーに戻るまでの高さ）\n' +
    '・エリアごとの設定内容\n' +
    '・150より大きいパワーの設定値\n\n' +
    '（正しく反映されない場合、初期値が入ります）',
  INF246:
    '設定中のステーションより新しいバージョンの設定内容です。以下の設定内容は正しく反映されない場合がありますがよろしいですか？\n' +
    '・プレイヤーサポートモード内の項目\n' +
    ' └ つかみ時のパワー\n' +
    ' └ 移動時のパワー\n' +
    ' └ タイミング（基本のパワーに戻るまでの高さ）\n' +
    '・エリアごとの設定内容\n' +
    '・150より大きいパワーの設定値\n\n' +
    '（正しく反映されない場合、初期値が入ります）',
  INF247: '設定を「OFF」にします。\nキャッチャーの設定は、現在設定していた値になります。',
  INF248: '設定を「OFF」にします。\nアームパワーは、現在設定していた「基本のアームパワー」になります。',
  INF301: '{0}を保存しました。',
  INF302: '{0}を登録しました。',
  INF303: '{0}を更新しました。',
  INF304: '{0}を削除しました。',
  INF305: '{0}を解除しました。',
  INF306: '{0}を完了しました。',
  INF307: '読込登録が完了しました。\n以下の景品は重複しているためスキップしました。\n{0}',
  INF308: '{0}を登録しました。\n{1}画面に戻ります。',
  INF309: '{0}を更新しました。\n{1}画面に戻ります。',
  INF310: 'CSVを発行しました。',
  INF311: '機器の登録を解除し、\nこのグループを削除します。',
  INF312: '新しいバージョンのアプリが公開されました。\n画面を再読み込みし、アプリをアップデートします。',
  INF538: '{0}をダウンロードしますか？\n',
  ERR601: '設定情報が見つかりません。もう一度保存設定一覧から選択してください。',
  ERR602: 'ファイルのダウンロードに失敗しました。',
  ERR603: '下降限界は初期高さより大きく設定してください。',
  ERR604: '他のユーザが既に更新している可能性があります。お手数ですが再度画面を開きなおして操作を実施してください。',
  ERR605: '機種の異なるステーションは同時に設定できません。選択する機種は1種類のみにしてください。',
};
export default MESSAGES;
