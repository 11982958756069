import COMMON from 'constants/common';
import React, { useCallback, useEffect, useState } from 'react';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import MachineConfContent from 'components/machineConf/organisms/contents/MachineConfContent';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NormalSwitch, ReturnButton } from 'components/system/atoms';
import DoneButton from 'components/system/atoms/buttons/DoneButton';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import redirectLinks from 'constants/redirectLinks';
import { useHistory } from 'react-router-dom';
import { handleCallSystemError } from 'redux/slices/commonSlice';
import ERROR_CODE from 'constants/errorCode';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { themeColor } from 'constants/theme';
import downArrowIcon from 'assets/images/downArrowIcon_3.svg';
import polyLineIconMedium from 'assets/images/polyLineIconMedium.svg';
import polyLineIconLarge from 'assets/images/polyLineIconLarge.svg';
import { OperationConfType } from 'types/machineConf/machineConfType';
import { setOperationConf } from 'redux/slices/machineConfSlice';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'シーケンス設定',
  screenId: 'SCR335',
  currentMenu: COMMON.MENU.MACHINE_CONF,
  disableContents: true,
};

/* ************ Style ************ */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '1080px',
    minHeight: '563px',
    margin: '18px 24px',
  },
  topContent: {
    display: 'flex',
    '& .discription': {
      fontSize: '23px',
      marginLeft: '16px',
    },
  },
  middleContent: {
    display: 'flex',
    position: 'relative',
    marginTop: '20px',
    '& .catcherOperation': {
      width: '670px',
      height: '361px',
    },
    '& .catcherReturn': {
      width: '327px',
      height: '361px',
      marginLeft: '36px',
    },
    '& .title': {
      fontSize: '21px',
      textAlign: 'center',
    },
    '& .content': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .toggleArea': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '276px',
      height: '72px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '4px',
      padding: '4px 16px',
      '&.movingShift': {
        border: 'none',
      },
    },
    '& .toggleName': {
      fontSize: '21px',
      '&.small': {
        fontSize: '19px',
      },
    },
  },
  bottomContent: {
    marginTop: 'auto',
    marginLeft: 'auto',
  },
  leftStyle: {
    borderColor: themeColor.leftDarkColor,
  },
  rightStyle: {
    borderColor: themeColor.rightDarkColor,
  },
  baseArea: {
    backgroundColor: '#FFF',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '6px 24px',
  },
  downArrowIcon: {
    margin: '4px auto',
  },
  polyLineIconMedium: {
    position: 'absolute',
    top: '60px',
    left: '301px',
  },
  polyLineIconLarge: {
    position: 'absolute',
    top: '60px',
    left: '644px',
  },
  doneImage: {
    width: '56px',
    height: '28px',
    padding: '2px 0px',
    borderRadius: '4px',
    fontSize: '16px',
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'inline-block',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

/* ******** Constants ******** */
const INPUT_NAMES = {
  MOVING_X: 'movingX',
  MOVING_Y: 'movingY',
  OPENING_ARM: 'openingArm',
  DESCENDING: 'descending',
  CLOSING_ARM: 'closingArm',
  MOVING_PIT: 'movingPit',
  FINISHING_ARM: 'finishingArm',
  MOVING_HOME: 'movingHome',
};

/* ******** Main Component ******** */
/**
 *
 * @returns {React.FC} - シーケンス設定
 *
 */
const SequenceConf: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const operationConf = useAppSelector((state) => state.machineConf.machineConf.operationConf);
  const leftRight = useAppSelector((state) => state.machineConf.selectedStation.leftRight);
  const stationStyle = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftStyle : classes.rightStyle;

  const [tempOperationConf, setTempOperationConf] = useState<OperationConfType | undefined>(operationConf);

  /* ************ Event ************ */

  useEffect(() => {
    if (!operationConf) {
      dispatch(handleCallSystemError({ errorNo: ERROR_CODE.NOT_FOUND }));
    }
  }, []);

  // 戻るボタン
  const handleclickReturn = () => {
    history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
  };

  // NormalSwitch 押下 共通処理
  const handleClickSwitch = useCallback(
    (event) => {
      const { id, checked }: { id: string; checked: boolean } = event.target;
      setTempOperationConf((prevState) =>
        prevState
          ? {
              ...prevState,
              [id]: checked ? COMMON.VALID_FLG.VALID : COMMON.VALID_FLG.INVALID,
            }
          : prevState,
      );
    },
    [tempOperationConf],
  );

  // 確定ボタン
  const handleClickDone = async () => {
    // Validationとセッション格納
    const http = new HttpConnection({ dispatch });
    await http
      .post<OperationConfType>(REST_API.MACHINE_CONF.SEQUENCE_CONF_VALIDATION, {
        movingX: tempOperationConf?.movingX,
        movingY: tempOperationConf?.movingY,
        openingArm: tempOperationConf?.openingArm,
        descending: tempOperationConf?.descending,
        closingArm: tempOperationConf?.closingArm,
        movingPit: tempOperationConf?.movingPit,
        finishingArm: tempOperationConf?.finishingArm,
        movingHome: tempOperationConf?.movingHome,
      })
      .then(() => {
        // グローバルステートに保存
        dispatch(setOperationConf(tempOperationConf));
        // 画面遷移
        history.push(redirectLinks.MCONF_STATION_CONF_GAME_CONF_TOP);
      });
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <MachineConfContent>
          <Box className={classes.root}>
            <Box className={classes.topContent}>
              <ReturnButton onClick={handleclickReturn}>ゲーム設定TOP</ReturnButton>
              <Box className="discription">
                「各シーケンス」を設定できます。
                <br />
                設定したいシーケンスをONにして、<Typography className={classes.doneImage}>確 定</Typography>
                してください。
              </Box>
            </Box>
            <Box className={classes.middleContent}>
              <Box className={`${classes.baseArea} ${stationStyle} catcherOperation`}>
                <Box className="title">キャッチャー操作時</Box>
                <Box className="content">
                  <Box className="col">
                    <Box className={`${stationStyle} toggleArea`}>
                      <Box className="toggleName">X 移動</Box>
                      <NormalSwitch
                        id={INPUT_NAMES.MOVING_X}
                        leftRight={leftRight}
                        value=""
                        checked={tempOperationConf?.movingX === COMMON.VALID_FLG.VALID}
                        onChange={handleClickSwitch}
                      />
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${stationStyle} toggleArea`}>
                      <Box className="toggleName">Y 移動</Box>
                      <NormalSwitch
                        id={INPUT_NAMES.MOVING_Y}
                        leftRight={leftRight}
                        value=""
                        checked={tempOperationConf?.movingY === COMMON.VALID_FLG.VALID}
                        onChange={handleClickSwitch}
                      />
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${stationStyle} toggleArea`}>
                      <Box className="toggleName">アームを開く</Box>
                      <NormalSwitch
                        id={INPUT_NAMES.OPENING_ARM}
                        leftRight={leftRight}
                        value=""
                        checked={tempOperationConf?.openingArm === COMMON.VALID_FLG.VALID}
                        onChange={handleClickSwitch}
                      />
                    </Box>
                  </Box>
                  <Box className="col">
                    <Box className={`${stationStyle} toggleArea`}>
                      <Box className="toggleName small">
                        下降
                        <br />
                        (戻り時、必ず上昇)
                      </Box>
                      <NormalSwitch
                        id={INPUT_NAMES.DESCENDING}
                        leftRight={leftRight}
                        value=""
                        checked={tempOperationConf?.descending === COMMON.VALID_FLG.VALID}
                        onChange={handleClickSwitch}
                      />
                    </Box>
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    {tempOperationConf?.operationConf === COMMON.OPERATION_CONF.SHIFT_OPERATION ? (
                      <Box className={`${stationStyle} toggleArea`}>
                        <Box className="toggleName">ずらし移動</Box>
                      </Box>
                    ) : (
                      <Box className="toggleArea movingShift">
                        <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                      </Box>
                    )}
                    <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                    <Box className={`${stationStyle} toggleArea`}>
                      <Box className="toggleName">アームを閉じる</Box>
                      <NormalSwitch
                        id={INPUT_NAMES.CLOSING_ARM}
                        leftRight={leftRight}
                        value=""
                        checked={tempOperationConf?.closingArm === COMMON.VALID_FLG.VALID}
                        onChange={handleClickSwitch}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={`${classes.baseArea} ${stationStyle} catcherReturn`}>
                <Box className="title">キャッチャー戻り時</Box>
                <Box className="col">
                  <Box className={`${stationStyle} toggleArea`}>
                    <Box className="toggleName">落とし口へ移動</Box>
                    <NormalSwitch
                      id={INPUT_NAMES.MOVING_PIT}
                      leftRight={leftRight}
                      value=""
                      checked={tempOperationConf?.movingPit === COMMON.VALID_FLG.VALID}
                      onChange={handleClickSwitch}
                    />
                  </Box>
                  <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                  <Box className={`${stationStyle} toggleArea`}>
                    <Box className="toggleName">アームを開閉</Box>
                    <NormalSwitch
                      id={INPUT_NAMES.FINISHING_ARM}
                      leftRight={leftRight}
                      value=""
                      checked={tempOperationConf?.finishingArm === COMMON.VALID_FLG.VALID}
                      onChange={handleClickSwitch}
                    />
                  </Box>
                  <img src={downArrowIcon} alt="downArrowIcon" className={classes.downArrowIcon} />
                  <Box className={`${stationStyle} toggleArea`}>
                    <Box className="toggleName">HPに戻る</Box>
                    <NormalSwitch
                      id={INPUT_NAMES.MOVING_HOME}
                      leftRight={leftRight}
                      value=""
                      checked={tempOperationConf?.movingHome === COMMON.VALID_FLG.VALID}
                      onChange={handleClickSwitch}
                    />
                  </Box>
                </Box>
              </Box>
              <img src={polyLineIconMedium} alt="polyLineIconMedium" className={classes.polyLineIconMedium} />
              <img src={polyLineIconLarge} alt="polyLineIconLarge" className={classes.polyLineIconLarge} />
            </Box>
            <Box className={classes.bottomContent}>
              <DoneButton onClick={handleClickDone}>確 定</DoneButton>
            </Box>
          </Box>
        </MachineConfContent>
      </Layout>
    </ScreenContext.Provider>
  );
};

export default SequenceConf;
