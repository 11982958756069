import React, { useMemo, useCallback, useContext } from 'react';
import { Stage, Layer, Text, Circle, Group, Rect, Line } from 'react-konva';
import { Vector2d } from 'konva/lib/types';
import { XyAreaType } from 'types/machineConf/areaType';
import MachineConfContext from 'Contexts/MachineConf/MachineConfContext';
import COMMON from 'constants/common';
import { throttle } from 'utils/controlFn.helper';
import { toPanelPosJack, toScreenPosJack } from 'utils/machineConf.helper';
import { useAppSelector } from 'redux/hooks';

/* ************ constant ************ */
const ICON_SIZE = 52;
const XY_AREA_SIZE = 360;
const XY_AREA_SIZE_HEIGHT = 390;
const PRIZE_AREA_MAX = 100;
const THROTTLE_SECOND = 50;

// 80 percent of the XY_AREA_SIZE_HEIGHT, equivalent 312px
const MAX_PERCENT = Math.round((XY_AREA_SIZE_HEIGHT / 100) * 80);
const MAX_HEIGHT = XY_AREA_SIZE_HEIGHT - MAX_PERCENT;

// 範囲幅の変換(対象の値*変換後のMAX/変換前のMAX)
const convertPixel = (width: number) => Math.round((width * XY_AREA_SIZE) / PRIZE_AREA_MAX);

const convertPixelY = (height: number) => Math.round((height * XY_AREA_SIZE_HEIGHT) / PRIZE_AREA_MAX);

/* ************ Component ************ */
const XyAreaJack: React.FC<XyAreaType> = ({
  enableCustomHome,
  // H/Eの位置（画面上に表示される0~100の座標で指定(プライズ機座標系)）
  homePos,
  customHomePos,
  endPos,
  onDrag,
}) => {
  // アイコンとエリアのサイズからkonvaによるアイコンの操作範囲を定義
  const width = XY_AREA_SIZE + ICON_SIZE;
  const height = XY_AREA_SIZE_HEIGHT + ICON_SIZE;
  const iconHalfSize = ICON_SIZE / 2;
  const maxAreaRangeX = XY_AREA_SIZE + iconHalfSize; // エリア最大X座標
  const maxAreaRangeY = XY_AREA_SIZE_HEIGHT + iconHalfSize; // エリア最大Y座標
  const minAreaRangeX = iconHalfSize; // エリア最小X座標
  const minAreaRangeY = iconHalfSize; // エリア最小Y座標

  const { leftRight } = useContext(MachineConfContext); //
  const isLeft = leftRight === COMMON.LEFT_RIGHT.LEFT;

  const INITIAL_POSITION_PARAMS = {
    screenAreaSize: XY_AREA_SIZE,
    screenAreaSizeHeight: XY_AREA_SIZE_HEIGHT,
    panelAreaSize: PRIZE_AREA_MAX,
    isLeft,
  };

  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  // Xは左右ステーションで位置が反転するため変換
  const minX = isLeft ? convertPixel(Number(nonAreaXMinSide)) : convertPixel(Number(nonAreaXMaxSide));
  const maxX = isLeft ? convertPixel(Number(nonAreaXMaxSide)) : convertPixel(Number(nonAreaXMinSide));
  const minY = convertPixelY(Number(nonAreaYMinSide));
  const maxY = convertPixelY(Number(nonAreaYMaxSide)) + 78;

  const maxRangeX = maxAreaRangeX - maxX;
  const minRangeX = minAreaRangeX + minX;
  const maxRangeY = maxAreaRangeY - minY; // 移動できる最大値 => yの最大値(100) - 移動不可範囲
  const minRangeY = minAreaRangeY + maxY; // 移動できる最小値 => yの最大値(0) + 移動不可範囲

  // useMemoでポジション変更時のみ変数を設定する。理由不明だがpropの値は利用できない。
  const areaHomePos = useMemo(
    () =>
      toScreenPosJack({
        ...homePos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [homePos],
  );
  const areaEndPos = useMemo(
    () =>
      toScreenPosJack({
        ...endPos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [endPos],
  );
  const areaCustomHomePos = useMemo(
    () =>
      toScreenPosJack({
        ...customHomePos,
        ...INITIAL_POSITION_PARAMS,
      }),
    [customHomePos],
  );

  /* ************ Event ************ */

  // Homeアイコン ドラッグ中イベント
  const handleDragHome = useCallback(
    throttle((pos: Vector2d) => {
      onDrag(toPanelPosJack({ ...pos, ...INITIAL_POSITION_PARAMS }), { x: 'homeX', y: 'homeY' });
    }, THROTTLE_SECOND),
    [],
  );

  // customHomeアイコン ドラッグ中イベント
  const handleDragCustomHome = useCallback(
    throttle((pos: Vector2d) => {
      onDrag(toPanelPosJack({ ...pos, ...INITIAL_POSITION_PARAMS }), { x: 'customX', y: 'customY' });
    }, THROTTLE_SECOND),
    [],
  );

  // Endアイコン ドラッグ中イベント
  const handleDragEnd = useCallback(
    throttle((pos: Vector2d) => {
      const cusY = {
        x: pos.x,
        y: pos.y <= MAX_HEIGHT ? MAX_HEIGHT : pos.y,
      };
      onDrag(toPanelPosJack({ ...cusY, ...INITIAL_POSITION_PARAMS }), { x: 'endX', y: 'endY' });
    }, THROTTLE_SECOND),
    [],
  );

  // Homeアイコン ドラッグ中移動範囲制御用イベント
  // Y座標計算用
  const dragYBoundFuncH = (y: number): number => {
    const movableRangeY = (enableCustomHome ? areaCustomHomePos.y : areaEndPos.y) + iconHalfSize; // 移動可能範囲最大Y座標 カスタムホームまたはエンドポジションの座標
    // 1. 移動不可範囲エリア(MAX側)にHPまたはEがある場合、HPまたはEの座標を設定
    if (movableRangeY > maxRangeY) return movableRangeY;
    // 2. 移動不可範囲エリア(MIN側)にHPまたはEがある場合かつ、移動不可範囲エリア(MIN側)を超えて移動した場合、移動最小値の座標を設定
    if (movableRangeY < minRangeY && y < minRangeY) return minRangeY;
    // 3. 移動可能範囲(MAX側)を超えて移動した場合、移動最大値の座標を設定
    if (maxRangeY < y) return maxRangeY;
    // 4. HPまたはEの内側に移動した場合、HPまたはEの座標を設定
    if (y < movableRangeY) return movableRangeY;
    // 5. 移動可能範囲を超えて移動した場合、移動最小値の座標を設定
    if (minRangeY > y) return minRangeY;

    return y;
  };
  const leftDragBoundFuncH = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    const movableRangeX = (enableCustomHome ? areaCustomHomePos.x : areaEndPos.x) + iconHalfSize; // 移動可能範囲最大X座標 カスタムホームまたはエンドポジションの座標

    if (movableRangeX < minRangeX) {
      // 1. 移動不可範囲エリア(MIN側)にHPまたはEがある場合、HPまたはEの座標を設定
      newPos.x = movableRangeX;
    } else if (movableRangeX > maxRangeX && pos.x > maxRangeX) {
      // 2. 移動不可範囲エリア(MAX側)にHPまたはEがある場合かつ、移動不可範囲エリア(MAX側)を超えて移動した場合、移動最大値の座標を設定
      newPos.x = maxRangeX;
    } else if (minRangeX > pos.x) {
      // 3. 移動できる最大値を超えて移動した場合、最大値の座標を設定
      newPos.x = minRangeX;
    } else if (pos.x > movableRangeX) {
      // 4. HPまたはEの内側に移動した場合、HPまたはEの座標を設定
      newPos.x = movableRangeX;
    }
    // Y座標
    newPos.y = dragYBoundFuncH(newPos.y);
    return newPos;
  };
  const rightDragBoundFuncH = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    const movableRangeX = (enableCustomHome ? areaCustomHomePos.x : areaEndPos.x) + iconHalfSize; // 移動可能範囲最大X座標 カスタムホームまたはエンドポジションの座標

    if (movableRangeX > maxRangeX) {
      // 1. 移動不可範囲エリア(MAX側)にHPまたはEがある場合、HPまたはEの座標を設定
      newPos.x = movableRangeX;
    } else if (movableRangeX < minRangeX && pos.x < minRangeX) {
      // 2. 移動不可範囲エリア(MIX側)にHPまたはEがある場合かつ、移動不可範囲エリア(MIN側)を超えて移動した場合、移動最小値の座標を設定
      newPos.x = minRangeX;
    } else if (maxRangeX < pos.x) {
      // 3. 移動できる最大値を超えて移動した場合、最大値の座標を設定
      newPos.x = maxRangeX;
    } else if (pos.x < movableRangeX) {
      // 4. HPまたはEの内側に移動した場合、HPまたはEの座標を設定
      newPos.x = movableRangeX;
    }

    // Y座標
    newPos.y = dragYBoundFuncH(newPos.y);
    return newPos;
  };
  const dragBoundFuncH = isLeft ? leftDragBoundFuncH : rightDragBoundFuncH;

  // CustomHomeアイコン ドラッグ中移動範囲制御用イベント
  // Y座標計算用
  const dragYBoundFuncHP = (y: number): number => {
    if (minRangeY > y) {
      // HOMEアイコンを下に超えて移動した場合
      return (areaEndPos.y <= MAX_HEIGHT ? MAX_HEIGHT : areaEndPos.y) + iconHalfSize;
    }

    if (y < areaEndPos.y + iconHalfSize) {
      // ENDアイコンを上に超えて移動した場合
      return areaEndPos.y + iconHalfSize;
    }

    if (areaHomePos.y + iconHalfSize < y) {
      // HOMEアイコンを下に超えて移動した場合
      return areaHomePos.y + iconHalfSize;
    }

    return y;
  };
  const leftDragBoundFuncHP = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    if (areaEndPos.x + iconHalfSize < pos.x) {
      // ENDアイコンより外に移動した場合
      newPos.x = areaEndPos.x + iconHalfSize;
    } else if (pos.x < areaHomePos.x + iconHalfSize) {
      // HOMEアイコンより外に移動した場合
      newPos.x = areaHomePos.x + iconHalfSize;
    }
    // Y座標
    newPos.y = dragYBoundFuncHP(newPos.y);
    return newPos;
  };
  const rightDragBoundFuncHP = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    if (pos.x < areaEndPos.x + iconHalfSize) {
      // ENDアイコンより外に移動した場合
      newPos.x = areaEndPos.x + iconHalfSize;
    } else if (pos.x > areaHomePos.x + iconHalfSize) {
      // HOMEアイコンより外に移動した場合
      newPos.x = areaHomePos.x + iconHalfSize;
    }
    // Y座標
    newPos.y = dragYBoundFuncHP(newPos.y);
    return newPos;
  };
  const dragBoundFuncHP = isLeft ? leftDragBoundFuncHP : rightDragBoundFuncHP;

  // Endアイコン ドラッグ中移動範囲制御用イベント
  // Y座標計算用
  const dragYBoundFuncE = (y: number): number => {
    const movableRangeY = (enableCustomHome ? areaCustomHomePos.y : areaHomePos.y) + iconHalfSize; // 移動可能範囲最大Y座標 カスタムホームまたはエンドポジションの座標

    // 1. 移動不可範囲エリア(0側)にHPまたはHがある場合、HPまたはHの座標を設定
    if (movableRangeY < minRangeY) return movableRangeY;
    // 2. 移動不可範囲エリア(100側)にHPまたはEがある場合かつ、移動不可範囲エリア(MAX側)を超えて移動した場合、移動最大値の座標を設定
    if (movableRangeY > maxRangeY && y > maxRangeY) return maxRangeY;
    // 3. 移動可能範囲(100側)を超えて移動した場合、移動最小値の座標を設定
    if (minRangeY > y) return minRangeY;
    // 4. HPまたはEの内側に移動した場合、HPまたはEの座標を設定
    if (y > movableRangeY) return movableRangeY;
    // 5. 移動可能範囲を超えて移動した場合、移動最大値の座標を設定
    if (maxRangeY < y) return maxRangeY;
    return y;
  };
  const leftDragBoundFuncE = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    const movableRangeX = (enableCustomHome ? areaCustomHomePos.x : areaHomePos.x) + iconHalfSize; // 移動可能範囲最小X座標 カスタムホームまたはエンドポジションの座標

    if (movableRangeX > maxRangeX) {
      // 1. 移動不可範囲エリア(100側)にHPまたはHがある場合、HPまたはHの座標を設定
      newPos.x = movableRangeX;
    } else if (movableRangeX < minRangeX && pos.x < minRangeX) {
      // 2. 移動不可範囲エリア(0側)にHPまたはHがある場合かつ、移動不可範囲エリア(0側)を超えて移動した場合、移動最大値の座標を設定
      newPos.x = minRangeX;
    } else if (maxRangeX < pos.x) {
      // 3. 移動できる最大値(100側)を超えて移動した場合、最大値の座標を設定
      newPos.x = maxRangeX;
    } else if (pos.x < movableRangeX) {
      // 4. HPまたはHの内側に移動した場合、HPまたはHの座標を設定
      newPos.x = movableRangeX;
    }

    // Y座標
    newPos.y = dragYBoundFuncE(newPos.y);

    return newPos;
  };
  const rightDragBoundFuncE = (pos: Vector2d) => {
    const newPos = { ...pos };
    // X座標
    const movableRangeX = (enableCustomHome ? areaCustomHomePos.x : areaHomePos.x) + iconHalfSize; // 移動可能範囲最小X座標 カスタムホームまたはエンドポジションの座標

    if (movableRangeX < minRangeX) {
      // 1. 移動不可範囲エリア(100側)にHPまたはHがある場合、HPまたはHの座標を設定
      newPos.x = movableRangeX;
    } else if (movableRangeX > maxRangeX && pos.x > maxRangeX) {
      // 2. 移動不可範囲エリア(0側)にHPまたはHがある場合かつ、移動不可範囲エリア(0側)を超えて移動した場合、移動最大値の座標を設定
      newPos.x = maxRangeX;
    } else if (minRangeX > pos.x) {
      // 3. 移動できる最大値(100側)を超えて移動した場合、最大値の座標を設定
      newPos.x = minRangeX;
    } else if (pos.x > movableRangeX) {
      // 4. HPまたはHの内側に移動した場合、HPまたはHの座標を設定
      newPos.x = movableRangeX;
    }

    // Y座標
    newPos.y = dragYBoundFuncE(newPos.y);
    return newPos;
  };

  const dragBoundFuncE = isLeft ? leftDragBoundFuncE : rightDragBoundFuncE;

  const areaEndPosY = areaEndPos.y <= MAX_HEIGHT ? 0 : areaEndPos.y - MAX_HEIGHT;
  const areaHomePosHeight = areaEndPos.y <= MAX_HEIGHT ? MAX_HEIGHT : areaEndPos.y;
  return (
    <div style={{ position: 'absolute', top: -32 }}>
      <Stage width={width} height={height}>
        {/* Layer=エリアはアイコンのサイズ分配置する座標をずらす。 */}

        <Layer x={iconHalfSize} y={iconHalfSize} width={XY_AREA_SIZE} height={XY_AREA_SIZE}>
          {/* Layer内の要素はLayerの左上を(x,y)=(0,0)として座標を指定する */}
          {/* 下に定義した要素が手前に表示されます */}

          {/* マスク（上段、中段左、中段右、下段に分けて描画） */}
          {/* マスク 上段 高さ：上から～ENDアイコン、幅：左から右まで */}
          <Rect x={0} y={MAX_HEIGHT} width={XY_AREA_SIZE} height={areaEndPosY} fill="black" opacity={0.5} />
          {/* マスク 中段左 高さ：エンドアイコン～ホームアイコン、幅：左側からホームアイコンまたはエリアアイコンまで */}
          <Rect
            x={0}
            y={areaEndPos.y <= MAX_HEIGHT ? MAX_HEIGHT : areaEndPos.y}
            width={isLeft ? areaHomePos.x : areaEndPos.x}
            height={areaHomePos.y - areaHomePosHeight}
            fill="black"
            opacity={0.5}
          />
          {/* マスク 中段右 高さ：エンドアイコン～ホームアイコン、幅：エンドアイコンまたはホームアイコンから右端まで */}
          <Rect
            x={isLeft ? areaEndPos.x : areaHomePos.x}
            y={areaEndPos.y <= MAX_HEIGHT ? MAX_HEIGHT : areaEndPos.y}
            width={XY_AREA_SIZE - (isLeft ? areaEndPos.x : areaHomePos.x)}
            height={areaHomePos.y - (areaEndPos.y <= MAX_HEIGHT ? MAX_HEIGHT : areaEndPos.y)}
            fill="black"
            opacity={0.5}
          />

          {/* マスク 下段 高さ：下から～ホームアイコン、幅：左から右まで */}
          <Rect
            x={0}
            y={areaHomePos.y}
            width={XY_AREA_SIZE}
            height={XY_AREA_SIZE_HEIGHT - areaHomePos.y}
            fill="black"
            opacity={0.5}
          />

          {/* H，Eに交差する点線 */}
          {/* Line E | */}
          <Line
            points={[areaEndPos.x, MAX_HEIGHT, areaEndPos.x, XY_AREA_SIZE_HEIGHT]}
            dash={[8, 8]}
            dashOffset={8}
            stroke="#3086FF"
            strokeWidth={4}
          />

          {/* Line E ------ */}
          <Line
            points={[
              0,
              areaEndPos.y >= MAX_HEIGHT ? areaEndPos.y : MAX_HEIGHT,
              XY_AREA_SIZE,
              areaEndPos.y >= MAX_HEIGHT ? areaEndPos.y : MAX_HEIGHT,
            ]}
            dash={[8, 8]}
            dashOffset={8}
            stroke="#3086FF"
            strokeWidth={4}
          />

          {/* Line H | */}
          <Line
            points={[areaHomePos.x, MAX_HEIGHT, areaHomePos.x, XY_AREA_SIZE_HEIGHT]}
            dash={[8, 8]}
            stroke="#FF3E3E"
            strokeWidth={4}
          />

          {/* Line H ------ */}
          <Line
            points={[
              0,
              areaHomePos.y >= MAX_HEIGHT ? areaHomePos.y : MAX_HEIGHT,
              XY_AREA_SIZE,
              areaHomePos.y >= MAX_HEIGHT ? areaHomePos.y : MAX_HEIGHT,
            ]}
            dash={[8, 8]}
            stroke="#FF3E3E"
            strokeWidth={4}
          />
          {/* Homeアイコン */}
          <Group
            x={areaHomePos.x}
            y={areaHomePos.y >= MAX_HEIGHT ? areaHomePos.y : MAX_HEIGHT}
            draggable
            onDragStart={(e) => handleDragHome(e.target.position())}
            onDragMove={(e) => handleDragHome(e.target.position())}
            onDragEnd={(e) => handleDragHome(e.target.position())}
            dragBoundFunc={dragBoundFuncH}>
            <Circle radius={iconHalfSize} fill="#FF3E3E" stroke="white" strokeWidth={3} />
            <Text
              width={ICON_SIZE}
              height={ICON_SIZE}
              x={-iconHalfSize}
              y={-iconHalfSize}
              text="H"
              fontSize={22}
              fill="white"
              align="center"
              verticalAlign="middle"
            />
          </Group>

          {/* customHomeアイコン */}
          {enableCustomHome && (
            <Group
              x={areaCustomHomePos.x}
              y={areaCustomHomePos.y}
              draggable
              onDragStart={(e) => handleDragCustomHome(e.target.position())}
              onDragMove={(e) => handleDragCustomHome(e.target.position())}
              onDragEnd={(e) => handleDragCustomHome(e.target.position())}
              dragBoundFunc={dragBoundFuncHP}>
              <Circle radius={iconHalfSize} fill="#FFB54E" stroke="white" strokeWidth={3} />
              <Text
                width={ICON_SIZE}
                height={ICON_SIZE}
                x={-iconHalfSize}
                y={-iconHalfSize}
                text="HP"
                fontSize={22}
                fill="white"
                align="center"
                verticalAlign="middle"
              />
            </Group>
          )}

          {/* Endアイコン */}
          {/* draggableを指定するとx,yは初期位置としてのみ使用する */}
          <Group
            x={areaEndPos.x}
            y={areaEndPos.y >= MAX_HEIGHT ? areaEndPos.y : MAX_HEIGHT}
            draggable
            onDragStart={(e) => handleDragEnd(e.target.position())}
            onDragMove={(e) => handleDragEnd(e.target.position())}
            onDragEnd={(e) => handleDragEnd(e.target.position())}
            dragBoundFunc={dragBoundFuncE}>
            <Circle radius={iconHalfSize} fill="#3086FF" stroke="white" strokeWidth={3} />
            <Text
              width={ICON_SIZE}
              height={ICON_SIZE}
              x={-iconHalfSize}
              y={-iconHalfSize}
              text="E"
              fontSize={22}
              fill="white"
              align="center"
              verticalAlign="middle"
            />
          </Group>
        </Layer>
      </Stage>
    </div>
  );
};

export default XyAreaJack;
