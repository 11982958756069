import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { BaseToggleType } from 'types/system/toggleType';
import COMMON from 'constants/common';

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  root: {
    height: '42px',
    width: '74px',
    padding: '6px',
  },
  leftSwitchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(32px)',
      color: theme.leftStation.itemMain,
      '& + $track': {
        backgroundColor: theme.leftStation.itemSub,
        opacity: 1,
      },
    },
  },
  rightSwitchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(32px)',
      color: theme.rightStation.itemMain,
      '& + $track': {
        backgroundColor: theme.rightStation.itemSub,
        opacity: 1,
      },
    },
  },
  thumb: {
    border: '1px solid',
    borderColor: theme.base.switchThumbBorderDefault,
    height: '40px',
    width: '40px',
  },
  track: {
    borderRadius: '16px',
    backgroundColor: theme.base.switchDefault,
    opacity: 1,
  },
  checked: {},
  left: {
    color: theme.leftStation.contents,
  },
  right: {
    color: theme.rightStation.contents,
  },
}));

/* ************ Component ************ */
/* ********** Main Component ********* */
/**
 * NormalSwitch スィッチ
 * 一覧選択画面を表示する。
 *
 * @author atsushi.teruya
 * @Params {SelectButtonType} props - 一覧選択ボタン利用に必要なパラメータ
 * @returns {React.FC}
 *
 */
const NormalSwitch: React.FC<BaseToggleType> = (props) => {
  const classes = useStyles();

  const { id, className, disabled, leftRight, value, onChange, checked } = props;
  const switchBase = leftRight === COMMON.LEFT_RIGHT.LEFT ? classes.leftSwitchBase : classes.rightSwitchBase;
  return (
    <Switch
      id={id}
      classes={{
        root: classes.root,
        switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      className={className}
      checked={checked}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

// propsの初期値。未指定時に使用する。
NormalSwitch.defaultProps = {
  disabled: false,
  checked: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(NormalSwitch);
