import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { StationDetailType } from 'types/data/dataType';
import TooltipComponent from 'components/system/organisms/dialogs/TooltipComponent';
import ListCheckBox from '../checkBoxs/ListCheckBox';

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  tCell: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      overflow: 'hidden',
    },
  },
}));

/* ************ Type ************ */
type Props = {
  row: StationDetailType;
  handleSelected: (row: StationDetailType) => void;
};

/* ************ Component ************ */
const NomalRow: React.FC<Props> = ({ row, handleSelected }) => {
  const classes = useStyles();
  const key = `station_list_table_key_${row.stationId}`;

  return (
    <TableRow key={key}>
      <TableCell className={`cellStyle ${classes.tCell}`}>
        <ListCheckBox checked={row.selected} className="checkbox" onChange={() => handleSelected(row)} />
        <TooltipComponent tooltipText={row.giftName} displayWord={row.stationNameDisp ?? ''} />
      </TableCell>
    </TableRow>
  );
};

export default NomalRow;
