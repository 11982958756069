import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/system/layouts/Layout';
import ScreenContext from 'Contexts/Common/ScreenContext';
import COMMON from 'constants/common';
import { Box, Grid, makeStyles } from '@material-ui/core';
import InfoDialog from 'components/system/organisms/dialogs/InfoDialog';
import StationTable from 'components/data/organisms/tables/StationTable';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import DecisionButton from 'components/machineConf/atoms/DecisionButton';
import UrlHelper from 'utils/url.helper';
import redirectLinks from 'constants/redirectLinks';
import MESSAGES from 'constants/messages';
import HttpConnection from 'utils/httpConnection';
import { REST_API } from 'constants/apiUrls';
import { StationDetailType } from 'types/data/dataType';
import { NormalSelectBox } from 'components/system/organisms';
import { getClsfcnByCategory } from 'utils/common.helper';
import CLSFCN from 'constants/classification';
import PrevNextButton from 'components/system/atoms/buttons/PrevNextButton';
import { animateScroll as scroll } from 'react-scroll';
import { setDataStationList, setDataStationListSort, setStationList } from 'redux/slices/dataSlice';
import { getUserAuthThunk } from 'redux/slices/commonSlice';

/* ************ Context ************ */
const ScreenContextValue = {
  title: 'ステーション選択',
  screenId: 'SCR211',
  currentMenu: COMMON.MENU.DATA,
  initializeState: true,
};

/* ********** Constants ********** */
const TABLE_ID = 'dataStationTable';
const MAX_DISPLAY_BAR = 7;
export const USE_REDUX_VALUES = {
  FALSE: '0', // Reduxの値を私用しない
  TRUE: '1', // Reduxの値を使用する
};

/* ************ Style ************ */
const useStyles = makeStyles(() => ({
  contents: {
    padding: '32px 16px',
  },
  description: {
    padding: '0 0 7px 24px',
    fontSize: '23px',
  },
  sortArea: {
    paddingLeft: '793px',
    paddingBottom: '10px',
    position: 'absolute',
  },
  decision: {
    textAlign: 'right',
    width: '1100px',
    marginTop: '-15px',
  },
  previousBtn: {
    paddingBottom: '10px',
    position: 'inherit',
    textAlign: 'center',
    width: '71%',
    height: '29px',
  },
  preveNextButton: {
    position: 'absolute',
    right: 153,
  },
  nextBtn: {
    paddingTop: '5px',
    textAlign: 'center',
    height: '29px',
  },
  outerGrid: {
    position: 'relative',
  },
}));

const DataStationList: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const loginUserInfo = useAppSelector((state) => state.common.userInfo);
  const dataSouceSort = getClsfcnByCategory(CLSFCN.DATA_STATION_SORT.CATEGORY_CD);
  const { prevScreen } = useParams<{ prevScreen: string }>();
  // flg: 初回レンダリング時にgetStationListを実行させるかどうか。
  const useReduxValFlg = useRef(prevScreen === USE_REDUX_VALUES.TRUE);

  /* ************ state/redux ************ */
  const { dataStationList: stationList, dataStationListSort } = useAppSelector((state) => state.data);
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);
  const [sort, setSort] = useState<string>(
    useReduxValFlg.current && dataStationListSort ? dataStationListSort : CLSFCN.DATA_STATION_SORT.STATION_NAME_ASC,
  );

  useEffect(() => {
    dispatch(getUserAuthThunk());
  }, []);

  useEffect(() => {
    if (useReduxValFlg.current && stationList.length > 0) {
      // 初回にgetStationListを実行せずにReduxの値を表示する
      useReduxValFlg.current = false;
      return;
    }
    getStationList();
  }, [sort]);

  /* ************ Function ************ */
  const getStationList = async () => {
    if (!sort || !loginUserInfo?.currentHeadQId || !loginUserInfo?.currentStoreId) {
      return;
    }
    const http = new HttpConnection({ dispatch });
    const data = await http.get<{
      stationList: Array<StationDetailType>;
    }>(
      UrlHelper.convertGetUrl(REST_API.DATA.GET_STATION_LIST, {
        headquarters_id: loginUserInfo.currentHeadQId,
        store_id: loginUserInfo.currentStoreId,
        sort,
      }),
    );
    dispatch(setDataStationList(data.stationList));
  };

  /* ************ Event ************ */
  // sort change
  const handleChangeSort = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(ev.target.value);
  };

  // decision button
  const handleClickDecision = () => {
    // 選択済みのｽﾃｰｼｮﾝIDを抽出
    const ids = stationList.filter((st) => st.selected === true).map((st) => st.stationId);
    if (ids.length === 0) {
      // 入力チェックメッセージ表示
      setOpenInfoDialog(true);
      return;
    }
    dispatch(setStationList(ids));
    dispatch(setDataStationListSort(sort));
    // 売上データ閲覧画面へ遷移
    history.push(
      UrlHelper.convertQueryUrlFrontEnd(redirectLinks.DATA_SALES_VIEW, {
        prevScreen: COMMON.DATA_PREV_SCREEN.DATA_STATION_LIST,
      }),
    );
  };

  const handleClickPrev = () => {
    scroll.scrollMore(-58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleClickNext = () => {
    scroll.scrollMore(58, {
      duration: 50,
      delay: 0,
      containerId: TABLE_ID,
    });
  };

  const handleCloseInfoDialog = (): void => {
    setOpenInfoDialog(false);
  };

  return (
    <ScreenContext.Provider value={ScreenContextValue}>
      <Layout>
        <Box className={classes.contents}>
          <Grid container alignItems="flex-end" className={classes.outerGrid}>
            <Grid className={classes.description}>データを確認するステーションを選択</Grid>
            <Grid className={classes.sortArea}>
              <NormalSelectBox
                name="sort"
                dataSource={dataSouceSort}
                value={sort}
                onChange={handleChangeSort}
                label="並び替え"
              />
            </Grid>
            <Grid className={classes.previousBtn}>
              {stationList.length > 0 && (
                <div className={classes.preveNextButton}>
                  <PrevNextButton isUp className="prevScroll" onClick={handleClickPrev} />
                </div>
              )}
            </Grid>
          </Grid>
          <StationTable tableId={TABLE_ID} stationList={stationList} />
          <Grid className={classes.nextBtn}>
            {MAX_DISPLAY_BAR < stationList.length && (
              <PrevNextButton className="nextScroll" onClick={handleClickNext} />
            )}
          </Grid>
          <Grid className={classes.decision}>
            <DecisionButton onClick={handleClickDecision}>データ確認</DecisionButton>
          </Grid>
        </Box>
        <InfoDialog open={openInfoDialog} msg={MESSAGES.INF110} closeFunc={handleCloseInfoDialog} />
      </Layout>
    </ScreenContext.Provider>
  );
};

export default DataStationList;
