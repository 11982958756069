import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * DecisionButton Component
 * 設定開始ボタン
 * @author atsushi.teruya
 *
 * @param props
 * @returns {*}
 * @constructor
 */

// 型定義
type Props = {
  fullWidth?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: React.ReactNode;
};

// makeStylesでstyleを定義
const useStyles = makeStyles((theme) => ({
  button: {
    position: 'relative',
    color: theme.palette.common.white,
    borderRadius: '8px',
    background: '#000000',
    height: '72px',
    width: '200px',
    fontSize: '21px',
    textAlign: 'center',
    lineHeight: '28px',
    boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      background: '#000000',
      boxShadow: '5px 5px 3px 0px rgba(0, 0, 0, 0.2)',
    },
  },
  caption: {
    marginLeft: '0px',
    width: '100%',
  },
}));

// コンポーネント定義
const DecisionButton: React.FC<Props> = (props) => {
  // makeStyleで定義したstyleを取得
  const classes = useStyles();

  // イベント定義

  // propsのプロパティ情報を取得
  const { children, fullWidth, onClick, disabled } = props;

  return (
    <Button variant="contained" fullWidth={fullWidth} className={classes.button} onClick={onClick} disabled={disabled}>
      <p className={classes.caption}>{children}</p>
    </Button>
  );
};

// propsの初期値。未指定時に使用する。
DecisionButton.defaultProps = {
  fullWidth: false,
  disabled: false,
};

// レンダーが前回と変わらない場合前回のレンダーを再利用
export default memo(DecisionButton);
