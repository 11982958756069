import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useAppSelector } from 'redux/hooks';

/* ************* constant ************* */
const PRIZE_AREA_MAX = 100;
const XY_AREA_SIZE_M = 360;
const XY_AREA_SIZE_L = 390;

type BoundAreaDetailType = {
  className?: string;
  isLeft: boolean;
  size: 'M' | 'L';
};

type StylesProps = {
  minX: number | string;
  maxX: number | string;
  minY: number | string;
  maxY: number | string;
  size: 'M' | 'L';
  opacity: number;
};

/* ************ Style ************ */
const useStyles = makeStyles<Theme, StylesProps>(() => ({
  boundArea: (props) => ({
    position: 'absolute',
    width: '414px',
    height: '414px',
    padding: `${props.size === 'M' ? '27px' : '12px'}`,
  }),
  boundXStyle: (props) => ({
    height: '100%',
    borderLeft: `${props.minX}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderRight: `${props.maxX}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderTop: `${props.maxY}px solid rgba(0, 0, 0, ${props.opacity})`,
    borderBottom: `${props.minY}px solid rgba(0, 0, 0, ${props.opacity})`,
  }),
}));

const convertPixel = (width: number, areaSize: number) => Math.round((width * areaSize) / PRIZE_AREA_MAX);

const BoundAreaDetail: React.FC<BoundAreaDetailType> = (props) => {
  const { isLeft, className, size } = props;
  const conf = useAppSelector((state) => state.machineConf.machineConf.conf);
  const { nonAreaXMinSide, nonAreaXMaxSide, nonAreaYMinSide, nonAreaYMaxSide } =
    conf !== undefined ? conf : { nonAreaXMinSide: 0, nonAreaXMaxSide: 0, nonAreaYMinSide: 0, nonAreaYMaxSide: 0 };
  const areaSize = size === 'M' ? XY_AREA_SIZE_M : XY_AREA_SIZE_L;
  const minX = isLeft
    ? convertPixel(Number(nonAreaXMinSide), areaSize)
    : convertPixel(Number(nonAreaXMaxSide), areaSize);
  const maxX = isLeft
    ? convertPixel(Number(nonAreaXMaxSide), areaSize)
    : convertPixel(Number(nonAreaXMinSide), areaSize);
  const minY = convertPixel(Number(nonAreaYMinSide), areaSize);
  const maxY = convertPixel(Number(nonAreaYMaxSide), areaSize);

  const opacity = 0.5;
  const styleProps: StylesProps = { minX, maxX, minY, maxY, size, opacity };
  const classes = useStyles(styleProps);

  return (
    <div className={`${className} ${classes.boundArea}`}>
      <div className={classes.boundXStyle} />
    </div>
  );
};

export default BoundAreaDetail;
