import React, { memo } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PointerIcon from 'components/machineConf/atoms/icons/PointerIcon';
import COMMON from 'constants/common';
import { XySpeedLeverConfType } from '../../../../types/machineConf/machineConfType';

const MAX_CONTAINER = 210;
const INNER_CONTAINER = 204;
const PERCENT_RATIO_INNER = 2.04;
const PERCENT_RATIO_CONTAINER = 2.03;
const MAX_WITH_COORDINATE = 30;
const WIDTH_BOX = 30;
const MAX_WIDTH_ICON = 28;
const DASH_LINE_WIDTH = 4;
const MARGIN_VALUE = 8;
const MIN_WIDTH_LINE = 25;
const X_Y_LIMIT_H = 5;
const WIDTH_BORDER_BOX = 2;
const MIN_LIMIT_X = 22;

type XyConfInfoProps = {
  title: string;
  subtitle?: string;
  isLeft: boolean;
  xySpeedLeverConf: XySpeedLeverConfType | undefined;
};

type XyConfInfoStyleProps = {
  isLeft: boolean;
};

const useStyles = makeStyles<Theme, XyConfInfoStyleProps>((theme) => ({
  xyContainer: {
    padding: '4px',
    position: 'relative',
    borderRadius: '4px',
    height: '292px',
    width: '403px',
    textAlign: 'center',
    border: ({ isLeft }) => `2px solid ${isLeft ? theme.leftStation.itemBorder : theme.rightStation.itemBorder}`,
    '& .customTitle': {
      width: '140px',
    },
    '& .customPanel': {
      transform: 'translateX(-50%)',
    },
    '& .customSpecifyArea': {},
  },
  xyStyle: {
    width: '100%',
    height: '100%',
  },
  titleStyle: {
    height: '100%',
    width: '140px',
    textAlign: 'left',
  },
  title: {
    position: 'relative',
    fontSize: 24,
  },
  subTitle: {
    fontSize: 21,
    whiteSpace: 'pre-wrap',
  },
  frame: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  child: {
    margin: '0 0px 0px 4px',
  },
  border: {
    border: '3px solid',
    borderColor: ({ isLeft }) => (isLeft ? theme.leftStation.itemBorder : theme.rightStation.itemBorder),
  },
  specifyArea: {
    display: 'flex',
    width: `${MAX_CONTAINER}px`,
    height: `${MAX_CONTAINER}px`,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'center',
    margin: '18px 10px 10px 0px',
    '& .inner': {
      position: 'relative',
    },
    '& .table': {
      position: 'relative',
      width: `${INNER_CONTAINER}px`,
      height: `${INNER_CONTAINER}px`,
    },
    '& .tableCols': {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
    },
    '& .tableCol': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      background: '#ffffff',
    },
    '& .tableRows': {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: 11,
    },
    '& .tableRow': {
      width: '100%',
      height: '33px',
      borderTop: 'none',
    },
    '& .tableColLeft-1, & .tableColLeft-3': {
      borderRight: '1px solid',
      borderRightColor: theme.leftStation.itemBorder,
    },
    '& .tableRowLeft-2, & .tableRowLeft-4': {
      borderTop: '1px solid',
      borderColor: theme.leftStation.itemBorder,
    },
    '& .tableColRight-1, & .tableColRight-3': {
      borderRight: '1px solid',
      borderRightColor: theme.rightStation.itemBorder,
    },
    '& .tableRowRight-2, & .tableRowRight-4': {
      borderTop: '1px solid',
      borderColor: theme.rightStation.itemBorder,
    },
    '& .line': {
      width: '33px',
      height: '33px',
    },
  },
  panel: {
    width: `${MAX_CONTAINER}px`,
    height: `${MAX_CONTAINER}px`,
    flexDirection: 'column',
    position: 'absolute',
    textAlign: 'center',
    top: '22px',
    left: '253px',
    zIndex: 20,
  },
  abs: {
    position: 'absolute',
  },
  homeHr: {
    position: 'absolute',
    borderTop: `dashed ${DASH_LINE_WIDTH}px #FF3E3E`,
    width: `${INNER_CONTAINER}px`,
  },
  homeVertical: {
    position: 'absolute',
    borderLeft: `${DASH_LINE_WIDTH}px dashed #FF3E3E`,
    width: '4px',
    height: `${INNER_CONTAINER}px`,
  },
  endHr: {
    position: 'absolute',
    borderTop: 'dashed 4px #3086FF',
    width: `${INNER_CONTAINER}px`,
  },
  endVertical: {
    position: 'absolute',
    borderLeft: `dashed ${DASH_LINE_WIDTH}px #3086FF`,
    width: '2px',
    height: `${INNER_CONTAINER}px`,
  },
  homeBg: {
    background: '#EEEEEE',
    border: '1px solid #FF3E3E',
    borderRadius: '2px',
    width: `${MAX_WITH_COORDINATE}px`,
    height: `${MAX_WITH_COORDINATE}px`,
    fontSize: '19px',
  },
  endBg: {
    background: '#EEEEEE',
    border: '1px solid #3086FF',
    borderRadius: '2px',
    width: `${MAX_WITH_COORDINATE}px`,
    height: `${MAX_WITH_COORDINATE}px`,
    fontSize: '19px',
  },
  p: {
    letterSpacing: '-2px',
  },
}));

const XyConfInfo: React.FC<XyConfInfoProps> = (props) => {
  const { title, subtitle, xySpeedLeverConf, isLeft } = props;
  const classes = useStyles({ isLeft });

  const tableCols = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(
        <Box className={isLeft ? `tableCol tableColLeft-${index}` : `tableCol tableColRight-${index}`}>&nbsp;</Box>,
      );
    }
    return item;
  };

  const tableRows = (value: number) => {
    const item: Array<JSX.Element> = [];
    for (let index = 0; index <= value; index += 1) {
      item.push(
        <Box className={isLeft ? `tableRow tableRowLeft-${index}` : `tableRow tableRowRight-${index}`}>&nbsp;</Box>,
      );
    }
    return item;
  };

  const hIcon = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const iconPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2}px,${
          INNER_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2
        }px`,
        left: '1px',
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER
        }px,${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="H" />
        </Box>,
      );
    }
    return item;
  };

  const hValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;

    if (isLeft) {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * 1.8 + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < (MAX_WITH_COORDINATE + 1) / 2) {
            marginLeft -= (MAX_WITH_COORDINATE + 1) / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px)`,
        left: `${marginLeft + marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${-MAX_WITH_COORDINATE - MARGIN_VALUE}px, ${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_CONTAINER
        }px)`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.homeX - xySpeedLeverConf?.endX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.homeY - xySpeedLeverConf?.endY);
        if (xySpeedLeverConf?.homeX < X_Y_LIMIT_H && xySpeedLeverConf.homeY < X_Y_LIMIT_H) {
          marginLimitX = (X_Y_LIMIT_H - xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < (MAX_WITH_COORDINATE + 1) / 2) {
            marginLeft += (MAX_WITH_COORDINATE + 1) / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${MAX_CONTAINER + MARGIN_VALUE}px, ${
          MAX_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_CONTAINER
        }px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.homeBg}>
              <p className={classes.p}>{xySpeedLeverConf?.homeY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };

  const hVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const VarPos = {
        transform: `translate(${Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER}px, 0px)`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${INNER_CONTAINER - Number(xySpeedLeverConf?.homeX) * PERCENT_RATIO_INNER}px,0px`,
      };
      item.push(
        <Box className={classes.homeVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  const hHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px, ${INNER_CONTAINER - Number(xySpeedLeverConf?.homeY) * PERCENT_RATIO_INNER}px)`,
    };
    item.push(<div className={classes.homeHr} style={hrPos} />);
    return item;
  };

  const eIcon = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const iconPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2}px,${
          INNER_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2
        }px`,
        left: '1px',
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    } else {
      const iconPos = {
        transform: `translate(${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER
        }px,${
          INNER_CONTAINER -
          MAX_WIDTH_ICON / 2 +
          DASH_LINE_WIDTH / 2 -
          Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER
        }px`,
      };
      item.push(
        <Box className={classes.abs} style={iconPos}>
          <PointerIcon size="S" type="E" />
        </Box>,
      );
    }
    return item;
  };

  const eValue = () => {
    const item: Array<JSX.Element> = [];
    let marginLeft = -WIDTH_BOX / 2;
    let marginTop = -WIDTH_BOX / 2;
    let marginLimitX = 0;

    if (isLeft) {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER + WIDTH_BORDER_BOX;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginLeft += MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }

      const xValPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px)`,
        left: `${marginLeft + marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${-MAX_WITH_COORDINATE - MARGIN_VALUE}px,  ${
          MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_CONTAINER
        }px)`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    } else {
      if (xySpeedLeverConf) {
        const valueOverloadX = Math.abs(xySpeedLeverConf?.endX - xySpeedLeverConf?.homeX);
        const valueOverloadY = Math.abs(xySpeedLeverConf?.endY - xySpeedLeverConf?.homeY);
        if (
          xySpeedLeverConf?.homeX < X_Y_LIMIT_H &&
          xySpeedLeverConf.homeY < X_Y_LIMIT_H &&
          valueOverloadX < MIN_LIMIT_X
        ) {
          marginLimitX = (MIN_LIMIT_X - xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER - WIDTH_BORDER_BOX * 2;
        }
        if (valueOverloadX <= MIN_WIDTH_LINE && marginLimitX === 0) {
          const pixelBetween = (valueOverloadX * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginLeft -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
        if (valueOverloadY <= MIN_WIDTH_LINE) {
          const pixelBetween = (valueOverloadY * INNER_CONTAINER) / 100 / 2;
          if (pixelBetween < MAX_WITH_COORDINATE / 2) {
            marginTop -= MAX_WITH_COORDINATE / 2 - pixelBetween;
          }
        }
      }
      const xValPos = {
        transform: `translate(${MAX_CONTAINER - Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_CONTAINER}px, ${
          MAX_CONTAINER + MARGIN_VALUE
        }px`,
        left: `${marginLeft - marginLimitX}px`,
      };
      const yValPos = {
        transform: `translate(${MAX_CONTAINER + MARGIN_VALUE}px,${
          MAX_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_CONTAINER
        }px`,
        top: `${marginTop}px`,
      };
      item.push(
        <Box>
          <Box className={classes.abs} style={xValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endX}</p>
            </Box>
          </Box>
          <Box className={classes.abs} style={yValPos}>
            <Box className={classes.endBg}>
              <p className={classes.p}>{xySpeedLeverConf?.endY}</p>
            </Box>
          </Box>
        </Box>,
      );
    }
    return item;
  };

  const eVerLine = () => {
    const item: Array<JSX.Element> = [];
    if (isLeft) {
      const VarPos = {
        transform: `translate(${Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER}px,0px)`,
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    } else {
      const VarPos = {
        transform: `translate(${INNER_CONTAINER - Number(xySpeedLeverConf?.endX) * PERCENT_RATIO_INNER}px,0px`,
      };
      item.push(
        <Box className={classes.endVertical} style={VarPos}>
          &nbsp;
        </Box>,
      );
    }
    return item;
  };

  const eHrLine = () => {
    const item: Array<JSX.Element> = [];
    const hrPos = {
      transform: `translate(0px, ${INNER_CONTAINER - Number(xySpeedLeverConf?.endY) * PERCENT_RATIO_INNER}px)`,
    };
    item.push(<div className={classes.endHr} style={hrPos} />);
    return item;
  };

  const customHpIcon = () => {
    const item: Array<JSX.Element> = [];
    if (xySpeedLeverConf?.customConf === COMMON.CUSTOM_HOME_POSITION.CUSTOM) {
      if (isLeft) {
        const iconPos = {
          transform: `translate(${
            Number(xySpeedLeverConf?.customX) * PERCENT_RATIO_INNER - MAX_WIDTH_ICON / 2 + DASH_LINE_WIDTH / 2
          }px,${
            INNER_CONTAINER -
            Number(xySpeedLeverConf?.customY) * PERCENT_RATIO_INNER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      } else {
        const iconPos = {
          transform: `translate(${
            INNER_CONTAINER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            Number(xySpeedLeverConf?.customX) * PERCENT_RATIO_INNER
          }px,${
            INNER_CONTAINER -
            MAX_WIDTH_ICON / 2 +
            DASH_LINE_WIDTH / 2 -
            Number(xySpeedLeverConf?.customY) * PERCENT_RATIO_INNER
          }px`,
        };
        item.push(
          <Box className={classes.abs} style={iconPos}>
            <PointerIcon size="S" type="HP" />
          </Box>,
        );
      }
    }
    return item;
  };

  return (
    <Box className={classes.xyContainer}>
      <Box className={classes.frame}>
        <Box className={classes.titleStyle}>
          <Box className="customTitle">
            <div className={`${classes.title}`}>
              <b>{title}</b>
            </div>
            {subtitle ? <span className={`${classes.subTitle}`}>{subtitle}</span> : ''}
          </Box>
        </Box>
        <Box className={classes.xyStyle}>
          <Box>
            <Box className={`${classes.child} customTable`}>
              <Box className={`${classes.specifyArea} customSpecifyArea`}>
                <Box className={classes.border}>
                  <Box className="inner">
                    <Box className="table">
                      <Box className="tableCols">{tableCols(5)} </Box>
                      <Box className="tableRows">{tableRows(5)}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={`${classes.panel} customPanel`}>
              {eHrLine()}
              {hHrLine()}
              {eVerLine()}
              {hVerLine()}
              {eValue()}
              {hValue()}
              {hIcon()}
              {customHpIcon()}
              {eIcon()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(XyConfInfo);
