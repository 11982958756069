import { createSlice } from '@reduxjs/toolkit';

interface HistoryActionTabmenuState {
  historyActionTabmenu: {
    viewIsDay: {
      startDate: string;
    };
    viewIsWeek: {
      startDate: string;
    };
    viewIsMonth: {
      startDate: string;
    };
    viewIsYear: {
      startDate: string;
    };
    activeTab: {
      startDate: string;
      viewUnit: string;
    };
  };
}

const initialState: HistoryActionTabmenuState = {
  historyActionTabmenu: {
    viewIsDay: {
      startDate: '',
    },
    viewIsWeek: {
      startDate: '',
    },
    viewIsMonth: {
      startDate: '',
    },
    viewIsYear: {
      startDate: '',
    },
    activeTab: {
      startDate: '',
      viewUnit: '1',
    },
  },
};

const historyActionTabmenu = createSlice({
  name: 'historyActionTabmenu',
  initialState,
  reducers: {
    tabViewIsDay: (state, action) => {
      state.historyActionTabmenu.viewIsDay.startDate = action.payload.startDate;
      state.historyActionTabmenu.activeTab.startDate = action.payload.startDate;
    },
    tabViewIsWeek: (state, action) => {
      state.historyActionTabmenu.viewIsWeek.startDate = action.payload.startDate;
      state.historyActionTabmenu.activeTab.startDate = action.payload.startDate;
    },
    tabViewIsMonth: (state, action) => {
      state.historyActionTabmenu.viewIsMonth.startDate = action.payload.startDate;
      state.historyActionTabmenu.activeTab.startDate = action.payload.startDate;
    },
    tabViewIsYear: (state, action) => {
      state.historyActionTabmenu.viewIsYear.startDate = action.payload.startDate;
      state.historyActionTabmenu.activeTab.startDate = action.payload.startDate;
    },
    tabActive: (state, action) => {
      state.historyActionTabmenu.activeTab.startDate = action.payload.startDate;
      state.historyActionTabmenu.activeTab.viewUnit = action.payload.viewUnit;
    },
    resetTabView: (state) => {
      state.historyActionTabmenu.viewIsDay.startDate = '';
      state.historyActionTabmenu.viewIsWeek.startDate = '';
      state.historyActionTabmenu.viewIsMonth.startDate = '';
      state.historyActionTabmenu.viewIsYear.startDate = '';
      state.historyActionTabmenu.activeTab.startDate = '';
      state.historyActionTabmenu.activeTab.viewUnit = '1';
    },
  },
});

export const {
  tabViewIsDay,
  tabViewIsWeek,
  tabViewIsMonth,
  tabViewIsYear,
  tabActive,
  resetTabView,
} = historyActionTabmenu.actions;

export default historyActionTabmenu.reducer;
